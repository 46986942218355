import {
  CasualUsers,
  PotentialPowerUsers,
  HibernatingPersona,
  NewUsersPersona,
  PersonaUser,
  ArrowRightBlack,
  ArrowUpGray,
  ArrowRightGray,
  Timer,
  PowerUsers,
  PersonaVideoPoster,
  PlayIcon,
  CurvedArrow,
  PersonaVideoBg,
} from 'ui-components/data-display/Icons';
import { useState } from 'react';
import Modal from 'ui-components/util/modal';

const PersonaBox = ({
  icon,
  bgColor,
  bgColorLight,
  textColor,
  width,
  height,
  personaName,
  borderColor,
  personaDetails,
}) => {
  return (
    <div style={{ width, height, color: textColor }}>
      <div className="relative w-full h-full group">
        <div
          className="absolute w-full h-full transition-opacity duration-300 ease-in opacity-100 group-hover:opacity-0"
          style={{ backgroundColor: bgColor }}
        >
          <div className="flex flex-col items-center justify-center w-full h-full text-base">
            {icon}
            <div className="font-gilroy-bold">{personaName}</div>
          </div>
        </div>
        <div
          className="absolute z-10 w-full h-full transition-opacity duration-300 ease-in opacity-0 group-hover:opacity-100"
          style={{ backgroundColor: bgColorLight }}
        >
          <div
            className="flex items-center px-4 py-2 text-base border-dashed border-b-1"
            style={{ borderColor }}
          >
            {icon}
            <div className="ml-2 font-gilroy-bold">{personaName}</div>
          </div>
          <div className="py-4 font-gilroy-semi-bold">
            {personaDetails?.length &&
              personaDetails.map((point, index) => (
                <div className="flex items-center px-2 pb-2 text-xs" key={index}>
                  <span className="mr-2">•</span>
                  <div> {point}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Persona() {
  const [showVideoModal, setShowVideoModal] = useState(false);

  const casualUserCommonProps = {
    icon: <CasualUsers />,
    bgColor: '#FFF3D4',
    bgColorLight: '#FFFCF4',
    textColor: '#FFAB02',
    width: '17rem',
    personaName: 'Casual user',
    borderColor: '#FFE9C5',
  };

  const personaProps = {
    newUser: {
      icon: <NewUsersPersona />,
      bgColor: '#EDF2FF',
      bgColorLight: '#F3F7FF',
      textColor: '#406EF6',
      width: '11.25rem',
      height: '27.5rem',
      personaName: 'New user',
      borderColor: '#DFE7FF',
      personaDetails: [
        'Very new to the platform',
        'Exploring features and functionalities',
        'Keep checking in them from time to time',
      ],
    },
    potentialPowerUser: {
      icon: <PotentialPowerUsers />,
      bgColor: '#FFEDE2',
      bgColorLight: '#FFF7F3',
      textColor: '#FA7A31',
      width: '17rem',
      height: '12.25rem',
      personaName: 'Potential power user',
      borderColor: '#FFDDCA',
      personaDetails: [
        'Have shown strong early signals of high engagement',
        'Help these customers get continued value from the product',
      ],
    },
    casualUserOne: {
      ...casualUserCommonProps,
      height: '15rem',
      personaDetails: [
        'Have been active on and off, but nothing too special',
        'Introduce them to your top features so they experience the "Aha" moment',
      ],
    },
    powerUser: {
      icon: <PowerUsers />,
      bgColor: '#E7FFED',
      bgColorLight: '#F3FFF6',
      textColor: '#39A129',
      width: '17rem',
      height: '9.75rem',
      personaName: 'Power user',
      borderColor: '#D0FFC9',
      personaDetails: [
        'Have consistently been receiving value from the product',
        'Reach out to them for reviews and feedback',
      ],
    },
    casualUserTwo: {
      ...casualUserCommonProps,
      height: '8.75rem',
      personaDetails: [
        'Occasional users of your product',
        'Keep sharing updates around what’s new ',
      ],
    },
    dormantUser: {
      icon: <HibernatingPersona />,
      bgColor: '#FFDEDE',
      bgColorLight: '#FFEFEF',
      textColor: '#DD3C3C',
      width: '17rem',
      height: '8.5rem',
      personaName: 'Dormant user',
      borderColor: '#FFDDDD',
      personaDetails: [
        'Rarely use the product',
        "If you're short on resources, you're better off focusing on the other personas",
      ],
    },
  };

  return (
    <div>
      <div className="flex items-center">
        <div className="flex items-center mt-32 align-self-baseline">
          <div className="flex flex-col items-center">
            <PersonaUser />
            <div className="mt-4 text-center font-gilroy-bold text-tw-black-5">
              New sign up enters here
            </div>
          </div>
          <ArrowRightBlack className="mb-10 w-14" />
        </div>
        <div className="">
          <div className="relative flex ml-4">
            <div className="">
              <PersonaBox {...personaProps.newUser} />
            </div>
            <div>
              <div className="px-px mx-px border-l-4 border-r-4 border-dashed border-tw-gray-eb">
                <div className="mb-1">
                  <PersonaBox {...personaProps.potentialPowerUser} />
                </div>
                <div>
                  <PersonaBox {...personaProps.casualUserOne} />
                </div>
              </div>
            </div>
            <div>
              <div className="mb-1">
                <PersonaBox {...personaProps.powerUser} />
              </div>
              <div className="mb-1">
                <PersonaBox {...personaProps.casualUserTwo} />
              </div>
              <div>
                <PersonaBox {...personaProps.dormantUser} />
              </div>
            </div>
            <div className="absolute text-xs" style={{ left: '-15%', bottom: '8%' }}>
              <div className="mb-3 -rotate-90 font-inter text-tw-black-5">Engagement levels</div>
              <ArrowUpGray className="absolute" style={{ right: '20px' }} />
            </div>
            <div className="absolute mt-8 text-xs" style={{ bottom: '-15%', left: '-3%' }}>
              <ArrowRightGray />
              <div className="mt-2 font-inter text-tw-black-5">Time spent on platform</div>
            </div>
          </div>
          <div className="relative flex justify-end w-full text-xs font-inter text-tw-black-9">
            <div className="absolute flex flex-col items-center my-2" style={{ left: '145px' }}>
              <Timer />
              <div className="text-center w-28">Incubation time</div>
            </div>
            <div
              className="absolute flex flex-col items-center w-full my-2"
              style={{ left: '104px' }}
            >
              <Timer />
              <div className="text-center w-28">Maturity time</div>
            </div>
          </div>
          <div>
            <div className="relative flex justify-end">
              <CurvedArrow className="absolute h-30" style={{ left: '50%', top: '19%' }} />
              <div onClick={() => setShowVideoModal(true)} className="relative cursor-pointer">
                <PersonaVideoPoster className="w-48 mt-3 " />
                <PlayIcon className="absolute z-20" style={{ top: '50%', right: '39%' }} />
                <PersonaVideoBg
                  className="absolute"
                  style={{ top: '5%', left: '-47%', width: '27rem', zIndex: '-1' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={showVideoModal}
        trigger={null}
        onClose={() => setShowVideoModal(false)}
        hrRequired={false}
        closeIcon={null}
        width="w-auto"
      >
        <div className="w-full">
          <div>
            <video
              autoPlay
              controls
              src="https://assets.toplyne.io/videos/personas_explanation.mp4"
              height="480"
              width="786"
            ></video>
          </div>
        </div>
      </Modal>
    </div>
  );
}
