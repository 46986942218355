import * as AccordionPrimitive from '@radix-ui/react-accordion';
import useDsEnabled from 'hooks/useDsEnabled';
import useMeta from 'pages/pql-workflow/hooks/useMeta';
import useOpportunityQuery from 'pages/pql-workflow/hooks/useOpportunityQuery';
import { useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import {
  ExportCategory,
  FiltersPQLSections,
  useFiltersPQLStore,
} from 'pages/pql-workflow/store/PQLFiltersStore';
import { PQLWorkflowSteps, usePQLWorkflowStore } from 'pages/pql-workflow/store/PQLWorkflowStore';
import { forwardRef, RefObject } from 'react';
import { EditIcon, TickIconFilled } from 'ui-components/data-display/Icons';
import { numberWithPluralWord } from 'utils/common';
import SectionHeader from '../SectionHeader';
import FilterSubSections from './FiltersSubSections';

const getExportLeadsText = (
  exportCategory: ExportCategory,
  leadObject: { percentage?: number; salesCapacity?: number },
  allLeads: boolean,
  dsEnabled: boolean
) => {
  if (leadObject.percentage) {
    return `${dsEnabled ? 'top' : ''} ${leadObject.percentage}%`;
  } else if (leadObject.salesCapacity) {
    return `${allLeads ? '' : dsEnabled ? 'top' : 'any'} ${leadObject.salesCapacity}`;
  } else {
    return `all`;
  }
};

const Filters = forwardRef((_, ref: RefObject<HTMLDivElement>) => {
  Filters.displayName = 'FilterPQLComponent';

  const { currentSection, setCurrentSection } = useFiltersPQLStore();
  const { currentStep, setCurrentStep, completedSteps } = usePQLWorkflowStore();
  const { filters, exportCategory, percentage, salesCapacity } = useFiltersPQLStore();
  const { modelId, targetEntity } = useDefinePQLStore();
  const { data: opporunityQueryData } = useOpportunityQuery();
  const dsEnabled = useDsEnabled(modelId, targetEntity);

  useMeta();

  if (!completedSteps[PQLWorkflowSteps.FILTERS] && currentStep !== PQLWorkflowSteps.FILTERS) {
    return (
      <div className="flex px-6 py-6 bg-white border gap-x-5 rounded-xl border-tw-gray-eb">
        <TickIconFilled className="fill-current text-tw-gray-c" />
        <div>
          <h4 className="text-base font-medium text-tw-black-7">Refine with business context</h4>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full border rounded-xl border-tw-gray-eb" ref={ref}>
      {currentStep === PQLWorkflowSteps.FILTERS && (
        <>
          <SectionHeader number={2} title={'Refine with business context'} />
          <AccordionPrimitive.Root
            type="single"
            value={currentSection}
            onValueChange={(value: FiltersPQLSections) => setCurrentSection(value)}
            collapsible
          >
            <FilterSubSections />
          </AccordionPrimitive.Root>
        </>
      )}
      {currentStep !== PQLWorkflowSteps.FILTERS && (
        <div
          className="flex justify-between px-6 py-6 bg-white cursor-pointer item-center gap-x-5 rounded-xl group"
          onClick={() => {
            setCurrentStep(PQLWorkflowSteps.FILTERS);
            ref?.current?.scrollIntoView();
          }}
        >
          <div className="flex gap-x-5">
            <TickIconFilled className="fill-current text-tw-green-39" />
            <div>
              <h4 className="text-base font-medium text-tw-black-7">
                Refine with business context
              </h4>
              <div className="text-tw-black-9">
                {filters.length > 0 ? numberWithPluralWord(filters.length, 'filter') : `No filters`}{' '}
                applied | Exporting{' '}
                {getExportLeadsText(
                  exportCategory,
                  { percentage, salesCapacity },
                  salesCapacity === opporunityQueryData?.count,
                  dsEnabled
                )}{' '}
                {targetEntity.toLowerCase()}
              </div>
            </div>
          </div>
          <div className="items-center hidden group-hover:flex gap-x-2 text-tw-black-5">
            <EditIcon />
            <span>Edit</span>
          </div>
        </div>
      )}
    </div>
  );
});

export default Filters;
