import { Integration, IntegrationType } from 'api-client';
import dataSyncAnimation from 'assets/lotties/data-sync.json';
import InfoBanner from 'ui-components/feedback/InfoBanner';
import Drawer from 'ui-components/navigation/Drawer';
import {
  CalendarColorIcon,
  GDPRIcon,
  LockOutlineIcon,
  SOC2Icon,
  TickIconRound,
  ToplyneLogoBlue,
  InfoAlertIcon,
  ExternalLink,
} from 'ui-components/data-display/Icons';
import { PrimaryButton, SecondaryButton } from 'ui-components/inputs/Buttons';
import Copy from 'ui-components/util/Copy';
import ToolTip from 'ui-components/data-display/Tooltip';
import { TextInput } from 'ui-components/inputs/Inputs';
import { classNames } from 'utils/common';
import { useAuth } from 'hooks/useAuth';
import Lottie from 'lottie-react';
import useUpdateOrganization from 'pages/activate/hooks/useUpdateOrganization';
import SnowflakeBestPractices from 'pages/activate/SnowflakeBestPractices';
import WarningModal from 'pages/activate/WarningModal';
import useAddIntegration from 'pages/integrations/hooks/useAddIntegration';
import useConnectedIntegrationsWithInterval from 'pages/integrations/hooks/useConnectedIntegrationsWithInterval';
import useIntegrations from 'pages/integrations/hooks/useIntegrations';
import useSegmentSources from 'pages/integrations/hooks/useSegmentSources';
import IntegrationIcon from 'pages/integrations/sub-components/IntegrationIcon';
import Card from 'ui-components/business/Card';
import { AddTokenForm } from 'pages/settings/api-settings/AddToken';
import { TokenDetails } from 'pages/settings/api-settings/APISettings';
import useAddAPIToken from 'pages/settings/hooks/useAddAPIToken';
import useSendInvite from 'pages/settings/hooks/useSendInvite';
import { Roles } from 'pages/settings/Roles';
import React, { useEffect, useState } from 'react';
import { CalendlyEventListener, InlineWidget } from 'react-calendly';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';
import { useAnalytics } from 'telemetry';
import { AnalyticsConst, AnalyticsEvents } from 'telemetry/constants';
import { useApiTokenStore } from 'stores/ApiTokenStore';
import useActivateOrganization from '../../hooks/useActivateOrganization';
import {
  SourceConnectionSteps,
  useActivateStore,
  segmentPlans,
  SegmentPlan,
} from './store/ActivateStore';
import { prerequisites, setUpGuides } from './setupGuides';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

export function SetupGuide({ type }) {
  let details;
  const { currentSegmentPlan } = useActivateStore();
  if (type == IntegrationType.SEGMENT) {
    details = setUpGuides[type][currentSegmentPlan];
  } else {
    details = setUpGuides[type];
  }
  if (details)
    return (
      <div className="px-6 mt-8">
        {prerequisites[type] && (
          <div className="my-6">
            <h4 className="font-medium text-tw-black-3 text-base">Prerequisites</h4>
            <p className="mt-3 text-tw-black-5 children:text-tw-blue-0d children:font-medium">
              {parse(prerequisites[type])}
            </p>
          </div>
        )}
        {Object.keys(details).map((d, idx) => {
          return (
            <div key={idx} className="mt-6">
              <h4 className="font-medium text-tw-black-3 text-base">{d}</h4>
              <ol className="ml-4 list-decimal">
                {details[d].map((point, index) => (
                  <li key={index} className="mt-6 font-medium text-tw-black-3">
                    <span className="children:text-tw-blue-0d children:font-medium">
                      {parse(point.text)}
                    </span>
                    {point.image && (
                      <img
                        src={point.image}
                        className={classNames(
                          [IntegrationType.SNOWFLAKE, IntegrationType.BIGQUERY].includes(type)
                            ? 'w-2/3'
                            : 'w-full',
                          'mt-3'
                        )}
                        alt="instructions"
                      />
                    )}
                  </li>
                ))}
              </ol>
            </div>
          );
        })}
      </div>
    );
  return <></>;
}
type InviteTeamMemberFormState = {
  email: string;
};

enum DataSyncViewType {
  connect = 'connect',
  sync = 'sync',
}

const readonlyGuidesIntegrationTypes = [IntegrationType.SNOWFLAKE, IntegrationType.BIGQUERY];

const sourcesWithoutSchema = [...readonlyGuidesIntegrationTypes, IntegrationType.API];

const sourcesWithSchemaAndRequiresCall = [IntegrationType.RUDDERSTACK];

const sourcesThatRequireCall = [...sourcesWithoutSchema, ...sourcesWithSchemaAndRequiresCall];

function TeamMemberInvite() {
  const [showTeamMemberInvite, setShowTeamMemberInvite] = useState(false);
  const { handleSubmit, register, watch, formState } = useForm();
  const email = watch('email');

  const sendInviteMutation = useSendInvite();

  const onSubmit: SubmitHandler<InviteTeamMemberFormState> = (data) => {
    sendInviteMutation.mutate([
      {
        email: data.email,
        role: Roles.READ_AND_WRITE,
      },
    ]);
  };

  return (
    <>
      <div className="flex justify-center mt-6">
        <span className="mr-1">Need help connecting? </span>
        <span
          onClick={() => setShowTeamMemberInvite((value) => !value)}
          className="font-medium cursor-pointer text-tw-blue-0d"
        >
          Invite team member
        </span>
      </div>
      {showTeamMemberInvite && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="relative flex items-center justify-center mt-4 gap-x-2"
        >
          <TextInput
            placeholder="Email address"
            className="w-full py-3"
            {...register('email', {
              required: true,
              pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            })}
          />
          <SecondaryButton
            isLoading={sendInviteMutation.isLoading}
            className="flex-none w-40 py-2 text-tw-black-3"
            disabled={!email || formState.errors.email}
            bgClassName="bg-tw-gray-eb"
            fontClassName="text-sm"
          >
            Send invitation
          </SecondaryButton>
          {sendInviteMutation.isSuccess && (
            <div className="absolute flex items-center ml-8 gap-x-1 -right-28">
              <TickIconRound className="w-6 h-6 fill-current text-tw-green-39" />
              <span>Invite sent</span>
            </div>
          )}
        </form>
      )}
    </>
  );
}

const RenderAPISource = ({ tokenMutation, organizationSchemaName }) => {
  const { id: tokenId, name: tokenName, token, expiresOn } = useApiTokenStore();

  const onAPITokenSubmit = (values) => {
    tokenMutation.mutate({
      name: values.name,
      expires_on: getExpireOn(values.expiresOn),
      schemaName: organizationSchemaName,
    });
  };
  const getExpireOn = (val) => {
    if (parseInt(val)) {
      let dte = new Date();
      dte.setDate(dte.getDate() + parseInt(val));
      return dte.toISOString().split('T')[0];
    }
    return null;
  };

  return (
    <div>
      {!token && (
        <AddTokenForm emitValuesToParent={onAPITokenSubmit} isError={tokenMutation.isError} />
      )}
      {token && tokenName && tokenId && (
        <div>
          <p className={'leading-5 font-medium text-base text-tw-black-3 mb-4'}>
            Recently created token
          </p>
          <TokenDetails token={token} tokenName={tokenName} expiresOn={expiresOn} />
          <div className="my-8 border-dashed border-t-1 border-tw-gray-c" />
          <div style={{ fontWeight: 500 }}>
            For API details:
            <a
              className="ml-2 text-tw-blue-0d"
              target={'_blank'}
              href="https://docs.toplyne.io/docs/toplyne-api"
              rel="noreferrer"
            >
              Read our API documentation
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

function SourceConnect({
  source,
  title,
  twoColumnLayout,
}: {
  source: Integration;
  title?: string;
  twoColumnLayout?: boolean;
}) {
  const { control, formState, watch, handleSubmit, trigger } = useForm({ mode: 'onChange' });

  const {
    additionalStepRequired,
    setAdditionalStepRequired,
    webhookUrl,
    setWebhookUrl,
    segmentWorkspaceSources,
    setSegmentWorkspaceSources,
    webhookUrlCopied,
    setWebhookUrlCopied,
    setShowCopyWarningModal,
    setButtonLoading,
    organizationSchemaName,
    reset,
    currentSegmentPlan,
    setCurrentSegmentPlan,
  } = useActivateStore();

  const isSegment = source.type === IntegrationType.SEGMENT;

  const isWebhookSource = source.type === IntegrationType.RUDDERSTACK;

  const addIntegrationMutation = useAddIntegration();

  const tokenMutation = useAddAPIToken();

  const getSegmentSources = useSegmentSources();

  const { token } = useApiTokenStore();

  const segmentOnComplete = (val) => {
    const { name, ...rest } = val;
    addIntegrationOnSubmit(name, rest);
  };

  const addIntegrationOnSubmit = (name, rest) => {
    addIntegrationMutation.mutate(
      {
        type: source.type,
        name: name,
        integration: source.id,
        connect: {
          [source.type]: {
            ...rest,
          },
        },
        schemaName: organizationSchemaName,
      },
      {
        onSuccess: (res) => {
          if (isWebhookSource) {
            const integrationMetadataObj = res?.metadata?.[source?.type] ?? {};
            setWebhookUrl(integrationMetadataObj?.webhookUrl ?? '');
            if (source.type !== 'segment') {
              setAdditionalStepRequired({ value: true, type: source.type });
            }
          } else {
            handleSourceConnectionSuccess();
          }
        },
      }
    );
  };

  useEffect(() => {
    if (addIntegrationMutation.isLoading || tokenMutation.isLoading) {
      setButtonLoading(true);
    } else {
      setButtonLoading(false);
    }
  }, [addIntegrationMutation.isLoading]);

  const onSubmit = ({ name, ...rest }) => {
    if (additionalStepRequired.value && additionalStepRequired.type !== IntegrationType.SEGMENT) {
      if (!webhookUrlCopied) {
        setShowCopyWarningModal(true);
      } else {
        handleSourceConnectionSuccess();
      }
      return;
    }

    if (isSegment && currentSegmentPlan === 'free') {
      getSegmentSources.mutate(
        {
          type: source.type,
          name: name,
          integration: source.id,
          connect: {
            [source.type]: {
              ...rest,
            },
          },
        },
        {
          onSuccess: (res) => {
            setSegmentWorkspaceSources(res);
            setAdditionalStepRequired({ value: true, type: source.type });
          },
        }
      );
    } else {
      addIntegrationOnSubmit(name, rest);
    }
  };

  const handleSourceConnectionSuccess = () => {
    reset();
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  const getSchemaController = (param) => {
    return (
      <div key={param.name} className="mt-6">
        <label className="capitalize text-tw-black-3">{param.label}</label>
        {(param.options?.length === 0 || param.options === null) && (
          <Controller
            name={param.name}
            control={control}
            rules={{ required: true, pattern: new RegExp(param.validation.pattern) }}
            defaultValue={''}
            render={({ field }) => (
              <div>
                <TextInput
                  className="w-full py-3 mt-2"
                  autoComplete={'off'}
                  onChange={field.onChange}
                  isError={formState.dirtyFields[param.name] && formState.errors[param.name]}
                />
              </div>
            )}
          />
        )}
        {param.options?.length > 0 && (
          <Controller
            name={param.name}
            control={control}
            rules={{ required: true, pattern: new RegExp(param.validation.pattern) }}
            defaultValue={param.options[0].name}
            render={({ field }) => (
              <div className={'flex gap-y-4 mt-2 flex-wrap'}>
                {param.options.map((option, index) => (
                  <Card
                    key={option.name}
                    isActive={watch(param.name) ? watch(param?.name) === option.name : index === 0}
                    onClick={() => field.onChange(option.name)}
                    label={option.label}
                  />
                ))}
              </div>
            )}
          />
        )}
      </div>
    );
  };

  const getNameController = () => {
    return (
      <div>
        <label className="text-tw-black-3">Name your integration</label>
        <Controller
          name={'name'}
          control={control}
          rules={{ required: true }}
          defaultValue={''}
          render={({ field }) => (
            <div>
              <TextInput
                className="w-full py-3 mt-2"
                autoComplete={'off'}
                onChange={field.onChange}
                isError={formState.dirtyFields['name'] && formState.errors['name']}
              />
            </div>
          )}
        />
      </div>
    );
  };

  const getIntegrationForm = () => {
    if (isSegment) {
      return (
        <div>
          <div className="flex pt-4 text-tw-black-3">
            <div>Which Segment plan are you on?</div>
            <div className="px-1">
              <ToolTip
                label={`To know your Segment plan, login in to Segment, go to Settings -> Usage and billing -> Plans`}
              >
                <InfoAlertIcon className="w-4 h-4 mt-0.5 ml-1 fill-current text-tw-black-7" />
              </ToolTip>
            </div>
          </div>
          <DropdownPrimitive.RadioGroup className="flex my-5 gap-x-1">
            {segmentPlans.map((f: SegmentPlan) => (
              <Card
                isActive={currentSegmentPlan === f.value}
                onClick={() => {
                  setCurrentSegmentPlan(f.value);
                }}
                key={f.value}
              >
                <div className="flex items-center gap-x-2 font-normal">{f.label}</div>
              </Card>
            ))}
          </DropdownPrimitive.RadioGroup>
          {!currentSegmentPlan && (
            <InfoBanner>
              <div>
                <span>
                  {' '}
                  Segment’s historic data sync is only available for their Team/Business tier
                  customers
                </span>
                <Link
                  to={{ pathname: 'https://docs.toplyne.io/docs/historic-data-sync' }}
                  target={'_blank'}
                >
                  <div className="flex items-center font-medium group text-tw-blue-0d gap-x-1">
                    <span>Learn more</span>
                    <ExternalLink className="hidden fill-current group-hover:block" />
                  </div>
                </Link>
              </div>
            </InfoBanner>
          )}
          {currentSegmentPlan && getNameController()}
          {currentSegmentPlan &&
            (source?.formMetadata as any).map((s) =>
              s[currentSegmentPlan].map((param) => getSchemaController(param))
            )}
        </div>
      );
    } else if (!isSegment && !additionalStepRequired.value) {
      return (
        <div>
          {getNameController()}
          {source?.schema?.map((s) => getSchemaController(s))}
        </div>
      );
    }
  };

  return (
    <>
      <div className="flex h-full">
        <div
          className={classNames(
            twoColumnLayout ? 'w-3/5' : 'w-full',
            'mt-16',
            source.type === IntegrationType.SNOWFLAKE ? 'px-0' : 'px-6'
          )}
        >
          <div className={classNames(source.type === IntegrationType.SNOWFLAKE && 'px-6')}>
            <IntegrationIcon type={source.type} className="w-14 h-14" logoUrl={source?.logoUrl} />
            {!(source?.type === IntegrationType.API && token) && (
              <h2 className={classNames('mb-8 text-h2 mt-7')}>
                {title || `Setting up ${source.name} as a source`}
              </h2>
            )}
          </div>

          {source.type === IntegrationType.MIXPANEL && (
            <InfoBanner>
              You need to be on one of Mixpanel’s paid plans to use this integration
            </InfoBanner>
          )}
          {source?.name.toLowerCase() === IntegrationType.RUDDERSTACK && (
            <InfoBanner>
              <div>
                <div>
                  This guide is only applicable for incremental data that is collected post setting
                  up this integration, and will have no bearing on historical data. For sending
                  historical data to Toplyne, please refer to the
                </div>
                <a
                  className="text-tw-blue-0d"
                  target={'_blank'}
                  href="https://docs.toplyne.io/reference/getentitybyidusingget"
                  rel="noreferrer"
                >
                  API source guide
                </a>
              </div>
            </InfoBanner>
          )}
          {source?.type === IntegrationType.API && token && (
            <div className="py-6">
              <InfoBanner>
                <div>
                  Make sure to copy your recently created token. You will need it in API header. It
                  will be hidden later for security reasons
                </div>
              </InfoBanner>
            </div>
          )}
          {source.type === IntegrationType.API && (
            <RenderAPISource
              tokenMutation={tokenMutation}
              organizationSchemaName={organizationSchemaName}
            />
          )}

          <form className="mt-8" id="add-source" onSubmit={handleSubmit(onSubmit)}>
            {!additionalStepRequired.value &&
              !sourcesWithoutSchema.includes(source.type) &&
              getIntegrationForm()}
          </form>

          {additionalStepRequired.value &&
            additionalStepRequired.type === 'segment' &&
            currentSegmentPlan === 'free' && (
              <form
                className="mt-8"
                id="update-segment-source"
                onSubmit={handleSubmit(segmentOnComplete)}
              >
                {segmentWorkspaceSources && (
                  <>
                    <label className="text-tw-black-3">Select your segment source</label>
                    <Controller
                      name={'selectedSourceName'}
                      control={control}
                      rules={{ required: true }}
                      defaultValue={segmentWorkspaceSources[0]?.name}
                      render={({ field }) => (
                        <div className={'flex mt-2 gap-y-4 flex-wrap'}>
                          {segmentWorkspaceSources.map((option, index) => (
                            <Card
                              key={option.name}
                              isActive={
                                watch('selectedSourceName')
                                  ? watch('selectedSourceName') === option.name
                                  : index === 0
                              }
                              onClick={() => field.onChange(option.name)}
                              label={option.displayName}
                            />
                          ))}
                        </div>
                      )}
                    />
                  </>
                )}
              </form>
            )}
          {additionalStepRequired.value && additionalStepRequired.type !== 'segment' && (
            <div className="w-full mt-8">
              <h3 className="text-base">
                Copy this URL and add it in the connection settings of your webhook destination in
                your <span className="capitalize">{source.type}</span> account{' '}
              </h3>
              <p className="mt-6 text-tw-black-5">Webhook URL</p>
              <Copy
                copyText={true}
                className="w-full py-3 mt-3"
                value={webhookUrl}
                onClick={() => setWebhookUrlCopied(true)}
              />
              {webhookUrlCopied && (
                <p className="mt-6 text-tw-black-5">
                  Awesome! Now paste it in connection settings of your webhook destination
                </p>
              )}
            </div>
          )}
          {sourcesWithoutSchema.includes(source.type) &&
            (source.type === IntegrationType.SNOWFLAKE ||
              source.type === IntegrationType.BIGQUERY) && (
              <div>
                <SnowflakeBestPractices source={source?.name} />
                <div className="p-1 bg-tw-gray-f5">
                  <SetupGuide type={source?.type} />
                </div>
              </div>
            )}
        </div>

        {twoColumnLayout && (
          <div className="w-2/5 py-12 overflow-y-scroll bg-tw-gray-fa">
            <h3 className="px-6 text-base font-medium text-tw-black-3">Setup guide</h3>
            {!currentSegmentPlan && source.type === IntegrationType.SEGMENT && (
              <h4 className="px-6 text-tw-black-4 mt-2 font-normal">
                The setup guide depends on which Segment plan you are on
              </h4>
            )}
            <SetupGuide type={source.type} />
          </div>
        )}
      </div>
    </>
  );
}

export default function Sources() {
  const {
    source,
    setSource,
    additionalStepRequired,
    buttonLoading,
    setShowDrawer,
    showDrawer,
    showCopyWarningModal,
    setShowCopyWarningModal,
    organizationSchemaName,
    reset,
    sourceConnectionStep,
    setSourceConnectionStep,
    setShowCalendly,
    showCalendly,
    setCurrentSegmentPlan,
  } = useActivateStore();
  const { data: integrations } = useIntegrations(organizationSchemaName);
  const { connectedSource, inProgressSource } = useActivateOrganization();
  const hasSource = !!connectedSource;
  const isSourceInProgress = !!inProgressSource;
  const { token, clearTokenStore } = useApiTokenStore();
  const addIntegrationMutation = useAddIntegration();
  const { user: data } = useAuth();
  useConnectedIntegrationsWithInterval(!!inProgressSource, 5000, organizationSchemaName);
  const { track } = useAnalytics();

  // reset calendly view on mount
  useEffect(() => {
    setShowCalendly(false);
    setSourceConnectionStep(SourceConnectionSteps.select);
    clearTokenStore();
  }, []);

  const addConnectedIntegration = () => {
    addIntegrationMutation.mutate(
      {
        type: source.type,
        name: `${data?.currentOrganization?.name} x ${source?.type}`,
        integration: source.id,
        connect: {
          [source.type]: {},
        },
        schemaName: organizationSchemaName,
      },
      {
        onSuccess: () => {
          setShowDrawer(false);
        },
      }
    );
  };

  const handlePrimaryButtonClick = () => {
    if (sourceConnectionStep === SourceConnectionSteps.select) {
      setSourceConnectionStep(SourceConnectionSteps.connect);
    } else if (
      sourceConnectionStep === SourceConnectionSteps.connect &&
      sourcesWithoutSchema.includes(source.type) &&
      (source?.type === IntegrationType.API ? token : true)
    ) {
      setShowCalendly(true);
      setShowDrawer(false);
    }
  };

  const getButtonFormId = () => {
    if (sourceConnectionStep === SourceConnectionSteps.select) {
      return null;
    }
    if (additionalStepRequired.value && additionalStepRequired.type === IntegrationType.SEGMENT) {
      return 'update-segment-source';
    } else if (
      sourceConnectionStep === SourceConnectionSteps.connect &&
      source?.type === IntegrationType.API
    ) {
      return token ? null : 'create-token-form';
    } else if (sourcesWithoutSchema.includes(source.type)) {
      return null;
    } else {
      return 'add-source';
    }
  };

  return (
    <div className="flex flex-col items-center w-4/5 mt-4 text-center">
      {(showCalendly || sourcesThatRequireCall.includes(connectedSource?.type)) && (
        <div className="flex flex-col items-center justify-center w-full text-center">
          <ScheduleCallView
            source={
              sourcesThatRequireCall.includes(connectedSource?.type)
                ? connectedSource.integration
                : source
            }
            addConnectedIntegration={addConnectedIntegration}
          />
        </div>
      )}
      {hasSource &&
        !isSourceInProgress &&
        !sourcesThatRequireCall.includes(connectedSource?.type) && (
          <DataSyncView type={DataSyncViewType.sync} name={connectedSource?.integration.name} />
        )}
      {!hasSource && !isSourceInProgress && !showCalendly && (
        <>
          <h2 className="text-h2">Connect your product analytics source</h2>
          <p className="mt-2.5 text-tw-black-9">
            We analyse your product usage data to come up with meaningful segments for your
            different growth goals
          </p>
          <PrimaryButton onClick={() => setShowDrawer(true)} className="w-2/3 mt-11">
            Connect
          </PrimaryButton>
          <TeamMemberInvite />
        </>
      )}
      {!hasSource && isSourceInProgress && (
        <DataSyncView type={DataSyncViewType.connect} name={inProgressSource?.integration.name} />
      )}
      <Drawer
        widthClassName={
          sourceConnectionStep === SourceConnectionSteps.connect &&
          (source.type === IntegrationType.API ||
            readonlyGuidesIntegrationTypes.includes(source?.type))
            ? 'w-1/2'
            : 'w-4/5'
        }
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        primaryButton={
          <PrimaryButton
            form={getButtonFormId()}
            onClick={handlePrimaryButtonClick}
            disabled={!source}
            className="w-60"
            isLoading={buttonLoading}
          >
            Proceed
          </PrimaryButton>
        }
        secondaryButton={
          sourceConnectionStep === SourceConnectionSteps.connect && (
            <SecondaryButton
              className="w-60 text-tw-blue-0d"
              onClick={() => {
                if (hasSource) {
                  setShowDrawer(false);
                } else {
                  setCurrentSegmentPlan('');
                  setSourceConnectionStep(SourceConnectionSteps.select);
                }
              }}
            >
              Go back
            </SecondaryButton>
          )
        }
        paddingX="px-0"
      >
        {sourceConnectionStep === SourceConnectionSteps.select && (
          <div className="flex flex-col justify-between h-full">
            <div className="px-8 mt-14">
              <div>
                <h2 className="text-h2">Connect your product analytics source</h2>
                <p className="mt-3 text-tw-black-9">
                  We analyse your product usage data to come up with meaningful segments for your
                  different growth goals
                </p>
              </div>
              {!!integrations?.length && (
                <div className="grid grid-cols-3 gap-4 mt-7">
                  {integrations
                    .filter(
                      (i) => (i.supportsRead && !i.supportsWrite) || i.type === IntegrationType.API
                    )
                    .map((i) => {
                      return (
                        <Card
                          key={i.id}
                          onClick={() => setSource(i)}
                          isActive={source?.id === i.id}
                          cardClassname="py-10 mr-0"
                        >
                          <div className="flex items-center gap-x-4">
                            <IntegrationIcon className="w-8" type={i.type} logoUrl={i.logoUrl} />
                            <p className="text-base">{i.name}</p>
                          </div>
                        </Card>
                      );
                    })}
                </div>
              )}
              <div className="flex items-center mt-8 text-tw-black-7 gap-x-1">
                <span>Can’t find your product analytics source here?</span>
                <button
                  onClick={() => track(AnalyticsEvents.SOURCE_NOT_FOUND)}
                  className="font-medium text-tw-blue-0d"
                >
                  Let us know
                </button>
              </div>
            </div>
            <div className="flex items-center w-full py-6 border border-b-0 bg-tw-green-f1 gap-x-4 px-7 rounded-t-3xl border-tw-gray-f5">
              <LockOutlineIcon className="w-8 fill-current text-tw-green-28" />
              <span className="text-base font-medium text-tw-green-28">
                Your data is safe with us
              </span>
              <div className="flex items-center px-2 py-3 bg-white rounded-lg gap-x-2">
                <GDPRIcon />
                <span>GDPR COMPLIANT</span>
              </div>
              <div className="flex items-center px-2 py-3 bg-white rounded-lg gap-x-2">
                <SOC2Icon />
                <span>SOC 2 COMPLIANT</span>
              </div>
            </div>
          </div>
        )}
        {sourceConnectionStep === SourceConnectionSteps.connect && (
          <SourceConnect
            source={source}
            twoColumnLayout={!sourcesWithoutSchema.includes(source?.type)}
            title={
              source.type === IntegrationType.API
                ? 'Generate token to access the Toplyne API to send event data and user profile data to Toplyne'
                : ''
            }
          />
        )}
      </Drawer>
      {/* Warning modal for Webhook sources */}
      <WarningModal
        flagToShowModal={showCopyWarningModal}
        setFlagToShowModal={setShowCopyWarningModal}
        handleSubmit={reset}
        heading="Forgot to copy the webhook URL?"
        subHeading={`The integration will not be successful unless you copy the webhook URL and add it in your webhook destination in your ${source?.name} account`}
        buttonCopy="I have copied the URL"
      />
    </div>
  );

  function DataSyncView({ type, name }: { type: DataSyncViewType; name: string }) {
    return (
      <>
        <Lottie
          animationData={dataSyncAnimation}
          loop={true}
          autoplay={true}
          style={{
            height: 150,
            width: 150,
          }}
        />
        <h2 className="text-h2">
          {type === DataSyncViewType.sync
            ? `Importing your data from ${name}...`
            : `Connecting to your ${name} account...`}
        </h2>
        <p className="mt-4 text-tw-black-9">
          {type === DataSyncViewType.sync
            ? `Keep an eye on your inbox! We will send you an email once the import is complete.
          Depending on the volume of your data, this might might take upto 48 hours`
            : `We are connecting Toplyne to your ${name} account. This can take a few minutes`}
        </p>
      </>
    );
  }
}

function ScheduleCallView({
  source,
  addConnectedIntegration,
}: {
  source: Integration;
  addConnectedIntegration: () => void;
}) {
  const { user } = useAuth();
  const [showCalendlyEmbed, setShowCalendlyEmbed] = useState(false);
  const { track } = useAnalytics();
  const updateOrganization = useUpdateOrganization();
  const { organization } = useActivateOrganization();
  const [guideOpen, setGuideOpen] = useState(false);
  const { setSource, setShowDrawer, setSourceConnectionStep } = useActivateStore();
  const { clearTokenStore } = useApiTokenStore();

  return (
    <>
      <div className="flex items-center w-full">
        <span className="grid w-12 h-12 rounded-full shadow-lg place-items-center">
          <IntegrationIcon type={source.type} logoUrl={source?.logoUrl} className="w-6" />
        </span>
        <span className="flex-grow border-b border-tw-gray-eb" />
        <CalendarColorIcon />
        <span className="flex-grow border-b border-tw-gray-eb" />
        <span className="grid w-12 h-12 rounded-full shadow-lg place-items-center">
          <ToplyneLogoBlue className="w-6" />
        </span>
      </div>
      <h2 className="mt-8 text-h2">
        {organization?.callScheduled
          ? `Meeting booked to complete ${source.name} integration`
          : `Book a 20 min slot with us`}
      </h2>
      {!showCalendlyEmbed && (
        <>
          <p className="w-11/12 mt-4 text-tw-black-9">
            {organization.callScheduled
              ? `We’ll see you soon! In the mean time, why don’t you play around with your Toplyne test dashboard?`
              : `We’d like to help you set up the final details of your ${source.name}
            integration. Schedule a session with our product expert now!`}
          </p>
          {!organization?.callScheduled && (
            <PrimaryButton onClick={() => setShowCalendlyEmbed(true)} className="mt-11 w-80">
              Get a calendar slot
            </PrimaryButton>
          )}
        </>
      )}
      {showCalendlyEmbed && (
        <div className="w-full mt-5">
          <CalendlyEventListener
            onEventScheduled={() => {
              track(AnalyticsEvents.CALL_SCHEDULED, {
                [AnalyticsConst.INTEGRATION_NAME]: source.name,
              });
              updateOrganization.mutate(
                {
                  schemaName: organization.schemaName,
                  callScheduled: true,
                },
                {
                  onSuccess: () => {
                    setShowCalendlyEmbed(false);
                    if (sourcesWithoutSchema.includes(source.type)) {
                      addConnectedIntegration();
                    }
                  },
                }
              );
            }}
          >
            <InlineWidget
              styles={{
                height: '820px',
              }}
              url={`${process.env.REACT_APP_CALENDLY_URL}?embed_domain=1`}
              prefill={{
                name: `${user?.firstName || ''} ${user?.lastName || ''}`,
                email: user?.email,
              }}
              pageSettings={{
                hideEventTypeDetails: true,
                hideGdprBanner: true,
              }}
            />
          </CalendlyEventListener>
        </div>
      )}
      {source.type === IntegrationType.API && (
        <div className="flex justify-between w-full mx-8 mt-9">
          <a
            className="font-medium text-tw-blue-0d"
            target={'_blank'}
            href="https://docs.toplyne.io/docs/toplyne-api"
            rel="noreferrer"
          >
            Read Toplyne API documentation
          </a>

          <button
            onClick={() => {
              setSource(source);
              setSourceConnectionStep(SourceConnectionSteps.connect);
              clearTokenStore();
              setShowDrawer(true);
            }}
            className="font-medium text-tw-blue-0d"
          >
            Create a new API token
          </button>
        </div>
      )}
      {source.type !== IntegrationType.API && (
        <div
          className={classNames(
            'flex w-full mx-8 mt-9',
            !sourcesWithSchemaAndRequiresCall.includes(source.type)
              ? 'justify-between'
              : 'justify-center'
          )}
        >
          <button onClick={() => setGuideOpen(true)} className="font-medium text-tw-blue-0d">
            See {source.name} integration guide
          </button>
          {!sourcesWithSchemaAndRequiresCall.includes(source.type) && (
            <button
              onClick={() => {
                setSourceConnectionStep(SourceConnectionSteps.select);
                setShowDrawer(true);
              }}
              className="font-medium text-tw-blue-0d"
            >
              Connect to a different source
            </button>
          )}
        </div>
      )}
      <Drawer open={guideOpen} hideButtons={true} onClose={() => setGuideOpen(false)}>
        <div className="py-8">
          {readonlyGuidesIntegrationTypes.includes(source?.type) && (
            <div>
              <SnowflakeBestPractices />
              <div className="p-1 bg-tw-gray-f5">
                <SetupGuide type={source?.type} />
              </div>
            </div>
          )}
          {!readonlyGuidesIntegrationTypes.includes(source?.type) && (
            <SetupGuide type={source.type} />
          )}
        </div>
      </Drawer>
    </>
  );
}
