import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';
import { useMutation, useQueryClient } from 'react-query';
import { toast as toastify } from 'react-toastify';
import { useClient } from '../../../api-client';

export default function useCancelInvite() {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation((id: number | string) => client.cancelInvite(id), {
    onSuccess: () => {
      toastify(<SuccessToast description="The invite has been cancelled" />, {
        type: 'success',
      });
      queryClient.refetchQueries(['pendingInvites']);
    },
    onError: (error) => {
      const err = error['response']['data']['detail'];
      toastify(<ErrorToast description={err ?? 'There was an error in cancelling the invite'} />, {
        type: 'error',
      });
    },
  });
}
