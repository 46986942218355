import { AnimatePresence, motion } from 'framer-motion';
import useEntitiesQuery from 'hooks/useEntitiesQuery';
import { noop } from 'lodash';
import { getCohortIconAndColor } from 'pages/pql-workflow/components/define-pql/Cohort';
import PersonaGrid from 'pages/pql-workflow/components/define-pql/PersonaGrid';
import RFACompareTable from 'pages/pql-workflow/components/define-pql/RFACompareTable';
import { CohortsResponse } from 'pages/pql-workflow/hooks/useCohorts';
import { Entity, FilterExpression } from 'pages/users-and-accounts/users/types';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { QueryType } from 'stores/QueryStore';
import { CrossIcon, InfoAlertIcon } from 'ui-components/data-display/Icons';
import { classNames } from 'utils/common';
import Drawer from '../../../ui-components/navigation/Drawer';
import { ColumnType } from '../store/AccountsStore';
import useAllCohorts from '../../../hooks/useAllCohorts';

const COHORT_SORT_ORDER = [
  'New users',
  'Promising users',
  'Champion users',
  'Needs attention',
  'About to sleep',
  'Hibernating users',
];

const COHORT_SORT_ORDER_NEW = [
  'New users',
  'Casual users',
  'Potential Power users',
  'Power users',
  'Dormant users',
];

type CohortsPanelProps = {
  filters: Array<FilterExpression>;
  setFilters: (filters) => void;
  entityType: QueryType;
  addFieldMapping: (obj: ColumnType) => void;
  fieldMappings: Record<string, ColumnType>;
};

const CohortsPanel = ({
  filters,
  setFilters,
  entityType,
  addFieldMapping,
  fieldMappings,
}: CohortsPanelProps) => {
  const { data: entitiesQueryData } = useEntitiesQuery(entityType.toLowerCase() as Entity);

  const [cohortInfoOpen, setCohortInfoOpen] = useState<boolean>(false);
  const { isLoading: loadingCohorts, cohortItems, allUsersCohortItem } = useAllCohorts();

  useEffect(() => {
    // sort cohortItems
    if (cohortItems?.length) {
      let sortOrder = COHORT_SORT_ORDER;

      // Hack to find if the org is using old persona or persona 1.5
      if (
        cohortItems?.length &&
        cohortItems.find(
          (cohort) =>
            cohort?.name.toLowerCase() === 'power users' ||
            cohort?.name.toLowerCase() === 'power accounts'
        )
      )
        sortOrder = COHORT_SORT_ORDER_NEW;

      cohortItems.sort(function (a, b) {
        return sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name);
      });
    }
  }, [cohortItems]);

  // either users store or accounts store.

  const isActiveCohortFilter = useCallback(
    (cohort: CohortsResponse): boolean => {
      const cohortFilterAlreadyApplied = filters.find(
        (f) => f.columnName === allUsersCohortItem?.filters[0].columnName
      );

      if (cohortFilterAlreadyApplied) {
        return (cohortFilterAlreadyApplied?.value as string).includes(cohort.filters[0]?.value[0])
          ? true
          : false;
      } else {
        return false;
      }
    },
    [allUsersCohortItem?.filters, filters]
  );

  const macroPersonaFilter = useMemo(
    () =>
      entitiesQueryData?.find((d) => d.columnName === allUsersCohortItem?.filters[0].columnName),
    [allUsersCohortItem, entitiesQueryData]
  );

  const showNewPersonas =
    cohortItems?.length &&
    !!cohortItems.find(
      (cohort) =>
        cohort?.name.toLowerCase() === 'power users' ||
        cohort?.name.toLowerCase() === 'power accounts'
    );

  return (
    <div className="product-tour-cohorts-panel">
      <div className="flex items-center w-full">
        <div>Select a Persona</div>
        <div
          className="px-1 cursor-pointer"
          onClick={() => {
            setCohortInfoOpen(true);
          }}
        >
          <InfoAlertIcon className="w-4 h-4 ml-1 fill-current text-tw-black-7" />
        </div>
      </div>
      {!loadingCohorts ? (
        <div className="flex flex-wrap-reverse my-3">
          {[...(cohortItems || []).filter((c) => c.id !== allUsersCohortItem.id)]?.map(
            (cohortToDisplay: CohortsResponse & { isRecommended: boolean }, index: number) => {
              return (
                <div
                  key={cohortToDisplay.id}
                  className={classNames(
                    index !== 0 ? 'mx-2' : 'mr-2',
                    'mb-2 rounded-3xl py-2 px-3 flex items-center cursor-pointer',
                    isActiveCohortFilter(cohortToDisplay)
                      ? 'border-1 border-tw-blue-0d bg-tw-blue-f2 text-tw-black-5 font-medium'
                      : 'border-1 border-tw-gray-eb font-medium text-tw-black-7'
                  )}
                  onClick={() => {
                    const currentFilter = cohortToDisplay.filters[0];
                    const filterAlreadyApplied = filters?.find(
                      (f) => f.columnName === currentFilter.columnName
                    );

                    // check if cohort is present in macro_persona filters.
                    if (filterAlreadyApplied) {
                      let payload: string[] = filterAlreadyApplied.value as Array<string>;

                      if (payload.includes(currentFilter.value[0])) {
                        noop();
                      } else {
                        payload = [...payload, currentFilter.value[0]];
                      }

                      setFilters([
                        ...filters.filter(
                          (f) => f.columnName !== allUsersCohortItem?.filters[0]?.columnName
                        ),
                        {
                          ...currentFilter,
                          value: payload,
                        },
                      ]);
                    } else {
                      // apply the filter first then update the payload
                      setFilters([
                        ...filters.filter(
                          (f) => f.columnName !== allUsersCohortItem?.filters[0]?.columnName
                        ),
                        {
                          ...currentFilter,
                          value: currentFilter.value,
                        },
                      ]);
                    }

                    // add the column.
                    if (!fieldMappings[currentFilter.keyName] && macroPersonaFilter) {
                      addFieldMapping({
                        source: macroPersonaFilter.source,
                        keyName: macroPersonaFilter.keyName,
                        keySpace: macroPersonaFilter.keySpace,
                        columnName: macroPersonaFilter.columnName,
                        keyAlias: macroPersonaFilter.keyName,
                        isSystem: macroPersonaFilter.isSystem,
                        dataType: macroPersonaFilter.dataType,
                        displayName: macroPersonaFilter.displayName,
                      });
                    }
                  }}
                >
                  {getCohortIconAndColor(cohortToDisplay.name, 24, 24).icon}
                  <div className="ml-1 text-tw-black-7">
                    {entityType === QueryType.accounts
                      ? cohortToDisplay.name.replaceAll('Users', 'Accounts')
                      : cohortToDisplay.name}
                  </div>
                  <motion.div
                    className="pt-0.5 h-full grid place-items-center"
                    initial={{ width: 0 }}
                    animate={
                      isActiveCohortFilter(cohortToDisplay)
                        ? {
                            paddingLeft: '8px',
                            width: '16px',
                            transition: { ease: 'easeInOut', duration: 0.4, repeat: 0 },
                          }
                        : {
                            paddingLeft: '0px',
                            width: 0,
                            transition: { ease: 'easeInOut', duration: 0.4 },
                          }
                    }
                    key="cross-width"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const currentFilter = cohortToDisplay.filters[0];
                      const filterAlreadyApplied = filters?.find(
                        (f) => f.columnName === currentFilter.columnName
                      );

                      if ((filterAlreadyApplied.value as Array<string>).length > 1) {
                        setFilters([
                          ...filters.filter(
                            (f) => f.columnName !== allUsersCohortItem?.filters[0]?.columnName
                          ),
                          {
                            ...currentFilter,
                            value: (filterAlreadyApplied.value as Array<string>).filter(
                              (val) => val !== currentFilter.value[0]
                            ),
                          },
                        ]);
                      } else {
                        // remove macro_persona filter
                        setFilters([
                          ...filters.filter(
                            (f) => f.columnName !== allUsersCohortItem?.filters[0]?.columnName
                          ),
                        ]);
                      }
                    }}
                  >
                    <AnimatePresence exitBeforeEnter>
                      {isActiveCohortFilter(cohortToDisplay) && (
                        <motion.div
                          initial={{ display: 'none' }}
                          animate={{ display: 'block' }}
                          transition={{ ease: 'easeInOut', delay: 0.4, duration: 0.2, repeat: 0 }}
                          key="cross-opacity"
                        >
                          <CrossIcon className="w-2 h-2 fill-current text-tw-black-3" />
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.div>
                </div>
              );
            }
          )}
        </div>
      ) : (
        <div className="flex flex-wrap-reverse w-full my-6 animate-pulse">
          {Array(4)
            .fill(0)
            .map((v, index) => {
              return (
                <div
                  key={index}
                  className={classNames(
                    'mr-2 rounded-3xl py-2 px-3 flex items-center h-[42px] bg-tw-gray-eb w-32',
                    index !== 0 ? 'mx-2' : 'mr-2'
                  )}
                ></div>
              );
            })}
        </div>
      )}
      {/* persona drawers */}
      <Drawer
        widthClassName="w-2/3"
        open={cohortInfoOpen && !showNewPersonas}
        onClose={() => setCohortInfoOpen(false)}
      >
        <h2 className="text-xl font-medium mt-11 text-tw-black-3">
          Here is how personas compare against one another
        </h2>
        <RFACompareTable />
      </Drawer>

      <Drawer
        widthClassName="w-2/3"
        open={cohortInfoOpen && showNewPersonas}
        onClose={() => setCohortInfoOpen(false)}
        hideButtons={true}
        paddingX="px-0"
      >
        <h2 className="py-4 pb-4 mb-8 text-xl font-medium border-b border-dashed text-tw-black-3 border-tw-gray-eb">
          <div className="px-6">Let’s understand personas</div>
        </h2>
        <div className="px-6">
          <PersonaGrid />
        </div>
      </Drawer>
    </div>
  );
};

export default memo(CohortsPanel);
