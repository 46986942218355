export const enum Roles {
  READ_ONLY = 'read_only',
  READ_AND_WRITE = 'read_and_write',
  ADMIN = 'admin',
}

export const RoleAccessColumns = [
  {
    Header: 'Action',
    accessor: 'action',
    Cell: ({ row }: { row: any }) => `${row.values['action']}`,
  },
  {
    Header: 'Read only access',
    accessor: 'readOnlyAccess',
    Cell: ({ row }: { row: any }) => (row.values['readOnlyAccess'] ? 'Yes' : '-'),
  },
  {
    Header: 'Read and Write Access',
    accessor: 'readAndWriteAccess',
    Cell: ({ row }: { row: any }) => (row.values['readAndWriteAccess'] ? 'Yes' : '-'),
  },
  {
    Header: 'Admin Access',
    accessor: 'adminAccess',
    Cell: ({ row }: { row: any }) => (row.values['adminAccess'] ? 'Yes' : '-'),
  },
];

export type RolesData = {
  action: string;
  readOnlyAccess: boolean;
  readAndWriteAccess: boolean;
  adminAccess: boolean;
};

export const RoleAccessData = [
  {
    action: 'Create/Edit playbook',
    readOnlyAccess: false,
    readAndWriteAccess: true,
    adminAccess: true,
  },
  {
    action: 'Create/Edit Integrations',
    readOnlyAccess: false,
    readAndWriteAccess: true,
    adminAccess: true,
  },
  {
    action: 'Add/Delete team members',
    readOnlyAccess: false,
    readAndWriteAccess: true,
    adminAccess: true,
  },
  {
    action: 'View playbook performance analytics',
    readOnlyAccess: true,
    readAndWriteAccess: true,
    adminAccess: true,
  },
  {
    action: 'Manage Billing',
    readOnlyAccess: false,
    readAndWriteAccess: false,
    adminAccess: true,
  },
];

export const roleOptions = [
  { label: 'Admin', value: Roles.ADMIN },
  { label: 'Read and Write', value: Roles.READ_AND_WRITE },
  { label: 'Read Only', value: Roles.READ_ONLY },
];
