import useModels from 'hooks/useModels';
import { QueryType } from 'stores/QueryStore';

export default function useDsEnabled(modelId: string, targetEntity: QueryType | '') {
  const { data } = useModels();

  if (!data) {
    return false;
  }

  return data?.find(
    (model) => model.modelId === modelId && model.entity === targetEntity.toLocaleLowerCase()
  )?.dsEnabled;
}
