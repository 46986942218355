import create from 'zustand';
import { type Dayjs } from 'dayjs';
import { devtools } from 'zustand/middleware';

export enum ExportPQLSections {
  DESTINATION = 'destination',
  MAPPINGS = 'mappings',
  SCHEDULE = 'schedule',
}

export const ExportSectionsMap: {
  [key in ExportPQLSections]: boolean;
} = {
  [ExportPQLSections.DESTINATION]: false,
  [ExportPQLSections.MAPPINGS]: false,
  [ExportPQLSections.SCHEDULE]: false,
};

type Mapping = {
  source: string;
  destination: string;
  required?: boolean;
  sourceDtype?: string;
  destinationDtype?: string;
  destinationLabel?: string;
};

type Tag = {
  name: string;
  value: string;
};

export type Cadence = {
  type: CadenceFrequency;
  hourlyValue?: Array<string>;
  weeklyValues?: Array<string>;
  monthlyValues?: Array<string>;
};

export enum CadenceFrequency {
  NULL = '',
  HOURLY = 'hourly',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export enum OneTimeType {
  NOW = 'now',
  TIME = 'time',
}

export type Schedule = {
  type: ScheduleType;
  startDate: Dayjs;
  cadence: Cadence;
  time: Dayjs;
  oneTimeType: OneTimeType;
};

export type ExtraFormData = {
  [key: string]: string | undefined | null;
};

export enum ScheduleType {
  ONETIME = 'onetime',
  RECURRING = 'recurring',
}

type ExportPQLState = {
  currentSection: ExportPQLSections;
  sectionsMap: { [key in ExportPQLSections]: boolean };
  destinationSearchQuery: string;
  destinationId: number;
  extraFormData?: ExtraFormData;
  mappings: Array<Mapping>;
  tags: Array<Tag>;
  object: string;
  subObject?: string;
  subObjectFields?: Array<object>;
  uniqueKeys: Array<{
    source: string;
    destination: string;
    sourceDtype?: string;
    destinationDtype?: string;
    destinationLabel?: string;
  }>;
  schedule: Schedule;
  name: string;
  emails: Array<string>;
  syncOverviewOpen: boolean;
  showConnectSection?: boolean;
  showDrawer?: boolean;
  activeIntegrationTab?: string;
};

type ExportPQLStore = {
  setCurrentSection: (section: ExportPQLSections) => void;
  setSectionsMap: (sectionsMap: { [key: string]: boolean }) => void;
  setDestinationSearchQuery: (query: string) => void;
  setDestinationId: (id: number) => void;
  setExtraFormData: (extraFormData: ExtraFormData) => void;
  setMappings: (mappings: Mapping[]) => void;
  setTags: (tags: Tag[]) => void;
  setObject: (object: string) => void;
  setSubObject: (subObject: string) => void;
  setSubObjectFields: (subObjectFields: Array<object>) => void;
  setUniqueKeys: (uniqueKeys: { source: string; destination: string }[]) => void;
  setSchedule: (schedule: Partial<Schedule>) => void;
  setName: (name: string) => void;
  setEmails: (emails: Array<string>) => void;
  setSyncOverviewOpen: (syncOverviewOpen: boolean) => void;
  setShowConnectSection?: (showConnectSection: boolean) => void;
  setShowDrawer?: (showConnectSection: boolean) => void;
  setActiveIntegrationTab?: (integrationName: string) => void;
  reset: () => void;
  hydrate: (state: ExportPQLState) => void;
} & ExportPQLState;

export const scheduleInitialState: Schedule = {
  type: null,
  startDate: null,
  cadence: {
    type: CadenceFrequency.HOURLY,
    hourlyValue: ['1'],
    weeklyValues: [],
    monthlyValues: [],
  },
  time: null,
  oneTimeType: OneTimeType.NOW,
};

const initialState: ExportPQLState = {
  currentSection: ExportPQLSections.DESTINATION,
  sectionsMap: ExportSectionsMap,
  destinationSearchQuery: '',
  destinationId: null,
  extraFormData: {},
  mappings: [],
  tags: [],
  object: '',
  subObject: null,
  subObjectFields: null,
  uniqueKeys: [
    {
      source: '',
      destination: '',
    },
  ],
  schedule: scheduleInitialState,
  name: '',
  emails: [],
  syncOverviewOpen: false,
  showConnectSection: false,
  activeIntegrationTab: 'Email',
};

export const useExportPQLStore = create<ExportPQLStore>(
  devtools((set) => {
    return {
      ...initialState,
      setCurrentSection: (section: ExportPQLSections) => {
        set((state) => ({
          ...state,
          currentSection: section,
        }));
      },
      setSectionsMap: (sectionsMap: { [key in ExportPQLSections]: boolean }) => {
        set((state) => ({
          ...state,
          sectionsMap,
        }));
      },
      setDestinationSearchQuery: (query: string) => {
        set((state) => ({
          ...state,
          destinationSearchQuery: query,
        }));
      },
      setDestinationId: (id: number) => {
        set((state) => ({
          ...state,
          destinationId: id,
        }));
      },
      setExtraFormData: (extraFormData: ExtraFormData) => {
        set((state) => ({
          ...state,
          extraFormData,
        }));
      },
      setMappings: (mappings: Mapping[]) => {
        set((state) => ({
          ...state,
          mappings,
        }));
      },
      setTags: (tags: Tag[]) => {
        set((state) => ({
          ...state,
          tags,
        }));
      },
      setObject: (object: string) => {
        set((state) => ({
          ...state,
          object,
        }));
      },
      setSubObject: (subObject: string) => {
        set((state) => ({
          ...state,
          subObject,
        }));
      },
      setSubObjectFields: (subObjectFields: Array<object>) => {
        set((state) => ({
          ...state,
          subObjectFields,
        }));
      },
      setUniqueKeys: (uniqueKeys: [{ source: string; destination: string }]) => {
        set((state) => ({
          ...state,
          uniqueKeys,
        }));
      },

      setSchedule: (schedule: Partial<Schedule>) => {
        set((state) => ({
          ...state,
          schedule: {
            ...state.schedule,
            ...schedule,
          },
        }));
      },
      setName: (name: string) => {
        set((state) => ({
          ...state,
          name,
        }));
      },
      setEmails: (emails: Array<string>) => {
        set((state) => ({
          ...state,
          emails,
        }));
      },
      setSyncOverviewOpen: (syncOverviewOpen: boolean) => {
        set((state) => ({
          ...state,
          syncOverviewOpen,
        }));
      },
      setShowConnectSection: (showConnectSection: boolean) => {
        set((state) => ({
          ...state,
          showConnectSection,
        }));
      },
      setShowDrawer: (showDrawer: boolean) => {
        set((state) => ({
          ...state,
          showDrawer,
        }));
      },
      setActiveIntegrationTab: (integrationName: string) => {
        set((state) => ({
          ...state,
          activeIntegrationTab: integrationName,
        }));
      },
      reset: () => {
        set(() => ({
          ...initialState,
        }));
      },
      hydrate: (state: ExportPQLState) => {
        set(() => ({
          ...state,
        }));
      },
    };
  })
);
