import { LoggedInUser, useClient } from 'api-client';
import { useAuth } from 'hooks/useAuth';
import { useAuthStore } from 'hooks/useAuthStore';
import { useMutation, useQueryClient } from 'react-query';

export default function useCreateSandbox() {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { setAccountId } = useAuthStore();
  const client = useClient();
  return useMutation((data: object) => client.createSandbox(data), {
    onSuccess: async (data) => {
      await queryClient.cancelQueries('user');
      if (data.isSandbox) {
        setAccountId(data.orgId, data.schemaName);
      }
      queryClient.setQueryData(
        ['user', user?.currentOrganization?.orgId],
        (oldData: LoggedInUser) => {
          return {
            ...oldData,
            organizations: [...oldData.organizations, data],
          };
        }
      );
    },
    onSettled: () => {
      queryClient.refetchQueries('user');
    },
  });
}
