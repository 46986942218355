import {
  FilterGlobeIcon,
  FilterLanguageIcon,
  FilterUsersIcon,
} from 'ui-components/data-display/Icons';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

const FiltersText = [
  {
    description: 'Qualify people who are in United states and Canada',
    icon: <FilterGlobeIcon />,
    color: 'rgb(101, 98, 241)',
  },
  {
    description: 'Qualify users who are Activated',
    icon: <FilterUsersIcon />,
    color: 'rgb(111, 73, 134)',
  },
  {
    description: 'Qualify people who speak English',
    icon: <FilterLanguageIcon />,
    color: 'rgb(229, 74, 133)',
  },
];

export default function QualifyingTextAnimated() {
  const [activeAnimationIndex, setActiveAnimationIndex] = useState(0);
  useEffect(() => {
    const timerId = setTimeout(() => {
      setActiveAnimationIndex((activeAnimationIndex + 1) % FiltersText.length);
    }, 2000);

    return () => clearTimeout(timerId);
  }, [activeAnimationIndex]);
  return (
    <div className="relative flex justify-center">
      <AnimatePresence exitBeforeEnter>
        {FiltersText.map((textItem, index) => {
          if (activeAnimationIndex === index) {
            return (
              <motion.div
                key={textItem.description}
                initial={{ y: 15, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -15, opacity: 0 }}
                transition={{
                  duration: 0.4,
                  ease: 'easeOut',
                }}
                className="absolute flex items-center justify-center overflow-hidden italic h-14 w-full"
              >
                <div
                  className="p-2 rounded-full"
                  style={{
                    background: `rgba(${textItem.color.split('(')[1].replace(')', '')}, 0.1)`,
                  }}
                >
                  {textItem.icon}
                </div>
                <div style={{ color: textItem.color }} className="ml-2 font-medium">
                  {textItem.description}
                </div>
              </motion.div>
            );
          }
          return null;
        })}
      </AnimatePresence>
    </div>
  );
}
