import { Ellipse1, Ellipse2, Ellipse3 } from 'ui-components/business/Backgrounds';

type SectionHeaderProps = {
  number: number;
  title: string;
};

export default function SectionHeader({ number, title }: SectionHeaderProps) {
  return (
    <div className="relative flex items-center w-full h-24 p-8 text-2xl font-bold bg-cover overflow-clip bg-section-header rounded-t-xl text-tw-white-ff shadow-3xl drop-shadow-3xl">
      <Ellipse1 className="absolute bottom-0 left-0" />
      <Ellipse2 className="absolute top-0 right-1/3" />
      <Ellipse3 className="absolute right-0" />
      {number && (
        <span className="text-tw-white-ff/[0.65] rounded-full bg-tw-black-9/[0.3] px-2.5 py-1 text-xs">
          {number}
        </span>
      )}
      <div className="ml-4 text-tw-white-ff/[0.4]">{title}</div>
    </div>
  );
}
