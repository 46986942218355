import { ActivationStatus, useClient } from 'api-client';
import { useMutation } from 'react-query';

export default function useModifyModelActivationStatus(activationStatus: ActivationStatus) {
  const client = useClient();

  return useMutation(({ modelId }: { modelId: string }) =>
    client.modifyModelActivationStatus(modelId, activationStatus)
  );
}
