import { KeyIcon, ToplyneLogo } from 'ui-components/data-display/Icons';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { PrimaryButton } from '../../ui-components/inputs/Buttons';
import { TextInput } from '../../ui-components/inputs/Inputs';
import { emailRegExp } from '../../utils/common';
import useSendPasswordResetEmail from './hooks/useSendPasswordResetEmail';

interface ResetPasswordProps {}

export type ResetPasswordState = {
  email: string;
};

const ResetPassword: FC<ResetPasswordProps> = () => {
  const sendPasswordResetEmailHook = useSendPasswordResetEmail();

  const onSubmit: SubmitHandler<ResetPasswordState> = (data) => {
    sendPasswordResetEmailHook.mutate(data);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <div className="h-screen py-4 pl-4" data-testid="forgot-password-component">
      <ToplyneLogo className="h-auto w-36" />
      <div className="my-4 h-88">
        <div className="w-36% mx-auto rounded-lg border-1 border-tw-gray-eb px-12 pt-12 h-full">
          <div className="d-flex justify-content-center">
            <KeyIcon />
          </div>
          <div className="mt-6">
            <div className="text-center">
              <h3 className="text-3xl font-medium text-tw-black-3">Reset password</h3>
              <p className="mt-2 text-sm text-tw-black-5">
                Enter your email associated with the account
              </p>
            </div>

            <form className="w-3/4 mx-auto mt-8" onSubmit={handleSubmit(onSubmit)}>
              <label htmlFor="email" className={'font-normal text-sm text-tw-black-5 mb-2'}>
                Email
              </label>
              <TextInput
                data-testid="email-input"
                className="w-full py-3"
                autoFocus
                {...register('email', {
                  required: true,
                  pattern: emailRegExp,
                })}
                isError={errors.email}
              />
              {errors.email && errors.email.type === 'pattern' && (
                <span className="mt-2 text-xs text-tw-red-dd" data-testid="errorMessage">
                  This is not a valid email
                </span>
              )}
              <PrimaryButton
                type="submit"
                className="w-full py-3 mt-8 uppercase"
                disabled={sendPasswordResetEmailHook.isLoading}
                data-testid="submitButton"
              >
                Send password reset link
              </PrimaryButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
