import PQLMetadata from '../PQLMetadata';
import PQLFilters from '../PQLFilters';
import { classNames } from 'utils/common';
import { PQL_STATS_PAGE_PADDING_CLASSNAME } from '../NewOpDetails';

export default function Abouts(props) {
  const { opportunity } = props;
  return (
    <div className={classNames('w-full z-5 h-100', PQL_STATS_PAGE_PADDING_CLASSNAME)}>
      <PQLMetadata opportunity={opportunity} />
      <PQLFilters opportunity={opportunity} />
    </div>
  );
}
