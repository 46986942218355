import { EmptyFolder } from 'ui-components/data-display/Icons';
import { SecondaryButton } from 'ui-components/inputs/Buttons';
import { classNames, getTableCell, updateCellWidthMap } from 'utils/common';
import { UserFields } from 'pages/users-and-accounts/fields';
import { useAuth } from 'hooks/useAuth';
import { useSyncScroller } from 'hooks/useSyncScroll';
import isEmpty from 'lodash/isEmpty';
import ColumnActions, {
  SortColumnFilters,
} from 'pages/users-and-accounts/components/ColumnActions';
import UserAccountsTable from 'pages/users-and-accounts/components/UserAccountsTable';
import { useUsersStore } from 'pages/users-and-accounts/store/UsersStore';
import { useEffect, useLayoutEffect, useMemo, useReducer, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useTable, useColumnOrder } from 'react-table';
import { QueryType } from 'stores/QueryStore';
import { ReactComponent as LeftArrowIcon } from '../../../../assets/images/arrow-left.svg';
import { ReactComponent as RightArrowIcon } from '../../../../assets/images/arrow-right.svg';
import { ReactComponent as AttributesIcon } from '../../../../assets/images/attributes.svg';
import useEntitiesQuery from '../../../../hooks/useEntitiesQuery';
import { Entity, SortOrder } from '../../users/types';
import UserAttributesModal from '../../users/users-list/UserAttributesModal';
import useUsersList from '../hooks/useUsersList';
import { useSticky } from 'react-table-sticky';
import ErrorToast from '../../../../ui-components/feedback/Toasts/ErrorToast';
import { getDefaultUserTableColumn } from '../../users/users-list/Columns';
import useModifyPersonaProperties from '../../hooks/useModifyPersonaProperties';
import { ColumnType } from '../../store/AccountsStore';
import { ModifyPersonaPropActions } from '../../hooks/useModifyPersonaProperties';
import { AnalyticsEvents, AnalyticsConst } from 'telemetry/constants';
import { toast as toastify } from 'react-toastify';
import { useAnalytics } from '../../../../telemetry';

const UserList = () => {
  const [page, setPage] = useState(1);
  const { field_mapping, setSortBy, sortBy, filters, setFilters, initFieldMappings } =
    useUsersStore();
  const { mutate: modifyPersonaProperties } = useModifyPersonaProperties();

  const { data, isLoading, isFetching } = useUsersList(page);

  const { data: userEntityFields } = useEntitiesQuery(Entity.users); //fetch the entity attributes
  const [attributesModalOpen, setAttributesModalOpen] = useState(false);
  const [columnHovered, setColumnHovered] = useState<string | null>(null);
  const [showPopupFilter, setShowPopupFilter] = useState<boolean>(false);
  // state, dispatch
  const [cellWidthMap, setCellWidthMap] = useReducer(updateCellWidthMap, {} as any);
  const { user: userData } = useAuth();
  // sync scroll refs
  const headersRef = useSyncScroller('syncScrollUserDivs');
  const tableRef = useSyncScroller('syncScrollUserDivs');
  const { track } = useAnalytics();

  const columns = useMemo(
    () => [
      ...Object.keys(field_mapping).map((f) => {
        // by-pass display names for custom keys
        if (f === 'user_id') {
          return {
            Header: 'USER ID',
            accessor: field_mapping[f].keyName,
            Cell: ({ cell }) => getTableCell(cell.value, cell.column.id),
            sticky:
              field_mapping[f].keyName === userData?.currentOrganization[UserFields.USER_ID]
                ? 'left'
                : false,
          };
        }

        if (f === 'user_creation_time') {
          return {
            Header: 'User Creation Time',
            accessor: field_mapping[f].keyName,
            Cell: ({ cell }) => getTableCell(cell.value, cell.column.id),
            sticky:
              field_mapping[f].keyName === userData?.currentOrganization[UserFields.USER_ID]
                ? 'left'
                : false,
          };
        }

        return {
          Header: field_mapping[f].displayName.toUpperCase(),
          accessor: field_mapping[f].keyName,
          Cell: ({ cell }) => {
            if (field_mapping[f].keySpace === 'profile_events')
              return getTableCell(cell.value, cell.column.id, field_mapping[f].keySpace);
            return getTableCell(cell.value, cell.column.id);
          },
          sticky:
            field_mapping[f].keyName === userData?.currentOrganization[UserFields.USER_ID]
              ? 'left'
              : false,
        };
      }),
    ],
    [field_mapping]
  );

  const tableInstance = useTable(
    {
      columns,
      data: data?.data || [],
    },
    useColumnOrder,
    useSticky
  );
  const { setColumnOrder } = tableInstance;

  useEffect(() => {
    setColumnOrder([
      userData?.currentOrganization[UserFields.USER_ID],
      userData?.currentOrganization[UserFields.USER_CREATED_AT],
    ]);
  }, [setColumnOrder, userData]);

  // get the cell width
  useLayoutEffect(() => {
    if (!isLoading && !isFetching) {
      tableInstance?.columns?.map((column) => {
        const el = document.getElementsByClassName(`td-${column.id}`);
        setCellWidthMap({
          type: 'update',
          payload: {
            id: column.id,
            width: el?.[0]?.getClientRects()[0].width,
            height: el?.[0]?.getClientRects()[0].height,
          },
        });
      });
    }
  }, [isFetching, isLoading, tableInstance?.columns]);

  // initialises field_mappings
  // handles initial field mappings
  useEffect(() => {
    if (userEntityFields && isEmpty(field_mapping)) {
      let initialMappings = {};
      userEntityFields
        ?.filter(
          (userProp) =>
            userData?.currentOrganization.userPersonaProperties.includes(userProp.keyName)
          // ||
          // userListPreferences?.find(
          //   (pref) =>
          //     pref.properties.includes(userProp.keyName) &&
          //     pref.entity.id === QueryType.users.toLowerCase()
          // )
        )
        .map((mapping) => (initialMappings[mapping.keyName] = mapping));
      initFieldMappings(initialMappings);
    }
  }, [
    userEntityFields,
    field_mapping,
    initFieldMappings,
    userData?.currentOrganization.userPersonaProperties,
    // userListPreferences,
  ]);

  const applySelectedAttribute = (attr: ColumnType) => {
    const { source, keyName, keySpace, displayName } = attr;

    if (!field_mapping[keyName]) {
      // mutate with adding field_mapping
      modifyPersonaProperties({
        entityType: QueryType.users,
        personaProps: [
          ...Object.keys(field_mapping),
          // .filter(
          //   (mapping) =>
          //     !userData?.currentOrganization?.accountPersonaProperties.includes(mapping)
          // ),
          keyName,
        ],
        action: ModifyPersonaPropActions.ADD,
        orgSchemaName: userData?.currentOrganization?.schemaName,
        currentFieldMapping: attr,
      });
      track(AnalyticsEvents.COLUMN_ADDED, {
        [AnalyticsConst.ATTRIBUTES]: {
          keyName: {
            keyAlias: keyName,
            keyName: keyName,
            source: source,
            keySpace: keySpace,
            displayName: displayName,
          },
        },
      });
    } else {
      if (getDefaultUserTableColumn(userData).includes(keyName)) {
        toastify(<ErrorToast description="Cannot remove a default account column" altText="" />, {
          type: 'error',
        });
      } else {
        // mutate with remove field_mapping
        modifyPersonaProperties({
          entityType: QueryType.users,
          personaProps: [
            ...Object.keys(field_mapping),
            // .filter(
            //   (mapping) =>
            //     mapping !== keyName &&
            //     !userData?.currentOrganization?.userPersonaProperties.includes(mapping)
            // ),
          ],
          action: ModifyPersonaPropActions.REMOVE,
          orgSchemaName: userData?.currentOrganization?.schemaName,
          currentFieldMapping: attr,
        });
        track(AnalyticsEvents.COLUMN_DELETED, {
          [AnalyticsConst.ATTRIBUTES]: {
            keyName: {
              keyAlias: keyName,
              keyName: keyName,
              source: source,
              keySpace: keySpace,
              displayName: displayName,
            },
          },
        });
      }
    }
  };

  return (
    <>
      <div className="flex-grow mx-auto my-4 bg-white rounded shadow pb-tw-8">
        <UserAttributesModal
          field_mapping={field_mapping}
          isOpen={attributesModalOpen}
          setIsOpen={setAttributesModalOpen}
          applySelectedAttribute={(attr: ColumnType) => applySelectedAttribute(attr)}
          defaultSelectedColumns={[]}
        />
        <div className="flex items-center justify-between px-tw-8 py-tw-4">
          {isLoading && <span className="w-40 h-10 bg-tw-gray-eb animate-pulse" />}
          {!isLoading && (
            <div className="flex items-center gap-x-1">
              <span className="text-base font-medium uppercase text-tw-black-7">
                {data?.count
                  ? new Intl.NumberFormat('en-us', {
                      compactDisplay: 'short',
                      notation: 'compact',
                    }).format(data?.count)
                  : 0}{' '}
                Users
              </span>
              {data?.count > 0 && page > 1 && (
                <span className="text-tw-black-7">
                  (Page {page} of {Math.ceil(data?.count / 10)})
                </span>
              )}
            </div>
          )}
          <div className={'flex'}>
            <SecondaryButton onClick={() => setAttributesModalOpen(true)}>
              <AttributesIcon />
              <span className="text-tw-blue-0d">Add attributes</span>
            </SecondaryButton>
          </div>
        </div>
        <div className="sticky top-[0px] z-5 bg-tw-white-ff ml-6">
          {!isLoading && !isFetching && !isEmpty(field_mapping) && (
            <div className="bg-tw-gray-f7 w-full overflow-hidden mt-2" ref={headersRef}>
              {tableInstance?.headerGroups?.map((headerGroup, index) => {
                return (
                  <div
                    key={index}
                    className={classNames(
                      'inline-flex items-center grow-1',
                      data?.count === 0 ? 'justify-between min-w-[5rem]' : ''
                    )}
                  >
                    {headerGroup.headers.map((column) => {
                      return (
                        <div
                          key={column.id}
                          style={{
                            minWidth: cellWidthMap[column.id]?.width ?? 0,
                            minHeight: cellWidthMap[column.id]?.height ?? 0,
                            maxHeight: cellWidthMap[column.id]?.height ?? 0 + 24,
                          }}
                          className={classNames(
                            'border-x-1 border-transparent pl-3 py-8 !text-md font-medium flex items-center justify-between pr-2 grow',
                            columnHovered === column.id
                              ? '!bg-tw-gray-eb cursor-pointer border-tw-gray-eb'
                              : '',
                            column.id === userData?.currentOrganization[UserFields.USER_ID]
                              ? 'sticky z-[3] left-0 bg-tw-gray-f7 border-r-1 border-l-0 border-tw-gray-eb'
                              : ''
                          )}
                          onMouseOver={() => {
                            if (!showPopupFilter) {
                              setColumnHovered(column.id);
                            }
                          }}
                          onMouseLeave={() => {
                            if (!showPopupFilter) {
                              setColumnHovered(null);
                            }
                          }}
                          onClick={() => {
                            setColumnHovered(column.id);
                            // also sort the table.
                            let sf: null | {
                              source: string;
                              keyName: string;
                              keySpace: string;
                              order: typeof SortColumnFilters[0];
                            } = null;
                            sortBy.forEach((sort) => {
                              if (sort.keyName === field_mapping[column.id]?.keyName) {
                                const { source, keyName, keySpace } = field_mapping[column.id];
                                sf = {
                                  source,
                                  keyName,
                                  keySpace,
                                  order: SortColumnFilters?.find(
                                    (filter) => filter.shortHand === sort.order
                                  ),
                                };
                              }
                            });

                            if (sf) {
                              if (sf.order?.shortHand === 'ASC') {
                                // sort descending
                                setSortBy([
                                  ...sortBy.filter(
                                    (s) => s.keyName !== field_mapping[column.id]?.keyName
                                  ),
                                  {
                                    source: field_mapping[column.id]?.source,
                                    keySpace: field_mapping[column.id]?.keySpace,
                                    keyName: field_mapping[column.id]?.keyName,
                                    order: SortOrder.descending,
                                  },
                                ]);
                              } else {
                                // sort default.
                                setSortBy([
                                  ...sortBy.filter(
                                    (s) => s.keyName !== field_mapping[column.id]?.keyName
                                  ),
                                ]);
                              }
                            } else {
                              // just sort ascending if there is no sort applied
                              // on the filter.
                              setSortBy([
                                ...sortBy.filter(
                                  (s) => s.keyName !== field_mapping[column.id]?.keyName
                                ),
                                {
                                  source: field_mapping[column.id]?.source,
                                  keySpace: field_mapping[column.id]?.keySpace,
                                  keyName: field_mapping[column.id]?.keyName,
                                  order: SortOrder.ascending,
                                },
                              ]);
                            }
                          }}
                        >
                          {column.Header}
                          {columnHovered === column.id ? (
                            <ColumnActions
                              column={field_mapping[column.id]}
                              showPopupFilter={showPopupFilter}
                              setShowPopupFilter={setShowPopupFilter}
                              entityType={QueryType.users}
                              setSortBy={setSortBy}
                              sortBy={sortBy}
                              setFilters={setFilters}
                              filters={filters}
                            />
                          ) : (
                            // Column actions are taking 82px
                            <div className="w-[84px] h-[22px]"></div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {(isLoading || isFetching || isEmpty(field_mapping)) && (
          <div className="text-center text-tw-black-7 animate-pulse mt-6 px-6">
            {Array(8)
              .fill(0)
              .map((v, index) => {
                return <div key={index} className="bg-tw-gray-eb h-6 my-8"></div>;
              })}
          </div>
        )}
        {!isLoading && !isFetching && !isEmpty(field_mapping) && (
          <div className="pl-6 w-full">
            {/* container for overflow-scroll */}
            <div className="overflow-x-scroll w-full border-b-1 border-tw-gray-eb" ref={tableRef}>
              <UserAccountsTable
                tableInstance={tableInstance}
                columnHovered={columnHovered}
                entityType={QueryType.users}
                // pass keyNames as default columns
                defaultColumns={[userData?.currentOrganization[UserFields.USER_ID]]}
              />
            </div>
            <div>
              {data?.data.length === 0 && (
                <div className="grid place-items-center my-12 mb-24 text-tw-black-7">
                  <EmptyFolder className="h-20 w-20" />
                  No accounts found...
                </div>
              )}
            </div>
          </div>
        )}
        {data?.count > 0 && (
          <div className="flex items-center justify-between mt-tw-8 px-tw-8">
            <div>
              Showing {page} out of {Math.ceil(data?.count / 10)} pages
            </div>
            {Math.ceil(data?.count / 10) > 1 && (
              <ReactPaginate
                pageCount={Math.ceil(data?.count / 10)}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={({ selected }) => {
                  setPage(selected + 1);
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                containerClassName="flex items-center justify-end"
                activeClassName="children:bg-tw-gray-eb children:text-tw-black-5 children:font-medium"
                pageLinkClassName="px-tw-3 py-2 rounded text-tw-black-5 hover:text-tw-black-5 font-medium"
                breakLinkClassName="px-tw-3 py-2"
                previousLabel={
                  <div className="flex items-center py-2 gap-x-2 px-tw-3 text-black-3">
                    <LeftArrowIcon className="fill-current" />
                    <span>Prev</span>
                  </div>
                }
                nextLabel={
                  <div className="flex items-center py-2 gap-x-2 px-tw-3 text-black-3">
                    <span>Next</span>
                    <RightArrowIcon className="fill-current" />
                  </div>
                }
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default UserList;
