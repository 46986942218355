import { useCallback, useState } from 'react';
import useCohorts from './useCohorts';
import isNil from 'lodash/isNil';
import useCohortCount from './useCohortCount';

export enum CohortTabs {
  ALL = 'all',
  // SYSTEM = 'system',
  // CUSTOM = 'custom',
}

export default function useCohortTabs() {
  const { data: cohorts, isLoading, isError } = useCohorts();
  const { data: cohortCounts } = useCohortCount();
  const [activeTab, setActiveTab] = useState<CohortTabs>(CohortTabs.ALL);
  const getActiveCohortItems = useCallback(() => {
    switch (activeTab) {
      case CohortTabs.ALL:
        return cohorts?.filter((cohort) => {
          return cohortCounts?.find((c) => {
            return c.id === cohort.id && !isNil(c.count);
          });
        });
      default:
        return [];
    }
  }, [activeTab, cohorts, cohortCounts]);

  const changeActiveTab = (tab: CohortTabs) => {
    setActiveTab(tab);
  };

  return {
    activeTab,
    changeActiveTab,
    // cohortTabs: [CohortTabs.ALL, CohortTabs.SYSTEM, CohortTabs.CUSTOM],
    isLoading: isLoading,
    isError: isError,
    cohortItems: getActiveCohortItems(),
    allUsersCohortItem: cohorts?.find((c) => c.name === 'All Users'),
  };
}
