import { useAuth } from 'hooks/useAuth';
import useModels from 'hooks/useModels';
import useCohortCount from 'pages/pql-workflow/hooks/useCohortCount';
import { CohortsResponse } from 'pages/pql-workflow/hooks/useCohorts';
import useCohortTabs from 'pages/pql-workflow/hooks/useCohortTabs';
import { DefinePQLSections, useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { PQLWorkflowSteps, usePQLWorkflowStore } from 'pages/pql-workflow/store/PQLWorkflowStore';
import { useEffect, useState } from 'react';
import { useAnalytics } from 'telemetry';
import { AnalyticsEvents } from 'telemetry/constants';
import { CompareArrows, CrossIconWithoutBg, UserPersonas } from 'ui-components/data-display/Icons';
import { PrimaryButton, SecondaryButton } from 'ui-components/inputs/Buttons';
import Drawer from 'ui-components/navigation/Drawer';
import Modal from 'ui-components/util/modal';
import { numberWithPluralWord } from 'utils/common';
import AccordionHeader from '../AccordionHeader';
import SectionAccordion from '../SectionAccordion';
import Cohort from './Cohort';
import PersonaGrid from './PersonaGrid';
import RFACompareTable from './RFACompareTable';

const recommendedCohortsByGoal = {
  conversion: ['Promising users', 'New users', 'Champion users'],
  expansion: ['Promising users', 'Champion users'],
  engagement: ['Promising users', 'New users'],
  churn_prevention: ['Needs attention', 'About to sleep'],
  resurrection: ['Hibernating users'],
  activation: ['New users'],
  expansion2: ['Promising users', 'New users'],
};

const recommendedCohortsByGoalNewPersonas = {
  conversion: ['Potential Power users', 'New users', 'Power users'],
  expansion: ['Potential Power users', 'Power users'],
  engagement: ['Potential Power users', 'New users'],
  churn_prevention: ['Dormant users'],
  expansion2: ['Promising users', 'New users'],
  resurrection: ['Dormant users'],
  activation: ['New users'],
};

export default function SelectCohort() {
  const { sectionsMap, currentSection, setSectionsMap, setCohorts, cohorts, modelId } =
    useDefinePQLStore();
  const { setCurrentStep, setCompletedSteps, completedSteps } = usePQLWorkflowStore();
  const { data: models } = useModels();
  const { user } = useAuth();

  const { track } = useAnalytics();

  const { isLoading, isError, cohortItems, allUsersCohortItem } = useCohortTabs();
  const { data: cohortCounts, isLoading: loadingCohortCounts } = useCohortCount();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showNewPersonas, setShowNewPersonas] = useState(false);
  const [recommendedCohortsByGoalMap, setRecommendedCohortsByGoalMap] =
    useState(recommendedCohortsByGoal);

  useEffect(() => {
    if (!cohorts?.length && allUsersCohortItem && !showDrawer) {
      setCohorts([allUsersCohortItem.id]);
    }
  }, [allUsersCohortItem, cohorts, setCohorts]);

  useEffect(() => {
    // Hack to find if the org is using old persona or persona 1.5
    if (
      cohortItems?.length &&
      cohortItems.find(
        (cohort) =>
          cohort?.name.toLowerCase() === 'power users' ||
          cohort?.name.toLowerCase() === 'power accounts'
      )
    ) {
      setShowNewPersonas(true);
      setRecommendedCohortsByGoalMap(recommendedCohortsByGoalNewPersonas);
    }
  }, [cohortItems]);
  // doesn't show the section if the previous section is not finished
  // OVERRIDE_ENABLE_SYSTEM_COHORTS
  if (!user?.currentOrganization.isPersonas) {
    return null;
  } else if (
    !sectionsMap[DefinePQLSections.CONVERSION_EVENT] ||
    currentSection === DefinePQLSections.CONVERSION_EVENT
  ) {
    return null;
  }

  return (
    <div>
      <hr className="w-full" />
      <SectionAccordion
        id={DefinePQLSections.COHORT}
        hasPadding={true}
        accordionHeader={
          <AccordionHeader
            onClick={() => {
              // if not on current section, mark it as current,
              // then mark all other next steps to not finished
              let newSectionsMap = {};
              let refIndex = Object.keys(sectionsMap).indexOf(DefinePQLSections.COHORT);
              (Object.keys(DefinePQLSections) as Array<keyof typeof DefinePQLSections>).forEach(
                (key, index) => {
                  if (index >= refIndex) {
                    newSectionsMap[key.toLowerCase()] = false;
                  }
                }
              );
              setSectionsMap({
                ...sectionsMap,
                ...newSectionsMap,
              });
            }}
          >
            {numberWithPluralWord(
              cohortItems?.filter((c) => cohorts?.includes(c.id)).length,
              'persona'
            ) + ' selected'}
          </AccordionHeader>
        }
        expanded={currentSection === DefinePQLSections.COHORT}
      >
        <div className="flex items-center justify-between">
          <div>
            <div className="font-medium rounded-b-xl text-tw-black-1">
              Which persona do you want to target
            </div>
            {!showNewPersonas && (
              <span
                className="my-2 text-xs cursor-pointer rounded-b-xl text-tw-blue-0d"
                onClick={() => {
                  track(AnalyticsEvents.COHORT_MODAL_OPENED, true);
                  setModalOpen(true);
                }}
              >
                What are personas?
              </span>
            )}
          </div>
          <SecondaryButton
            className="flex items-center"
            onClick={() => {
              setCohorts([]);
              setShowDrawer(true);
            }}
          >
            <CompareArrows />
            <span className="text-sm text-tw-black-3"> What are personas?</span>
          </SecondaryButton>
        </div>
        <div className="mt-4 gap-y-4">
          <div className="w-full overflow-scroll min-h-96">
            {(isLoading || isError) && (
              <div className="w-full">
                <div className="ml-2 animate-pulse">
                  <div className="m-4">
                    <div className="w-2/5 h-12 rounded-sm bg-tw-gray-eb"></div>
                  </div>
                  <div className="grid grid-cols-2 mx-4 gap-x-2">
                    {Array(4)
                      .fill(0)
                      .map((i) => {
                        return (
                          <div
                            key={i}
                            className="w-11/12 h-32 px-4 py-4 my-4 mb-2 mr-2 rounded-sm border-1 border-tw-gray-eb"
                          >
                            <div className="w-1/3 h-6 rounded-sm bg-tw-gray-eb"></div>
                            <div className="h-6 mt-2 rounded-sm bg-tw-gray-eb"></div>
                            <div className="flex w-2/3 h-6 mt-2 rounded-sm bg-tw-gray-eb"></div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
            {!isLoading && !isError && (
              <div>
                <div className="flex items-center justify-between"></div>
                <div className="w-full min-h-[400px]">
                  {/* <div className="col-span-2 mt-4">
                    {cohortTabs?.map((tab) => (
                      <SideTab
                        key={tab}
                        label={`${capitalizeFirstLetter(tab)} cohorts`}
                        isActive={tab === activeTab}
                        onClick={() => {
                          changeActiveTab(tab);
                        }}
                      />
                    ))}
                  </div> */}
                  <div>
                    <div className="grid grid-cols-2 gap-4 mb-4 overflow-y-scroll">
                      {[
                        allUsersCohortItem,
                        // eslint-disable-next-line no-unsafe-optional-chaining
                        ...cohortItems
                          ?.filter((c) => c.id !== allUsersCohortItem.id)
                          .map((c) => {
                            return {
                              ...c,
                              isRecommended: recommendedCohortsByGoalMap[
                                models.find((m) => m.modelId === modelId).goal
                              ].includes(c.name),
                            };
                          })
                          .sort((a, b) => b.isRecommended - a.isRecommended),
                      ].map(
                        (
                          cohortToDisplay: CohortsResponse & { isRecommended: boolean },
                          index: number
                        ) => {
                          return (
                            <Cohort
                              key={cohortToDisplay?.id}
                              cohortToDisplay={cohortToDisplay}
                              index={index}
                              isRecommended={cohortToDisplay?.isRecommended}
                              showCheckbox={showCheckbox}
                              setShowCheckbox={setShowCheckbox}
                              counts={cohortCounts?.find((c) => c.id === cohortToDisplay?.id)}
                              loadingCounts={loadingCohortCounts}
                            />
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mt-6">
          <PrimaryButton
            className="w-full product-tour-6"
            onClick={() => {
              setCurrentStep(PQLWorkflowSteps.FILTERS);
              // mark define section as completed
              setCompletedSteps({
                ...completedSteps,
                [PQLWorkflowSteps.DEFINE]: true,
              });
            }}
            disabled={
              cohortCounts
                ?.filter((c) => cohorts.includes(c.id))
                .reduce((acc, c) => acc + c.count, 0) === 0
            }
          >
            Proceed
          </PrimaryButton>
        </div>
      </SectionAccordion>
      <Modal
        open={modalOpen}
        trigger={null}
        onClose={() => setModalOpen(false)}
        hrRequired={false}
        width="w-6/12"
        closeIcon={null}
      >
        <div className="w-full">
          <div className="flex justify-end mt-4 mr-4">
            <div
              className="flex justify-center w-6 h-6 p-2 rounded-full cursor-pointer bg-tw-gray-eb"
              onClick={() => setModalOpen(false)}
            >
              <CrossIconWithoutBg className="-mt-0.5 fill-current border-0 w-3 h-3 text-tw-black-7 mx-auto" />
            </div>
          </div>
          <div className="px-6 bg-tw-white-ff">
            <div className="flex items-center justify-center">
              <UserPersonas />
            </div>
            <div className="mt-3 text-3xl font-medium text-center">What are personas?</div>
            <div className="my-6 font-normal text-tw-black-5">
              Personas describe a set of characterstics and behaviours based on which users are
              grouped together. We’ve created some personas to get your started.
            </div>
          </div>
          {/* TODO: Uncomment when documentation link is available */}
          {/* <div className="p-6 rounded-b-lg bg-tw-gray-f5">
              <div className="flex items-center justify-center w-5 h-5 mx-auto rounded-full border-1 border-tw-black-9 text-tw-black-9">
                ?
              </div>
              <div className="mt-4 text-sm font-normal text-center">
                Still have questions about cohorts?
              </div>
              <div className="mx-auto mt-2 text-xs text-center text-tw-blue-0d">
                Read documentation
              </div>
            </div> */}
        </div>
      </Modal>
      <Drawer
        widthClassName="w-2/3"
        open={showDrawer && !showNewPersonas}
        onClose={() => setShowDrawer(false)}
        primaryButton={
          <PrimaryButton
            className="w-56"
            disabled={
              cohortCounts
                ?.filter((c) => cohorts.includes(c.id))
                .reduce((acc, c) => acc + c.count, 0) === 0
            }
            onClick={() => {
              setCurrentStep(PQLWorkflowSteps.FILTERS);
              // mark define section as completed
              setCompletedSteps({
                ...completedSteps,
                [PQLWorkflowSteps.DEFINE]: true,
              });
              setShowDrawer(false);
            }}
          >
            {cohorts?.includes(allUsersCohortItem?.id) ? 'Proceed with all users' : 'Proceed'}
          </PrimaryButton>
        }
      >
        <h2 className="text-xl font-medium mt-11 text-tw-black-3">
          Here is how personas compare against one another
        </h2>
        <RFACompareTable />
      </Drawer>

      <Drawer
        widthClassName="w-2/3"
        open={showDrawer && showNewPersonas}
        onClose={() => setShowDrawer(false)}
        hideButtons={true}
        paddingX="px-0"
      >
        <h2 className="py-4 pb-4 mb-8 text-xl font-medium border-b border-dashed text-tw-black-3 border-tw-gray-eb">
          <div className="px-6">Let’s understand personas</div>
        </h2>
        <div className="px-6">
          <PersonaGrid />
        </div>
      </Drawer>
    </div>
  );
}
