import { useMemo } from 'react';
import { useTable } from 'react-table';

export const Table = ({ columns, data, hiddenColumns }) => {
  const tableColumns = useMemo(() => columns, []);
  const tableData = useMemo(() => data, []);
  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: tableColumns,
    data: tableData,
    initialState: {
      hiddenColumns: hiddenColumns ? hiddenColumns : [], //Add data to be accessible and view to be hidden
    },
  });
  return (
    <div className="tableFixHead tscroll rounded-lg overflow-scroll" style={{ maxHeight: '67vh' }}>
      <table
        {...getTableBodyProps()}
        className={`table table-lg table-alternate table-thead-bordered table-nowrap table-align-middle`}
      >
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th key={column.id} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {rows.length ? (
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return [
                <tr key={row.id} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td key={cell.row.id} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>,
              ];
            })}
          </tbody>
        ) : (
          <>
            <div className={'items-center flex justify-center'}>
              <p className={'text-base'}>No Data Available</p>
            </div>
          </>
        )}
      </table>
    </div>
  );
};
