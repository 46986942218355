import { WarningIcon } from 'ui-components/data-display/Icons';
import Modal from 'ui-components/util/modal';
import { OpportunityResponse } from 'ui-components/business/opportunities/types';
import { SecondaryButton, TertiaryButton } from 'ui-components/inputs/Buttons';

const renderWarning = (affectedPlaybooks: OpportunityResponse[]) => {
  return (
    <div className="my-8">
      {affectedPlaybooks?.length !== 0 && (
        <div className="flex items-center ">
          <div className="mr-6">
            <div className="w-20 h-16">
              <div className="flex items-center justify-center border-solid rounded bg-tw-papaya border-tw-papaya">
                <div className="my-5">
                  <WarningIcon />
                </div>
              </div>
            </div>
          </div>
          <div className="text-base font-medium text-tw-black-5">
            Deleting this app will stop{' '}
            {`${affectedPlaybooks ? affectedPlaybooks?.length : 'some'}`} of your active Playbooks
          </div>
        </div>
      )}
    </div>
  );
};

export default function DeleteIntegrationModal({
  isDeleteOpenDialog,
  setIsDeleteOpenDialog,
  integrationName,
  deleteApp,
  isLoading,
  affectedPlaybooks,
  affectedPlaybooksLoading,
}) {
  return (
    <Modal
      title={'Delete Integrations'}
      onClose={() => {
        setIsDeleteOpenDialog(false);
      }}
      open={isDeleteOpenDialog}
    >
      <div>
        <div>
          <div className="flex flex-col px-6 py-2 min-h-[9rem]">
            {affectedPlaybooksLoading && (
              <div className="py-2">
                <div className="w-full h-8 bg-tw-gray-eb animate-pulse"></div>
                <div className="w-full h-6 mt-4 bg-tw-gray-eb animate-pulse"></div>
                <div className="w-full h-6 mt-2 bg-tw-gray-eb animate-pulse"></div>
              </div>
            )}
            {!affectedPlaybooksLoading && (
              <div>
                {renderWarning(affectedPlaybooks)}
                <p>
                  On deleting this integration, you will no longer be able to sync your qualified
                  leads to <span className="font-medium">{integrationName}</span>
                </p>
                <div className="my-4">
                  <p>Are you sure you want to delete?</p>
                </div>

                <br />
              </div>
            )}
          </div>
          <hr />
          <div className="flex items-center justify-end p-4 gap-x-2">
            <TertiaryButton onClick={() => setIsDeleteOpenDialog(false)}>Cancel</TertiaryButton>
            <SecondaryButton
              onClick={() => {
                deleteApp();
              }}
              className="text-tw-red-dd w-44"
              bgClassName="bg-tw-red-ff"
              borderClassName="border-tw-red-dd"
              isLoading={isLoading}
              disabled={affectedPlaybooksLoading}
            >
              Delete Integration
            </SecondaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}
