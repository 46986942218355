export enum Entity {
  users = 'users',
  accounts = 'accounts',
}

export enum SortOrder {
  ascending = 'ASC',
  descending = 'DESC',
}

export type SortBy = {
  source: string;
  keySpace: string;
  keyName: string;
  order: SortOrder;
};

export type UserListStoreInterface = {
  page: number;
  setPage: (page: number) => void;
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  field_mapping: Record<string, FieldMapping>;
  initFieldMappings: (initialMappings) => void;
  setFieldMapping: (obj: FieldMapping) => void;
  removeFieldMapping: (keyName: string) => void;
  sortBy: Array<SortBy>;
  setSortBy: (obj: SortBy) => void;
  filterExpression: Array<FilterExpression>;
  setFilterExpression: (obj: FilterExpression, replace?: boolean) => void;
  removeFilterExpression: (keyname: string) => void;
};

export type FieldMapping = {
  keyAlias: string;
  keyName: string;
  keySpace: string;
  source: string;
  displayName?: string;
  columnName?: string;
};
export type FilterExpression = {
  keyAlias?: string;
  keyName: string;
  keySpace: string;
  source: string;
  displayName?: string;
  op: string;
  value: string | number | string[];
  columnName?: string;
};
