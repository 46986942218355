import { dateBetweenOptions, Leads } from 'pages/opportunities/types';
import { SortBy } from 'pages/users-and-accounts/users/types';
import create, { SetState } from 'zustand';
import { persist } from 'zustand/middleware';

interface filterSelection {
  op: string;
  value: string | string[];
  // value: string[]; //enable this for array of filters
}

export interface Filter extends filterSelection {
  source: string;
  keySpace: string;
  keyName: string;
  displayName: string;
  columnName: string;
}

export enum QueryType {
  users = 'USERS',
  accounts = 'ACCOUNTS',
}

export enum QueryGoal {
  engagement = 'ENGAGEMENT',
  conversion = 'CONVERSION',
  expansion = 'EXPANSION',
  churn = 'CHURN',
  churnPrevention = 'CHURN_PREVENTION',
}

export interface QueryData {
  type: string;
  goal: string;
  modelId: string;
  time: string;
  timeOptions: {
    start: number | Date;
    end: number | Date;
    relativeTimeOption: string | null;
  };
  filter: Filter[];
  queryLoading: boolean;
  showSampleProfiles: boolean;
  pageNumberTable: number;
  response: Leads | null;
  modified: boolean;
  page: number;
  setPage: (page: number) => void;
  sortBy: Array<SortBy>;
  setSortBy: (obj: SortBy) => void;
}

interface QueryDataInterface extends QueryData {
  setQueryData: (data: Partial<QueryData> | null) => void;
  resetQueryData: () => void;
}

const initialValues = {
  type: '',
  goal: '',
  modelId: '',
  time: dateBetweenOptions[0].value,
  timeOptions: { start: null, end: null, relativeTimeOption: 'days' },
  filter: [],
  queryLoading: false,
  showSampleProfiles: false,
  pageNumberTable: 1,
  response: null,
  modified: false,
};

export const filterSelectionIntial: filterSelection = { op: '', value: '' };

// export const filterSelectionIntial: filterSelection = { op: '', value: [] }; //enable this for array of filters

export const queryStore = (set: SetState<QueryDataInterface>): QueryDataInterface => ({
  ...initialValues,
  setQueryData: (data) =>
    data
      ? set((state) => ({
          ...state,
          queryLoading: true,
          response: null,
          ...data,
          modified: true,
        }))
      : set((state) => ({ ...state, ...initialValues })),
  resetQueryData: () =>
    set(() => ({
      ...initialValues,
    })),
  page: 1,
  setPage: (page) => set(() => ({ page })),
  sortBy: [],
  setSortBy: (sortBy) => set(() => ({ sortBy: sortBy ? [sortBy] : [] })),
});

export const useQueryStore = create<QueryDataInterface>(
  persist(queryStore, { name: 'queryStore' })
);
