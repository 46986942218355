import { Switch } from '@headlessui/react';
import { classNames } from 'utils/common';
import { ReactComponent as ToggleOffIcon } from '../../assets/images/toggle-off.svg';
import { ReactComponent as ToggleOnIcon } from '../../assets/images/toggle-on.svg';

interface ToggleProps {
  enabled: boolean;
  setEnabled?: () => void;
  classNames?: string;
}

const Toggle: React.FC<ToggleProps> = ({ enabled, setEnabled, classNames: customClassNames }) => {
  return (
    <Switch
      checked={enabled}
      onChange={() => {
        if (setEnabled) {
          setEnabled();
        }
      }}
      className={classNames(
        enabled ? 'bg-tw-blue-0d' : 'bg-tw-black-9',
        'relative inline-flex items-center h-5 rounded-full w-9',
        customClassNames
      )}
    >
      <span className="sr-only">Enable notifications</span>
      <span
        className={classNames(
          `transform transition ease-in-out duration-200`,
          enabled ? 'translate-x-5' : 'translate-x-1',
          `inline-block w-3 h-3 transform rounded-full`
        )}
      >
        {enabled ? <ToggleOnIcon className="w-3 h-3" /> : <ToggleOffIcon className="w-3 h-3" />}
      </span>
    </Switch>
  );
};

export default Toggle;
