import { FC, ReactNode } from 'react';
import { FilledInfoWithWave } from '../data-display/Icons';

type Props = {
  children: ReactNode;
};
const InfoBanner: FC = ({ children }: Props) => {
  return (
    <div className="flex mt-2 rounded-lg cursor-pointer text-tw-black-5">
      <div className="w-3 bg-tw-blue-0d rounded-tl-lg rounded-bl-lg"> </div>
      <span className="flex-1 p-3.5 bg-tw-white-ff flex place-items-center space-x-3 text-sm border-1 border-y-tw-blue-b6 border-r-tw-blue-b6 rounded-br-lg rounded-tr-lg">
        <FilledInfoWithWave className="h-8 w-8" />
        <span className="text-tw-black-5">{children}</span>
      </span>
    </div>
  );
};
export default InfoBanner;
