import ReactDOM from 'react-dom';
import App from 'App';
import 'focus-visible/dist/focus-visible';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { DEV, ENVIRONMENT } from 'utils/environment';
import './css/tailwind.css';
import { BrowserRouter } from 'react-router-dom';

Sentry.init({
  dsn: !DEV ? 'https://65a55873e220473a836734ab8b99fd9c@o894267.ingest.sentry.io/5840331' : '',
  integrations: [new Integrations.BrowserTracing()],
  environment: ENVIRONMENT,
  release: process.env.REACT_APP_SENTRY_RELEASE_TAG,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
