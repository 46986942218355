import { toast as toastify } from 'react-toastify';
import { useClient } from 'api-client';
import useConnectedIntegrations from 'hooks/useConnectedIntegrations';
import useEntitiesQuery from 'hooks/useEntitiesQuery';
import { Entity } from 'pages/users-and-accounts/users/types';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { routes } from 'routing/clientRoutes';
import { useDefinePQLStore } from '../store/PQLDefineStore';
import { useExportPQLStore } from '../store/PQLExportStore';
import { getMetadata } from './useCreatePQL';
import { useAuthStore } from '../../../hooks/useAuthStore';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';

export function useEditExports(opportunityId: number, exportId: number) {
  const client = useClient();
  const queryClient = useQueryClient();
  const router = useHistory();
  const { getAccountId } = useAuthStore();
  const { targetEntity } = useDefinePQLStore();
  const { destinationId, tags, object, subObject, emails, extraFormData, mappings, uniqueKeys } =
    useExportPQLStore();
  const { data: entities } = useEntitiesQuery(targetEntity.toLowerCase() as Entity);

  const { data: connectedIntegrations } = useConnectedIntegrations(false);
  const integration = connectedIntegrations?.find((i) => i.id === destinationId);

  let fieldMapping = [];

  if (integration?.integration.supportsEntities) {
    uniqueKeys.forEach((uniqueKey) => {
      const entity = entities?.find((e) => e.columnName === uniqueKey?.source.split(',')[0]);
      entity &&
        fieldMapping.push({
          source: entity?.source,
          keyName: entity?.keyName,
          columnName: entity?.columnName,
          keySpace: entity?.keySpace,
          keyAlias: uniqueKey.destination,
          isUniqueKey: true,
        });
    });
  }

  mappings.forEach((m) => {
    const columnName = m.source.split(',')[0];
    const entity = entities?.find((e) => e.columnName === columnName);
    entity &&
      fieldMapping.push({
        source: entity?.source,
        keyName: entity?.keyName,
        columnName: entity?.columnName,
        keySpace: entity?.keySpace,
        keyAlias: m.destination,
        isUniqueKey: false,
      });
  });

  return useMutation(
    () =>
      client.editExports(opportunityId, exportId, {
        metadata: getMetadata(integration?.type, object, subObject, emails, extraFormData),
        fieldMapping,
        tags: tags.reduce((obj, item) => Object.assign(obj, { [item.name]: item.value }), {}),
      }),
    {
      onSuccess: () => {
        // refetch current opportunity if active
        queryClient.refetchQueries(['opportunity', opportunityId.toString()]);
        toastify(<SuccessToast description="Successfully edited export" />, {
          type: 'success',
        });
        // takes the user to the
        // opportunities page
        router.push({
          pathname: `/${getAccountId()}${routes.opportunitiesList}`,
        });
      },
    }
  );
}
