import { PrimaryButton, SecondaryButton } from 'ui-components/inputs/Buttons';
import { useAuth } from 'hooks/useAuth';
import { DefinePQLSections, useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import VirtualizedSelect from 'ui-components/inputs/VirtualizedSelect';
import { PQLWorkflowSteps, usePQLWorkflowStore } from 'pages/pql-workflow/store/PQLWorkflowStore';
import AccordionHeader from '../AccordionHeader';
import SectionAccordion from '../SectionAccordion';
import { getProfileEvents } from '../../../opp-details/common/utils';
import useMeta from 'pages/pql-workflow/hooks/useMeta';

export const modelToQuestionMap = {
  conversion: 'Count a conversion when a user performs',
  expansion: 'Count an expansion when a user performs',
  activation: 'Count an activation when a user performs',
  engagement: 'Count an engagement when a user performs',
  churn_prevention: 'Count prevention in churn when a user performs',
  churnPrevention: 'Count prevention in churn when a user performs',
  churn: 'Count prevention in churn when a user performs',
  resurrection: 'Count a resurrection when a user performs',
};

export const modelToPerformanceVerbMap = {
  conversion: 'Conversion event',
  expansion: 'Expansion event',
  activation: 'Activation event',
  engagement: 'Engagement event',
  churn_prevention: 'Churn prevention event ',
  churnPrevention: 'Churn prevention event ',
  churn: 'Churn prevention event ',
  resurrection: 'Resurrection event',
};

export default function SelectConversionEvent() {
  const {
    currentSection,
    setSectionsMap,
    setCurrentSection,
    setPerformanceTrackEvent,
    performanceTrackEvent,
    sectionsMap,
    modelId,
  } = useDefinePQLStore();
  const { user } = useAuth();
  const { data, isLoading } = useMeta();
  const { setCurrentStep, setCompletedSteps, completedSteps } = usePQLWorkflowStore();
  if (!sectionsMap[DefinePQLSections.GOALS] || currentSection === DefinePQLSections.GOALS) {
    return null;
  }
  const sourceItems = getProfileEvents(data?.sourceMeta?.data);
  return (
    <div>
      <hr className="w-full" />
      <SectionAccordion
        id={DefinePQLSections.CONVERSION_EVENT}
        hasPadding={true}
        accordionHeader={
          <AccordionHeader
            onClick={() => {
              // if not on current section, mark it as current,
              // then mark all other next steps to not finished
              let newSectionsMap = {};
              let refIndex = Object.keys(sectionsMap).indexOf(DefinePQLSections.CONVERSION_EVENT);
              (Object.keys(DefinePQLSections) as Array<keyof typeof DefinePQLSections>).forEach(
                (key, index) => {
                  if (index >= refIndex) {
                    newSectionsMap[key.toLowerCase()] = false;
                  }
                }
              );
              setSectionsMap({
                ...sectionsMap,
                ...newSectionsMap,
              });
            }}
          >
            {`${modelToPerformanceVerbMap[modelId]}: ${performanceTrackEvent?.value ?? 'N/A'}`}
          </AccordionHeader>
        }
        expanded={currentSection === DefinePQLSections.CONVERSION_EVENT}
      >
        <div className="font-medium rounded-b-xl text-tw-black-1">
          How do you want to track performance ?
        </div>
        <div className="flex gap-4 py-4">
          <div className="mt-2 text-tw-gray-7 shrink-0">{`${modelToQuestionMap[modelId]}`}</div>
          {isLoading && <div className="w-50 h-8 bg-tw-gray-eb animate-pulse"></div>}
          {!isLoading && (
            <VirtualizedSelect
              onChange={(value: string) => {
                const source = sourceItems.find((sourceItem) => {
                  return sourceItem.value === value;
                })?.source;
                setPerformanceTrackEvent({ value, source });
              }}
              width={'400px'}
              options={sourceItems}
              value={performanceTrackEvent?.value}
              virtualize
              ph="Select an event"
              itemStyle="pl-0"
            />
          )}
        </div>
        <div className="flex gap-4 mt-6">
          <SecondaryButton
            className="w-full py-2 font-semibold text-tw-blue-0d product-tour-10"
            onClick={() => {
              setSectionsMap({
                ...sectionsMap,

                [DefinePQLSections.CONVERSION_EVENT]: true,
              });
              setPerformanceTrackEvent(null);
              if (user?.currentOrganization?.isPersonas) {
                setCurrentSection(DefinePQLSections.COHORT);
              } else {
                setCurrentStep(PQLWorkflowSteps.FILTERS);
                setCompletedSteps({
                  ...completedSteps,
                  [PQLWorkflowSteps.DEFINE]: true,
                });
              }
            }}
          >
            Skip
          </SecondaryButton>
          <PrimaryButton
            className="w-full product-tour-3"
            disabled={!performanceTrackEvent}
            onClick={() => {
              setSectionsMap({
                ...sectionsMap,
                // mark entities as finished
                [DefinePQLSections.CONVERSION_EVENT]: true,
              });
              // OVERRIDE_ENABLE_SYSTEM_COHORTS
              if (user?.currentOrganization?.isPersonas) {
                setCurrentSection(DefinePQLSections.COHORT);
              } else {
                setCurrentStep(PQLWorkflowSteps.FILTERS);
                // mark define section as completed
                setCompletedSteps({
                  ...completedSteps,
                  [PQLWorkflowSteps.DEFINE]: true,
                });
              }
            }}
          >
            Proceed with selected event
          </PrimaryButton>
        </div>
      </SectionAccordion>
    </div>
  );
}
