import { GoogleLogo, InfoIcon } from 'ui-components/data-display/Icons';
import AlternateTopNav from 'ui-components/business/navbars/AlternateTopNav';
import { PrimaryButton } from 'ui-components/inputs/Buttons';
import { PasswordInput, TextInput } from 'ui-components/inputs/Inputs';
import { passwordRegExp, passwordValidationString } from 'utils/common';
import useOAuth from 'hooks/use-oauth';
import { useAuth } from 'hooks/useAuth';
import LoginRightBanner from 'ui-components/business/LoginRightBanner';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import ErrorFromHook from '../../ui-components/feedback/ErrorFromHook';
import useSignup from './hooks/useSignUp';
import { useHistory } from 'react-router';

type SignupFormState = {
  email: string;
  password: string;
};

export default function SignUp() {
  const [signUpLoading, setSignUpLoading] = useState(false);
  const signUpMutation = useSignup();
  const { googleAuth } = useAuth(false);
  const [signupError, setSignupError] = useState(null);
  const router = useHistory();

  const recaptchaRef = useRef(null);

  const onSubmit: SubmitHandler<SignupFormState> = async (data) => {
    setSignUpLoading(true);
    const token = await recaptchaRef.current.executeAsync();
    try {
      await signUpMutation.mutateAsync(
        {
          email: data.email,
          password: data.password,
          recaptcha: token,
        },
        {
          onSettled: () => {
            setSignUpLoading(false);
          },
        }
      );
    } catch (err) {
      setSignUpLoading(false);
      setSignupError(Object.values(err)[0] || null);
    }
  };

  // Force reset recaptcha when value changes
  useEffect(() => {
    recaptchaRef.current.reset();
  }, [recaptchaRef?.current?.getValue()]);

  const oauthCallback = useCallback(
    (err, code) => {
      googleAuth.login(code);
    },
    [googleAuth]
  );

  const authorize = useOAuth(
    'google',
    process.env.REACT_APP_GOOGLE_CLIENT_ID,
    'https://accounts.google.com/o/oauth2/v2/auth',
    'email',
    'login',
    oauthCallback
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    const params = new URLSearchParams(router.location.search);
    if (params.has('email')) {
      const email = params.get('email');
      let decodedEmail = '';
      try {
        decodedEmail = atob(email);
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        setValue('email', decodedEmail, { shouldValidate: !!decodedEmail });
      }
    }
  }, []);

  return (
    <div className="grid grid-cols-5">
      <div className="h-screen col-span-3 py-8">
        <AlternateTopNav className={'ml-8'} />
        <div className="flex flex-col items-center justify-start h-full py-8 mx-auto">
          <h2 className="mt-12 text-3xl font-medium">Create your Account</h2>
          <div className="w-1/2">
            <button
              data-testid="google-login-button"
              className="flex items-center justify-center w-full p-4 mt-12 rounded gap-x-2 border-tw-blue-0d border-1"
              onClick={() => authorize()}
            >
              <span>
                <GoogleLogo />
              </span>
              <span className="font-bold uppercase text-tw-blue-0d">
                {googleAuth.isLoading ? `Signing up` : `Continue with Google`}
              </span>
            </button>
            {signupError && (
              <div className="mt-6">
                <ErrorFromHook level="error">{signupError}</ErrorFromHook>
              </div>
            )}
            <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-8 text-tw-black-5">
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              />
              <label>Work Email</label>
              <TextInput
                className="w-full py-3 mt-2"
                {...register('email', {
                  required: true,
                  pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                })}
                isError={!!errors.email}
              />
              <label className="mt-6 mb-2">Create Password</label>
              <PasswordInput
                className="w-full py-3"
                {...register('password', {
                  required: true,
                  pattern: passwordRegExp,
                })}
                isError={!!errors.password}
              />
              {!!errors.password && (
                <div className="flex items-center mt-2 text-xs gap-x-1 text-tw-red">
                  <span>
                    <InfoIcon className="w-4 fill-current" />
                  </span>
                  <span>{passwordValidationString}</span>
                </div>
              )}
              <div className="grid mt-6 place-items-center">
                <PrimaryButton
                  type="submit"
                  className="w-full py-3 uppercase"
                  isLoading={signUpLoading}
                  disabled={signUpLoading}
                >
                  Get Started
                </PrimaryButton>
              </div>
              <div className="mt-4 font-medium text-center">
                <span className="text-tw-black-3">Already have an account?</span>
                <Link to="/login">
                  <span className="ml-1 text-tw-blue-0d">Sign in</span>
                </Link>
              </div>
            </form>
          </div>
          <div className="mt-auto font-medium text-tw-black-9">
            By creating an account you agree to our{' '}
            <span className="text-tw-blue-0d">
              <a
                href="https://www.toplyne.io/terms-and-conditions"
                target={'_blank'}
                rel="noreferrer"
              >
                Terms
              </a>
            </span>{' '}
            &{' '}
            <span className="text-tw-blue-0d">
              <a href="https://www.toplyne.io/privacy-policy" target={'_blank'} rel="noreferrer">
                Privacy policy
              </a>
            </span>
          </div>
        </div>
      </div>
      <div className="col-span-2">
        <LoginRightBanner />
      </div>
    </div>
  );
}
