import { useClient } from 'api-client';
import { useQuery } from 'react-query';

export default function useConnectedIntegrationFormDropDownOptions(
  sourceUrl,
  options,
  entity,
  integrationId,
  formField
): any {
  const client = useClient();
  return useQuery(
    [
      'connectedIntegration',
      'pql',
      'formMetaDataDropDown',
      entity,
      integrationId,
      formField,
      sourceUrl,
    ],
    () => client.getFormDropDown(sourceUrl, options)
  );
}
