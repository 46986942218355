import { useClient } from 'api-client';
import { useMutation, useQueryClient } from 'react-query';
import { AnalyticsConst, AnalyticsEvents } from '../telemetry/constants';
import { useAnalytics } from '../telemetry';

type SendJoinRequestData = {
  organizationSchemaName: string;
  isResend?: boolean;
};

export default function useSendJoinRequest() {
  const client = useClient();
  const queryClient = useQueryClient();
  const { track } = useAnalytics();
  return useMutation(
    (data: SendJoinRequestData) =>
      client.sendJoinRequest({
        organization_schema_name: data.organizationSchemaName,
        is_resend: data.isResend,
      }),
    {
      onSuccess: async (variables) => {
        if (variables.isResend)
          track(AnalyticsEvents.SEND_JOIN_EXISTING_ORG, {
            [AnalyticsConst.ORGANIZATION]: variables.organizationSchemaName,
          });
        else
          track(AnalyticsEvents.RESEND_JOIN_EXISTING_ORG, {
            [AnalyticsConst.ORGANIZATION]: variables.organizationSchemaName,
          });

        await queryClient.cancelQueries(['integrations', 'connected']);
        queryClient.refetchQueries('join-request');
      },
    }
  );
}
