import { useAuth } from 'hooks/useAuth';
import useConnectedIntegrations from 'hooks/useConnectedIntegrations';
import useDsEnabled from 'hooks/useDsEnabled';
import { usePQLWorkflowRoute } from 'pages/pql-workflow';
import useOpportunityQuery from 'pages/pql-workflow/hooks/useOpportunityQuery';
import useTotalUsersAggregate from 'pages/pql-workflow/hooks/useTotalUsersAggregate';
import { useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { useExportPQLStore } from 'pages/pql-workflow/store/PQLExportStore';
import {
  ExportCategory,
  FiltersPQLSections,
  useFiltersPQLStore,
} from 'pages/pql-workflow/store/PQLFiltersStore';
import { PQLWorkflowSteps, usePQLWorkflowStore } from 'pages/pql-workflow/store/PQLWorkflowStore';
import { useEffect, useState } from 'react';
import { useAnalytics } from 'telemetry';
import { AnalyticsConst, AnalyticsEvents } from 'telemetry/constants';
import { EditIcon } from 'ui-components/data-display/Icons';
import { PrimaryButton } from 'ui-components/inputs/Buttons';
import { classNames } from 'utils/common';
import AccordionHeader from '../AccordionHeader';
import Card from '../../../../ui-components/business/Card';
import SectionAccordion from '../SectionAccordion';
import ControlGroup from './ControlGroup';

const salesOptions = [
  {
    label: 'Top 100',
    nonDSLabel: 'Any 100',
    value: 100,
  },
  {
    label: 'Top 500',
    nonDSLabel: 'Any 500',
    value: 500,
  },
  {
    label: 'Top 1000',
    nonDSLabel: 'Any 1000',
    value: 1000,
  },
];

const percentageOptions = [
  {
    label: 'Top 10%',
    nonDSLabel: '10%',
    value: 10,
  },
  {
    label: 'Top 25%',
    nonDSLabel: '25%',
    value: 25,
  },
  {
    label: 'Top 50%',
    nonDSLabel: '50%',
    value: 50,
  },
];

type CustomInputCardProps = {
  onChange: (value: number) => void;
  maxValue: number;
  active: boolean;
  onClick: Function;
};

function CustomInputCard({ onChange, maxValue, active, onClick }: CustomInputCardProps) {
  const [value, setValue] = useState(null);
  const { track } = useAnalytics();
  const { user } = useAuth();
  const { modelId, targetEntity } = useDefinePQLStore();
  const dsEnabled = useDsEnabled(modelId, targetEntity);

  return (
    <div
      className={classNames(
        'border-1 py-6 flex-none w-44 rounded-lg mr-4 pl-4 flex items-center font-medium cursor-pointer select-none',
        value > 0 && active ? 'bg-tw-blue-f2 border-tw-blue-0d' : 'border-tw-gray-eb'
      )}
      onClick={() => onClick(true)}
    >
      {!active && (
        <span className="flex items-center gap-x-2">
          {value > 0 ? `${dsEnabled ? 'Top' : 'Any'} ${value}` : 'Custom'} <EditIcon />
        </span>
      )}
      {active && (
        <span className="flex items-center gap-x-2">
          {`${dsEnabled ? 'Top' : 'Any'}`}
          <input
            onBlur={() => {
              onChange(value);
              track(AnalyticsEvents.OPPORTUNITY_SALES_CUSTOM_VALUE_SET, {
                [AnalyticsConst.USER_EMAIL]: user.email,
                [AnalyticsConst.USER_NAME]: `${user.firstName} ${user.lastName}`,
                [AnalyticsConst.ACCOUNT_NAME]: user.currentOrganization.name,
                value,
              });
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.key === 'Escape') {
                onChange(value);
              }
            }}
            autoFocus
            value={value}
            onChange={(e) => {
              if (/^\d*\.?\d*$/g.test(e.target.value)) {
                const inputVal = Number(e.target.value);
                if (inputVal <= maxValue) {
                  setValue(inputVal);
                }
              }
            }}
            className="w-20 font-medium bg-transparent border-b text-tw-black-5 focus:outline-none border-tw-blue-0d"
          />
        </span>
      )}
    </div>
  );
}

export default function Capacity() {
  // query stores
  const {
    sectionsMap,
    currentSection,
    exportCategory,
    setExportCategory,
    salesCapacity,
    setSalesCapacity,
    setSalesCustomValue,
    percentage,
    setPercentage,
    setSectionsMap,
    controlGroup,
  } = useFiltersPQLStore();
  const { modelId, targetEntity } = useDefinePQLStore();
  const { countFromLeadStatus } = useTotalUsersAggregate();
  const { setEmails, setSyncOverviewOpen } = useExportPQLStore();
  const { setCurrentStep, setCompletedSteps, completedSteps } = usePQLWorkflowStore();
  const [customInputActive, setCustomInputActive] = useState(false);
  const [allUsersSelected, setAllUsersSelected] = useState(false);

  // query APIs
  const { user } = useAuth();
  const { data: opportunityQueryData } = useOpportunityQuery();
  const { data: connectedIntegrations } = useConnectedIntegrations(false);

  // custom hooks
  const { isDuplicate } = usePQLWorkflowRoute();
  const dsEnabled = useDsEnabled(modelId, targetEntity);

  useEffect(() => {
    if (!exportCategory) {
      setExportCategory(ExportCategory.SALES);
    }
    if (!salesCapacity && !percentage) {
      setAllUsersSelected(true);
    }
  }, []);

  // doesn't show the section if the previous section is not finished
  if (!sectionsMap[FiltersPQLSections.FILTERS] || currentSection === FiltersPQLSections.FILTERS) {
    return null;
  }

  return (
    <div>
      <hr className="w-full" />
      <SectionAccordion
        id={FiltersPQLSections.CAPACITY}
        hasPadding={true}
        accordionHeader={
          <AccordionHeader
            onClick={() => {
              // if not on current section, mark it as current,
              // then mark all other next steps to not finished
              let newSectionsMap = {};
              let refIndex = Object.keys(sectionsMap).indexOf(FiltersPQLSections.CAPACITY);
              (Object.keys(FiltersPQLSections) as Array<keyof typeof FiltersPQLSections>).forEach(
                (key, index) => {
                  if (index >= refIndex) {
                    newSectionsMap[key.toLowerCase()] = false;
                  }
                }
              );
              setSectionsMap({
                ...sectionsMap,
                ...newSectionsMap,
              });
            }}
          >
            Capacity
          </AccordionHeader>
        }
        expanded={currentSection === FiltersPQLSections.CAPACITY}
      >
        <h3 className="font-medium text-tw-black-1">Narrow down to the best</h3>
        <div className="flex items-center mt-4 gap-x-4 product-tour-capacity">
          <div
            onClick={() => {
              setExportCategory(ExportCategory.SALES);
              setPercentage(null);
            }}
            className={classNames(
              exportCategory === ExportCategory.SALES
                ? 'font-bold text-tw-blue-0d border-tw-blue-0d'
                : 'font-medium text-tw-black-9 border-transparent',
              'border-b-2 pb-2 cursor-pointer select-none'
            )}
          >
            By sales capacity
          </div>
          <div
            onClick={() => {
              setExportCategory(ExportCategory.PERCENTAGE);
              setSalesCapacity(null);
            }}
            className={classNames(
              exportCategory === ExportCategory.PERCENTAGE
                ? 'font-bold text-tw-blue-0d border-tw-blue-0d'
                : 'font-medium text-tw-black-9 border-transparent',
              'border-b-2 pb-2 cursor-pointer select-none'
            )}
          >
            By percentage
          </div>
        </div>
        {exportCategory === ExportCategory.SALES && (
          <div className="flex flex-wrap items-center mt-4 gap-y-4 product-tour-sales-capacity">
            <Card
              label="All users"
              onClick={() => {
                setAllUsersSelected(true);
                setSalesCapacity(null);
                setPercentage(null);
                setSalesCustomValue(0);
                setCustomInputActive(false);
              }}
              isActive={allUsersSelected}
            />
            {salesOptions
              .filter((option) => {
                if (dsEnabled) {
                  return option.value <= countFromLeadStatus;
                } else {
                  return option.value <= opportunityQueryData?.count;
                }
              })
              .map((option, index) => (
                <Card
                  key={index}
                  label={dsEnabled ? option.label : option.nonDSLabel}
                  onClick={() => {
                    setAllUsersSelected(false);
                    setSalesCapacity(option.value);
                    setPercentage(null);
                    setSalesCustomValue(0);
                    setCustomInputActive(false);
                  }}
                  isActive={salesCapacity === option.value}
                />
              ))}

            <CustomInputCard
              onChange={setSalesCapacity}
              maxValue={dsEnabled ? countFromLeadStatus : opportunityQueryData?.count}
              active={customInputActive}
              onClick={(active) => {
                setSalesCapacity(NaN);
                setPercentage(null);
                setCustomInputActive(active);
              }}
            />
          </div>
        )}
        {exportCategory === ExportCategory.PERCENTAGE && (
          <div className="flex flex-wrap items-center mt-4 gap-y-4">
            <Card
              label="All users"
              onClick={() => {
                setAllUsersSelected(true);
                setSalesCapacity(null);
                setPercentage(null);
                setSalesCustomValue(0);
                setCustomInputActive(false);
              }}
              isActive={allUsersSelected}
            />
            {percentageOptions.map((option, index) => {
              return (
                <Card
                  key={index}
                  label={dsEnabled ? option.label : option.nonDSLabel}
                  onClick={() => {
                    setPercentage(option.value);
                    setSalesCapacity(null);
                    setCustomInputActive(false);
                    setAllUsersSelected(false);
                  }}
                  isActive={percentage === option.value}
                />
              );
            })}

            <CustomInputCard
              onChange={setPercentage}
              maxValue={100}
              active={customInputActive}
              onClick={(active) => {
                setPercentage(NaN);
                setSalesCapacity(null);
                setCustomInputActive(active);
              }}
            />
          </div>
        )}
        <ControlGroup />
        <PrimaryButton
          onClick={() => {
            if (!isDuplicate) {
              // If there is no destinationId, set email a default
              const destinationOnlyConnectionIntegration = connectedIntegrations?.filter(
                (connectedIntegration) => {
                  return (
                    connectedIntegration?.integration?.supportsWrite &&
                    connectedIntegration?.integration?.available
                  );
                }
              );
              if (destinationOnlyConnectionIntegration?.length === 1) {
                setSyncOverviewOpen(true);
              } else {
                setEmails([user.email]);
              }
            }
            setCurrentStep(PQLWorkflowSteps.EXPORT);
            setCompletedSteps({
              ...completedSteps,
              [PQLWorkflowSteps.FILTERS]: true,
            });
          }}
          className="w-full py-2 mt-6 product-tour-11"
          disabled={controlGroup.enabled && isNaN(controlGroup.value)}
        >
          Proceed
        </PrimaryButton>
      </SectionAccordion>
    </div>
  );
}
