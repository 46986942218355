import { useQuery } from 'react-query';
import { useClient } from '../api-client';
import { Entity } from '../pages/users-and-accounts/users/types';

export default function useEntitiesQuery(entity: Entity, enabled?: boolean) {
  const client = useClient();
  return useQuery(['entities', entity, 'query'], () => client.getEntitiesQuery(entity), {
    enabled,
  });
}
