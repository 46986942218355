import { SecondaryButton } from 'ui-components/inputs/Buttons';
import { classNames } from 'utils/common';
import { AccountFields, UserFields } from 'pages/users-and-accounts/fields';
import { useAuth } from 'hooks/useAuth';
import { useAuthStore } from 'hooks/useAuthStore';
import { TableInstance } from 'react-table';
import { useAnalytics } from 'telemetry';
import { AnalyticsConst, AnalyticsEvents } from 'telemetry/constants';
import { QueryType } from 'stores/QueryStore';

type UserAccountsTableProps = {
  tableInstance: TableInstance<Object>;
  columnHovered: string;
  entityType: QueryType;
  defaultColumns: Array<string>;
};

export default function UserAccountsTable({
  tableInstance,
  columnHovered,
  entityType,
  defaultColumns = [],
}: UserAccountsTableProps) {
  const { getTableProps, getTableBodyProps, rows, prepareRow } = tableInstance;
  const { track } = useAnalytics();
  const { user: userData } = useAuth();
  const { getAccountId } = useAuthStore();

  return (
    <table
      {...getTableProps()}
      className="table table-alternate table-thead-bordered table-nowrap table-align-middle border-separate"
      style={{
        borderSpacing: '0',
      }}
    >
      <thead></thead>
      <tbody {...getTableBodyProps()} className="z-0">
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr key={row.id} {...row.getRowProps()} className="cursor-pointer filter group">
              {row.cells.map((cell) => {
                return (
                  <td
                    key={cell.row.id}
                    {...cell.getCellProps()}
                    className={classNames(
                      columnHovered === cell.column.id
                        ? 'border-x-1'
                        : 'border-x-1 border-transparent',
                      // this is used to get the width of the
                      // cell at runtime using a useLayoutEffect.
                      `td-${cell.column.id}`,
                      // refer App.css for default column styles.
                      defaultColumns?.includes(cell.column.id)
                        ? 'td-defaultColumn border-r-1 border-l-0 border-tw-gray-eb'
                        : ''
                    )}
                  >
                    <div
                      onClick={() => {
                        if (entityType === QueryType.users) {
                          track(AnalyticsEvents.USER_PROFILE_VIEWED, {
                            [AnalyticsConst.PROFILE_ID]:
                              row.original[userData?.currentOrganization[UserFields.USER_ID]],
                          });
                        } else {
                          track(AnalyticsEvents.ACCOUNT_PROFILE_VIEWED, {
                            [AnalyticsConst.ACCOUNT_ID]:
                              row.original[userData.currentOrganization[AccountFields.ACCOUNT_ID]],
                          });
                        }

                        // open user/account details page in a new tab.
                        window.open(
                          `/${getAccountId()}/${
                            entityType === QueryType.users ? 'users' : 'accounts'
                          }/${
                            entityType === QueryType.accounts
                              ? // coalesce for synced users table.
                                // it has a defined columnId of "accountId".
                                encodeURIComponent(
                                  row.original[
                                    userData.currentOrganization[AccountFields.ACCOUNT_ID]
                                  ] ?? row.original['accountId']
                                )
                              : // coalesce for synced users table.
                                // it has a defined columnId of "userId".
                                row.original[userData?.currentOrganization[UserFields.USER_ID]] ??
                                row.original['userId']
                          }`,
                          '_blank'
                        );
                      }}
                      className={classNames(
                        'flex w-full items-center grow relative',
                        defaultColumns?.includes(cell.column.id)
                          ? 'group-hover:text-tw-blue-0d font-medium'
                          : 'hover:text-inherit'
                      )}
                      // need to incorporate Header and column actions.
                      style={{ minWidth: 300 }}
                    >
                      <div className="flex-grow group-hover:max-w-[60%] group-hover:text-ellipsis group-hover:overflow-hidden py-2">
                        {cell.render('Cell')}
                      </div>
                      <SecondaryButton
                        className={classNames(
                          'ml-2 text-xs absolute right-0 text-tw-blue-0d',
                          defaultColumns?.includes(cell.column.id)
                            ? 'hidden group-hover:block '
                            : 'hidden'
                        )}
                      >
                        View Details
                      </SecondaryButton>
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
