// TODO: Revisit. Something went wrong should pop up on error
import { AxiosError, LoginResponse, useApiClient } from 'api-client';
import { AxiosInstance } from 'axios';
import { useAuthStore } from 'hooks/useAuthStore';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { routes } from 'routing/clientRoutes';
import useLoginSuccess from './useLoginSuccess';

type LoginData = {
  email: string;
  password: string;
};

async function login(client: AxiosInstance, values: LoginData) {
  const { data } = await client.post<LoginResponse>('auth/login/', values, {
    headers: {
      Authorization: null,
    },
  });

  return data;
}

export default function useLogin() {
  const client = useApiClient(true);
  const router = useHistory();
  const success = useLoginSuccess();
  const { removeAccountId } = useAuthStore();

  return useMutation((data: LoginData) => login(client, data), {
    onMutate: () => {
      removeAccountId();
    },
    onSuccess: (data) => {
      success(data);
    },
    onError: (error: AxiosError, variables) => {
      const emailError = error.response?.data?.detail?.nonFieldErrors;
      if (emailError.length && emailError[0] === 'email is not verified.') {
        router.push(`${routes.emailVerificationSent}?email=${variables.email}`);
      }
    },
  });
}
