import { useAuthStore } from 'hooks/useAuthStore';
import { useQuery } from 'react-query';
import { Model, useClient } from '../api-client';
import { useAuth } from './useAuth';

export default function useModels() {
  const client = useClient();
  const { shouldFireQueries } = useAuth();
  const { isLoggedIn, getAccountId } = useAuthStore();
  const accountId = getAccountId();
  return useQuery(['models', accountId], () => client.getModels(), {
    placeholderData: JSON.parse(localStorage.getItem(`models-${accountId}`)) as Model[],
    onSuccess: (data) => {
      localStorage.setItem(
        `models-${accountId}`,
        JSON.stringify(data.sort((a: Model, b: Model) => a.name.localeCompare(b.name)))
      );
    },
    select: (data) => {
      return data?.sort((a: Model, b: Model) => a.name.localeCompare(b.name));
    },
    enabled: isLoggedIn && shouldFireQueries,
  });
}
