import useModels from 'hooks/useModels';
import { useQuery } from 'react-query';
import { useClient } from '../../../api-client';
import { useDefinePQLStore } from '../store/PQLDefineStore';

export default function useMeta() {
  const client = useClient();
  const { targetEntity: entity, modelId } = useDefinePQLStore();
  const { data: models } = useModels();

  // also send goal with the query API
  const goal = models
    ?.find((m) => m.modelId === modelId && m.entity === entity.toLocaleLowerCase())
    ?.goal?.toLocaleUpperCase();

  return useQuery(
    ['meta', entity, modelId, goal],
    () => client.getHistogram({ type: entity, modelId, goal }),
    {
      enabled: !!entity && !!modelId,
    }
  );
}
