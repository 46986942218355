import { ExportPQLSections, useExportPQLStore } from 'pages/pql-workflow/store/PQLExportStore';
import AccordionHeader from '../../AccordionHeader';
import SectionAccordion from '../../SectionAccordion';
import MappingsForm from './MappingsForm';

export default function Destination() {
  const { currentSection, sectionsMap, setSectionsMap } = useExportPQLStore();

  if (currentSection === ExportPQLSections.DESTINATION) {
    return null;
  }

  return (
    <div>
      <hr />
      <SectionAccordion
        id={ExportPQLSections.MAPPINGS}
        hasPadding={true}
        accordionHeader={
          <AccordionHeader
            onClick={() => {
              // if not on current section, mark it as current,
              // then mark all other next steps to not finished
              let newSectionsMap = {};
              let refIndex = Object.keys(sectionsMap).indexOf(ExportPQLSections.MAPPINGS);
              (Object.keys(ExportPQLSections) as Array<keyof typeof ExportPQLSections>).forEach(
                (key, index) => {
                  if (index >= refIndex) {
                    newSectionsMap[key.toLowerCase()] = false;
                  }
                }
              );
              setSectionsMap({
                ...sectionsMap,
                ...newSectionsMap,
              });
            }}
          >
            Sync setup done
          </AccordionHeader>
        }
        expanded={currentSection === ExportPQLSections.MAPPINGS}
      >
        <MappingsForm />
      </SectionAccordion>
    </div>
  );
}
