import * as React from 'react';
import { Table as RolesTable } from './Table';
import { RoleAccessColumns, RoleAccessData } from '../../Roles';
import Modal, { ModalWidth } from '../../../../ui-components/util/modal';

interface MemberAccessTableModalProps {
  showTeamMembersAccess: boolean;
  setShowTeamMembersAccess: (showTeamMembersAccess: boolean) => void;
  onCloseTeamMembersAccessModal: () => void;
}

const MemberAccessTableModal: React.FC<MemberAccessTableModalProps> = (props) => {
  const { showTeamMembersAccess, setShowTeamMembersAccess, onCloseTeamMembersAccessModal } = props;
  return (
    <Modal
      open={showTeamMembersAccess}
      onClose={() => {
        setShowTeamMembersAccess(false);
        onCloseTeamMembersAccessModal();
      }}
      width={ModalWidth.lg}
      title={'Team member access'}
    >
      <div className="mt-12 pb-44">
        <RolesTable columns={RoleAccessColumns} data={RoleAccessData} />
      </div>
    </Modal>
  );
};

export default MemberAccessTableModal;
