import * as React from 'react';
import { ReactComponent as AddSymbolIcon } from '../../../../assets/images/plus.svg';
import { ReactComponent as HubspotIcon } from '../../../../assets/images/hubspot.svg';
import { ReactComponent as PipedriveIcon } from '../../../../assets/images/pipedrive.svg';
import { IntegrationType } from '../../../../api-client';

interface AddNewPropertyStuckFieldProps {
  onclick: any;
  integrationType: string;
  title?: string;
}

export const AddNewPropertyStuckField: React.FC<AddNewPropertyStuckFieldProps> = ({
  onclick,
  integrationType,
  title,
}) => {
  return (
    <>
      <div className={'relative my-6'}>
        <hr />
        <span
          className={'top-0 left-2/4 absolute text-tw-black-9 text-xs font-normal bg-tw-white-ff'}
          style={{
            width: '28px',
            transform: ' translate(-50%, -50%)',
            paddingLeft: '5px',
          }}
        >
          OR
        </span>
      </div>
      <div className={'flex justify-center'}>
        <button
          className={
            'rounded pr-4 pl-3 py-3 border-1 mb-3 text-tw-black-5 font-medium border-tw-black-9 flex items-center'
          }
          onClick={onclick}
          type={'button'}
        >
          <div className={'flex relative ml-4'}>
            <div
              className={
                'border-1 rounded-2xl p-1 border-tw-black-7 mr-2 absolute right-1/2 bg-tw-white-ff flex'
              }
            >
              {integrationType === IntegrationType.HUBSPOT && (
                <HubspotIcon style={{ width: '12px', height: '12px' }} />
              )}
              {integrationType === IntegrationType.PIPEDRIVE && (
                <PipedriveIcon style={{ width: '12px', height: '12px' }} />
              )}
            </div>
            <div className={'border-1 rounded-2xl p-1 border-tw-black-7 mr-2 bg-tw-gray-f5 flex'}>
              <AddSymbolIcon style={{ width: '12px', height: '12px', fill: '#555555' }} />
            </div>
          </div>
          {title}
        </button>
      </div>
    </>
  );
};

AddNewPropertyStuckField.defaultProps = {
  title: 'Create New Field',
};
