import { toast as toastify } from 'react-toastify';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';
import { useQuery } from 'react-query';
import { useClient } from '../api-client';

export default function useInviteDetails(token) {
  const client = useClient();
  return useQuery(['inviteDetails'], () => client.getInviteDetails(token), {
    onError: () => {
      toastify(
        <ErrorToast
          description="This invite is not valid anymore"
          altText="Please ask your admin to a new one"
        />,
        {
          type: 'error',
        }
      );
    },
  });
}
