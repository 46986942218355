import { useField } from 'formik';
import { FC } from 'react';
import Tooltip from '../../../data-display/Tooltip';
import { ReactComponent as InfoToolTipIcon } from 'assets/images/toolTip.svg';
import VirtualizedSelect, { SelectProps } from '../../../inputs/VirtualizedSelect';

export const InfoToolTip = ({
  label,
  width = '24px',
  height = '24px',
}: {
  label: string;
  width?: string;
  height?: string;
}) => {
  return (
    <Tooltip className="text-white bg-tw-gray-7" label={label} hasArrow={true}>
      <InfoToolTipIcon className="ml-3 text-gray-300 " style={{ width, height }} />
    </Tooltip>
  );
};
interface Props extends SelectProps {
  name: string;
  stuckField?: any;
  onChange?: () => void;
}

const errorClassName = 'border-tw-red-dd';

const FormikSelect: FC<Props> = (props) => {
  const { onChange } = props;
  const [field, meta, { setValue }] = useField(props as any);
  const { error, touched } = meta;
  const displayError = touched && error;
  const handleChange = (val) => {
    if (onChange) {
      onChange();
    }
    setValue(val);
  };
  return (
    <VirtualizedSelect
      {...props}
      {...field}
      btnStyle={displayError ? errorClassName : ''}
      onChange={handleChange}
    />
  );
};

export default FormikSelect;
