import { classNames } from 'utils/common';
import { useLocation } from 'react-router-dom';
import { routes } from 'routing/clientRoutes';
import SideNavBar from './SideNavBar';
import TopNavBar from './TopNavBar';

export default function Navbar({ children }) {
  const { pathname } = useLocation();
  const collapsed =
    pathname.includes(routes.opportunities) && !pathname.includes(routes.opportunitiesList);
  return (
    <div className="flex w-screen">
      <SideNavBar />
      <div className={classNames('flex flex-col h-screen', collapsed ? 'w-[95%]' : 'w-[85%]')}>
        <TopNavBar />
        <div className="overflow-y-scroll grow">{children}</div>
      </div>
    </div>
  );
}
