import { LoggedInUser } from 'api-client';
import { UserFields } from 'pages/users-and-accounts/fields';
import { getPropertyDisplayName, getTableCell } from '../../../../utils/common';

// used in sample users table
export function getUserTableColumns(userData: LoggedInUser) {
  if (!userData) {
    return [];
  }

  const { currentOrganization } = userData;

  let defaultFields = [];
  if (currentOrganization[UserFields.USER_ID]) {
    if (
      currentOrganization[UserFields.USER_ID] &&
      currentOrganization[UserFields.USER_ID] !== 'NULL'
    ) {
      defaultFields.push(currentOrganization[UserFields.USER_ID]);
    }
  }

  if (currentOrganization[UserFields.USER_CREATED_AT]) {
    if (
      currentOrganization[UserFields.USER_CREATED_AT] &&
      currentOrganization[UserFields.USER_CREATED_AT] !== 'NULL'
    ) {
      defaultFields.push(currentOrganization[UserFields.USER_CREATED_AT]);
    }
  }

  return [
    ...defaultFields,
    ...currentOrganization.userPersonaProperties.filter((item) => !defaultFields.includes(item)),
  ].map((item) => {
    if (item) {
      return {
        Header: getPropertyDisplayName(item),
        accessor: item,
        Cell: ({ cell }) => getTableCell(cell.value, cell.column.id),
      };
    }
  });
}

export function getDefaultUserTableColumn(userData: LoggedInUser) {
  if (!userData) {
    return [];
  }

  const { currentOrganization } = userData;

  let defaultFields = [];
  if (currentOrganization[UserFields.USER_ID]) {
    defaultFields.push(currentOrganization[UserFields.USER_ID]);
  }

  if (currentOrganization[UserFields.USER_CREATED_AT]) {
    defaultFields.push(currentOrganization[UserFields.USER_CREATED_AT]);
  }

  return defaultFields;
}

export const getColumns = (fieldMapping = {}) => {
  return [
    ...Object.keys(fieldMapping).map((f) => {
      // by-pass display names for custom keys
      if (f === 'user_id') {
        return {
          Header: 'USER ID',
          accessor: fieldMapping[f].keyName,
          Cell: ({ cell }) => getTableCell(cell.value, cell.column.id),
        };
      }

      if (f === 'user_creation_time') {
        return {
          Header: 'User Creation Time',
          accessor: fieldMapping[f].keyName,
          Cell: ({ cell }) => getTableCell(cell.value, cell.column.id),
        };
      }

      return {
        Header: fieldMapping[f].displayName.toUpperCase(),
        accessor: fieldMapping[f].keyName,
        Cell: ({ cell }) => {
          if (fieldMapping[f].keySpace === 'profile_events')
            return getTableCell(cell.value, cell.column.id, fieldMapping[f].keySpace);
          return getTableCell(cell.value, cell.column.id);
        },
      };
    }),
  ];
};
