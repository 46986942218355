import { ActivationStatus, Model } from 'api-client';
import UnlockGif from 'assets/gifs/unlock.gif';
import { useAuthStore } from 'hooks/useAuthStore';
import { noop } from 'lodash';
import useModifyModelActivationStatus from 'pages/pql-workflow/hooks/useModifyModelActivationStatus';
import { useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { useQueryClient } from 'react-query';
import { QueryType } from 'stores/QueryStore';
import HoverPopover from 'ui-components/business/HoverPopover';
import { LockedStateIcon, LockIcon } from 'ui-components/data-display/Icons';
import { PrimaryButton } from 'ui-components/inputs/Buttons';
import { MODELID_DESCRIPTION } from 'utils/constants';
import Card from '../../../../ui-components/business/Card';
import { getModelName } from './SelectModelId';

export default function LockedModelId({ model }: { model: Model }) {
  const { targetEntity } = useDefinePQLStore();
  const { mutate: requestModel, isLoading } = useModifyModelActivationStatus(
    ActivationStatus.REQUESTED
  );

  const queryClient = useQueryClient();
  const { getAccountId } = useAuthStore();
  const accountId = getAccountId();

  return (
    <HoverPopover
      side="right"
      align="end"
      popOverContent={
        <>
          {model.activationStatus === ActivationStatus.NOT_AVAILABLE && (
            <LockedStateIcon className="w-10 h-10 -ml-1.5" />
          )}
          {model.activationStatus === ActivationStatus.REQUESTED && (
            <div className="h-10 w-10 -ml-1.5">
              <img src={UnlockGif} alt="unlock" />
            </div>
          )}
          {model.activationStatus === ActivationStatus.REQUESTED ? (
            <div>
              <div className="mt-4 font-medium text-tw-black-3">Activation in progress...</div>
              <div className="mt-2 text-xs leading-5 text-tw-black-7 h-36">
                Keep an eye on your inbox - our team will reach out to you soon to get additional
                details
              </div>
              <div className="w-full h-8 px-4 py-2 mt-6 text-xs font-semibold text-center"></div>
            </div>
          ) : (
            <>
              <div className="mt-4 font-medium text-tw-black-3">{model.name}</div>
              <div className="h-36">
                <div className="mt-2 text-xs leading-5 text-tw-black-7">
                  {model.description == null
                    ? MODELID_DESCRIPTION[model.modelId]
                    : model.description}
                </div>
                <div className="mt-4 text-xs leading-5 text-tw-black-7">
                  Click the button below and and keep an eye on your inbox - our team will reach out
                  shortly.
                </div>
              </div>
              <PrimaryButton
                spinnerSize="sm"
                isLoading={isLoading}
                onClick={() => {
                  requestModel(
                    {
                      modelId: model.id.toString(),
                    },
                    {
                      onSuccess: () => {
                        // manually invalidate the queryCache
                        // set activation_status of the current model as REQUESTED
                        const models: Model[] = queryClient.getQueryData(['models', accountId]);
                        queryClient.setQueryData(
                          ['models', accountId],
                          [
                            ...models.filter((m) => m.id !== model.id),
                            {
                              ...model,
                              activationStatus: ActivationStatus.REQUESTED,
                            },
                          ]
                        );
                      },
                    }
                  );
                }}
                className="justify-center w-full mt-6 text-xs text-center border-0 bg-tw-blue-0d text-tw-white-ff"
              >
                Activate
              </PrimaryButton>
            </>
          )}
        </>
      }
    >
      <Card isActive={false} onClick={() => noop()}>
        <div className="w-full cursor-pointer">
          <div className="text-tw-black-9">{getModelName(model, targetEntity as QueryType)}</div>
          <LockIcon className="absolute w-4 h-4 fill-current text-tw-gray-c top-2 right-2" />
        </div>
      </Card>
    </HoverPopover>
  );
}
