import * as AccordionPrimitive from '@radix-ui/react-accordion';
import useDsEnabled from 'hooks/useDsEnabled';
import useModels from 'hooks/useModels';
import useCohorts from 'pages/pql-workflow/hooks/useCohorts';
import { PQLWorkflowSteps, usePQLWorkflowStore } from 'pages/pql-workflow/store/PQLWorkflowStore';
import { forwardRef, RefObject } from 'react';
import { EditIcon, TickIconFilled } from 'ui-components/data-display/Icons';
import { capitalizeFirstLetter, numberWithPluralWord } from 'utils/common';
import { useAuth } from '../../../../hooks/useAuth';
import { DefinePQLSections, useDefinePQLStore } from '../../store/PQLDefineStore';
import SectionHeader from '../SectionHeader';
import DefineSubSections from './DefineSubSections';
import { modelToPerformanceVerbMap } from './SelectConversionEvent';

const Define = forwardRef((_, ref: RefObject<HTMLDivElement>) => {
  Define.displayName = 'DefinePQLComponent';

  const { currentSection, setCurrentSection, cohorts, performanceTrackEvent, leadStatus } =
    useDefinePQLStore();
  const { currentStep, setCurrentStep, setCompletedSteps, completedSteps } = usePQLWorkflowStore();
  const { targetEntity, modelId } = useDefinePQLStore();
  const { setExpandedSidebar } = usePQLWorkflowStore();
  const dsEnabled = useDsEnabled(modelId, targetEntity);

  const { data: models } = useModels();
  const { data: cohortsResponse } = useCohorts();
  const { user } = useAuth();

  return (
    <div className="w-full border rounded-xl overflow-clip border-tw-gray-eb" ref={ref}>
      {currentStep === PQLWorkflowSteps.DEFINE && (
        <>
          <SectionHeader number={1} title={'Define your business objective'} />
          <AccordionPrimitive.Root
            type="single"
            value={currentSection}
            onValueChange={(value: DefinePQLSections) => setCurrentSection(value)}
            collapsible
          >
            <DefineSubSections />
          </AccordionPrimitive.Root>
        </>
      )}
      {currentStep !== PQLWorkflowSteps.DEFINE && (
        <div
          className="flex justify-between px-6 py-6 bg-white cursor-pointer rounded-xl item-center group"
          onClick={() => {
            setCurrentStep(PQLWorkflowSteps.DEFINE);
            // mark current step as not finished
            setCompletedSteps({
              ...completedSteps,
              [PQLWorkflowSteps.DEFINE]: false,
            });
            // close the sidebar
            setExpandedSidebar(false);
          }}
        >
          <div className="flex gap-x-5">
            <TickIconFilled className="fill-current text-tw-green-39" />
            <div>
              <h4 className="text-base font-medium text-tw-black-7">
                Define your business objective
              </h4>
              <div className="text-tw-black-9">
                {targetEntity[0] + targetEntity.slice(1).toLowerCase()} | Finding{' '}
                {models?.find((m) => m.modelId === modelId).name.toLowerCase()} opportunities{' '}
                {dsEnabled &&
                  `| ${leadStatus.map((l) => capitalizeFirstLetter(l)).join(', ')} leads selected`}
                {!dsEnabled &&
                  `| ${modelToPerformanceVerbMap[modelId]}: ${
                    performanceTrackEvent?.value ?? 'N/A'
                  } `}
                {/* OVERRIDE_ENABLE_SYSTEM_COHORTS */}
                {user?.currentOrganization?.isPersonas && !dsEnabled && (
                  <span>
                    {cohorts && <span>|</span>}{' '}
                    {numberWithPluralWord(
                      cohortsResponse?.filter((c) => cohorts?.includes(c.id)).length,
                      'persona'
                    ) + ' selected'}{' '}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="items-center hidden group-hover:flex gap-x-2 text-tw-black-5">
            <EditIcon />
            <span>Edit</span>
          </div>
        </div>
      )}
    </div>
  );
});

export default Define;
