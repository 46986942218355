import { toast as toastify } from 'react-toastify';
import { useClient } from 'api-client';
import { useAuth } from 'hooks/useAuth';
import { useMutation } from 'react-query';
import { useQueryStore } from 'stores/QueryStore';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';

export default function useValidOrg() {
  const client = useClient();
  const { changeOrg } = useAuth();
  const { resetQueryData } = useQueryStore();

  return useMutation((schemaName: string) => client.validateOrg(schemaName), {
    onError: () => {
      toastify(<ErrorToast description="Please enter a valid organization" altText="" />, {
        type: 'error',
      });
    },
    onSuccess: (data: { [key: string]: string }) => {
      resetQueryData();
      changeOrg(data.orgId, data.schemaName);
    },
  });
}
