import * as Popover from '@radix-ui/react-popover';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getSourceImg } from '../../../utils/common';

export function getProfileEvents(entities) {
  return entities
    ?.filter((entity) => {
      return entity.SOURCE !== 'toplyne' && entity.KEYSPACE === 'profile_events';
    })
    ?.map((e) => {
      return {
        label: e.DISPLAY_NAME,
        value: e.COLUMN_NAME,
        type: e.DATA_TYPE,
        source: e.SOURCE,
        Icon: () => <img className="w-6 mr-2" src={getSourceImg(e.SOURCE)} alt={e.SOURCE} />,
      };
    });
}

export const MoreItemsPopover = (props) => {
  const { items } = props;
  const [showDialogOnHover, setShowDialogOnHover] = useState(false);
  const popoverContentRef = useRef(null);
  const cardComponentRef = useRef(null);
  const mouseOverTimerId = useRef(null);

  const mouseMoveCallback = useCallback(
    (e) => {
      if (
        !(
          popoverContentRef?.current?.contains(e.target) ||
          cardComponentRef?.current?.contains(e.target)
        )
      ) {
        if (mouseOverTimerId?.current !== undefined) {
          clearTimeout(mouseOverTimerId?.current);
        }
        mouseOverTimerId.current = setTimeout(() => {
          if (showDialogOnHover) {
            setShowDialogOnHover(false);
          }
        }, 20);
      }
    },
    [showDialogOnHover]
  );

  useEffect(() => {
    document.addEventListener('mousemove', mouseMoveCallback);
    return () => {
      clearTimeout(mouseOverTimerId?.current);
      document.removeEventListener('mousemove', mouseMoveCallback);
    };
  }, [mouseMoveCallback]);
  return (
    <Popover.Root open={showDialogOnHover}>
      <Popover.Anchor />
      <Popover.Trigger>
        <div
          className="text-blue-700"
          onMouseOver={() => {
            if (mouseOverTimerId?.current !== undefined) {
              clearTimeout(mouseOverTimerId?.current);
            }
            mouseOverTimerId.current = setTimeout(() => {
              setShowDialogOnHover(true);
            }, 20);
          }}
        >
          <div
            ref={cardComponentRef}
            className="ml-1 text-tw-black-9"
          >{`+ ${items?.length} more`}</div>
        </div>
      </Popover.Trigger>
      <Popover.Content
        ref={popoverContentRef}
        avoidCollisions
        side="top"
        align="start"
        className="w-64 -ml-2"
        onEscapeKeyDown={() => setShowDialogOnHover(false)}
        onInteractOutside={() => setShowDialogOnHover(false)}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="px-3 py-2 border rounded-md bg-tw-white-ff shadow-3xl drop-shadow-3xl border-tw-gray-eb">
          <div>
            {items.slice(0, 5).map((item) => {
              return (
                <div key={item} className="px-2 py-1 text-tw-black-5">
                  {item}
                </div>
              );
            })}
            {items.length > 5 && <div>{`... ${items.length - 6} More`}</div>}
          </div>
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};
