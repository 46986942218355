import { useCallback, useEffect } from 'react';

export function redirectUri(type: string, path: string) {
  return `${window.location.origin}/${path}/${type}/callback`;
}

export default function useOAuth(
  type: string,
  clientId: string,
  authorizationUrl: string,
  scope: string,
  path: string,
  callback: (Error, string) => void
) {
  useEffect(() => {
    const listener = (e: MessageEvent) => {
      if (e.origin !== window.location.origin) {
        return;
      }

      if (e.data.source) {
        return;
      }

      if (e.data.type === type) {
        callback(null, e.data.code);
      }
    };

    window.addEventListener('message', listener);

    return () => window.removeEventListener('message', listener);
  }, [type, callback]);

  return useCallback(() => {
    const popupTarget = new URL(authorizationUrl);
    popupTarget.searchParams.set('client_id', clientId);
    popupTarget.searchParams.set('redirect_uri', redirectUri(type, path));
    popupTarget.searchParams.set('scope', scope);
    popupTarget.searchParams.set('response_type', 'code');

    const popupWinHeight = 640;
    const popupWinWidth = 480;

    const left = (window.screen.width - popupWinWidth) / 2;
    const top = (window.screen.height - popupWinHeight) / 4;
    const features = `height=${popupWinHeight},width=${popupWinWidth},left=${left},top=${top},resizable,scrollbars,status`;

    const popup = window.open(popupTarget.toString(), type, features);

    if (popup) {
      popup.focus();
    } else {
      callback(
        new Error('Unable to open authorization popup. Please allow popus for this website'),
        null
      );
    }
  }, [authorizationUrl, clientId, type, path, scope, callback]);
}
