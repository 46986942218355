import { classNames } from '../../utils/common';

interface Props {
  count?: number;
  tailWHeight?: number;
}

const Skeleton = ({ count = 3, tailWHeight }: Props) => {
  return (
    <div className="w-full animate-pulse">
      {Array(count)
        .fill(0)
        .map((a, index) => (
          <div
            key={index}
            className={classNames(
              tailWHeight
                ? `w-full h-${tailWHeight} mt-2 even:bg-tw-gray-eb`
                : 'w-full h-4 mt-2 even:bg-tw-gray-eb'
            )}
          />
        ))}
    </div>
  );
};

export default Skeleton;
