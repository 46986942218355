import { OpportunityResponse } from 'ui-components/business/opportunities/types';
import { capitalizeFirstLetter, classNames } from 'utils/common';
import IntegrationIcon from 'pages/integrations/sub-components/IntegrationIcon';
import { OpportunitySchedule } from 'pages/opportunities/types';
import { useDefinePQLStore } from '../../pql-workflow/store/PQLDefineStore';
import { useFiltersPQLStore } from '../../pql-workflow/store/PQLFiltersStore';
import { useExportPQLStore } from '../../pql-workflow/store/PQLExportStore';
import { usePQLWorkflowStore } from '../../pql-workflow/store/PQLWorkflowStore';
import { useAuthStore } from '../../../hooks/useAuthStore';
import { useQueryClient } from 'react-query';
import { ReactNode } from 'react';
import dayjs from 'dayjs';
import { trim } from 'utils/helpers';
import { useHistory } from 'react-router-dom';
import { EditIcon } from '../../../ui-components/data-display/Icons';
import isEqual from 'lodash/isEqual';
import { getSourceImg } from '../../../utils/common';
import { IntegrationType } from '../../../api-client/index';
import useMeta from '../hooks/useMeta';
import { PQL_STATS_PAGE_PADDING_CLASSNAME } from '../NewOpDetails';
import { useAuth } from '../../../hooks/useAuth';

const formatAMPM = (date) => {
  let [hours, minutes] = date.split(':');
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes.toString().padStart(2, '0');
  let strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

function getMetadata(opportunity) {
  const destinationType = opportunity.exports[0].destination?.integration?.type;
  const metadata = opportunity.exports[0]?.metadata;
  switch (destinationType) {
    case IntegrationType.SALESFORCE:
      return metadata['salesforce']?.sObject;
    case IntegrationType.SALESFORCE_SANDBOX:
      return metadata['salesforceSandbox']?.sObject;
    case IntegrationType.HUBSPOT:
      return metadata['hubspot']?.object;
    case IntegrationType.PIPEDRIVE:
      return metadata['pipedrive']?.object;
    case IntegrationType.WEBHOOK:
      return 'N/A';
    case IntegrationType.EMAIL:
      return metadata['email']?.join(', ');
    default:
      return metadata[destinationType]?.entity;
  }
}

const CardContainer = (props: { children: ReactNode }) => {
  return (
    <div className="py-4 pl-4 pr-20 my-2 rounded-lg relative h-20 border-1 border-tw-gray-eb shrink-0">
      {props.children}
    </div>
  );
};

function getCadence(schedule: OpportunitySchedule, timezone): string {
  if (schedule.recurring) {
    const frequency = schedule?.recurring?.frequency;
    if (frequency.hourly) {
      if (Number(frequency.hourly?.days) <= 1) {
        return 'Hourly';
      }

      return `Every ${frequency.hourly?.days} hours`;
    } else if (frequency.daily) {
      return `Daily at ${formatAMPM(frequency.daily?.time)}`;
    } else if (frequency.weekly) {
      if (isEqual(frequency.weekly.days, ['MON', 'TUE', 'WED', 'THU', 'FRI'])) {
        return `Every weekday (Mon - Fri) at ${formatAMPM(frequency.weekly?.time)}`;
      } else {
        return `${frequency.weekly.days.join(', ')} at ${formatAMPM(frequency.weekly?.time)} `;
      }
    } else if (frequency.monthly) {
      return `Monthly Recurring on every ${frequency.monthly.days.join(', ')} at ${formatAMPM(
        frequency.monthly?.time
      )}`;
    }
    return `${capitalizeFirstLetter(Object.keys(schedule.recurring?.frequency)[0])} Recurring`;
  }
  return dayjs(schedule?.time).tz(timezone).format('DD MMM YYYY, hh:mm A');
}

const DestinationDetails = (props: { opportunity: OpportunityResponse }) => {
  const { opportunity } = props;
  const { user } = useAuth();

  return (
    <div className="flex gap-x-4">
      <CardContainer>
        <div className="text-tw-black-7">Destination</div>
        <div className="flex gap-2 mt-2 font-medium">
          <IntegrationIcon
            type={opportunity.destination?.type}
            logoUrl={opportunity.exports[0]?.destination?.integration?.logoUrl}
            width={16}
            height={16}
            style={{ marginTop: 3 }}
          />
          <div>{trim(opportunity.exports[0]?.destination?.integration?.name, 24)}</div>
        </div>
      </CardContainer>
      <CardContainer>
        <div className="text-tw-black-7">
          {opportunity?.exports[0]?.destination?.integration?.metadata?.integrationObjectName ||
            'Object'}
        </div>
        <div className="flex gap-2 mt-2 font-medium">
          <div>{trim(getMetadata(opportunity), 24)}</div>
        </div>
      </CardContainer>
      <CardContainer>
        <div className="text-tw-black-7">Schedule</div>
        <div className="flex gap-2 mt-2 font-medium">
          <div>
            {trim(getCadence(opportunity.schedule, user?.currentOrganization?.timezone), 40)}
          </div>
        </div>
      </CardContainer>
    </div>
  );
};

const FieldMappings = (props: { opportunity: OpportunityResponse }) => {
  const { opportunity } = props;
  const { reset: resetDefineStore } = useDefinePQLStore();
  const { reset: resetFiltersStore } = useFiltersPQLStore();
  const { reset: resetExportStore } = useExportPQLStore();
  const { reset: resetPQLWorkflowStore } = usePQLWorkflowStore();
  const queryClient = useQueryClient();
  const router = useHistory();
  const { getAccountId } = useAuthStore();
  const { data, isLoading } = useMeta(
    opportunity?.query.type,
    opportunity.query?.modelId,
    opportunity.query?.goal
  );
  if (isLoading) {
    return (
      <div className="py-8 z-5 h-100">
        <div className="bg-tw-gray-eb h-8 w-30 animate-pulse"></div>
        {Array(2)
          .fill(0)
          .map((idx) => {
            return (
              <div key={idx} className="flex gap-4">
                <div className="bg-tw-gray-eb w-3/5 h-8 animate-pulse my-4"></div>
                <div className="bg-tw-gray-eb w-3/5 h-8 animate-pulse my-4"></div>
              </div>
            );
          })}
      </div>
    );
  }

  return (
    <>
      <hr className="w-full my-6 border-b border-tw-gray-eb" />
      <div className="flex gap-x-4 sticky top-[72px] h-16 bg-tw-white-ff">
        <div className="flex w-full items-center justify-between  gap-x-4 z-[2]">
          <div className="flex gap-x-2 items-center">
            <span className="font-medium">Sync fields</span>
            <div className="w-6 h-6 bg-tw-blue-f2 px-2 rounded-lg grid place-items-center text-xs text-tw-black-5">
              <div className="text-tw-black-5">{opportunity.exports[0]?.fieldMapping?.length}</div>
            </div>
          </div>
          {opportunity.status !== 'completed' && (
            <div
              onClick={() => {
                queryClient.removeQueries(['opportunity']);
                resetDefineStore();
                resetFiltersStore();
                resetExportStore();
                resetPQLWorkflowStore();
                router.push(`/${getAccountId()}/opportunities/edit-exports/${opportunity.id}`);
              }}
              className="flex items-center gap-x-2 bg-tw-gray-f5 text-black font-medium px-3 py-2 rounded ml-5 cursor-pointer"
            >
              <EditIcon className="w-4 h-4" />
              <span>Edit sync fields</span>
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-12 w-full bg-tw-gray-f7 h-12 mt-4 rounded-lg">
        <div className="flex gap-x-4 col-span-6 font-weight-bold py-3 px-3">
          <img alt={'toplyne'} className="mx-1 w-6 h-6" src={getSourceImg('toplyne')} />
          <div>Field name in Toplyne</div>
        </div>
        <div className="flex gap-x-4 col-span-6 font-weight-bold py-3 px-3">
          <IntegrationIcon type={opportunity.destination.type} height={24} width={24} />
          <div>{`Field name in ${opportunity.exports[0]?.destination?.integration?.name}`}</div>
        </div>
      </div>
      <div className="pb-4">
        {opportunity?.exports[0]?.fieldMapping?.map((mapping, idx) => {
          return (
            <div
              key={mapping.columnName}
              className={classNames(
                'grid grid-cols-12 w-full py-4',
                idx !== opportunity.exports[0]?.fieldMapping?.length - 1
                  ? 'border-b-1 border-tw-gray-eb'
                  : 'border-b-1 border-transparent'
              )}
            >
              <div className="gap-x-4 py-3 px-3 h-12 col-span-6">
                <div>
                  {data?.sourceMeta?.data.find((metaItem) => {
                    return metaItem.COLUMN_NAME === mapping.columnName;
                  })?.DISPLAY_NAME || mapping.keyName}
                </div>
                {idx === 0 && (
                  <div className="text-xs text-tw-black-9 mt-2">
                    (Toplyne field to identify users)
                  </div>
                )}
              </div>
              <div className="col-span-6 py-3 px-3 flex gap-x-4 h-12">{mapping.keyAlias}</div>
            </div>
          );
        })}
      </div>
      {Object.keys(opportunity?.exports[0]?.tags).length > 0 && (
        <hr className="w-full my-6 border-b border-tw-gray-eb" />
      )}
      {Object.keys(opportunity?.exports[0]?.tags).length > 0 && (
        <div className="flex gap-x-2 items-center sticky top-[72px] h-16 bg-tw-white-ff">
          <span className="font-medium">Sync tags</span>
          <div className="w-6 h-6 bg-tw-blue-f2 px-2 rounded-lg grid place-items-center text-xs text-tw-black-5">
            {Object.keys(opportunity?.exports[0]?.tags).length}
          </div>
        </div>
      )}
      {Object.keys(opportunity?.exports[0]?.tags).length > 0 && (
        <div className="grid grid-cols-12 w-full bg-tw-gray-f7 h-12 mt-4 rounded-lg">
          <div className="flex gap-x-4 col-span-6 font-weight-bold py-3 px-3">
            <IntegrationIcon type={opportunity.destination.type} height={24} width={24} />
            <div>{`Field name in ${opportunity.exports[0]?.destination?.integration?.name}`}</div>
          </div>
          <div className="flex gap-x-4 col-span-6 font-weight-bold py-3 px-3">
            <div>{`Tag value`}</div>
          </div>
        </div>
      )}
      {Object.keys(opportunity?.exports[0]?.tags).length > 0 && (
        <div className="pb-4">
          {Object.keys(opportunity?.exports[0]?.tags).map((tag) => {
            return (
              <div
                key={tag}
                className={classNames(
                  'grid grid-cols-12 w-full py-4',
                  'border-b-1 border-tw-gray-eb'
                )}
              >
                <div className="gap-x-4 py-3 px-3 h-12 col-span-6">
                  <div>{tag}</div>
                </div>
                <div className="col-span-6 py-3 px-3 flex gap-x-4 h-12">
                  {opportunity?.exports[0]?.tags[tag]}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

type SyncDetailsTabProps = {
  opportunity: OpportunityResponse;
};

export default function SyncDetailsTab(props: SyncDetailsTabProps) {
  const { opportunity } = props;

  return (
    <div className={classNames('w-full z-5', PQL_STATS_PAGE_PADDING_CLASSNAME)}>
      <DestinationDetails opportunity={opportunity} />
      <FieldMappings opportunity={opportunity} />
    </div>
  );
}
