import { toast as toastify } from 'react-toastify';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
  CopyIcon,
  CrossIcon,
  EditIcon,
  MoreVertical,
  StartIcon,
  StopIcon,
} from 'ui-components/data-display/Icons';
import Modal from 'ui-components/util/modal';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';
import { SecondaryButton, TertiaryButton } from 'ui-components/inputs/Buttons';
import { classNames } from 'utils/common';
import { useAuthStore } from 'hooks/useAuthStore';
import useDeleteOpportunity from 'pages/opportunities/hooks/useDeleteOpportunity';
import useOpportunityAction from 'pages/opportunities/hooks/useOpportunityAction';
import { useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { useExportPQLStore } from 'pages/pql-workflow/store/PQLExportStore';
import { useFiltersPQLStore } from 'pages/pql-workflow/store/PQLFiltersStore';
import { usePQLWorkflowStore } from 'pages/pql-workflow/store/PQLWorkflowStore';
import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useAnalytics } from 'telemetry';
import { AnalyticsConst, AnalyticsEvents } from 'telemetry/constants';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';

const statusToActionMap = {
  active: ['edit', 'duplicate', 'stop', 'archive'],
  stopped: ['duplicate', 'start', 'archive'],
  failed: ['edit', 'duplicate', 'start', 'stop', 'archive'],
  running: ['edit', 'duplicate', 'stop', 'archive'],
  completed: ['duplicate', 'archive'],
  scheduled: ['edit', 'duplicate', 'stop', 'archive'],
};

function getStatusToActionMap(status, isSample) {
  if (isSample) {
    return statusToActionMap[status].filter((s) => {
      return s !== 'duplicate';
    });
  }
  return statusToActionMap[status];
}

type StatusItem = {
  label: string;
  icon: React.ReactElement;
  onClick: () => void;
  color: string;
};

type OpportunityActionDropdownProps = {
  id: number;
  isSample: boolean;
  name: string;
  status: string;
  sizeClassName: string;
  align: 'start' | 'center' | 'end';
  onChangeOpen?: (open: boolean) => void;
};

export default function OpportunityActionDropdown({
  id,
  name,
  status,
  sizeClassName,
  align,
  onChangeOpen,
  isSample,
}: OpportunityActionDropdownProps) {
  const router = useHistory();
  const deleteAnOpportunity = useDeleteOpportunity();
  const actionOnOpportunity = useOpportunityAction();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { track } = useAnalytics();

  const { reset: resetDefineStore } = useDefinePQLStore();
  const { reset: resetFiltersStore } = useFiltersPQLStore();
  const { reset: resetExportStore } = useExportPQLStore();
  const { reset: resetPQLWorkflowStore } = usePQLWorkflowStore();
  const { getAccountId } = useAuthStore();

  const queryClient = useQueryClient();

  const actionOpportunity = useCallback(
    (oppId: number, action: string) => {
      const param = { id: oppId, action: action };
      actionOnOpportunity.mutate(param, {
        onSuccess: () => {
          if (action === 'start') {
            toastify(<SuccessToast description="Successfully started the Playbook" />, {
              type: 'success',
            });
          } else if (action === 'stop') {
            toastify(<SuccessToast description="Successfully stopped the Playbook" />, {
              type: 'success',
            });
          } else {
            toastify(<SuccessToast description="Successfully executed the operation" />, {
              type: 'success',
            });
          }
        },
        onError: () => {
          toastify(
            <ErrorToast description="There was an error executing the requested operation" />
          );
        },
      });
    },
    [actionOnOpportunity]
  );

  const startOpportunity = useCallback(
    (oppId: number) => {
      actionOpportunity(oppId, 'start');
    },
    [actionOpportunity]
  );

  const stopOpportunity = useCallback(
    (oppId: number) => {
      actionOpportunity(oppId, 'stop');
    },
    [actionOpportunity]
  );

  const deleteOpportunity = (oppId: number) => {
    deleteAnOpportunity.mutate(oppId, {
      onSettled: () => {
        setIsDialogOpen(false);
      },
    });
  };

  const actionMap = useMemo<Record<string, StatusItem>>(() => {
    return {
      edit: {
        label: 'Edit Sync Fields',
        color: 'text-tw-black-3',
        icon: <EditIcon />,
        onClick: () => {
          queryClient.removeQueries(['opportunity']);
          resetDefineStore();
          resetFiltersStore();
          resetExportStore();
          resetPQLWorkflowStore();
          router.push(`/${getAccountId()}/opportunities/edit-exports/${id}`);
        },
      },
      duplicate: {
        label: 'Duplicate',
        color: 'text-tw-black-3',
        icon: <CopyIcon />,
        onClick: () => {
          track(AnalyticsEvents.CLICKED_OPPORTUNITY_DUPLICATE, {
            [AnalyticsConst.OPPORTUNITY_ID]: id,
            [AnalyticsConst.OPPORTUNITY_NAME]: name,
            [AnalyticsConst.TYPE]: 'duplicate',
          });
          queryClient.removeQueries(['opportunity']);
          resetDefineStore();
          resetFiltersStore();
          resetExportStore();
          resetPQLWorkflowStore();
          router.push(`/${getAccountId()}/opportunities/duplicate/${id}`);
        },
      },
      start: {
        label: 'Start',
        color: 'text-tw-black-3',
        icon: <StartIcon />,
        onClick: () => {
          startOpportunity(id);
        },
      },
      stop: {
        label: 'Stop',
        color: 'text-tw-black-3',
        icon: <StopIcon />,
        onClick: () => {
          stopOpportunity(id);
        },
      },
      archive: {
        label: 'Archive',
        color: 'text-tw-red-dd',
        icon: <CrossIcon className="fill-current " />,
        onClick: () => {
          setIsDialogOpen(true);
        },
      },
    };
  }, [id, name, router, startOpportunity, stopOpportunity, track]);

  return (
    <>
      <DropdownMenu.Root
        onOpenChange={(open) => {
          onChangeOpen && onChangeOpen(open);
        }}
      >
        <DropdownMenu.Trigger>
          <MoreVertical className={sizeClassName} />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content className="py-2 mb-8 bg-white shadow-lg" align={align}>
          {status &&
            getStatusToActionMap(status, isSample)?.map((s) => (
              <DropdownMenu.Item
                key={s}
                className="px-4 py-2 cursor-pointer hover:bg-tw-gray-f9"
                onClick={actionMap[s].onClick}
              >
                <div className={classNames('flex items-center h-6 gap-x-2', actionMap[s].color)}>
                  <div className="flex-none w-3">{actionMap[s].icon}</div>
                  <p>{actionMap[s].label}</p>
                </div>
              </DropdownMenu.Item>
            ))}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
      <Modal
        open={isDialogOpen}
        title={'Archive PQL'}
        onClose={() => {
          setIsDialogOpen(false);
          // fix to hide display buttons on closing the modal
          onChangeOpen && onChangeOpen(false);
        }}
      >
        <div className="w-full overflow-x-hidden">
          <div className={'p-6'}>
            <span className="px-2 py-1 text-base truncate bg-tw-gray-eb">{name}</span>
            <p className="my-2">
              You will not be able to recover a playbook once {`it's`} been archived. Are you sure?
            </p>
          </div>
          <hr className="w-full" />
          <div className="flex justify-end p-4 gap-x-4">
            <TertiaryButton onClick={() => setIsDialogOpen(false)}>Cancel</TertiaryButton>
            <SecondaryButton
              className="w-24 text-tw-red-dd"
              bgClassName="bg-tw-red-ff"
              borderClassName="border-tw-red-dd"
              isLoading={deleteAnOpportunity.isLoading}
              onClick={() => {
                deleteOpportunity(id);
              }}
            >
              Archive
            </SecondaryButton>
          </div>
        </div>
      </Modal>
    </>
  );
}

OpportunityActionDropdown.defaultProps = {
  sizeClassName: 'w-4',
  align: 'start',
};
