import { SearchIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import AttributeBtn from 'ui-components/business/attributes/AttributeBtn';
import { CrossIcon } from 'ui-components/data-display/Icons';
import useDebounce from 'hooks/useDebounce';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { ColumnType } from 'pages/users-and-accounts/store/AccountsStore';
import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useVirtual } from 'react-virtual';
import { QueryType } from 'stores/QueryStore';
import { ReactComponent as EmptyFolder } from '../../../assets/images/empty-folder.svg';
import Attribute from './Attribute';
import { ModalAttribute } from './types';

type Section = {
  name: string;
  value: string;
};

type AttributesListProps = {
  selectedProperties: ModalAttribute[];
  notSelectedProperties: ModalAttribute[];
  field_mapping: Record<string, ColumnType>;
  sections: Section[];
  currentSection: string;
  changeSection: (section: string) => void;
  isLoadingModal: boolean;
  modalRef?: any;
  entityType: QueryType;
  applySelectedAttribute: (attribute: ColumnType) => void;
  defaultSelectedColumns?: string[];
};

export default function AttributesList(props: AttributesListProps) {
  const {
    selectedProperties,
    notSelectedProperties,
    sections,
    currentSection,
    changeSection,
    field_mapping,
    isLoadingModal,
    applySelectedAttribute,
    defaultSelectedColumns,
  } = props;
  const parentRef = useRef();
  const [loadingEvents, setLoadingEvents] = useState(false);

  // current search space
  const [searchSpace, setSearchSpace] = useState<ModalAttribute[]>([]);

  // searchKey
  const [searchKey, setSearchKey] = useState('');

  // initially sets the search space
  useEffect(() => {
    setLoadingEvents(true);
    setSearchSpace([...selectedProperties, ...notSelectedProperties]);
    setLoadingEvents(false);
  }, [selectedProperties, notSelectedProperties]);

  const debouncedSearchTerm = useDebounce(searchKey, 500);

  const { height: viewportHeight } = useWindowDimensions();

  // execute debounced search
  useEffect(() => {
    if (debouncedSearchTerm) {
      setLoadingEvents(true);
      let attributes = [...selectedProperties, ...notSelectedProperties]?.filter(
        (atr) =>
          atr?.displayName.toLowerCase()?.includes(searchKey.toLowerCase()) ||
          atr?.source.toLowerCase()?.includes(searchKey.toLowerCase())
      );
      setLoadingEvents(false);
      setSearchSpace(attributes);
    } else {
      setSearchSpace([...selectedProperties, ...notSelectedProperties]);
    }
    setLoadingEvents(false);
  }, [debouncedSearchTerm, currentSection, searchKey, selectedProperties, notSelectedProperties]);

  // row virtualizer
  const rowVirtualizer = useVirtual({
    size: searchSpace?.length,
    parentRef,
    estimateSize: useCallback(() => 42, []),
  });

  const modalHeight = useMemo(() => (viewportHeight * 60) / 100, [viewportHeight]);

  return (
    <div
      className="pb-4"
      style={{
        height: modalHeight,
      }}
    >
      <div className="px-6">
        <InputGroup my={5}>
          <InputLeftElement>
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            fontSize="14px"
            onChange={(e) => setSearchKey(e.target.value)}
            value={searchKey}
            _focus={{ borderColor: 'inherit' }}
            placeholder="Search attributes"
          />
          <InputRightElement>
            <span className={'cursor-pointer'}>
              {searchKey && (
                <CrossIcon
                  className="w-2 fill-current text-tw-black-3"
                  onClick={() => setSearchKey('')}
                />
              )}
            </span>
          </InputRightElement>
        </InputGroup>
        <div className="flex items-center">
          {sections.map((sec) => (
            <AttributeBtn
              key={sec.name}
              isActive={sec.value === currentSection}
              title={sec.name}
              clicked={() => changeSection(sec.value)}
            />
          ))}
        </div>
      </div>
      {loadingEvents || isLoadingModal ? (
        <div className="flex px-6 flex-col mt-4 gap-y-2 animate-pulse h-5/6">
          {Array(10)
            .fill(0)
            .map((v, index) => (
              <div key={index} className="w-full h-10 bg-tw-gray-eb" />
            ))}
        </div>
      ) : searchSpace.length > 0 ? (
        <div
          ref={parentRef}
          className="mt-4 overflow-y-scroll px-6"
          style={{
            // 120px is the height of searchbar and buttons
            // in attributes list.
            height: modalHeight - 120,
          }}
        >
          <div
            style={{
              height: `${rowVirtualizer.totalSize}px`,
              position: 'relative',
            }}
          >
            {rowVirtualizer.virtualItems.map((virtualRow) => {
              let atr = searchSpace[virtualRow.index];
              return (
                <div
                  key={virtualRow.index}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    // Positions the virtual elements at the right place in container.
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                >
                  <Attribute
                    key={`${atr.source},${atr.keyName},${atr.keySpace},${atr.displayName},${
                      field_mapping[atr.keyName]
                    }`}
                    attribute={{
                      displayName: atr.displayName,
                      source: atr.source,
                      keyName: atr.keyName,
                      keySpace: atr.keySpace,
                      columnName: atr.columnName,
                      isSystem: atr.isSystem,
                      dataType: atr.dataType,
                      // keyAlias is the same as keyName
                      keyAlias: atr.keyName,
                    }}
                    isEnabled={
                      !!field_mapping[atr.keyName] ||
                      !!defaultSelectedColumns?.includes(atr.keyName)
                    }
                    onToggle={(attr) => applySelectedAttribute(attr)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="grid pt-20 place-items-center text-tw-black-5">
          <EmptyFolder />
          <p className="mt-4">No search results</p>
        </div>
      )}
    </div>
  );
}
