import { toast as toastify } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { AxiosError, useClient, WebhookConnect } from '../../../api-client';
import { useAuthStore } from 'hooks/useAuthStore';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';

export default function useEditWebhook(id: number | string) {
  const queryClient = useQueryClient();
  const client = useClient();
  const router = useHistory();
  const { getAccountId } = useAuthStore();

  return useMutation(
    (data: { name: string; webhook: WebhookConnect }) =>
      client.updateIntegration(id, {
        name: data.name,
        connect: {
          webhook: data.webhook,
        },
      }),
    {
      onSuccess: async (data) => {
        await queryClient.refetchQueries(['integrations', 'connected']);
        await queryClient.refetchQueries(['webhook', data?.data?.id.toString()]);
        toastify(<SuccessToast description="Sucessfully edited the webhook" />, {
          type: 'success',
        });
        router.push(`/${getAccountId()}/integrations?type=webhooks`);
      },
      onError: (error: AxiosError) => {
        const errorMessage = error?.response?.data?.detail ?? '';
        toastify(<ErrorToast description={errorMessage || 'Uh oh! Something went wrong'} />, {
          type: 'error',
        });
      },
    }
  );
}
