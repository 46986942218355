import InitialsAvatar from 'ui-components/data-display/InitialsAvatar';
import { ErrorBoundary } from 'react-error-boundary';
import { transformText } from 'utils/common';
import { useAuth } from 'hooks/useAuth';
import { useAuthStore } from 'hooks/useAuthStore';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { TertiaryButton } from '../../../ui-components/inputs/Buttons';
import SettingsSideNav from '../SettingsSideNav';
import { ErrorAnalyticsEvents } from 'telemetry/constants';
import ErrorBoundaryFallback from 'ui-components/feedback/ErrorBoundaryFallback';
import { useAnalytics } from 'telemetry';

interface MemberProfileProps {}

const MemberProfile: React.FC<MemberProfileProps> = () => {
  const { getAccountId } = useAuthStore();

  const { user: data, isLoading } = useAuth();
  const router = useHistory();

  const { track } = useAnalytics();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={(e) =>
        track(ErrorAnalyticsEvents.ERROR_OCCURED, {
          error: e.message,
        })
      }
    >
      <div className="flex w-full mt-4">
        <SettingsSideNav />
        <div className="w-full px-6 py-4 mr-3 bg-white rounded">
          <div className="flex items-center justify-between">
            <p className="mb-0 text-base font-normal uppercase text-tw-gray-7">Profile</p>
            <TertiaryButton
              type="button"
              onClick={() => router.push(`/${getAccountId()}/settings/edit-profile`)}
            >
              Edit Profile
            </TertiaryButton>
          </div>
          <hr className="my-4 border-b border-tw-gray-eb" />
          <div className="py-3">
            <div className="grid grid-cols-5 gap-3 mb-8">
              <div className="flex items-center text-sm text-tw-gray-7">Avatar</div>
              <div className="col-span-4">
                {!isLoading && (
                  <>
                    {data?.avatarName && data?.avatarName !== 'initials' && (
                      <img
                        src={`https://assets.toplyne.io/avatars/${data?.avatarName}.png`}
                        alt={data?.avatarName}
                        className="w-16 h-16 border-solid rounded-full border-1 border-tw-gray-e7"
                      />
                    )}
                    {(!data?.avatarName || data?.avatarName === 'initials') && (
                      <div className="w-16 h-16">
                        <InitialsAvatar user={data} />
                      </div>
                    )}
                  </>
                )}
                {isLoading && (
                  <div className="w-16 h-16 rounded-full animate-pulse bg-tw-gray-eb" />
                )}
              </div>
            </div>
            <div>
              <div className="grid grid-cols-5 gap-3 mb-6">
                <div className="text-sm text-tw-gray-7">Name</div>
                {!isLoading &&
                  (data?.firstName && data?.lastName ? (
                    <div className="col-span-4 text-sm text-tw-black-1">
                      {data?.firstName} {data?.lastName}
                    </div>
                  ) : (
                    <div className="col-span-4 text-sm text-tw-gray-7">NA</div>
                  ))}
                {isLoading && <div className="w-40 h-8 bg-tw-gray-eb animate-pulse" />}
              </div>
              <div className="grid grid-cols-5 gap-3 mb-6">
                <div className="text-sm text-tw-gray-7">Email</div>
                {!isLoading && (
                  <div className="col-span-4 text-sm text-tw-black-1">{data?.email}</div>
                )}
                {isLoading && <div className="w-40 h-8 bg-tw-gray-eb animate-pulse" />}
              </div>
              <div className="grid grid-cols-5 gap-3">
                <div className="text-sm text-tw-gray-7">Role</div>
                {!isLoading && (
                  <div className="col-span-4 text-sm text-tw-black-1">
                    {transformText(data?.currentRole['role'])}
                  </div>
                )}
                {isLoading && <div className="w-40 h-8 bg-tw-gray-eb animate-pulse" />}
              </div>
            </div>
          </div>
          <hr className="my-4 border-b border-tw-gray-eb" />
          <div className="flex items-center justify-between mt-8">
            <div>
              <p className="mb-0 text-base font-normal text-tw-black-3">Password</p>
              <span className="text-sm text-tw-black-9">
                Your abra-ca-dabra to login to Toplyne 🪄
              </span>
            </div>
            <TertiaryButton
              type="button"
              onClick={() => router.push(`/${getAccountId()}/settings/change-password`)}
            >
              Change Password
            </TertiaryButton>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default MemberProfile;
