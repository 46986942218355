import { SearchIcon } from '@chakra-ui/icons';
import _debounce from 'lodash/debounce';
import * as React from 'react';
import { FC, useEffect, useRef, useState } from 'react';
import MenuVirtualized from '../../navigation/MenuVirtualized';
import { Option } from '../../util/types';

interface Props {
  onChange: any;
  value: any;
  closeMenu: Function;
  optionsArray: { label: string; value: string }[];
  isMenuOpen: boolean;
  render?: (option: Option) => any;
  parentWidth?: string;
}

const MenuOptionsSearch: FC<Props> = (props) => {
  const { onChange, value, closeMenu, optionsArray, isMenuOpen, render, parentWidth } = props;
  const [options, setOptions] = useState<Props['optionsArray']>(optionsArray);
  const ref = useRef<HTMLInputElement>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const onSearch = _debounce((input: string) => {
    setOptions(() =>
      optionsArray?.filter(({ label }) => label.toLowerCase().includes(input.toLowerCase()))
    );
  }, 300);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setSearchInput(input);
    onSearch(input);
  };
  useEffect(() => {
    if (isMenuOpen) {
      setTimeout(() => ref?.current?.focus(), 100);
    }
  }, [ref, isMenuOpen]);

  useEffect(() => {
    setOptions(optionsArray);
  }, [optionsArray]);

  return (
    <div className="px-2 product-tour-16">
      <div className="flex items-center w-full pl-2 pr-1 mt-2 mb-4 rounded-sm border-1 border-tw-gray-c product-tour-15 product-tour-19">
        <input
          className="w-full py-1 focus:outline-none"
          onChange={handleChange}
          value={searchInput}
          ref={ref}
        />
        <SearchIcon />
      </div>
      <MenuVirtualized
        parentHeight={`${Math.min(280, options?.length * 40)}px`}
        childHeight={40}
        totalRows={options?.length}
        childValues={options}
        onChange={onChange}
        value={value}
        closeMenu={closeMenu}
        render={render}
        parentWidth={parentWidth}
      />
    </div>
  );
};

export default MenuOptionsSearch;
