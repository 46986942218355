import { ArrowBack, ArrowRight } from 'ui-components/data-display/Icons';
import { SecondaryButton } from 'ui-components/inputs/Buttons';
import HoverPopover from 'ui-components/business/HoverPopover';
import Toggle from 'ui-components/inputs/Switch';
import ToolTip from 'ui-components/data-display/Tooltip';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useAuth } from 'hooks/useAuth';
import { useAuthStore } from 'hooks/useAuthStore';
import { noop } from 'lodash';
import useActivateOrganization from 'hooks/useActivateOrganization';
import { AnimatePing, SampleDataBanner } from 'pages/opportunities/Opportunities';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { routes } from 'routing/clientRoutes';
import { classNames } from '../../../utils/common';
import useInterval from './hooks/useInterval';
import NavDropdown from './NavDropdown';
import { useAnalytics } from '../../../telemetry';
import { AnalyticsConst, AnalyticsEvents } from '../../../telemetry/constants';

dayjs.extend(utc);
dayjs.extend(timezone);

interface TopNavBarProps {}

interface TopNavProps {
  Component?: React.FC | null;
}

const navHeader = (pathName: string) => {
  if (pathName.endsWith('opportunities/list')) {
    return 'Playbooks';
  } else if (pathName.endsWith('users') || pathName.endsWith('accounts')) {
    return 'Users & Accounts';
  } else if (pathName.endsWith('integrations')) {
    return 'Integrations';
  } else if (pathName.endsWith('next-steps')) {
    return '';
  } else if (pathName.endsWith('member-profile')) {
    return 'Settings';
  } else if (pathName.endsWith('team-members')) {
    return 'Settings';
  } else if (pathName.endsWith('api')) {
    return 'Settings';
  } else {
    return 'Back';
  }
};

const TopNav: React.FC<TopNavProps> = ({ children }) => {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { organization } = useActivateOrganization();
  const showSampleDataBanner =
    user?.currentOrganization.isSandbox &&
    (pathname.includes(routes.users) ||
      pathname.includes(routes.accounts) ||
      pathname.includes(routes.createOpportunity) ||
      pathname.includes(routes.duplicateOpportunity) ||
      (pathname.includes(routes.opportunitiesList) && organization?.isActive));
  return (
    <div>
      <nav
        className={classNames(
          'z-10 flex items-center justify-between w-full px-6 py-2 bg-white gap-x-8 border-tw-gray-ef border-b'
        )}
      >
        {children}
      </nav>
      {showSampleDataBanner && <SampleDataBanner />}
    </div>
  );
};

const TopNavTimeZone = () => {
  const { user: data, isLoading } = useAuth();
  const [date, setDate] = useState(dayjs());

  useInterval(
    () => {
      setDate(dayjs());
    },
    5000 //doing the update in 5 sec here, since it's not that urgent
  );

  useEffect(() => {
    dayjs.tz.setDefault(data?.currentOrganization?.timezone);
  }, [data, data?.currentOrganization?.timezone]);
  return (
    <ToolTip label="Account timezone" placement="bottom">
      {!isLoading ? (
        <p className="text-tw-black-7">
          {dayjs.tz(date, data?.currentOrganization?.timezone).format('hh:mm A')} (
          {data?.currentOrganization?.timezone})
        </p>
      ) : (
        <span className="w-40 h-10 bg-tw-gray-eb animate-pulse"></span>
      )}
    </ToolTip>
  );
};

const TopNavBar: React.FC<TopNavBarProps> = () => {
  const [header, setHeader] = useState('');
  const router = useHistory();
  const { track } = useAnalytics();
  const { user, changeOrg } = useAuth();
  const { getAccountId } = useAuthStore();
  const { activateButtonCTA, activateButtonLoading, organization, isActivateBannerTextError } =
    useActivateOrganization();

  React.useEffect(() => {
    setHeader(navHeader(router.location.pathname));
  }, [router.location.pathname]);

  const { logout } = useAuth(false);

  return (
    <TopNav>
      {/* <div className="text-xl text-tw-gray-7">4:30 PM IST</div> */}
      <div
        className={classNames(
          'flex items-center text-base font-medium text-tw-black-3 gap-x-4',
          header === 'Back' ? 'cursor-pointer' : ''
        )}
        onClick={() => {
          if (header === 'Back') {
            router.goBack();
          }
        }}
      >
        <div className="flex items-center gap-x-2">
          {header === 'Back' && <ArrowBack className="w-3" />}
          <span>{header}</span>
        </div>
        {user?.currentOrganization?.isSandbox && !organization?.isActive && (
          <SecondaryButton
            onClick={(e) => {
              e.stopPropagation();
              router.push(`/${getAccountId()}/activate`);
            }}
            fontClassName="font-medium text-sm"
            bgClassName={activateButtonLoading ? 'bg-tw-gray-f5' : 'bg-tw-blue-f2'}
            className={classNames(activateButtonLoading ? 'text-tw-black-3' : 'text-tw-blue-0d')}
          >
            {(activateButtonLoading || isActivateBannerTextError) && (
              <AnimatePing
                className={classNames(isActivateBannerTextError ? 'bg-tw-red-dd' : 'bg-tw-gray-c')}
              />
            )}
            <span>{activateButtonCTA}</span>
            {!activateButtonLoading && <ArrowRight className="fill-current text-tw-blue-0d" />}
          </SecondaryButton>
        )}
      </div>
      <div className="flex items-center gap-x-5">
        {user?.organizations?.find((o) => o.isSandbox) && (
          <>
            {!organization?.isActive && (
              <HoverPopover
                side="bottom"
                align="end"
                // marginClassName="mt-3"
                popOverContent={
                  <div>
                    Please{' '}
                    <Link
                      className="font-medium text-tw-blue-0d hover:text-tw-blue-0d"
                      to={'/activate'}
                    >
                      Activate your account
                    </Link>{' '}
                    to access live mode
                  </div>
                }
              >
                <div className="flex items-center py-2 mr-2 gap-x-3">
                  <span className="text-tw-black-7">Test mode</span>
                  <div
                    className={classNames(
                      !organization?.isActive && 'opacity-50 cursor-not-allowed'
                    )}
                  >
                    <Toggle
                      enabled={user?.currentOrganization?.isSandbox}
                      setEnabled={() => noop()}
                    />
                  </div>
                </div>
              </HoverPopover>
            )}
            {organization?.isActive && (
              <div className="flex items-center mr-2 gap-x-3">
                <span className="text-tw-black-7">Test mode</span>
                <div
                  className={classNames(!organization?.isActive && 'opacity-50 cursor-not-allowed')}
                >
                  <Toggle
                    enabled={user?.currentOrganization?.isSandbox}
                    setEnabled={() => {
                      track(AnalyticsEvents.TOGGLE_TEST_LIVE, {
                        [AnalyticsConst.ORGANIZATION]: user?.currentOrganization?.name,
                      });
                      if (user?.currentOrganization?.isSandbox) {
                        changeOrg(organization.orgId, organization.schemaName);
                      } else {
                        const sandboxOrg = user?.organizations?.find((o) => o.isSandbox);
                        changeOrg(sandboxOrg.orgId, sandboxOrg.schemaName);
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}
        {!user?.currentOrganization?.isSandbox && <TopNavTimeZone />}
        <NavDropdown handleSignOut={logout} />
      </div>
    </TopNav>
  );
};

export default TopNavBar;
