import * as Dialog from '@radix-ui/react-dialog';
import { CrossIconWithoutBg } from 'ui-components/data-display/Icons';
import { classNames } from 'utils/common';

type TransitionModalProps = {
  title?: string | React.ReactNode;
  trigger: React.ReactNode;
  children: React.ReactNode;
  onClose: any;
  open: boolean;
  closeIcon: boolean;
  width: string;
  hrRequired?: boolean;
  className?: string;
  closeOnOutsideClick: boolean;
};

export enum ModalWidth {
  xl = 'w-3/4',
  lg = 'w-1/2',
  md = 'w-1/3',
  sm = 'w-1/4',
}

const Modal = ({
  trigger,
  title,
  children,
  onClose,
  open,
  closeIcon,
  width,
  hrRequired,
  className,
  closeOnOutsideClick,
}: TransitionModalProps) => {
  return (
    <Dialog.Dialog open={open}>
      <Dialog.DialogTrigger style={{ WebkitAppearance: 'none' }} asChild>
        <div>{trigger}</div>
      </Dialog.DialogTrigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-40 flex flex-col items-center justify-center bg-tw-black-50">
          <Dialog.DialogContent
            className={classNames(
              'bg-white rounded-lg focus:outline-none',
              width,
              (width === ModalWidth.md || width === ModalWidth.sm) && `xl:w-2/5 2xl:w-1/3`,
              'max-h-4/5 overflow-y-hidden',
              width === ModalWidth.lg && `xl:w-7/12 2xl:w-3/6`,
              width === ModalWidth.xl && `xl:w-3/4 2xl:w-1/2`,
              className
            )}
            onPointerDownOutside={closeOnOutsideClick && onClose}
          >
            {title && (
              <Dialog.DialogTitle className="flex items-center justify-between p-6 text-base font-medium bg-tw-gray-f5">
                <h3>{title}</h3>
                {closeIcon && (
                  <Dialog.DialogClose asChild>
                    <div
                      className="flex justify-center w-6 h-6 p-2 rounded-full cursor-pointer bg-tw-gray-eb"
                      onClick={() => onClose()}
                    >
                      <CrossIconWithoutBg className="-mt-0.5 fill-current border-0 w-3 h-3 text-tw-black-7 mx-auto" />
                    </div>
                  </Dialog.DialogClose>
                )}
              </Dialog.DialogTitle>
            )}
            {hrRequired && <hr className={'w-full'} />}
            {children}
          </Dialog.DialogContent>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Dialog>
  );
};

Modal.defaultProps = {
  onClose: () => {},
  open: false,
  trigger: <></>,
  closeIcon: true,
  width: ModalWidth.md,
  hrRequired: true,
  className: '',
  closeOnOutsideClick: true,
};

export default Modal;
