import { PrimaryButton } from 'ui-components/inputs/Buttons';
import ComboBox from 'ui-components/inputs/Combobox';
import dayjs from 'dayjs';
import { useAuth } from 'hooks/useAuth';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useAnalytics } from 'telemetry';
import { AnalyticsEvents } from 'telemetry/constants';
import useActivateOrganization from '../../hooks/useActivateOrganization';
import useUpdateOrganization from './hooks/useUpdateOrganization';
import { ActivateSteps, useActivateStore } from './store/ActivateStore';
import supportedTimezones from './timezones.json';

type SelectTimezoneFormState = {
  timezone: {
    id: string;
    name: string;
  };
};

const getTimezoneWithOffset = (timeZone: string, date = new Date()): string => {
  const tz = dayjs.tz(date, timeZone).format('Z');
  return `(UTC${tz}) ${timeZone}`;
};

const getMatchingTimezone = (timezone: string) => {
  if (supportedTimezones.includes(timezone)) {
    return timezone;
  }
  const tzOffset = dayjs().tz(timezone).format('Z');
  return supportedTimezones.find((tz) => dayjs().tz(tz).format('Z') === tzOffset);
};

type TimezoneSelectProps = {
  label: string;
  name: string;
};

const getSupportedTimezonesWithLabel = (): Array<TimezoneSelectProps> => {
  const timezones = supportedTimezones.map((timezone) => ({
    label: getTimezoneWithOffset(timezone),
    name: timezone,
  }));

  const sortedNegatives = timezones
    .filter((timezone) => timezone.label.startsWith('(UTC-'))
    .sort((a: TimezoneSelectProps, b: TimezoneSelectProps) => {
      const aOffset = a.label.split(')')[0].replace('(UTC-', '');
      const bOffset = b.label.split(')')[0].replace('(UTC-', '');
      return parseFloat(bOffset.replace(':', '.')) - parseFloat(aOffset.replace(':', '.'));
    });

  const sortedPositives = timezones
    .filter((timezone) => timezone.label.startsWith('(UTC+'))
    .sort((a: TimezoneSelectProps, b: TimezoneSelectProps) => {
      const aOffset = a.label.split(')')[0].replace('(UTC+', '');
      const bOffset = b.label.split(')')[0].replace('(UTC+', '');
      return parseFloat(aOffset.replace(':', '.')) - parseFloat(bOffset.replace(':', '.'));
    });

  return [...sortedNegatives, ...sortedPositives];
};

export default function TimezoneSelect() {
  const { user } = useAuth();
  const timezones = getSupportedTimezonesWithLabel();
  const updateOrganization = useUpdateOrganization();
  const { track } = useAnalytics();
  const { setStep: changeStep } = useActivateStore();
  const { organization } = useActivateOrganization();

  const { handleSubmit, control, setValue, getValues } = useForm({
    defaultValues: {
      timezone: { id: '', name: '' },
    },
  });

  const onSubmit: SubmitHandler<SelectTimezoneFormState> = (data) => {
    updateOrganization.mutate(
      {
        schemaName: organization.schemaName,
        timezone: data?.timezone?.id,
      },
      {
        onSuccess: () => {
          track(AnalyticsEvents.TIMEZONE_SELECTED, {
            timezone: data?.timezone?.id,
          });
          changeStep(ActivateSteps.sources);
        },
      }
    );
  };

  useEffect(() => {
    if (!getValues('timezone.id') && user?.currentOrganization?.timezone) {
      const timezone = getMatchingTimezone(user?.currentOrganization?.timezone);
      setValue('timezone', {
        id: timezone,
        name: timezones?.find((t) => t.name === timezone)?.label,
      });
    }
  }, [user?.currentOrganization?.timezone, timezones]);

  return (
    <div className="flex flex-col items-center w-2/3 mx-auto mt-4">
      <h2 className="text-2xl font-medium text-center">Select your account time zone</h2>
      <p className="mt-2 text-center text-tw-black-9">
        We need this information to sync leads in your GTM tools as per your timezone
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="w-4/5 mt-7">
        <Controller
          name={'timezone'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <>
                <ComboBox
                  placeholder="Timezone"
                  data={timezones?.map((t) => ({ id: t.name, name: t.label }))}
                  field={field}
                />
                <PrimaryButton
                  isLoading={updateOrganization.isLoading}
                  disabled={!field.value?.id}
                  className="w-full py-2 mt-9"
                >
                  Proceed
                </PrimaryButton>
              </>
            );
          }}
        />
      </form>
    </div>
  );
}
