import { useCallback, useMemo } from 'react';
import { useDefinePQLStore } from '../store/PQLDefineStore';
import { LeadStatus, useFiltersPQLStore } from '../store/PQLFiltersStore';
import useCohortCount from './useCohortCount';
import useCohortTabs from './useCohortTabs';
import useOpportunityQuery from './useOpportunityQuery';

export default function useTotalUsersAggregate() {
  const { exportCategory, salesCapacity, percentage } = useFiltersPQLStore();
  const { leadStatus } = useDefinePQLStore();
  const { data } = useOpportunityQuery();
  const { allUsersCohortItem } = useCohortTabs();
  const { data: cohortCounts } = useCohortCount();

  const getTotalUsersAggregate = useCallback(() => {
    let newData;
    if (leadStatus.length > 0) {
      if (leadStatus?.[0] === LeadStatus.ALL) {
        newData = data?.count;
      } else {
        newData = leadStatus
          .map((lead) => data?.leadDistribution?.[lead] || 0)
          .reduce((a, b) => a + b, 0);
      }
    } else {
      newData = data?.count;
    }
    if (!salesCapacity && !percentage) {
      return newData;
    }

    if (salesCapacity) {
      if (salesCapacity > data?.count) {
        return newData;
      }
      return salesCapacity;
    } else if (percentage) {
      return Math.floor((newData * percentage) / 100);
    }

    return newData;
  }, [exportCategory, leadStatus, data, salesCapacity, percentage]);

  const countFromLeadStatus = useMemo(() => {
    if (leadStatus.includes(LeadStatus.ALL)) {
      return data?.count;
    } else {
      return leadStatus.reduce((a, b) => a + data?.leadDistribution?.[b], 0);
    }
  }, [data, leadStatus]);

  const distributeHWC = useCallback(
    (_leadStatus, newCapacity, hwcMap) => {
      for (const lead of _leadStatus) {
        if (newCapacity > data?.leadDistribution[lead]) {
          hwcMap[lead] = data?.leadDistribution[lead];
          newCapacity -= data?.leadDistribution[lead];
        } else {
          hwcMap[lead] = newCapacity;
          newCapacity = 0;
        }
      }
      return hwcMap;
    },
    [data]
  );

  const getHWCDistribution = useCallback(() => {
    if (!salesCapacity && !percentage) {
      return data?.leadDistribution;
    }

    let hwcMap = {
      [LeadStatus.HOT]: 0,
      [LeadStatus.WARM]: 0,
      [LeadStatus.COLD]: 0,
    };
    let newLeadStatus = leadStatus?.includes(LeadStatus.ALL)
      ? [LeadStatus.HOT, LeadStatus.WARM, LeadStatus.COLD]
      : leadStatus;
    if (salesCapacity) {
      let newCapacity = salesCapacity;

      hwcMap = distributeHWC(newLeadStatus, newCapacity, hwcMap);
    } else if (percentage) {
      let count = Math.floor((percentage * countFromLeadStatus) / 100);
      hwcMap = distributeHWC(newLeadStatus, count, hwcMap);
    }

    return hwcMap;
  }, [exportCategory, leadStatus, data, salesCapacity, percentage]);

  const getPersonaDistribution = useCallback((): Record<string, number> => {
    let personaCountMap = {};
    cohortCounts
      ?.filter((c) => c.id !== allUsersCohortItem?.id)
      .forEach((c) => {
        personaCountMap[c.id] = c.count;
      });

    if (!salesCapacity && !percentage) {
      return personaCountMap;
    }

    if (salesCapacity) {
      for (const key in personaCountMap) {
        personaCountMap[key] = Math.floor((personaCountMap[key] * salesCapacity) / data?.count);
      }
    } else if (percentage) {
      for (const key in personaCountMap) {
        personaCountMap[key] = Math.floor((personaCountMap[key] * percentage) / 100);
      }
    }

    return personaCountMap;
  }, [exportCategory, data, salesCapacity, percentage, cohortCounts]);

  return {
    getTotalUsersAggregate,
    getHWCDistribution,
    getPersonaDistribution,
    countFromLeadStatus,
  };
}
