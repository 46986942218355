import { animate } from 'framer-motion';
import usePrevious from 'hooks/usePreviousValue';
import { useEffect, useRef } from 'react';

export function AnimatedNumber({ to, duration }: { to: number; duration: number }) {
  const nodeRef = useRef(null);

  const prevTo = usePrevious(to) || 0;

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(prevTo, to, {
      duration: duration,
      onUpdate(value) {
        node.textContent = Math.floor(value).toLocaleString('en-US');
      },
    });

    return () => controls.stop();
  }, [to]);

  return <span ref={nodeRef} />;
}

AnimatedNumber.defaultProps = {
  duration: 1,
};
