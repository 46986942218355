import Toggle from 'ui-components/inputs/Switch';
import { getSourceImg } from 'utils/common';
import { ColumnType } from 'pages/users-and-accounts/store/AccountsStore';

interface AttributeProps {
  attribute: {
    displayName: string;
    source: string;
    keyName: string;
    keySpace: string;
    columnName: string;
    isSystem: boolean;
    dataType: string;
    keyAlias: string;
  };
  isEnabled?: boolean;
  onToggle: (attribute: ColumnType) => void;
}

export default function Attribute({ attribute, isEnabled, onToggle }: AttributeProps) {
  const { displayName, source } = attribute;

  return (
    <div className="flex items-center justify-between h-12 px-1 py-2 text-base text-tw-black-1 gap-x-2">
      <div className="flex items-center w-80 gap-x-2">
        <img alt="source-logo" className="h-tw-8 w-tw-8" src={getSourceImg(source)} />
        <div className="truncate">{displayName}</div>
      </div>
      <Toggle enabled={isEnabled || false} setEnabled={() => onToggle(attribute)} />
    </div>
  );
}
