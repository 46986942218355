import { FC } from 'react';
import { useAuth } from 'hooks/useAuth';
import { useAuthStore } from 'hooks/useAuthStore';
import { Route } from 'react-router-dom';

interface Props {
  render: any;
  path: string;
}

const AuthRoute: FC<Props> = (props) => {
  useAuth();
  useAuthStore();

  return <Route {...props} />;
};

export default AuthRoute;
