import { OpportunityStatus } from 'api-client';
import dayjs from 'dayjs';
import { capitalizeFirstLetter } from 'utils/common';
import { OpportunitySchedule } from './types';

type DisplayPQLCadenceProps = {
  schedule: OpportunitySchedule;
  status: string;
  timezone?: string;
  lastRun: string;
  nextRun: string;
};

export enum CadenceType {
  ONE_TIME = 'one_time',
  RECURRING = 'recurring',
}

export function getCadenceType(schedule: OpportunitySchedule): string | null {
  if (!schedule) return null;

  if (schedule.time) {
    return CadenceType.ONE_TIME;
  }

  if (schedule.recurring) {
    return CadenceType.RECURRING;
  }

  return null;
}

// Display PQL cadence
export default function DisplayPQLCadence(props: DisplayPQLCadenceProps) {
  const { schedule, status, timezone, lastRun, nextRun } = props;
  const isRecurring = getCadenceType(schedule) === CadenceType.RECURRING;
  const isOneTime = getCadenceType(schedule) === CadenceType.ONE_TIME;

  if (!timezone || (!isRecurring && !isOneTime) || !schedule) {
    return <div className="inline-flex items-center justify-center h-4"></div>;
  }

  if (isOneTime) {
    return (
      <div className="flex items-center justify-center h-5 my-auto">
        {lastRun && (
          <div>
            Last run{' '}
            <span className="font-medium text-tw-black-3">
              {dayjs(lastRun).tz(timezone).fromNow()}
            </span>
          </div>
        )}
        {status === OpportunityStatus.SCHEDULED && nextRun && lastRun && (
          <div className="mx-1 w-1 h-1 bg-tw-gray-7 rounded-full"></div>
        )}
        {status === OpportunityStatus.SCHEDULED && nextRun && (
          <div>
            First run at{' '}
            <span className="font-medium text-tw-black-3">
              {dayjs(nextRun).format('DD MMM')}, {timezone}{' '}
              {dayjs(nextRun).tz(timezone).format('hh:mm A')}
            </span>
          </div>
        )}
        {((status === OpportunityStatus.SCHEDULED && nextRun) || lastRun) && status && (
          <div className="mx-2 w-1 h-1 bg-tw-gray-7 rounded-full"></div>
        )}
        {status &&
          capitalizeFirstLetter(status === OpportunityStatus.SCHEDULED ? 'Pending' : status)}
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center h-5 my-auto">
      {lastRun && (
        <div>
          Last run{' '}
          <span className="font-medium text-tw-black-3">
            {dayjs(lastRun).tz(timezone).fromNow()}
          </span>
        </div>
      )}
      {lastRun && isRecurring && status !== OpportunityStatus.STOPPED && nextRun && (
        <div className="mx-2 w-1 h-1 bg-tw-gray-7 rounded-full"></div>
      )}
      {isRecurring && status !== OpportunityStatus.STOPPED && nextRun && (
        <div>
          {status === OpportunityStatus.SCHEDULED ? 'First' : 'Next'} run at{' '}
          {nextRun && (
            <span className="font-medium text-tw-black-3">
              {dayjs(nextRun).format('DD MMM')}, {timezone}{' '}
              {dayjs(nextRun).tz(timezone).format('hh:mm A')}
            </span>
          )}
        </div>
      )}
      {((isRecurring && status !== OpportunityStatus.STOPPED && nextRun) || lastRun) && status && (
        <div className="mx-2 w-1 h-1 bg-tw-gray-7 rounded-full"></div>
      )}
      {status && capitalizeFirstLetter(status === OpportunityStatus.SCHEDULED ? 'Pending' : status)}
    </div>
  );
}
