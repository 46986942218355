import { useClient } from 'api-client';
import useModels from 'hooks/useModels';
import { useQuery } from 'react-query';
import { QueryType } from 'stores/QueryStore';
import { useDefinePQLStore } from '../store/PQLDefineStore';
import { useFiltersPQLStore } from '../store/PQLFiltersStore';

export type CohortCountResponse = {
  count: number;
  convertedCount: number;
  hotLeadCount: number;
  id: number;
};

export default function useCohortCount() {
  const client = useClient();
  const { data: models } = useModels();
  const { modelId, targetEntity } = useDefinePQLStore();
  const { filters } = useFiltersPQLStore();

  return useQuery<Array<CohortCountResponse>>(
    [
      'cohort',
      'count',
      modelId,
      targetEntity,
      JSON.stringify(filters.map((f) => `${f.columnName}_${f.op}_${JSON.stringify(f.value)}`)),
    ],
    () =>
      client.getCohortCounts({
        type: targetEntity as QueryType,
        goal: models.find((m) => m.modelId === modelId)?.goal.toUpperCase(),
        modelId: modelId,
        filter: filters,
      }),
    {
      enabled: !!modelId && !!targetEntity && !!models,
    }
  );
}
