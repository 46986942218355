import { useIntegrationListStore } from '../stores/IntegrationListStore';
import * as React from 'react';
import useIntegrations from './useIntegrations';
import { useAuth } from '../../../hooks/useAuth';
import { checkIfDateIsOlderThanAMonth } from '../../../utils/common';
import { useMemo } from 'react';
import { ConnectedIntegrationStatus } from '../../../api-client';

export default function useFilteredIntegrations() {
  const {
    searchQuery,
    availableIntegrationsBasedOnCategory,
    inactiveIntegrationsBasedOnCategory,
    connectedIntegrationsBasedOnCategory,
  } = useIntegrationListStore();
  const { data: integrations } = useIntegrations();
  const { user: userData } = useAuth();

  const [categoriesMap, setCategoriesMap] = React.useState({});

  React.useEffect(() => {
    let categories = [];

    if (availableIntegrationsBasedOnCategory) {
      categories.push(...Object.keys(availableIntegrationsBasedOnCategory));
    }
    if (inactiveIntegrationsBasedOnCategory) {
      categories.push(...Object.keys(inactiveIntegrationsBasedOnCategory));
    }
    if (connectedIntegrationsBasedOnCategory) {
      categories.push(...Object.keys(connectedIntegrationsBasedOnCategory));
    }
    categories = [...new Set(categories)];

    let items = [];

    categories?.map((category) => {
      if (!items[category]) {
        items[category] = {};
      }

      const availableApps = (availableIntegrationsBasedOnCategory?.[category] || [])?.filter(
        (i) =>
          !i.default &&
          i.type !== 'webhook' &&
          i.type !== 'api' &&
          i.available &&
          i.supportsWrite &&
          i.active &&
          i?.name?.toLowerCase().includes(searchQuery)
      );

      const requestedApps = (inactiveIntegrationsBasedOnCategory?.[category] || [])
        ?.filter((element) => {
          const orgs = element?.requestedBy?.map((subElement) => subElement.organization);
          if (orgs.includes(userData?.currentOrganization?.name)) {
            return element;
          }
        })
        ?.filter((integration) => integration?.name?.toLowerCase().includes(searchQuery));

      const inactiveApps = (inactiveIntegrationsBasedOnCategory?.[category] || [])
        ?.filter(
          (integration) =>
            integration?.status === 'inactive' &&
            integration?.name?.toLowerCase().includes(searchQuery)
        )
        ?.filter((el) => {
          return requestedApps?.indexOf(el) < 0;
        });

      const newlyLaunchedApps = (integrations || [])?.filter(
        (i) =>
          !i.default &&
          i.type !== 'webhook' &&
          i.available &&
          i.supportsWrite &&
          i.active &&
          i?.name?.toLowerCase().includes(searchQuery) &&
          checkIfDateIsOlderThanAMonth(i?.createdAt)
      );

      const popularApps = (integrations || [])?.filter(
        (i) =>
          !i.default &&
          i.type !== 'webhook' &&
          i.available &&
          i.supportsWrite &&
          i.active &&
          i?.popular &&
          i?.name?.toLowerCase().includes(searchQuery)
      );

      const connectedApps = (connectedIntegrationsBasedOnCategory?.[category] || [])
        ?.filter((i) => {
          if (!i.integration.supportsWrite) {
            return false;
          }
          if (['webhook', 'api', 'email'].includes(i.type)) {
            return false;
          }
          return !(i.type === 'tray' && i.status !== ConnectedIntegrationStatus.READY);
        })
        ?.filter((integration) => integration?.name?.toLowerCase().includes(searchQuery));

      items[category]['available'] = availableApps;
      items[category]['requested'] = requestedApps;
      items[category]['inactive'] = inactiveApps;
      items[category]['connected'] = connectedApps;
      items['Newly launched'] = newlyLaunchedApps;
      items['Popular'] = popularApps;
    });

    setCategoriesMap(items);
  }, [
    availableIntegrationsBasedOnCategory,
    connectedIntegrationsBasedOnCategory,
    inactiveIntegrationsBasedOnCategory,
    integrations,
    searchQuery,
    userData?.currentOrganization?.name,
  ]);

  return useMemo(() => ({ categoriesMap }), [categoriesMap]);
}
