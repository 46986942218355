import { OpportunityStatus } from 'api-client';
import dayjs from 'dayjs';
import useEntitiesQuery from 'hooks/useEntitiesQuery';
import { useSyncScroller } from 'hooks/useSyncScroll';
import { getCohortIconAndColor } from 'pages/pql-workflow/components/define-pql/Cohort';
import UserAccountsTable from 'pages/users-and-accounts/components/UserAccountsTable';
import { ColumnType } from 'pages/users-and-accounts/store/AccountsStore';
import { Entity, SortOrder } from 'pages/users-and-accounts/users/types';
import { useCallback, useEffect, useLayoutEffect, useMemo, useReducer, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { Cell, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { toast as toastify } from 'react-toastify';
import { QueryType } from 'stores/QueryStore';
import { useAnalytics } from 'telemetry';
import { OpportunityResponse } from 'ui-components/business/opportunities/types';
import {
  CaretDown,
  DeleteTableResults,
  EmptyFolder,
  SandboxUserStats,
  ScheduleIcon,
} from 'ui-components/data-display/Icons';
import { Search } from 'ui-components/inputs/Search/Search';
import {
  capitalizeFirstLetter,
  classNames,
  convertInOrgTimezone,
  getEntityAccessor,
  getEntityHeader,
  getTableCell,
  updateCellWidthMap,
} from 'utils/common';
import { ReactComponent as LeftArrowIcon } from '../../../assets/images/left-arrow.svg';
import { ReactComponent as RightArrowIcon } from '../../../assets/images/right-arrow.svg';
import useActivateOrganization from '../../../hooks/useActivateOrganization';
import { useAuth } from '../../../hooks/useAuth';
import { useAuthStore } from '../../../hooks/useAuthStore';
import { AnalyticsConst, AnalyticsEvents } from '../../../telemetry/constants';
import ErrorToast from '../../../ui-components/feedback/Toasts/ErrorToast';
import { SecondaryButton } from '../../../ui-components/inputs/Buttons';
import AccountAttributesModal from '../../users-and-accounts/accounts/accounts-list/account-list/AccountAttributesModal';
import { AccountFields, UserFields } from '../../users-and-accounts/fields';
import UserAttributesModal from '../../users-and-accounts/users/users-list/UserAttributesModal';
import useGetSyncedUsers from '../hooks/useGetSyncedUsers';
import { useSyncedUsersStore } from '../store/SyncedUsersStore';
import useAllCohorts from '../../../hooks/useAllCohorts';

const MAP_COLUMN_TO_META = {
  persona_now: 'macro_persona',
};

export default function SyncedEntities({ opportunity }: { opportunity: OpportunityResponse }) {
  // @group: data store.
  const {
    page,
    setPage,
    searchQuery,
    setSearchQuery,
    sortBy,
    setSortBy,
    field_mapping,
    initFieldMappings,
    removeFieldMapping,
    addFieldMapping,
  } = useSyncedUsersStore();
  const { getAccountId } = useAuthStore();
  const { user: userData } = useAuth();

  // @group: data fetch
  const { data, isLoading, isFetching, isError } = useGetSyncedUsers(
    opportunity?.id,
    opportunity?.exports?.[0]?.id,
    !(
      userData?.currentOrganization?.isSandbox ||
      opportunity?.status === OpportunityStatus.SCHEDULED ||
      opportunity?.isSample ||
      opportunity?.status === OpportunityStatus.FAILED
    )
  );
  const { data: entityFields, isLoading: loadingEntityFields } = useEntitiesQuery(
    opportunity?.query?.type?.toLowerCase() as Entity
  );
  // @group: state declarations
  const [cellWidthMap, setCellWidthMap] = useReducer(updateCellWidthMap, {} as any);
  const [columnHovered, setColumnHovered] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  // @group: side-effects
  useEffect(() => {
    // initialise synced entity field_mappings.
    let initialMappings = {};
    entityFields
      ?.filter((entityField) =>
        opportunity?.query?.filter?.find((f) => f.columnName === entityField.columnName)
      )
      .map((mapping) => (initialMappings[mapping.keyName] = mapping));
    initFieldMappings(initialMappings);
  }, [opportunity?.query, entityFields, initFieldMappings]);

  // @group: custom hooks
  const { cohortItems, allUsersCohortItem } = useAllCohorts();
  const { track } = useAnalytics();
  const { organization } = useActivateOrganization();
  const { user } = useAuth();

  // @group: sync scroll refs
  const headersRef = useSyncScroller('syncedUsers');
  const tableRef = useSyncScroller('syncedUsers');
  // @group: Table helpers.
  const columns = useMemo(() => {
    return [
      {
        Header: getEntityHeader(opportunity?.query?.type),
        accessor: getEntityAccessor(opportunity?.query?.type),
        Cell: ({ cell }) => getTableCell(cell.value, cell.column.id),
        sticky: 'left',
      },
      {
        Header: 'Qualified on',
        accessor: 'qualified_on',
        Cell: (row: Cell) => <div>{dayjs(row.value).tz().format('DD MMM, YYYY')}</div>,
      },
      {
        Header: 'Persona when qualified',
        accessor: 'persona_when_qualified',
        Cell: ({ cell }) => {
          return (
            <div className="flex items-center">
              <div>
                {
                  getCohortIconAndColor(
                    cohortItems
                      ?.filter((c) => c.name !== allUsersCohortItem.name)
                      .find((c) => c.filters?.[0].value.includes(cell.value))?.name
                  ).icon
                }
              </div>
              <div className="ml-1 capitalize">
                {cell.value
                  ? cell.value.replace('Users', opportunity.query?.type).toLowerCase()
                  : 'N/A'}
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Persona now',
        accessor: 'persona_now',
        Cell: ({ cell }) => {
          return (
            <div className="flex items-center">
              <div>
                {
                  getCohortIconAndColor(
                    cohortItems
                      ?.filter((c) => c.name !== allUsersCohortItem.name)
                      .find((c) => c.filters?.[0].value.includes(cell.value))?.name
                  ).icon
                }
              </div>
              <div className="ml-1 capitalize">
                {' '}
                {cell.value
                  ? cell.value.replace('Users', opportunity.query?.type).toLowerCase()
                  : 'N/A'}
              </div>
            </div>
          );
        },
      },
      // TODO handle target entity persona columns
      // rest columns depending on field_mappings.
      ...Object.keys(field_mapping).map((fm) => ({
        Header: capitalizeFirstLetter(field_mapping[fm]?.displayName),
        accessor: fm,
        Cell: ({ cell }) => {
          if (field_mapping[fm]?.keySpace === 'profile_events')
            return getTableCell(cell.row.original[fm], cell.column.id, field_mapping[fm]?.keySpace);
          return getTableCell(cell.row.original[fm], cell.column.id);
        },
      })),
    ];
  }, [allUsersCohortItem?.name, cohortItems, field_mapping, opportunity?.query?.type]);
  const tableInstance = useTable(
    {
      columns: columns as any,
      data: data?.data || [],
    },
    useSticky
  );
  // get the cell width
  // and apply it to the headers
  useLayoutEffect(() => {
    if (!isLoading && !isFetching && !loadingEntityFields) {
      tableInstance?.columns?.map((column) => {
        const el = document.getElementsByClassName(`td-${column.id}`);
        setCellWidthMap({
          type: 'update',
          payload: {
            id: column.id,
            width: el?.[0]?.getClientRects()[0].width,
            height: el?.[0]?.getClientRects()[0].height,
          },
        });
      });
    }
  }, [isFetching, isLoading, tableInstance?.columns, loadingEntityFields]);
  const DEFAULT_SYNCED_USER_COLUMNS = useMemo(
    () => [
      user?.currentOrganization?.[UserFields.USER_ID] || 'user_id',
      'persona_now',
      'qualified_on',
      'persona_when_qualified',
    ],
    [user?.currentOrganization]
  );
  const DEFAULT_SYNCED_ACCOUNTS_COLUMNS = useMemo(
    () => [
      user?.currentOrganization?.[AccountFields.ACCOUNT_ID] || 'account_id',
      'persona_now',
      'qualified_on',
      'persona_when_qualified',
    ],
    [user?.currentOrganization]
  );

  const getDefaultSyncedUsersColumns: (keyName: string) => boolean = useCallback(
    (keyName: string) => {
      return DEFAULT_SYNCED_USER_COLUMNS?.includes(keyName) ||
        DEFAULT_SYNCED_ACCOUNTS_COLUMNS.includes(keyName)
        ? true
        : false;
    },
    [DEFAULT_SYNCED_USER_COLUMNS, DEFAULT_SYNCED_ACCOUNTS_COLUMNS]
  );

  // callback after applying/disapplying an attribute
  // from attributes modal
  const applySelectedAttribute = (attr: ColumnType) => {
    const { source, keyName, keySpace, displayName } = attr;
    if (
      DEFAULT_SYNCED_USER_COLUMNS.includes(keyName) ||
      DEFAULT_SYNCED_ACCOUNTS_COLUMNS.includes(keyName)
    ) {
      toastify(<ErrorToast description="Cannot remove a default account column" altText="" />, {
        type: 'error',
      });
      return;
    }

    if (!field_mapping[keyName]) {
      // mutate with adding field_mapping
      addFieldMapping(attr);

      track(AnalyticsEvents.COLUMN_ADDED, {
        [AnalyticsConst.ATTRIBUTES]: {
          keyName: {
            keyAlias: keyName,
            keyName: keyName,
            source: source,
            keySpace: keySpace,
            displayName: displayName,
          },
        },
      });
    } else {
      if (getDefaultSyncedUsersColumns(keyName)) {
        toastify(<ErrorToast description="Cannot remove a default account column" altText="" />, {
          type: 'error',
        });
      } else {
        // mutate with remove field_mapping
        removeFieldMapping(attr.keyName);

        track(AnalyticsEvents.COLUMN_DELETED, {
          [AnalyticsConst.ATTRIBUTES]: {
            keyName: {
              keyAlias: keyName,
              keyName: keyName,
              source: source,
              keySpace: keySpace,
              displayName: displayName,
            },
          },
        });
      }
    }
  };

  if (user?.currentOrganization?.isSandbox) {
    return (
      <div className={classNames('pl-48 2xl:pl-64 pr-8')}>
        <SandboxUserStats />
        <div className="mt-6 text-base text-center ">
          {!organization?.isActive && (
            <>
              <Link to={`/${getAccountId()}/activate`}>
                <span style={{ color: '#0D6EFD', fontWeight: 500 }}> Activate your account</span>
              </Link>
              <span className="ml-1">to see synced users/accounts in a playbook</span>
            </>
          )}
          {organization?.isActive && (
            <>
              <span>Synced users are not available for playbooks created in Test Mode</span>
            </>
          )}
        </div>
      </div>
    );
  }

  if (opportunity?.status === OpportunityStatus.SCHEDULED) {
    return (
      <div>
        <div className="grid my-20 overflow-x-scroll overflow-y-hidden place-items-center text-tw-black-7">
          <ScheduleIcon className="w-20 h-20" />
          <div className="mt-5 text-lg font-medium text-tw-black-1">
            This Playbook has not run yet
          </div>
          <div className="text-tw-black-7">
            You’ll be able to see synced users once the playbook runs,{' '}
            {opportunity?.nextRun
              ? 'on ' +
                convertInOrgTimezone(opportunity?.nextRun, user?.currentOrganization?.timezone)
              : 'in the future'}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('pl-48 2xl:pl-64 pr-8')}>
      {isLoading || isFetching || !opportunity || loadingEntityFields ? (
        <div className="h-[21px] bg-tw-gray-eb w-1/5"></div>
      ) : (
        <div className="font-medium">
          {new Intl.NumberFormat('en-us', {
            compactDisplay: 'short',
            notation: 'standard',
          }).format(data?.count || 0)}{' '}
          {opportunity?.query?.type?.toLowerCase() || 'users'} qualified
        </div>
      )}
      <div className="flex items-center justify-between">
        <Search
          placeholder={`Search by ${capitalizeFirstLetter(
            opportunity?.query?.type?.toLowerCase()?.slice(0, -1)
          )} ID`}
          value={searchQuery}
          onChangeFunction={(e) => setSearchQuery(e.target.value)}
          onClearFunction={() => setSearchQuery('')}
          className="mt-4"
          hasShadow={false}
        />
        <SecondaryButton
          className="mt-4 py-[11px] rounded-lg text-tw-blue-0d"
          onClick={() => setModalOpen(true)}
        >
          Add Attributes
        </SecondaryButton>
        {opportunity?.query?.type === QueryType.accounts ? (
          <AccountAttributesModal
            isOpen={modalOpen}
            setIsOpen={(open: boolean) => setModalOpen(open)}
            field_mapping={field_mapping}
            applySelectedAttribute={(attr: ColumnType) => applySelectedAttribute(attr)}
            defaultSelectedColumns={DEFAULT_SYNCED_ACCOUNTS_COLUMNS.map(
              (defaultColumn) => MAP_COLUMN_TO_META[defaultColumn] ?? defaultColumn
            )}
          />
        ) : (
          <UserAttributesModal
            isOpen={modalOpen}
            setIsOpen={(open: boolean) => setModalOpen(open)}
            field_mapping={field_mapping}
            applySelectedAttribute={(attr: ColumnType) => applySelectedAttribute(attr)}
            defaultSelectedColumns={DEFAULT_SYNCED_USER_COLUMNS.map(
              (defaultColumn: string) => MAP_COLUMN_TO_META[defaultColumn] ?? defaultColumn
            )}
          />
        )}
      </div>
      <div
        className={classNames('bg-tw-gray-f7 w-full mt-6 userlist-header overflow-hidden')}
        ref={headersRef}
      >
        {tableInstance?.headerGroups?.map((headerGroup, index) => {
          return (
            // use inline-flex to fix sticky scroll.
            <div
              key={index}
              className={classNames(
                'inline-flex items-center grow-1',
                data?.data?.length === 0 ? '!flex w-full' : ''
              )}
            >
              {headerGroup.headers.map((column) => {
                const sortFilterApplied = sortBy?.find(
                  (sortByFilter) => sortByFilter?.keyName === column?.id
                );

                return (
                  <div
                    key={column.id}
                    style={{
                      minWidth: cellWidthMap[column.id]?.width
                        ? cellWidthMap[column.id]?.width
                        : 250,
                      minHeight: cellWidthMap[column.id]?.height
                        ? cellWidthMap[column.id]?.height - 12
                        : 52,
                      maxHeight: cellWidthMap[column.id]?.height
                        ? cellWidthMap[column.id]?.height - 12
                        : 52,
                    }}
                    className={classNames(
                      'border-x-1 border-transparent pl-3 !text-md font-medium flex items-center justify-between pr-2 grow cursor-pointer',
                      columnHovered === column.id
                        ? '!bg-tw-gray-eb cursor-pointer border-tw-gray-eb'
                        : '',
                      column.id === 'user_id' || column.id === 'account_id'
                        ? 'sticky z-[3] left-0 bg-tw-gray-f7 border-r-1 border-l-0 border-tw-gray-eb'
                        : 'z-0'
                    )}
                    onMouseOver={() => {
                      setColumnHovered(column.id);
                    }}
                    onMouseLeave={() => {
                      setColumnHovered(null);
                    }}
                    onClick={() => {
                      setColumnHovered(column.id);
                      // also sort the table.
                      let sf: null | {
                        keyName: string;
                        order: SortOrder;
                      } = sortFilterApplied ? { ...sortFilterApplied } : null;

                      if (sf) {
                        if (sf.order === SortOrder.ascending) {
                          // sort descending
                          setSortBy([
                            ...(sortBy || []).filter((s) => s.keyName !== column.id),
                            {
                              keyName: sf.keyName,
                              order: SortOrder.descending,
                            },
                          ]);
                        } else {
                          // sort normal
                          setSortBy([...(sortBy || []).filter((s) => s.keyName !== column.id)]);
                        }
                      } else {
                        // no sort applied, sort ascending
                        setSortBy([
                          ...(sortBy || []).filter((s) => s.keyName !== column.id),
                          {
                            keyName: column.id,
                            order: SortOrder.ascending,
                          },
                        ]);
                      }
                    }}
                  >
                    {column.Header}
                    {columnHovered === column.id ? (
                      <div className="flex items-center mr-1 rounded bg-tw-gray-eb">
                        {sortFilterApplied ? (
                          <div>
                            {sortFilterApplied?.order === SortOrder.ascending ? (
                              <div
                                className="py-1 px-1.5 border-1 border-tw-gray-c rounded"
                                // ascending -> descending
                                onClick={() => {
                                  setSortBy([
                                    ...(sortBy || []).filter((sf) => sf.keyName !== column.id),
                                    {
                                      keyName: column.id,
                                      order: SortOrder.descending,
                                    },
                                  ]);
                                }}
                              >
                                <CaretDown className="h-1.5 w-1.5 rotate-180 text-tw-black-9 fill-current" />
                                <CaretDown className="h-1.5 w-1.5 text-tw-gray-eb fill-current" />
                              </div>
                            ) : (
                              <div
                                className="py-1 px-1.5 border-1 border-tw-gray-c rounded"
                                // descending -> normal
                                onClick={() => {
                                  setSortBy([
                                    ...(sortBy || []).filter((sf) => sf.keyName !== column.id),
                                  ]);
                                }}
                              >
                                <CaretDown className="h-1.5 w-1.5 rotate-180 text-tw-gray-eb fill-current" />
                                <CaretDown className="h-1.5 w-1.5 text-tw-black-9 fill-current" />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div
                            className="py-1 px-1.5 border-1 border-tw-gray-c rounded bg-tw-white-ff"
                            onClick={() => {
                              setSortBy([
                                ...(sortBy || []).filter((sf) => sf.keyName !== column.id),
                                {
                                  keyName: column.id,
                                  order: SortOrder.ascending,
                                },
                              ]);
                            }}
                          >
                            <CaretDown className="h-1.5 w-1.5 rotate-180 text-tw-black-9 fill-current" />
                            <CaretDown className="h-1.5 w-1.5 text-tw-black-9 fill-current" />
                          </div>
                        )}
                        {/* Delete columns which are additional. */}
                        {!getDefaultSyncedUsersColumns(column.id) && (
                          <div className="p-1 ml-1 rounded bg-tw-white-ff border-1 border-tw-gray-c">
                            <DeleteTableResults
                              className="w-3 h-3 fill-current text-tw-black-9"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                removeFieldMapping(column.id);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      // Column actions are taking 82px
                      <div className="w-[50px] h-[22px]"></div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      {isLoading || isFetching || !opportunity || loadingEntityFields ? (
        <div className="mt-4 animate-pulse">
          {Array(6)
            .fill(0)
            .map((item, idx) => {
              return <div key={idx} className="w-full h-10 mb-4 bg-tw-gray-eb"></div>;
            })}
        </div>
      ) : (
        <div>
          <div className="relative w-full overflow-x-scroll" ref={tableRef}>
            <UserAccountsTable
              tableInstance={tableInstance as any}
              columnHovered={columnHovered}
              entityType={opportunity?.query?.type || QueryType.users}
              defaultColumns={['user_id', 'account_id']}
            />
            <div
              className={classNames(
                'max-w-full h-[35vh] relative',
                data?.data?.length === 0 || isError ? '' : 'hidden'
              )}
            >
              {(data?.data?.length === 0 || isError) && (
                <div
                  className="h-full overflow-scroll bg-tw-white-ff hide-scroll"
                  style={{
                    width: 250 * columns.length,
                  }}
                >
                  <div className="absolute ml-[42%] overflow-y-hidden my-24 text-tw-black-7">
                    <EmptyFolder className="w-20 h-20" />
                    No users found
                  </div>
                </div>
              )}
            </div>
            <div className="mt-10">
              {data?.count > 0 && !isFetching && (
                <ReactPaginate
                  forcePage={page - 1}
                  pageCount={Math.ceil(data?.count / 50)}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={({ selected }) => {
                    setPage(selected + 1);
                    window.scrollTo({ top: 0, behavior: 'auto' });
                    // TODO: Telemetry
                  }}
                  containerClassName="flex items-center justify-start"
                  activeClassName="children:bg-tw-gray-eb children:text-tw-black-5 children:font-medium"
                  pageLinkClassName="px-tw-3 py-2 rounded text-tw-black-5 hover:text-tw-black-5 font-medium"
                  breakLinkClassName="px-tw-3 py-2"
                  previousLabel={
                    <div className="flex items-center py-2 gap-x-2 px-tw-3 text-black-3">
                      <LeftArrowIcon className="fill-current" />
                      <span>Prev</span>
                    </div>
                  }
                  nextLabel={
                    <div className="flex items-center py-2 gap-x-2 px-tw-3 text-black-3">
                      <span>Next</span>
                      <RightArrowIcon className="fill-current" />
                    </div>
                  }
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
