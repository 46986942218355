import { ActivationStatus, EntityResponse } from 'api-client';
import UnlockGif from 'assets/gifs/unlock.gif';
import { LockedStateIcon, LockIcon } from 'ui-components/data-display/Icons';
import { PrimaryButton } from 'ui-components/inputs/Buttons';
import HoverPopover from 'ui-components/business/HoverPopover';
import { noop } from 'lodash';
import useModifyEntityActivationStatus from 'pages/pql-workflow/hooks/useModifyEntityActivationStatus';
import { useQueryClient } from 'react-query';
import { ENTITY_DESCRIPTION } from 'utils/constants';
import Card from '../../../../ui-components/business/Card';

export default function LockedEntity({ entity }: { entity: EntityResponse }) {
  const { mutate: requestEntity, isLoading } = useModifyEntityActivationStatus(
    ActivationStatus.REQUESTED
  );

  const queryClient = useQueryClient();

  return (
    <HoverPopover
      align="end"
      side="right"
      popOverContent={
        <>
          {entity.activationStatus === ActivationStatus.NOT_AVAILABLE && (
            <LockedStateIcon className="w-10 h-10 -ml-1.5" />
          )}
          {entity.activationStatus === ActivationStatus.REQUESTED && (
            <div className="h-10 w-10 -ml-1.5">
              <img src={UnlockGif} alt="unlock" />
            </div>
          )}
          {entity.activationStatus === ActivationStatus.REQUESTED ? (
            <div>
              <div className="mt-4 font-medium text-tw-black-3">We’ll be right with you</div>
              <div className="h-36">
                <div className="mt-2 text-xs leading-5 text-tw-black-7">
                  Keep an eye on your inbox - our team will be in touch to get this started 🚀
                </div>
              </div>
              <div className="w-full h-8 px-4 py-2 mt-6 text-xs font-semibold text-center"></div>
            </div>
          ) : (
            <>
              <div className="mt-4 font-medium text-tw-black-3">{entity.name}</div>
              <div className="h-36">
                <div className="mt-2 text-xs leading-5 text-tw-black-7">
                  {ENTITY_DESCRIPTION[entity.id]}
                </div>
                <div className="mt-4 text-xs leading-5 text-tw-black-7">
                  Click the button below and and keep an eye on your inbox - our team will reach out
                  shortly.
                </div>
              </div>
              <PrimaryButton
                isLoading={isLoading}
                onClick={() => {
                  requestEntity(
                    {
                      entityId: entity.id,
                    },
                    {
                      onSuccess: () => {
                        // manually invalidate the queryCache
                        // set activation_status of the current entity as REQUESTED
                        const entities: EntityResponse[] = queryClient.getQueryData(['entities']);
                        queryClient.setQueryData(
                          ['entities'],
                          [
                            ...entities.filter((e) => e.id !== entity.id),
                            {
                              ...entity,
                              activationStatus: ActivationStatus.REQUESTED,
                            },
                          ]
                        );
                      },
                    }
                  );
                }}
                className="justify-center w-full mt-6 text-xs text-center border-0 bg-tw-blue-0d text-tw-white-ff"
                spinnerSize="sm"
              >
                Activate
              </PrimaryButton>
            </>
          )}
        </>
      }
    >
      <Card isActive={false} onClick={() => noop()}>
        <div className="w-full cursor-pointer">
          <div className="text-tw-black-9">{entity.name}</div>
          <LockIcon className="absolute w-4 h-4 fill-current text-tw-gray-c top-2 right-2" />
        </div>
      </Card>
    </HoverPopover>
  );
}
