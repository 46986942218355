import { useClient } from '../../../api-client/index';
import { useQuery } from 'react-query';
export default function useMeta(entity, modelId, goal) {
  const client = useClient();
  return useQuery(
    ['meta', entity, modelId, goal],
    () => client.getHistogram({ type: entity, modelId, goal }),
    {
      enabled: !!entity && !!modelId,
    }
  );
}
