import * as SliderPrimitive from '@radix-ui/react-slider';
import { classNames } from 'utils/common';
import ToolTip from '../data-display/Tooltip';

type SliderProps = {
  value: number;
  onChange: (value: number[]) => void;
  tooltipContent?: React.ReactNode;
};

export function Slider({ value, onChange, tooltipContent }: SliderProps) {
  return (
    <SliderPrimitive.Root
      className="relative flex items-center flex-grow h-5 cursor-pointer"
      value={[value]}
      onValueChange={onChange}
    >
      <SliderPrimitive.Track className="relative w-full h-4 rounded-full bg-tw-green-e7">
        <SliderPrimitive.Range
          className={classNames(
            'absolute h-4 rounded-l-full bg-tw-green-3e',
            value > 30 && 'rounded-r-full'
          )}
        />
      </SliderPrimitive.Track>
      <ToolTip
        hasArrow={true}
        tooltipArrowClassName="text-white fill-current"
        content={tooltipContent}
        open={!!tooltipContent}
      >
        <SliderPrimitive.Thumb className="block bg-white rounded-full shadow-lg w-9 h-9" />
      </ToolTip>
    </SliderPrimitive.Root>
  );
}
