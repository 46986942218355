import { useMutation, useQueryClient } from 'react-query';
import { useClient } from '../../../api-client';
import { ListAllOpportunities } from '../types';
import { useOpportunityListStore } from '../store/OpportunityListStore';

export default function useOpportunityAction() {
  const queryClient = useQueryClient();
  const { queryParams } = useOpportunityListStore();
  const opportunitiesQueryKey = [
    'opportunities',
    queryParams.offset,
    queryParams.query,
    queryParams.status,
    queryParams.selectedOrderFilter,
    queryParams.destinations,
  ];
  const client = useClient();
  return useMutation(
    ({ id, action }: { id: number; action: string }) => client.actionOpportunity(id, action),
    {
      onMutate: async (newData) => {
        await queryClient.cancelQueries(opportunitiesQueryKey);
        queryClient.setQueryData(opportunitiesQueryKey, (opportunities: ListAllOpportunities) => {
          return {
            count: opportunities.count,
            results: opportunities.results.map((data) => {
              if (data.id === newData.id)
                return { ...data, status: newData.action === 'start' ? 'active' : 'stopped' };
              return data;
            }),
            previous: opportunities.previous,
            next: opportunities.next,
          };
        });
      },
      onSettled: () => {
        queryClient.refetchQueries(opportunitiesQueryKey);
      },
    }
  );
}
