import * as AccordionPrimitive from '@radix-ui/react-accordion';
import useConnectedIntegrations from 'hooks/useConnectedIntegrations';
import { usePQLWorkflowRoute } from 'pages/pql-workflow';
import { ExportPQLSections, useExportPQLStore } from 'pages/pql-workflow/store/PQLExportStore';
import { PQLWorkflowSteps, usePQLWorkflowStore } from 'pages/pql-workflow/store/PQLWorkflowStore';
import { forwardRef, RefObject } from 'react';
import { EditIcon, InfoAlertIcon, TickIconFilled } from 'ui-components/data-display/Icons';
import SectionHeader from '../SectionHeader';
import ExportSubSections from './ExportSubSections';

const Export = forwardRef((_, ref: RefObject<HTMLDivElement>) => {
  Export.displayName = 'OldExportPage';

  const { currentSection, setCurrentSection, mappings } = useExportPQLStore();
  const { data: connectedIntegrations, isLoading: connectedIntegrationsIsLoading } =
    useConnectedIntegrations(false);
  const { isDuplicate } = usePQLWorkflowRoute();

  const { currentStep, setCurrentStep, completedSteps } = usePQLWorkflowStore();
  const { destinationId } = useExportPQLStore();

  if (!completedSteps[PQLWorkflowSteps.EXPORT] && currentStep !== PQLWorkflowSteps.EXPORT) {
    return (
      <div className="flex px-6 py-6 bg-white border gap-x-5 rounded-xl border-tw-gray-eb">
        <TickIconFilled className="fill-current text-tw-gray-c" />
        <div>
          <h4 className="text-base font-medium text-tw-black-7">Sync with your GTM applications</h4>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full border rounded-xl overflow-clip border-tw-gray-eb" ref={ref}>
      {currentStep === PQLWorkflowSteps.EXPORT && (
        <>
          <SectionHeader number={3} title={'Sync with your GTM applications'} />
          <AccordionPrimitive.Root
            type="single"
            value={currentSection}
            onValueChange={(value: ExportPQLSections) => setCurrentSection(value)}
            collapsible
          >
            <ExportSubSections />
          </AccordionPrimitive.Root>
        </>
      )}
      {currentStep !== PQLWorkflowSteps.EXPORT && (
        <div
          className="flex justify-between px-6 py-6 bg-white cursor-pointer item-center gap-x-5 rounded-xl group"
          onClick={() => {
            setCurrentStep(PQLWorkflowSteps.EXPORT);
            ref?.current?.scrollIntoView();
          }}
        >
          <div className="flex gap-x-5">
            <TickIconFilled className="fill-current text-tw-green-39" />
            <div>
              <h4 className="text-base font-medium text-tw-black-7">
                Sync with your GTM applications
              </h4>
              <div className="text-tw-black-9">
                {connectedIntegrationsIsLoading ? (
                  <div className="h-5 w-28 bg-tw-gray-eb animate-pulse"></div>
                ) : (
                  `${connectedIntegrations?.find((i) => i.id === destinationId)?.name} |
                ${mappings.length} field${mappings.length > 1 ? 's' : ''} mapped`
                )}
              </div>
              {isDuplicate && (
                <div className="flex items-center p-2 mt-4 font-medium rounded bg-tw-orange-ff gap-x-2 text-tw-orange-de">
                  <InfoAlertIcon className="w-4 fill-current" />
                  <span className="text-xs">This step needs your attention</span>
                </div>
              )}
            </div>
          </div>
          <div className="items-center hidden group-hover:flex gap-x-2 text-tw-black-5">
            <EditIcon />
            <span>Edit</span>
          </div>
        </div>
      )}
    </div>
  );
});

export default Export;
