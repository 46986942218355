// When a new model gets added, we need to update this
// file for its LABEL and DESCRIPTION
export const MODELID_LABEL_FOR_USERS = {
  activation: `Improve activation`,
  conversion: `Convert free users`,
  expansion: `Account expansion`,
  leap_expansion: `Account expansion`,
  churn_prevention: `Prevent churn`,
  churn: `Prevent churn`,
  engagement: `Increase engagement`,
  resurrection: `Increase resurrections`,
  expansion2: `Account expansion to teams >= 2`,
};

export const MODELID_LABEL_FOR_ACCOUNTS = {
  activation: `Improve activation`,
  conversion: `Convert free accounts`,
  expansion: `Account expansion`,
  leap_expansion: `Account expansion`,
  churn_prevention: `Prevent churn`,
  churn: `Prevent churn`,
  engagement: `Increase engagement`,
  resurrection: `Increase resurrections`,
  expansion2: `Account expansion to teams >= 2`,
};

export const MODELID_DESCRIPTION = {
  conversion: `Monetize your user growth. Identify which free users/accounts are a perfect fit for paid subscriptions.`,
  expansion: `Increase revenue without boiling the ocean. Identify users/accounts who are most likely to shift to a higher paid plan.`,
  churn_prevention: `Reduce churn, without boiling the ocean. Find engaged users who run the risk of churn!`,
  churn: `Reduce churn, without boiling the ocean. Find engaged users who run the risk of churn!`,
  leap_expansion: `Increase revenue without boiling the ocean. Identify users/accounts who are most likely to shift to a higher paid plan.`,
  engagement: `Engagement is bedrock on which you build retention and loyalty. If you want to find users/accounts who are most likely to be engaged.`,
  expansion2: `Increase revenue without boiling the ocean. Identify users/accounts who are most likely to shift to a higher paid plan.`,
  activation: `Improve activation`,
  resurrection: `Increase resurrections`,
};

export const ENTITY_DESCRIPTION = {
  users: `If you'd like to work with individual users, instead of accounts, you've come to the right place! 😀`,
  accounts: `If you'd like to work with teams (also called accounts), instead of individual users in a team, you've come to the right place! 😀`,
};
