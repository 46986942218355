import { useAuth } from 'hooks/useAuth';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { bootPosthog, bootIntercom } from '../../../utils/helpers';
import Navbar from './Navbar';

interface Props {
  style?: any;
}

const NavBars: React.FC<Props> = ({ children }) => {
  const { user: data, isLoading, isError } = useAuth();
  React.useEffect(() => {
    if (data) {
      bootPosthog(data);
      bootIntercom(data);
    }
  }, [data]);

  return (
    <>
      <div>
        <Helmet>
          <title>
            {!isLoading && !isError && data?.currentOrganization?.name
              ? `Toplyne | ${
                  data?.currentOrganization?.isSandbox
                    ? `Test account`
                    : data?.currentOrganization?.name
                }`
              : `Toplyne`}
          </title>
        </Helmet>
        <main id="content" role="main" className={`bg-white flex`}>
          <Navbar>{children}</Navbar>
          {/* <Header currentPage='opportunities' breadCrumbArray={breadCrumbArray} /> */}
          {/* <!-- Fluid Content --> */}
        </main>
      </div>
    </>
  );
};

export default NavBars;
