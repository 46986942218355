import { useClient } from 'api-client';
import { useQuery } from 'react-query';

export default function useOrganizationDetails(
  schemaName: string | null,
  enabled?: boolean,
  refetchInterval?: number
) {
  const client = useClient();

  return useQuery(
    ['organizationDetails', schemaName],
    () => client.getOrganizationDetails(schemaName),
    {
      enabled: enabled,
      refetchInterval: refetchInterval,
    }
  );
}
