import { classNames } from 'utils/common';
import { FC } from 'react';
import { FilledCrossIconRound } from '../data-display/Icons';

interface Props {
  level?: 'error' | 'warning' | 'info';
}

const ErrorFromHook: FC<Props> = (props) => {
  const { children } = props;
  return (
    <div
      className={classNames(
        'flex items-center px-8 py-4 mb-6 space-x-2 rounded',
        props.level === 'error'
          ? 'bg-tw-red-ff text-tw-red-dd'
          : 'bg-tw-orange-ff text-tw-orange-de'
      )}
    >
      <span>
        <FilledCrossIconRound className="fill-current" />
      </span>
      <span data-testid="error-message-body" className="text-base font-medium">
        {children}
      </span>
    </div>
  );
};

ErrorFromHook.defaultProps = {
  level: 'error',
};

export default ErrorFromHook;
