import { OpportunityStatus } from 'api-client';
import Tooltip from 'ui-components/data-display/Tooltip';
import { ModelGoals } from 'pages/pql-workflow/store/PQLDefineStore';
import { Filter } from 'stores/QueryStore';
import dayjs from 'dayjs';
import { capitalizeFirstLetter } from 'utils/common';
import { CadenceFrequency } from 'pages/pql-workflow/store/PQLExportStore';

// filters a given dataset in users/account attributes modal
// defaultSelectedColumns are always selected.
export async function filterAsync(datasets, section, field_mapping, defaultColumns = []) {
  return new Promise((resolve) => {
    let selected = [];
    let notSelected = [];
    let uniqueMapper = new Map();
    datasets?.map((atr) => {
      if (atr.keySpace === section) {
        if (!uniqueMapper.get(atr.keyName)) {
          if (field_mapping[atr.keyName] || defaultColumns.includes(atr.keyName)) {
            selected.push(atr);
          } else {
            notSelected.push(atr);
          }
          uniqueMapper.set(atr.keyName, true);
        }
      }
      return atr;
    });
    resolve({
      selected,
      notSelected,
    });
  });
}

export function trim(str: string, noOfChars: number) {
  if (!str) {
    return null;
  }
  if (str.length >= noOfChars) {
    return (
      <Tooltip label={str}>
        <div>{str.substring(0, noOfChars) + '...'}</div>
      </Tooltip>
    );
  }
  return <div>{str}</div>;
}

export function getDestinationIconColor(destinationType: string) {
  switch (destinationType) {
    case 'salesforce':
      return 'text-tw-mild-blue';
    case 'salesforce_sandbox':
      return 'text-tw-mild-blue';
    case 'email':
      return 'text-tw-blue-31';
    case 'webhook':
      return 'text-tw-purple-78';
    case 'pipedrive':
      return 'text-tw-gray-23';
    default:
      return 'text-tw-mild-orange';
  }
}

export function getStatusRequestPayload(status: string): null | string[] | string {
  if (status === OpportunityStatus.ALL) {
    return null;
  } else if (status === OpportunityStatus.ACTIVE) {
    return [OpportunityStatus.ACTIVE, OpportunityStatus.RUNNING];
  } else if (status === OpportunityStatus.FINISHED) {
    return [OpportunityStatus.COMPLETED, OpportunityStatus.STOPPED];
  } else if (status === OpportunityStatus.PENDING) {
    return OpportunityStatus.SCHEDULED;
  }

  return status;
}

export function whiteListCohortsForOrgs(schemaName: string) {
  if (!schemaName) return false;

  // OVERRIDE_ENABLE_SYSTEM_COHORTS
  // Canva and harness doesn't have any persona
  return ['canva', 'harness_straight_helm'].includes(schemaName) ? false : true;
}

// TODO: // for proper implementation, this should be an org
// specific flag in the backend.
export function whiteListCohortsForModels(modelId: string) {
  if (!modelId) return false;

  if (modelId === ModelGoals.EXPANSION) return false;

  return true;
}

export function addPluralSuffix(len: number) {
  return len > 1 ? 's' : '';
}

// simply filters out personas from filters.
export const removePersonasFromFilters = (filters: Filter[]) => {
  return filters.filter((f) => f.columnName !== 'MACRO_PERSONA');
};

export function bootIntercom(data) {
  window.Intercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    email: data.email,
    user_id: data.email,
    name: data.firstName ? data.firstName + ' ' + data.lastName : 'there',
    Account: data.currentOrganization?.name,
    Schema: data.currentOrganization?.schemaName,
  });
}

export function bootPosthog(data) {
  window.posthog.identify(data.email, {
    user_id: data.id,
    name: data.firstName + ' ' + data.lastName,
    Account: data.currentOrganization?.name,
    Schema: data.currentOrganization?.schemaName,
    isStaff: data.isStaff ? 'true' : 'false',
    email: data.email,
  });
  window.posthog.people.set({ email: data.email });
}

export const getHubspotCookie = () => {
  let match = document.cookie.match(new RegExp('(^| )hubspotutk' + '=([^;]+)'));
  if (match) return match[2] || '';
  return '';
};

export const getDateOrdinal = (day) => {
  if (day > 3 && day < 21) return `${day}th`;
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};

export const getCadenceString = (
  frequency: string,
  startTimeString: string,
  startDateString: string,
  daysForWeekly = [],
  daysForMonthly = []
) => {
  // get string for days of month in case of monthly
  let daysStringMonthly = daysForMonthly?.length
    ? daysForMonthly.map((day) => getDateOrdinal(day))?.join(', ')
    : '';

  // get string for days of week in case of weekly
  let daysStringWeekly = daysForWeekly?.length
    ? daysForWeekly.map((day) => capitalizeFirstLetter(day)).join(', ')
    : '';

  switch (frequency) {
    case CadenceFrequency.WEEKLY:
      return `every week on ${daysStringWeekly} at ${startTimeString} starting ${startDateString}`;
    case CadenceFrequency.DAILY:
      return `daily at ${startTimeString} starting ${startDateString}`;
    case CadenceFrequency.HOURLY:
      return `every hour starting ${startDateString} ${startTimeString}`;
    case CadenceFrequency.MONTHLY:
      return `every month on ${daysStringMonthly} at ${startTimeString} starting ${startDateString}`;
    default:
      `recurringly starting ${startDateString} ${startTimeString} `;
  }
};
export function getIllustration(date: Date) {
  if (dayjs(date).tz().hour() >= 6 && dayjs(date).tz().hour() < 12) {
    return 'url(../../public_assets/greeting-morning.png)';
  } else if (dayjs(date).tz().hour() >= 12 && dayjs(date).tz().hour() < 18) {
    return 'url(../../public_assets/greeting-afternoon.png)';
  } else if (dayjs(date).tz().hour() === 18 && dayjs(date).tz().minute() < 30) {
    return 'url(../../public_assets/greeting-afternoon.png)';
  }

  return 'url(../../public_assets/greeting-evening.png)';
}
