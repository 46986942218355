import { ReactComponent as Flower } from 'assets/svg/components/flower.svg';
import Modal, { ModalWidth } from 'ui-components/util/modal';
import { PrimaryButton } from 'ui-components/inputs/Buttons';
import { noop } from 'lodash';
import Card from 'ui-components/business/Card';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'routing/clientRoutes';
import useSandboxDatasets from 'hooks/useSandboxDatasets';
import { useAnalytics } from '../../telemetry';
import { AnalyticsConst, AnalyticsEvents } from '../../telemetry/constants';

export default function ChooseSandboxDataModal({ open, onClose }) {
  const { data, isSuccess, isLoading } = useSandboxDatasets();
  const router = useHistory();
  const { track } = useAnalytics();

  const handleSelectDataset = (dataset) => {
    router.push(`${routes.setupOrganization}?dataset=${dataset}`);
    track(AnalyticsEvents.SELECT_DATASET, { [AnalyticsConst.NAME]: dataset });
  };

  const [sortedDatasets, setSortedDatasets] = useState([]);
  useEffect(() => {
    if (data && Object.keys(data)?.length)
      setSortedDatasets([data['sandbox1'], data['sandbox3'], data['sandbox2']]);
  }, [data]);
  return (
    <Modal
      closeOnOutsideClick={false}
      width={ModalWidth.xl}
      open={open}
      onClose={onClose}
      className="mx-11"
    >
      <div className="flex flex-col items-center px-32 text-center">
        <Flower />
        <h2 className="text-3xl font-semibold text-tw-black-3">Let’s set up your test dashboard</h2>
        <p className="mt-3 text-base text-tw-black-9">
          Take Toplyne for a spin using a sample dataset and later plug in your product analytics
          data
        </p>
      </div>
      {
        <div className="pb-12 mt-20 mx-11">
          <h5 className="font-medium text-tw-orange-de">SELECT A SAMPLE DATA SET</h5>
          <div className="grid grid-cols-3 mt-4">
            {isSuccess &&
              sortedDatasets?.map((dataset) => (
                <Card
                  cardClassname="py-12 relative group"
                  key={dataset.databaseName}
                  isActive={false}
                  onClick={() => noop()}
                >
                  <div className="flex items-center gap-x-4 group-hover:blur-xss">
                    <img src={dataset.logoUrl} />
                    <div>
                      <h4 className="text-base font-medium">{dataset.displayName}</h4>
                      <p className="mt-1 text-xs text-tw-black-7">{dataset.description}</p>
                    </div>
                  </div>
                  <PrimaryButton
                    onClick={() => handleSelectDataset(dataset.databaseName)}
                    className="absolute left-0 right-0 hidden mx-auto group-hover:block w-30"
                    fontClassName="text-sm font-medium"
                  >
                    Select
                  </PrimaryButton>
                </Card>
              ))}
            {isLoading &&
              Array(3)
                .fill(0)
                .map((_, idx) => (
                  <Card cardClassname="py-12" key={idx} isActive={false} onClick={() => noop()}>
                    <div className="flex items-center w-full gap-x-4">
                      <div className="w-1/6 rounded h-11 bg-tw-gray-eb animate-pulse"></div>
                      <div className="w-full">
                        <div className="w-full h-6 bg-tw-gray-eb animate-pulse"></div>
                        <div className="w-full h-6 mt-2 bg-tw-gray-eb animate-pulse"></div>
                      </div>
                    </div>
                  </Card>
                ))}
          </div>
        </div>
      }
    </Modal>
  );
}
