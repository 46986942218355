import { AccountFields } from 'pages/users-and-accounts/fields';
import { useAuth } from 'hooks/useAuth';
import useEntitiesQuery from 'hooks/useEntitiesQuery';
import { Entity } from 'pages/users-and-accounts/users/types';
import * as React from 'react';
import Card from '../../../../ui-components/surfaces/cards/Card';
import {
  formatForCellDisplay,
  getAverageColor,
  getSourceImg,
  toSentence,
} from '../../../../utils/common';
import useEntityDetails from '../hooks/useAccountDetails';

interface HeroContentProps {
  country: string;
  createdAt: string;
  domain: string;
  freeUsers: number;
  payingUsers: number;
  isPaid: boolean;
  website: string;
  source: string;
  accountName: string;
  accountID: string;
  firstPayment: string;
}

function extractObjectFromAccountDetails(data, currentOrganization) {
  let ret = {};
  const fields = Object.values(AccountFields)
    .filter((f) => currentOrganization[f])
    .map((f) => currentOrganization[f]);
  data?.accountDetails.forEach((d) => {
    if (fields.includes(d.key)) {
      ret[d.key] = d.value;
    }
  });
  return ret;
}

const HeroContent: React.FC<HeroContentProps> = (props) => {
  const [backgroundColor, setBackgroundColor] = React.useState('');
  const {
    user: { currentOrganization },
  } = useAuth();
  const { data } = useEntityDetails(Entity.accounts, props.accountID);
  const { data: accountsData } = useEntitiesQuery(Entity.accounts);

  const color = async () => {
    let clr = await getAverageColor(
      `https://logo.clearbit.com/${props.website ? props.website : props.domain}`
    );
    if (clr.success) {
      setBackgroundColor(clr.color + '26'); // adding opacity
    } else if (props.isPaid) {
      setBackgroundColor('#ECFBFF');
    } else {
      setBackgroundColor('#F5F5F5');
    }
  };

  const getPropertyDisplayNameFromItem = React.useCallback(
    (item: string) => {
      if (item === 'domain_1') {
        return 'Domain';
      }
      if (item === 'user_creation_time') {
        return 'Created At';
      }

      if (item === 'user_id') {
        return 'User ID';
      }

      if (accountsData?.find((e) => e.keyName === item)?.displayName) {
        return accountsData?.find((e) => e.keyName === item)?.displayName;
      }

      return toSentence(item);
    },
    [accountsData]
  );

  React.useEffect(() => {
    color();
  });

  const accountFieldValue = (field: AccountFields) => {
    return accountObject[currentOrganization[field]];
  };

  const accountObject = extractObjectFromAccountDetails(data, currentOrganization);

  return (
    <Card bodyClass={'p-0'} className={'mb-4 rounded'} hover={false} style={{ border: 'none' }}>
      <div
        className={'h-24 p-4 bg-ad-line bg-no-repeat rounded-top'}
        style={{
          backgroundColor: `${backgroundColor}`,
        }}
      >
        <div className={'justify-between items-center flex'}>
          <div className={'flex items-center'}>
            <div className={''}>
              <p className={'font-medium text-base'}>
                {accountFieldValue(AccountFields.ACCOUNT_WEBSITE)
                  ? accountFieldValue(AccountFields.ACCOUNT_WEBSITE)
                  : accountFieldValue(AccountFields.ACCOUNT_ID)}
              </p>
              {props.isPaid ? (
                <small className={'text-sm font-normal text-tw-gray-7'}>
                  Subscribed to the plan (Billed )
                </small>
              ) : (
                <small className={'text-sm font-normal text-tw-gray-7'}>Not Billed</small>
              )}
            </div>
          </div>
          {/*
            isPaid is not well defined for an account, so we do not know
            how to display the billing data
          */}
        </div>
      </div>
      {(props.freeUsers > 0 || props.payingUsers > 0) && (
        <div className={'grid grid-cols-4 gap-4 p-4'}>
          {props.freeUsers > 0 && (
            <div className={'box-border rounded-lg relative py-4 px-6 border border-tw-gray-eb'}>
              <span>
                <p className={'text-sm font-normal text-tw-black-7'}>Free Plan</p>
                <p className={'text-xl font-medium text-tw-black-1'}>{props.freeUsers} users</p>
              </span>
            </div>
          )}
          {props.payingUsers > 0 && (
            <div className={'box-border rounded-lg relative py-4 px-6 border border-tw-gray-eb'}>
              <span>
                <p className={'text-sm font-normal text-tw-black-7'}>Starter Plan</p>
                <p className={'text-xl font-medium text-tw-black-1'}>{props.payingUsers} users</p>
              </span>
            </div>
          )}
        </div>
      )}
      <div className={'p-4'}>
        <ul className="grid grid-cols-2 gap-x-2">
          {currentOrganization.accountPersonaProperties.map((item: string) => {
            return (
              <li key={item} className={'grid grid-cols-2 mb-4'}>
                <div className={'flex space-x-1'}>
                  <img
                    alt={''}
                    className={'w-6 h-6 filter grayscale opacity-60'}
                    src={getSourceImg(props.source)}
                  />
                  <p className={'font-medium'}>{getPropertyDisplayNameFromItem(item)}</p>
                </div>
                <div>
                  <p className={'text-tw-black-5 font-medium truncate'}>
                    {formatForCellDisplay(data?.accountDetails?.find((a) => a.key === item)?.value)}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Card>
  );
};

export default HeroContent;
