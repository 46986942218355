import { LoggedInUser, OrganizationStatus, useApiClient } from 'api-client';
import { AxiosInstance } from 'axios';
import { useAuth } from 'hooks/useAuth';
import { useMutation, useQueryClient } from 'react-query';

type CreateOrganizationResponse = {
  name: string;
  schemaName: string;
  timezone: string;
  domain: string;
  status: OrganizationStatus;
  orgId: string;
  isSandbox: boolean;
};

async function createOrganization(client: AxiosInstance, variables: CreateOrganizationData) {
  const { data } = await client.post<CreateOrganizationResponse>(
    '/organizations/',
    {
      ...variables,
    },
    {
      headers: {
        'TL-ORG': undefined,
      },
    }
  );

  return data;
}

type CreateOrganizationData = {
  name: string;
  sandboxId?: string;
};

export default function useCreateOrganization() {
  const client = useApiClient(false);
  const { user } = useAuth();
  const queryClient = useQueryClient();
  return useMutation((data: CreateOrganizationData) => createOrganization(client, data), {
    onSuccess: async (data) => {
      await queryClient.cancelQueries('user');
      queryClient.setQueryData(
        ['user', user?.currentOrganization?.orgId],
        (oldData: LoggedInUser) => {
          return {
            ...oldData,
            organizations: [...oldData.organizations, data],
          };
        }
      );
    },
    onSettled: () => {
      queryClient.refetchQueries('user');
    },
  });
}
