import * as React from 'react';
import dollarSymbol from '../../../assets/svg/components/dollar-symbol.svg';
import calender from '../../../assets/svg/components/calender.svg';
import Highlights from './Highlights';
import { Columns } from './Columns';
import { Table } from './Table';
import { convertDate, convertAmount } from '../../../utils/common';

interface BillingData {
  data: {
    [key: string]: any;
  };
  billingLedger: any;
  billingHeroData: {
    [key: string]: any;
  };
}

const Billing: React.FC<BillingData> = (props) => {
  return (
    <>
      <div className={'grid grid-cols-3 gap-4 py-4'}>
        <div className={'box-border rounded-lg relative py-4 px-6 border border-tw-gray-eb'}>
          <span
            className={'absolute rounded-3xl bg-tw-blue-cf w-11 h-11 left-4'}
            style={{
              top: '21px',
            }}
          >
            <img
              src={dollarSymbol}
              alt={'money-symbol'}
              className={'absolute'}
              style={{ inset: '27%' }}
            />
          </span>
          <span>
            <p className={'ml-14 text-sm font-normal text-tw-black-7'}>Total Lifetime Value</p>
            <p className={'ml-14 text-2xl font-semibold text-tw-black-1'}>
              {' '}
              {props.billingHeroData.billing_subscription_total
                ? `$ ${convertAmount(props.billingHeroData.billing_subscription_total)}`
                : '--'}
            </p>
          </span>
        </div>
        <div className={'box-border rounded-lg relative py-4 px-6 border border-tw-gray-eb'}>
          <span
            className={'absolute rounded-3xl bg-tw-orange-ff w-11 h-11 left-4'}
            style={{
              top: '21px',
            }}
          >
            <img
              src={calender}
              alt={'calender-symbol'}
              className={'absolute'}
              style={{ inset: '27%' }}
            />
          </span>
          <span>
            <p className={'ml-14 text-sm font-normal text-tw-black-7'}>Next Billing Date</p>
            <p className={'ml-14 text-2xl font-semibold text-tw-black-1'}>
              {props.billingHeroData.billing_next_charge_date
                ? convertDate(props.billingHeroData.billing_next_charge_date)
                : '--'}
            </p>
          </span>
        </div>
      </div>
      {Object.keys(props.data).length > 0 ? (
        <>
          <div className={'py-8 px-0'}>
            <Highlights items={props.data} />
          </div>
          <hr />
        </>
      ) : (
        ''
      )}
      {props.billingLedger && props.billingLedger.length ? (
        <div className={'py-8 px-0'}>
          <p className={'text-tw-black-5 mb-4 font-medium text-base'}>Billing History</p>
          {props.billingLedger && (
            <Table data={props.billingLedger} columns={Columns} hiddenColumns={['currency']} />
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Billing;
