import { useMutation, useQueryClient } from 'react-query';
import { useClient } from '../../../api-client';
import { ListAllOpportunities } from '../types';
import { useOpportunityListStore } from '../store/OpportunityListStore';
import { toast as toastify } from 'react-toastify';
import { AnalyticsEvents, AnalyticsConst } from 'telemetry/constants';
import { useAnalytics } from 'telemetry';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';

export default function useDeleteOpportunity() {
  const queryClient = useQueryClient();
  const { queryParams } = useOpportunityListStore();
  const opportunitiesQueryKey = [
    'opportunities',
    queryParams.offset,
    queryParams.query,
    queryParams.status,
    queryParams.selectedOrderFilter,
    queryParams.destinations,
  ];
  const client = useClient();
  const { track } = useAnalytics();

  return useMutation((id: number | string) => client.deleteOpportunity(id), {
    onSuccess: async (data, id) => {
      await queryClient.cancelQueries(opportunitiesQueryKey);

      queryClient.setQueryData(opportunitiesQueryKey, (opportunities: ListAllOpportunities) => {
        return {
          count: opportunities?.count - 1,
          results: opportunities.results.filter((r) => r.id !== id),
          previous: opportunities.previous,
          next: opportunities.next,
        };
      });
      // show toast
      toastify(<SuccessToast description="Playbook has been archived" />, {
        type: 'error',
      });
      track(AnalyticsEvents.OPPORTUNITY_DELETED, {
        [AnalyticsConst.OPPORTUNITY_ID]: id,
      });
    },
    onSettled: () => {
      queryClient.refetchQueries(opportunitiesQueryKey);
    },
    onError: () => {
      toastify(<ErrorToast description="There was an error deleting Playbook" />, {
        type: 'error',
      });
    },
  });
}
