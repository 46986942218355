import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Integration, IntegrationType } from 'api-client';
import { AddNewPropertyStuckField } from 'ui-components/business/custom-forms/destination-export-forms/AddNewPropertyStuckField';
import { NewDestinationField } from 'ui-components/business/custom-forms/destination-export-forms/NewDestinationField';
import VirtualizedSelect from 'ui-components/inputs/VirtualizedSelect';
import {
  CrossIconWithoutBg,
  DeleteBinIcon,
  InfoToolTipIcon,
  PlusIcon,
} from 'ui-components/data-display/Icons';
import Modal from 'ui-components/util/modal';
import { classNames } from 'utils/common';

import { PrimaryButton, SecondaryButton, TertiaryButton } from 'ui-components/inputs/Buttons';
import { TextInput } from 'ui-components/inputs/Inputs';
import ToolTip from 'ui-components/data-display/Tooltip';
import { capitalizeFirstLetter, getSourceImg, toSnakeCase } from 'utils/common';
import isEmpty from 'lodash/isEmpty';
import { checkTypeMappings, uniqueProperty, validateFieldMappingsTypes } from '../../../utils';
import useConnectedIntegrations from 'hooks/useConnectedIntegrations';
import { usePQLWorkflowRoute } from 'pages/pql-workflow';
import useConnectedIntegrationEntityDetail from 'pages/pql-workflow/hooks/useConnectedIntegrationEntityDetail';
import { useEditExports } from 'pages/pql-workflow/hooks/useEditExports';
import useOpportunity from 'pages/pql-workflow/hooks/useOpportunity';
import { useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { ExportPQLSections, useExportPQLStore } from 'pages/pql-workflow/store/PQLExportStore';
import useEntitiesQuery from 'hooks/useEntitiesQuery';
import { Entity } from 'pages/users-and-accounts/users/types';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import ObjectUniqueFieldMapping from './ObjectUniqueFieldMapping';

//Allowed field mappings

// this custom method checks that the required field should be unique.
Yup.addMethod(Yup.array, 'uniqueProperty', uniqueProperty);
Yup.addMethod(Yup.array, 'checkTypeMappings', checkTypeMappings);

const find_dtype = (value: string, sourceItems: any, key: string) => {
  return sourceItems.find((x) => x[key] == value).type;
};

const schema = Yup.object().shape({
  typeMappings: Yup.object(),
  mappings: Yup.array()
    .of(
      Yup.object().shape({
        source: Yup.string().required({ msg: 'The source is missing' }),
        destination: Yup.string().required({ msg: 'The destination is missing' }),
        required: Yup.string(),
        sourceDtype: Yup.string(),
        destinationDtype: Yup.string(),
        destinationLabel: Yup.string(),
      })
    )
    // HACK: This is a common issue tracked in
    // https://github.com/jquense/yup/issues/312
    // @ts-ignore, automatically injected.
    .uniqueProperty('destination', 'sync destination should be unique', {
      // unique w.r.t tags name
      field: 'tags',
      propertyPath: 'name',
    })
    .checkTypeMappings('sourceDtype', 'destinationDtype', 'Data types are not compatible', {}),

  tags: Yup.array()
    // unique w.r.t mappings destination
    .of(
      Yup.object().shape({
        name: Yup.string().required({ msg: 'Tag name is missing' }),
        value: Yup.string().required({ msg: 'Tag value is missing' }),
      })
    )
    // https://github.com/jquense/yup/issues/312
    // @ts-ignore, automatically injected.
    .uniqueProperty('name', 'tag name should be unique', {
      field: 'mappings',
      propertyPath: 'destination',
    }),
});

function getIntegrationSpecificDestinationField(
  supportsEntities,
  destination,
  mappings,
  index,
  field,
  trigger,
  register,
  update,
  dirtyFields,
  errors,
  tagUpdate,
  entityDetails,
  controlledMappings,
  uniqueKeys,
  setMakeUniqueProperty,
  setShowNewDestinationModal,
  integrationMetadata
) {
  const allowedFieldMappings = integrationMetadata?.typeMappings
    ? integrationMetadata.typeMappings
    : {};

  if (supportsEntities) {
    const isPipedrive = isPipedriveType(destination?.integration);
    const isTray = isTrayType(destination?.integration);
    const isHubspot = isHubspotType(destination?.integration);

    if (isPipedrive && index === 0) {
      return (
        <TextInput
          className="w-full"
          disabled
          value={capitalizeFirstLetter(mappings[0].destination)}
          onChangeCapture={() => trigger('mappings')}
          isError={
            (!!errors?.mappings?.[index]?.destination &&
              !!dirtyFields?.mappings?.[index]?.destination) ||
            (!!errors?.mappings?.[index]?.destinationDtype &&
              !!dirtyFields?.mappings?.[index]?.destinationDtype)
          }
          error={errors?.mappings?.[index]?.destinationDtype}
        />
      );
    }
    if (isTray) {
      if (isEmpty(entityDetails) || field.required) {
        return (
          <TextInput
            className="w-full h-full"
            onChange={({ target: { value } }) => {
              update(index, {
                source: field.source,
                destination: value,
                sourceDtype: field.sourceDtype,
                destinationDtype: undefined,
                destinationLabel: field?.destinationLabel ?? '',
              });
              trigger('mappings');
              trigger('tags');
            }}
            disabled={field.required}
            placeholder={`Enter ${destination?.integration?.name || 'destination'} field name`}
            value={field.destinationLabel || field.destination}
            {...register(`mappings.${index}.destination`)}
            isError={
              (!!errors?.mappings?.[index]?.destination &&
                !!dirtyFields?.mappings?.[index]?.destination) ||
              (!!errors?.mappings?.[index]?.destinationDtype &&
                !!dirtyFields?.mappings?.[index]?.destinationDtype)
            }
            error={errors?.mappings?.[index]?.destinationDtype}
            key={field.id}
          />
        );
      }
    }

    return (
      <VirtualizedSelect
        onChange={(value: string) => {
          update(index, {
            source: field.source,
            destination: value,
            sourceDtype: field.sourceDtype,
            typeMappings: allowedFieldMappings,
            destinationLabel: field?.destinationLabel ?? '',
            destinationDtype: find_dtype(value, entityDetails.fields, 'name'),
          }),
            trigger('mappings');
        }}
        options={entityDetails?.fields.map((e) => ({
          label: e.label,
          value: e.name,
        }))}
        value={field.destination}
        virtualize
        ph={`Select ${destination?.integration?.name || 'destination'} field`}
        disabledFields={[
          ...uniqueKeys.map((uniqueKey) => uniqueKey.destination),
          ...controlledMappings.map((f) => !!f.destination && f.destination),
        ]}
        stuckField={
          (isPipedrive || isHubspot) && (
            <AddNewPropertyStuckField
              onclick={() => {
                setMakeUniqueProperty(false);
                setShowNewDestinationModal(true);
              }}
              integrationType={destination?.integration.type}
            />
          )
        }
        error={{
          isError:
            !!errors?.mappings?.[index]?.destinationDtype &&
            !!dirtyFields?.mappings?.[index]?.destinationDtype,
          message: errors?.mappings?.[index]?.destinationDtype?.message,
        }}
      />
    );
  }
  return (
    <TextInput
      className="w-full h-full"
      onChange={({ target: { value } }) => {
        update(index, {
          source: field.source,
          destination: value,
          sourceDtype: field.sourceDtype,
          destinationDtype: undefined,
          destinationLabel: field?.destinationLabel ?? '',
        });
        trigger('mappings');
        trigger('tags');
      }}
      placeholder={`Enter ${destination?.integration?.name || 'destination'} field name`}
      value={field.destination}
      {...register(`mappings.${index}.destination`, {
        required: true,
        onChange: () => {
          // revalidate mappings and tags
          trigger('mappings');
          trigger('tags');
        },
      })}
      isError={
        (!!errors?.mappings?.[index]?.destination &&
          !!dirtyFields?.mappings?.[index]?.destination) ||
        (!!errors?.mappings?.[index]?.destinationDtype &&
          !!dirtyFields?.mappings?.[index]?.destinationDtype)
      }
      error={errors?.mappings?.[index]?.destinationDtype}
      key={field.id}
    />
  );
}

function checkExportValidation(
  supportsEntities,
  controlledMappings,
  object,
  uniqueKeys,
  extraFormdata,
  typeMappings
) {
  let errors: any = [Array(uniqueKeys.length).fill({ isError: false, message: '' })];
  if (supportsEntities) {
    let areUniqueKeysPopulated = true;
    let dataTypesCompatible = true;
    for (let i = 0; i < uniqueKeys.length; i++) {
      const sourceTypeVal = uniqueKeys[i]?.sourceDtype;
      const destinationTypeVal = uniqueKeys[i]?.destinationDtype;
      if (validateFieldMappingsTypes(sourceTypeVal, destinationTypeVal, typeMappings)) {
        dataTypesCompatible = false;
        errors[i] = {
          isError: true,
          message: 'Data types are not compatible',
        };
      }
      if (isEmpty(uniqueKeys[i]?.source) || isEmpty(uniqueKeys[i]?.destination)) {
        areUniqueKeysPopulated = false;
        break;
      }
    }

    let areExtraFormDataPopulated = true;
    for (let formField in extraFormdata) {
      if (isEmpty(extraFormdata[formField])) {
        areExtraFormDataPopulated = false;
        break;
      }
    }
    const mappingsRequired = uniqueKeys.length > 0 ? true : controlledMappings.length > 0;
    return {
      disable: !(
        object &&
        areExtraFormDataPopulated &&
        areUniqueKeysPopulated &&
        mappingsRequired &&
        dataTypesCompatible
      ),
      errors: errors,
    };
  }

  return { disable: controlledMappings.length === 0, errors: errors };
}

function isPipedriveType(integration: Integration) {
  return integration?.type === IntegrationType.PIPEDRIVE;
}

function isTrayType(integration: Integration) {
  return integration?.type === IntegrationType.TRAY;
}

function isHubspotType(integration: Integration) {
  return integration?.type === IntegrationType.HUBSPOT;
}

export default function MappingsForm() {
  const { data: connectedIntegrations } = useConnectedIntegrations(false);
  const {
    setCurrentSection,
    setMappings,
    setTags,
    tags,
    mappings,
    destinationId,
    object,
    uniqueKeys,
    extraFormData,
    subObject,
    subObjectFields,
  } = useExportPQLStore();
  const { targetEntity } = useDefinePQLStore();
  const { data: entities } = useEntitiesQuery(targetEntity.toLowerCase() as Entity);
  const {
    data: entityDetails,
    refetch: refetchEntityDetails,
    isLoading: entityDetailsLoading,
  } = useConnectedIntegrationEntityDetail();
  const { isEditExports, opportunityId } = usePQLWorkflowRoute();
  const { data: opportunity } = useOpportunity(opportunityId);
  const editExportMutation = useEditExports(Number(opportunityId), opportunity?.exports[0].id);
  const router = useHistory();
  const sourceItems = entities?.map((e) => {
    return {
      label: e.displayName,
      value: e.columnName,
      type: e.dataType,
      Icon: () => <img className="w-6 mr-2" src={getSourceImg(e.source)} alt={e.source} />,
    };
  });
  const destination = connectedIntegrations?.find((i) => i.id === destinationId);

  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors, dirtyFields },
    trigger,
    setValue,
  } = useForm({
    defaultValues: {
      mappings: mappings,
      tags: tags,
      typeMappings: {},
    },
    mode: 'onChange',
    resolver: async (data, context, options) => {
      // you can debug your validation schema here
      // console.log('validation result', await yupResolver(schema)(data, context, options));
      return yupResolver(schema)(data, context, options);
    },
  });

  const { fields, update, remove } = useFieldArray({
    control,
    name: 'mappings',
  });

  const {
    fields: tagFields,
    update: tagUpdate,
    remove: tagRemove,
  } = useFieldArray({
    control,
    name: 'tags',
  });

  const isCRM = connectedIntegrations?.find(
    (i) => i.id === destinationId && i.integration.supportsEntities
  );

  const watchMappings = watch('mappings');
  const controlledMappings = fields.map((field, index) => {
    return {
      ...field,
      ...watchMappings[index],
    };
  });

  const watchTags = watch('tags');
  const controlledTags = tagFields.map((field, index) => {
    return {
      ...field,
      ...watchTags[index],
    };
  });

  useEffect(() => {
    if (entityDetails?.fields && destination?.integration?.type === IntegrationType.TRAY) {
      const requiredMappings = entityDetails.fields
        .filter((field) => field.required && !field.unique)
        .map((field, index) => {
          return {
            source: mappings[index]?.source || '',
            destination: field.name || mappings[index]?.destination,
            required: true,
            destinationLabel: field?.label || field.name || '',
            sourceDtype: mappings[index]?.sourceDtype || '',
            destinationDtype: field.type || mappings[index]?.destinationDtype,
          };
        });
      const finalMappings = [
        ...requiredMappings,
        ...watch('mappings')
          .slice(requiredMappings.length)
          .map((mapping) => {
            return {
              source: mapping.source,
              destination: mapping.destination,
            };
          }),
      ];
      setValue('mappings', finalMappings);
      trigger('mappings');
    }

    setValue('typeMappings', destination?.integration?.metadata?.['typeMappings'] || {});
  }, [entityDetails]);

  useEffect(() => {
    // trigger validation
    // manually
    trigger('mappings');
    trigger('tags');
    return () => {
      setMappings(watch('mappings'));
      setTags(watch('tags'));
    };
  }, [setMappings, setTags, watch, trigger]);

  const onSubmit = (data) => {
    setMappings(data.mappings);
    setTags(data.tags);
  };

  const handleSave = () => {
    setMappings(watch('mappings'));
    setTags(watch('tags'));
    // mutate only after mappings and tags are
    // populated in the store.
    setTimeout(() => {
      editExportMutation.mutate();
    }, 0);
  };

  const isPipedrive = isPipedriveType(destination?.integration);
  const isHubspot = isHubspotType(destination?.integration);
  const isTray = isTrayType(destination?.integration);

  const [makeUniqueProperty, setMakeUniqueProperty] = useState(false);
  const [showNewDestinationModal, setShowNewDestinationModal] = useState(false);
  const showFields = isCRM ? object && (subObjectFields ? subObject : true) : true;

  const [isBackModalOpen, setIsBackModalOpen] = useState(false);

  const uniqueKeyValidationResult = checkExportValidation(
    isCRM,
    controlledMappings,
    object,
    uniqueKeys,
    extraFormData,
    destination?.integration?.metadata?.['typeMappings'] || {}
  );
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isCRM && (isEditExports ? !entityDetailsLoading && showFields : true) && (
          <ObjectUniqueFieldMapping
            setMakeUniqueProperty={setMakeUniqueProperty}
            setShowNewDestinationModal={setShowNewDestinationModal}
            destination={destination}
            isPipedrive={isPipedrive}
            isHubspot={isHubspot}
            errors={uniqueKeyValidationResult.errors}
          />
        )}
        {entityDetailsLoading && (
          <div className="h-10 mt-2.5 bg-tw-gray-eb animate-pulse w-160"></div>
        )}
        {showFields && !entityDetailsLoading && (
          <>
            {isCRM && <hr className="my-6 border-t border-dashed border-tw-gray-c" />}
            <div className="flex items-center gap-x-2">
              <h4 className="font-medium text-tw-black-1">
                {destination?.integration.name
                  ? `Send usage and profile data to  ${capitalizeFirstLetter(
                      destination?.integration.name
                    )}`
                  : 'Add fields to sync'}
              </h4>
              <ToolTip label={`e.g., for sales and marketing personalisation`}>
                <InfoToolTipIcon className="w-4 h-4" />
              </ToolTip>
            </div>
            {!!controlledMappings.length && (
              <div className="grid grid-cols-12 mt-5">
                <div className="col-span-5">Map a Toplyne field (e.g. Persona)...</div>
                <span></span>
                <div className="col-span-5">
                  {`...to a field on
            ${capitalizeFirstLetter(
              connectedIntegrations?.find((d) => d.id === destinationId).integration?.name
            )} (e.g., Toplyne Persona)`}
                </div>
                <span></span>
              </div>
            )}
            {controlledMappings.map((field: any, index: number) => {
              return (
                <div className="grid grid-cols-12 mt-4" key={field.id}>
                  <div
                    className={classNames(
                      'col-span-5',
                      'product-tour-14',
                      index === 1 && 'product-tour-18'
                    )}
                  >
                    <VirtualizedSelect
                      onChange={(value: string) => {
                        const type = find_dtype(value, sourceItems, 'value');
                        if (isCRM) {
                          if (field.required) {
                            // @ts-ignore
                            update(index, {
                              source: value,
                              destination: field?.destination,
                              required: true,
                              sourceDtype: type,
                              destinationDtype: field?.destinationDtype || undefined,
                              destinationLabel: field.destinationLabel ?? '',
                            });
                          } else if (isTray && isEmpty(entityDetails)) {
                            update(index, {
                              source: value,
                              destination: toSnakeCase(
                                entities.find((e) => e.columnName === value)?.displayName
                              ),
                              sourceDtype: type,
                              destinationDtype: field?.destinationDtype || undefined,
                              destinationLabel: field.destinationLabel ?? '',
                            });
                          } else {
                            update(index, {
                              source: value,
                              destination: field?.destination || '',
                              sourceDtype: type,
                              destinationDtype: field?.destinationDtype || undefined,
                              destinationLabel: field?.destinationLabel ?? '',
                            });
                          }
                        } else {
                          update(index, {
                            source: value,
                            destination: toSnakeCase(
                              entities.find((e) => e.columnName === value)?.displayName
                            ),
                            sourceDtype: type,
                            destinationDtype: field?.destinationDtype || undefined,
                            destinationLabel: field?.destinationLabel ?? '',
                          });
                        }
                        trigger('mappings');
                      }}
                      options={sourceItems}
                      value={field.source}
                      virtualize
                      ph="Select Toplyne field"
                      error={{
                        isError:
                          !!errors?.mappings?.[index]?.sourceDtype &&
                          !!dirtyFields?.mappings?.[index]?.sourceDtype,
                        message: errors?.mappings?.[index]?.sourceDtype?.message,
                      }}
                    />
                  </div>
                  <span></span>
                  <div className="col-span-5">
                    {getIntegrationSpecificDestinationField(
                      isCRM,
                      destination,
                      mappings,
                      index,
                      field,
                      trigger,
                      register,
                      update,
                      dirtyFields,
                      errors,
                      tagUpdate,
                      entityDetails,
                      controlledMappings,
                      uniqueKeys,
                      setMakeUniqueProperty,
                      setShowNewDestinationModal,
                      destination?.integration?.metadata
                    )}
                  </div>
                  <span className="grid place-items-center">
                    {(isPipedrive && index === 0) || field.required ? (
                      <span className="ml-2 text-xs text-tw-black-7">(Required)</span>
                    ) : (
                      <DeleteBinIcon
                        onClick={() => {
                          remove(index);
                          // revalidate mappings and tags
                          trigger('mappings');
                          trigger('tags');
                        }}
                        className="w-4 cursor-pointer fill-current text-tw-black-7"
                      />
                    )}
                  </span>
                </div>
              );
            })}
            <button
              onClick={() => {
                setValue('mappings', [...watch('mappings'), { source: '', destination: '' }], {
                  shouldDirty: false,
                  shouldValidate: false,
                  shouldTouch: false,
                });
              }}
              className="flex items-center mt-5 cursor-pointer select-none text-tw-blue-0d gap-x-1 product-tour-13 product-tour-17"
            >
              <PlusIcon className="fill-current" />
              <span className="font-medium">Add data</span>
            </button>
            {destination?.integration.name !== 'Facebook Ads' && (
              <>
                <hr className="my-6 border-t border-dashed border-tw-gray-c" />
                <div className="flex items-center gap-x-2">
                  <h4 className="font-medium text-tw-black-1">Tag your leads</h4>
                  <ToolTip
                    label={`Example, a tag called "Toplyne" can be added to each qualified lead. That tag can then be used to trigger a sequence on ${destination?.integration?.name}`}
                  >
                    <InfoToolTipIcon className="w-4 h-4" />
                  </ToolTip>
                </div>
                {!!controlledTags.length && (
                  <div className="grid grid-cols-12 mt-5">
                    <div className="col-span-5">Tag name</div>
                    <span></span>
                    <div className="col-span-5">Tag value</div>
                    <span></span>
                  </div>
                )}
                {controlledTags.map((field: any, index: number) => {
                  return (
                    <div className="grid grid-cols-12 mt-4" key={field.id}>
                      <div className="col-span-5">
                        {isCRM && !isEmpty(entityDetails) ? (
                          <VirtualizedSelect
                            onChange={(value: string) => {
                              tagUpdate(index, { name: value, value: field.value });
                              trigger('tags');
                            }}
                            options={entityDetails?.fields?.map((e) => ({
                              label: e.label,
                              value: e.name,
                            }))}
                            value={field.name}
                            virtualize
                            ph={`Select ${destination?.integration?.name || 'destination'} field`}
                            disabledFields={[
                              ...uniqueKeys.map((uniqueKey) => uniqueKey.destination),
                              ...controlledMappings.map((f) => !!f.destination && f.destination),
                            ]}
                            stuckField={
                              (isPipedrive || isHubspot) && (
                                <AddNewPropertyStuckField
                                  onclick={() => {
                                    setMakeUniqueProperty(false);
                                    setShowNewDestinationModal(true);
                                  }}
                                  integrationType={destination?.integration.type}
                                />
                              )
                            }
                          />
                        ) : (
                          <TextInput
                            className="w-full h-full"
                            onChange={({ target: { value } }) => {
                              tagUpdate(index, { name: value, value: value });
                            }}
                            placeholder="Name"
                            value={field.name}
                            {...register(`tags.${index}.name` as const, {
                              required: true,
                              onChange: () => {
                                // revalidate mappings and tags
                                trigger('mappings');
                                trigger('tags');
                              },
                            })}
                            isError={
                              !!errors?.tags?.[index]?.name && !!dirtyFields?.tags?.[index]?.name
                            }
                          />
                        )}
                      </div>
                      <span></span>
                      <div className="col-span-5">
                        <TextInput
                          className="w-full h-full"
                          onChange={({ target: { value } }) => {
                            tagUpdate(index, { value: value, name: value });
                          }}
                          placeholder="Value"
                          value={field.value}
                          {...register(`tags.${index}.value` as const, {
                            required: true,
                            onChange: () => {
                              // revalidate mappings and tags
                              trigger('mappings');
                              trigger('tags');
                            },
                          })}
                          isError={
                            !!errors?.tags?.[index]?.value && !!dirtyFields?.tags?.[index]?.value
                          }
                        />
                      </div>
                      <span className="grid place-items-center">
                        <DeleteBinIcon
                          onClick={() => tagRemove(index)}
                          className="w-4 cursor-pointer fill-current text-tw-black-7"
                        />
                      </span>
                    </div>
                  );
                })}
                <button
                  onClick={() =>
                    setValue('tags', [
                      ...watch('tags'),
                      {
                        name: '',
                        value: '',
                      },
                    ])
                  }
                  className="flex items-center mt-5 cursor-pointer select-none text-tw-blue-0d gap-x-1"
                >
                  <PlusIcon className="fill-current" />
                  <span className="font-medium">Add tag</span>
                </button>
              </>
            )}
          </>
        )}
        {!isEditExports && (
          <PrimaryButton
            className="w-full py-2 mt-5 product-tour-21"
            type="submit"
            disabled={Object.keys(errors).length >= 1 || uniqueKeyValidationResult?.disable}
            onClick={() => {
              setCurrentSection(ExportPQLSections.SCHEDULE);
            }}
          >
            Proceed
          </PrimaryButton>
        )}
        {isEditExports && (
          <div className="grid grid-cols-2 mt-6 gap-x-4">
            <SecondaryButton onClick={() => setIsBackModalOpen(true)}>Go back</SecondaryButton>
            <PrimaryButton onClick={() => handleSave()} isLoading={editExportMutation.isLoading}>
              Save
            </PrimaryButton>
          </div>
        )}
      </form>
      <Modal open={isBackModalOpen} onClose={() => setIsBackModalOpen(false)} closeIcon={null}>
        <div className="flex justify-end mt-4 mr-4">
          <div
            className="flex justify-center w-6 h-6 p-2 rounded-full cursor-pointer bg-tw-gray-eb"
            onClick={() => setIsBackModalOpen(false)}
          >
            <CrossIconWithoutBg className="-mt-0.5 fill-current border-0 w-3 h-3 text-tw-black-7 mx-auto" />
          </div>
        </div>
        <div className="p-6">
          <p className="text-base font-semibold text-tw-black-1">Save changes?</p>
          <p className="text-xs text-tw-black-5 mt-2.5">
            Any unsaved changes will be lost if you exit without saving
          </p>
          <div className="flex items-center justify-end mt-8 gap-x-2">
            <TertiaryButton onClick={() => router.goBack()}>Discard changes</TertiaryButton>
            <PrimaryButton
              className="w-36"
              isLoading={editExportMutation.isLoading}
              onClick={() => handleSave()}
            >
              Save and exit
            </PrimaryButton>
          </div>
        </div>
      </Modal>
      <NewDestinationField
        integration={destination}
        object={object}
        show={showNewDestinationModal}
        title={`Creating new field in  ${isPipedrive ? 'Pipedrive' : 'Hubspot'}`}
        availableFields={entityDetails?.fields?.map((field) => field.label)}
        onclose={(reFetch: boolean = false) => {
          if (reFetch === true) {
            // do not change this condition as onClick event can also be passed in this callback function
            refetchEntityDetails();
          }
          setShowNewDestinationModal(false);
        }}
        isUniqueProperty={makeUniqueProperty}
      />
      <DevTool control={control} />
    </>
  );
}
