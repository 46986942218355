import { TertiaryButton } from 'ui-components/inputs/Buttons';
import { useCallback, useState, useRef } from 'react';
import { IntegrationType } from '../../api-client';
import { ReactComponent as SuccessGreenTick } from '../../assets/images/successGreenTick.svg';
import useOAuth from '../../hooks/use-oauth';

interface OAuthConnectFieldProps {
  name: string;
  showReconnect?: boolean;
  type: IntegrationType;
  clientId: string;
  scope: string;
  authorizationUrl: string;
  onSuccess: (code: string, integrationName: string) => void;
  integrationName?: string;
}

export default function OAuthConnectField(props: OAuthConnectFieldProps) {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const integrationName = useRef('');

  const oauthCallback = useCallback(
    (err, code) => {
      setAuthenticated(!err);
      if (!err) {
        props.onSuccess(code, integrationName.current);
      }
      setLoading(false);
    },
    [setAuthenticated]
  );

  const authorize = useOAuth(
    props.type,
    props.clientId,
    props.authorizationUrl,
    props.scope,
    'integrations',
    oauthCallback
  );
  return !authenticated ? (
    <TertiaryButton
      className="w-40"
      onClick={() => {
        integrationName.current = props.integrationName;
        setLoading(true);
        authorize();
      }}
      isLoading={loading}
    >
      {props.showReconnect ? 'Reauthenticate' : 'Authenticate'}
    </TertiaryButton>
  ) : (
    <div className="px-2 py-1 font-semibold rounded bg-tw-green-e7 text-tw-green-20">
      <div className="flex items-center gap-x-1">
        <SuccessGreenTick />
        <div>CONNECTED</div>
      </div>
    </div>
  );
}
