import * as Popover from '@radix-ui/react-popover';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import dayjs from 'dayjs';
import { classNames } from 'utils/common';
import { useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { useFiltersPQLStore } from 'pages/pql-workflow/store/PQLFiltersStore';
import { useAccountsStore } from 'pages/users-and-accounts/store/AccountsStore';
import { useUsersStore } from 'pages/users-and-accounts/store/UsersStore';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../../assets/images/close-circle.svg';
import { ReactComponent as FilterIcon } from '../../assets/images/filter.svg';
import {
  boolComparatorMap,
  comparatorSymbolMap,
  dateComparatorMap,
  intComparatorMap,
  PopupFilterPage,
  stringComparatorMap,
} from '../../utils/globalTypes';
import useHistogramFilter from '../../pages/opportunities/hooks/useHistogramFilter';
import { CORRELATION } from '../../pages/opportunities/types';
import { Filter, QueryType, useQueryStore } from '../../stores/QueryStore';
import { CrossIcon } from '../data-display/Icons';
import { PopoverContent } from './opportunities/pql-filter-popover/PopoverContent';
import { PrimaryButton } from '../inputs/Buttons';

export interface Row {
  original: {
    COLUMN_NAME: string;
    CORRELATION?: string;
    KEYNAME: string;
    KEYSPACE: string;
    SOURCE: string;
    WEIGHT?: number;
    DATA_TYPE: string;
    DISPLAY_NAME: string;
  };
}

interface PopupFilterProps {
  row: Row;
  /**
   * Older PQL flow on new PQL flow
   * Depending on that, we update the store accordingly
   */
  old?: boolean;
  onClick?: () => void;
  onClose?: () => void;
  initialValue?: {
    timestamp?: {
      start: number;
      end: number;
      timeType: string;
    };
    numberValue?: string;
    stringValues?: string[];
  };
  buttonClassname?: string;
  isApplied?: boolean;
  trigger?: React.ReactNode;
  popoverSide?: 'top' | 'bottom' | 'right' | 'left';
  onApply?: (currentComparator: string, filterToBeApplied) => void;
  onReset?: (columnName: string) => void;
  shouldFetchHistogram?: boolean | null;
  entityType?: QueryType;
  //  Which page are we currently on?
  //    - users/accounts list page
  //    - PQL workflow page
  page: PopupFilterPage;
}

const PopupFilter: React.FC<PopupFilterProps> = ({
  row,
  old,
  onClick,
  onClose,
  onApply,
  onReset,
  initialValue,
  buttonClassname,
  isApplied,
  trigger,
  popoverSide = 'top',
  shouldFetchHistogram,
  entityType,
  page,
}) => {
  const { filters: usersFilters } = useUsersStore();
  const { filters: accountsFilters } = useAccountsStore();

  const queryStore = useQueryStore();
  const [stringComparator, setStringComparator] = useState<string>('eq');
  const [integerComparator, setIntegerComparator] = useState<string>('gt');
  const [boolComparator, setBoolComparator] = useState<string>('eq');
  const [dateComparator, setDateComparator] = useState<string>('inthelast');
  const [comparatorValue, setComparatorValue] = useState('');
  const [startComparatorValue, setStartComparatorValue] = useState('');
  const [endComparatorValue, setEndComparatorValue] = useState('');

  // const [filterFound, setFilterFound] = useState<boolean>(false);
  const [rowClicked, setRowClicked] = useState<boolean>(false);
  const { setQueryData, filter } = queryStore;
  const { targetEntity } = useDefinePQLStore();
  const { setFilters, filters: newPQLWorkflowFilters } = useFiltersPQLStore();
  const [startDateValue, setStartDateValue] = useState<number | Date>(0);
  const [endDateValue, setEndDateValue] = useState<number | Date>(1);
  const [duringSelectionRange, setDuringSelectionRange] = useState<{
    startDate: Date;
    endDate: Date;
    key: string;
  }>({
    startDate: new Date(),
    endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    key: 'selection',
  });
  const [relativeTimeOption, setRelativeTimeOption] = useState<string>('days');
  const { original } = row || {};
  const { DISPLAY_NAME, COLUMN_NAME, DATA_TYPE } = original || {};
  const [rowBins, setRowBins] = useState<any>([]);
  const [stringValues, setStringValues] = useState<string[]>([]);

  const { data, refetch, isLoading, isSuccess } = useHistogramFilter({
    columnName: COLUMN_NAME,
    type: entityType ?? targetEntity,
    shouldFetchHistogram,
  });

  const [filtersObj, setFiltersObj] = useState<Record<string, boolean>>({});
  const [value, setValue] = useState<string>('');
  const [localSearchBin, setLocalSearchBin] = useState<string[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedBoolValue, setSelectedBoolValue] = useState<string>('');
  const [isComparatorChanged, setIsComparatorChanged] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(
    ['str', 'int', 'float', 'bool'].includes(DATA_TYPE)
  );

  useEffect(() => {
    if (isSuccess) {
      setRowBins(data?.filters);
    }
  }, [data?.filters, isSuccess]);

  useEffect(() => {
    if (
      rowClicked &&
      DATA_TYPE === 'str' &&
      row.original.CORRELATION !== CORRELATION.HIGHLYCARDINAL
    ) {
      const getRowBins = async () => {
        if (!isSuccess) {
          await refetch();
        }
      };
      getRowBins();
    }
  }, [isSuccess, refetch, DATA_TYPE, rowClicked]);

  const setValueNullForExistsAndDoesNotExists = (comparator, filterToCheck) => {
    return comparator === 'null' || comparator === 'notnull' ? '' : filterToCheck;
  };

  const applyFilters = () => {
    const filters = Object.keys(filtersObj).filter((f) => filtersObj[f]);

    let filterToBeApplied;
    let currentComparator: string;

    if (DATA_TYPE === 'str') {
      currentComparator = stringComparator;
      if (stringComparator === 'contains' || stringComparator === 'doesnotcontain') {
        filterToBeApplied = localSearchBin.map((val: string) => val.trim().toLowerCase());
      } else {
        if (row.original.CORRELATION == CORRELATION.HIGHLYCARDINAL) {
          filterToBeApplied = stringValues;
        } else {
          if (stringComparator === 'eq' || stringComparator === 'ne') {
            filterToBeApplied = filters;
          }
        }
      }
    }
    if (DATA_TYPE === 'int' || DATA_TYPE === 'float') {
      currentComparator = integerComparator;
      if (integerComparator === 'between') {
        let rangeList = [];
        rangeList.push(startComparatorValue);
        rangeList.push(endComparatorValue);
        filterToBeApplied = rangeList;
      } else if (!['null', 'notnull', 'between'].includes(integerComparator)) {
        filterToBeApplied = value;
      }
    }
    if (DATA_TYPE === 'bool') {
      currentComparator = boolComparator;
      if (!['null', 'notnull'].includes(boolComparator)) {
        filterToBeApplied = selectedBoolValue;
      }
    }
    if (DATA_TYPE === 'timestamp') {
      currentComparator = dateComparator;
      if (dateComparator === 'inthelast') {
        let dateRangeData = {
          start: Number(startDateValue),
          end: Number(endDateValue),
          timeType: relativeTimeOption,
        };
        filterToBeApplied = dateRangeData;
      } else if (dateComparator === 'during') {
        let dateRangeData = {
          start: duringSelectionRange['startDate'].toISOString(),
          end: duringSelectionRange['endDate'].toISOString(),
        };
        filterToBeApplied = dateRangeData;
      }
    }

    filterToBeApplied = setValueNullForExistsAndDoesNotExists(currentComparator, filterToBeApplied);

    onApply(currentComparator, filterToBeApplied);
  };

  const clearFilters = () => {
    // TODO: Abstract this out in the future
    const prevFilters = filter;
    if (page === PopupFilterPage.PQLWORKFLOW) {
      if (old) {
        const newFilters = prevFilters.filter((prevFilter) => {
          return prevFilter.columnName !== COLUMN_NAME;
        });
        setQueryData({
          filter: [...newFilters],
        });
      } else {
        const newFilters = newPQLWorkflowFilters.filter((f) => f.columnName !== COLUMN_NAME);
        setFilters([...newFilters]);
      }
    } else {
      onReset(COLUMN_NAME);
    }
    // abstract this out upto this line in the future
    setFiltersObj({});
    setAllSelected(false);
    setStringComparator('eq');
    setIntegerComparator('gt');
    setBoolComparator('eq');
    setDateComparator('inthelast');
    setComparatorValue('');
    setSelectedBoolValue('');
    setLocalSearchBin([]);
    setStartDateValue(0);
    setEndDateValue(1);
    setStringValues([]);
    setDuringSelectionRange({
      startDate: new Date(),
      endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      key: 'selection',
    });
  };

  const handleInputDate = (dates: {
    [key: string]: {
      startDate: Date;
      endDate: Date;
      label?: string;
    };
  }) => {
    setDuringSelectionRange({
      ...duringSelectionRange,
      startDate: dates['selection']['startDate'],
      endDate: dates['selection']['endDate'],
    });
  };

  const getFilterWithComparator = (item: Filter) => {
    let v = Array.isArray(item?.value) ? item?.value.join(',') : item?.value;
    const compare = comparatorSymbolMap[item.op];

    if (item.op === 'during') {
      return [dayjs(v['start']).format('MM/DD'), dayjs(v['end']).format('MM/DD')];
    }
    if (item.op === 'inthelast') {
      return [v['start'], v['end'], v['timeType']];
    }
    v = Array.isArray(item?.value) ? `${compare}  ${v}` : `${compare} &-& ${v}`;

    const ret = item?.value?.length > 1 ? `${v}&-& + ${item?.value.length - 1}` : `${v}`;
    return ret.split('&-&');
  };

  const { location } = useHistory();

  // based on location.pathname we decide
  // whether to use filters from users store or accounts store.
  const getUserOrAccountFilters = React.useCallback(() => {
    return location.pathname.includes('users') ? usersFilters : accountsFilters;
  }, [accountsFilters, location.pathname, usersFilters]);

  const filterToUse: Array<any> = React.useMemo(
    () =>
      old
        ? filter
        : page === PopupFilterPage.PQLWORKFLOW
        ? newPQLWorkflowFilters
        : getUserOrAccountFilters(),
    [filter, getUserOrAccountFilters, newPQLWorkflowFilters, old, page]
  );

  const filterSelected = React.useMemo(
    () => filterToUse.map((f) => f.columnName).includes(COLUMN_NAME),
    [COLUMN_NAME, filterToUse]
  );

  useEffect(() => {
    if (!filterSelected) {
      setFiltersObj({});
      setAllSelected(false);
      setStringValues([]);
    }
    setLocalSearchBin([]);
    setIsComparatorChanged(!isComparatorChanged);
    if (
      DATA_TYPE === 'str' &&
      !(stringComparator === 'null' || stringComparator === 'notnull') &&
      !initialValue
    ) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [stringComparator]);

  useEffect(() => {
    if (!filterSelected) {
      setStartDateValue(0);
      setEndDateValue(1);
      setDuringSelectionRange({
        startDate: new Date(),
        endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        key: 'selection',
      });
    }
  }, [dateComparator]);

  useEffect(() => {
    if (!filterSelected) {
      setStartComparatorValue('');
      setEndComparatorValue('');
      setComparatorValue('');
    }
    if (
      DATA_TYPE === 'int' &&
      !(integerComparator === 'null' || integerComparator === 'notnull') &&
      !initialValue
    ) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [integerComparator]);

  useEffect(() => {
    if (!filterSelected) {
      setSelectedBoolValue('');
      setButtonDisabled(true);
    }
  }, [boolComparator]);

  // restore filters while cloning
  useEffect(() => {
    let item = filterToUse.filter((f) => f.columnName === COLUMN_NAME)[0];
    if (item) {
      let comparator = item.op;
      let itemVal = item.value;
      if (comparator === 'inthelast') {
        setStartDateValue(itemVal['start']);
        setEndDateValue(itemVal['end']);
        setRelativeTimeOption(itemVal['timeType']);
        setDateComparator('inthelast');
      }
      if (comparator === 'during') {
        setDuringSelectionRange({
          startDate: new Date(itemVal['start']),
          endDate: new Date(itemVal['end']),
          key: 'selection',
        });
        setDateComparator('during');
      }
      if (DATA_TYPE === 'bool') {
        setSelectedBoolValue(item.value as string);
        setBoolComparator(comparator);
      }
      if (DATA_TYPE === 'int' || DATA_TYPE === 'float') {
        if (comparator === 'between') {
          setStartComparatorValue(item.value[0]);
          setEndComparatorValue(item.value[1]);
          setIntegerComparator(item.op);
        } else {
          setComparatorValue(item.value as string);
          setIntegerComparator(item.op);
        }
      }
      if (DATA_TYPE === 'str') {
        if (row.original.CORRELATION === CORRELATION.HIGHLYCARDINAL) {
          setStringValues(item.value as string[]);
        } else {
          if (comparator === 'eq' || comparator === 'ne') {
            let obj = {};
            (item.value as string[])?.map((bin) => {
              obj = { ...obj, [bin]: !obj[bin] };
              return obj;
            });
            setFiltersObj(obj);
          }
          setStringComparator(comparator);
        }
      }
    }
  }, [COLUMN_NAME, DATA_TYPE, filterToUse, row.original.CORRELATION]);

  useEffect(() => {
    if (!isApplied) {
      if (initialValue?.timestamp) {
        setStartDateValue(initialValue.timestamp.start);
        setEndDateValue(initialValue.timestamp.end);
        setRelativeTimeOption(initialValue.timestamp.timeType);
      } else if (initialValue?.numberValue) {
        setValue(initialValue.numberValue);
        setComparatorValue(initialValue.numberValue);
      } else if (initialValue?.stringValues) {
        setStringValues(initialValue.stringValues);
        setFiltersObj(
          initialValue.stringValues.reduce((acc, curr) => {
            acc[curr] = true;
            return acc;
          }, {})
        );
      }
    }
  }, [initialValue, isApplied]);

  return (
    <Popover.Root
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Popover.Trigger
        className="relative"
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        {trigger ?? (
          <div className="align-middle">
            <div
              className={classNames(
                'flex items-center justify-center w-30.5 py-2 rounded bg-tw-gray-f5',
                !filterSelected
                  ? buttonClassname
                    ? 'bg-tw-blue-f2'
                    : 'bg-tw-gray-f5'
                  : 'bg-tw-blue-a',
                filterSelected ? 'justify-between' : 'justify-center'
              )}
              onClick={() => {
                setRowClicked(!rowClicked);
              }}
            >
              {filterSelected && (
                <div className="flex items-center">
                  {old &&
                    filter
                      .filter((item) => item.columnName === COLUMN_NAME)
                      .map((item) => {
                        return (
                          <div
                            key={item.columnName}
                            className="flex items-center justify-center w-24 py-0.5 pl-3 space-x-1 text-xs font-medium text-tw-blue-0d"
                          >
                            {DATA_TYPE !== 'timestamp' && (
                              <>
                                <div className="truncate">{getFilterWithComparator(item)[0]}</div>
                                <div className="shrink-0">
                                  {getFilterWithComparator(item)[1] || ''}
                                </div>
                              </>
                            )}
                            {DATA_TYPE === 'timestamp' && dateComparator === 'during' && (
                              <div className="px-2">
                                {getFilterWithComparator(item)[0]} to{' '}
                                {getFilterWithComparator(item)[1]}
                              </div>
                            )}
                            {DATA_TYPE === 'timestamp' && dateComparator === 'inthelast' && (
                              <div className="px-2">
                                {getFilterWithComparator(item)[0]} to{' '}
                                {getFilterWithComparator(item)[1]}{' '}
                                {getFilterWithComparator(item)[2]}
                                {}
                              </div>
                            )}
                          </div>
                        );
                      })}
                  {/**
                   * Display for new pql filters
                   */}
                  {!old &&
                    newPQLWorkflowFilters
                      .filter((item) => item.columnName === COLUMN_NAME)
                      .map((item) => {
                        return (
                          <div
                            key={item.columnName}
                            className="flex items-center justify-center w-24 py-0.5 pl-3 space-x-1 text-xs font-medium text-tw-blue-0d"
                          >
                            {DATA_TYPE !== 'timestamp' && (
                              <>
                                <div className="truncate">{getFilterWithComparator(item)[0]}</div>
                                <div className="shrink-0">
                                  {getFilterWithComparator(item)[1] || ''}
                                </div>
                              </>
                            )}
                            {DATA_TYPE === 'timestamp' && dateComparator === 'during' && (
                              <div className="px-2">
                                {getFilterWithComparator(item)[0]} to{' '}
                                {getFilterWithComparator(item)[1]}
                              </div>
                            )}
                            {DATA_TYPE === 'timestamp' && dateComparator === 'inthelast' && (
                              <div className="px-2">
                                {getFilterWithComparator(item)[0]} to{' '}
                                {getFilterWithComparator(item)[1]}{' '}
                                {getFilterWithComparator(item)[2]}
                                {}
                              </div>
                            )}
                          </div>
                        );
                      })}
                </div>
              )}
              {old && !filterSelected && (
                <div className="flex items-center px-3 -mt-0.5 font-medium rounded gap-x-1 bg-tw-gray-f5 text-tw-black-3">
                  <span>Apply</span>
                  <FilterIcon className="ml-2" />
                </div>
              )}
              {!old && !filterSelected && (
                <div
                  className={classNames(
                    'flex items-center px-3 -mt-0.5 font-medium rounded gap-x-1',
                    buttonClassname || 'bg-tw-gray-f5 text-tw-black-3'
                  )}
                >
                  <span>Apply</span>
                </div>
              )}
              {old && filterSelected && (
                <div className="pr-2.5">
                  <CrossIcon
                    className="w-2 fill-current text-tw-black-3"
                    onClick={(e) => {
                      clearFilters();
                      e.stopPropagation();
                    }}
                  />
                </div>
              )}
              {!old && filterSelected && (
                <div className="pr-2.5">
                  <CrossIcon
                    className="w-2 fill-current text-tw-black-3"
                    onClick={(e) => {
                      clearFilters();
                      e.stopPropagation();
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          side={popoverSide}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          className="relative z-10 pt-4 pb-3 mt-2 overflow-y-scroll text-left bg-white rounded-lg shadow-lg w-72 ring-black focus:outline-none text-tw-black-5"
        >
          <div className="absolute right-2 top-2">
            <PopoverPrimitive.Close aria-label="Close">
              <CloseIcon className="w-4 h-4" />
            </PopoverPrimitive.Close>
          </div>
          <div className="flex justify-between px-3">
            <div className="mt-2 overflow-auto text-xs font-medium text-tw-black-1">
              {DISPLAY_NAME}
            </div>
          </div>
          {(DATA_TYPE === 'int' || DATA_TYPE === 'float') && (
            <PopoverContent
              comparatorMap={intComparatorMap}
              setComparator={setIntegerComparator}
              setValue={setValue}
              comparatorType={integerComparator}
              dataType={DATA_TYPE}
              integerComparator={integerComparator}
              startComparatorValue={startComparatorValue}
              setStartComparatorValue={setStartComparatorValue}
              endComparatorValue={endComparatorValue}
              setEndComparatorValue={setEndComparatorValue}
              comparatorValue={comparatorValue}
              setComparatorValue={setComparatorValue}
              setButtonDisabled={setButtonDisabled}
            />
          )}
          {DATA_TYPE === 'timestamp' && (
            <PopoverContent
              comparatorMap={dateComparatorMap}
              setComparator={setDateComparator}
              comparatorType={dateComparator}
              startDateValue={startDateValue}
              setStartDateValue={setStartDateValue}
              endDateValue={endDateValue}
              setEndDateValue={setEndDateValue}
              duringSelectionRange={duringSelectionRange}
              relativeTimeOption={relativeTimeOption}
              setRelativeTimeOption={setRelativeTimeOption}
              dateComparator={dateComparator}
              handleInputDate={handleInputDate}
              dataType={DATA_TYPE}
              setButtonDisabled={setButtonDisabled}
            />
          )}
          {DATA_TYPE === 'str' && (
            <PopoverContent
              comparatorMap={stringComparatorMap}
              setComparator={setStringComparator}
              filtersObj={filtersObj}
              setFiltersObj={setFiltersObj}
              comparatorType={stringComparator}
              rowBins={rowBins}
              isLoading={isLoading}
              dataType={DATA_TYPE}
              allSelected={allSelected}
              setAllSelected={setAllSelected}
              stringComparator={stringComparator}
              isComparatorChanged={isComparatorChanged}
              localSearchBin={localSearchBin}
              setLocalSearchBin={setLocalSearchBin}
              stringValues={stringValues}
              setStringValues={setStringValues}
              correlation={row.original.CORRELATION}
              setButtonDisabled={setButtonDisabled}
            />
          )}
          {DATA_TYPE === 'bool' && (
            <PopoverContent
              comparatorMap={boolComparatorMap}
              setComparator={setBoolComparator}
              setSelectedBoolValue={setSelectedBoolValue}
              comparatorType={boolComparator}
              dataType={DATA_TYPE}
              selectedBoolValue={selectedBoolValue}
              setButtonDisabled={setButtonDisabled}
            />
          )}
          <hr className="w-full mt-3 mb-2" />
          <div className="px-3">
            <div className="flex justify-end gap-x-3">
              <PopoverPrimitive.Close aria-label="Close">
                {filterSelected && (
                  <div
                    className="flex items-center px-4 py-2 text-sm font-semibold rounded border-1 border-tw-black-9 text-tw-black-7"
                    onClick={() => clearFilters()}
                  >
                    Reset
                  </div>
                )}
                {!filterSelected && (
                  <div className="flex items-center px-4 py-2 text-sm font-semibold rounded border-1 border-tw-black-9 text-tw-black-7">
                    Close
                  </div>
                )}
              </PopoverPrimitive.Close>
              <PopoverPrimitive.Close disabled={buttonDisabled} aria-label="Close">
                <PrimaryButton
                  className={classNames(
                    'px-4 py-2 gap-x-2 border-1 border-transparent flex items-center text-sm justify-center font-semibold text-white rounded bg-tw-blue-0d'
                  )}
                  disabled={buttonDisabled}
                  onClick={applyFilters}
                >
                  Apply
                </PrimaryButton>
              </PopoverPrimitive.Close>
            </div>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default PopupFilter;

PopupFilter.defaultProps = {
  old: true,
  onClick: () => {},
  onClose: () => {},
};
