import { QueryClient, useMutation, useQueryClient } from 'react-query';
import { AddIntegrationRequest, ConnectedIntegration, useClient } from '../../../api-client';
import { getIntegrationConnectorType } from '../../../utils/common';
import { useAnalytics } from '../../../telemetry';
import { AnalyticsConst, AnalyticsEvents } from '../../../telemetry/constants';
import useIntegrations from './useIntegrations';

export async function addNewIntegrationToCache(
  queryClient: QueryClient,
  data: ConnectedIntegration
) {
  await queryClient.cancelQueries(['integrations', 'connected']);

  const previousConnectedIntegrationsWithEmail = queryClient.getQueryData([
    'integrations',
    'connected',
    false,
  ]);
  const previousConnectedIntegrationsWithoutEmail = queryClient.getQueryData([
    'integrations',
    'connected',
    true,
  ]);

  if (previousConnectedIntegrationsWithEmail) {
    queryClient.setQueryData(['integrations', 'connected', false], (old) => [
      ...(old as any),
      data,
    ]);
  }

  if (previousConnectedIntegrationsWithoutEmail) {
    queryClient.setQueryData(['integrations', 'connected', true], (old) => [...(old as any), data]);
  }
  return {
    previousConnectedIntegrationsWithEmail,
    previousConnectedIntegrationsWithoutEmail,
  };
}

export default function useAddIntegration() {
  const client = useClient();
  const queryClient = useQueryClient();
  const allIntegration = useIntegrations();
  const { track } = useAnalytics();
  return useMutation(
    (data: AddIntegrationRequest & { schemaName?: string }) => {
      const { schemaName, ...rest } = data;
      return client.addIntegration(rest, schemaName);
    },
    {
      onSuccess: async (data) => {
        return addNewIntegrationToCache(queryClient, data);
      },
      onError: (error, variables) => {
        track(AnalyticsEvents.CONNECTED_INTEGRATION_CREATED_FAILED, {
          [AnalyticsConst.INTEGRATION_NAME]: variables.name,
          [AnalyticsConst.INTEGRATION_TYPE]: variables.type,
          [AnalyticsConst.CONNECTOR_TYPE]: getIntegrationConnectorType(
            allIntegration.data,
            variables.type
          ),
        });
      },
      onSettled: () => {
        queryClient.refetchQueries(['integrations', 'connected']);
      },
    }
  );
}
