export default function SnowflakeBestPractices({ source }) {
  return (
    <div className="px-6">
      <div className="text-lg font-medium text-tw-black-3">
        Best practice for setting up analytics
      </div>
      <div className="mt-6">
        Data in {source} typically resides in Tables. When you are sharing data with Toplyne, the
        best practice is to create separate tables for event data and profile data.
      </div>
      <div className="my-6">
        <div className="mb-1 font-bold">Event data table </div>
        <div className="mb-1">
          This table will capture an action an entity takes on your website/application
        </div>

        <ul className="mb-1 ml-3 list-disc">
          <li>
            Each row in this table represents an event that has been triggered by a user/account
          </li>
          <li>
            Columns
            <ul className="ml-3 list-disc">
              <li>
                ACCOUNT_ID/USER_ID (Required): Key to identify which entity has performed the event
              </li>
              <li>EVENT_NAME (Required): Name of the event</li>
              <li>TIME_STAMP (Required): Timestamp at which the event has occurred</li>
              <li>
                EVENT_PROPERTIES (Optional): Event properties, typically shared as a JSON with
                key/value pairs
              </li>
            </ul>
          </li>
        </ul>
        <div className="mt-2">
          <div className="mb-1 italic font-bold font-size-sm">Sample table</div>
          <img
            src="https://assets.toplyne.io/setup-guides/snowflake-event-data-table.png"
            className="w-2/3"
          />
        </div>
      </div>

      <div className="my-6">
        <div className="mb-1 font-bold ">Profile data table </div>
        <div className="mb-1">
          This table will capture any profile information a user/account has, it will help answer
          the question “who is a user/account”
        </div>
        <ul className="mb-1 ml-3 list-disc">
          <li>Each row is a unique user/account</li>
          <li>Each column is profile key, and against it you will have its value</li>
        </ul>
        <div className="mt-2">
          <div className="mb-1 italic font-bold font-size-sm">Sample table</div>
          <img
            src="https://assets.toplyne.io/setup-guides/snowflake-profile-data-table.png"
            className="w-2/3"
          />
        </div>
      </div>
    </div>
  );
}

SnowflakeBestPractices.defaultProps = {
  source: 'Snowflake',
};
