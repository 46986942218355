import Card from 'ui-components/surfaces/cards/Card';
import { ErrorBoundary } from 'react-error-boundary';
import { disableUsersTableInAccountsDetails } from 'utils/common';
import { AccountFields } from 'pages/users-and-accounts/fields';
import { useAuth } from 'hooks/useAuth';
import { useAuthStore } from 'hooks/useAuthStore';
import useEntities from 'hooks/useEntities';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useApiClient } from '../../../../api-client';
import Attribute from '../../../../ui-components/business/attributes/Attribute';
import {
  ATTRIBUTE_CONST,
  checkDsEnabled,
  processAttributeDetails,
  processToplyneStats,
  setDefaultAttributes,
} from '../../../../ui-components/business/attributes/Attributes';
import useAttributePreferences from '../hooks/useAttributePreferences';
import useModels from '../../../../hooks/useModels';
import { QueryType } from '../../../../stores/QueryStore';
import useEntitiesQuery from '../../../../hooks/useEntitiesQuery';
import { Entity, SortOrder } from '../../users/types';
import useEntityDetails from '../hooks/useAccountDetails';
import { useUserAccountsStore } from '../hooks/useUserAccountsStore';
import ToplyneStats from '../toplyne-shap/ToplyneStats';
import HeroContent from './HeroContent';
import UserList from './UserList';
import { ErrorAnalyticsEvents } from 'telemetry/constants';
import ErrorBoundaryFallback from 'ui-components/feedback/ErrorBoundaryFallback';
import { useAnalytics } from 'telemetry';

const TableShimmer = (props: any) => {
  return (
    <div className="md:w-1/1">
      <div className="h-full overflow-hidden">
        <div className="p-4">
          <div className="w-1/6 h-10 mb-4 bg-tw-gray-eb animate-pulse"></div>
          <div className="animate-pulse">
            {Array(props?.count)
              .fill(0)
              .map((val, index) => (
                <div key={index} className="w-full h-10 odd:bg-tw-gray-eb" />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
interface AccountDetailsProps {
  match?: any;
}

const AccountDetails: React.FC<AccountDetailsProps> = () => {
  const { getAccountId } = useAuthStore();
  const location = useLocation();

  const accountId = decodeURIComponent(
    location.pathname.replace(`/${getAccountId()}/accounts/`, '').replaceAll('/', '\\')
  );
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { setFilterExpression, setFieldMapping, setSortBy } = useUserAccountsStore();
  const fieldMappings = [
    // {
    //   keyAlias: 'Plan',
    //   keyName: 'Plan',
    //   source: 'amplitude',
    //   keySpace: 'profile_properties',
    //   displayName: 'Plan',
    // },
  ];

  const { data: modelData, isLoading: isModalLoading, isError: isModalError } = useModels();

  const { user: userData } = useAuth();
  const isUsersTableEnabled =
    modelData?.some((d) => d.entity === QueryType.users.toLowerCase() && d.active) &&
    !disableUsersTableInAccountsDetails(userData?.currentOrganization?.schemaName);

  const { data: entities, isLoading: loadingEntities } = useEntities();

  const {
    data: accountDetailsData,
    isLoading: isAccountDetailsLoading,
    isError: isAccountDetailsError,
  } = useEntityDetails(Entity.accounts, accountId);

  const {
    data: accountMetaData,
    isLoading: isAccountMetaLoading,
    isError: isAccountMetaError,
  } = useEntitiesQuery(Entity.accounts);
  const {
    data: userAttributePreferences,
    isLoading: isUserAttributePreferenceLoading,
    isError: isUserAttributePreferenceError,
  } = useAttributePreferences('entity=accounts');

  const dsEnabled = checkDsEnabled(modelData);

  const { track } = useAnalytics();

  const [userAttributePreferencesBase, setUserAttributePreferencesBase] = React.useState({});
  const [tempUserAttributePreferencesBase, setTempUserAttributePreferencesBase] = React.useState(
    {}
  );
  const [attributeData, setAttributeData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [billingHeroData, setBillingHeroData] = React.useState({
    billing_subscription_total: null,
    billing_next_charge_date: null,
  });
  const [heroContentData, setHeroContentData] = React.useState({
    country: 'N/A',
    user_creation_time: '',
    website: '',
    non_paying_users: 0,
    account_is_paid: false,
    domain_1: '',
    source: '',
    account_id: '',
    paying_users: 0,
  });
  const [toplyneStatsData, setToplyneStatsData] = React.useState({
    expansion: { name: 'Expansion Score', value: 0, key: 'expansion_score' },
    conversion: { name: 'Conversion Score', value: 0, key: 'conversion_score' },
    engagement: { name: 'Engagement Score', value: 0, key: 'engagement_score' },
  });

  const accountJoinField = userData?.currentOrganization[AccountFields.ACCOUNT_JOIN_FIELD];

  React.useEffect(() => {
    if (
      !isAccountDetailsLoading &&
      !isAccountMetaLoading &&
      !isUserAttributePreferenceLoading &&
      !isModalLoading &&
      !isAccountDetailsError &&
      !isAccountMetaError &&
      !isUserAttributePreferenceError &&
      !isModalError
    ) {
      let displayAttribute;
      if (userAttributePreferences.length === 0) {
        displayAttribute = { profileEvents: [], billing: [], profileProperties: [] };
      } else {
        displayAttribute = userAttributePreferences[0]['displayAttribute'];
      }
      setUserAttributePreferencesBase(displayAttribute);

      let {
        _profilePropertiesData,
        _billingData,
        _productEventData,
        profilePropertiesShowCount,
        billingPropertyShowCount,
        productEventsPropertyShowCount,
        _heroContentData,
        _billingHeroData,
      } = processAttributeDetails(
        accountDetailsData['accountDetails'],
        displayAttribute,
        accountMetaData,
        heroContentData,
        billingHeroData
      );

      if (dsEnabled) {
        setToplyneStatsData(
          processToplyneStats(accountDetailsData['accountDetails'], toplyneStatsData)
        );
      }

      setAttributeData({
        [ATTRIBUTE_CONST.PRODUCT_EVENTS]: _productEventData,
        [ATTRIBUTE_CONST.BILLING]: _billingData,
        [ATTRIBUTE_CONST.PROFILE_PROPERTIES]: _profilePropertiesData,
      });
      setHeroContentData(_heroContentData);
      setBillingHeroData(_billingHeroData);
      setUserAttributePreferencesBase(displayAttribute);
      let { productEventData, billingData, profilePropertiesData, temporarySelections } =
        setDefaultAttributes(
          _profilePropertiesData,
          _billingData,
          _productEventData,
          profilePropertiesShowCount,
          billingPropertyShowCount,
          productEventsPropertyShowCount,
          displayAttribute
        );
      setTempUserAttributePreferencesBase(temporarySelections);
      setAttributeData({
        [ATTRIBUTE_CONST.PRODUCT_EVENTS]: productEventData,
        [ATTRIBUTE_CONST.BILLING]: billingData,
        [ATTRIBUTE_CONST.PROFILE_PROPERTIES]: profilePropertiesData,
      });
      setFilterExpression(
        {
          source: accountJoinField?.split(',')[0],
          keySpace: accountJoinField?.split(',')[1],
          keyName: accountJoinField?.split(',')[2],
          op: 'eq',
          value: accountId,
        },
        true
      );
      fieldMappings.forEach((fieldMapping) => setFieldMapping(fieldMapping));
      modelData.forEach((model) => {
        if (model.entity === Entity.accounts && model.active && model.dsEnabled) {
          setFieldMapping({
            displayName: `${model.name} Lead Status`,
            keyAlias: `${model.modelId}_lead_status`,
            keyName: `${model.modelId}_lead_status`,
            keySpace: 'profile_properties',
            source: 'amplitude',
          });
          setFieldMapping({
            displayName: `${model.name} Score`,
            keyAlias: `${model.modelId}_score`,
            keyName: `${model.modelId}_score`,
            keySpace: 'profile_properties',
            source: 'amplitude',
          });
          setSortBy({
            keyName: `${model.modelId}_score`,
            order: SortOrder.descending,
            keySpace: 'profile_properties',
            source: 'amplitude',
          });
        }
      });
      setIsLoading(false);
    }
  }, [isAccountDetailsLoading, isAccountMetaLoading, isUserAttributePreferenceLoading]);

  const updateAttributePreferences = async (preferences, tempPreferences, updateDB) => {
    try {
      setTempUserAttributePreferencesBase(tempPreferences);
      if (updateDB) {
        const { data } = await apiClient.post(`data/user-attributes/`, {
          display_attribute: preferences,
          entity: 'accounts',
        });
        await queryClient.invalidateQueries('displayAttributes');
        let {
          _profilePropertiesData,
          _billingData,
          _productEventData,
          profilePropertiesShowCount,
          billingPropertyShowCount,
          productEventsPropertyShowCount,
          _heroContentData,
          _billingHeroData,
        } = processAttributeDetails(
          accountDetailsData['accountDetails'],
          data['displayAttribute'],
          accountMetaData,
          heroContentData,
          billingHeroData
        );

        if (dsEnabled) {
          setToplyneStatsData(
            processToplyneStats(accountDetailsData['accountDetails'], toplyneStatsData)
          );
        }

        setAttributeData({
          [ATTRIBUTE_CONST.PRODUCT_EVENTS]: _productEventData,
          [ATTRIBUTE_CONST.BILLING]: _billingData,
          [ATTRIBUTE_CONST.PROFILE_PROPERTIES]: _profilePropertiesData,
        });
        setHeroContentData(_heroContentData);
        setBillingHeroData(_billingHeroData);
        setUserAttributePreferencesBase(data['displayAttribute']);
        let { productEventData, billingData, profilePropertiesData, temporarySelections } =
          setDefaultAttributes(
            _profilePropertiesData,
            _billingData,
            _productEventData,
            profilePropertiesShowCount,
            billingPropertyShowCount,
            productEventsPropertyShowCount,
            data['displayAttribute']
          );
        setTempUserAttributePreferencesBase(temporarySelections);
        setAttributeData({
          [ATTRIBUTE_CONST.PRODUCT_EVENTS]: productEventData,
          [ATTRIBUTE_CONST.BILLING]: billingData,
          [ATTRIBUTE_CONST.PROFILE_PROPERTIES]: profilePropertiesData,
        });
      } else {
        let {
          _profilePropertiesData,
          _billingData,
          _productEventData,
          _heroContentData,
          _billingHeroData,
        } = processAttributeDetails(
          accountDetailsData['accountDetails'],
          preferences,
          accountMetaData,
          heroContentData,
          billingHeroData
        );

        if (dsEnabled) {
          setToplyneStatsData(
            processToplyneStats(accountDetailsData['accountDetails'], toplyneStatsData)
          );
        }

        setAttributeData({
          [ATTRIBUTE_CONST.PRODUCT_EVENTS]: _productEventData,
          [ATTRIBUTE_CONST.BILLING]: _billingData,
          [ATTRIBUTE_CONST.PROFILE_PROPERTIES]: _profilePropertiesData,
        });
        setHeroContentData(_heroContentData);
        setBillingHeroData(_billingHeroData);
        setUserAttributePreferencesBase(preferences);
      }
    } catch (error) {
      return;
    }
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={(e) =>
        track(ErrorAnalyticsEvents.ERROR_OCCURED, {
          error: e.message,
        })
      }
    >
      <div className="grid grid-flow-col grid-cols-10 gap-4 mt-8 h-5/6">
        <div className={dsEnabled ? `col-span-7 ml-4` : `col-start-2 col-span-8`}>
          {isLoading ? (
            <Card
              bodyClass={''}
              className={'mb-4 rounded'}
              hover={false}
              style={{ border: 'none' }}
            >
              <div className="flex space-x-4 animate-pulse">
                <div className="flex-1 py-1 space-y-4">
                  <div className="h-12 bg-gray-100 rounded" />
                  <div className="grid grid-cols-2 gap-4 space-y-2">
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                  </div>
                </div>
              </div>
            </Card>
          ) : (
            <>
              <HeroContent
                country={heroContentData?.country}
                createdAt={heroContentData.user_creation_time}
                domain={heroContentData.domain_1}
                freeUsers={heroContentData.non_paying_users}
                payingUsers={heroContentData.paying_users}
                isPaid={heroContentData.account_is_paid}
                website={heroContentData.website}
                source={heroContentData.source}
                accountName={''}
                accountID={heroContentData.account_id}
                firstPayment={''}
              />
            </>
          )}
          {isLoading ? (
            <Card
              bodyClass={''}
              className={'mb-4 rounded'}
              hover={false}
              style={{ border: 'none' }}
            >
              <div className="flex space-x-4 animate-pulse">
                <div className="flex-1 py-1 space-y-4">
                  <div className="flex justify-between h-12">
                    <div className="w-1/6 h-4 col-span-1 bg-gray-100 rounded" />
                    <span className={'bg-gray-100 mr-4 px-16 py-2 rounded w-8'} />
                  </div>
                  <div className="h-12 rounded">
                    <span className={'bg-gray-100 h-4 mr-4 px-12 py-2 rounded w-8'} />
                    <span className={'bg-gray-100 h-4 mr-4 px-12 py-2 rounded w-8'} />
                    <span className={'bg-gray-100 h-4 mr-4 px-12 py-2 rounded w-8'} />
                  </div>
                  <div className="grid grid-cols-2 gap-4 space-y-2">
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                  </div>
                </div>
              </div>
            </Card>
          ) : (
            <Attribute
              productEventsData={attributeData[ATTRIBUTE_CONST.PRODUCT_EVENTS]}
              billingData={attributeData[ATTRIBUTE_CONST.BILLING]}
              profilePropertiesData={attributeData[ATTRIBUTE_CONST.PROFILE_PROPERTIES]}
              userAttributePreferences={userAttributePreferencesBase}
              updateParentAttributePreferences={updateAttributePreferences}
              billingHeroData={billingHeroData}
              ATTRIBUTE_CONST={ATTRIBUTE_CONST}
              temporaryUserAttributePreferences={tempUserAttributePreferencesBase}
            />
          )}
          {isUsersTableEnabled &&
            (isLoading ? (
              <Card
                bodyClass={''}
                className={'mb-4 rounded'}
                hover={false}
                style={{ border: 'none' }}
              >
                <TableShimmer />
              </Card>
            ) : (
              !loadingEntities && entities?.find((e) => e.id === 'users')?.enabled && <UserList />
            ))}
        </div>
        {dsEnabled && (
          <div className="col-span-3 row-span-2 mr-4">
            <ToplyneStats
              scores={toplyneStatsData}
              queryType={QueryType.accounts}
              id={accountId}
              entity={Entity.accounts}
            />
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default AccountDetails;
