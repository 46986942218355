import { Box } from '@chakra-ui/react';
import { useRef, useCallback, FC } from 'react';
import { useVirtual } from 'react-virtual';

type Option = { label: string; value: string; keySpace?: string; isSystem?: boolean };

interface Props {
  parentHeight: string;
  parentWidth?: string;
  childHeight: number;
  totalRows: number;
  childValues: Option[];
  onChange?: any;
  value?: any;
  closeMenu?: Function;
  render?: (option: Option) => any;
}

const MenuVirtualized: FC<Props> = (props) => {
  const {
    parentHeight,
    childHeight,
    totalRows,
    parentWidth,
    childValues,
    onChange,
    value,
    closeMenu,
    render,
  } = props;
  const parentRef = useRef();
  const rowVirtualizer = useVirtual({
    size: totalRows,
    parentRef,
    estimateSize: useCallback(() => childHeight, []),
    overscan: 5,
  });

  return (
    <>
      <div
        ref={parentRef as any}
        style={{
          height: parentHeight,
          width: parentWidth ? parentWidth : `400px`,
          overflow: 'auto',
        }}
      >
        <div
          style={{
            height: `${rowVirtualizer.totalSize}px`,
            width: '100%',
            position: 'relative',
          }}
          onChange={onChange}
        >
          {rowVirtualizer.virtualItems.map((virtualRow) => {
            return (
              <Box
                key={virtualRow.index}
                className="grid pl-4"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                }}
                _hover={{ backgroundColor: '#FAFAFA' }}
                onClick={() => {
                  if (value === childValues[virtualRow.index].value) {
                    closeMenu && closeMenu();
                    return;
                  }
                  onChange && onChange(childValues[virtualRow.index].value);
                  closeMenu && closeMenu();
                }}
              >
                {render
                  ? render(childValues[virtualRow.index])
                  : childValues[virtualRow.index].label}
              </Box>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MenuVirtualized;
