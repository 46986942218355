import { toast as toastify } from 'react-toastify';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useClient } from '../../../api-client';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';

type SendNewPasswordDetails = {
  uid: string;
  token: string;
  password: string;
};

export default function useSendNewPassword() {
  const client = useClient();
  const router = useHistory();
  return useMutation(
    (data: SendNewPasswordDetails) => client.sendNewPassword(data.uid, data.token, data.password),
    {
      onSuccess: () => {
        toastify(<SuccessToast description="Your password has been successfully reset" />, {
          type: 'success',
        });
        router.push('/password/confirm');
      },
      onError: (error) => {
        const err = error['response']['data']['detail']['password'];
        toastify(<ErrorToast description={err ?? 'Uh oh! Something went wrong'} />, {
          type: 'error',
        });
      },
    }
  );
}
