import { OrganizationStatus, useClient } from 'api-client';
import { useQuery } from 'react-query';

export default function useOrganization(schemaName: string) {
  const client = useClient();
  return useQuery(['organization', schemaName], () => client.validateOrg(schemaName), {
    enabled: !!schemaName,
    refetchInterval: (data) => {
      if (data?.status === OrganizationStatus.IN_PROGRESS) {
        return 3000;
      }
      return false;
    },
  });
}
