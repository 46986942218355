import { TickIconFilled } from 'ui-components/data-display/Icons';
import { classNames } from 'utils/common';

export function CheckBoxCircle({
  checked,
  className,
  checkboxClassname,
  checkedClassName,
  uncheckedClassName,
  onClick,
}: {
  checked: boolean;
  className?: string;
  checkboxClassname?: string;
  onClick?: () => void;
  checkedClassName?: string;
  uncheckedClassName?: string;
}) {
  return (
    <div
      className={classNames('border-2 rounded-full', className, checked && 'border-transparent')}
      onClick={onClick}
    >
      <TickIconFilled
        className={classNames(
          'fill-current rounded-full',
          checked ? `${checkedClassName} text-tw-blue-0d` : `${uncheckedClassName} text-white`,
          checkboxClassname
        )}
      />
    </div>
  );
}
