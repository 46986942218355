import { ConnectedIntegration, IntegrationType } from 'api-client';
import { AddNewPropertyStuckField } from 'ui-components/business/custom-forms/destination-export-forms/AddNewPropertyStuckField';
import VirtualizedSelect from 'ui-components/inputs/VirtualizedSelect';
import { capitalizeFirstLetter, getSourceImg } from 'utils/common';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import useConnectedIntegrations from 'hooks/useConnectedIntegrations';
import useConnectedIntegrationEntities from 'pages/pql-workflow/hooks/useConnectedIntegrationEntities';
import useConnectedIntegrationEntityDetail from 'pages/pql-workflow/hooks/useConnectedIntegrationEntityDetail';
import { useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { useExportPQLStore } from 'pages/pql-workflow/store/PQLExportStore';
import useEntitiesQuery from 'hooks/useEntitiesQuery';
import { Entity } from 'pages/users-and-accounts/users/types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { TextInput } from '../../../../../ui-components/inputs/Inputs';
import useConnectedIntegrationFormDropDownOptions from '../../../hooks/useAsyncFormOptions';

type ObjectUniqueFieldMappingProps = {
  setMakeUniqueProperty: (makeUniqueProperty: boolean) => void;
  setShowNewDestinationModal: (showNewDestinationModal: boolean) => void;
  isPipedrive: boolean;
  isHubspot: boolean;
  destination: ConnectedIntegration;
  errors: Array<{
    message: string;
    isError: boolean;
  }>;
};

const find_dtype = (value: string, sourceItems: any) => {
  return sourceItems.find((x) => x.value == value).type;
};

function generateDropDownSourceUrl(cid, source, extraFormData) {
  if (source) {
    let optionsUrl = `/data/connected-integrations/${cid}/custom_op/${source.pathName}`;
    if (source.queryParams) {
      let queryString = '?';
      source.queryParams.forEach((queryParam) => {
        queryString += `${queryParam}=${extraFormData[queryParam]}`;
      });
      optionsUrl += queryString;
    }
    return optionsUrl;
  }
  return null;
}

function AsynSelect(props) {
  const { data: options, isLoading } = useConnectedIntegrationFormDropDownOptions(
    props.sourceUrl,
    props.choices,
    props.entity,
    props.integrationId,
    props.name
  );
  return !isLoading ? (
    <div className="w-60">
      <VirtualizedSelect
        onChange={(value: string) => {
          props.onChange(value);
        }}
        ph={''}
        options={options?.map((option) => ({ label: option.label, value: option.name })) || []}
        value={props.value}
      />
    </div>
  ) : (
    <div className="h-10 mt-2.5 bg-tw-gray-eb animate-pulse w-80"></div>
  );
}

function FormComponentBasedOnType({ value, onChange, type, ...rest }) {
  switch (type) {
    case 'TEXTBOX':
      return <TextInput onChange={(e) => onChange(e.target.value)} value={value} />;
    case 'STATIC':
      return <TextInput readOnly value={value} />;
    case 'DROPDOWN':
      return <AsynSelect value={value} onChange={onChange} {...rest} />;

    default:
      return null;
  }
}

export default function ObjectUniqueFieldMapping({
  setMakeUniqueProperty,
  setShowNewDestinationModal,
  isPipedrive,
  isHubspot,
  destination,
  errors,
}: ObjectUniqueFieldMappingProps) {
  const {
    destinationId,
    object,
    setObject,
    setUniqueKeys,
    uniqueKeys,
    setExtraFormData,
    extraFormData,
    subObject,
    setSubObject,
    subObjectFields,
    setSubObjectFields,
  } = useExportPQLStore();
  const { targetEntity } = useDefinePQLStore();
  const [formFields, setFormFields] = useState({});
  const { data: connectedIntegrations } = useConnectedIntegrations(false);
  const { data: entities } = useEntitiesQuery(targetEntity.toLowerCase() as Entity);
  const { data: connectedIntegrationEntities, isLoading: connectedIntegrationEntitiesIsLoading } =
    useConnectedIntegrationEntities();
  const { data: entityDetails, isLoading: entityDetailsLoading } =
    useConnectedIntegrationEntityDetail();
  const didMount = useRef(false);
  const sourceItems = entities?.map((e) => {
    return {
      label: e.displayName,
      value: e.columnName,
      Icon: () => <img className="w-6 mr-2" src={getSourceImg(e.source)} alt={e.source} />,
      type: e.dataType,
    };
  });

  const entityDetailsOptions = useMemo(() => {
    if (isHubspot) {
      let uniqueFields = entityDetails?.fields
        ?.filter((f) => f.unique)
        .map((e) => ({ label: e.label, value: e.name, type: e.type }));
      uniqueFields = uniqueFields || [];
      if (object === 'contacts') {
        return [
          {
            label: 'Email',
            value: 'email',
            type: 'string',
          },
          ...uniqueFields,
        ];
      } else if (object === 'companies') {
        return [
          {
            label: 'Domain',
            value: 'domain',
            type: 'string',
          },
          ...uniqueFields,
        ];
      } else if (object === 'deals') {
        return [
          {
            label: 'Email',
            value: 'email',
            type: 'string',
          },
          ...uniqueFields,
        ];
      }
    }
    return entityDetails?.fields?.map((f) => ({ label: f.label, value: f.name, type: f.type }));
  }, [entityDetails, isHubspot, object]);

  useEffect(() => {
    const formSchema = destination.integration?.formMetadata || {};
    const children = formSchema[object]?.children;
    let defaultMappingsPath = [object];
    if (subObject) {
      defaultMappingsPath.push(subObject);
    }
    setSubObjectFields(
      children?.map((child) => {
        return {
          label: formSchema[object][child]?.label || child,
          value: child,
        };
      })
    );
    let defaultMappingsArray = [];
    if (
      destination.integration?.type !== IntegrationType.HUBSPOT &&
      destination.integration?.supportsFields &&
      !isEmpty(entityDetails?.fields)
    ) {
      defaultMappingsArray = entityDetails.fields
        .filter((field) => field.unique)
        .map((field, index) => {
          return {
            source: didMount.current ? '' : uniqueKeys[index]?.source || '',
            destination: didMount.current
              ? field.name
              : uniqueKeys[index]?.destination || field.name,
            unique: true,
            sourceDtype: didMount.current ? '' : uniqueKeys[index]?.sourceDtype || '',
            destinationDtype: didMount.current
              ? field.type
              : uniqueKeys[index].destinationDtype || field.type,
            destinationLabel: field?.label || field.name || '',
          };
        });
    } else {
      const defaultMappings = get(formSchema, [...defaultMappingsPath, 'defaultMappings'], {});
      defaultMappingsArray = Object.keys(defaultMappings).map((field, index) => {
        return {
          source: didMount.current ? '' : uniqueKeys[index]?.source || '',
          destination: didMount.current
            ? defaultMappings[field]?.name
            : uniqueKeys[index]?.destination || defaultMappings[field]?.name,
          unique: true,
          sourceDtype: didMount.current ? '' : uniqueKeys[index]?.sourceDtype || '',
          destinationDtype: didMount.current
            ? defaultMappings[field].type
            : uniqueKeys[index].destinationDtype || defaultMappings[field].type,
          destinationLabel: defaultMappings[field]?.label || defaultMappings[field]?.name || '',
        };
      });
    }
    if (
      defaultMappingsArray.length === 0 &&
      destination.integration.requiresUniqueId &&
      !children
    ) {
      defaultMappingsArray.push({
        source: didMount.current ? '' : uniqueKeys[0]?.source || '',
        destination: didMount.current ? '' : uniqueKeys[0]?.destination || '',
        unique: false,
        sourceDtype: didMount.current ? '' : uniqueKeys[0]?.sourceDtype || '',
        destinationDtype: didMount.current ? '' : uniqueKeys[0]?.destinationDtype || '',
      });
    }
    const filteredFormFields = keyBy(
      get(formSchema, [...defaultMappingsPath, 'formElements'], {}),
      'name'
    );
    setUniqueKeys(defaultMappingsArray);
    setFormFields(filteredFormFields);
    const newExtraFormdata = {};
    for (let formField in filteredFormFields) {
      newExtraFormdata[formField] = didMount.current ? '' : extraFormData[formField] || '';
      if (filteredFormFields[formField].children) {
        const childrenFields = keyBy(filteredFormFields[formField].children || {}, 'name');
        for (let childFormField in childrenFields) {
          newExtraFormdata[childFormField] = didMount.current
            ? ''
            : extraFormData[childFormField] || '';
        }
      }
    }
    setExtraFormData(newExtraFormdata);
    if (!didMount.current) {
      didMount.current = true;
    }
  }, [object, subObject, entityDetails]);
  const formInputs = [];
  Object.keys(formFields).forEach((formField) => {
    const { label, source, children } = formFields[formField];
    formInputs.push(
      <div key={formField}>
        <label>{label}</label>
        <div className="mt-2.5">
          <FormComponentBasedOnType
            value={extraFormData[formField]}
            onChange={(value) => {
              const newExtraFormdata = {
                ...extraFormData,
                [formField]: value,
              };
              setExtraFormData(newExtraFormdata);
            }}
            {...formFields[formField]}
            entity={object}
            integrationId={destinationId}
            sourceUrl={generateDropDownSourceUrl(destinationId, source, extraFormData)}
          />
        </div>
      </div>
    );

    if (children && extraFormData[formField]) {
      const reKeyedChildren = keyBy(children, 'name');
      Object.keys(reKeyedChildren).forEach((childFormField) => {
        const { label: formLabel, source: formSource } = reKeyedChildren[childFormField];
        formInputs.push(
          <div className="mt-5" key={childFormField}>
            <label>{formLabel}</label>
            <div className="mt-2.5">
              <FormComponentBasedOnType
                value={extraFormData[childFormField]}
                onChange={(value) => {
                  const newExtraFormdata = {
                    ...extraFormData,
                    [childFormField]: value,
                  };
                  setExtraFormData(newExtraFormdata);
                }}
                {...reKeyedChildren[childFormField]}
                entity={object}
                integrationId={destinationId}
                sourceUrl={generateDropDownSourceUrl(destinationId, formSource, extraFormData)}
              />
            </div>
          </div>
        );
      });
    }
  });

  return (
    <div>
      <div>
        {destination?.integration?.name
          ? `Where in ${destination?.integration?.name}`
          : 'Choose an object'}
      </div>
      {!connectedIntegrationEntitiesIsLoading ? (
        <div className="mt-2.5 w-60">
          <VirtualizedSelect
            onChange={(value: string) => {
              setObject(value);
              setSubObject(null);
            }}
            options={
              connectedIntegrationEntities?.map((e) => ({ label: e.label, value: e.name })) || []
            }
            value={object}
            virtualize
            ph="Choose an object"
          />
        </div>
      ) : (
        <div className="h-10 mt-2.5 bg-tw-gray-eb animate-pulse w-80"></div>
      )}
      {object && subObjectFields && (
        <div className="mt-2.5 w-60">
          <VirtualizedSelect
            onChange={(value: string) => {
              setSubObject(value);
            }}
            options={(subObjectFields as any) || []}
            value={subObject}
            virtualize
            ph="Select a sub object"
          />
        </div>
      )}
      {formInputs && <div className="mt-5">{formInputs}</div>}
      {object && (subObjectFields ? subObject : true) && uniqueKeys.length > 0 && (
        <div className="grid grid-cols-12 mt-5">
          <div className="col-span-5">Map a Toplyne user identifier (e.g., user_id)...</div>
          <span></span>
          <div className="col-span-5">
            {`...to an identifier on
            ${capitalizeFirstLetter(
              connectedIntegrations?.find((d) => d.id === destinationId).integration?.name
            )} (e.g., lead_id)`}
          </div>
          <span></span>
        </div>
      )}
      {object &&
        (subObjectFields ? subObject : true) &&
        uniqueKeys.map((field: any, index) => {
          return (
            <div className="grid grid-cols-12 mt-4" key={index}>
              <div className="col-span-5">
                {!entityDetailsLoading ? (
                  <VirtualizedSelect
                    onChange={(value: string) => {
                      const newUniqueKeys: any = [...uniqueKeys];
                      newUniqueKeys[index] = {
                        source: value,
                        destination: field?.destination,
                        unique: field?.unique,
                        sourceDtype: find_dtype(value, sourceItems),
                        destinationDtype: field?.destinationDtype,
                        destinationLabel: field?.destinationLabel ?? '',
                      };
                      setUniqueKeys(newUniqueKeys);
                    }}
                    options={sourceItems}
                    value={field.source}
                    virtualize
                    ph="Select Toplyne field"
                    error={errors[index]}
                  />
                ) : (
                  <div className="h-10 bg-tw-gray-eb animate-pulse w-80"></div>
                )}
              </div>
              <span></span>
              <div className="col-span-5">
                {!entityDetailsLoading ? (
                  entityDetailsOptions && !field.unique ? (
                    <VirtualizedSelect
                      onChange={(value: string) => {
                        const newUniqueKeys: any = [...uniqueKeys];
                        newUniqueKeys[index] = {
                          source: field?.source,
                          destination: value,
                          unique: field?.unique,
                          sourceDtype: field?.sourceDtype,
                          destinationDtype: find_dtype(value, entityDetailsOptions),
                          destinationLabel: field?.destinationLabel ?? '',
                        };
                        setUniqueKeys(newUniqueKeys);
                      }}
                      options={entityDetailsOptions}
                      value={field.destination}
                      virtualize
                      ph={`Select ${
                        capitalizeFirstLetter(
                          connectedIntegrations?.find((d) => d.id === destinationId).integration
                            ?.name
                        ) || 'destination'
                      } field`}
                      error={errors[index]}
                      stuckField={
                        (isPipedrive || isHubspot) && (
                          <>
                            {isHubspot && object !== 'contacts' && (
                              <AddNewPropertyStuckField
                                onclick={() => {
                                  setMakeUniqueProperty(true);
                                  setShowNewDestinationModal(true);
                                }}
                                integrationType={destination?.integration.type}
                              />
                            )}
                          </>
                        )
                      }
                    />
                  ) : (
                    <div className="flex">
                      <TextInput
                        className="w-full"
                        disabled={!isEmpty(field.destination)}
                        value={field.destinationLabel || field.destination}
                        isError={errors[index]?.isError}
                        error={errors[index]}
                        onChange={(event) => {
                          const newUniqueKeys: any = [...uniqueKeys];
                          newUniqueKeys[index] = {
                            source: field?.source,
                            destination: event.target.value,
                            unique: field?.unique,
                            sourceDtype: field?.sourceDtype,
                            destinationDtype: 'string',
                            destinationLabel: event.target.value || field?.destinationLabel || '',
                          };
                          setUniqueKeys(newUniqueKeys);
                        }}
                      />
                      <span className="mt-2 ml-2 text-xs text-tw-black-7">(Required)</span>
                    </div>
                  )
                ) : (
                  <div className="h-10 bg-tw-gray-eb animate-pulse w-80"></div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
}
