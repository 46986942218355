import useDsEnabled from 'hooks/useDsEnabled';
import { FiltersTabs, useFilterTabs } from 'pages/pql-workflow/hooks/useFilterTabs';
import useMeta from 'pages/pql-workflow/hooks/useMeta';
import { useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { FiltersPQLSections, useFiltersPQLStore } from 'pages/pql-workflow/store/PQLFiltersStore';
import { PQLWorkflowSteps, usePQLWorkflowStore } from 'pages/pql-workflow/store/PQLWorkflowStore';
import { memo, useRef } from 'react';
import { useVirtual } from 'react-virtual';
import { BannerBulb, FilterFunnelIcon } from 'ui-components/data-display/Icons';
import { PrimaryButton, SecondaryButton } from 'ui-components/inputs/Buttons';
import { Search } from 'ui-components/inputs/Search/Search';
import VirtualItem from 'ui-components/util/VirtualItem';
import VirtualItemMeasurer from 'ui-components/util/VirtualItemMeasurer';
import { classNames, numberWithPluralWord } from 'utils/common';
import { QueryType } from '../../../../stores/QueryStore';
import { MODELID_LABEL_FOR_ACCOUNTS, MODELID_LABEL_FOR_USERS } from '../../../../utils/constants';
import AccordionHeader from '../AccordionHeader';
import SectionAccordion from '../SectionAccordion';
import SideTab from '../SideTab';
import FilterRow from './FilterRow';

const MemoItem = memo(({ virtualItem, filtersToShow }: any) => {
  MemoItem.displayName = 'MemoItem';
  return (
    <VirtualItem index={virtualItem.index}>
      <div className="py-2">
        <FilterRow key={virtualItem.key} filter={filtersToShow[virtualItem.index]} />
      </div>
    </VirtualItem>
  );
});

export default function Filters() {
  // query stores
  const {
    filtersOpen,
    setFiltersOpen,
    searchQuery,
    setSearchQuery,
    filters,
    sectionsMap,
    setSectionsMap,
    currentSection,
    setCurrentSection,
  } = useFiltersPQLStore();
  const { modelId, targetEntity } = useDefinePQLStore();
  const { setCompletedSteps, completedSteps } = usePQLWorkflowStore();

  // query APIs
  const { data, isLoading, isError } = useMeta();

  // custom hooks
  const dsEnabled = useDsEnabled(modelId, targetEntity);
  const { activeTab, setActiveTab, filtersToShow, filterCount, loadingMeta, filterTabs } =
    useFilterTabs();
  const parentRef = useRef(null);

  const rowVirtualizer = useVirtual({
    size: filtersToShow?.length || 0,
    parentRef,
  });

  const handleFiltersComplete = () => {
    setCurrentSection(FiltersPQLSections.CAPACITY);
    setSectionsMap({
      ...sectionsMap,
      [FiltersPQLSections.FILTERS]: true,
    });
  };

  const getFilterLabels = (tab) => {
    if (dsEnabled) return tab;
    return tab === FiltersTabs.ALL ? 'Both' : tab;
  };

  return (
    <SectionAccordion
      id={FiltersPQLSections.FILTERS}
      accordionHeader={
        <AccordionHeader
          onClick={() => {
            // if not on current section, mark it as current,
            // then mark all other next steps to not finished
            let newSectionsMap = {};
            let refIndex = Object.keys(sectionsMap).indexOf(FiltersPQLSections.FILTERS);
            (Object.keys(FiltersPQLSections) as Array<keyof typeof FiltersPQLSections>).forEach(
              (key, index) => {
                if (index >= refIndex) {
                  newSectionsMap[key.toLowerCase()] = false;
                }
              }
            );
            setSectionsMap({
              ...sectionsMap,
              ...newSectionsMap,
            });
          }}
        >
          {filters.length > 0 ? numberWithPluralWord(filters.length, 'filter') : `No filters`}{' '}
          applied
        </AccordionHeader>
      }
      expanded={currentSection === FiltersPQLSections.FILTERS}
      hasPadding={false}
    >
      <div>
        <div className="pt-6 bg-white">
          <div
            className="pb-4 border rounded-lg border-tw-gray-eb"
            onClick={() => {
              if (!filtersOpen) {
                setFiltersOpen(!filtersOpen);
              }
            }}
          >
            <div
              className={classNames(
                'flex items-center justify-between px-4 pt-4',
                filtersOpen ? '' : 'cursor-pointer'
              )}
              onClick={() => {
                if (!filtersOpen) {
                  setFiltersOpen(!filtersOpen);
                }
              }}
            >
              {!filtersOpen && (
                <div>
                  <span className="text-tw-black-3">Show more</span>
                  <span className="flex items-center mt-2 text-xs text-tw-black-9 gap-x-1">
                    <FilterFunnelIcon className="fill-current text-tw-black-7" />
                    {/* All possible filters */}
                    Segment with{' '}
                    {
                      data?.sourceMeta.data.filter((d) => {
                        if (searchQuery) {
                          return d.DISPLAY_NAME.toLowerCase().includes(searchQuery.toLowerCase());
                        }
                        return true;
                      }).length
                    }{' '}
                    other attributes
                  </span>
                </div>
              )}
              {filtersOpen && (
                <Search
                  placeholder="Search"
                  value={searchQuery}
                  onChangeFunction={(e) => {
                    // when there is a non-empty search query,
                    // switch active tab to ALL
                    if (e.target.value) {
                      setActiveTab(FiltersTabs.ALL);
                    }
                    setSearchQuery(e.target.value);
                  }}
                  onClearFunction={() => setSearchQuery('')}
                  hasShadow={false}
                />
              )}
            </div>
            {filtersOpen && (
              <div
                className={classNames(
                  'grid w-full mt-8',
                  dsEnabled ? 'grid-cols-5' : 'grid-cols-7'
                )}
              >
                {loadingMeta ? (
                  <div className="flex flex-col animate-pulse">
                    {Array(3)
                      .fill(0)
                      .map((_, id) => (
                        <div key={id} className="h-6 mx-2 mb-2 bg-tw-gray-eb"></div>
                      ))}
                  </div>
                ) : (
                  <div
                    className={classNames('flex flex-col', dsEnabled ? 'col-span-1' : 'col-span-2')}
                  >
                    {/** Tabs */}
                    {filterTabs.map((tab) => (
                      <SideTab
                        isActive={activeTab === tab}
                        label={`${getFilterLabels(tab)} (${filterCount[tab]})`}
                        key={tab}
                        onClick={() => setActiveTab(tab)}
                      />
                    ))}
                    <hr className="w-2/5 my-2 mb-4 ml-2" />
                    <SideTab
                      isActive={activeTab === FiltersTabs.APPLIED_FILTERS}
                      label={`Applied Filters (${filterCount[FiltersTabs.APPLIED_FILTERS]})`}
                      onClick={() => setActiveTab(FiltersTabs.APPLIED_FILTERS)}
                    />
                  </div>
                )}
                <div className={classNames('mx-4', dsEnabled ? 'col-span-4' : 'col-span-5')}>
                  {/** filters table */}
                  {/* <div className="grid grid-cols-7 px-5 py-4 mb-4 text-tw-black-7 bg-tw-gray-f9">
                    <span className="col-span-3">ATTRIBUTE</span>
                    <span className="col-span-2">{dsEnabled && `SIGNIFICANCE`}</span>
                  </div> */}
                  {loadingMeta ? (
                    <div className="animate-pulse">
                      {Array(2)
                        .fill(0)
                        .map((_, id) => (
                          <div key={id} className="h-16 mx-2 mb-4 rounded bg-tw-gray-eb"></div>
                        ))}
                    </div>
                  ) : (
                    <div className="grid gap-y-4 min-h-[420px] 2xl:h-[450px] overflow-scroll">
                      {filtersToShow?.length === 0 ? (
                        <div className="px-6 pb-4 text-tw-black-7 h-[300px]">
                          {activeTab === FiltersTabs.APPLIED_FILTERS
                            ? 'Applied filters will show up here'
                            : 'No filters to show'}
                        </div>
                      ) : (
                        <div>
                          {(activeTab === FiltersTabs.STRONG ||
                            activeTab === FiltersTabs.VERY_STRONG) && (
                            <div className="flex items-start w-full px-4 py-3 rounded-lg bg-tw-orange-ff">
                              <BannerBulb className="w-10 h-10 -mt-1" />
                              <p className="ml-2 text-tw-black-5">
                                Our data science models automatically identify attributes that are{' '}
                                {activeTab?.toLowerCase() || 'strong'} indicators in predicting
                                likelihood of{' '}
                                {targetEntity === QueryType.users
                                  ? MODELID_LABEL_FOR_USERS[modelId]?.toLowerCase()
                                  : MODELID_LABEL_FOR_ACCOUNTS[modelId]?.toLowerCase()}
                              </p>
                            </div>
                          )}
                          {dsEnabled && (
                            <div className="grid items-end w-full grid-cols-3 px-4 py-3 mt-4 rounded-md bg-tw-gray-f9 text-tw-black-7">
                              <div>ATTRIBUTE</div>
                              <div className="text-center">SIGNAL STRENGTH</div>
                              <div className="text-center">ACTION</div>
                            </div>
                          )}
                          <div
                            className="h-[350px] 2xl:h-[420px]"
                            ref={parentRef}
                            style={{
                              width: `100%`,
                              overflow: 'auto',
                            }}
                          >
                            <div
                              style={{
                                height: `${rowVirtualizer.totalSize}px`,
                                width: '100%',
                                position: 'relative',
                              }}
                            >
                              {rowVirtualizer.virtualItems.map((virtualItem) => {
                                return (
                                  <VirtualItemMeasurer
                                    tagName="div"
                                    data-key={virtualItem.key}
                                    key={virtualItem.key}
                                    measure={virtualItem.measureRef}
                                    style={{
                                      position: 'absolute',
                                      top: 0,
                                      left: 0,
                                      width: '100%',
                                      transform: `translateY(${virtualItem.start}px)`,
                                    }}
                                  >
                                    <MemoItem
                                      virtualItem={virtualItem}
                                      filtersToShow={filtersToShow}
                                    />
                                  </VirtualItemMeasurer>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="pb-6 mt-6">
          {!filters.length && (
            <SecondaryButton
              className="w-full py-2 font-semibold text-tw-blue-0d product-tour-10"
              onClick={() => {
                handleFiltersComplete();
              }}
            >
              Proceed without filters
            </SecondaryButton>
          )}
          {!!filters.length && (
            <PrimaryButton
              className="w-full py-2"
              onClick={() => {
                handleFiltersComplete();
                // mark filters as complete
                setCompletedSteps({
                  ...completedSteps,
                  [PQLWorkflowSteps.FILTERS]: true,
                });
              }}
              disabled={isLoading || isError}
            >
              Proceed
            </PrimaryButton>
          )}
        </div>
      </div>
    </SectionAccordion>
  );
}
