import { ColumnType } from 'pages/users-and-accounts/store/AccountsStore';
import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { SortSyncedUsersBy } from '../hooks/useGetSyncedUsers';

type SyncedUserState = {
  searchQuery: string;
  page: number;
  sortBy: Array<SortSyncedUsersBy>;
  field_mapping: Record<string, ColumnType>;
};

type SyncedUserStore = {
  setSearchQuery: (searchQuery: string) => void;
  setPage: (page: number) => void;
  setSortBy: (sortBy: Array<SortSyncedUsersBy>) => void;
  initFieldMappings: (initialMappings: Record<string, ColumnType>) => void;
  addFieldMapping: (obj: ColumnType) => void;
  removeFieldMapping: (keyName: string) => void;
} & SyncedUserState;

const initialState: SyncedUserState = {
  searchQuery: '',
  page: 1,
  sortBy: [],
  field_mapping: {},
};

export const useSyncedUsersStore = create<SyncedUserStore>(
  devtools((set, get) => {
    return {
      ...initialState,
      setSearchQuery: (searchQuery: string) => {
        set({
          searchQuery,
        });
      },
      setPage: (page: number) => {
        set({
          page,
        });
      },
      setSortBy: (sortBy: Array<SortSyncedUsersBy>) => {
        set({
          sortBy,
          // reset page back to 1
          page: 1,
        });
      },
      initFieldMappings: (initialMappings: Record<string, ColumnType>) => {
        set({
          field_mapping: initialMappings,
        });
      },
      addFieldMapping: (obj: ColumnType) =>
        set(() => ({
          field_mapping: {
            ...get().field_mapping,
            [obj.keyName]: obj,
          },
        })),
      removeFieldMapping: (keyName: string) => {
        let newState = get().field_mapping;
        delete newState[keyName];
        set(() => ({ field_mapping: { ...newState } }));
      },
    };
  })
);
