import { AxiosError } from 'api-client';
import { GoogleLogo } from 'ui-components/data-display/Icons';
import AlternateTopNav from 'ui-components/business/navbars/AlternateTopNav';
import { PrimaryButton } from 'ui-components/inputs/Buttons';
import { PasswordInput, TextInput } from 'ui-components/inputs/Inputs';
import useOAuth from 'hooks/use-oauth';
import { useAuth } from 'hooks/useAuth';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import ErrorFromHook from '../../ui-components/feedback/ErrorFromHook';
import { emailRegExp } from '../../utils/common';
import LoginRightBanner from '../../ui-components/business/LoginRightBanner';

type LoginFormState = {
  email: string;
  password: string;
};

function getLoginErrorMessage(error: AxiosError) {
  const response = error?.response;

  switch (response?.status) {
    case 400:
      if (response?.data?.code === 'invalid') {
        return 'Your email id or password is incorrect';
      } else {
        return 'Something went wrong';
      }
    case 403:
      return (
        'Your account is locked due to too many unsuccesful attempts. ' +
        'Please attempt to login after some time or contact support.'
      );
    default:
      return 'Something went wrong';
  }
}

export default function Login() {
  const { login, googleAuth } = useAuth(false);
  const router = useHistory();

  const onSubmit: SubmitHandler<LoginFormState> = (data) => login.mutate(data);
  const errMsg = getLoginErrorMessage(login?.error);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    const params = new URLSearchParams(router.location.search.replace('+', '%2b'));
    if (params.has('email')) {
      const email = params.get('email');
      setValue('email', email, { shouldValidate: !!email });
    }
  }, []);

  const oauthCallback = useCallback(
    (err, code) => {
      googleAuth.login(code);
    },
    [googleAuth]
  );

  const authorize = useOAuth(
    'google',
    process.env.REACT_APP_GOOGLE_CLIENT_ID,
    'https://accounts.google.com/o/oauth2/v2/auth',
    'email',
    'login',
    oauthCallback
  );

  return (
    <div className="grid grid-cols-5" data-testid="login-page">
      <div className="col-span-3 py-8">
        <AlternateTopNav className={'ml-8'} />
        <div className="flex flex-col items-center">
          <h2 className="mt-28 text-3.5xl font-medium">Welcome back 👋</h2>
          <div className="w-1/2">
            <button
              data-testid="google-login-button"
              className="flex items-center justify-center w-full p-4 mt-12 rounded gap-x-2 border-tw-blue-0d border-1"
              onClick={() => authorize()}
            >
              <span>
                <GoogleLogo />
              </span>
              <span className="font-bold uppercase text-tw-blue-0d">
                {googleAuth.isLoading ? `Logging in` : `Continue with Google`}
              </span>
            </button>
            {googleAuth.isError && (
              <div className="mt-6">
                <ErrorFromHook level="error">Something went wrong, please try again.</ErrorFromHook>
              </div>
            )}
            <form className="mt-12" onSubmit={handleSubmit(onSubmit)}>
              {login.isError && (
                <div data-testid="error-message">
                  <ErrorFromHook>{errMsg}</ErrorFromHook>
                </div>
              )}
              <label className="mb-3 text-tw-black-5">Work Email</label>
              <TextInput
                data-testid="email-input"
                className="w-full py-3"
                {...register('email', {
                  required: true,
                  pattern: emailRegExp,
                })}
                isError={errors.email}
              />
              <label className="mt-5 mb-3 text-tw-black-5">Enter Password</label>
              <PasswordInput
                data-testid="password-input"
                className="w-full py-3"
                {...register('password', { required: true })}
                isError={errors.password}
              />
              <div className="flex justify-end mt-4 font-medium">
                <p
                  className="cursor-pointer text-tw-blue-0d"
                  onClick={() => {
                    router.push('/password/reset');
                  }}
                  data-testid="forgot-password"
                >
                  Forgot Password?
                </p>
              </div>
              <PrimaryButton
                data-testid="login-button"
                type="submit"
                className="w-full py-3 mt-6"
                isLoading={login.isLoading}
                disabled={login.isLoading}
              >
                Login
              </PrimaryButton>
              <div className="mt-4 font-medium text-center">
                <span className="text-tw-black-3">{`Don't`} have an account?</span>
                <Link to="/signup">
                  <span className="ml-1 text-tw-blue-0d">Sign up</span>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-span-2" data-testid="login-banner">
        <LoginRightBanner />
      </div>
    </div>
  );
}
