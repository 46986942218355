import { useQuery, useQueryClient } from 'react-query';
import { ConnectedIntegrationStatus, useClient } from '../../../api-client';

export default function useConnectedIntegrationsWithInterval(
  enabled: boolean = true,
  refetchInterval: number = 0,
  schemaName?: string
) {
  const client = useClient();
  const queryClient = useQueryClient();
  return useQuery(
    ['integrations', 'connected', 'interval', schemaName],
    () => client.connectedIntegrations(schemaName),
    {
      enabled: enabled,
      refetchInterval: refetchInterval,
      onSuccess: (resp) => {
        const connectedIntegration = resp?.filter(
          (integration) =>
            integration.integration.supportsRead && !integration.integration.supportsWrite
        );

        if (connectedIntegration.length) {
          const lastConnectedIntegration = connectedIntegration.sort((a, b) =>
            a.id > b.id ? 1 : -1
          )[connectedIntegration.length - 1];
          if (
            lastConnectedIntegration.status === ConnectedIntegrationStatus.READY ||
            lastConnectedIntegration.status === ConnectedIntegrationStatus.ERROR
          ) {
            queryClient.refetchQueries('user');
            queryClient.refetchQueries(['integrations', 'connected', schemaName]);
          }
        }
      },
    }
  );
}
