import { AxiosError } from 'api-client';
import { useAuthStore } from 'hooks/useAuthStore';
import useGoogleAuth from 'pages/login/hooks/useGoogleAuth';
import useLogin from 'pages/login/hooks/useLogin';
import useSignUpByInvite from 'pages/settings/hooks/useSignUpByInvite';
import { useCallback, useMemo } from 'react';
import { getHubspotCookie } from 'utils/helpers';
import { useAnalytics } from '../telemetry';
import useLogout from './auth/useLogout';
import useAuthUser from './useAuthUser';

type LoginData = {
  email: string;
  password: string;
};

export type SignUpByInviteData = {
  token: string;
  password: string;
};

export function useAuth(requireUserDetails = true) {
  const { isLoading, isError } = useAuthUser(requireUserDetails);
  const { user, isLoggedIn, setAccountId } = useAuthStore();
  const loginMutation = useLogin();
  const signUpByInviteMutation = useSignUpByInvite();
  const logoutMutation = useLogout();
  const googleAuthMutation = useGoogleAuth();
  const { track, identify } = useAnalytics();

  const login = useCallback(
    (data: LoginData) => {
      loginMutation.mutate({
        email: data.email,
        password: data.password,
      });
    },
    [loginMutation]
  );

  const logout = useCallback(() => {
    logoutMutation.mutate();
  }, [logoutMutation]);

  const changeOrg = useCallback(
    (accountId: string, schemaName: string) => {
      setAccountId(accountId, schemaName);
      identify(user.email, {
        Account: schemaName,
      });
      // TODO: use history.push
      window.location.href = `/`;
    },
    [setAccountId, user?.email, identify]
  );

  const signUpByEmailInvite = useCallback(
    (data: SignUpByInviteData) => {
      signUpByInviteMutation.mutate(data, {
        onSuccess: (resp, d) => {
          login({ email: resp.email, password: d.password });
        },
      });
    },
    [signUpByInviteMutation, track, login]
  );

  const googleLogin = useCallback(
    (code: string, invitationToken?: string) => {
      googleAuthMutation.mutate({
        code,
        invitationToken,
        hubspotCookie: getHubspotCookie(),
      });
    },
    [googleAuthMutation]
  );

  return useMemo(
    () => ({
      user,
      isLoggedIn,
      isError,
      isLoading,
      login: {
        mutate: login,
        isLoading: loginMutation.isLoading,
        isError: loginMutation.isError,
        error: loginMutation.error as AxiosError,
      },
      logout,
      changeOrg,
      signUpByEmailInvite,
      googleAuth: {
        login: googleLogin,
        isLoading: googleAuthMutation.isLoading,
        isError: googleAuthMutation.isError,
      },
      shouldFireQueries: !(
        user?.currentOrganization?.isSandbox && !user?.currentOrganization?.isActive
      ),
    }),
    [
      user,
      isLoggedIn,
      isError,
      isLoading,
      login,
      loginMutation.isLoading,
      loginMutation.isError,
      loginMutation.error,
      logout,
      changeOrg,
      signUpByEmailInvite,
      googleLogin,
      googleAuthMutation.isLoading,
      googleAuthMutation.isError,
    ]
  );
}
