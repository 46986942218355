import useCohorts from '../pages/pql-workflow/hooks/useCohorts';

export default function useAllCohorts() {
  const { data: cohorts, isLoading } = useCohorts();

  return {
    isLoading,
    cohortItems: cohorts,
    allUsersCohortItem: cohorts?.find((c) => c.name === 'All Users'),
  };
}
