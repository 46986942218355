import { TokenResponse, useApiClient } from '../../../api-client';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosInstance } from 'axios';
import { toast as toastify } from 'react-toastify';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';

async function deleteToken(client: AxiosInstance, id: number) {
  const { data } = await client.delete<TokenResponse>(`/api-token/${id}`);

  return data;
}

export default function useDeleteAPIToken() {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation((id: number) => deleteToken(client, id), {
    onSuccess: async (data, id) => {
      toastify(<SuccessToast description="Token has been deleted" />, {
        type: 'success',
      });
      queryClient.setQueryData('useMemberApiDetails', (apiDetails: TokenResponse[]) => {
        return apiDetails.filter((apiDetail) => apiDetail.id !== id);
      });
    },
    onError: () => {
      toastify(<ErrorToast description="There was an error deleting the token" />, {
        type: 'error',
      });
    },
    onSettled: async () => {
      await queryClient.refetchQueries('useMemberApiDetails');
    },
  });
}
