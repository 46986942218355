import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { classNames } from 'utils/common';
import { ReactNode } from 'react';

type SectionAccordionProps = {
  children: ReactNode;
  accordionHeader: ReactNode;
  expanded: boolean;
  id: string;
  enabled?: boolean;
  hasPadding: boolean;
  headerPadding?: boolean;
};

export default function SectionAccordion(props: SectionAccordionProps) {
  const { children, accordionHeader, expanded, id, enabled } = props;

  if (enabled === false) {
    return <div className="pl-6 cursor-not-allowed">{accordionHeader}</div>;
  }

  return (
    <AccordionPrimitive.Item
      value={id}
      className={classNames('bg-tw-white-ff rounded-b-xl', !props.headerPadding && 'px-6')}
    >
      <AccordionPrimitive.Header className="w-full">
        {!expanded ? (
          <AccordionPrimitive.Trigger className="w-full text-left bg-tw-white-ff">
            {accordionHeader}
          </AccordionPrimitive.Trigger>
        ) : null}
      </AccordionPrimitive.Header>
      <AccordionPrimitive.Content className={classNames(props.hasPadding && 'py-6')}>
        {children}
      </AccordionPrimitive.Content>
    </AccordionPrimitive.Item>
  );
}
