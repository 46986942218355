import { useMutation, useQueryClient } from 'react-query';
import { EditIntegrationRequest, useClient } from '../../../api-client';
import { useAnalytics } from '../../../telemetry';
import { AnalyticsConst, AnalyticsEvents } from '../../../telemetry/constants';

export default function useUpdateIntegration(id: number | string) {
  const queryClient = useQueryClient();
  const client = useClient();
  const { track } = useAnalytics();
  return useMutation((data: EditIntegrationRequest) => client.updateIntegration(id, data), {
    onSuccess: () => {
      queryClient.refetchQueries(['integrations', 'connected']);
    },
    onError: (error, variables) => {
      track(AnalyticsEvents.CONNECTED_INTEGRATION_UPDATED_FAILED, {
        [AnalyticsConst.INTEGRATION_NAME]: variables.name,
      });
    },
  });
}
