import { FC, useEffect } from 'react';
import { PopoverContentProps } from './PopoverContent';

export interface BetweenFilterPopoverProps extends PopoverContentProps {}

export const BetweenFilterPopover: FC<BetweenFilterPopoverProps> = (props) => {
  const {
    startComparatorValue,
    setStartComparatorValue,
    endComparatorValue,
    setEndComparatorValue,
    setButtonDisabled,
  } = props;

  useEffect(() => {
    if (startComparatorValue && endComparatorValue) setButtonDisabled(false);
  }, [startComparatorValue, endComparatorValue]);
  return (
    <div className="flex justify-between mt-2 space-x-2">
      <input
        className="relative flex items-center justify-between w-1/2 px-3 py-3 text-xs rounded-lg focus:outline-none border-1 border-tw-gray-ed"
        type="text"
        value={startComparatorValue}
        name="startValueInput"
        autoComplete="off"
        placeholder="Start value"
        onChange={(e) => {
          setStartComparatorValue(e.target.value as any);
        }}
      />
      <input
        className="relative flex items-center justify-between w-1/2 px-3 py-3 text-xs rounded-lg focus:outline-none border-1 border-tw-gray-ed"
        type="text"
        value={endComparatorValue}
        name="endValueInput"
        autoComplete="off"
        placeholder="End value"
        onChange={(e) => {
          setEndComparatorValue(e.target.value as any);
        }}
      />
    </div>
  );
};

export interface OtherThanBetweenFilterPopoverProps extends PopoverContentProps {}

export const OtherThanBetweenFilterPopover: FC<OtherThanBetweenFilterPopoverProps> = (props) => {
  const { comparatorValue, setComparatorValue, setValue, setButtonDisabled } = props;
  return (
    <input
      className="relative flex items-center justify-between w-full px-3 py-3 text-xs rounded-lg focus:outline-none border-1 border-tw-gray-ed"
      type=""
      value={comparatorValue}
      name="valueInput"
      placeholder="Enter a number"
      autoComplete="off"
      onChange={(e) => {
        if (/^\d*\.?\d*$/g.test(e.target.value)) {
          setComparatorValue(e.target.value as any);
          setValue(e.target.value as any);
          setButtonDisabled(false);
        }
        if (!e.target.value) {
          setButtonDisabled(true);
        }
      }}
    />
  );
};
