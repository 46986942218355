import sortBy from 'lodash/sortBy';
import { dataTypes } from 'pages/opportunities/barChartOp';

export interface dataObj {
  bin: string;
  conversionRates: number;
  convertedCounts: number;
  userCounts: number;
}

export const converToBarFormat = (
  binsArray: string[],
  conversionRatesArray: number[],
  convertedCountsArray: number[],
  userCountsArray: number[]
) => {
  let final: dataObj[] = [];
  for (let i = 0; i < binsArray.length; i++) {
    const obj: dataObj = {
      bin: '',
      conversionRates: 0,
      convertedCounts: 0,
      userCounts: 0,
    };
    obj.bin =
      typeof binsArray[i] === 'string'
        ? binsArray[i].replace('[', '').replace(']', '').replace(', inf', '+').replace(', ', '-')
        : `${binsArray[i]}`;
    obj.conversionRates = Number((conversionRatesArray[i] * 100)?.toFixed(2));
    obj.convertedCounts = Number(convertedCountsArray[i]?.toFixed(2));
    obj.userCounts = Number(userCountsArray[i]?.toFixed(2));
    final = [...final, obj];
  }
  return final;
};

export const getBarChartData = (data: any, duration: string, dataType: string) => {
  for (const key in data) {
    if (key === duration) {
      return dataType === dataTypes.recommended
        ? converToBarFormat(
            data[key].bins,
            data[key].conversionRates,
            data[key].convertedCounts,
            data[key].userCounts
          )
        : sortBy(
            converToBarFormat(
              data[key].bins,
              data[key].conversionRates,
              data[key].convertedCounts,
              data[key].userCounts
            ).sort(function (a, b) {
              // @ts-ignore
              return b[dataType] - a[dataType];
            })
          );
    }
  }
};
