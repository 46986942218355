export enum AnalyticsConst {
  // Keep the values same on backend and frontend when adding any new key

  // Backend Keys
  NAME = 'name',
  CREATED_BY = 'created_by',
  ACCOUNT_NAME = 'account_name',
  ACCOUNT_TYPE = 'account_type',
  ACCOUNT_TIMEZONE = 'account_timezone',
  ENVIRONMENT = 'environment',
  CREATED_AT = 'created_at',
  EXPIRES_ON = 'expires_on',
  INVITED_USER = 'invited_user',
  USER_EMAIL = 'user_email',
  ACCEPTED_ON = 'accepted_on',
  USER_NAME = 'user_name',
  USER_ID = 'user_id',
  INVITED_BY = 'invited_by',
  EXPIRE_DAYS_LEFT = 'expire_days_left',
  EXPIRED = 'expired',
  AVATAR_NAME = 'avatar_name',
  ENTITY = 'entity',
  DISPLAY_ATTRIBUTE_BILLING = 'display_attribute_billing',
  DISPLAY_ATTRIBUTE_PROFILE_PROPERTIES = 'display_attribute_profile_properties',
  DISPLAY_ATTRIBUTE_PROFILE_EVENTS = 'display_attribute_profile_events',
  INTEGRATION_NAME = 'integration_name',
  INTEGRATION_TYPE = 'integration_type',
  CONNECTOR_TYPE = 'connector_type',
  INTEGRATION_ID = 'integration_id',
  TYPE = 'type',
  ACTIVE = 'active',
  FILTER_EXPRESSION = 'filter_expression',
  TIME = 'time',
  FIELD_MAPPING = 'field_mapping',
  OFFSET = 'offset',
  LIMIT = 'limit',
  SORT_BY = 'sort_by',
  SEARCH_QUERY = 'search_query',
  SEARCH = 'search',
  STATUS = 'status',
  ORDER_BY = 'order_by',
  GOAL = 'goal',
  MODEL_ID = 'model_id',
  SAMPLE_PROFILES = 'sample_profiles',
  FILTER = 'filter',
  DESTINATION = 'destination',
  EMAIL = 'email',
  IS_RECURRING = 'is_recurring',
  SCHEDULE_TYPE = 'schedule_type',
  DESTINATION_OBJECT = 'destination_object',
  PROFILE_ID = 'profile_id',
  ACCOUNT_ID = 'account_id',
  PAGE = 'page',
  DATE_OPERATOR = 'date_operator',
  NUM_OF_DAYS = 'num_of_days',
  TIME_PERIOD = 'time_period',
  ATTRIBUTE_NAME = 'attribute_name',
  OPERATOR = 'operator',
  VALUE = 'value',
  SOURCE_NAME = 'source_name',
  SOURCE_TYPE = 'source_type',
  ORG_ID = 'org_id',

  // Exclusive Frontend Keys

  DESCRIPTION = 'description',
  DESTINATION_ID = 'destination_id',
  SCHEDULE_CADENCE = 'schedule_cadence',
  EXPORT_TYPE = 'export_type',
  NUMBER_OF_FIELDS_EXPORTED = 'number_of_fields_exported',
  OPPORTUNITY_ID = 'opportunity_id',
  OPPORTUNITY_NAME = 'opportunity_name',
  INVITE_ID = 'invite_id',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  COLUMN = 'column',
  ATTRIBUTES = 'attributes',
  AFFECTED_USER = 'affected_user',
  OLD_ROLE = 'old_role',
  NEW_ROLE = 'new_role',
  QUICK_VIEW = 'quick_view',
  RECOMMENDED_FILTER_NAME = 'recommended_filter_name',
  SCHEDULED_FOR = 'scheduled_for',
  PAGE_NAME = 'page_name',
  GTM_STRATEGY = 'gtm_strategy',
  STEP_NAME = 'step_name',
  ORGANIZATION = 'organization',
}

export enum AnalyticsEvents {
  // Keep the values same on backend and frontend when adding any new event

  // Events on Backend
  CONNECTED_INTEGRATION_CREATED = 'Integration Saved',
  CONNECTED_INTEGRATION_UPDATED = 'Integration Edited',
  CONNECTED_INTEGRATION_DELETED = 'Integration Deleted',

  OPPORTUNITY_CREATED = 'Opportunity Created',
  OPPORTUNITY_CREATE_FILTER = 'PQL Filter Applied',
  OPPORTUNITY_CREATE_QUERY_FILTER = 'PQL Query Fired',
  OPPORTUNITY_RESUMED = 'Opportunity Resumed',
  OPPORTUNITY_STOPPED = 'Opportunity Stopped',
  OPPORTUNITY_FILTER = 'Opportunity Filter',
  OPPORTUNITY_DELETED = 'Opportunity Deleted',
  OPPORTUNITY_SIDEBAR_EXPANDED = 'Opportunity Sidebar Expanded',
  OPPORTUNITY_FILTER_SKIPPED = 'Opportunity Filter Skipped',
  OPPORUNITY_RECOMMENDED_FILTER_APPLIED = 'Opportunity Recommended Filter Applied',
  OPPORUNITY_RECOMMENDED_FILTER_UNAPPLIED = 'Opportunity Recommended Filter Unapplied',
  OPPORTUNITY_RECOMMENDED_FILTER_EDIT_CLICKED = 'Opportunity Recommended Filter Edit Clicked',
  OPPORTUNITY_SALES_CUSTOM_VALUE_SET = 'Opportunity Sales Custom Value Set',

  ENTITY_QUERY = 'Entity Query',

  DISPLAY_ATTRIBUTE_ADD_OR_UPDATE = 'Display Attribute Add Or Update',

  USER_PROFILE_VIEWED = 'User Profile Viewed',
  ACCOUNT_PROFILE_VIEWED = 'Account Profile Viewed',

  TEAM_MEMBER_INVITE_CANCELLED = 'Team Member Invite Cancelled',
  TEAM_MEMBER_INVITE_RESEND = 'Team Member Invite Resend',
  TEAM_MEMBER_INVITE_ACCEPTED = 'Team Member Invite Accepted',
  TEAM_MEMBER_INVITE_SEND = 'Team Member Invite Send',
  TEAM_MEMBER_DELETED = 'Team Member Deleted',

  TOKEN_CREATED = 'Token Created',
  TOKEN_DELETED = 'Token Deleted',

  USER_PROFILE_EDITED = 'User Profile Edited',
  USER_PASSWORD_CHANGED = 'User Password Changed',
  USER_PASSWORD_RESET_REQUESTED = 'User Password Reset Requested',
  USER_PASSWORD_RESET_CONFIRM = 'User Password Reset Confirm',

  //Onboarding
  SIGNUP_SUCCESS = 'User Signed up',
  CREATE_ORGANIZATION_SUCCESS = 'Account Created',
  TIMEZONE_SELECTED = 'Timezone Selected',
  SOURCE_CONNECTED = 'Source Connected',
  SOURCE_NOT_FOUND = 'Source Not Found',
  CALL_SCHEDULED = 'Meeting Invite Booked',

  // Activation
  ACTIVATION_INITIATED = 'Account activation initiated',
  ACTIVATION_RESUMED = 'Account activation resumed',

  // Exclusive Frontend Events
  OPPORTUNITY_SEARCH_CLICKED = 'Opportunity Search Clicked',
  OPPORTUNITY_SEARCH_QUERY = 'Opportunity Search Query',
  OPPORTUNITY_FAILED = 'Opportunity Failed',
  OPPORTUNITY_QUICK_VIEW_CLICKED = 'Opportunity Quick View Clicked',
  OPPORTUNITY_DETAIL_CLICKED = 'Opportunity Detail Clicked',
  CLICKED_OPPORTUNITY_DUPLICATE = 'Clicked Opportunity Duplicate',
  TOKEN_FAILED = 'Token Failed',
  TEAM_MEMBER_INVITE_CANCELLED_FAILED = 'Team Member Invite Cancelled Failed',
  USER_PASSWORD_CHANGED_FAILED = 'User Password Changed Failed',
  TOKEN_DELETED_FAILED = 'Token Deleted Failed',
  USER_PROFILE_EDITED_FAILED = 'User Profile Edited Failed',
  TEAM_MEMBER_INVITE_RESEND_FAILED = 'Team Member Invite Resend Failed',
  TEAM_MEMBER_INVITE_SEND_FAILED = 'Team Member Invite Send Failed',
  TEAM_MEMBER_DELETED_FAILED = 'Team Member Deleted Failed',
  CONNECTED_INTEGRATION_CREATED_FAILED = 'Integration Failed',
  ADD_INTEGRATION_CLICKED = 'Integration Clicked',
  CONNECTED_INTEGRATION_DELETED_FAILED = 'Integration Deleted Failed',
  CONNECTED_INTEGRATION_INACTIVE = 'Integration Paused',
  CONNECTED_INTEGRATION_ACTIVE = 'Integration Resumed',
  USER_LIST_SEARCH_CLICKED = 'User List Search Clicked',
  ACCOUNT_LIST_SEARCH_CLICKED = 'Account List Search Clicked',
  CONNECTED_INTEGRATION_UPDATED_FAILED = 'Integration Edit Failed',
  OPPORTUNITY_SORTED = 'Opportunity Sorted',
  USER_LIST_SORTED = 'User List Sorted',
  ACCOUNT_LIST_SORTED = 'Account List Sorted',
  USER_LIST_ATTRIBUTE_ADDED = 'User List Attribute Added',
  ACCOUNT_LIST_ATTRIBUTE_ADDED = 'Account List Attribute Added',
  USER_LIST_PAGE_CHANGED = 'User List Page Changed',
  ACCOUNT_LIST_PAGE_CHANGED = 'Account List Page Changed',
  SAMPLE_PROFILES_CLICKED = 'Sample Profile Clicked',
  ACCOUNT_LIST_ATTRIBUTE_REMOVED = 'Account List Attribute Removed',
  USER_LIST_ATTRIBUTE_REMOVED = 'User List Attribute Removed',
  ROLE_UPDATED = 'Role updated',
  ROLE_UPDATE_FAILED = 'Role Update Failed',
  AVATAR_SELECTED = 'Avatar Selected',
  ONBOARDING_LINK_CLICKED = 'Onboarding Link Clicked',
  CHAT_WITH_US = 'Chat With Us',
  // system_cohorts or growth_strategies
  COHORT_MODAL_OPENED = 'Cohort Modal Opened',
  GTMSTRATEGIES_MODAL_OPENED = 'GTM Strategies Modal Opened',
  GTMSTRATEGY_SELECTED = 'GTM Strategy Selected',
  INTEGRATION_REQUESTED = 'Integration requested',
  INTEGRATION_REQUEST_FAILED = 'Integration request failed',
  INTEGRATION_SEARCH_QUERY = 'Integration Search Query',
  // Playbook Stats event
  ABOUT_TAB_CLICKED = 'About tab clicked',
  SYNC_DETAILS_TAB_CLICKED = 'Sync details tab clicked',
  PERFORMANCE_TAB_CLICKED = 'Performance tab clicked',
  SYNCED_USERS_TAB_CLICKED = 'Synced users tab clicked',

  COLUMN_ADDED = 'Column Added',
  COLUMN_DELETED = 'Column Deleted',

  SEND_JOIN_EXISTING_ORG = 'Send Join Existing Organisation Request',
  RESEND_JOIN_EXISTING_ORG = 'Resend Join Existing Organisation Request',

  TOGGLE_TEST_LIVE = 'Toggle Test-Live Account',
  SELECT_DATASET = 'Dataset Selection',

  OPPORTUNITY_INITIATED = 'Opportunity Initiated',
}

export enum ErrorAnalyticsEvents {
  // For toast and error boundaries
  ERROR_OCCURED = 'ERROR_OCCURED',
}
