import useDsEnabled from 'hooks/useDsEnabled';
import useModels from 'hooks/useModels';
import { goalsWordDictionary } from 'pages/opp-details/constants';
import { AnimatePing } from 'pages/opportunities/Opportunities';
import { usePQLWorkflowRoute } from 'pages/pql-workflow';
import useCohortTabs from 'pages/pql-workflow/hooks/useCohortTabs';
import useOpportunityQuery from 'pages/pql-workflow/hooks/useOpportunityQuery';
import useTotalUsersAggregate from 'pages/pql-workflow/hooks/useTotalUsersAggregate';
import { DefinePQLSections, useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { LeadStatus, useFiltersPQLStore } from 'pages/pql-workflow/store/PQLFiltersStore';
import { useState } from 'react';
import {
  BannerInfo,
  ControlGroupIcon,
  FilledArrowRound,
  ZapIcon,
} from 'ui-components/data-display/Icons';
import Banner from 'ui-components/feedback/Banner';
import Drawer from 'ui-components/navigation/Drawer';
import { capitalizeFirstLetter, classNames } from 'utils/common';
import { AnimatedNumber } from '../AnimatedNumber';
import { getCohortIconAndColor } from '../define-pql/Cohort';
import LeadStatusEmoji from '../filters/LeadStatusEmoji';
import SampleEntities from '../sample-entities/SampleEntities';

export default function SectionSidebar() {
  const { data: opportunityQuery, isLoading, isSuccess, isFetching } = useOpportunityQuery();
  const { getTotalUsersAggregate, getHWCDistribution, getPersonaDistribution } =
    useTotalUsersAggregate();
  const { targetEntity, setShowSampleProfiles, cohorts, sectionsMap, modelId, leadStatus } =
    useDefinePQLStore();
  const { isDuplicate } = usePQLWorkflowRoute();
  const { data: models } = useModels();
  const goal = models?.find((m) => m.modelId === modelId)?.goal;
  const dsEnabled = useDsEnabled(modelId, targetEntity);
  const [expandedSidebar, setExpandedSidebar] = useState(false);
  const { controlGroup } = useFiltersPQLStore();
  const { cohortItems: cohortsResponse, allUsersCohortItem } = useCohortTabs();
  const cohortsToShow = cohorts?.includes(allUsersCohortItem?.id)
    ? cohortsResponse
    : cohortsResponse?.filter((cohort) => cohorts.includes(cohort.id));

  const countToShow = targetEntity && modelId && isSuccess ? getTotalUsersAggregate() : 0;

  const showQualifiedUI =
    (dsEnabled
      ? sectionsMap[DefinePQLSections.GOALS]
      : sectionsMap[DefinePQLSections.CONVERSION_EVENT]) || isDuplicate;

  return (
    <div className="sticky w-full top-4">
      <div className="pt-8 pb-6 bg-no-repeat bg-cover bg-tw-blue-f2 rounded-xl bg-pql-rhs">
        <div className="px-6">
          <h4 className="text-xs text-tw-black-9">
            {showQualifiedUI ? 'YOU HAVE QUALIFIED' : 'LIFETIME COUNT'}
          </h4>
          <div className="flex items-center mt-2 gap-x-4">
            <h3 className="text-2xl font-medium text-tw-black-3">
              <AnimatedNumber to={countToShow} /> {targetEntity.toLowerCase()}
            </h3>
            {(isLoading || isFetching) && <AnimatePing className="bg-tw-blue-0d" />}
          </div>
          <button
            onClick={() => {
              setExpandedSidebar(true);
              setShowSampleProfiles(true);
            }}
            className="flex items-center mt-3 text-tw-blue-0d gap-x-2"
          >
            <span className="font-medium">See sample {targetEntity.toLowerCase()}</span>
            <FilledArrowRound className="fill-current" />
          </button>
        </div>
        <div className="px-4">
          {!showQualifiedUI && dsEnabled && opportunityQuery?.goalMetRate && (
            <div className="flex items-center p-4 mt-6 bg-white rounded-xl gap-x-3">
              <ZapIcon className="w-6 fill-current text-tw-black-1" />
              <span className="font-medium text-tw-black-3">
                {(opportunityQuery?.goalMetRate?.all * 100).toFixed(2)}% expected{' '}
                {goalsWordDictionary[goal.toUpperCase()]['noun']} rate
              </span>
            </div>
          )}
          {showQualifiedUI && opportunityQuery && (
            <div className="flex flex-col p-4 mt-6 bg-white gap-y-6 rounded-xl">
              {dsEnabled && (
                <>
                  {(leadStatus.includes(LeadStatus.ALL) || leadStatus.includes(LeadStatus.HOT)) && (
                    <div className="flex gap-x-2.5">
                      <LeadStatusEmoji value="hot" />
                      <div className="flex flex-col">
                        <p className="font-medium text-tw-red-fd">
                          <AnimatedNumber to={getHWCDistribution()?.hot || 0} duration={0.5} /> hot{' '}
                          {targetEntity.toLowerCase()}
                        </p>
                        {opportunityQuery?.goalMetRate && (
                          <p className="mt-2 text-xs text-tw-black-9">
                            {(opportunityQuery?.goalMetRate?.hot * 100).toFixed(2)}% expected{' '}
                            {goalsWordDictionary[goal.toUpperCase()]['noun']} rate
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                  {(leadStatus.includes(LeadStatus.ALL) ||
                    leadStatus.includes(LeadStatus.WARM)) && (
                    <div className="flex gap-x-2.5">
                      <LeadStatusEmoji value="warm" />
                      <div className="flex flex-col">
                        <p className="font-medium text-tw-yellow-fd">
                          <AnimatedNumber to={getHWCDistribution()?.warm || 0} duration={0.5} />{' '}
                          warm {targetEntity.toLowerCase()}
                        </p>
                        {opportunityQuery?.goalMetRate && (
                          <p className="mt-2 text-xs text-tw-black-9">
                            {(opportunityQuery?.goalMetRate?.warm * 100).toFixed(2)}% expected{' '}
                            {goalsWordDictionary[goal.toUpperCase()]['noun']} rate
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                  {(leadStatus.includes(LeadStatus.ALL) ||
                    leadStatus.includes(LeadStatus.COLD)) && (
                    <div className="flex gap-x-2.5">
                      <LeadStatusEmoji value="cold" />
                      <div className="flex flex-col">
                        <p className="font-medium text-tw-blue-39">
                          <AnimatedNumber to={getHWCDistribution()?.cold || 0} duration={0.5} />{' '}
                          cold {targetEntity.toLowerCase()}
                        </p>
                        {opportunityQuery?.goalMetRate && (
                          <p className="mt-2 text-xs text-tw-black-9">
                            {(opportunityQuery?.goalMetRate?.cold * 100).toFixed(2)}% expected{' '}
                            {goalsWordDictionary[goal.toUpperCase()]['noun']} rate
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
              {!dsEnabled &&
                cohortsToShow
                  ?.filter((c) => c.name !== 'All Users')
                  .map((cohort) => {
                    return (
                      <div key={cohort.id} className="flex items-center gap-x-3">
                        {getCohortIconAndColor(cohort.name).icon}
                        <span
                          className={classNames(
                            'font-medium',
                            getCohortIconAndColor(cohort.name).color
                          )}
                        >
                          <AnimatedNumber
                            to={getPersonaDistribution()?.[cohort.id] || 0}
                            duration={0.5}
                          />{' '}
                          {cohort.name
                            .replace('Users', capitalizeFirstLetter(targetEntity))
                            .toLowerCase()}
                        </span>
                      </div>
                    );
                  })}
            </div>
          )}
        </div>
        {controlGroup.enabled && (
          <div className="px-4 mt-4">
            <div className="p-4 bg-white rounded-xl">
              <div className="flex items-center gap-x-3">
                <ControlGroupIcon />
                <span className="font-medium text-tw-black-5">Control group experiment</span>
              </div>
              <div className="grid grid-cols-2 mt-4 gap-x-2">
                <div className="p-2 rounded-lg bg-tw-blue-f2">
                  <div className="text-xs text-tw-black-9">Test</div>
                  <div className="text-tw-black-3">
                    {new Intl.NumberFormat('en-US', {
                      notation: 'compact',
                    }).format(
                      Math.floor(((100 - controlGroup.value) / 100) * getTotalUsersAggregate())
                    )}{' '}
                    {targetEntity.toLowerCase()}
                  </div>
                </div>
                <div className="p-2 rounded-lg bg-tw-blue-f2">
                  <div className="text-xs text-tw-black-9">Control</div>
                  <div className="text-tw-black-3">
                    {new Intl.NumberFormat('en-US', {
                      notation: 'compact',
                    }).format(
                      Math.floor((controlGroup.value / 100) * getTotalUsersAggregate())
                    )}{' '}
                    {targetEntity.toLowerCase()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Drawer
        widthClassName="w-4/5"
        open={expandedSidebar}
        onClose={() => setExpandedSidebar(false)}
        primaryButton={null}
        hideButtons={true}
      >
        {/* Control group */}
        {controlGroup?.enabled && (
          <div className="my-11">
            <Banner icon={<BannerInfo />} color="#0D6EFD" borderColor="#0D6EFD">
              <div className="font-semibold text-tw-black-3">Control group is active</div>
              <div className="text-tw-black-7">
                {controlGroup?.value}% of your qualified user base would be randomly selected for
                the control group
              </div>
            </Banner>
          </div>
        )}
        {isLoading ? (
          <div className="w-2/5 h-8 mt-11 bg-tw-gray-eb"></div>
        ) : (
          <h2 className="text-xl font-medium mt-11 text-tw-black-3">
            {new Intl.NumberFormat('en-us', {
              notation: 'standard',
            }).format(getTotalUsersAggregate())}{' '}
            {targetEntity?.toLowerCase()} qualified
          </h2>
        )}
        <SampleEntities qualifiedEntitiesCount={getTotalUsersAggregate()} />
      </Drawer>
    </div>
  );
}
