import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import { FC, useRef, useState } from 'react';
import { classNames } from '../../utils/common';
import useClickOutside from '../../hooks/UseClickOutside';
import { isComingSoon } from '../../pages/opportunities/types';
import MenuOptionsSearch from '../business/opportunities/MenuOptionsSearch';

export type SelectOption = {
  label: string;
  value: string;
  Icon?: any;
  type?: any;
  id?: any;
  CustomIcon?: any;
};
export interface SelectProps {
  options: SelectOption[];
  value?: string | null;
  ph: string;
  onChange?: (value: string | string[]) => void;
  btnStyle?: string;
  listStyle?: string;
  itemStyle?: string;
  width?: string;
  type?: 'normal' | 'multi';
  virtualize?: boolean;
  render?: (option: any) => any;
  stuckField?: any;
  disabledFields?: Array<string>;
  error?: {
    isError: boolean;
    message: string;
  };
}

const defaultBtnClassname =
  'bg-white mt-0 w-64 text-sm text-tw-black-3 py-3 px-4 text-left border-1 border-solid border-tw-gray-c rounded';

const VirtualizedSelect: FC<SelectProps> = (props) => {
  const {
    width = '100%',
    value,
    type = 'normal',
    options,
    ph,
    onChange,
    virtualize,
    render,
    disabledFields,
    error,
  } = props;
  const { btnStyle = '', listStyle = '', itemStyle = '' } = props;
  const optionSelected = options?.find((option) => option?.value === value);
  const btnLabel = optionSelected?.label || ph;
  const BtnIcon = optionSelected?.Icon ? optionSelected?.Icon : null;
  const CustomIcon = optionSelected?.CustomIcon || null;
  const menuRef = useRef<any>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  useClickOutside(menuRef, () => setIsMenuOpen(false));
  const closeMenu = () => setIsMenuOpen(false);
  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  return (
    <Menu isOpen={isMenuOpen} closeOnSelect={true} matchWidth>
      <MenuButton
        as={Button}
        onClick={toggleMenu}
        className={classNames(
          defaultBtnClassname,
          !optionSelected ? 'text-tw-black-9' : '',
          error?.isError ? 'border-tw-red' : '',
          btnStyle
        )}
        w={width}
        rightIcon={<ChevronDownIcon />}
        variant="outlined"
      >
        <div className="flex items-center">
          {BtnIcon && <BtnIcon />}
          {CustomIcon}
          <div
            className={classNames(
              'overflow-hidden text-sm font-normal overflow-ellipsis',
              btnLabel === ph && 'text-tw-black-7'
            )}
          >
            {btnLabel}
          </div>
        </div>
      </MenuButton>
      <p className="text-xs text-red-500">{error?.isError ? error?.message : ''}</p>
      <MenuList className={classNames(listStyle)} ref={menuRef} zIndex={10}>
        {virtualize ? (
          <>
            <MenuOptionsSearch
              isMenuOpen={isMenuOpen}
              optionsArray={options}
              onChange={(inputVal) => {
                if (!disabledFields?.includes(inputVal)) {
                  onChange(inputVal);
                }
              }}
              value={value}
              closeMenu={closeMenu}
              parentWidth={width}
              render={(option: SelectOption) =>
                render ? (
                  render(option)
                ) : (
                  <Option
                    disabled={disabledFields?.includes(option.value)}
                    itemStyle={itemStyle}
                    {...option}
                  />
                )
              }
            />
            {props.stuckField}
          </>
        ) : (
          <MenuOptionGroup
            onChange={onChange}
            value={value}
            type={type === 'multi' ? 'checkbox' : 'radio'}
          >
            {options.map((option, index) => (
              <MenuItemOption onClick={closeMenu} key={index} value={option.value}>
                <Option itemStyle={itemStyle} {...option} />
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        )}
      </MenuList>
    </Menu>
  );
};

interface OptionProps {
  value: string;
  itemStyle?: string;
  label: string;
  Icon?: any;
  type?: any;
  disabled?: boolean;
  infoMessage?: string;
}

export const Option: FC<OptionProps> = ({ Icon, label, type, disabled, itemStyle }) => {
  return (
    <HStack className={classNames('px-4', itemStyle)} spacing="2">
      {Icon && <Icon />}
      {disabled ? (
        <div className={'text-tw-black-9 cursor-not-allowed'}>
          <p>{label}</p>
          <p className={'text-xs'}>(Field has already been assigned)</p>
        </div>
      ) : (
        <div className="product-tour-20">{label}</div>
      )}
      {isComingSoon(type) && (
        <div className="py-1 px-2 rounded-md text-2.5 ml-auto"> coming soon! </div>
      )}
    </HStack>
  );
};

export default VirtualizedSelect;
