import { ErrorBoundary } from 'react-error-boundary';
import { classNames } from 'utils/common';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAnalytics } from '../../telemetry';
import { AnalyticsEvents, ErrorAnalyticsEvents } from '../../telemetry/constants';
import useOpportunity from '../../hooks/useOpportunity';
import PQlDescription from './PQLDescription';
import PQLRunData from './PQLRunData';

import Abouts from './stats-tabs/About';
import Performance from './stats-tabs/Performance';
import SyncDetails from './stats-tabs/SyncDetails';
// TODO: Rename this tab as per entity, Synced users/Synced accounts.
import SyncedEntities from './stats-tabs/SyncedEntities';
import { OpportunityStatus } from 'api-client';
import useGetSyncedUsers from './hooks/useGetSyncedUsers';
import { useGlobalStore } from 'stores/GlobalStore';
import { useAuth } from 'hooks/useAuth';
import ErrorBoundaryFallback from 'ui-components/feedback/ErrorBoundaryFallback';

const sectionTabs = [
  { id: 0, label: 'About', anayticsEvent: AnalyticsEvents.ABOUT_TAB_CLICKED },
  { id: 1, label: 'Sync details', anayticsEvent: AnalyticsEvents.SYNC_DETAILS_TAB_CLICKED },
  { id: 2, label: 'Performance', anayticsEvent: AnalyticsEvents.PERFORMANCE_TAB_CLICKED },
  { id: 3, label: 'Qualified', anayticsEvent: AnalyticsEvents.SYNCED_USERS_TAB_CLICKED },
];

// Giving padding to the sections separately helps us
// disable for the ones we dont need and also helps { position: sticky }
export const PQL_STATS_PAGE_PADDING_CLASSNAME = 'px-48 2xl:px-64';

// eslint-disable-next-line
function getAvailableTabs(opportunity) {
  if (opportunity?.isSample) {
    return sectionTabs.slice(0, 3);
  }
  // do not show synced users for failed playbooks
  if (opportunity?.status === OpportunityStatus.FAILED) {
    return sectionTabs.slice(0, 3);
  }
  // only hide Performance tab for non ds
  // if (!performanceStatsEnabled) {
  //   return sectionTabs.filter((st) => st.label !== 'Performance');
  // }
  return sectionTabs;
}

export default function NewOpDetails() {
  const { opportunityDetails, setActiveStatsTab } = useGlobalStore();
  const { id: opId } = useParams<{ schemaName: string; id: string }>();
  const [activeTabId, setActiveTabId] = useState(opportunityDetails?.activeStatsTab);
  const { track } = useAnalytics();
  const { user: userData } = useAuth();

  const { data: opportunity, isLoading: loadingOpportunityDetails } = useOpportunity(opId);

  const {
    data: syncedUsers,
    isLoading: loadingSyncedUsers,
    isError: errorLoadingSyncedUsers,
  } = useGetSyncedUsers(
    opportunity?.id,
    opportunity?.exports?.[0]?.id,
    !(
      userData?.currentOrganization?.isSandbox ||
      opportunity?.status === OpportunityStatus.SCHEDULED ||
      opportunity?.isSample ||
      opportunity?.status === OpportunityStatus.FAILED
    )
  );

  useEffect(() => {
    return () => {
      setActiveStatsTab(activeTabId);
    };
  }, [activeTabId]);

  if (loadingOpportunityDetails || !opportunity) {
    return (
      <div className="bg-white h-[93.2634vh] -mt-6 overflow-y-scroll 2xl:mx-48">
        <div className="px-48 2xl:px-64">
          <div className="flex items-center justify-between w-full gap-x-4 z-5 h-26">
            <div className="bg-tw-gray-eb h-[50%] w-48 animate-pulse mt-6"></div>
          </div>
          <hr className="border-b border-tw-gray-eb"></hr>
          <div className="w-full py-8 z-5 h-100">
            <div className="w-2/5 h-10 bg-tw-gray-eb animate-pulse"></div>
            <div className="w-3/5 h-6 my-4 bg-tw-gray-eb animate-pulse"></div>
            <div className="w-3/5 h-6 bg-tw-gray-eb animate-pulse"></div>
          </div>
          <hr className="my-4 border-b border-tw-gray-eb"></hr>
          <div className="w-full z-5 h-100">
            <div className="flex items-center gap-x-4">
              <div className="pb-2 font-bold border-b-2 border-transparent cursor-pointer select-none text-tw-black-9">
                About
              </div>
              <div className="pb-2 font-medium border-b-2 border-transparent cursor-pointer select-none text-tw-black-9">
                Sync details
              </div>
              <div className="pb-2 font-medium border-b-2 border-transparent cursor-pointer select-none text-tw-black-9">
                Performance
              </div>
              {opportunity && opportunity?.status !== OpportunityStatus.FAILED && (
                <div className="font-medium text-tw-black-9 border-transparent border-b-2 pb-2 cursor-pointer select-none">
                  Qualified {opportunity?.query?.type?.toLowerCase()}
                </div>
              )}
              {/* <div className="pb-2 font-medium border-b-2 border-transparent cursor-pointer select-none text-tw-black-9">
                Errors
              </div> */}
            </div>
          </div>
          <div className="w-full z-5 h-100">
            <div className="flex flex-wrap items-start gap-x-6">
              {Array(4)
                .fill(0)
                .map((val, idx) => {
                  return (
                    <div
                      key={idx}
                      className="relative w-64 px-4 py-4 my-2 rounded-lg h-36 border-1 border-tw-gray-eb shrink-0"
                    >
                      <div className="w-3/5 h-8 bg-tw-gray-eb animate-pulse"></div>
                      <div className="w-full h-8 my-2 bg-tw-gray-eb animate-pulse"></div>
                      <div className="w-full h-8 bg-tw-gray-eb animate-pulse"></div>
                    </div>
                  );
                })}
            </div>
            <hr className="mt-10 border-b border-tw-gray-eb" />
            <div className="py-8 z-5 h-100">
              <div className="flex items-center gap-x-2">
                <span className="font-weight-bold">Filters applied</span>
              </div>
              <div className="py-4">
                {Array(4)
                  .fill(0)
                  .map((val, idx) => {
                    return (
                      <div key={idx} className="w-full h-8 mb-4 bg-tw-gray-eb animate-pulse"></div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={(e) =>
        track(ErrorAnalyticsEvents.ERROR_OCCURED, {
          error: e.message,
        })
      }
    >
      <div className="bg-white 2xl:mx-48 -mt-6">
        <div className="pb-20">
          <PQlDescription opportunity={opportunity} />
          <PQLRunData opportunity={opportunity} />
          <div
            className={classNames(
              'w-full z-5 h-100 sticky top-0 bg-tw-white-ff',
              PQL_STATS_PAGE_PADDING_CLASSNAME
            )}
          >
            <hr className="py-3 border-b border-tw-gray-eb" />
            <div className="flex items-center pb-6 gap-x-4 pt-3">
              {getAvailableTabs(opportunity).map(({ id, label, anayticsEvent }) => (
                <div
                  key={id}
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    });
                    setActiveTabId(id);
                    track(anayticsEvent, {
                      opportunityId: opportunity.id,
                    });
                  }}
                  className={classNames(
                    activeTabId === id
                      ? 'font-bold text-tw-blue-0d border-tw-blue-0d'
                      : 'font-medium text-tw-black-9 border-transparent',
                    'border-b-2 pb-2 cursor-pointer select-none h-8 flex'
                  )}
                >
                  {label}{' '}
                  {(label === 'Qualified' && opportunity?.query?.type?.toLowerCase()) ?? 'users'}
                  {label === 'Qualified' &&
                    !userData?.currentOrganization?.isSandbox &&
                    !loadingSyncedUsers &&
                    opportunity?.status !== OpportunityStatus.SCHEDULED &&
                    !errorLoadingSyncedUsers && (
                      <div className="ml-1">
                        (
                        {new Intl.NumberFormat('en-us', {
                          compactDisplay: 'short',
                          notation: 'standard',
                        }).format(syncedUsers?.count)}
                        )
                      </div>
                    )}
                </div>
              ))}
            </div>
          </div>
          {activeTabId === 0 && <Abouts opportunity={opportunity} />}
          {activeTabId === 1 && <SyncDetails opportunity={opportunity} />}
          {activeTabId === 2 && <Performance opportunity={opportunity} />}
          {activeTabId === 3 && <SyncedEntities opportunity={opportunity} />}
        </div>
      </div>
    </ErrorBoundary>
  );
}
