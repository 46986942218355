import { CrossIcon, SearchIcon } from 'ui-components/data-display/Icons';
import { classNames } from 'utils/common';
import * as React from 'react';
import { ENTER } from '../../../utils/globalTypes';

interface SearchProps {
  placeholder: string;
  value: string;
  onChangeFunction: (any) => void;
  onClearFunction: () => void;
  onClick?: () => void;
  hasShadow?: boolean;
  className?: string;
  fullWidth?: boolean;
  autoFocus?: boolean;
}

export const Search: React.FC<SearchProps> = ({
  placeholder,
  value,
  onChangeFunction,
  onClearFunction,
  onClick,
  className,
  fullWidth,
  autoFocus,
}) => {
  const onKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === ENTER) {
      e.preventDefault();
      onChangeFunction(e);
    }
  };

  return (
    <div
      className={classNames(
        `relative flex justify-between items-center bg-white border-1 border-tw-gray-ed rounded-md py-3 pr-3`,
        className,
        placeholder === 'Start searching' && value === '' ? `hidden` : `block`,
        fullWidth ? 'w-full' : 'w-68'
      )}
    >
      <div className="px-3 cursor-pointer">
        <SearchIcon />
      </div>
      <div className="flex-1">
        <input
          type="text"
          value={value}
          autoFocus={autoFocus}
          onChange={onChangeFunction}
          id="searchInput"
          placeholder={placeholder}
          autoComplete="off"
          onKeyDown={onKeyDown}
          className="text-sm bg-transparent w-100 text-tw-black-7 focus:outline-none"
          onClick={onClick}
        />
      </div>
      <div className="absolute p-2 cursor-pointer right-1" onClick={onClearFunction}>
        {value && <CrossIcon className="w-2 fill-current text-tw-black-3" />}
      </div>
    </div>
  );
};

Search.defaultProps = {
  hasShadow: true,
  fullWidth: false,
};
