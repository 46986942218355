import { toast as toastify } from 'react-toastify';
import { CopyIcon } from 'ui-components/data-display/Icons';
import * as React from 'react';
import { classNames } from '../../utils/common';
import { useAnalytics } from '../../telemetry';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';

interface CopyProps {
  value: string;
  className?: string;
  toastTitle?: string;
  trackTitle?: string;
  copyText?: boolean;
  onClick?: () => void;
}

const Copy: React.FC<CopyProps> = ({
  value,
  onClick,
  className,
  toastTitle,
  trackTitle,
  copyText,
}) => {
  const { track } = useAnalytics();

  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
    toastify(<SuccessToast description={toastTitle} />, {
      type: 'success',
    });
    track(trackTitle);
  };

  return (
    <div
      onClick={() => {
        copyToClipboard(value);
        onClick();
      }}
      className={classNames(
        'border-dashed border-1 border-tw-gray-c p-2 rounded mr-8',
        'flex items-center justify-between gap-x-1 cursor-pointer',
        className
      )}
    >
      <span className="w-11/12 truncate">{value}</span>
      {copyText ? (
        <div className="flex items-center cursor-pointer gap-x-1.5">
          <CopyIcon className="w-4 h-4" />
          <span className="text-tw-black-5">COPY</span>
        </div>
      ) : (
        <CopyIcon className="w-4 h-4 cursor-pointer" />
      )}
    </div>
  );
};

Copy.defaultProps = {
  toastTitle: 'URL copied to clipboard',
  trackTitle: 'Webhook URL Copied',
  onClick: () => {},
  copyText: false,
};

export default Copy;
