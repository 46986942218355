import {
  AboutToSleepPersona,
  AllUsersPersona,
  ChampionsPersona,
  HibernatingPersona,
  NeedsAttentionPersona,
  NewUsersPersona,
  PromisingUsersPersona,
} from 'ui-components/data-display/Icons';
import { capitalizeFirstLetter, classNames } from 'utils/common';
import useCohortCount from 'pages/pql-workflow/hooks/useCohortCount';
import useCohortTabs from 'pages/pql-workflow/hooks/useCohortTabs';
import { useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import RFATableCheckbox from './RFATableCheckbox';

enum CellColor {
  blue = 'blue',
  lightBlue = 'lightblue',
  faintBlue = 'faintblue',
}

function getColorBorderClassname(color: CellColor) {
  switch (color) {
    case CellColor.blue:
      return 'bg-tw-blue-0d text-tw-gray-eb';
    case CellColor.lightBlue:
      return 'bg-tw-blue-b6 text-tw-black-7';
    case CellColor.faintBlue:
      return 'bg-tw-blue-f2 text-tw-black-7';
    default:
      return '';
  }
}

function Cell({ color, children }: { color: CellColor; children: React.ReactNode }) {
  return (
    <div
      className={classNames(
        'grid w-full h-full place-items-center',
        getColorBorderClassname(color)
      )}
    >
      {children}
    </div>
  );
}

function PersonaCell({
  title,
  count,
  icon,
}: {
  title: string;
  count: number;
  icon: React.ReactNode;
}) {
  const { targetEntity } = useDefinePQLStore();
  return (
    <div className="flex items-center px-4 py-5 gap-x-2">
      {icon}
      <div>
        <p className="text-base font-medium text-tw-black-1 whitespace-nowrap">{title}</p>
        <p className="text-xs text-tw-black-7">
          {count?.toLocaleString('en-US')} {targetEntity.toLowerCase()}
        </p>
      </div>
    </div>
  );
}

export default function RFACompareTable() {
  const { setCohorts, cohorts, targetEntity } = useDefinePQLStore();
  const { allUsersCohortItem, cohortItems } = useCohortTabs();
  const { data: cohortCounts } = useCohortCount();

  const checkCohort = (cohortString: string) => {
    const cohort = cohortItems?.find((c) => c.name.toLowerCase().includes(cohortString));
    if ([...cohorts, cohort?.id].length === cohortItems?.length - 1) {
      setCohorts([allUsersCohortItem?.id]);
    } else if (cohorts?.includes(cohort?.id)) {
      setCohorts(cohorts?.filter((c) => c !== cohort?.id));
    } else {
      setCohorts([...cohorts, cohort?.id].filter((c) => c != allUsersCohortItem?.id));
    }
  };

  const isCohortChecked = (cohortString: string) => {
    return cohorts?.includes(
      cohortItems?.find((c) => c.name.toLowerCase().includes(cohortString))?.id
    );
  };

  const getCohortCount = (cohortString: string) => {
    const cohort = cohortItems?.find((c) => c.name.toLowerCase().includes(cohortString));
    return cohortCounts?.find((c) => c.id === cohort?.id)?.count;
  };

  return (
    <div className="2xl:h-full">
      <table className="mt-6 border-collapse table-fixed col-span-14 mb-11 2xl:w-full">
        <thead>
          <tr className="text-tw-black-7">
            <th className="w-12"></th>
            <th className="w-68"></th>
            <th className="w-48 px-2 pb-4 text-center">When was the persona last active? 😴 </th>
            <th className="w-48 pb-4 text-center">
              How long has the persona been using the product? 👶
            </th>
            <th className="w-48 pb-4 text-center">How engaged is the persona? 💻</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div
                className="grid w-10 h-20 cursor-pointer place-items-center"
                onClick={() => checkCohort('new')}
              >
                <RFATableCheckbox checked={isCohortChecked('new')} />
              </div>
            </td>
            <td className="border border-tw-gray-eb">
              <PersonaCell
                title={`New ${capitalizeFirstLetter(targetEntity)}`}
                count={getCohortCount('new')}
                icon={<NewUsersPersona />}
              />
            </td>
            <td className="h-full px-4 text-center border bg-tw-blue-0d border-tw-gray-eb">
              <Cell color={CellColor.blue}>Active very recently</Cell>
            </td>
            <td className="h-full px-4 text-center border bg-tw-blue-f2 border-tw-gray-eb">
              <Cell color={CellColor.faintBlue}>Signed up recently</Cell>
            </td>
            <td className="h-full px-4 text-center border bg-tw-blue-f2 border-tw-gray-eb">
              <Cell color={CellColor.faintBlue}>Low engagement levels</Cell>
            </td>
          </tr>

          <tr>
            <td>
              <div
                className="grid w-10 h-20 cursor-pointer place-items-center"
                onClick={() => checkCohort('promising')}
              >
                <RFATableCheckbox checked={isCohortChecked('promising')} />
              </div>
            </td>
            <td className="mt-4 border border-tw-gray-eb">
              <PersonaCell
                title={`Promising ${capitalizeFirstLetter(targetEntity)}`}
                count={getCohortCount('promising')}
                icon={<PromisingUsersPersona />}
              />
            </td>
            <td className="h-full px-4 text-center border bg-tw-blue-0d border-tw-gray-eb">
              <Cell color={CellColor.blue}>Active very recently</Cell>
            </td>
            <td className="h-full px-4 text-center border bg-tw-blue-f2 border-tw-gray-eb">
              <Cell color={CellColor.faintBlue}>Signed up recently</Cell>
            </td>
            <td className="h-full px-4 text-center border bg-tw-blue-b6 border-tw-gray-eb">
              <Cell color={CellColor.lightBlue}>Highly engaged</Cell>
            </td>
          </tr>

          <tr>
            <td>
              <div
                className="grid w-10 h-20 cursor-pointer place-items-center"
                onClick={() => checkCohort('champion')}
              >
                <RFATableCheckbox checked={isCohortChecked('champion')} />
              </div>
            </td>
            <td className="border border-tw-gray-eb">
              <PersonaCell
                title={`Champion ${capitalizeFirstLetter(targetEntity)}`}
                count={getCohortCount('champion')}
                icon={<ChampionsPersona />}
              />
            </td>
            <td className="h-full px-4 text-center border bg-tw-blue-0d border-tw-gray-eb">
              <Cell color={CellColor.blue}>Active very recently</Cell>
            </td>
            <td className="h-full px-4 text-center border bg-tw-blue-0d border-tw-gray-eb">
              <Cell color={CellColor.blue}>Signed up long time ago</Cell>
            </td>
            <td className="h-full px-4 text-center border bg-tw-blue-0d border-tw-gray-eb">
              <Cell color={CellColor.blue}>Very highly engaged</Cell>
            </td>
          </tr>

          <tr>
            <td>
              <div
                className="grid w-10 h-20 cursor-pointer place-items-center"
                onClick={() => checkCohort('attention')}
              >
                <RFATableCheckbox checked={isCohortChecked('attention')} />
              </div>
            </td>
            <td className="border border-tw-gray-eb">
              <PersonaCell
                title={'Needs attention'}
                count={getCohortCount('attention')}
                icon={<NeedsAttentionPersona />}
              />
            </td>
            <td className="h-full px-4 text-center border bg-tw-blue-b6 border-tw-gray-eb">
              <Cell color={CellColor.lightBlue}>Inactive for sometime now</Cell>
            </td>
            <td className="h-full px-4 text-center border bg-tw-blue-b6 border-tw-gray-eb">
              <Cell color={CellColor.lightBlue}>Signed up sometime ago️</Cell>
            </td>
            <td className="h-full px-4 text-center border bg-tw-blue-b6 border-tw-gray-eb">
              <Cell color={CellColor.lightBlue}>Showing declining engagement</Cell>
            </td>
          </tr>

          <tr>
            <td>
              <div
                className="grid w-10 h-20 cursor-pointer place-items-center"
                onClick={() => checkCohort('sleep')}
              >
                <RFATableCheckbox checked={isCohortChecked('sleep')} />
              </div>
            </td>
            <td className="border border-tw-gray-eb">
              <PersonaCell
                title={'About to sleep'}
                count={getCohortCount('sleep')}
                icon={<AboutToSleepPersona />}
              />
            </td>
            <td className="h-full px-4 text-center border bg-tw-blue-0d border-tw-gray-eb">
              <Cell color={CellColor.blue}>Active very recently</Cell>
            </td>
            <td className="h-full px-4 text-center border bg-tw-blue-b6 border-tw-gray-eb">
              <Cell color={CellColor.lightBlue}>Signed up sometime ago️</Cell>
            </td>
            <td className="h-full px-4 text-center border bg-tw-blue-f2 border-tw-gray-eb">
              <Cell color={CellColor.faintBlue}>Use product once in a while</Cell>
            </td>
          </tr>

          <tr>
            <td>
              <div
                className="grid w-10 h-20 cursor-pointer place-items-center"
                onClick={() => checkCohort('hibernating')}
              >
                <RFATableCheckbox checked={isCohortChecked('hibernating')} />
              </div>
            </td>
            <td className="border border-tw-gray-eb">
              <PersonaCell
                title={`Hibernating ${capitalizeFirstLetter(targetEntity)}`}
                count={getCohortCount('hibernating')}
                icon={<HibernatingPersona />}
              />
            </td>
            <td className="h-full px-4 text-center border bg-tw-blue-b6 border-tw-gray-eb">
              <Cell color={CellColor.lightBlue}>Inactive for a long time now</Cell>
            </td>
            <td className="h-full px-4 text-center border bg-tw-blue-0d border-tw-gray-eb">
              <Cell color={CellColor.blue}>Signed up long time ago</Cell>
            </td>
            <td className="h-full px-4 text-center border bg-tw-blue-f2 border-tw-gray-eb">
              <Cell color={CellColor.faintBlue}>Not engaged for a long time</Cell>
            </td>
          </tr>

          <tr>
            <td>
              <div
                className="grid w-10 h-20 cursor-pointer place-items-center"
                onClick={() => {
                  setCohorts([allUsersCohortItem?.id]);
                }}
              >
                <RFATableCheckbox checked={cohorts?.includes(allUsersCohortItem?.id)} />
              </div>
            </td>
            <td className="border-t border-b border-l border-tw-gray-eb">
              <PersonaCell
                title={`Select all ${capitalizeFirstLetter(targetEntity)}`}
                count={getCohortCount('all')}
                icon={<AllUsersPersona />}
              />
            </td>
            <td className="border-t border-b border-tw-gray-eb">
              <div></div>
            </td>
            <td className="border-t border-b border-tw-gray-eb">
              <div></div>
            </td>
            <td className="border-t border-b border-r border-tw-gray-eb">
              <div></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
