import { useQuery } from 'react-query';
import { useClient } from '../../../api-client';
import { getCategorizedIntegrations } from '../../../utils/common';
import { useIntegrationListStore } from '../stores/IntegrationListStore';

export default function useInactiveIntegrations() {
  const client = useClient();
  const { setInactiveIntegrationsBasedOnCategory } = useIntegrationListStore();
  return useQuery('inactiveIntegrations', () => client.getInactiveIntegrations(), {
    onSuccess: (data) => {
      setInactiveIntegrationsBasedOnCategory(getCategorizedIntegrations(data));
    },
  });
}
