import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { TokenResponse } from '../../../api-client';
import { ReactComponent as EmptyFolder } from '../../../assets/images/empty-folder.svg';

interface APITokensTableProps {
  columns: any;
  data: TokenResponse[];
  isLoading: boolean;
}

export const APITokensTable: React.FC<APITokensTableProps> = ({ columns, data, isLoading }) => {
  const tableColumns = useMemo(() => columns, [columns]);
  const tableData = useMemo(() => data || [], [data]);
  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: tableColumns,
    data: tableData,
    initialState: {},
  });
  return (
    <div>
      <table
        {...getTableBodyProps()}
        className={`w-full table-lg table-alternate table-thead-bordered table-nowrap table-align-middle`}
      >
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  key={column.id}
                  {...column.getHeaderProps()}
                  className="px-4 text-sm font-normal leading-4 uppercase text-tw-gray-7 bg-tw-gray-f9"
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return [
              <tr key={row.id} {...row.getRowProps()} className="h-12">
                {row.cells.map((cell) => {
                  return (
                    <td
                      key={cell.row.id}
                      {...cell.getCellProps()}
                      className="px-4 text-sm font-normal text-left text-tw-black-5"
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>,
            ];
          })}
        </tbody>
      </table>
      {!isLoading && !tableData?.length && (
        <div>
          <div className="grid pt-20 place-items-center text-tw-black-5">
            <EmptyFolder />
            <p className="mt-4">There are no tokens generated yet</p>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="animate-pulse">
          {Array(8)
            .fill(0)
            .map((v, index) => (
              <div key={index} className="w-full h-12 even:bg-tw-gray-eb" />
            ))}
        </div>
      )}
    </div>
  );
};
