import { CheckBoxCircle } from 'ui-components/inputs/CheckBoxCircle';
import { classNames } from 'utils/common';
import { ReactNode } from 'react';

type CardProps = {
  label?: string;
  isActive: boolean;
  onClick: () => void;
  children?: ReactNode;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  isMultiSelect?: boolean;
  checkboxClassname?: string;
  cardClassname?: string;
};

export default function Card({
  label,
  onClick,
  isActive,
  children,
  onMouseLeave,
  onMouseOver,
  isMultiSelect,
  checkboxClassname,
  cardClassname,
}: CardProps) {
  return (
    <div
      className={classNames(
        'border-1 group relative rounded-lg min-w-40 max-h-20 px-4 flex items-center font-medium cursor-pointer select-none py-6 mr-4',
        isActive ? 'bg-tw-blue-f2 border-tw-blue-0d' : 'border-tw-gray-eb',
        cardClassname
      )}
      onMouseOver={onMouseOver ?? null}
      onMouseLeave={onMouseLeave ?? null}
      onClick={onClick}
    >
      {isMultiSelect && (
        <CheckBoxCircle
          checked={isActive}
          checkboxClassname="w-4 h-4"
          className={checkboxClassname}
        />
      )}
      {children || label}
    </div>
  );
}

Card.defaultProps = {
  cardClassname: '',
};
