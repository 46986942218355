import animationData from 'assets/lotties/loader.json';
import Lottie from 'lottie-react';

type LoaderProps = {
  height: number;
  width: number;
};

export default function Loader({ height, width }: LoaderProps) {
  return (
    <Lottie
      animationData={animationData}
      loop={true}
      autoplay={true}
      style={{
        height,
        width,
      }}
    />
  );
}
