import { CircularProgress } from '@chakra-ui/react';
import { ErrorMessage, Form, Formik } from 'formik';
import * as React from 'react';
import * as YUP from 'yup';
import { IntegrationType } from '../../../../api-client';
import { InfoToolTip } from './FormComponents';
import FormikSelect from './FormComponents';
import { PrimaryButton, TertiaryButton } from '../../../inputs/Buttons';
import { hubspotFieldType, hubspotType } from '../constants';
import useHubspotContactPropertyGroup from './hooks/useHubspotContactPropertyGroup';
import InfoBanner from '../../../feedback/InfoBanner';

interface HubspotFormProps {
  integrationId: number;
  integrationType: IntegrationType.HUBSPOT;
  submitCallback: any;
  onclose: any;
  availableFieldNames: string[];
  objectType: string;
  hasUniqueValue: boolean;
}

export const HubspotFieldForm: React.FC<HubspotFormProps> = ({
  integrationId,
  integrationType,
  submitCallback,
  onclose,
  availableFieldNames,
  objectType,
  hasUniqueValue,
}) => {
  const validationRules = YUP.object({
    label: YUP.mixed()
      .notOneOf(availableFieldNames, 'A field with this name already exists')
      .required('This Field is required'),
    type: YUP.string().required(),
    fieldType: YUP.string().required(),
    groupName: YUP.string().required(),
    objectType: YUP.string().required(),
    integrationId: YUP.number().required(),
    integrationType: YUP.string().required(),
  });
  const { data: hubspotObjectGroupData, isLoading: hubspotObjectGroupDataLoading } =
    useHubspotContactPropertyGroup(objectType.toLocaleLowerCase(), integrationId);
  return (
    <Formik
      initialValues={{
        label: '',
        type: '',
        fieldType: '',
        groupName: '',
        objectType: objectType,
        integrationId: integrationId,
        integrationType: integrationType,
        hasUniqueValue: hasUniqueValue,
      }}
      onSubmit={(values) => {
        submitCallback({
          ...values,
          name: `${values.label.toLowerCase().split(' ').join('_')}`,
        });
      }}
      validationSchema={validationRules}
    >
      {(formik) => (
        <Form>
          <div className={'px-4 py-2'}>
            {hasUniqueValue && (
              <InfoBanner>
                <div>You are creating a unique identifier in Hubspot</div>
              </InfoBanner>
            )}
            <div className={'flex items-center'}>
              <span className="my-2 text-sm text-tw-black-5">Field Name</span>
              <InfoToolTip
                label="A human-readable property name that will be shown in HubSpot. Alphanumeric and underscores only."
                width={'16px'}
                height={'16px'}
              />
            </div>
            <input
              className={`w-full px-4 py-2 rounded-sm border-1 border-tw-gray-c focus:outline-none ${
                formik.errors.label && formik.touched.label ? 'border-tw-red-dd' : ''
              }`}
              type="text"
              name="label"
              value={formik.values.label}
              onChange={(event) => {
                /**
                 * alphanumeric and underscore characters only
                 */
                if (/^[a-z0-9_]*$/g.test(event.target.value)) {
                  formik.setFieldValue('label', event.target.value);
                }
              }}
              placeholder={``}
            />
            <ErrorMessage
              name={'label'}
              className={'text-tw-red-dd text-xs pt-1'}
              component={'div'}
            />
            <div className={'grid grid-cols-3 gap-x-4 my-6'}>
              <div>
                <div className={'flex items-center'}>
                  <span className="my-2 text-tw-black-5">Data Type</span>
                  <InfoToolTip
                    label="The data type of the property."
                    width={'16px'}
                    height={'16px'}
                  />
                </div>
                <FormikSelect ph={`Select one`} name={'type'} virtualize options={hubspotType} />
              </div>
              <div>
                <div className={'flex items-center'}>
                  <div className="my-2 text-tw-black-5">Field Type</div>
                  <InfoToolTip
                    label="Controls how the property appears in Hubspot."
                    width={'16px'}
                    height={'16px'}
                  />
                </div>
                <FormikSelect
                  ph={`Select one`}
                  name={'fieldType'}
                  virtualize
                  options={hubspotFieldType}
                />
              </div>
            </div>
            <div className={'mb-20'}>
              <div className={'flex items-center'}>
                <div className="my-2 text-tw-black-5">Group Name</div>
                <InfoToolTip
                  label="A human-readable property name that will be shown in HubSpot."
                  width={'16px'}
                  height={'16px'}
                />
              </div>
              {hubspotObjectGroupDataLoading ? (
                <CircularProgress mt={4} isIndeterminate size="30px" />
              ) : (
                <FormikSelect
                  ph={`Select one`}
                  name={'groupName'}
                  virtualize
                  options={hubspotObjectGroupData?.results?.map((item) => ({
                    label: item.label,
                    value: item.name,
                  }))}
                />
              )}
            </div>
          </div>
          <hr className={'mt-2'} />
          <div className={'py-4 justify-content-end flex px-4'}>
            <TertiaryButton type={'button'} onClick={onclose}>
              Cancel
            </TertiaryButton>
            <PrimaryButton className={'ml-3'} type={'submit'}>
              Create field
            </PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};
