import { useClient } from 'api-client';
import { useQuery } from 'react-query';
import { useAuth } from './useAuth';

export default function useEntities() {
  const client = useClient();
  const { shouldFireQueries } = useAuth();
  return useQuery('entities', () => client.getEntities(), {
    enabled: shouldFireQueries,
  });
}
