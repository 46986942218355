import { ReactComponent as ConfettiSvg } from 'assets/images/confetti.svg';
import BlackRocket from 'assets/svg/components/black-rocket.svg';
import Card from 'ui-components/surfaces/cards/Card';
import GradientCircleProgressbar from 'ui-components/feedback/Progress/GradientCircleProgressbar';
import { leadStatusGradientMap, leadStatusTextMap } from 'utils/common';
import useModels from 'hooks/useModels';
import { Entity } from 'pages/users-and-accounts/users/types';
import * as React from 'react';
import { useCallback } from 'react';
import { QueryType } from 'stores/QueryStore';
import useEntityDetails from '../hooks/useAccountDetails';

interface ToplyneStatsData {
  scores: {
    [key: string]: any;
  };
  queryType: QueryType;
  id: string | number;
  entity: Entity;
}

const ToplyneStats: React.FC<ToplyneStatsData> = (props) => {
  const { data: modelData } = useModels();
  const { queryType, entity, id } = props;
  const { data: entityDetailsData, isLoading: entityDetailsDataLoading } = useEntityDetails(
    entity,
    id
  );

  const getScore = useCallback(
    (modelId: string) => {
      //hack to get details accessor, userDetails / accountDetails
      return entityDetailsData?.[`${entity.slice(0, -1)}Details`]?.find(
        (v) => v.key === `${modelId}_score`
      )?.value;
    },
    [entityDetailsData, entity]
  );

  const getLeadStatus = useCallback(
    (modelId: string) => {
      return entityDetailsData?.[`${entity.slice(0, -1)}Details`].find(
        (item: { [key: string]: string }) => item.key === `${modelId}_lead_status`
      )?.value;
    },
    [entityDetailsData, entity]
  );

  return (
    <>
      <Card hover={false} className="rounded" style={{ border: 'none' }}>
        <div className={'flex justify-center'}>
          <img src={BlackRocket} alt={'toplyne-score'} />
        </div>
        <div className={'justify-center flex my-2'}>
          <p className={'font-normal text-xl'}>Toplyne Scores</p>
        </div>
        {entityDetailsData && modelData ? (
          <>
            {!entityDetailsDataLoading &&
              modelData
                ?.filter((d) => d.entity === queryType.toLowerCase() && d.active && d.dsEnabled)
                .map(({ goal, modelId, name }, index: number) => {
                  let value = getScore(modelId);
                  return (
                    <div key={`${goal}-${index}`}>
                      {value === -1 ? (
                        <div className="box-border relative h-full px-2 py-4 mt-4 overflow-auto border-dashed rounded-lg border-1 border-tw-gray-c">
                          <div className="flex items-center py-2">
                            <div className="px-2">
                              <ConfettiSvg />
                            </div>
                            <div className="ml-4">
                              <h4 className="mb-3 font-medium">{`${name} Score`}</h4>
                              <span className="p-1 rounded text-tw-black-7 bg-tw-green-e7">
                                GOAL MET 🎉
                              </span>
                              <p className="mt-3 text-xs">
                                This {queryType === QueryType.users ? 'user' : 'account'} will no
                                longer be scored for this goal
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="box-border relative h-full px-2 py-4 mt-4 overflow-auto rounded-lg border-1 border-tw-gray-c">
                          <div className="flex items-center">
                            <GradientCircleProgressbar
                              percentage={value * 100}
                              primaryColor={leadStatusGradientMap.get(getLeadStatus(modelId))}
                              secondaryColor={'#F5F5F5'}
                              strokeWidth={7}
                              fontSize={'14px'}
                              fontWeight={500}
                              width={95}
                              title={String(value ? value.toFixed(3) : '--')}
                            />
                            <div className={'ml-4'}>
                              <p className={'font-medium text-base text-tw-black-3 mb-1'}>
                                {`${name} Score`}
                              </p>
                              <p className={'text-tw-black-7 text-sm font-medium'}>
                                {leadStatusTextMap.get(getLeadStatus(modelId))}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
          </>
        ) : (
          <>
            <div className={'animate-pulse'}>
              <div className="h-24 mb-4 bg-gray-100 rounded" />
              <div className="h-24 bg-gray-100 rounded" />
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default React.memo(ToplyneStats);
