import { IntegrationType, MetaResponse, useClient } from 'api-client';
import { useAuth } from 'hooks/useAuth';
import isEmpty from 'lodash/isEmpty';
import { goalToVerbMap } from 'pages/opportunities/hooks/useHistogramFilter';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Filter } from 'stores/QueryStore';
import { ExportsEntity, OpportunityResponse } from 'ui-components/business/opportunities/types';
import { capitalizeFirstLetter, convertObjectKeysFromCamelToSnake } from 'utils/common';
import { Status } from '../../../hooks/use-async';
import { useAuthStore } from '../../../hooks/useAuthStore';
import { DefinePQLSections, useDefinePQLStore } from '../store/PQLDefineStore';
import {
  ExportPQLSections,
  scheduleInitialState,
  useExportPQLStore,
} from '../store/PQLExportStore';
import {
  ExportCategory,
  FiltersPQLSections,
  LeadStatus,
  useFiltersPQLStore,
} from '../store/PQLFiltersStore';
import { PQLWorkflowSteps, usePQLWorkflowStore } from '../store/PQLWorkflowStore';

const getLeadStatus = (leadStatus: LeadStatus[]) => {
  if (leadStatus?.length === 3 || !leadStatus?.length) {
    return [LeadStatus.ALL];
  }
  return leadStatus;
};

export function useCheckOpportunityCanBeDuplicated(
  opportunity: OpportunityResponse,
  meta: MetaResponse
) {
  const router = useHistory();
  const { getAccountId } = useAuthStore();

  const check = useCallback(() => {
    const columnNames = opportunity && [
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...opportunity?.query.filter.map((f) => f.columnName),
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...opportunity?.exports[0].fieldMapping.map((f) => f.columnName),
    ];

    const columnNamesToCheckAgainst = meta?.sourceMeta.data.map((m) => m.COLUMN_NAME);

    if (!columnNamesToCheckAgainst || !columnNames) {
      return Status.PENDING;
    }

    const containsAll = columnNames.every((element) => {
      return columnNamesToCheckAgainst.includes(element);
    });

    if (!containsAll) {
      router.push(`/${getAccountId()}/opportunities/list?duplicateError=true`);
    }
    if (containsAll) {
      return Status.SUCCESS;
    }
    return Status.ERROR;
  }, [meta?.sourceMeta.data, opportunity, router]);

  return { check };
}

function getExportCategory(exportData: ExportsEntity) {
  if (exportData.filter?.limit?.by === 'count') {
    return ExportCategory.SALES;
  } else if (exportData.filter?.limit?.by === 'percentage') {
    return ExportCategory.PERCENTAGE;
  }

  return null;
}

export default function useOpportunity(oppId: string, enabled?: boolean) {
  const client = useClient();
  const { user } = useAuth();
  const { hydrate: hydrateDefineStore } = useDefinePQLStore();
  const { hydrate: hydrateFiltersStore } = useFiltersPQLStore();
  const { hydrate: hydrateExportStore } = useExportPQLStore();
  const { setCompletedSteps, setCurrentStep } = usePQLWorkflowStore();

  return useQuery(['opportunity', 'newpqlworkflow', oppId], () => client.getOpportunity(oppId), {
    enabled: !!oppId && (enabled ?? true),
    onSuccess: (data) => {
      const exports = data.exports[0];
      hydrateDefineStore({
        modelId: data.query.modelId,
        targetEntity: data.query.type,
        showSampleProfiles: data.query.sampleProfiles,
        // OVERRIDE_ENABLE_SYSTEM_COHORTS
        currentSection: DefinePQLSections.GOALS,
        // OVERRIDE_ENABLE_SYSTEM_COHORTS
        sectionsMap: {
          [DefinePQLSections.ENTITIES]: true,
          [DefinePQLSections.GOALS]: false,
          [DefinePQLSections.CONVERSION_EVENT]: false,
          [DefinePQLSections.COHORT]: false,
          [DefinePQLSections.HWC]: false,
        },
        performanceTrackEvent: data.query?.performanceTrackEvent || null,
        cohorts: data.cohorts?.map((c) => c.id),
        leadStatus: getLeadStatus(exports.filter.leadStatus as LeadStatus[]),
        gtmStrategy: !isEmpty(data.gtmStrategy)
          ? data.gtmStrategy[Object.keys(data.gtmStrategy)[0]]
          : {},
      });

      let exportCategory = getExportCategory(exports);

      let creationTimeFilter: Filter;
      const filters = data.query.filter;
      if (data.query.time) {
        creationTimeFilter = {
          keyName: `${data.query.type.toLowerCase().slice(0, -1)}_creation_time`,
          source: 'toplyne',
          keySpace: 'profile_properties',
          columnName: `${data.query.type.slice(0, -1)}_CREATION_TIME`,
          displayName: `${capitalizeFirstLetter(data.query.type.slice(0, -1))}Creation Time`,
          op: data.query.time.relative ? 'inthelast' : 'during',
          value: data.query.time.relative
            ? {
                timeType: data.query.time.relative.timeUnit,
                start: data.query.time.relative.start,
                end: data.query.time.relative.end,
              }
            : ({
                start: data.query.time.fixed.start,
                end: data.query.time.fixed.end,
              } as any),
        };
        filters.push(creationTimeFilter as any);
      }

      const filtersToHydrate = filters?.map((f) => {
        if (f?.columnName?.includes('LEAD_STATUS')) {
          const filterValue = f.value as string[];
          return {
            ...f,
            value: filterValue?.map((v) => {
              if (v === '-1') {
                return goalToVerbMap[data?.query?.goal?.toLowerCase()];
              }
              return v;
            }),
          };
        }
        return f;
      });

      hydrateFiltersStore({
        overviewOpen: false,
        filters: filtersToHydrate,
        filtersOpen: true,
        searchQuery: '',
        currentSection: FiltersPQLSections.CAPACITY,
        exportCategory: exportCategory,
        sectionsMap: {
          [FiltersPQLSections.CAPACITY]: false,
          [FiltersPQLSections.FILTERS]: true,
        },
        filtersListOpen: [],
        salesCapacity:
          exportCategory === ExportCategory.SALES && (exports.filter?.limit?.end || NaN),
        salesCustomValue:
          exportCategory === ExportCategory.SALES &&
          ![100, 500, 1000].includes(exports.filter?.limit?.end)
            ? exports.filter?.limit?.end
            : null,
        percentage:
          exportCategory === ExportCategory.PERCENTAGE && (exports.filter.limit.end || NaN),
        filtersActiveTab: null,
        controlGroup: exports.controlGroup
          ? {
              enabled: true,
              value: exports.controlGroup.metadata.percentage,
              hasChanged: true,
            }
          : {
              enabled: false,
              value: null,
              hasChanged: false,
            },
      });

      const uniqueKeys = exports.fieldMapping.filter((f) => f.isUniqueKey);

      const mappings = exports.fieldMapping.filter((f) => !f.isUniqueKey);

      hydrateExportStore({
        currentSection: ExportPQLSections.SCHEDULE,
        sectionsMap: {
          [ExportPQLSections.SCHEDULE]: false,
          [ExportPQLSections.DESTINATION]: true,
          [ExportPQLSections.MAPPINGS]: true,
        },
        destinationSearchQuery: '',
        destinationId: exports.destination.id,
        tags: Object.keys(exports.tags).map((t) => {
          return {
            name: t,
            value: exports.tags[t],
          };
        }),
        mappings: mappings.map((m) => {
          return {
            source: m.columnName,
            destination: m.keyAlias,
          };
        }),
        object: exports.destination.integration.supportsEntities
          ? exports.destination.integration.type === IntegrationType.TRAY
            ? exports.metadata[exports.destination.type]['entity']
            : (exports.metadata[exports.destination.type][
                Object.keys(exports.metadata[exports.destination.type])[0]
              ] as any)
          : null,
        subObject:
          exports.destination.integration.type === IntegrationType.TRAY
            ? exports.metadata[exports.destination.type]['subEntity']
            : null,
        uniqueKeys: uniqueKeys
          ? uniqueKeys.map((uniqueKey) => {
              return {
                source: uniqueKey.columnName,
                destination: uniqueKey.keyAlias,
              };
            })
          : null,
        schedule: scheduleInitialState,
        extraFormData: {
          ...convertObjectKeysFromCamelToSnake(exports.metadata[exports.destination.type]),
        } as any,
        name: `${data.name} (duplicate)`,
        emails: (exports?.metadata?.email as string[])?.length
          ? (exports.metadata.email as string[])
          : [user.email],
        syncOverviewOpen: false,
      });

      setCompletedSteps({
        [PQLWorkflowSteps.DEFINE]: true,
        [PQLWorkflowSteps.FILTERS]: true,
        [PQLWorkflowSteps.EXPORT]: true,
      });

      setCurrentStep(null);
    },
  });
}
