import { MailIcon, ToplyneLogo } from 'ui-components/data-display/Icons';
import { BackButton } from 'ui-components/inputs/Buttons';
import { FC } from 'react';

interface ResetPasswordEmailConfirmProps {
  location: any;
}

const ResetPasswordEmailConfirm: FC<ResetPasswordEmailConfirmProps> = (props) => {
  return (
    <div className="h-screen py-4 pl-4">
      <ToplyneLogo className="h-auto w-36" />
      <div className="my-4 h-88">
        <div className="w-36% mx-auto rounded-lg border-1 border-tw-gray-eb px-12 pt-12 h-full">
          <MailIcon className="mx-auto" />
          <div className="mt-6">
            <div className="text-center">
              <h3 className="text-3xl font-medium text-tw-black-3">Check your mail</h3>
              <p className="w-3/4 mx-auto mt-4 text-sm text-tw-black-5">
                We have sent a password reset link to&nbsp;
                {props.location.state.email}
              </p>
            </div>
          </div>
          <div className="mt-8 flex justify-center">
            <BackButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordEmailConfirm;
