import orderBy from 'lodash/orderBy';
import isNil from 'lodash/isNil';
import { trimLChar } from '../../../utils/common';

export enum ATTRIBUTE_CONST {
  PRODUCT_EVENTS = 'profileEvents',
  BILLING = 'billing',
  PROFILE_PROPERTIES = 'profileProperties',
}

const isEmpty = (val) => {
  if (isNil(val) || val === '') {
    return true;
  }
  return false;
};

export const getRawKey = (key) => {
  if (key.substr(0, Math.min(key.length, 13)) === 'USER_PROPERTY') {
    key = key.replace('USER_PROPERTY', '');
  }
  if (key.substr(0, Math.min(key.length, 7)) === 'billing') {
    key = key.replace('billing', '');
  }
  key = trimLChar(key, '_');
  return key;
};

export const checkDsEnabled = (modelData) => {
  return modelData?.some((model) => model.active && model.dsEnabled);
};

export const processToplyneStats = (attributeDetails, toplyneStatsData) => {
  let _toplyneStatsData = toplyneStatsData;
  for (let val of attributeDetails) {
    let key = val['key'];
    let _toplyneStatsDataKey = Object.keys(toplyneStatsData).find(
      (_key) => toplyneStatsData[_key]['key'] === key
    );
    if (_toplyneStatsDataKey) {
      _toplyneStatsData[_toplyneStatsDataKey]['value'] = val['value'];
    }
  }
  return _toplyneStatsData;
};

export const processAttributeDetails = (
  attributeDetails,
  attributesPreferenceBase,
  toplyneMetaData,
  heroContentData,
  billingHeroData
) => {
  let _productEventData = [],
    _billingData = [],
    _profilePropertiesData = [];
  let _heroContentData = heroContentData,
    _billingHeroData = billingHeroData;

  let profilePropertiesShowCount = 10,
    billingPropertyShowCount = 10,
    productEventsPropertyShowCount = 10;
  for (let val of attributeDetails) {
    let key = val['key'];
    if (Object.keys(heroContentData).includes(key)) {
      _heroContentData[key] = val['value'];
    }
    if (Object.keys(billingHeroData).includes(key)) {
      _billingHeroData[key] = val['value'];
    }

    key = getRawKey(key);
    let metaValue = toplyneMetaData.find(
      (item) => item.keyName.toLowerCase() === key.toLowerCase()
    );
    if (metaValue) {
      if (metaValue.keySpace === 'profile_properties') {
        let doShow = attributesPreferenceBase[ATTRIBUTE_CONST.PROFILE_PROPERTIES].includes(key);
        if (doShow) {
          profilePropertiesShowCount--;
        }
        _profilePropertiesData.push({ ...metaValue, value: val['value'], show: doShow });
      } else if (metaValue.keySpace === 'profile_events') {
        let doShow = attributesPreferenceBase[ATTRIBUTE_CONST.PRODUCT_EVENTS].includes(key);
        if (doShow) {
          productEventsPropertyShowCount--;
        }
        _productEventData.push({ ...metaValue, value: val['value'], show: doShow });
      } else {
        let doShow = attributesPreferenceBase[ATTRIBUTE_CONST.BILLING].includes(key);
        if (doShow) {
          billingPropertyShowCount--;
        }
        _billingData.push({ ...metaValue, value: val['value'], show: doShow });
      }
    }
  }
  _heroContentData['source'] = toplyneMetaData[0].source;
  _productEventData.sort((a, b): number => {
    const emptyA = isEmpty(a.value);
    const emptyB = isEmpty(b.value);
    if (emptyA && emptyB) {
      return 0;
    }
    if (emptyA) {
      return 1;
    }
    if (emptyB) {
      return -1;
    }
    return b.value - a.value;
  });

  // first order the ones, those have some value alphabetically then the rest alphabetically
  let _profilePropertiesWithValue = _profilePropertiesData.filter(({ value }) => value);
  let _profilePropertiesWithoutValue = _profilePropertiesData.filter(({ value }) => !value);
  _profilePropertiesWithValue = orderBy(
    _profilePropertiesWithValue,
    [({ displayName }) => displayName.toLowerCase()],
    ['asc']
  );
  _profilePropertiesWithoutValue = orderBy(
    _profilePropertiesWithoutValue,
    [({ displayName }) => displayName.toLowerCase()],
    ['asc']
  );
  _profilePropertiesData = [..._profilePropertiesWithValue, ..._profilePropertiesWithoutValue];
  _billingData = orderBy(_billingData, ['value'], ['desc']);
  // adding extra attributes to show in case user has no selections

  return {
    _profilePropertiesData,
    _billingData,
    _productEventData,
    profilePropertiesShowCount,
    billingPropertyShowCount,
    productEventsPropertyShowCount,
    _heroContentData,
    _billingHeroData,
  };
};

export const setDefaultAttributes = (
  profilePropertiesData,
  billingData,
  productEventData,
  profilePropertiesShowCount,
  billingPropertyShowCount,
  productEventsPropertyShowCount,
  attributesPreferenceBase
) => {
  let temporarySelections = {
    [ATTRIBUTE_CONST.PRODUCT_EVENTS]: [],
    [ATTRIBUTE_CONST.BILLING]: [],
    [ATTRIBUTE_CONST.PROFILE_PROPERTIES]: [],
  };
  for (
    let i = 0;
    profilePropertiesShowCount > 0 &&
    i < profilePropertiesData.length &&
    attributesPreferenceBase[ATTRIBUTE_CONST.PROFILE_PROPERTIES].length === 0;
    i++
  ) {
    if (!profilePropertiesData[i]['show']) {
      profilePropertiesData[i]['show'] = true;
      temporarySelections[ATTRIBUTE_CONST.PROFILE_PROPERTIES].push(
        profilePropertiesData[i]['keyName']
      );
      profilePropertiesShowCount--;
    }
  }
  for (
    let i = 0;
    billingPropertyShowCount > 0 &&
    i < billingData.length &&
    attributesPreferenceBase[ATTRIBUTE_CONST.BILLING].length === 0;
    i++
  ) {
    if (!billingData[i]['show']) {
      billingData[i]['show'] = true;
      temporarySelections[ATTRIBUTE_CONST.BILLING].push(billingData[i]['keyName']);
      billingPropertyShowCount--;
    }
  }
  for (
    let i = 0;
    productEventsPropertyShowCount > 0 &&
    i < productEventData.length &&
    attributesPreferenceBase[ATTRIBUTE_CONST.PRODUCT_EVENTS].length === 0;
    i++
  ) {
    if (!productEventData[i]['show']) {
      productEventData[i]['show'] = true;
      temporarySelections[ATTRIBUTE_CONST.PRODUCT_EVENTS].push(productEventData[i]['keyName']);
      productEventsPropertyShowCount--;
    }
  }

  return { productEventData, billingData, profilePropertiesData, temporarySelections };
};
