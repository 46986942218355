import { useClient } from 'api-client';
import { capitalizeColumnName } from 'utils/common';
import { useAuth } from 'hooks/useAuth';
import useModels from 'hooks/useModels';
import { goalToVerbMap } from 'pages/opportunities/hooks/useHistogramFilter';
import { useQuery } from 'react-query';
import { Filter, QueryType } from 'stores/QueryStore';
import { useDefinePQLStore } from '../store/PQLDefineStore';
import { useFiltersPQLStore } from '../store/PQLFiltersStore';
import useDsEnabled from '../../../hooks/useDsEnabled';

export type OpportunityQueryPayload = {
  query: Record<string, any>;
};

export const replaceValueForLeadStatus = (filters: Filter[]) => {
  return filters.map((filter) => {
    if (filter?.columnName?.includes('LEAD_STATUS')) {
      const filterValue = filter.value as string[];
      return {
        ...filter,
        value: filterValue?.map((v) => {
          if (Object.values(goalToVerbMap).includes(v)) {
            return '-1';
          }
          return v;
        }),
      };
    }
    return filter;
  });
};

export default function useOpportunityQuery(keepPreviousData = true) {
  const { targetEntity, modelId, showSampleProfiles, cohorts } = useDefinePQLStore();
  const { filters } = useFiltersPQLStore();
  const client = useClient();

  const { data: models } = useModels();

  const dsEnabled = useDsEnabled(modelId, targetEntity);
  const { user } = useAuth();

  // also send goal with the query API
  const goal = models
    ?.find((m) => m.modelId === modelId && m.entity === targetEntity.toLocaleLowerCase())
    ?.goal?.toLocaleUpperCase();

  let fieldMapping = dsEnabled
    ? [
        dsEnabled && {
          keyAlias: `${modelId}_score`,
          keyName: `${modelId}_score`,
          keySpace: 'profile_properties',
          source: 'toplyne',
          columnName: `${modelId}_score`.toUpperCase(),
        },
      ]
    : [];

  if (user?.currentOrganization.userEmailField && targetEntity === QueryType.users) {
    fieldMapping = [
      ...fieldMapping,
      {
        keyAlias: 'email',
        keyName: user?.currentOrganization.userEmailField,
        keySpace: 'profile_properties',
        source: 'toplyne',
        columnName: capitalizeColumnName(user?.currentOrganization.userEmailField),
      },
    ];
  }

  fieldMapping = [
    ...fieldMapping,
    ...filters.map((f) => ({
      keyAlias: f.columnName,
      keyName: f.keyName,
      keySpace: f.keySpace,
      source: f.source,
      columnName: f.columnName,
    })),
  ];

  const payload: OpportunityQueryPayload = {
    query: {
      type: targetEntity,
      modelId: modelId,
      sampleProfiles: showSampleProfiles,
      filter: replaceValueForLeadStatus(filters),
      cohorts,
      field_mapping: fieldMapping,
      goal,
    },
  };
  return useQuery(
    [
      'oppquery',
      'newpqlworkflow',
      targetEntity,
      modelId,
      goal,
      showSampleProfiles,
      JSON.stringify(filters.map((f) => `${f.columnName}_${f.op}_${JSON.stringify(f.value)}`)),
      JSON.stringify(cohorts),
    ],
    () => client.getOpportunityQuery(payload),
    {
      enabled: !!targetEntity && !!modelId,
      keepPreviousData: keepPreviousData,
    }
  );
}
