import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function OAuthCallbackPage() {
  const { type } = useParams<{ type: string }>();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const opener = window.opener;

    if (opener) {
      opener.postMessage(
        {
          type,
          code: params.get('code'),
        },
        '*'
      );
    }
    window.close();
  });

  return <p>Authorization successful. You may close this window</p>;
}
