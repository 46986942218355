import create from 'zustand';
import { devtools } from 'zustand/middleware';

type GlobalStoreState = {
  // page -> state
  opportunityDetails: {
    activeStatsTab: number;
  };
};

type GlobalStore = {
  setActiveStatsTab: (tabIndex: number) => void;
} & GlobalStoreState;

const initialState: GlobalStoreState = {
  opportunityDetails: {
    activeStatsTab: 0,
  },
};

export const useGlobalStore = create<GlobalStore>(
  devtools((set) => {
    return {
      ...initialState,
      setActiveStatsTab: (tabIndex: number) => {
        set((state) => ({
          ...state,
          opportunityDetails: {
            activeStatsTab: tabIndex,
          },
        }));
      },
    };
  })
);
