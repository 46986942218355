import { IntegrationType } from '../../../api-client';
import Pipedrive from 'assets/images/pipedrive.svg';
import Salesforce from 'assets/images/salesforce.svg';
import Slack from 'assets/images/slack.svg';
import Email from 'assets/images/email.svg';
import HubSpot from 'assets/images/hubspot.svg';
import Marketo from 'assets/images/marketo.svg';
import GainSight from 'assets/images/gainsight.svg';
import Webhook from 'assets/images/webhook.svg';

const renderIcon = (src: string) => {
  // eslint-disable-next-line react/display-name
  return () => <img alt="int" src={src} style={{ marginRight: 5, maxWidth: 20 }} />;
};

export const returnDestinationIcon = (src: string, logoUrl: string) => {
  switch (src) {
    case IntegrationType.EMAIL:
      return renderIcon(Email);
    case IntegrationType.SALESFORCE:
      return renderIcon(Salesforce);
    case IntegrationType.SLACK:
      return renderIcon(Slack);
    case IntegrationType.PIPEDRIVE:
      return renderIcon(Pipedrive);
    case IntegrationType.HUBSPOT:
      return renderIcon(HubSpot);
    case IntegrationType.GAINSIGHT:
      return renderIcon(GainSight);
    case IntegrationType.MARKETO:
      return renderIcon(Marketo);
    case IntegrationType.WEBHOOK:
      return renderIcon(Webhook);
    case IntegrationType.SALESFORCE_SANDBOX:
      return renderIcon(Salesforce);
    case IntegrationType.TRAY:
      return renderIcon(logoUrl);
  }
};

export const pipeDriveFields = [
  { label: 'Varchar', value: 'varchar' },
  { label: 'Phone', value: 'phone' },
  { label: 'Date', value: 'date' },
  { label: 'Organisation', value: 'org' },
  { label: 'User', value: 'user' },
  { label: 'Double', value: 'double' },
  { label: 'Integer', value: 'int' },
  { label: 'Picture', value: 'picture' },
];

export const hubspotType = [
  { label: 'String', value: 'string' },
  { label: 'Number', value: 'number' },
  { label: 'Date', value: 'date' },
  { label: 'Date Time', value: 'datetime' },
];

export const hubspotFieldType = [
  { label: 'Text Area', value: 'textarea' },
  { label: 'Text', value: 'text' },
  { label: 'Date', value: 'date' },
  { label: 'Number', value: 'number' },
];
