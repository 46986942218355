import { useMutation, useQueryClient } from 'react-query';
import { useClient } from '../../../api-client';

export default function useDeleteIntegration() {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation((id: number | string) => client.deleteIntegration(id), {
    onSuccess: () => {
      queryClient.refetchQueries(['integrations']);
    },
  });
}
