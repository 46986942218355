import { Entity } from 'pages/users-and-accounts/users/types';
import { useQuery } from 'react-query';
import { useClient } from '../../../../api-client';

export default function useEntityDetails(entity: Entity, id: number | string) {
  const client = useClient();

  return useQuery([entity, 'details', id], () =>
    entity === Entity.accounts ? client.accountDetails(id) : client.userDetails(id)
  );
}
