import { useClient } from 'api-client';
import useConnectedIntegrations from 'hooks/useConnectedIntegrations';
import { useQuery } from 'react-query';
import { useExportPQLStore } from '../store/PQLExportStore';

export default function useConnectedIntegrationEntities() {
  const client = useClient();
  const { destinationId } = useExportPQLStore();
  const { data: connectedIntegrations } = useConnectedIntegrations(false);
  return useQuery(
    ['connectedintegration', 'pql', destinationId],
    () => client.connectedIntegrationEntities(destinationId),
    {
      enabled:
        !!destinationId &&
        //only fetch integration entities for crms
        connectedIntegrations?.find((i) => i.id === destinationId)?.integration.supportsEntities,
    }
  );
}
