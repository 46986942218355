import { useClient } from 'api-client';
import useDebounce from 'hooks/useDebounce';
import { useAuth } from 'hooks/useAuth';
import { useQuery } from 'react-query';
import { useOpportunityListStore } from '../store/OpportunityListStore';

export function useOpportunityStatusCount() {
  const client = useClient();
  const { shouldFireQueries } = useAuth();
  const { queryParams } = useOpportunityListStore();
  const debouncedSearchQuery = useDebounce(queryParams.query);

  return useQuery(
    ['opportunities', 'count', debouncedSearchQuery, queryParams.destinations],
    () => client.opportunitiesStatusCount({ queryParams }),
    {
      enabled: shouldFireQueries,
    }
  );
}
