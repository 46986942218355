import { useClient } from 'api-client';
import { GtmStrategy } from 'pages/pql-workflow/store/PQLDefineStore';
import { useMutation } from 'react-query';

type UpdatePQL = {
  opportunityId: number;
  gtmStrategy: GtmStrategy;
};

export default function useUpdatePQL() {
  const client = useClient();
  return useMutation((data: UpdatePQL) => {
    return client.updateOpportunity(data.opportunityId, { gtmStrategy: data.gtmStrategy });
  });
}
