import { useClient } from 'api-client';
import { useAuthStore } from 'hooks/useAuthStore';
import { useMutation, useQueryClient } from 'react-query';
import { QueryType } from 'stores/QueryStore';
import { ColumnType, useAccountsStore } from '../store/AccountsStore';
import { useUsersStore } from '../store/UsersStore';

export enum ModifyPersonaPropActions {
  ADD = 'add',
  REMOVE = 'remove',
}

export default function useModifyPersonaProperties() {
  const {
    addFieldMapping: addFieldMappingsForAccounts,
    removeFieldMapping: removeFieldMappingForAccounts,
  } = useAccountsStore();
  const {
    addFieldMapping: addFieldMappingsForUsers,
    removeFieldMapping: removeFieldMappingForUsers,
  } = useUsersStore();
  const { getAccountId } = useAuthStore();

  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    (data: {
      personaProps: Array<string>;
      orgSchemaName: string;
      action: ModifyPersonaPropActions;
      entityType: QueryType;
      currentFieldMapping: ColumnType;
    }) => {
      const { personaProps, orgSchemaName, entityType } = data;
      const payload =
        entityType === QueryType.users
          ? {
              userPersonaProperties: personaProps,
            }
          : {
              accountPersonaProperties: personaProps,
            };

      return client.modifyPersonaProps(payload, orgSchemaName);
    },
    {
      onMutate: (variables) => {
        const { action, entityType, currentFieldMapping } = variables;
        const { keyName, keySpace, displayName, columnName, source, dataType, isSystem } =
          currentFieldMapping;
        if (action === ModifyPersonaPropActions.ADD) {
          if (entityType === QueryType.users) {
            addFieldMappingsForUsers({
              keyAlias: keyName,
              keyName,
              source,
              keySpace,
              displayName,
              columnName,
              dataType,
              isSystem,
            });
          } else {
            addFieldMappingsForAccounts({
              keyAlias: keyName,
              keyName,
              source,
              keySpace,
              displayName,
              columnName,
              dataType,
              isSystem,
            });
          }
        } else {
          if (entityType === QueryType.users) {
            removeFieldMappingForUsers(keyName);
          } else {
            removeFieldMappingForAccounts(keyName);
          }
        }
      },
      onError: (error, variables) => {
        const { entityType, currentFieldMapping } = variables;
        const { keyName } = currentFieldMapping;

        if (entityType === QueryType.users) {
          removeFieldMappingForUsers(keyName);
        } else {
          removeFieldMappingForAccounts(keyName);
        }
      },
      onSettled: () => {
        // invalidate user query.
        queryClient.invalidateQueries(['user', getAccountId()]);
      },
    }
  );
}
