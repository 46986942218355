import { useClient } from 'api-client';
import { useAuth } from 'hooks/useAuth';
import { useQuery } from 'react-query';

export function useRangeOpportunityStatusCount(dateRange: { from: string; to: string }) {
  const client = useClient();
  const { shouldFireQueries } = useAuth();

  return useQuery(
    ['opportunities', 'count', dateRange],
    () => client.opportunitiesStatusCount({ dateRange }),
    {
      enabled: shouldFireQueries,
    }
  );
}
