import { useEffect, useMemo, useState } from 'react';
import { Roles } from '../pages/settings/Roles';
import { useAuthStore } from './useAuthStore';

export default function useRole() {
  const { user } = useAuthStore();
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [isReadAndWrite, setIsReadAndWrite] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const role = user?.currentRole?.role;
  useEffect(() => {
    if (role === Roles.READ_ONLY) {
      setIsReadOnly(true);
    } else if (role === Roles.READ_AND_WRITE) {
      setIsReadAndWrite(true);
    } else if (role === Roles.ADMIN) {
      setIsAdmin(true);
    }
  }, [role]);

  return useMemo(
    () => ({ isReadOnly, isReadAndWrite, isAdmin }),
    [isReadOnly, isReadAndWrite, isAdmin]
  );
}
