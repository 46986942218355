import { useMutation } from 'react-query';
import { toast as toastify } from 'react-toastify';
import { useClient } from '../../../api-client';
import { useHistory } from 'react-router-dom';
import { useAnalytics } from '../../../telemetry';
import { AnalyticsEvents } from '../../../telemetry/constants';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';

type ChangePasswordData = {
  currentPassword: string;
  newPassword: string;
};

export default function useChangePassword() {
  const router = useHistory();
  const client = useClient();
  const { track } = useAnalytics();
  return useMutation(
    (data: ChangePasswordData) => client.changePassword(data.currentPassword, data.newPassword),
    {
      onSuccess: () => {
        toastify(
          <SuccessToast description="Your password has been updated changed successfully" />,
          {
            type: 'success',
          }
        );
        router.goBack();
      },
      onError: (error) => {
        const err = error['response']['data']['newPassword'];
        toastify(<ErrorToast description={err ?? 'Uh oh! Something went wrong'} />, {
          type: 'error',
        });
        track(AnalyticsEvents.USER_PASSWORD_CHANGED_FAILED);
      },
    }
  );
}
