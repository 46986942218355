import { LastRun, StatsUser } from '../../ui-components/data-display/Icons';
import { capitalizeFirstLetter, classNames, convertInOrgTimezone } from '../../utils/common';
import { OpportunityStatus } from '../../api-client/index';
import { getCadenceType, CadenceType } from '../opportunities/DisplayPQLCadence';

import { PQL_STATS_PAGE_PADDING_CLASSNAME } from './NewOpDetails';
import useAuthUser from 'hooks/useAuthUser';

function getCadence(schedule) {
  if (schedule.recurring) {
    return `${capitalizeFirstLetter(Object.keys(schedule.recurring?.frequency)[0])} recurring`;
  }
  return 'One time';
}

function getPQLDisplayStatus(status) {
  if (status === OpportunityStatus.SCHEDULED) {
    OpportunityStatus.PENDING;
  }
  return status;
}

const statusColorMap = {
  active: '#44AF34',
  failed: '#DD3C3C',
};

const DotCircle = (props: { color: string; size: number }) => {
  return (
    <div
      className={`w-${props.size} h-${props.size}`}
      style={{ backgroundColor: props.color, borderRadius: '50%' }}
    />
  );
};

function getPQLRunScheduleDetails(opportunity) {
  const { data: userData } = useAuthUser(false);
  const isOneTime = getCadenceType(opportunity?.schedule) === CadenceType.ONE_TIME;
  if (isOneTime) {
    return (
      <div className="flex text-tw-black-5">
        {opportunity?.lastRun && (
          <span className="mr-2">{`Last run ${convertInOrgTimezone(
            opportunity?.lastRun,
            userData?.currentOrganization?.timezone
          )}`}</span>
        )}
        {opportunity.status === OpportunityStatus.SCHEDULED &&
          !opportunity?.lastRun &&
          opportunity?.nextRun && (
            <span className="mr-2">{`First run at ${convertInOrgTimezone(
              opportunity?.nextRun,
              userData?.currentOrganization?.timezone
            )}`}</span>
          )}
      </div>
    );
  }
  return (
    <div className="flex text-tw-black-5 gap-x-2">
      {opportunity?.lastRun && (
        <div className="flex gap-x-1">
          <span className="mr-1">{`Last run ${convertInOrgTimezone(
            opportunity?.lastRun,
            userData?.currentOrganization?.timezone
          )}`}</span>
          {opportunity.nextRun && (
            <div className="mt-2">
              <DotCircle color="#777777" size={1} />
            </div>
          )}
        </div>
      )}
      {opportunity?.nextRun && (
        <span className="mr-2">{`${
          opportunity.status === OpportunityStatus.SCHEDULED ? 'First' : 'Next'
        } run ${convertInOrgTimezone(
          opportunity?.nextRun,
          userData?.currentOrganization?.timezone
        )}`}</span>
      )}
    </div>
  );
}

const getOpportunityCreatedBy = (opportunity) => {
  if (opportunity.destination?.name === 'Sandbox Salesforce' && opportunity?.isSample) {
    return 'janedoe@toplyne.io';
  } else if (opportunity.destination?.name === 'Sandbox CustomerIO' && opportunity?.isSample) {
    return 'johndoe@toplyne.io';
  }

  return opportunity?.createdBy?.email || '--';
};

export default function PQLRunData(props) {
  const { data: userData } = useAuthUser(false);
  const { opportunity } = props;
  const pqlCreatedBy =
    opportunity.createdBy?.firstName && opportunity.createdBy?.lastName
      ? opportunity.createdBy?.firstName + ' ' + opportunity.createdBy?.lastName
      : opportunity.createdBy?.email;
  return (
    <div className={classNames(PQL_STATS_PAGE_PADDING_CLASSNAME)}>
      <hr className="border-b border-tw-gray-eb" />
      <div className="w-full py-8 z-5 h-100">
        <div className="flex items-start gap-x-3">
          <div className="bg-tw-gray-f5 text-tw-black-5 font-medium  px-3 py-2 rounded-[40px]">
            <span>{getCadence(opportunity.schedule)}</span>
          </div>
          <div className="flex items-center gap-2 bg-tw-gray-f5 font-medium text-tw-black-5  px-4 py-2 rounded-[40px]">
            {statusColorMap[opportunity.status] && (
              <DotCircle color={statusColorMap[opportunity.status]} size={2} />
            )}
            <span className="">
              {capitalizeFirstLetter(getPQLDisplayStatus(opportunity.status))}
            </span>
          </div>
        </div>
        <div className="flex items-center mt-6 gap-x-2">
          <StatsUser className="w-5 h-5" />
          <div className="text-tw-black-5">
            {`Created by ${
              pqlCreatedBy || getOpportunityCreatedBy(opportunity)
            } on ${convertInOrgTimezone(
              opportunity.createdAt,
              userData?.currentOrganization?.timezone
            )}`}
          </div>
        </div>
        {(opportunity.lastRun || opportunity.nextRun) && (
          <div className="flex items-center mt-5 gap-x-2">
            <LastRun className="w-5 h-5" />
            {getPQLRunScheduleDetails(opportunity)}
          </div>
        )}
      </div>
    </div>
  );
}
