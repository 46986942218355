import { useAuth } from 'hooks/useAuth';
import { useAuthStore } from 'hooks/useAuthStore';
import { useQuery } from 'react-query';
import { IntegrationType, useClient } from '../api-client';

export default function useConnectedIntegrations(
  filtered: boolean = true,
  schemaName?: string,
  disabled?: boolean
) {
  const client = useClient();
  const { shouldFireQueries } = useAuth();
  const { getAccountSchemaName } = useAuthStore();

  const organizationSchemaName = schemaName || getAccountSchemaName();

  return useQuery(
    ['integrations', 'connected', organizationSchemaName],
    async () => {
      const data = await client.connectedIntegrations(organizationSchemaName);
      if (filtered) {
        return data.filter((i) => i.type !== IntegrationType.EMAIL);
      }
      return data;
    },
    {
      enabled: shouldFireQueries && !disabled,
    }
  );
}
