import { useClient } from 'api-client';
import { useMutation } from 'react-query';

export default function useBulkStopPlaybooks() {
  const client = useClient();
  return useMutation((opportunityIds: number[]) => {
    return client.bulkStopPlaybooks({
      opportunityIds,
    });
  });
}
