import { SmallCloseIcon } from '@chakra-ui/icons';
import { Tag, TagLabel, TagRightIcon } from '@chakra-ui/tag';
import { debounce } from 'lodash';
import * as React from 'react';
import { FC, useCallback, useEffect, useState } from 'react';
import { classNames } from '../../utils/common';
import { BACKSPACE, ENTER, INPUT_TAGS_MAX_COUNT, TAB } from '../../utils/globalTypes';

export interface InputProps {
  value: string | string[];
  onChange: (value: string[], cleanup?: Function | null) => void;
  ph?: string;
  removeTag?: (value: string) => void;
  containerStyle?: string;
  error?: boolean;
  isQuick?: boolean;
  onInput?: (value: string) => void; // to relay all input changes to its parent as they happen
  wrap?: boolean;
}

const InputWIthAddons: FC<InputProps> = (props) => {
  const { value, onChange, ph, removeTag, containerStyle = '', isQuick, onInput } = props;
  const tags = Array.isArray(value) ? value : null;
  const [inputValue, setInputValue] = useState<string>(null);

  useEffect(() => {
    if (!tags && value !== inputValue && typeof value === 'string') {
      setInputValue(value as string);
    }
  }, [value, inputValue, tags]);

  const debouncedChangeHandler = useCallback(
    debounce((val) => onInputChange(val), 500),
    []
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let targetVal = e.target.value;
    setInputValue(targetVal);
    onInput && onInput(targetVal);
    if (tags) {
      if (targetVal.includes(',') || targetVal.includes(' ')) {
        onInputChange(targetVal);
      }
      if (isQuick) {
        debouncedChangeHandler(targetVal);
      }
    } else {
      onChange(targetVal as any);
    }
  };

  const onInputChange = (inputVal: string) => {
    //@ts-ignore
    const inputTags = inputVal?.split(/[\s,]+/).filter(Boolean) || [];
    onChange(
      inputTags as any,
      tags ? (wrongTags: string[]) => setInputValue(wrongTags?.join(',') || '') : null
    );
  };

  const onKeyDown = (e: any) => {
    if (tags) {
      if (e.key === BACKSPACE && e.target.value === '') {
        const tagToRemove = tags.pop();
        removeTag && removeTag(tagToRemove);
      }
      if (e.key === ENTER || e.key === TAB) {
        onInputChange(inputValue);
        e.stopPropagation();
        e.preventDefault();
      }
    }
  };

  const onBlur = () => {
    if (tags) {
      onInputChange(inputValue);
    }
  };

  const isShowInput = tags ? tags.length < INPUT_TAGS_MAX_COUNT : true;

  return (
    <div
      className={classNames(
        'bg-white px-3 py-2.5 flex items-center gap-1 border-1 border-solid border-tw-gray-eb rounded-lg overflow-auto text-xs',
        containerStyle,
        props.wrap && `flex-wrap max-w-100`
      )}
      onKeyDown={onKeyDown}
    >
      {tags?.map((tag, index) => {
        return (
          <Tag key={index} className="px-2 py-1 text-xs tw-gray-eb foc " minW="auto">
            <TagLabel className="text-xs">{tag}</TagLabel>
            <TagRightIcon
              className="cursor-pointer"
              onClick={() => removeTag && removeTag(tag)}
              as={SmallCloseIcon}
            />
          </Tag>
        );
      })}
      {isShowInput && (
        <input
          className="w-full text-xs outline-none min-w-24"
          placeholder={ph}
          value={inputValue}
          onChange={handleChange}
          onBlur={onBlur}
        />
      )}
    </div>
  );
};

export default InputWIthAddons;
