import React from 'react';
import SuccessToast from 'assets/gifs/success_toast.gif';
import ErrorToast from 'assets/gifs/error_toast.gif';

export default function ToastIcons({
  type,
}: {
  type: 'info' | 'success' | 'warning' | 'error' | 'default';
}) {
  if (type === 'error') {
    return (
      <div>
        <img src={ErrorToast} className="w-12 h-12" />
      </div>
    );
  }

  if (type === 'success') {
    return (
      <div>
        <img src={SuccessToast} className="w-12 h-12" />
      </div>
    );
  }

  return (
    <div>
      <img src={ErrorToast} className="w-12 h-12" />
    </div>
  );
}
