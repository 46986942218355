import { OpportunityStatus } from 'api-client';
import useInterval from 'ui-components/business/navbars/hooks/useInterval';
import dayjs from 'dayjs';
import { useState } from 'react';
import { getIllustration } from '../../utils/helpers';
import { useRangeOpportunityStatusCount } from './hooks/useRangeOpportunitiesCount';

function getMessageText(date: Date) {
  if (dayjs(date).tz().hour() >= 6 && dayjs(date).tz().hour() < 12) {
    return 'Good Morning!';
  } else if (dayjs(date).tz().hour() >= 12 && dayjs(date).tz().hour() < 18) {
    return 'Good Afternoon!';
  } else if (dayjs(date).tz().hour() === 18 && dayjs(date).tz().minute() < 30) {
    return 'Good Afternoon!';
  }

  return 'Good Evening!';
}

function getScheduledPQLText(scheduledCount: { status: string; count: number } | null | undefined) {
  if (!scheduledCount) {
    return '0 playbook';
  }

  if (scheduledCount.count === 1) {
    return `1 playbook`;
  }

  return `${scheduledCount.count} playbooks`;
}

export default function Salutation() {
  const [date, setDate] = useState<Date>(dayjs().toDate());

  // update the date object every 30 mins
  useInterval(() => {
    setDate(dayjs().toDate());
  }, 1000 * 60 * 30);

  const { data, isLoading, isError } = useRangeOpportunityStatusCount({
    from: dayjs().startOf('week').format('YYYY-MM-DD'),
    to: dayjs().endOf('week').format('YYYY-MM-DD'),
  });

  return (
    <div className="2xl:px-0 px-8">
      <div
        className="inline-flex w-full text-2xl bg-tw-white-ff rounded-xl sm:h-40 2xl:h-60"
        style={{
          backgroundImage: `${getIllustration(date)}`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="ml-6">
          <div className="font-medium text-tw-white-ff sm:mt-6 2xl:mt-16">
            {getMessageText(date)}
          </div>
          {data && !isLoading && !isError && (
            <div className="mt-1 text-sm text-tw-white-ff">
              You have{' '}
              {getScheduledPQLText(
                data?.find(
                  (d) =>
                    d.status.toLowerCase() === OpportunityStatus.SCHEDULED ||
                    d.status.toLowerCase() === OpportunityStatus.ACTIVE ||
                    d.status.toLowerCase() === OpportunityStatus.RUNNING
                )
              )}{' '}
              running this week{' '}
              {`(${dayjs().startOf('week').format('MMM D')} - ${dayjs()
                .endOf('week')
                .format('MMM D')})`}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
