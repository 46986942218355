import { SideBorder } from 'ui-components/data-display/Icons';
import { useEffect, useState } from 'react';
import { Integration, IntegrationCategory, IntegrationType } from '../../../api-client';
import {
  checkIfDateIsOlderThanAMonth,
  classNames,
  cleanObject,
  transformText,
} from '../../../utils/common';
import useFilteredIntegrations from '../hooks/useFilteredIntegrations';
import useIntegrations from '../hooks/useIntegrations';
import { useIntegrationListStore } from '../stores/IntegrationListStore';

type IntegrationsFilterTabProps = {
  categories?: Array<string>;
  featuredItems?: Array<string>;
  activeIndex?: string;
  setActiveIndex?: (activeIndex: string) => void;
};

const IntegrationsFilterTab = ({ activeIndex, setActiveIndex }: IntegrationsFilterTabProps) => {
  const {
    availableIntegrationsBasedOnCategory,
    inactiveIntegrationsBasedOnCategory,
    connectedIntegrationsBasedOnCategory,
  } = useIntegrationListStore();
  const { data: integrations, isLoading: isIntegrationsLoading } = useIntegrations();

  const [featuredCategories, setFeaturedCategories] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    if (!isIntegrationsLoading && integrations?.length) {
      const mapIntegrations: Record<string, Integration[]> = integrations?.reduce(
        (acc, integration) => {
          if (!acc['Newly launched']) {
            acc['Newly launched'] = [];
          }
          if (
            checkIfDateIsOlderThanAMonth(integration?.createdAt) &&
            !acc['Newly launched'].includes(integration)
          ) {
            acc['Newly launched'].push(integration);
          }

          if (!acc['Popular']) {
            acc['Popular'] = [];
          }
          if (integration.popular && !acc['Popular'].includes(integration)) {
            acc['Popular'].push(integration);
          }

          return acc;
        },
        {} as any
      );

      setFeaturedCategories([...Object.keys(cleanObject(mapIntegrations))]);
    }
  }, [integrations, isIntegrationsLoading]);

  useEffect(() => {
    let categoryList = [];
    if (availableIntegrationsBasedOnCategory) {
      categoryList.push(...Object.keys(availableIntegrationsBasedOnCategory));
    }
    if (inactiveIntegrationsBasedOnCategory) {
      categoryList.push(...Object.keys(inactiveIntegrationsBasedOnCategory));
    }

    let tabNames = [...new Set([...categoryList])]?.filter((item) => item !== IntegrationType.API);

    tabNames = tabNames.filter((name) => {
      const filteredIntegrations = availableIntegrationsBasedOnCategory?.[name]?.filter(
        (i) => !i.default && i.type !== 'webhook' && i.available && i.supportsWrite && i.active
      );

      const connectedIntegrations = connectedIntegrationsBasedOnCategory?.[name]
        ?.filter((i) => i.type !== 'webhook')
        .filter((i) => !i.integration.categories.includes(IntegrationCategory.ANALYTICS));

      const inactiveIntegrations = inactiveIntegrationsBasedOnCategory?.[name];

      return (
        !!filteredIntegrations?.length ||
        !!connectedIntegrations?.length ||
        !!inactiveIntegrations?.length
      );
    });
    setCategories([...tabNames]);
  }, [
    availableIntegrationsBasedOnCategory,
    connectedIntegrationsBasedOnCategory,
    inactiveIntegrationsBasedOnCategory,
  ]);

  return (
    <div className="space-y-8">
      <div>
        <div className="ml-2 text-base not-italic font-medium uppercase text-tw-black-1">
          Featured
        </div>
        {!featuredCategories?.length ? (
          <div className="p-x-4 gap-y-4 animate-pulse">
            {Array(2)
              .fill(0)
              .map((v, idx) => (
                <div key={idx} className="w-3/4 h-4 mt-4 ml-3 bg-tw-gray-eb"></div>
              ))}
          </div>
        ) : (
          <FilterTabMenu
            categories={featuredCategories}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        )}
      </div>

      <div>
        <div className="ml-2 text-base not-italic font-medium uppercase text-tw-black-1">
          Categories
        </div>
        {!categories?.length ? (
          <div className="p-x-4 gap-y-4 animate-pulse">
            {Array(4)
              .fill(0)
              .map((v, idx) => (
                <div key={idx} className="w-3/4 h-4 mt-4 ml-3 bg-tw-gray-eb"></div>
              ))}
          </div>
        ) : (
          <FilterTabMenu
            categories={categories}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        )}
      </div>
    </div>
  );
};

export default IntegrationsFilterTab;

const FilterTabMenu = ({ categories, activeIndex, setActiveIndex }: IntegrationsFilterTabProps) => {
  const { categoriesMap } = useFilteredIntegrations();

  const integrationsLengthCategoryWise = (menuItem) => {
    if (['Newly launched', 'Popular'].includes(menuItem)) {
      return categoriesMap?.[menuItem]?.length;
    }
    return (
      categoriesMap?.[menuItem]?.['available']?.length +
      categoriesMap?.[menuItem]?.['requested']?.length +
      categoriesMap?.[menuItem]?.['inactive']?.length
    );
  };

  return (
    <ul className="mb-4 border-l border-tw-gray-eb">
      {categories.map((menuItem, index) => (
        <li
          key={index}
          className={classNames(
            'flex items-center cursor-pointer mt-3 gap-x-3',
            activeIndex === menuItem
              ? 'text-tw-blue-0d font-medium'
              : 'text-tw-black-5 font-regular'
          )}
          onClick={() => {
            setActiveIndex(menuItem);
          }}
        >
          <SideBorder
            className={classNames(
              'h-6 fill-current',
              activeIndex === menuItem ? 'text-tw-blue-0d' : 'text-transparent'
            )}
          />
          <span>
            {transformText(menuItem)}
            &nbsp; {`(${integrationsLengthCategoryWise(menuItem)})`}
          </span>
        </li>
      ))}
    </ul>
  );
};
