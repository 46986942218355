import { ExternalLink, WarningIconOutlined } from 'ui-components/data-display/Icons';
import { Slider } from 'ui-components/inputs/Slider';
import Toggle from 'ui-components/inputs/Switch';
import { AnimatePresence, motion } from 'framer-motion';
import { classNames } from 'utils/common';
import { usePQLWorkflowRoute } from 'pages/pql-workflow';
import useDsEnabled from 'hooks/useDsEnabled';
import useOpportunityQuery from 'pages/pql-workflow/hooks/useOpportunityQuery';
import useTotalUsersAggregate from 'pages/pql-workflow/hooks/useTotalUsersAggregate';
import { useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { ControlGroupState, useFiltersPQLStore } from 'pages/pql-workflow/store/PQLFiltersStore';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function ControlGroup() {
  const { controlGroup, setControlGroup } = useFiltersPQLStore();
  const { modelId, targetEntity, performanceTrackEvent } = useDefinePQLStore();
  const { isDuplicate } = usePQLWorkflowRoute();
  const { getTotalUsersAggregate } = useTotalUsersAggregate();
  const dsEnabled = useDsEnabled(modelId, targetEntity);
  const { data } = useOpportunityQuery();

  useEffect(() => {
    if (!isDuplicate && (dsEnabled || performanceTrackEvent)) {
      setControlGroup({
        ...controlGroup,
        enabled: data?.count > 5000,
        value: 10,
      });
    }
  }, [isDuplicate, dsEnabled, performanceTrackEvent]);

  const [isInputError, setIsInputError] = useState(false);

  const handleControlGroupChange = (value: Partial<ControlGroupState>) => {
    setControlGroup({
      ...controlGroup,
      ...value,
    });
  };

  useEffect(() => {
    if (controlGroup.enabled && isNaN(controlGroup.value)) setIsInputError(true);
    else setIsInputError(false);
  }, [controlGroup]);

  return (
    (dsEnabled || performanceTrackEvent) && (
      <div className="my-6">
        <div className="border-b border-dashed border-tw-gray-eb mb-7" />
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center gap-x-2">
            <p className="font-medium text-tw-black-1">Hold back a percentage as a control group</p>
            <Link to={{ pathname: 'https://docs.toplyne.io/docs/control-group' }} target={'_blank'}>
              <div className="flex items-center font-medium group text-tw-blue-0d gap-x-1">
                <span>Learn more</span>
                <ExternalLink className="hidden fill-current group-hover:block" />
              </div>
            </Link>
          </div>
          <Toggle
            enabled={controlGroup.enabled}
            setEnabled={() => handleControlGroupChange({ enabled: !controlGroup.enabled })}
            classNames={'product-tour-control-group'}
          />
        </div>
        <AnimatePresence initial={false}>
          {controlGroup.enabled && (
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
              className="flex items-center gap-x-2 product-tour-control-group"
            >
              <Slider
                value={controlGroup.value}
                onChange={(values) =>
                  handleControlGroupChange({ value: values[0], hasChanged: true })
                }
                tooltipContent={
                  controlGroup.value >= 90 ? (
                    <div className="flex items-center p-3 text-xs font-medium bg-white rounded-lg shadow-lg gap-x-1 text-tw-orange-de">
                      <WarningIconOutlined className="w-4 fill-current" />
                      <span>
                        You’re leaving out{' '}
                        {Math.floor(
                          (controlGroup.value * getTotalUsersAggregate()) / 100
                        ).toLocaleString()}{' '}
                        users ({controlGroup.value}%)
                      </span>
                    </div>
                  ) : null
                }
              />
              <span
                className={classNames(
                  'flex items-center w-10 px-1 border rounded  text-tw-black-3',
                  isInputError ? 'border border-tw-red' : 'border-tw-gray-eb'
                )}
              >
                <input
                  type="number"
                  value={isNaN(controlGroup.value) ? '' : controlGroup.value}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    if ((value < 100 && value >= 0) || isNaN(value))
                      handleControlGroupChange({ value, hasChanged: true });
                  }}
                  className="w-full py-1 focus:outline-none"
                />
                %
              </span>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    )
  );
}
