import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { DropdownArrowIcon } from 'ui-components/data-display/Icons';
import { classNames } from 'utils/common';
import { useCallback, useRef } from 'react';
import { useVirtual } from 'react-virtual';

type DropdownMenuItemProps = {
  label: string;
  value: string;
  disabled?: boolean;
};

type PrimaryDropdownProps = {
  options: DropdownMenuItemProps[];
  value: string;
  onChange: (value: string) => void;
  side?: 'top' | 'bottom' | 'left' | 'right';
  align?: 'start' | 'center' | 'end';
  displayValue?: string | React.ReactNode;
  selectBtnWidth?: string;
  id?: string;
  placeholder?: string;
  isError?: boolean;
  className?: string;
};

export function PrimaryDropdown({
  options,
  value,
  onChange,
  side,
  align,
  displayValue,
  selectBtnWidth,
  id,
  placeholder,
  isError,
  className,
  ...rest
}: PrimaryDropdownProps) {
  const scrollRef = useRef();

  const rowVirtualizer = useVirtual({
    size: options.length,
    parentRef: scrollRef,
    estimateSize: useCallback(() => 42, []),
  });

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild onClick={(e) => e.stopPropagation()}>
        <div
          className={classNames(
            'flex items-center justify-between p-2 rounded border-1',
            selectBtnWidth ? `${selectBtnWidth}` : 'max-w-60',
            isError ? 'border-tw-red' : 'border-tw-gray-c',
            !value ? 'text-tw-black-9' : 'text-tw-black-3',
            className
          )}
          id={id ?? 'triggerId'}
          {...rest}
        >
          <span className="mr-2 truncate">
            {!value && placeholder}
            {(value && displayValue) || options.find((option) => option.value === value)?.label}
          </span>
          <DropdownArrowIcon className="fill-current text-tw-black-5" />
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        ref={scrollRef}
        side={side}
        align={align}
        style={{
          height: `${rowVirtualizer.totalSize}px`,
        }}
        className={classNames(
          'py-2 mt-2 overflow-y-scroll bg-white rounded shadow-lg max-h-60',
          selectBtnWidth || 'min-w-40 max-w-60'
        )}
      >
        {options.map((o) => (
          <DropdownMenu.Item
            className={classNames(
              'px-4 py-2 hover:bg-tw-gray-eb',
              o.disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
              value === o.value && 'bg-tw-gray-f5'
            )}
            key={o.value}
            disabled={o.disabled}
            onSelect={() => {
              onChange(o.value);
            }}
          >
            {o.label}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}

PrimaryDropdown.defaultProps = {
  side: 'bottom',
  align: 'center',
};
