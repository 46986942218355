import { toast as toastify } from 'react-toastify';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';
import { TertiaryButton } from 'ui-components/inputs/Buttons';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ConnectedIntegration, Integration } from '../../api-client';
import { ReactComponent as SuccessGreenTick } from '../../assets/images/successGreenTick.svg';
import useAddIntegration from './hooks/useAddIntegration';
import useDeleteIntegration from './hooks/useDeleteIntegration';
import useGetTrayConfigUrl from './hooks/useGetTrayConfigUrl';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';

export enum TrayConfigStatus {
  ERROR = 'error',
  FINISH = 'finish',
  CANCEL = 'cancel',
}

interface TrayConfigFieldProps {
  connectedIntegrationName: string;
  integration: Integration;
  updateTrayConnectedIntegration: (data: ConnectedIntegration) => void;
  updateTrayConfigStatus: (status: TrayConfigStatus) => void;
  updateTrayAuthId: (authId: string) => void;
  heightTillEndOfPage?: boolean;
  onClick?: (bool: boolean) => void;
}

export default function TrayConfigField(props: TrayConfigFieldProps) {
  const [configured, setConfigured] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [iframeVisibility, setIframeVisibility] = useState(false);
  const trayIframeDimensions = useRef(null);
  const [loading, setLoading] = useState(false);
  const integration = props.integration;
  const addIntegration = useAddIntegration();
  const [newTrayIntegration, setNewTrayIntegration] = useState(null);
  // eslint-disable-next-line
  const { data: trayConfigUrl, isLoading: trayConfigUrlLoading } = useGetTrayConfigUrl(
    newTrayIntegration?.id
  );
  const deleteIntegration = useDeleteIntegration();

  const handleIframeEvents = useCallback(
    async (e) => {
      // Here we should handle all event types
      if (e.data.type === 'tray.configPopup.error' || e.data.type === 'tray.authpopup.error') {
        props.updateTrayConfigStatus(TrayConfigStatus.ERROR);
        props.onClick && props.onClick(false);
        deleteIntegration.mutate(newTrayIntegration?.id);
        trayIframeDimensions.current = null;
        setNewTrayIntegration(null);
        setShowIframe(false);
        setLoading(false);
        props.updateTrayConnectedIntegration(null);
        toastify(<ErrorToast description="Could not complete configuration" />, {
          type: 'error',
        });
      }
      // TODO currently only works for configPopup.ready
      if (e.data.type === 'tray.configPopup.ready' || e.data.type === 'tray.authpopup.ready') {
        setIframeVisibility(true);
      }
      if (e.data.type === 'tray.configPopup.cancel' || e.data.type === 'tray.authpopup.cancel') {
        props.updateTrayConfigStatus(TrayConfigStatus.CANCEL);
        deleteIntegration.mutate(newTrayIntegration?.id);
        trayIframeDimensions.current = null;
        setNewTrayIntegration(null);
        setShowIframe(false);
        setLoading(false);
        props.onClick && props.onClick(false);
        props.updateTrayConnectedIntegration(null);
      }
      if (e.data.type === 'tray.configPopup.finish' || e.data.type === 'tray.authpopup.finish') {
        trayIframeDimensions.current = null;
        setConfigured(true);
        setShowIframe(false);
        setLoading(false);
        if (e.data.type === 'tray.authpopup.finish' && e.data.authId) {
          props.updateTrayAuthId(e.data.authId);
        }
        props.updateTrayConfigStatus(TrayConfigStatus.FINISH);
        props.onClick && props.onClick(false);
        toastify(<SuccessToast description="Configuration successful" />, {
          type: 'success',
        });
      }
    },
    [newTrayIntegration]
  );

  useEffect(() => {
    window.addEventListener('message', handleIframeEvents);
    return () => {
      window.removeEventListener('message', handleIframeEvents);
    };
  }, [handleIframeEvents]);

  const handleAdd = () => {
    setLoading(true);
    props.onClick && props.onClick(true);
    try {
      addIntegration.mutate(
        {
          name: props.connectedIntegrationName,
          type: integration.type,
          integration: integration.id,
          active: false,
          connect: {
            [integration.type]: {
              solution: {
                solutionId: integration?.metadata?.tray?.solutionId,
              },
            },
          },
        },
        {
          onSuccess: (response) => {
            setNewTrayIntegration(response);
            trayIframeDimensions.current = {};
            const integrationSection = document.getElementsByClassName('custom-int-section');
            const integrationSectionBounds = integrationSection[0].getBoundingClientRect();
            trayIframeDimensions.current = integrationSectionBounds;
            setShowIframe(true);
            if (integration?.metadata?.tray?.externalAuthId) {
              setIframeVisibility(true);
            }
            props.updateTrayConnectedIntegration(response);
          },
          onError: () => {
            toastify(<ErrorToast description="Error while adding integration" />, {
              type: 'error',
            });
          },
        }
      );
    } catch (e) {
      toastify(<ErrorToast description="Error while adding integration" />, {
        type: 'error',
      });
      setLoading(false);
    }
  };

  const styles: any = {
    container: {
      flex: 1,
      position: 'absolute',
      top: 0,
      left: 0,
      width: trayIframeDimensions?.current?.width,
      height: trayIframeDimensions?.current?.height,
      zIndex: 100,
    },
    iframe: {
      width: '100%',
      height: '100%',
      minHeight: '500px',
      backgroundColor: 'white !important',
      visibility: iframeVisibility ? 'visible' : 'hidden',
    },
  };
  return !configured ? (
    <div>
      <TertiaryButton
        className="w-32"
        isLoading={loading}
        onClick={handleAdd}
        disabled={props.connectedIntegrationName.length === 0}
      >
        <div className="font-medium text-tw-black-3">
          {newTrayIntegration ? 'Re-Configure' : 'Configure'}
        </div>
      </TertiaryButton>
      {showIframe && newTrayIntegration && (
        <div style={styles.container}>
          <iframe
            id={'tray-config-wizard'}
            key={newTrayIntegration?.metadata?.tray?.configWizardUrl}
            style={styles.iframe}
            src={`${newTrayIntegration?.metadata?.tray?.configWizardUrl}&skipTitleField=true`}
            title="Solution instance configurator"
          />
        </div>
      )}
    </div>
  ) : (
    <div className="px-2 py-1 font-semibold rounded bg-tw-green-e7 text-tw-green-20">
      <div className="flex items-center gap-x-1">
        <SuccessGreenTick />
        <div>CONFIGURED</div>
      </div>
    </div>
  );
}
