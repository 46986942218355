import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { SortOrder } from '../../users-and-accounts/users/types';

export type QueryParams = {
  query: string;
  status: string;
  destinations: string[];
  offset: number;
  page: number;
  selectedOrderFilter: {
    filter: { label: string; value: string };
    order: SortOrder;
  };
  duplicateError?: boolean;
};

export type SortFilter = { label: string; value: string };
export const sortFilters: SortFilter[] = [
  {
    label: 'Last created',
    value: 'last_created',
  },
  {
    label: 'Last run',
    value: 'last_run',
  },
  {
    label: 'Next run',
    value: 'next_run',
  },
];

export const initialQueryParamsState = {
  query: '',
  page: 1,
  status: 'all',
  destinations: [],
  offset: 0,
  selectedOrderFilter: {
    filter: sortFilters[0],
    order: SortOrder.descending,
  },
};

type OpportunityListStore = {
  queryParams: QueryParams;
  destinationFieldMapping: {};
  updateDestinationFieldMapping: (destinationFieldMapping: { [key: string]: string }) => void;
  updateQueryParams: (queryParams: QueryParams) => void;
};

export const useOpportunityListStore = create<OpportunityListStore>(
  devtools((set) => {
    return {
      queryParams: initialQueryParamsState,
      destinationFieldMapping: {},
      updateQueryParams: (queryParams: QueryParams) => {
        set((state) => {
          return {
            ...state,
            queryParams,
          };
        });
      },
      updateDestinationFieldMapping: (destinationFieldMapping: { [key: string]: string }) => {
        set((state) => {
          return {
            ...state,
            destinationFieldMapping,
          };
        });
      },
    };
  })
);
