import { useMutation, useQueryClient } from 'react-query';
import { TeamMemberEntity, useClient } from '../../../api-client';
import { toast as toastify } from 'react-toastify';
import { AnalyticsConst, AnalyticsEvents } from '../../../telemetry/constants';
import { useAnalytics } from '../../../telemetry';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';

export type RoleData = {
  id: string;
  new_role: string;
  email?: string;
  old_role?: string;
};

export default function useUpdateRole() {
  const queryClient = useQueryClient();
  const client = useClient();
  const { track } = useAnalytics();
  return useMutation((data: RoleData) => client.updateMemberRole(data?.id, data?.new_role), {
    onMutate: (data: RoleData) => {
      queryClient.setQueryData('acceptedTeamMembers', (userDetails: TeamMemberEntity[]) => {
        return userDetails.map((userDetail) => {
          if (data.id === userDetail.id) {
            return { ...userDetail, currentRole: { role: data.new_role } };
          }
          return userDetail;
        });
      });
    },
    onSuccess: () => {
      toastify(<SuccessToast description="Role updated successfully" />, {
        type: 'success',
      });
    },
    onError: (error, data) => {
      toastify(<ErrorToast />, {
        type: 'error',
      });
      track(AnalyticsEvents.ROLE_UPDATE_FAILED, {
        [AnalyticsConst.AFFECTED_USER]: data.email,
        [AnalyticsConst.OLD_ROLE]: data.old_role,
        [AnalyticsConst.NEW_ROLE]: data.new_role,
      });
    },
    onSettled: async () => {
      await queryClient.refetchQueries('acceptedTeamMembers');
    },
  });
}
