import { LoggedInUser, OrganizationStatus } from 'api-client';
import animationData from 'assets/lotties/setup-rocket.json';
import useInterval from 'ui-components/business/navbars/hooks/useInterval';
import { useAuthStore } from 'hooks/useAuthStore';
import Lottie from 'lottie-react';
import useOrganizationDetails from 'hooks/useOrganizationDetails';
import useSetupSandbox from 'hooks/useSetupSandbox';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

export default function SandboxSetup() {
  const { getAccountSchemaName } = useAuthStore();
  const [enabled, setEnabled] = useState<boolean>(true);
  const { data: organizationData } = useOrganizationDetails(getAccountSchemaName(), enabled, 5000);
  const history = useHistory();
  const queryClient = useQueryClient();
  const { getAccountId, setAuthState, user: userData } = useAuthStore();
  const setupSandbox = useSetupSandbox();
  const urlSearchParams = new URLSearchParams(history.location.search);

  useEffect(() => {
    if (organizationData && organizationData.status === OrganizationStatus.READY) {
      setEnabled(false);
      setupSandbox.mutate(urlSearchParams.get('dataset'), {
        onSuccess: (data) => {
          queryClient.setQueryData(['user', getAccountId()], (oldData: LoggedInUser) => {
            return {
              ...oldData,
              currentOrganization: {
                ...oldData.currentOrganization,
                ...data,
                isActive: true,
              },
            };
          });
          setAuthState({
            user: {
              ...userData,
              currentOrganization: {
                ...userData.currentOrganization,
                ...data,
                isActive: true,
              },
            },
            isLoggedIn: true,
          });
          queryClient.refetchQueries(['user', getAccountId()]);
          history.push('/');
        },
      });
    }
  }, [organizationData]);

  const [growthDefinitionToggle, setGrowthDefinitionToggle] = useState<boolean>(true);

  useInterval(() => {
    setGrowthDefinitionToggle(!growthDefinitionToggle);
  }, 4000);

  return (
    <div className="grid h-screen place-items-center">
      <div className="flex flex-col items-center w-1/2">
        <Lottie animationData={animationData} loop={true} autoplay={true} style={{ width: 250 }} />
        <h2 className="text-3xl font-medium text-tw-black-1">Setting up your test dashboard</h2>
        <div className="flex flex-col items-center w-3/4 p-6 mt-6 border rounded-lg border-tw-gray-eb">
          <h4 className="text-base font-medium">What is growth?</h4>
          <p className="mt-3 text-center text-tw-black-3">
            {growthDefinitionToggle
              ? `The distinct absence of de-growth`
              : `A rare phenomenon highly correlated to the usage of Toplyne`}
          </p>
          <p className="text-tw-black-9">
            {growthDefinitionToggle
              ? `"The day we stopped de-growing, we surprisingly grew"`
              : `"It's insane how fast Canva, Grafana and Gather Town are growing"`}
          </p>
        </div>
      </div>
    </div>
  );
}
