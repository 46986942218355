import { CrossIcon } from 'ui-components/data-display/Icons';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { classNames } from '../../utils/common';

export interface CheckBoxInputProps {
  onChange: (value: string, inputValue?: string, cleanup?: Function | null) => void;
  placeholder?: string;
  containerStyle?: string;
  autoComplete?: string;
  onComparatorChanged?: (value: string) => void;
  isComparatorChanged?: boolean;
}

const CheckBoxInput: FC<CheckBoxInputProps> = (props) => {
  const {
    onChange,
    placeholder,
    containerStyle = '',
    autoComplete,
    onComparatorChanged,
    isComparatorChanged,
  } = props;
  const [inputValue, setInputValue] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    setInputValue(value);
    onChange(value as any, inputValue);
  };

  useEffect(() => {
    setInputValue('');
    onComparatorChanged(inputValue);
  }, [isComparatorChanged]);

  const onClearFunction = () => {
    setInputValue('');
    onChange('');
  };

  return (
    <div
      className={classNames(
        `bg-white px-3 py-3.5 flex items-center border-1 border-solid border-tw-gray-ed rounded-lg flex-wrap w-full justify-start}`,
        containerStyle
      )}
    >
      <input
        className="flex-grow inline text-xs outline-none text-tw-black-3"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleChange}
        autoComplete={autoComplete}
      />
      <span className={'cursor-pointer'}>
        {inputValue && (
          <CrossIcon className="w-2 fill-current text-tw-black-7 mr-1" onClick={onClearFunction} />
        )}
      </span>
    </div>
  );
};

export default CheckBoxInput;
