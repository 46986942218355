import { useEffect, useState } from 'react';
import * as React from 'react';
import { DropDownProps } from '../DropDownMenu';
import { Listbox } from '@headlessui/react';
import { ReactComponent as DropdownArrowIcon } from '../../../../assets/images/dropdown-arrow.svg';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/images/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../assets/images/arrow-up.svg';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

interface CategoryFilterDropdownProps extends DropDownProps {
  onChange: any;
  value: string;
  isLoading?: boolean;
}

const CategoryFilterDropdown: React.FC<CategoryFilterDropdownProps> = ({
  value,
  onChange,
  options: optionsArray,
  customOptions,
  isLoading,
}) => {
  const [expandListDetails, setExpandListDetails] = useState<{
    itemsToShow: number;
    expanded: boolean;
  }>({
    itemsToShow: 4,
    expanded: false,
  });

  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  useEffect(() => {
    if (value && selectedOption !== value && Array.isArray(optionsArray)) {
      const label = optionsArray?.find((option) => option.value == value)?.label || '';
      setSelectedOption(label);
    }
    if (value && selectedOption !== value && customOptions) {
      setSelectedOption(value);
    }
  }, [value, optionsArray]);

  const getMenuLayout = () => {
    if (optionsArray) {
      return (
        <>
          {optionsArray.slice(0, expandListDetails.itemsToShow).map((option) => (
            <Listbox.Option
              className="text-xs text-tw-black-5 cursor-pointer"
              key={option.value}
              value={option.value}
            >
              {option.label}
            </Listbox.Option>
          ))}
          {optionsArray.length > expandListDetails.itemsToShow && (
            <p>
              <a
                className="text-xs text-tw-blue-0d font-medium cursor-pointer"
                onClick={(e) => showMore(e)}
              >
                {expandListDetails.expanded ? (
                  <span className="flex items-center">
                    Show less
                    <ArrowUpIcon className="ml-2" />
                  </span>
                ) : (
                  <span className="flex items-center">
                    More operators
                    <ArrowDownIcon className="ml-2" />
                  </span>
                )}
              </a>
            </p>
          )}
        </>
      );
    }
  };

  const showMore = (e) => {
    e.stopPropagation();
    if (expandListDetails.itemsToShow === 4) {
      setExpandListDetails({ itemsToShow: optionsArray.length, expanded: true });
    } else {
      setExpandListDetails({ itemsToShow: 4, expanded: false });
    }
  };

  return (
    <Listbox value={selectedOption} onChange={onChange}>
      <DropdownMenuPrimitive.Root>
        <DropdownMenuPrimitive.Trigger className="flex items-center justify-between w-full px-3 py-3 rounded-lg cursor-pointer focus:outline-none border-1 border-tw-gray-ed text-xs text-tw-black-3">
          <div>{selectedOption}</div>
          <DropdownArrowIcon className="fill-current text-tw-black-7 inline-block" />
        </DropdownMenuPrimitive.Trigger>
        <DropdownMenuPrimitive.Content className="w-64 p-4 mt-1 overflow-auto bg-white rounded shadow-lg focus:outline-none">
          <DropdownMenuPrimitive.Item className="list-none space-y-3">
            {!isLoading && getMenuLayout()}
          </DropdownMenuPrimitive.Item>
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Root>
    </Listbox>
  );
};

export default CategoryFilterDropdown;
