import { useClient } from 'api-client';
import { SortOrder } from 'pages/users-and-accounts/users/types';
import { useQuery } from 'react-query';
import { useSyncedUsersStore } from '../store/SyncedUsersStore';

export type SyncedUsersResponse = {
  count: number;
  data: Array<{
    personaNow: string | null;
    personaWhenQualified: string | null;
    qualifiedOn: string;
    userId: string;
  }>;
};

export type SortSyncedUsersBy = {
  keyName: string;
  order: SortOrder;
};

export default function useGetSyncedUsers(
  oppId: string | number,
  exportId: string | number,
  enabled: boolean = true
) {
  const { searchQuery, page, sortBy, field_mapping } = useSyncedUsersStore();
  const client = useClient();

  return useQuery<SyncedUsersResponse>(
    [
      'synced-users',
      oppId?.toString(),
      searchQuery,
      page,
      exportId?.toString(),
      sortBy?.map((sf) => `${sf.keyName}-${sf.order}`).join(','),
      JSON.stringify(Object.keys(field_mapping)),
    ],
    () =>
      client.getSyncedUsers(
        searchQuery,
        50,
        sortBy,
        oppId?.toString(),
        (page - 1) * 50,
        exportId?.toString(),
        field_mapping
      ),
    {
      enabled: enabled && !!oppId && !!exportId,
    }
  );
}
