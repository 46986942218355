import { yupResolver } from '@hookform/resolvers/yup';
import { Error } from 'api-client';
import { InfoIcon, KeyIcon, ToplyneLogo } from 'ui-components/data-display/Icons';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import ErrorFromHook from '../../ui-components/feedback/ErrorFromHook';
import { PrimaryButton } from '../../ui-components/inputs/Buttons';
import { PasswordInput } from '../../ui-components/inputs/Inputs';
import { passwordRegExp, passwordValidationString } from '../../utils/common';
import useSendNewPassword from './hooks/useSendNewPassword';

interface ResetPasswordFormProps {
  match: any;
}

type ResetPasswordFormState = {
  newPassword: string;
  confirmedPassword: string;
};

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('New password is required')
    .matches(passwordRegExp, passwordValidationString),
  confirmedPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords do not match')
    .required('Password is required'),
});

const ResetPasswordForm: FC<ResetPasswordFormProps> = (props) => {
  const uid = props.match.params.uid;
  const token = props.match.params.token;
  const sendNewPasswordHook = useSendNewPassword();

  const onSubmit = (data: ResetPasswordFormState) => {
    var resetData = {
      uid,
      token,
      password: data.newPassword,
    };
    sendNewPasswordHook.mutate(resetData);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  return (
    <div className="h-screen py-4 pl-4">
      <ToplyneLogo className="h-auto w-36" />
      <div className="my-4 h-88">
        <div className="w-36% mx-auto rounded-lg border-1 border-tw-gray-eb px-12 pt-12 h-full">
          <a className="d-flex justify-content-center">
            <KeyIcon />
          </a>
          <div className="mt-6">
            <div className="text-center">
              <h3 className="text-3xl font-medium text-tw-black-3">Reset password</h3>
            </div>

            <form className="w-3/4 mx-auto mt-6" onSubmit={handleSubmit(onSubmit)}>
              {sendNewPasswordHook.isError &&
                (sendNewPasswordHook.error as Error).response.data['password'] && (
                  <ErrorFromHook>
                    {(sendNewPasswordHook.error as Error).response.data['password']}
                  </ErrorFromHook>
                )}
              <label className={'font-normal text-sm text-tw-black-5 mb-2'}>New Password</label>
              <PasswordInput
                className="w-full py-3"
                autoFocus
                {...register('newPassword')}
                isError={errors.newPassword}
              />
              <div className="flex flex-row mt-2">
                <div>
                  <InfoIcon
                    className={`mr-2 w-4 ${
                      errors.newPassword?.message
                        ? `fill-current text-tw-red-dd`
                        : `fill-current text-tw-black-9`
                    }`}
                  />
                </div>
                <div>
                  <p
                    className={`text-tw-black-9 text-xs ${
                      errors.newPassword?.message ? `text-tw-red-dd` : ``
                    }`}
                  >
                    {errors.newPassword?.message
                      ? errors.newPassword?.message
                      : passwordValidationString}
                  </p>
                </div>
              </div>

              <label className={'font-normal text-sm text-tw-black-5 mt-8 mb-2'}>
                Confirm new Password
              </label>
              <PasswordInput
                className="w-full py-3"
                {...register('confirmedPassword')}
                isError={errors.confirmedPassword}
              />
              {errors.confirmedPassword?.message && (
                <div className="flex flex-row mt-2">
                  <InfoIcon className="w-4 mr-2 fill-current text-tw-red-dd" />
                  <p className="text-xs text-tw-red-dd">{errors.confirmedPassword?.message}</p>
                </div>
              )}
              <PrimaryButton type="submit" className="w-full py-3 mt-8 uppercase">
                Reset Password
              </PrimaryButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
