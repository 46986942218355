import Amplitude from 'assets/images/amplitude.svg';
import ApiIcon from 'assets/images/api_logo.svg';
import Hubspot from 'assets/images/hubspot.svg';
import Mixpanel from 'assets/images/mixpanel.svg';
import Pipedrive from 'assets/images/pipedrive.svg';
import Salesforce from 'assets/images/salesforce.svg';
import Segment from 'assets/images/segment.svg';
import Slintel from 'assets/images/slintel.svg';
import Snowflake from 'assets/images/snowflake.svg';
import Toplyne from 'assets/images/toplyne_logo2.svg';
import Rudderstack from 'assets/svg/components/rudderstack.svg';
import Pendo from 'assets/svg/components/pendo.svg';
import { average, prominent } from 'color.js';
import dayjs from 'dayjs';
import isNil from 'lodash/isNil';
import { QueryGoal, QueryType } from 'stores/QueryStore';
import Stripe from '../assets/svg/components/stripe.svg';

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const convertDate = (dateString) => {
  if (dateString) {
    let date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      year: 'numeric',
      day: '2-digit',
    }).format(date);
  }

  return 'N/A';
};
export const convertDay = (dateString) => {
  if (dateString) {
    let date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
    }).format(date);
  }

  return 'N/A';
};

export const convertDateTime = (dateString) => {
  if (dateString) {
    let date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      dateStyle: 'long',
      timeStyle: 'short',
    }).format(date);
  }

  return 'N/A';
};

export const convertAmount = (amount) => {
  if (amount) {
    return amount.toLocaleString('en-US');
  }
  return 0;
};

export const leadStatusTextMap = new Map([
  ['cold', <>❄️ COLD</>],
  ['cold_lead', <>❄️ COLD</>],
  ['hot', <>&#x1F525; HOT</>],
  ['hot_lead', <>&#x1F525; HOT</>],
  ['warm', <>&#x2615; WARM</>],
  ['warm_lead', <>&#x2615; WARM</>],
]);

export const leadStatusGradientMap = new Map([
  ['cold', ['#b6ebf5', '#b6ebf51a']],
  ['cold_lead', ['#b6ebf5', '#b6ebf51a']],
  ['hot', ['#e47171', '#e471711a']],
  ['hot_lead', ['#e47171', '#e471711a']],
  ['warm', ['#de9033', '#de90331a']],
  ['warm_lead', ['#de9033', '#de90331a']],
]);

export const getGradientLoaderColor = (score, threshold) => {
  if (score && threshold) {
    if (score > threshold['hot']) {
      return ['#e47171', '#e471711a'];
    } else if (score > threshold['warm']) {
      return ['#de9033', '#de90331a'];
    }
  }
  return ['#b6ebf5', '#b6ebf51a'];
};

export const getSourceImg = (source) => {
  source = source?.toLowerCase();
  switch (source) {
    case 'amplitude':
      return Amplitude;
    case 'salesforce':
      return Salesforce;
    case 'slintel':
      return Slintel;
    case 'mixpanel':
      return Mixpanel;
    case 'pipedrive':
      return Pipedrive;
    case 'hubspot':
      return Hubspot;
    case 'snowflake':
      return Snowflake;
    case 'segment':
      return Segment;
    case 'stripe':
      return Stripe;
    case 'toplyne':
      return Toplyne;
    case 'rudderstack':
      return Rudderstack;
    case 'toplyne_api':
      return ApiIcon;
    case 'pendo':
      return Pendo;
    default:
      return '';
  }
};

export const trimLChar = (string: string, charToRemove: string) => {
  while (string.charAt(0) === charToRemove) {
    string = string.substring(1);
  }
  return string;
};

export const trimRChar = (string: string, charToRemove: string) => {
  while (string.charAt(string.length - 1) === charToRemove) {
    // to trim string from right
    string = string.substring(0, string.length - 1);
  }
  return string;
};

export const getPlanIntervalShort = (interval) => {
  if (interval === 'YEARLY') {
    return '/yr';
  } else if (interval === 'MONTHLY') {
    return '/mo';
  } else if (interval === 'WEEKLY') {
    return '/wk';
  }
};

export const capitalizeFirstLetter = (str?: string) => {
  if (!str) return '';

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const getDominantColor = async (image, amount = 1, format = 'hex') => {
  return await prominent(image, { amount: amount, format: format })
    .then((res) => {
      return { color: res, success: true };
    })
    // Note: this function recieves a parameter (error: Error).
    // We might need it in the future if we want to handle errors differently.
    .catch(() => {
      return { color: '', success: false };
    });
};

export const getAverageColor = async (image, amount = 1, format = 'hex') => {
  return await average(image, { amount: amount, format: format })
    .then((res) => {
      return { color: res, success: true };
    })
    // Note: this function recieves a parameter (error: Error).
    // We might need it in the future if we want to handle errors differently.
    .catch(() => {
      return { color: '', success: false };
    });
};

export const getInitialTextFromGoal = (goal: QueryGoal, type: QueryType) => {
  const isAccount = type === QueryType.accounts;
  switch (goal) {
    case QueryGoal.expansion:
      return isAccount ? 'which first converted' : 'who first converted';
    default:
      return isAccount ? 'first seen' : 'who first signed up';
  }
};

type UserName = {
  firstName: string;
  lastName: string;
  email: string;
};

export const getUserInitials = (data: Partial<UserName>) => {
  if (!data) return '';
  if (data.firstName && data.lastName) {
    return data.firstName[0] + data.lastName[0];
  }
  return data?.email && data?.email[0];
};

export const DefaultTableCell = (value: string, keySpace?: string) => {
  return (
    <span className="truncate">
      {keySpace ? formatForCellDisplay(value, keySpace) : formatForCellDisplay(value)}
    </span>
  );
};

function toKebab(string) {
  return string
    .split('')
    .map((letter) => {
      if (/[A-Z]/.test(letter)) {
        return ` ${letter.toLowerCase()}`;
      }
      return letter;
    })
    .join('')
    .trim();
}

export function toSentence(string) {
  const interim = toKebab(string).replace(/-/g, ' ');
  return interim.slice(0, 1).toUpperCase() + interim.slice(1);
}

export const getPropertyDisplayName = (item: string) => {
  if (item === 'domain_1') {
    return 'Domain';
  }
  if (item === 'user_creation_time') {
    return 'User Creation Time';
  }

  return toSentence(item);
};

export const GoalMetCell = (cellId: string, value: string) => {
  if (value?.toString() === '-1') {
    return <span className="p-1 rounded-sm text-tw-black-7 bg-tw-gray-eb">Goal met 🎉</span>;
  }

  if (cellId.includes('_lead_status')) {
    return <LeadStatusCell cellValue={value} />;
  }

  return formatForCellDisplay(value);
};

export const getTableCell = (cellValue: string, cellId: string, keySpace?: string) => {
  if (isNil(cellValue)) {
    return <span>N/A</span>;
  }
  if (cellId === 'user_creation_time') {
    return DateTableCell(cellValue);
  }

  if (cellId.includes('_score') || cellId.includes('_lead_status')) {
    return GoalMetCell(cellId, cellValue);
  }

  if (keySpace) return DefaultTableCell(cellValue, keySpace);
  return DefaultTableCell(cellValue);
};

export const DateTableCell = (value: string) => <div>{convertDate(value)}</div>;

export const updateCellWidthMap = (state, action) => {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        [action.payload.id]: {
          width: action.payload.width,
          height: action.payload.height,
        },
      };
    default:
      return state;
  }
};

export function formatForCellDisplay(value: any, keySpace?: string): string {
  if (typeof value === 'boolean') {
    return value.toString();
  }

  let objectCheck = false;
  // check if the parsed value is an object.
  // do not create date objects of object values.
  try {
    const v = JSON.parse(value);

    if (v) {
      objectCheck = true;
    }
  } catch (error) {
    objectCheck = false;
  }

  if (/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d.\d{6}Z/.test(value) && !objectCheck) {
    return convertDate(value);
  }

  let formattedValue;

  if (typeof value === 'string') {
    formattedValue = value.trim();
  } else {
    formattedValue = value;
  }

  if (typeof formattedValue === 'number' && formattedValue < 1) {
    if (formattedValue === 0) return value;
    return value.toFixed(3).toString();
  }

  if (!formattedValue && !keySpace) {
    return '--';
  }

  return formattedValue?.toString();
}

const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getPlaceholder = (fields) => {
  const presentSearchFields = fields;
  let placeholderFields = '';
  placeholderFields = fields
    .map((field) => {
      return field
        .split('_')
        .map((f) => capitalize(f))
        .join(' ');
    })
    .join(', ')
    .replace(/, ([^,]*)$/, ' or $1');
  let placeholder = 'Search by ';
  if (presentSearchFields && presentSearchFields.length > 0) {
    placeholder = placeholder.concat(placeholderFields);
  } else {
    placeholder = 'Start searching';
  }
  return placeholder;
};

enum LeadStatusEnum {
  HOT = 'hot',
  WARM = 'warm',
  COLD = 'cold',
}

function getEmojiFromLeadStatus(leadStatus: LeadStatusEnum) {
  switch (leadStatus) {
    case LeadStatusEnum.HOT:
      return `🔥`;
    case LeadStatusEnum.WARM:
      return `☕`;
    case LeadStatusEnum.COLD:
      return `❄️`;
  }
}

const LeadStatusCell = ({ cellValue }: { cellValue: string }) => {
  return (
    <>
      <div className={'flex items-center gap-x-1'}>
        <span>{getEmojiFromLeadStatus(cellValue.toLowerCase() as LeadStatusEnum)}</span>
        <span className="ml-1">{capitalizeFirstLetter(cellValue)}</span>
      </div>
    </>
  );
};

export const transformText = (text: string) => {
  let tabName = text
    .split('_')
    .map((x: string) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(' ');
  if (tabName.length > 3) {
    return tabName;
  } else {
    return tabName.toUpperCase();
  }
};

export const highlightSearchedString = (str, substring, className) => {
  const parts = str?.toString().split(new RegExp(`(${substring})`, 'gi'));
  return (
    <span>
      {parts.map((part) =>
        part.toLowerCase() === substring.toLowerCase() ? (
          <span className={`${className}`}>{part}</span>
        ) : (
          part
        )
      )}
    </span>
  );
};

export const GetSortOrder = (prop) => {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
};

export const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const passwordValidationString =
  'Your password should have at least 10 characters, composed of at least one letter and one number';

export const passwordRegExp = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*\-#?&]{10,}$/);

export const removePredictorText = (str: string) => {
  if (str === 'Model Dropped') {
    return 'Not applicable';
  }

  return str.replace('Predictor', '');
};

export const formatColumnName = (str: string) => {
  if (str.startsWith("'") && str.endsWith("'")) {
    return str;
  }
  return str.toLowerCase();
};

export const capitalizeColumnName = (str: string) => {
  if (!str) {
    return;
  }
  if (str.startsWith("'") && str.endsWith("'")) {
    return str;
  }
  return str.toUpperCase();
};

export const getIntegrationConnectorType = (integrations, integrationType) => {
  const integration = integrations?.find((i) => i.type === integrationType);
  if (integration?.supportsRead) {
    return 'source';
  }
  return 'destination';
};

export const formattedColumnNames = (columnName: string) => {
  switch (columnName) {
    // users
    case 'user_id':
      return 'User ID';
    case 'domain_1':
      return 'Domain';
    case 'user_creation_time':
      return 'User Creation Time';
    // accounts
    case 'account_id':
      return 'Account ID';
    case 'account_creation_time':
      return 'Account Creation Time';
    default:
      return capitalizeFirstLetter(columnName).replaceAll('_', ' ');
  }
};

export const joinStringsByCommaAndAnd = (strs: string[]) => {
  if (strs.length === 1) {
    return strs[0];
  }
  if (strs.length === 2) {
    return `${strs[0]} and ${strs[1]}`;
  }
  return `${strs.slice(0, -1).join(', ')} and ${strs[strs.length - 1]}`;
};

export const checkIfDateIsOlderThanAMonth = (date) => {
  const today = new Date();
  const converted_date = new Date(date);
  const diff_in_time = today.getTime() - converted_date.getTime();
  const diff_in_days = diff_in_time / (1000 * 3600 * 24);
  return diff_in_days < 30;
};

// remove keys from object if it has null, undefined value or is an empty list
export const cleanObject = (obj) => {
  for (let propName in obj) {
    if (!obj[propName].length || obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
};

export const toSnakeCase = (str: string) => {
  return str.trim().split(' ').join('_').toLowerCase();
};

export function kFormatter(num: any) {
  return Math.abs(num) > 999 ? (Math.abs(num) / 1000).toFixed(1) + 'K' : Math.abs(num);
}

export function numberWithPluralWord(num: number, str: string) {
  return num + ' ' + str + (num > 1 ? 's' : '');
}

export const getCategorizedIntegrations = (integrations) => {
  const mapInactiveIntegrations = integrations?.reduce((acc, integration) => {
    let categories = [];
    if (integration?.categories) {
      categories = integration?.categories;
    } else {
      categories = integration?.integration?.categories;
    }
    categories?.forEach((category) => {
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(integration);
    });

    return acc;
  }, {} as any);

  return {
    'All Apps': integrations,
    ...cleanObject(mapInactiveIntegrations),
  };
};

export const valuePlusCountNotation = (value: Array<string>, count: number) => {
  if (!value) {
    return '';
  }
  if (value.length > count) {
    return `${value.slice(0, count).join(', ')} +${value.length - count}`;
  }
  return value.join(', ');
};

export function camelToSnake(str) {
  return str.replace(/[A-Z]/g, (c) => {
    return '_' + c.toLowerCase();
  });
}

export function convertObjectKeysFromCamelToSnake(sourceObj) {
  const destinationObject = {};
  for (let key in sourceObj) {
    destinationObject[camelToSnake(key)] = sourceObj[key];
  }
  return destinationObject;
}

export const disableUsersTableInAccountsDetails = (schemaName: string) => {
  if (!schemaName) return false;

  return ['gathertown'].includes(schemaName) ? true : false;
};

export const getEntityHeader = (entityType: QueryType | null | undefined) => {
  switch (entityType) {
    case QueryType.users:
      return 'User ID';
    case QueryType.accounts:
      return 'Account ID';
    default:
      return 'User ID';
  }
};

export const getEntityAccessor = (entityType: QueryType | null | undefined) => {
  switch (entityType) {
    case QueryType.users:
      return 'user_id';
    case QueryType.accounts:
      return 'account_id';
    default:
      return 'user_id';
  }
};

export const convertInOrgTimezone = (
  time: string,
  timezone: string,
  format = 'DD MMM YYYY, hh:mm A'
) => {
  return `${dayjs(time).tz(timezone).format(format)}`;
};

export function getDaysAndHoursOnly(payload) {
  return {
    start: dayjs(payload.start).format('YYYY/MM/DDTHH'),
    end: dayjs(payload.end).format('YYYY/MM/DDTHH'),
  };
}
