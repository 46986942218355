import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import isPlainObject from 'lodash/isPlainObject';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import {
  EventIcon,
  StatsFilterUserEvent,
  RightChevron,
  NoApplication,
} from '../../ui-components/data-display/Icons';
import { getSourceImg, convertDate } from '../../utils/common';
import { OpportunityResponse } from 'ui-components/business/opportunities/types';
import { MoreItemsPopover } from './common/utils';
import { allComparatorsMap } from '../../utils/globalTypes';
import useMeta from './hooks/useMeta';

const theme: any = createTheme({
  overrides: {
    // @ts-ignore
    MuiTimelineItem: {
      missingOppositeContent: {
        '&:before': {
          display: 'none',
        },
      },
    },
    MuiTimelineContent: {
      root: {
        flex: 'none',
      },
    },
    MuiTimelineConnector: {
      root: {
        height: 40,
        width: 1,
        backgroundColor: '#ebebeb',
      },
    },
    MuiTimeline: {
      root: {
        padding: 'none',
      },
    },
    MuiTimelineDot: {
      defaultGrey: {
        backgroundColor: 'white',
        borderColor: '#ebebeb',
        borderWidth: '1px',
      },
      root: {
        borderRadius: '25%',
        width: 32,
        height: 32,
        boxShadow: 'none',
        display: 'grid',
        placeItems: 'center',
      },
    },
  },
});

function getFilterAppliedText(filter) {
  if (isPlainObject(filter.value)) {
    if (filter.op === 'inthelast') {
      return (
        <div className="flex gap-x-2 pr-2">
          <div className="font-medium">{'Start: '}</div>
          <div>{filter.value?.start}</div>
          <div className="font-medium">{'End: '}</div>
          <div>{filter.value?.end}</div>
          <div className="font-medium">{'Timetype: '}</div>
          <div>{filter.value?.timeType}</div>
        </div>
      );
    }
    // op --> during
    return (
      <div className="flex gap-x-2 pr-2">
        <div className="font-medium">{'Start: '}</div>
        <div>{convertDate(filter.value?.start)}</div>
        <div className="font-medium">{'End: '}</div>
        <div>{convertDate(filter.value?.end)}</div>
      </div>
    );
  }
  if (Array.isArray(filter.value)) {
    return (
      <div className="flex gap-x-0 pr-2">
        <div className="font-medium">{filter.value?.slice(0, 2).join(',')}</div>
        {filter.value.length > 2 && (
          <MoreItemsPopover items={filter.value?.slice(2).map((c) => c)} />
        )}
      </div>
    );
  }
  return <div className="pr-2">{filter.value?.toString()}</div>;
}

function getTimelineIcon(filter) {
  if (filter.keySpace === 'profile_events') {
    return <EventIcon />;
  }
  return <StatsFilterUserEvent />;
}

type PQLFiltersProps = {
  opportunity: OpportunityResponse;
};

export default function PQLFilters(props: PQLFiltersProps) {
  const { opportunity } = props;

  const filters = opportunity.query?.filter;
  if (!opportunity.query?.filter?.length) {
    return (
      <>
        <hr className="border-b border-tw-gray-eb mt-8" />
        <div className="py-8 z-5 h-100 flex gap-x-2">
          <NoApplication />
          <div className="mt-2 text-tw-black-9">No filters were applied in this playbook</div>
        </div>
      </>
    );
  }
  const { data, isLoading } = useMeta(
    opportunity?.query.type,
    opportunity.query?.modelId,
    opportunity.query?.goal
  );
  return (
    <>
      <hr className="mt-8 border-b border-tw-gray-eb" />
      {isLoading && (
        <div className="py-8 z-5 h-100">
          <div className="bg-tw-gray-eb h-8 w-30 animate-pulse"></div>
          {Array(2)
            .fill(0)
            .map((idx) => {
              return <div key={idx} className="bg-tw-gray-eb w-3/5 h-8 animate-pulse my-4"></div>;
            })}
        </div>
      )}
      {!isLoading && (
        <div className="py-8 z-5 h-100">
          <div className="flex items-center gap-x-2">
            <span className="font-weight-bold">Filters applied</span>
            <span className="w-6 h-6 bg-tw-blue-f2  grid place-items-center rounded-lg text-xs text-tw-black-5">
              {opportunity.query?.filter?.length}
            </span>
          </div>
          <div className="py-4">
            <ThemeProvider theme={theme}>
              <Timeline align={'left'}>
                {filters?.map((filter, index) => {
                  return (
                    <TimelineItem key={index}>
                      <TimelineSeparator>
                        <TimelineDot>{getTimelineIcon(filter)}</TimelineDot>
                        {index < filters?.length - 1 ? <TimelineConnector /> : null}
                      </TimelineSeparator>
                      <TimelineContent>
                        <div className="flex gap-x-3 border-1 border-tw-gray-eb rounded-[40px] h-10 px-2 py-2">
                          <div>
                            {getSourceImg(filter.source) ? (
                              <img
                                alt={filter.source}
                                className="w-5 h-5 mx-1"
                                src={getSourceImg(filter.source)}
                              />
                            ) : (
                              <div className="grid w-6 h-6 mr-2 font-medium text-center rounded-full place-items-center bg-tw-blue-f2">
                                {filter.source}
                              </div>
                            )}
                          </div>
                          <div className="font-medium">
                            {data?.sourceMeta?.data.find((metaItem) => {
                              return metaItem.COLUMN_NAME === filter.columnName;
                            })?.DISPLAY_NAME || filter.keyName}
                          </div>
                          <RightChevron className="mt-1" />
                          <div className="text-gray-500">{allComparatorsMap[filter.op]}</div>
                          <RightChevron className="mt-1" />
                          {getFilterAppliedText(filter)}
                        </div>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
              </Timeline>
            </ThemeProvider>
          </div>
        </div>
      )}
    </>
  );
}
