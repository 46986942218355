import useDebounce from 'hooks/useDebounce';
import { useAuth } from 'hooks/useAuth';
import { useQuery, useQueryClient } from 'react-query';
import { useClient } from '../../../api-client';
import { useOpportunityListStore } from '../store/OpportunityListStore';

export default function useOpportunities() {
  const client = useClient();
  const { shouldFireQueries } = useAuth();
  const { queryParams } = useOpportunityListStore();
  const debouncedSearchQuery = useDebounce(queryParams.query, 400);
  const queryClient = useQueryClient();

  return useQuery(
    [
      'opportunities',
      queryParams.offset,
      debouncedSearchQuery,
      queryParams.status,
      queryParams.selectedOrderFilter,
      queryParams.destinations,
    ],
    () => client.opportunities(queryParams),
    {
      enabled: shouldFireQueries,
      // refetch counts
      onSuccess: () => {
        queryClient.refetchQueries([
          'opportunities',
          'count',
          debouncedSearchQuery,
          queryParams.destinations,
        ]);
      },
    }
  );
}
