import { useState } from 'react';
import Chip from '../data-display/Chip';

type MultiInputProps = {
  onChange?: (value: string[]) => void;
  validation?: RegExp;
  value?: string[];
};

export default function MultiInput(props: MultiInputProps) {
  const [inputValue, setInputValue] = useState('');
  const [selectedItems, setSelectedItems] = useState(props.value || []);

  function handleKeyDown(event) {
    if (event.key === 'Enter' || event.key === ',') {
      const newSelectedItems = [...selectedItems];
      const duplicatedValues = newSelectedItems.indexOf(event.target.value.trim());

      if (props.validation && !props.validation.test(event.target.value.trim())) {
        return;
      }

      if (duplicatedValues !== -1) {
        setInputValue('');
        return;
      }

      newSelectedItems.push(event.target.value.trim());
      setSelectedItems(newSelectedItems);
      setInputValue('');
      props.onChange(newSelectedItems);
    }
    if (selectedItems.length && !inputValue.length && event.key === 'Backspace') {
      const newSelectedItems = selectedItems.slice(0, selectedItems.length - 1);
      setSelectedItems(newSelectedItems);
      props.onChange(newSelectedItems);
    }
  }

  const handleDelete = (item) => () => {
    const newSelectedItems = [...selectedItems];
    newSelectedItems.splice(newSelectedItems.indexOf(item), 1);
    setSelectedItems(newSelectedItems);
    props.onChange(newSelectedItems);
  };

  function handleInputChange(event) {
    if (event.target.value.slice(-1) === ',') {
      return;
    }
    setInputValue(event.target.value);
  }
  return (
    <div className="flex flex-wrap items-center gap-2 p-4 border rounded-lg min-w-80 max-w-100 text-tw-black-5 border-tw-gray-eb">
      {selectedItems.map((item) => (
        <Chip label={item} key={item} onDelete={handleDelete(item)} />
      ))}
      <input
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        className="flex-1 focus:outline-none min-w-10"
      />
    </div>
  );
}
