import { FC } from 'react';
import { PopoverContentProps } from './PopoverContent';

export interface BooleanFilterPopoverProps extends PopoverContentProps {}

export const BooleanFilterPopover: FC<BooleanFilterPopoverProps> = (props) => {
  const { selectedBoolValue, setSelectedBoolValue, setButtonDisabled } = props;

  const handleBoolOptionSelection = (value: string) => {
    setSelectedBoolValue(value);
  };

  return (
    <div className="px-3 mt-2">
      <div className="flex flex-wrap items-center justify-start w-full mt-1">
        <div
          className={`bg-tw-gray-eb rounded-sm text-tw-black-7 text-xs py-0.5 px-3 m-1 cursor-pointer
                 ${selectedBoolValue === 'true' ? `bg-tw-blue-a text-tw-blue-0d` : ``}`}
          onClick={() => {
            handleBoolOptionSelection('true');
            setButtonDisabled(false);
          }}
        >
          True
        </div>
        <div
          className={`bg-tw-gray-eb rounded-sm text-tw-black-7 text-xs py-0.5 px-3 m-1 cursor-pointer
                 ${selectedBoolValue === 'false' ? `bg-tw-blue-a text-tw-blue-0d` : ``}`}
          onClick={() => {
            handleBoolOptionSelection('false');
            setButtonDisabled(false);
          }}
        >
          False
        </div>
      </div>
    </div>
  );
};
