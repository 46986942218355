import { PrimaryButton } from 'ui-components/inputs/Buttons';
import { capitalizeFirstLetter } from 'utils/common';
import useEntities from 'hooks/useEntities';
import { DefinePQLSections, useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { useEffect } from 'react';
import { QueryType } from 'stores/QueryStore';
import AccordionHeader from '../AccordionHeader';
import Card from '../../../../ui-components/business/Card';
import SectionAccordion from '../SectionAccordion';
import LockedEntity from './LockedEntity';

export default function SelectEntity() {
  const {
    targetEntity,
    currentSection,
    setCurrentSection,
    setTargetEntity,
    sectionsMap,
    setModelId,
    setSectionsMap,
  } = useDefinePQLStore();

  const { data: entities, isLoading, isError } = useEntities();

  useEffect(() => {
    const enabledEntities = entities?.filter((e) => e.enabled);
    if (enabledEntities?.length >= 1 && !targetEntity) {
      setTargetEntity(QueryType[enabledEntities[0].id]);
    }
  }, [entities, setTargetEntity]);

  return (
    <SectionAccordion
      id={DefinePQLSections.ENTITIES}
      hasPadding={true}
      accordionHeader={
        <AccordionHeader
          onClick={() => {
            // if not on current section, mark it as current,
            // then mark all other next steps to not finished
            let newSectionsMap = {};
            let refIndex = Object.keys(sectionsMap).indexOf(DefinePQLSections.ENTITIES);
            (Object.keys(DefinePQLSections) as Array<keyof typeof DefinePQLSections>).forEach(
              (key, index) => {
                if (index >= refIndex) {
                  newSectionsMap[key.toLowerCase()] = false;
                }
              }
            );
            setSectionsMap({
              ...sectionsMap,
              ...newSectionsMap,
            });
          }}
        >
          {capitalizeFirstLetter(targetEntity.toLowerCase()) || 'Qualify users'}
        </AccordionHeader>
      }
      expanded={currentSection === DefinePQLSections.ENTITIES}
    >
      <div className="font-medium rounded-b-xl text-tw-black-1">
        Do you work on users or accounts?
      </div>
      {isLoading || isError ? (
        <div className="flex mt-4 animate-pulse">
          <div className="w-1/5 h-16 rounded-lg bg-tw-gray-eb"></div>
          <div className="w-1/5 h-16 ml-4 rounded-lg bg-tw-gray-eb"></div>
        </div>
      ) : (
        <div className="flex items-center mt-4">
          {entities
            .sort((a, b) => {
              if (a.enabled && b.enabled) {
                return 0;
              }
              return a.enabled ? -1 : 1;
            })
            .map((entity) => {
              if (!entity.enabled) {
                return <LockedEntity key={entity.id} entity={entity} />;
              }

              return (
                <Card
                  key={entity.id}
                  isActive={targetEntity.toLowerCase() === entity.id}
                  onClick={() => {
                    setTargetEntity(QueryType[entity.id]);
                    // reset modelId when different entity is selected.
                    // When targetEntity changes, a default modelId will
                    // be selected in SelectModelId.tsx
                    // else further states will be preserved.
                    if (targetEntity !== QueryType[entity.id]) {
                      setModelId(null);
                    }
                  }}
                  label={entity.name}
                />
              );
            })}
        </div>
      )}
      <div className="mt-6">
        <PrimaryButton
          className="w-full product-tour-2"
          onClick={() => {
            // if DS, go to next section GOALS, else go to filters directly.
            setSectionsMap({
              ...sectionsMap,
              // mark entities as finished
              [DefinePQLSections.ENTITIES]: true,
            });
            setCurrentSection(DefinePQLSections.GOALS);
          }}
          disabled={!targetEntity || isLoading || isError}
        >
          Proceed
        </PrimaryButton>
      </div>
    </SectionAccordion>
  );
}
