import create from 'zustand';
import { UserListStoreInterface, FieldMapping, FilterExpression } from '../../users/types';

export const useUserAccountsStore = create<UserListStoreInterface>((set, get) => ({
  page: 1,
  setPage: (page) => set(() => ({ page })),
  searchQuery: '',
  setSearchQuery: (searchQuery: string) => set(() => ({ searchQuery })),
  field_mapping: {},
  initFieldMappings: (initialMappings: { [key: string]: FieldMapping } | {}) =>
    set(() => ({
      field_mapping: initialMappings,
    })),
  setFieldMapping: (obj: FieldMapping) =>
    set((state) => ({
      field_mapping: {
        ...state.field_mapping,
        [obj.keyName]: obj,
      },
    })),
  removeFieldMapping: (keyName: string) => {
    let newState = get().field_mapping;
    delete newState[keyName];
    set(() => ({ field_mapping: { ...newState } }));
  },
  sortBy: [],
  setSortBy: (sortBy) => set(() => ({ sortBy: sortBy ? [sortBy] : [] })),
  filterExpression: [],
  setFilterExpression: (obj: FilterExpression, replace?: boolean) => {
    if (replace) {
      set(() => ({
        filterExpression: [obj],
      }));
    } else {
      set((state) => ({
        filterExpression: [...state.filterExpression, obj],
      }));
    }
  },
  removeFilterExpression: (keyname: string) => {
    let newState = get().filterExpression;
    let objIndex = newState.findIndex(({ keyName }) => keyName === keyname);
    delete newState[objIndex];
    set(() => ({ filterExpression: newState }));
  },
}));
