import * as React from 'react';
import { classNames } from '../../utils/common';

interface ProgressBarProps {
  parts?: number;
  colors?: string[];
  weight?: number;
  toolTip?: string;
}

const circlesFromWeight = (weight: number): Array<boolean> => {
  if (weight === 0) {
    return [...Array(4).fill(false)];
  } else if (weight <= 40) {
    return [...Array(1).fill(true), ...Array(3).fill(false)];
  } else if (weight <= 60) {
    return [...Array(2).fill(true), ...Array(2).fill(false)];
  } else if (weight <= 80) {
    return [...Array(3).fill(true), ...Array(1).fill(false)];
  } else {
    return [...Array(4).fill(true)];
  }
};

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const weight = props.weight;

  return (
    <div>
      <div className="flex gap-x-2">
        {circlesFromWeight(weight).map((c, index) => {
          return (
            <div
              key={index}
              className={classNames(
                `rounded-full h-2.5 w-2.5`,
                c ? 'bg-tw-black-3' : 'bg-tw-gray-ef'
              )}
            ></div>
          );
        })}
      </div>
      <div className="mt-1 text-xs text-tw-black-7">{props.toolTip}</div>
    </div>
  );
};

export default ProgressBar;
