import { useAuth } from 'hooks/useAuth';
import useDsEnabled from 'hooks/useDsEnabled';
import { useDefinePQLStore } from '../../store/PQLDefineStore';
import SelectCohort from './SelectCohort';
import SelectConversionEvent from './SelectConversionEvent';
import SelectEntity from './SelectEntity';
import SelectHWC from './SelectHWC';
import SelectGoal from './SelectModelId';

// This component would manage when the entire subsection is finished then call the
// next "step" of the workflow.
export default function DefineSubSections() {
  const { user } = useAuth();
  const { modelId, targetEntity } = useDefinePQLStore();
  const dsEnabled = useDsEnabled(modelId, targetEntity);
  return (
    <div className="rounded-lg bg-tw-white-ff">
      <SelectEntity />
      <SelectGoal />
      {!dsEnabled && <SelectConversionEvent />}
      {/* OVERRIDE_ENABLE_SYSTEM_COHORTS */}
      {user?.currentOrganization?.isPersonas && !dsEnabled && <SelectCohort />}
      {dsEnabled && <SelectHWC />}
    </div>
  );
}
