import { toast as toastify } from 'react-toastify';
import { useAuthStore } from 'hooks/useAuthStore';
import { useMutation } from 'react-query';
import { useClient } from '../../../api-client';
import { SignUpByInviteData } from '../../../hooks/useAuth';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';

export default function useSignUpByInvite() {
  const client = useClient();
  const { removeAccountId } = useAuthStore();
  return useMutation((data: SignUpByInviteData) => client.signUpUser(data?.token, data?.password), {
    onMutate: () => {
      removeAccountId();
    },
    onError: () => {
      toastify(<ErrorToast />, {
        type: 'error',
      });
    },
  });
}
