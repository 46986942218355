import { ActivationStatus, useClient } from 'api-client';
import { useMutation } from 'react-query';

export default function useModifyEntityActivationStatus(activationStatus: ActivationStatus) {
  const client = useClient();

  return useMutation(({ entityId }: { entityId: string }) =>
    client.modifyEntityActivationStatus(entityId, activationStatus)
  );
}
