import { useApiClient } from 'api-client';
import { AxiosInstance } from 'axios';
import Loader from 'ui-components/feedback/Loader';
import { useAuth } from 'hooks/useAuth';
import { useAuthStore } from 'hooks/useAuthStore';
import { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { routes } from 'routing/clientRoutes';
import { ACCOUNT_ID, ACCOUNT_SCHEMANAME } from '../utils/globalTypes';

export default function GlobalRedirect() {
  const { getAccountId, setAccountId, getAccountSchemaName } = useAuthStore();

  const { user, isLoading, isError } = useAuth();
  const client = useApiClient();
  const [redirectPath, setRedirectPath] = useState(null);

  const accountIdFromLocalStorage =
    localStorage.getItem(ACCOUNT_ID) === 'null' ? null : localStorage.getItem(ACCOUNT_ID);
  const accountSchemaNameFromLocalStorage =
    localStorage.getItem(ACCOUNT_SCHEMANAME) === 'null'
      ? null
      : localStorage.getItem(ACCOUNT_SCHEMANAME);

  const updateAccountIdToStorageAndHeaders = useCallback(
    (accountId: string, path: string, apiClient: AxiosInstance, schemaName: string) => {
      setAccountId(accountId, schemaName);
      apiClient.defaults.headers.common['TL-ORG'] = schemaName;
      setRedirectPath(path);
    },
    [setAccountId]
  );

  useEffect(() => {
    if (user) {
      let accountIdToPersist: string | null = null,
        pathToRedirect: string = '/',
        schemaName: string | null = null;
      // priority wise accountId setters.
      // Priority 1: accountId & schemaName from session storage
      if (
        getAccountId() &&
        (user?.isStaff || user?.organizations.find((org) => org.orgId === getAccountId())) &&
        getAccountSchemaName()
      ) {
        accountIdToPersist = getAccountId();
        pathToRedirect = `${getAccountId()}${routes.opportunitiesList}`;
        schemaName = getAccountSchemaName();
      } else if (
        // Priority 2: accountId & schemaName from local storage
        accountIdFromLocalStorage &&
        (user?.isStaff || user?.organizations.find((org) => org.orgId === getAccountId())) &&
        accountSchemaNameFromLocalStorage
      ) {
        accountIdToPersist = accountIdFromLocalStorage;
        pathToRedirect = `${accountIdFromLocalStorage}${routes.opportunitiesList}`;
        schemaName = accountSchemaNameFromLocalStorage;
      } else if (user?.defaultOrganization) {
        // Priority 3: accountId & schemaName from users default org
        accountIdToPersist = user?.defaultOrganization?.orgId;
        pathToRedirect = `${user?.defaultOrganization?.orgId}${routes.opportunitiesList}`;
        schemaName = user?.defaultOrganization?.schemaName;
      } else if (user?.currentOrganization?.schemaName) {
        // Priority 4: accountId & schemaName from current org
        accountIdToPersist = user?.currentOrganization?.orgId;
        pathToRedirect = `${user?.currentOrganization?.orgId}${routes.opportunitiesList}`;
        schemaName = user?.currentOrganization?.schemaName;
      } else if (user?.organizations.length) {
        // Priority 5: accountId & schemaName from first org
        accountIdToPersist = user?.organizations[0].orgId;
        pathToRedirect = `${user?.organizations[0].orgId}${routes.opportunitiesList}`;
        schemaName = user?.organizations[0].schemaName;
      }

      updateAccountIdToStorageAndHeaders(accountIdToPersist, pathToRedirect, client, schemaName);
    }
  }, [
    user,
    getAccountId,
    accountIdFromLocalStorage,
    client,
    updateAccountIdToStorageAndHeaders,
    getAccountSchemaName,
    accountSchemaNameFromLocalStorage,
  ]);

  if (isError) {
    // redirect to login if login request fails
    return <Redirect to={routes.login} />;
  }

  if (isLoading || !user || !redirectPath) {
    return (
      <div className="grid h-screen place-items-center">
        <Loader height={100} width={100} />
      </div>
    );
  }

  if (!user?.currentOrganization?.isActive && user?.currentOrganization?.isSandbox) {
    return <Redirect to={routes.chooseDataset} />;
  } else if (user?.currentOrganization?.isActive || user?.currentOrganization?.isSandbox) {
    return <Redirect to={redirectPath} />;
  } else if (user.hasSimilarOrganization && user.organizations.length === 0) {
    return <Redirect to={routes.existingOrganization} />;
  }

  return <Redirect to={redirectPath} />;
}
