import { InfoIcon } from 'ui-components/data-display/Icons';
import * as React from 'react';
import { forwardRef, RefObject } from 'react';
import { classNames } from '../../../utils/common';
import { useIntegrationListStore } from '../stores/IntegrationListStore';
import IntegrationItem from './IntegrationItem';

interface IntegrationItemWithHeadingProps {
  integrations: any[];
  isSource?: boolean;
  heading?: string;
  inactive?: boolean;
  headingDescription?: string;
  typeOfIntegration: string;
  requestedApp?: boolean;
  isConnected?: boolean;
  ref?: RefObject<HTMLDivElement>;
  scrollCallback?: any;
  requestedAppRef?: any;
}

const IntegrationItemWithHeading = forwardRef(
  (props: IntegrationItemWithHeadingProps, forwardedRef: RefObject<HTMLDivElement>) => {
    IntegrationItemWithHeading.displayName = 'IntegrationItemWithHeadingComponent';

    const {
      integrations,
      isSource,
      heading,
      inactive,
      headingDescription,
      typeOfIntegration,
      isConnected,
      requestedApp,
      scrollCallback,
    } = props;

    const { searchQuery } = useIntegrationListStore();

    return (
      <div ref={forwardedRef}>
        <div>
          {integrations?.length === 0 ? (
            typeOfIntegration === 'available' && searchQuery ? (
              <>
                <div className="space-y-1">
                  <p className="text-base font-medium uppercase text-tw-black-7">{heading}</p>
                </div>
                <NoAppsFoundComponent message={'No available apps found'} />
              </>
            ) : null
          ) : (
            <>
              {integrations?.length && (
                <>
                  <div className="space-y-1">
                    <p className="text-base font-medium uppercase text-tw-black-7">{heading}</p>
                    <p className="text-xs font-normal text-tw-gray-7">{headingDescription}</p>
                  </div>
                  <div className="w-full">
                    <div
                      className={classNames(
                        'w-full',
                        isConnected
                          ? 'grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-2'
                          : 'grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 mt-3 mb-8'
                      )}
                    >
                      {integrations?.map((integration) => (
                        <IntegrationItem
                          inactive={inactive}
                          key={integration?.id}
                          integrationId={integration?.id}
                          {...integration}
                          connectedIntegrationId={isConnected ? integration.id : null}
                          isSource={isSource}
                          isConnected={isConnected}
                          requestedApp={requestedApp}
                          requestedAppRef={forwardedRef}
                          scrollCallback={scrollCallback}
                          logoUrl={
                            isConnected ? integration.integration.logoUrl : integration.logoUrl
                          }
                          integrationName={
                            isConnected ? integration?.integration?.name : integration?.name
                          }
                        />
                      ))}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
);

export default IntegrationItemWithHeading;

IntegrationItemWithHeading.defaultProps = {
  inactive: false,
  requestedApp: false,
  headingDescription: '',
  heading: '',
  isConnected: false,
  scrollCallback: () => {},
};

interface NoAppsFoundComponentProps {
  message: string;
}

export const NoAppsFoundComponent: React.FC<NoAppsFoundComponentProps> = (props) => {
  const { message } = props;
  return (
    <div className="flex flex-row my-8 space-x-2 place-items-center">
      <InfoIcon className="w-4 fill-current text-tw-black-1" />
      <div>
        <p className="text-base font-medium text-tw-black-1">{message}</p>
      </div>
    </div>
  );
};
