import { useAuth } from 'hooks/useAuth';
import { useQuery } from 'react-query';
import { useClient } from '../../../api-client';

type OrganisationResponse = {
  schemaName: string;
  name: string;
  orgId: string;
  callScheduled: boolean;
  domain: string;
  status: string;
  timezone: string;
};

export default function useGetAllOrganizations(active = true) {
  const client = useClient();
  const { user } = useAuth();

  return useQuery('allOrganizations', () => client.getAllOrganizations(active), {
    select: (organizations: OrganisationResponse[]) => {
      return organizations.map(({ name, schemaName, orgId }) => ({
        id: schemaName,
        name: name,
        orgId,
        schemaName,
      }));
    },
    enabled: !!user && user?.isStaff,
  });
}
