import Modal from 'ui-components/util/modal';
import { SecondaryButton, TertiaryButton } from 'ui-components/inputs/Buttons';
import useDeleteMember from 'pages/settings/hooks/useDeleteMember';

export const DeleteTeamMemberModal = ({ showValue, onCloseModal }) => {
  const deleteMemberHook = useDeleteMember();

  const onCloseDelModal = () => {
    onCloseModal(false);
  };
  const deleteMember = () => {
    deleteMemberHook.mutate(showValue, {
      onSettled: () => {
        onCloseModal(false);
      },
    });
  };

  return (
    <Modal
      open={showValue.show}
      onClose={() => {
        onCloseDelModal();
      }}
      title={'Deleting team member'}
    >
      <div className="mt-3 p-5">
        <div className="inline-block p-2 mb-3 overflow-hidden rounded bg-tw-gray-eb">
          <p className="mb-0 font-semibold">{showValue.email}</p>
        </div>
        <p className="mb-4 text-base text-tw-black-5">
          On deleting this member, they will no longer be allowed to login to this account.
        </p>
        <p className="mb-4 text-base text-tw-black-5">Are you sure you want to delete?</p>
      </div>
      <hr className="w-full" />
      <div className="flex justify-end p-4 gap-x-2">
        <TertiaryButton
          onClick={() => {
            onCloseDelModal();
          }}
        >
          Cancel
        </TertiaryButton>
        <SecondaryButton
          className="w-40 text-tw-red-dd"
          bgClassName="bg-tw-red-ff"
          borderClassName="border-tw-red-dd"
          onClick={() => {
            deleteMember();
          }}
          isLoading={deleteMemberHook.isLoading}
        >
          Delete Member
        </SecondaryButton>
      </div>
    </Modal>
  );
};
