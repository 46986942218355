import * as React from 'react';
import { useRef } from 'react';
import { Search } from '../../../ui-components/inputs/Search/Search';
import { classNames } from '../../../utils/common';
import { useAnalytics } from '../../../telemetry';
import { AnalyticsConst, AnalyticsEvents } from '../../../telemetry/constants';
import useFilteredIntegrations from '../hooks/useFilteredIntegrations';
import { IntegrationsState } from '../IntegrationList';
import { useIntegrationListStore } from '../stores/IntegrationListStore';
import IntegrationItemWithHeading from './IntegrationItemWithHeading';
import IntegrationsFilterTab from './IntegrationsFilterTab';

interface PanelForDestinationProps {
  integrationsState?: IntegrationsState;
  isIntegrationsLoading: boolean;
  isSource?: boolean;
}

const PanelForDestination: React.FC<PanelForDestinationProps> = ({
  isIntegrationsLoading,
  isSource,
}) => {
  const { searchQuery, setSearchQuery } = useIntegrationListStore();
  const { track } = useAnalytics();

  const [activeIndex, setActiveIndex] = React.useState<string>('All Apps');

  const { categoriesMap } = useFilteredIntegrations();

  const requestedAppsRef = useRef<HTMLDivElement>();

  const scrollToRequestedApps = () => {
    setTimeout(() => {
      requestedAppsRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 0);
  };

  return (
    <>
      <div className="grid grid-cols-5 mt-8">
        <IntegrationsFilterTab activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
        <div className="col-span-4">
          <div className="px-2 pb-2">
            <Search
              className="mb-8"
              placeholder="Search apps"
              value={searchQuery}
              onChangeFunction={(e) => {
                setActiveIndex('All Apps');
                setSearchQuery(e.target.value);
                track(AnalyticsEvents.INTEGRATION_SEARCH_QUERY, {
                  [AnalyticsConst.SEARCH_QUERY]: e.target.value,
                });
              }}
              onClearFunction={() => {
                setSearchQuery('');
              }}
            />

            <>
              <div
                className={classNames(
                  categoriesMap?.[activeIndex]?.['connected']?.length ? 'mb-8' : ''
                )}
              >
                {categoriesMap?.[activeIndex]?.['connected']?.length ? (
                  <div className="flex items-center gap-x-2">
                    <div className="text-base font-medium text-tw-black-7">CONNECTED APPS</div>
                    <div className="px-2 py-1 font-semibold rounded bg-tw-green-e7 text-tw-green-20">
                      LIVE
                    </div>
                  </div>
                ) : null}
                <IntegrationItemWithHeading
                  integrations={categoriesMap?.[activeIndex]?.['connected']}
                  isSource={isSource}
                  typeOfIntegration={'connected'}
                  isConnected={true}
                />
              </div>

              {activeIndex === 'Newly launched' && (
                <IntegrationItemWithHeading
                  integrations={categoriesMap?.[activeIndex]}
                  isSource={isSource}
                  heading={'newly launched 🎉'}
                  typeOfIntegration={'newlyLaunched'}
                />
              )}

              {activeIndex === 'Popular' && (
                <IntegrationItemWithHeading
                  integrations={categoriesMap?.[activeIndex]}
                  isSource={isSource}
                  heading={'popular'}
                  typeOfIntegration={'popular'}
                />
              )}

              <IntegrationItemWithHeading
                integrations={categoriesMap?.[activeIndex]?.['available']}
                isSource={isSource}
                heading={'available apps'}
                typeOfIntegration={'available'}
              />

              <IntegrationItemWithHeading
                integrations={categoriesMap?.[activeIndex]?.['requested']}
                isSource={isSource}
                heading={'requested apps'}
                ref={requestedAppsRef}
                requestedApp={true}
                typeOfIntegration={'requestedApp'}
              />

              <IntegrationItemWithHeading
                integrations={categoriesMap?.[activeIndex]?.['inactive']}
                isSource={isSource}
                heading={'get more apps'}
                inactive={true}
                scrollCallback={scrollToRequestedApps}
                headingDescription={
                  'Put in your request and our team will build the destination integration for you. Estimated time to build: 5 business days'
                }
                typeOfIntegration={'inactive'}
              />

              {/* Unavailable integrations */}
              {isIntegrationsLoading && (
                <div className="grid grid-cols-3 gap-4 mt-2 animate-pulse">
                  {Array(3)
                    .fill(0)
                    .map((v, idx) => (
                      <div key={idx} className="w-full h-24 bg-tw-gray-eb"></div>
                    ))}
                  <div className="w-full h-24 col-span-4 bg-tw-gray-eb mt-11" />
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default PanelForDestination;
