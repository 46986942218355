import Loader from 'ui-components/feedback/Loader';
import AlternateTopNav from 'ui-components/business/navbars/AlternateTopNav';
import { useAuth } from 'hooks/useAuth';
import { useAuthStore } from 'hooks/useAuthStore';
import useEditUserDetails from 'hooks/useEditUserDetails';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import RequestToJoinOrg from '../../ui-components/business/RequestToJoinOrg';
import useCreateSandbox from '../../hooks/useCreateSandbox';

export default function ExistingOrganization() {
  const { user, isLoading } = useAuth();
  const { setAccountId } = useAuthStore();
  const editUserDetails = useEditUserDetails();
  const createSandbox = useCreateSandbox();
  const router = useHistory();

  const handleCreateNewOrganization = () => {
    createSandbox.mutate(
      {},
      {
        onSuccess: (resp) => {
          setAccountId(resp.orgId, resp.schemaName);
          router.push('/');
        },
      }
    );
  };

  useEffect(() => {
    if (user && !user?.hasSimilarOrganization) {
      router.push('/');
    }
  }, [user]);

  return (
    <div className="flex flex-col h-screen">
      <AlternateTopNav className={'mt-6 ml-8'} />
      <div className="grid h-full grow place-items-center">
        {isLoading || createSandbox.isLoading || editUserDetails.isLoading ? (
          <Loader height={100} width={100} />
        ) : (
          <RequestToJoinOrg
            CTA={'Create a new account'}
            handleClickCTA={handleCreateNewOrganization}
          />
        )}
      </div>
    </div>
  );
}
