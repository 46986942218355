import { LoggedInUser } from 'api-client';
import { classNames, getUserInitials } from 'utils/common';

type InitialsAvatarProps = {
  user: LoggedInUser;
  fontClass?: string;
};

export default function InitialsAvatar({ user, fontClass }: InitialsAvatarProps) {
  return (
    <span
      className={classNames(
        'flex items-center justify-center h-full font-bold text-white uppercase rounded-full bg-gradient-to-r from-tw-primary via-tw-blue-31 to-tw-blue-6f',
        fontClass || 'text-2xl'
      )}
    >
      {getUserInitials({
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
      })}
    </span>
  );
}
