import usePerformance from '../hooks/usePerformance';
import { motion } from 'framer-motion';
import { PrimaryButton } from 'ui-components/inputs/Buttons';
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
} from 'recharts';
import Toggle from 'ui-components/inputs/Switch';
import { useAuthStore } from '../../../hooks/useAuthStore';
import { PQL_STATS_PAGE_PADDING_CLASSNAME } from '../NewOpDetails';
import { ReactComponent as DropdownArrowIcon } from 'assets/images/dropdown-arrow.svg';
import VirtualizedSelect from 'ui-components/inputs/VirtualizedSelect';
import * as Popover from '@radix-ui/react-popover';
import { OpportunityStatus } from 'api-client';
import {
  BarSeperator,
  Calendar,
  ChartLeft,
  ChartRight,
  SandboxPerfStats,
  ScheduleIcon,
  TrendUp,
  TrendDown,
} from 'ui-components/data-display/Icons';
import { useState, useRef, useCallback, useEffect } from 'react';
import { capitalizeFirstLetter, classNames, convertInOrgTimezone } from 'utils/common';
import { toast as toastify } from 'react-toastify';
import { goalsWordDictionary } from '../constants';
import useUpdateOpportunity from '../hooks/useUpdateOpportunity';
import { FilledInfoWithWave } from 'ui-components/data-display/Icons';
import { SchedulePQL } from 'ui-components/data-display/Icons';
import { getProfileEvents } from '../common/utils';
import Banner from 'ui-components/feedback/Banner';
import useDsEnabled from '../../../hooks/useDsEnabled';
import { getSourceImg, convertDay, convertDate } from '../../../utils/common';
import { modelToPerformanceVerbMap } from 'pages/pql-workflow/components/define-pql/SelectConversionEvent';
import { OpportunityResponse } from 'ui-components/business/opportunities/types';
import { getCadenceType, CadenceType } from '../../opportunities/DisplayPQLCadence';
import usePrevious from 'hooks/usePreviousValue';
import dayjs from 'dayjs';
import omit from 'lodash/omit';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import useActivateOrganization from '../../../hooks/useActivateOrganization';
import useMeta from '../hooks/useMeta';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';
import RangedDatePicker from '../../../ui-components/inputs/RangedDatePicker/RangedDatePicker';
import { modelToQuestionMap } from '../../pql-workflow/components/define-pql/SelectConversionEvent';

export function getGoalVerb(verbPast, entity) {
  if (verbPast === 'did not churn') {
    return `${capitalizeFirstLetter(entity)} who didn't churn`;
  }
  return `${capitalizeFirstLetter(verbPast)} ${entity}`;
}
const NUMBER_OF_BARS = 30;

function getPerformanceRequestPayload(dateRange) {
  return {
    start: `${dayjs(dateRange.startDate).format('YYYY-MM-DD')}${dayjs(new Date())
      .tz('UTC')
      .format('THH:mm:00.000[Z]')}`,
    end: `${dayjs(dateRange.endDate).format('YYYY-MM-DD')}${dayjs(new Date())
      .tz('UTC')
      .format('THH:mm:00.000[Z]')}`,
  };
}

function getHWCTimeseriesData(timeSeriesData, qualTimeSeries) {
  const HWCTimeseriesData = [];
  for (let tsd in timeSeriesData) {
    const dataObject = {
      hot: timeSeriesData[tsd]?.hot?.perc || 0,
      warm: timeSeriesData[tsd]?.warm?.perc || 0,
      cold: timeSeriesData[tsd]?.cold?.perc || 0,
      hotCount: timeSeriesData[tsd]?.hot?.count || 0,
      warmCount: timeSeriesData[tsd]?.warm?.count || 0,
      coldCount: timeSeriesData[tsd]?.cold?.count || 0,
      qualified: qualTimeSeries[tsd]?.total?.count || 0,
      hotQualified: qualTimeSeries[tsd]?.hot?.count || 0,
      warmQualified: qualTimeSeries[tsd]?.warm?.count || 0,
      coldQualified: qualTimeSeries[tsd]?.cold?.count || 0,
      // hot: Math.random(),
      // warm: Math.random(),
      // cold: Math.random(),
      day: convertDay(tsd),
      date: convertDate(tsd),
    };
    HWCTimeseriesData.push(dataObject);
  }
  return HWCTimeseriesData;
}

function getConversionTimeseriesData(timeSeriesData, qualTimeSeries, entity, goal, sliceIndex) {
  const conversionTimeSeriesData = [];
  for (let tsd in timeSeriesData) {
    const dataObject = {
      count: timeSeriesData[tsd]?.total?.count || 0,
      perc: timeSeriesData[tsd]?.total?.perc || 0,
      qualified: qualTimeSeries[tsd]?.total?.count || 0,
      //count: Math.ceil(Math.random() * 2000),
      //perc: Math.random(),
      //qualified: Math.ceil(Math.random() * 20000),
      day: convertDay(tsd),
      date: convertDate(tsd),
      entity: entity?.toLowerCase(),
      modelVerb: goalsWordDictionary[goal]?.verbPast,
    };
    conversionTimeSeriesData.push(dataObject);
  }
  return conversionTimeSeriesData.slice(sliceIndex, sliceIndex + NUMBER_OF_BARS);
}

const renderLegend = () => {
  return (
    <ul style={{ textAlign: 'center', marginTop: 32, fontSize: 12 }}>
      <li style={{ display: 'inline-block', color: '#999999' }}>X-axis:</li>
      <li style={{ display: 'inline-block', marginLeft: 5 }}>Playbook run dates</li>
      <li style={{ display: 'inline-block', marginLeft: 8, position: 'relative', top: -2 }}>
        <DotCircle color={'#999999'} size={1} />
      </li>
      <li style={{ display: 'inline-block', marginLeft: 8, position: 'relative', top: 1 }}>
        <Rectangle color={'#FD6969'} />
      </li>
      <li style={{ display: 'inline-block', marginLeft: 8 }}>Hot</li>
      <li style={{ display: 'inline-block', marginLeft: 8, position: 'relative', top: 1 }}>
        <Rectangle color={'#FDB03B'} />
      </li>
      <li style={{ display: 'inline-block', marginLeft: 8 }}>Warm</li>
      <li style={{ display: 'inline-block', marginLeft: 8, position: 'relative', top: 1 }}>
        <Rectangle color={'#55B1F4'} />
      </li>
      <li style={{ display: 'inline-block', marginLeft: 8 }}>Cold</li>
    </ul>
  );
};
const ConversionLegend = (props: any) => {
  return (
    <>
      {props.showPageAnchors && (
        <ChartLeft
          style={{
            position: 'relative',
            top: -10,
            left: 20,
            visibility: props.showLeftAnchor ? 'visible' : 'hidden',
          }}
          onClick={() => {
            props.onPageChange(Math.max(1, props.pageNumber - 1));
          }}
        />
      )}
      <ul style={{ textAlign: 'center', marginTop: 32, fontSize: 12 }}>
        <li style={{ display: 'inline-block', color: '#999999' }}>{'X-axis: '}</li>
        <li style={{ display: 'inline-block', marginLeft: 5 }}>Playbook run dates</li>
      </ul>
      {props.showPageAnchors && (
        <ChartRight
          style={{
            float: 'right',
            top: -84,
            position: 'relative',
            left: -10,
            visibility: props.showRightAnchor ? 'visible' : 'hidden',
          }}
          onClick={() => {
            props.onPageChange(Math.min(props.noOfPages, props.pageNumber + 1));
          }}
        />
      )}
    </>
  );
};

const ChartTooltip = (props) => {
  return (
    <div className="px-4 py-4 text-xs border rounded-md bg-tw-white-ff shadow-3xl drop-shadow-3xl border-tw-gray-eb">
      <div className="text-sm font-medium">{props.payload[1]?.payload?.date || props.label}</div>
      <div className="flex justify-between mt-4 text-xs gap-x-8">
        <div className="flex items-center gap-2">
          <DotCircle color={'#FD6969'} />
          <span>Hot users</span>
        </div>
        <div className="flex gap-x-1">
          <div className="font-medium">{`${props.payload?.[0]?.value?.toFixed(2) || 0}%`}</div>
          <div className="text-xs text-tw-black-7">{`(${props.payload?.[0]?.payload?.hotCount} out of ${props.payload?.[0]?.payload?.hotQualified})`}</div>
        </div>
      </div>
      <div className="flex justify-between mt-3 text-xs gap-x-8">
        <div className="flex items-center gap-2">
          <DotCircle color={'#FDB03B'} />
          <span>Warm users</span>
        </div>
        <div className="flex gap-x-1">
          <div className="font-medium">{`${props.payload?.[1]?.value?.toFixed(2) || 0}%`}</div>
          <div className="text-xs text-tw-black-7">{`(${props.payload?.[0]?.payload?.warmCount} out of ${props.payload?.[0]?.payload?.warmQualified})`}</div>
        </div>
      </div>
      <div className="flex justify-between mt-3 text-xs gap-x-8">
        <div className="flex items-center gap-2">
          <DotCircle color={'#55B1F4'} />
          <span>Cold users</span>
        </div>
        <div className="flex gap-x-1">
          <div className="font-medium">{`${props.payload?.[2]?.value?.toFixed(2) || 0}%`}</div>
          <div className="text-xs text-tw-black-7">{`(${props.payload?.[0]?.payload?.coldCount} out of ${props.payload?.[0]?.payload?.coldQualified})`}</div>
        </div>
      </div>
    </div>
  );
};

const ConversionChartTooltip = (props) => {
  return (
    <div className="px-4 py-4 text-xs border rounded-md bg-tw-white-ff shadow-3xl drop-shadow-3xl border-tw-gray-eb">
      <div className="text-sm font-medium">{props.payload?.[0]?.payload?.date || props.label}</div>
      <div className="flex justify-between mt-3 text-xs gap-x-8">
        <div className="flex items-center gap-2">
          <DotCircle color={'#0E6EFD'} />

          <span>{`Qualified ${props.payload?.[0]?.payload?.entity || 'users'}`}</span>
        </div>
        <div className="font-medium">{`${props.payload?.[0]?.value || 0}`}</div>
      </div>
      <div className="flex justify-between mt-6 text-xs gap-x-8">
        <div className="flex items-center gap-2">
          <DotCircle color={'#FF9F3E'} />
          <span>{`% ${props.payload?.[0]?.payload?.entity || 'users'} who ${
            props.payload?.[0]?.payload?.modelVerb || 'converted'
          }`}</span>
        </div>
        <div className="font-medium">{`${props.payload?.[1]?.value.toFixed(2) || 0.0}%`}</div>
      </div>
    </div>
  );
};

function areStatsAvailable(opportunity) {
  return opportunity.lastRun;
}

const NoData = (props) => {
  return (
    <div className="grid my-20 overflow-x-scroll overflow-y-hidden place-items-center text-tw-black-7">
      <ScheduleIcon className="w-20 h-20" />
      <div className="mt-5 text-lg font-medium text-tw-black-1">This Playbook has not run yet</div>
      <div className="text-tw-black-7">{props.secondaryText}</div>
    </div>
  );
};

// eslint-disable-next-line
export const ConversionEvent = (props) => {
  const { opportunity, containerClassName, ctaClassName } = props;
  const { data } = useMeta(
    opportunity.query?.type,
    opportunity.query?.modelId,
    opportunity.query?.goal
  );
  const updateOpportunity = useUpdateOpportunity(opportunity.id);
  const [localTrackEvent, setLocalTrackEvent] = useState(null);
  const sourceItems = getProfileEvents(data?.sourceMeta?.data);

  const [isLoadingOpp, setIsLoadingOpp] = useState(false);
  const isLoadingPrev = usePrevious(updateOpportunity.isLoading);

  useEffect(() => {
    // set isLoading as true for "Confirm" CTA until we have updated performanceTrackeEvent in opportunity and fetched the updated opportunity
    if (
      (isLoadingPrev &&
        !updateOpportunity.isLoading &&
        !opportunity?.query?.performanceTrackEvent) ||
      updateOpportunity.isLoading
    ) {
      setIsLoadingOpp(true);
    } else setIsLoadingOpp(false);
  }, [isLoadingPrev, updateOpportunity.isLoading, opportunity?.query?.performanceTrackEvent]);

  const conversionEventChange = (value) => {
    updateOpportunity.mutate(
      {
        opportunityId: opportunity.id,
        query: { ...opportunity.query, performanceTrackEvent: value },
      },
      {
        onError: () => {
          toastify(<ErrorToast />, {
            type: 'error',
          });
        },
      }
    );
  };

  if (opportunity.query?.performanceTrackEvent?.value) {
    return (
      <div className="inline-block font-medium">
        <div>{`${modelToPerformanceVerbMap[opportunity.query?.modelId]}`}</div>
        <div className="flex px-2 py-2 mt-4 rounded-lg border-1 border-tw-gray-eb gap-x-1 h-9">
          <img
            alt={opportunity.query?.performanceTrackEvent?.source}
            className="w-3 h-3 mt-1"
            src={getSourceImg(opportunity.query?.performanceTrackEvent?.source)}
          />
          <div className="text-sm">{opportunity.query?.performanceTrackEvent?.value}</div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'w-9/12 px-8 py-8 rounded-lg border-1 border-tw-gray-eb',
        containerClassName
      )}
    >
      <div className="font-medium rounded-b-xl text-tw-black-1">
        How do you want to track performance ?
      </div>
      <div className="flex gap-4 py-4">
        <div className="mt-2 text-tw-black-3 shrink-0">
          {modelToQuestionMap[opportunity.query?.modelId]}
        </div>
        <VirtualizedSelect
          onChange={(value: string) => {
            const source = sourceItems.find((sourceItem) => {
              return sourceItem.value === value;
            })?.source;
            setLocalTrackEvent({ value, source });
          }}
          width={'300px'}
          options={sourceItems}
          value={localTrackEvent?.value}
          virtualize
          ph="Select an event"
        />
      </div>
      <PrimaryButton
        className={classNames('w-full product-tour-3', ctaClassName)}
        isLoading={isLoadingOpp}
        disabled={!localTrackEvent}
        onClick={() => {
          conversionEventChange(localTrackEvent);
        }}
      >
        Confirm
      </PrimaryButton>
    </div>
  );
};

const DotCircle = (props: { color: string; size: number }) => {
  return (
    <div
      className={`w-${props.size} h-${props.size}`}
      style={{ backgroundColor: props.color, borderRadius: '50%' }}
    />
  );
};

DotCircle.defaultProps = {
  size: 3,
};

const Rectangle = (props: { color: string }) => {
  return <div style={{ height: 10, width: 10, background: props.color || 'green' }}></div>;
};

const LeadsStats = (props) => {
  const { leadStatusData, considerPercentage, totalValue, label } = props;
  return (
    <div className="px-4 py-4 text-xs border rounded-md bg-tw-white-ff shadow-3xl drop-shadow-3xl border-tw-gray-eb">
      <div className="text-tw-black-9">{label}</div>
      <div className="flex justify-between mt-4">
        <div className="flex items-center gap-2">
          <DotCircle color={'#FD6969'} />
          <span>Hot</span>
        </div>
        <div>
          {considerPercentage
            ? `${leadStatusData?.['hot']?.perc?.toFixed(2)}%`
            : leadStatusData?.['hot']?.count}
        </div>
      </div>
      <div className="flex justify-between mt-3">
        <div className="flex items-center gap-2">
          <DotCircle color={'#FDB03B'} />
          <span>Warm</span>
        </div>
        <div>
          {considerPercentage
            ? `${leadStatusData?.['warm']?.perc?.toFixed(2)}%`
            : leadStatusData?.['warm']?.count}
        </div>
      </div>
      <div className="flex justify-between mt-3">
        <div className="flex items-center gap-2">
          <DotCircle color={'#55B1F4'} />
          <span>Cold</span>
        </div>
        <div>
          {considerPercentage
            ? `${leadStatusData?.['cold']?.perc?.toFixed(2)}%`
            : leadStatusData?.['cold']?.count}
        </div>
      </div>
      <hr className="mt-3 border-dashed botder-1" />
      <div className="flex justify-between mt-3">
        <div className="flex gap-2">
          <span>{considerPercentage ? 'Average' : 'Total'}</span>
        </div>
        <div>{totalValue}</div>
      </div>
    </div>
  );
};

const PerformanceStatsCard = (props) => {
  const { loading, label, dataValue, leadStatusData, opportunity, considerPercentage } = props;
  const popoverContentRef = useRef(null);
  const cardComponentRef = useRef(null);
  const mouseOverTimerId = useRef(null);
  const [showHWC, setShowHWC] = useState(false);
  const dsEnabled = useDsEnabled(opportunity?.query?.modelId, opportunity?.query?.type);
  const mouseMoveCallback = useCallback(
    (e) => {
      if (
        !(
          popoverContentRef?.current?.contains(e.target) ||
          cardComponentRef?.current?.contains(e.target)
        )
      ) {
        if (mouseOverTimerId?.current !== undefined) {
          clearTimeout(mouseOverTimerId?.current);
        }
        mouseOverTimerId.current = setTimeout(() => {
          if (setShowHWC) {
            setShowHWC(false);
          }
        }, 10);
      }
    },
    [showHWC]
  );

  useEffect(() => {
    document.addEventListener('mousemove', mouseMoveCallback);
    return () => {
      clearTimeout(mouseOverTimerId?.current);
      document.removeEventListener('mousemove', mouseMoveCallback);
    };
  }, [mouseMoveCallback]);

  return (
    <div style={{ position: 'relative' }}>
      <div
        className="p-4 border rounded-lg border-tw-gray-eb"
        ref={cardComponentRef}
        onMouseOver={() => {
          setShowHWC(true);
        }}
      >
        {loading ? (
          <div className="w-40 h-3 animate-shimmer"></div>
        ) : (
          <div className="flex justify-between text-xs gap-x-10 text-tw-black-7">
            <div>{label}</div>

            {dsEnabled && (
              <div>
                <DropdownArrowIcon className="fill-current text-tw-black-9" />
              </div>
            )}
          </div>
        )}
        {loading ? (
          <div className="w-16 h-4 mt-3 animate-shimmer"></div>
        ) : (
          <p className="mt-2 font-medium text-tw-black-1">{dataValue}</p>
        )}
      </div>
      {showHWC && dsEnabled && !loading && (
        <motion.div
          initial={{
            height: 0,
            opacity: 0,
          }}
          animate={{
            height: 'auto',
            opacity: 1,
          }}
          exit={{
            height: 0,
            opacity: 0,
          }}
          style={{ position: 'absolute', zIndex: 100, top: 0, left: 0, width: '100%' }}
        >
          <div ref={popoverContentRef}>
            <LeadsStats
              label={label}
              leadStatusData={leadStatusData}
              considerPercentage={considerPercentage}
              totalValue={dataValue}
            />
          </div>
        </motion.div>
      )}
    </div>
  );
};

const ControlGroupBanner = (props: any) => {
  const { performanceDetails, opportunity } = props;
  return (
    <>
      {performanceDetails?.liftSummary?.impact ? (
        <div className="relative flex items-center w-full px-4 py-2 gap-x-1">
          {performanceDetails?.liftSummary?.lift === 'increase' ? (
            <Banner
              icon={<TrendUp className="w-8 h-8" />}
              color={'#39A129'}
              borderColor={'#9CDAAC'}
            >
              <div className="flex gap-x-2">
                <span className="font-semibold" style={{ color: '#39A129' }}>
                  {performanceDetails?.liftSummary?.change}%
                </span>
                <span
                  className="font-semibold"
                  style={{ color: '#39A129' }}
                >{`lift in ${opportunity.query?.modelId}`}</span>
                <BarSeperator className="w-5 h-5" />
                <span className="text-sm text-tw-black-3">
                  Yay! Test Group is performing better than the control group
                </span>
              </div>
            </Banner>
          ) : (
            <Banner
              icon={<TrendDown className="w-8 h-8" />}
              color={'#DD3C3C'}
              borderColor={'#FFDEDE'}
            >
              <div className="flex gap-x-2">
                <span className="font-semibold" style={{ color: '#DD3C3C' }}>
                  {performanceDetails?.liftSummary?.change}%
                </span>
                <span
                  className="font-semibold"
                  style={{ color: '#DD3C3C' }}
                >{`dip in ${opportunity.query?.modelId}`}</span>
                <BarSeperator className="w-5 h-5" />
                <span className="text-sm text-tw-black-3">
                  Oh no! Test group is performing worse than control group
                </span>
              </div>
            </Banner>
          )}
        </div>
      ) : (
        <Banner
          icon={<FilledInfoWithWave className="w-8 h-8" />}
          color={'#0D6EFD'}
          borderColor={'#B6D3FF'}
        >
          Not enough data yet to conclude the results of this experiment
        </Banner>
      )}
    </>
  );
};

const NonControlGroupStats = (props) => {
  const { opportunity, loading, performanceDetails, controlGroupEnabled } = props;
  const percentageValue =
    (performanceDetails?.conversions?.nonControlGroup?.aggregate?.count /
      performanceDetails?.qualifications?.nonControlGroup?.aggregate?.count) *
    100;
  return (
    <>
      <div className="mt-6">
        {loading ? (
          <div className="h-5 w-60 animate-shimmer"></div>
        ) : controlGroupEnabled ? (
          <h3 className="font-medium text-tw-black-7">
            {`Test group (${
              100 - opportunity?.exports[0].controlGroup?.metadata?.percentage
            }% ${opportunity.query?.type?.toLowerCase()})`}
          </h3>
        ) : null}
        <div className="flex mt-4 gap-x-4">
          <PerformanceStatsCard
            loading={loading}
            label={`Qualified ${opportunity.query?.type?.toLowerCase()}`}
            dataValue={performanceDetails?.qualifications?.nonControlGroup?.aggregate?.count || 0}
            leadStatusData={performanceDetails?.qualifications?.nonControlGroup?.summary}
            opportunity={opportunity}
          />
          <PerformanceStatsCard
            loading={loading}
            label={getGoalVerb(
              goalsWordDictionary[opportunity?.query.goal]['verbPast'],
              opportunity.query?.type?.toLowerCase()
            )}
            dataValue={performanceDetails?.conversions?.nonControlGroup?.aggregate?.count || 0}
            leadStatusData={performanceDetails?.conversions?.nonControlGroup?.summary}
            opportunity={opportunity}
          />
          <PerformanceStatsCard
            loading={loading}
            label={`% ${opportunity.query?.type?.toLowerCase()} who ${
              goalsWordDictionary[opportunity?.query.goal]['verbPast']
            }`}
            dataValue={isNaN(percentageValue) ? '0.00%' : `${percentageValue.toFixed(2)}%`}
            leadStatusData={performanceDetails?.conversions?.nonControlGroup?.summary}
            opportunity={opportunity}
            considerPercentage={true}
          />
        </div>
      </div>
    </>
  );
};
const ControlGroupStats = (props) => {
  const { opportunity, loading, performanceDetails } = props;
  const percentageValue =
    (performanceDetails?.conversions?.controlGroup?.aggregate?.count /
      performanceDetails?.qualifications?.controlGroup?.aggregate?.count) *
    100;
  return (
    <>
      <div className="mt-6">
        {loading ? (
          <div className="h-5 w-60 animate-shimmer"></div>
        ) : (
          <h3 className="font-medium text-tw-black-7">
            {`Control group (${
              opportunity?.exports[0].controlGroup?.metadata?.percentage
            }% ${opportunity.query?.type?.toLowerCase()})`}
          </h3>
        )}
        <div className="flex mt-4 gap-x-4">
          <PerformanceStatsCard
            loading={loading}
            label={`Qualified ${opportunity.query?.type?.toLowerCase()}`}
            dataValue={performanceDetails?.qualifications?.controlGroup?.aggregate?.count || 0}
            leadStatusData={performanceDetails?.qualifications?.controlGroup?.summary}
            opportunity={opportunity}
          />
          <PerformanceStatsCard
            loading={loading}
            label={getGoalVerb(
              goalsWordDictionary[opportunity?.query.goal]['verbPast'],
              opportunity.query?.type?.toLowerCase()
            )}
            dataValue={performanceDetails?.conversions?.controlGroup?.aggregate?.count || 0}
            leadStatusData={performanceDetails?.conversions?.controlGroup?.summary}
            opportunity={opportunity}
          />
          <PerformanceStatsCard
            loading={loading}
            label={`% ${opportunity.query?.type?.toLowerCase()} who ${
              goalsWordDictionary[opportunity?.query?.goal]['verbPast']
            }`}
            dataValue={isNaN(percentageValue) ? '0.00%' : `${percentageValue.toFixed(2)}%`}
            leadStatusData={performanceDetails?.conversions?.controlGroup?.summary}
            opportunity={opportunity}
            considerPercentage={true}
          />
        </div>
      </div>
    </>
  );
};

const BarLineComposedChart = (props) => {
  const { opportunity, performanceDetails, timeline } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const noOfPages = Math.ceil(Number(timeline) / NUMBER_OF_BARS);
  const [showHWCCHart, setShowHWCChart] = useState(false);
  const dsEnabled = opportunity.query?.dsEnabled;
  const HWCTimeseriesData = getHWCTimeseriesData(
    performanceDetails.conversions?.nonControlGroup.timeSeries,
    performanceDetails.qualifications?.nonControlGroup.timeSeries
  );
  const conversionTimeseriesData = getConversionTimeseriesData(
    performanceDetails.conversions?.nonControlGroup.timeSeries,
    performanceDetails.qualifications?.nonControlGroup.timeSeries,
    opportunity.query?.type,
    opportunity.query?.goal,
    (pageNumber - 1) * NUMBER_OF_BARS
  );
  return (
    <div>
      <hr className="w-full my-8 border-b border-dashed border-tw-gray-eb botder-1" />
      <div className="flex justify-between mt-8">
        <div className="font-medium">{`${capitalizeFirstLetter(
          goalsWordDictionary[opportunity?.query.goal]['noun']
        )} over time`}</div>
        {dsEnabled && (
          <div className="flex px-3 py-2 border rounded-lg gap-x-4 border-tw-gray-eb">
            <div className="mt-0.5">Split by Hot, Warm and Cold users</div>
            <Toggle
              enabled={showHWCCHart}
              setEnabled={() => {
                if (dsEnabled) {
                  setShowHWCChart(!showHWCCHart);
                }
              }}
            />
          </div>
        )}
      </div>
      {showHWCCHart && (
        <div key={showHWCCHart.toString()} className="mt-10" style={{ width: '100%', height: 250 }}>
          <ResponsiveContainer>
            <ComposedChart
              data={HWCTimeseriesData}
              margin={{ top: 0, left: 0, bottom: 0, right: 0 }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                dataKey="day"
                minTickGap={20}
                tick={{ fill: '#999999', fontSize: 12 }}
                dy={24}
                tickLine={false}
                axisLine={false}
              />
              <YAxis
                tick={{ fill: '#999999', fontSize: 12 }}
                tickLine={false}
                axisLine={false}
                tickMargin={15}
                tickSize={10}
                tickFormatter={(val) => {
                  return `${val}%`;
                }}
              />
              <Tooltip content={<ChartTooltip />} />
              <Line type="monotone" dataKey="hot" stroke="#FD6969" strokeWidth={3} dot={false} />
              <Line type="monotone" dataKey="warm" stroke="#FF9F3E" strokeWidth={3} dot={false} />
              <Line type="monotone" dataKey="cold" stroke="#55B1F4" strokeWidth={3} dot={false} />
              <Legend content={renderLegend} />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
      {!showHWCCHart && (
        <div key={showHWCCHart.toString()} className="mt-10" style={{ width: '100%', height: 250 }}>
          <ResponsiveContainer>
            <ComposedChart
              data={conversionTimeseriesData}
              margin={{ top: 0, left: 0, bottom: 0, right: 0 }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                dataKey="day"
                minTickGap={20}
                dy={10}
                tick={{ fill: '#999999', fontSize: 12 }}
                tickLine={false}
                axisLine={false}
              />
              <YAxis
                tick={{ fill: '#999999', fontSize: 12 }}
                tickLine={false}
                tickMargin={15}
                yAxisId={'left'}
                axisLine={false}
                tickSize={10}
              />
              <YAxis
                tick={{ fill: '#999999', fontSize: 12 }}
                tickLine={false}
                yAxisId={'right'}
                tickMargin={12}
                axisLine={false}
                orientation="right"
                tickSize={10}
                tickFormatter={(val) => {
                  return `${val}%`;
                }}
              />
              <Tooltip content={<ConversionChartTooltip />} />
              <Bar yAxisId={'left'} dataKey="qualified" fill="#0D6EFD" barSize={25} />
              <Line
                yAxisId={'right'}
                type="monotone"
                dataKey="perc"
                stroke="#FF9F3E"
                strokeWidth={2}
                dot={false}
              />
              <Legend
                content={
                  <ConversionLegend
                    showPageAnchors={noOfPages > 1}
                    showLeftAnchor={pageNumber > 1}
                    showRightAnchor={noOfPages > 1 && pageNumber < noOfPages}
                    pageNumber={pageNumber}
                    onPageChange={(val) => {
                      setPageNumber(val);
                    }}
                    noOfPages={noOfPages}
                  />
                }
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

const ModelStats = (props) => {
  const { opportunity } = props;
  const { user } = useAuth();
  const [dateRange, setDateRange] = useState({
    key: 'selection',
    startDate:
      getCadenceType(opportunity.schedule) === CadenceType.RECURRING
        ? new Date(dayjs().tz('UTC').subtract(30, 'days').format('MM/DD/YYYY'))
        : new Date(dayjs(opportunity.createdAt).tz('UTC').format('MM/DD/YYYY')),
    endDate: new Date(dayjs().tz('UTC').format('MM/DD/YYYY')),
  });

  const [requestPayload, setRequestPayload] = useState({
    ...getPerformanceRequestPayload(dateRange),
    label: 'Last 30 days',
  });
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const getDaysRangeLabel = () => {
    if (requestPayload?.label) {
      return requestPayload.label;
    }
    return `${convertDate(requestPayload.start)} -  ${convertDate(requestPayload.end)}`;
  };
  const { data: performanceDetails, isLoading: isPerformanceDetailsLoading } = usePerformance(
    opportunity.id,
    omit(requestPayload, ['label']),
    props.enabled
  );
  const loading = isPerformanceDetailsLoading;
  const controlGroupEnabled =
    !!opportunity?.exports[0].controlGroup &&
    opportunity?.exports[0]?.controlGroup?.metadata?.percentage !== 0;
  if (!areStatsAvailable(opportunity) && opportunity.status === OpportunityStatus.STOPPED) {
    return (
      <NoData
        icon={<SchedulePQL />}
        primaryText={'This Playbook has stopped'}
        secondaryText={`Please start the playbook to see performance details`}
      />
    );
  }
  if (!areStatsAvailable(opportunity)) {
    return (
      <NoData
        icon={<SchedulePQL />}
        primaryText={'This Playbook has not run yet'}
        secondaryText={`You will be able to see the playbook details once it runs on ${convertInOrgTimezone(
          opportunity?.nextRun,
          user?.currentOrganization?.timezone
        )}`}
      />
    );
  }
  return (
    <div>
      <div className="flex gap-4">
        <div className="mt-2 font-weight-bold">Summary</div>
        {getCadenceType(opportunity.schedule) === CadenceType.RECURRING && (
          <div className="stats-calendar">
            <Popover.Root open={calendarOpen} onOpenChange={(open) => setCalendarOpen(open)}>
              <Popover.Anchor />
              <Popover.Trigger className="flex px-3 py-2 text-sm rounded-lg cursor-pointer gap-x-2 border-1 text-tw-black-9 border-tw-gray-c">
                <Calendar className="mt-0.5" />
                <div className="text-tw-black-3">{getDaysRangeLabel()}</div>
              </Popover.Trigger>

              <Popover.Content
                avoidCollisions
                className="stats-calendar"
                side="bottom"
                align="start"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onMouseOver={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onEscapeKeyDown={() => setCalendarOpen(false)}
                onInteractOutside={() => setCalendarOpen(false)}
              >
                <div className="rounded-lg shadow-lg bg-tw-white-ff ring-black">
                  <RangedDatePicker
                    disableFutureDates={true}
                    handleInputDate={(dates) => {
                      setDateRange({
                        startDate: dates['selection']?.startDate,
                        endDate: dates['selection']?.endDate,
                        key: 'selection',
                      });
                      if (dates['selection']?.label) {
                        setCalendarOpen(false);
                        setRequestPayload({
                          ...getPerformanceRequestPayload(dates['selection']),
                          label: dates['selection']?.label,
                        });
                      }
                    }}
                    initialSelectionRange={dateRange}
                  />
                  <div className="flex-row-reverse h-12 px-4 pb-2 text-xs rounded-lg bg-tw-white-ff">
                    <PrimaryButton
                      onClick={() => {
                        setCalendarOpen(false);
                        setRequestPayload({
                          ...getPerformanceRequestPayload(dateRange),
                          label: undefined,
                        });
                      }}
                      style={{ width: 80, height: 32, float: 'right' }}
                    >
                      Submit
                    </PrimaryButton>
                  </div>
                </div>
              </Popover.Content>
            </Popover.Root>
          </div>
        )}
      </div>
      {controlGroupEnabled &&
        (isPerformanceDetailsLoading ? (
          <div className="h-12 mt-4 bg-tw-gray-eb"></div>
        ) : (
          <div className="grid my-4 place-items-center">
            <ControlGroupBanner performanceDetails={performanceDetails} opportunity={opportunity} />
          </div>
        ))}
      {!controlGroupEnabled && (
        <NonControlGroupStats
          opportunity={opportunity}
          loading={loading}
          performanceDetails={performanceDetails}
          controlGroupEnabled={controlGroupEnabled}
        />
      )}
      {controlGroupEnabled && (
        <>
          <NonControlGroupStats
            opportunity={opportunity}
            loading={loading}
            performanceDetails={performanceDetails}
            controlGroupEnabled={controlGroupEnabled}
          />
          <ControlGroupStats
            opportunity={opportunity}
            loading={loading}
            performanceDetails={performanceDetails}
          />
        </>
      )}
      {performanceDetails &&
        performanceDetails.conversions?.nonControlGroup?.timeSeries &&
        (getCadenceType(opportunity.schedule) === CadenceType.RECURRING ||
          opportunity?.isSample) && (
          <BarLineComposedChart
            opportunity={opportunity}
            performanceDetails={performanceDetails}
            timeline={dayjs(requestPayload.end).diff(requestPayload.start, 'day')}
          />
        )}
    </div>
  );
};

export default function Performance(props: { opportunity: OpportunityResponse }) {
  const { opportunity } = props;
  const { getAccountId } = useAuthStore();
  const { user } = useAuth();
  const { organization } = useActivateOrganization();
  const showStats = opportunity.query?.dsEnabled ? true : opportunity.query?.performanceTrackEvent;
  if (user.currentOrganization?.isSandbox && !opportunity?.isSample) {
    return (
      <div className={classNames('w-full z-5 h-100 py-6', PQL_STATS_PAGE_PADDING_CLASSNAME)}>
        <SandboxPerfStats />
        <div className="mt-6 text-base text-center ">
          {!organization?.isActive && (
            <>
              <Link to={`/${getAccountId()}/activate`}>
                <span style={{ color: '#0D6EFD', fontWeight: 500 }}> Activate your account</span>
              </Link>
              <span className="ml-1">to see playbook performance insights</span>
            </>
          )}
          {organization?.isActive && (
            <>
              <span>Performance insights are not available for playbooks created in Test Mode</span>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('w-full z-5 h-100', PQL_STATS_PAGE_PADDING_CLASSNAME)}>
      {!opportunity.query?.dsEnabled && (
        <>
          <ConversionEvent opportunity={opportunity} />
          <hr className="my-6 border-1" />
        </>
      )}
      {showStats && <ModelStats opportunity={opportunity} />}
    </div>
  );
}
