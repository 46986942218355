import { toast as toastify } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { IntegrationRequest, useClient } from '../../../api-client';
import { AnalyticsConst, AnalyticsEvents } from '../../../telemetry/constants';
import { useAnalytics } from '../../../telemetry';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';

export type IntegrationRequestData = {
  id: number;
  name: string;
  data: IntegrationRequest;
};

export default function useSaveIntegrationRequest() {
  const queryClient = useQueryClient();
  const { track } = useAnalytics();
  const client = useClient();
  return useMutation(
    (data: IntegrationRequestData) =>
      client.saveRequestForInactiveIntegration(data?.id, data?.data),
    {
      onSuccess: () => {
        toastify(<SuccessToast description="Integration requested successfully" />, {
          type: 'success',
        });
        queryClient.refetchQueries(['inactiveIntegrations']);
      },
      onError: (error, data) => {
        const err = error['response']['data'];
        toastify(<ErrorToast description={err ?? 'Uh oh! Something went wrong'} />, {
          type: 'error',
        });
        track(AnalyticsEvents.INTEGRATION_REQUEST_FAILED, {
          [AnalyticsConst.INTEGRATION_NAME]: data?.['name'],
          [AnalyticsConst.USER_EMAIL]: data?.['email'],
          [AnalyticsConst.ACCOUNT_NAME]: data?.['organization'],
        });
      },
    }
  );
}
