import { Spinner } from '@chakra-ui/react';
import { AddUserIcon, EmptyFolder } from 'ui-components/data-display/Icons';
import { PrimaryDropdown } from 'ui-components/navigation/Dropdown';
import useGrantAccess from 'pages/grant-access/hooks/useGrantAccess';
import { useState } from 'react';
import useJoinRequests from '../hooks/useJoinRequests';
import useRejectJoinRequest from '../hooks/useRejectJoinRequest';
import { roleOptions, Roles } from '../Roles';
import { TeamMembersTabs } from './TeamMembers';
import { toast as toastify } from 'react-toastify';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';

function JoinRequestItem({
  email,
  setActiveTab,
}: {
  email: string;
  setActiveTab: (tab: TeamMembersTabs) => void;
}) {
  const [role, setRole] = useState<Roles>(Roles.READ_AND_WRITE);
  const grantAccess = useGrantAccess();
  const rejectAccess = useRejectJoinRequest();

  const handleGrantAccess = () => {
    grantAccess.mutate(
      {
        email,
        role,
      },
      {
        onSuccess: () => {
          toastify(<SuccessToast description="Access granted" />, {
            type: 'success',
          });
          setActiveTab(TeamMembersTabs.members);
        },
      }
    );
  };

  const handleRejectAccess = () => {
    rejectAccess.mutate(
      { email },
      {
        onSuccess: () => {
          // if last item change tab to members
          toastify(<ErrorToast description="Access denied" />, {
            type: 'error',
          });
        },
      }
    );
  };

  return (
    <div className="px-4 py-5 border rounded-lg border-tw-gray-eb">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-4">
          <AddUserIcon className="fill-current text-tw-black-5" />
          <span className="text-tw-black-1">{email}</span>
        </div>
        <div className="flex items-center gap-x-4">
          <PrimaryDropdown
            selectBtnWidth="w-40"
            value={role}
            onChange={(val) => setRole(val as Roles)}
            options={roleOptions}
          />
          <button
            onClick={handleGrantAccess}
            className="w-24 px-4 py-3 font-semibold border rounded border-tw-gray-eb text-tw-blue-0d"
          >
            {grantAccess.isLoading ? <Spinner size={'sm'} /> : 'Approve'}
          </button>
          <button onClick={handleRejectAccess} className="font-semibold text-tw-black-7">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default function JoinRequests({
  setActiveTab,
}: {
  setActiveTab: (tab: TeamMembersTabs) => void;
}) {
  const { data } = useJoinRequests();
  return (
    <div className="h-[70vh]">
      {data?.length > 0 && (
        <div className="flex flex-col w-2/3 gap-4">
          {data?.map((item) => (
            <JoinRequestItem key={item.email} setActiveTab={setActiveTab} email={item.email} />
          ))}
        </div>
      )}
      {data?.length === 0 && (
        <div className="grid place-items-center h-3/4">
          <div className="flex flex-col items-center mt-8">
            <EmptyFolder />
            <h2 className="mt-5 text-h2">No requested invites yet</h2>
            <p className="mt-2 text-tw-black-7">
              You’ll be able to see all the requested invites here when they come
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
