import dayjs from 'dayjs';
import { classNames } from 'utils/common';
import React, { useCallback, useEffect, useState } from 'react';
import { DateRange, Range } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

enum DateRanges {
  LAST_WEEK = 'Last week',
  LAST_15_DAYS = 'Last 15 days',
  LAST_30_DAYS = 'Last 30 days',
  LAST_3_MONTHS = 'Last 3 months',
}

const dateRangeObj: { label: string; startDate: Date; endDate: Date }[] = [
  {
    label: DateRanges.LAST_WEEK,
    startDate: dayjs().subtract(1, 'week').toDate(),
    endDate: dayjs().toDate(),
  },
  {
    label: DateRanges.LAST_15_DAYS,
    startDate: dayjs().subtract(15, 'days').toDate(),
    endDate: dayjs().toDate(),
  },
  {
    label: DateRanges.LAST_30_DAYS,
    startDate: dayjs().subtract(30, 'days').toDate(),
    endDate: dayjs().toDate(),
  },
  {
    label: DateRanges.LAST_3_MONTHS,
    startDate: dayjs().subtract(3, 'months').toDate(),
    endDate: dayjs().toDate(),
  },
];

// props:
// 1. handleInputDate is exposed on change event of the datepicker internally:
//   :dates: {
//        [key: string]: {
//        startDate: Date;
//        endDate: Date;
//        label?: string;
//      };
//    }
// 2. initialSelectionRange provided as default date from the API
//   :startDate: Date;
//    endDate: Date;
//    key: string (selection);
export default function RangedDatePicker({
  initialSelectionRange,
  handleInputDate,
  showDateRanges = true,
  disableFutureDates = false,
}: {
  initialSelectionRange: {
    startDate: Date;
    endDate: Date;
    key: string;
  };
  handleInputDate: (dates: {
    [key: string]: {
      startDate: Date;
      endDate: Date;
      label?: string;
    };
  }) => void;
  showDateRanges?: boolean;
  disableFutureDates?: boolean;
}) {
  const [activeRange, setActiveRange] = useState<DateRanges | null>(null);

  const updateActiveRange = useCallback((dateObj: Range) => {
    const { startDate, endDate } = dateObj;
    if (!dayjs(startDate).isSame(endDate, 'day')) {
      const isToday = dayjs(endDate).isSame(dayjs(), 'day');

      if (isToday && dayjs().diff(dayjs(startDate), 'days') === 7) {
        setActiveRange(DateRanges.LAST_WEEK);
      } else if (isToday && dayjs().diff(dayjs(startDate), 'days') === 15) {
        setActiveRange(DateRanges.LAST_15_DAYS);
      } else if (isToday && dayjs().diff(dayjs(startDate), 'days') === 30) {
        setActiveRange(DateRanges.LAST_30_DAYS);
      } else if (isToday && dayjs().diff(dayjs(startDate), 'months') === 2) {
        setActiveRange(DateRanges.LAST_3_MONTHS);
      }
    } else {
      setActiveRange(null);
    }
  }, []);

  // replenish from props.
  useEffect(() => {
    if (initialSelectionRange) {
      const { startDate, endDate } = initialSelectionRange;
      updateActiveRange({
        startDate,
        endDate,
      });
    }
  }, [initialSelectionRange, updateActiveRange]);

  const extraProps = {};
  if (disableFutureDates) {
    extraProps['maxDate'] = dayjs().toDate();
  }

  return (
    <div className="bg-tw-white-ff shadow-lg ring-black rounded-lg">
      <DateRange
        editableDateInputs={false}
        ranges={[initialSelectionRange]}
        onChange={(dates) => {
          // clear active range selection.
          updateActiveRange(dates['selection']);
          handleInputDate(dates as any);
        }}
        showDateDisplay={false}
        moveRangeOnFirstSelection={false}
        {...extraProps}
      />
      <hr />
      {showDateRanges && (
        <div className="px-2 py-3 flex items-center max-w-full overflow-scroll text-xs">
          {dateRangeObj.map((range) => {
            const { startDate, endDate, label } = range;
            return (
              <div
                key={label}
                className={classNames(
                  'mr-2 border-1 p-1 rounded cursor-pointer',
                  label === activeRange ? 'border-tw-blue-0d bg-tw-blue-f2' : 'border-tw-gray-eb'
                )}
                onClick={() => {
                  updateActiveRange({
                    startDate,
                    endDate,
                  });
                  handleInputDate({
                    selection: {
                      startDate,
                      endDate,
                      label,
                    },
                  });
                }}
              >
                {label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
