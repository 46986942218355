import { useMutation, useQueryClient } from 'react-query';
import { useClient } from '../../../api-client';
import {
  useOpportunityListStore,
  initialQueryParamsState,
} from '../../opportunities/store/OpportunityListStore';

type UpdatePQL = {
  opportunityId: number;
  name?: string;
  query?: any;
};

export default function useUpdateOpportunity(id: number) {
  const { queryParams, updateQueryParams } = useOpportunityListStore();
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation((data: UpdatePQL) => client.updateOpportunity(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries('opportunity');
      updateQueryParams(initialQueryParamsState);
      queryClient.refetchQueries([
        'opportunities',
        'count',
        queryParams.query,
        queryParams.destinations,
      ]);
      queryClient.refetchQueries([
        'opportunities',
        queryParams.offset,
        queryParams.query,
        queryParams.status,
        queryParams.selectedOrderFilter,
        queryParams.destinations,
      ]);
      queryClient.refetchQueries(['opportunity', id.toString()]);
    },
  });
}
