import { ErrorMessage, Form, Formik } from 'formik';
import * as React from 'react';
import * as YUP from 'yup';
import { IntegrationType } from '../../../../api-client';
import { InfoToolTip } from './FormComponents';
import FormikSelect from './FormComponents';
import { PrimaryButton, TertiaryButton } from '../../../inputs/Buttons';
import { pipeDriveFields } from '../constants';

interface PipedriveFormProps {
  integrationId: number;
  integrationType: IntegrationType.PIPEDRIVE;
  submitCallback: any;
  onclose: any;
  availableFieldNames: string[];
}

export const PipedriveFieldForm: React.FC<PipedriveFormProps> = ({
  integrationId,
  integrationType,
  submitCallback,
  onclose,
  availableFieldNames,
}) => {
  const validationRules = YUP.object({
    name: YUP.mixed()
      .notOneOf(availableFieldNames, 'A field with this name already exists')
      .required('This Field is required'),
    fieldType: YUP.string().required(),
    objectType: YUP.string().required(),
    integrationId: YUP.number().required(),
    integrationType: YUP.string().required(),
  });
  return (
    <Formik
      initialValues={{
        name: '',
        fieldType: '',
        objectType: 'Persons',
        integrationId: integrationId,
        integrationType: integrationType,
      }}
      onSubmit={(values) => submitCallback(values)}
      validationSchema={validationRules}
    >
      {(formik) => (
        <Form>
          <div className={'px-4'}>
            <div className={'flex items-center'}>
              <span className="my-2 text-sm text-tw-black-5">Field Name</span>
              <InfoToolTip
                label="A human-readable property name that will be shown in PipeDrive."
                width={'16px'}
                height={'16px'}
              />
            </div>
            <input
              className={`w-full px-4 py-2 rounded border-1 border-tw-gray-c focus:outline-none ${
                formik.errors.name && formik.touched.name ? 'border-tw-red-dd' : ''
              }`}
              type="text"
              name="name"
              value={formik.values.name}
              onChange={(event) => formik.setFieldValue('name', event.target.value)}
              placeholder={`Name of the field`}
            />
            <ErrorMessage
              name={'name'}
              className={'text-tw-red-dd text-xs pt-1'}
              component={'div'}
            />
            <div className={'mt-6 mb-24'}>
              <div className={'flex items-center'}>
                <span className="my-2 text-sm text-tw-black-5">Field Type</span>
                <InfoToolTip
                  label="Controls how the property appears in PipeDrive."
                  width={'16px'}
                  height={'16px'}
                />
              </div>
              <FormikSelect
                ph={`Type of the field`}
                name={'fieldType'}
                virtualize
                options={pipeDriveFields}
              />
            </div>
          </div>
          <hr className={'mt-4'} />
          <div className={'py-4 justify-content-end flex px-4'}>
            <TertiaryButton type={'button'} onClick={onclose}>
              Cancel
            </TertiaryButton>
            <PrimaryButton className={'ml-3'} type={'submit'}>
              Create field
            </PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};
