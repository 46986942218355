import NavBars from 'ui-components/business/navbars/NavBars';
import GlobalRedirect from 'routing/GlobalRedirect';
import Activate from 'pages/activate';
import EmailVerificationSent from 'pages/email-verification/EmailVerificationSent';
import VerifyEmail from 'pages/email-verification/VerifyEmail';
import ExistingOrganization from 'pages/existing-organization';
import GrantAccess from 'pages/grant-access';
import AddIntegrationPage from 'pages/integrations/AddIntegrationPage';
import IntegrationList from 'pages/integrations/IntegrationList';
import OAuthCallbackPage from 'pages/integrations/OAuthCallbackPage';
import WebhookPage from 'pages/integrations/WebhookPage';
import Login from 'pages/login/Login';
import OrgChooser from 'pages/login/OrgChooser';
import OpportunitiesList from 'pages/opportunities/Opportunities';
import PQLWorkflow from 'pages/pql-workflow';
import EditExports from 'pages/pql-workflow/edit-exports';
import SandboxSetup from 'pages/sandbox-setup';
import APISettings from 'pages/settings/api-settings/APISettings';
import ChangePassword from 'pages/settings/profile/ChangePassword';
import EditProfile from 'pages/settings/profile/EditProfile';
import MemberProfile from 'pages/settings/profile/MemberProfile';
import TeamMembers from 'pages/settings/team-members/TeamMembers';
import SignUp from 'pages/sign-up/Signup';
import SignUpByInvite from 'pages/sign-up/SignUpByInvite';
import AccountDetails from 'pages/users-and-accounts/accounts/account-details/AccountDetails';
import { UserDetails } from 'pages/users-and-accounts/users/user-details';
import AccountsList from 'pages/users-and-accounts/accounts/accounts-list';
import UsersList from 'pages/users-and-accounts/users/users-list';
import ChooseSandboxDataset from 'pages/sandbox-setup/ChooseSandboxDataset';
import * as React from 'react';
import NewOpDetails from '../pages/opp-details/NewOpDetails';
import PasswordChanged from '../pages/reset-password/PasswordChanged';
import ResetPassword from '../pages/reset-password/ResetPassword';
import ResetPasswordEmailConfirm from '../pages/reset-password/ResetPasswordEmailConfirm';
import ResetPasswordForm from '../pages/reset-password/ResetPasswordForm';
import { AddToken } from '../pages/settings/api-settings/AddToken';
import AddTeamMember from '../pages/settings/team-members/sub-components/AddTeamMember';
import StaticNotFound from './StaticNotFound';
import TenantRoute from './TenantRoute';

export const routes = {
  home: '/',
  login: '/login',
  opportunities: '/opportunities',
  opportunitiesList: '/opportunities/list',
  users: '/users',
  accounts: '/accounts',
  insights: '/insights',
  createOpportunity: '/opportunities/new',
  duplicateOpportunity: '/opportunities/duplicate',
  editExports: '/opportunities/edit-exports',
  integrations: '/integrations',
  settings: '/settings',
  signup: '/signup/:invitation_token',
  createToken: '/settings/token/add',
  apiSettings: '/settings/api',
  resendVerificationEmail: '/email/verification/resend',
  verifyEmail: '/email/verify/:token',
  onboarding: '/onboarding',
  nextSteps: '/next-steps',
  existingOrganization: '/organization/existing',
  setupOrganization: '/organization/setup',
  chooseDataset: '/organization/choose-dataset',
  emailVerificationSent: '/email/verify',
};

export interface ClientRouteInterface {
  path: string;
  nestedRoute?: ClientRouteInterface[];
  children?: ClientRouteInterface[];
  exact?: boolean;
  component?: React.FC;
  isSecure?: boolean;
  props?: { [key: string]: any };
}

export const ClientRoutes: ClientRouteInterface[] = [
  {
    path: routes.login,
    component: Login,
    exact: true,
    isSecure: false,
  },
  {
    path: '/login/:type/callback',
    component: OAuthCallbackPage,
  },
  {
    path: '/signup/invite/:invitation_token',
    component: SignUpByInvite,
    isSecure: false,
  },
  {
    path: '/password/reset',
    component: ResetPassword,
    exact: true,
    isSecure: false,
  },
  {
    path: '/password/reset/email/confirm',
    component: ResetPasswordEmailConfirm,
    exact: true,
    isSecure: false,
  },
  {
    path: '/password/reset/:uid/:token',
    component: ResetPasswordForm,
    isSecure: false,
  },
  {
    path: '/password/confirm',
    component: PasswordChanged,
    isSecure: false,
  },
  {
    path: '/signup',
    component: SignUp,
    isSecure: false,
    exact: true,
  },
  // Authenticated routes
  {
    path: routes.home,
    component: GlobalRedirect,
    exact: true,
    isSecure: true,
  },
  {
    path: routes.existingOrganization,
    component: ExistingOrganization,
    isSecure: true,
  },
  {
    path: routes.setupOrganization,
    component: SandboxSetup,
    isSecure: true,
  },
  {
    path: routes.chooseDataset,
    component: NavBars,
    isSecure: true,
    children: [
      {
        path: '/',
        component: ChooseSandboxDataset,
      },
    ],
  },
  {
    path: routes.emailVerificationSent,
    component: EmailVerificationSent,
    exact: true,
  },
  {
    path: routes.verifyEmail,
    component: VerifyEmail,
  },
  {
    path: '/organizations/choose',
    component: OrgChooser,
    exact: true,
    isSecure: true,
  },
  {
    path: '/integrations/:type/callback',
    component: OAuthCallbackPage,
    isSecure: true,
  },
  {
    path: '/:schemaName',
    component: TenantRoute,
    isSecure: true,
    children: [
      {
        path: '/activate',
        component: Activate,
        isSecure: true,
      },
      {
        path: '/users',
        component: NavBars,
        isSecure: true,
        children: [
          {
            path: '/',
            component: UsersList,
            isSecure: true,
            exact: true,
          },
          {
            path: '/:id',
            component: UserDetails,
            isSecure: true,
          },
        ],
      },
      {
        path: '/accounts',
        component: NavBars,
        isSecure: true,
        children: [
          {
            path: '/',
            component: AccountsList,
            isSecure: true,
            exact: true,
          },
          {
            path: '/:id',
            component: AccountDetails,
            isSecure: true,
          },
        ],
      },
      // New PQL workflow
      {
        path: routes.opportunities,
        component: NavBars,
        isSecure: true,
        children: [
          {
            path: '/list',
            component: OpportunitiesList,
            exact: true,
          },
          {
            path: '/details/:id',
            component: NewOpDetails,
            exact: true,
            isSecure: true,
          },
          {
            path: '/new',
            component: PQLWorkflow,
            exact: true,
          },
          {
            path: `/duplicate/:id`,
            component: PQLWorkflow,
            exact: true,
          },
          {
            path: `/edit-exports/:id`,
            component: EditExports,
            exact: true,
          },
          {
            path: `/*`,
            component: StaticNotFound,
            exact: true,
          },
        ],
      },
      {
        path: '/integrations',
        component: NavBars,
        isSecure: true,
        children: [
          {
            path: '/',
            exact: true,
            component: IntegrationList,
            isSecure: true,
          },
          {
            path: '/webhook/:integrationId/new',
            exact: true,
            component: WebhookPage,
            isSecure: true,
          },
          {
            path: '/webhook/:connectedIntegrationId/edit',
            exact: true,
            component: WebhookPage,
            isSecure: true,
          },
          {
            path: '/:integrationId/add',
            component: AddIntegrationPage,
            isSecure: true,
          },
          {
            path: '/:connectedIntegrationId/edit',
            component: AddIntegrationPage,
            isSecure: true,
          },
        ],
      },
      {
        path: '/settings',
        component: NavBars,
        isSecure: true,
        children: [
          {
            path: '/team-members',
            component: TeamMembers,
            isSecure: true,
          },
          {
            path: '/member-profile',
            component: MemberProfile,
            isSecure: true,
          },
          {
            path: '/api',
            component: APISettings,
            isSecure: true,
          },
          {
            path: '/token/add',
            component: AddToken,
            isSecure: true,
          },
          {
            path: '/edit-profile',
            component: EditProfile,
            isSecure: true,
          },
          {
            path: '/change-password',
            component: ChangePassword,
            isSecure: true,
          },
          {
            path: '/member/add',
            component: AddTeamMember,
            isSecure: true,
          },
        ],
      },
      {
        path: '/member/grant-access/:email',
        component: GrantAccess,
        isSecure: true,
      },
      {
        path: `/*`,
        component: StaticNotFound,
        exact: true,
      },
    ],
  },
];
