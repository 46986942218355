export const setUpGuides = {
  amplitude: {
    'Check your role': [
      {
        text: 'Log in to <a target="_blank" href="https://analytics.amplitude.com/login">Amplitude</a>',
      },
      {
        text: 'On the left-hand navigation menu, click settings',
        image: 'https://assets.toplyne.io/setup-guides/amplitude-settings.png',
      },
      {
        text: 'Click on Members. You will then see a list of all members in your organisation and their corresponding roles',
        image: 'https://assets.toplyne.io/setup-guides/amplitude-members.png',
      },
    ],
    'Get API Key and Secret Key': [
      {
        text: 'Log in to <a target="_blank" href="https://analytics.amplitude.com/login">Amplitude</a>',
      },
      {
        text: 'On the left-hand navigation menu, click settings',
        image: 'https://assets.toplyne.io/setup-guides/amplitude-settings.png',
      },
      {
        text: 'Click on Projects. You will see a list of projects',
        image: 'https://assets.toplyne.io/setup-guides/amplitude-projects.png',
      },
      {
        text: 'Select the project you wish to use. Make a note of project name. You will need it',
        image: 'https://assets.toplyne.io/setup-guides/amplitude-project-select.png',
      },
      {
        text: 'In your project dashboard, find the API Key and the Secret Key. Make a note of your API Key and Secret Key. You will need them later. Tip: Click Show to view your Secret Key',
        image: 'https://assets.toplyne.io/setup-guides/secret_key.png',
      },
    ],
    'Connect to Toplyne': [
      {
        text: 'Name your integration',
      },
      {
        text: 'In the project field, enter project name you had noted down',
      },
      {
        text: 'Do the same for the API Key and the Secret key as well, in the API Key and Secret Key fields',
      },
      {
        text: 'Click on Complete Integration to finish',
      },
    ],
  },
  mixpanel: {
    'Find API Secret': [
      {
        text: 'Log in to your <a target="_blank" href="https://mixpanel.com/login/">Mixpanel account</a>',
      },
      {
        text: 'Click settings and then go to your Personal settings page',
        image: 'https://assets.toplyne.io/setup-guides/mixpanel-settings.png',
      },
      {
        text: 'Go to the Projects tab',
      },
      {
        text: 'In the All Projects tab, find the API Secret and make a note of it. You will need it',
        image: 'https://assets.toplyne.io/setup-guides/get-keys.png',
      },
    ],
    'Connect to Toplyne': [
      {
        text: 'Name your integration',
      },
      {
        text: 'Enter the API Secret in the API Secret field',
      },
      {
        text: 'Click on complete integration to finish',
      },
    ],
  },
  segment: {
    paid: {
      'Get the Source ID': [
        {
          text: 'Log in to <a target="_blank" href="https://app.segment.com/login">Segment</a>',
        },
        {
          text: 'On the left-hand navigation menu, click Connections -> Sources.',
          image: 'https://assets.toplyne.io/setup-guides/segment-select-sources.png',
        },
        {
          text: 'Now, click on your desired source from the list of "My Sources".',
        },
        {
          text: 'Under the Settings tab, click on "API Keys" from left sub-navigation menu.',
          image: 'https://assets.toplyne.io/setup-guides/segment-select-source-id.png',
        },
        {
          text: 'Copy the Source ID.',
        },
      ],
      'Find the Access Token': [
        {
          text: 'Go to the Access Management tab and select Tokens',
          image: 'https://assets.toplyne.io/setup-guides/segment-select-token-tab.png',
        },
        {
          text: 'Click on create token',
          image: 'https://assets.toplyne.io/setup-guides/segment-create-token.png',
        },
        {
          text: 'Add a description. Select assign access as Workspace Owner and click on create button',
          image: 'https://assets.toplyne.io/setup-guides/segment-token-description.png',
        },
        {
          text: 'Copy the access token. You will need it.',
          image: 'https://assets.toplyne.io/setup-guides/segment-copy-access-token.png',
        },
      ],
      'Connecting historic data with "Segment Replay"': [
        {
          text: "To train our models, Toplyne will need at least 6-12 months of historical data as a one-time drop. To enable this, you'll need to launch a Replay from your Segment Account to trigger a transfer of your historical Segment data source to Toplyne. Learn more about <a target='_blank' href='https://segment.com/docs/guides/what-is-replay/'>Segment Replay</a>. ",
        },
        {
          text: "Replays are not self-serve in Segment, so you'll need to contact their team directly to request a replay specifying the following ",
          image: 'https://assets.toplyne.io/setup-guides/segment-historic-sync.png',
        },
      ],
    },
    free: {
      'Get Workspace Slug Name': [
        {
          text: 'Log in to <a target="_blank" href="https://app.segment.com/login">Segment</a>',
        },
        {
          text: 'On the left-hand navigation menu, click Settings -> Workspace Settings',
          image: 'https://assets.toplyne.io/setup-guides/segment-settings.png',
        },
        {
          text: 'Under the General Settings tab, copy value of Slug *. This is your workspace slug name',
          image: 'https://assets.toplyne.io/setup-guides/segment-get-slug.png',
        },
      ],
      'Find Access Token': [
        {
          text: 'Go to the Access Management tab and select Tokens',
          image: 'https://assets.toplyne.io/setup-guides/segment-select-token-tab.png',
        },
        {
          text: 'Click on create token',
          image: 'https://assets.toplyne.io/setup-guides/segment-create-token.png',
        },
        {
          text: 'Add a description. Select assign access as Workspace Owner and click on create button',
          image: 'https://assets.toplyne.io/setup-guides/segment-token-description.png',
        },
        {
          text: 'Copy the access token. You will need it.',
          image: 'https://assets.toplyne.io/setup-guides/segment-copy-access-token.png',
        },
      ],
      'Historic data sync': [
        {
          text: "Segment's non-Business plans do not support Replay, However, If you've been able to accumulate historic Segment data in any other data destination (such as S3, GCP, Snowflake, or Redshift) - Toplyne can ingest data directly from these destinations.",
        },
        {
          text: 'Please write to us at <a href = "mailto: support@toplyne.io">support@toplyne.io </a> or reach out to your assigned Customer Success Manager.',
        },
      ],
    },
  },
  snowflake: {
    '': [
      {
        text: 'login to your <a target="_blank" href="https://jfa01628.us-east-1.snowflakecomputing.com/console/login#/">Snowflake account</a>',
      },
      {
        text: "On the upper right corner, click on your email account and switch role to ACCOUNTADMIN. Reach out to your admin if you can't see the option",
        image: 'https://assets.toplyne.io/setup-guides/snowflake-switch-role.png',
      },
      {
        text: 'On the upper left side, click on the “Shares” icon',
        image: 'https://assets.toplyne.io/setup-guides/snowflake-shares.png',
      },
      {
        text: 'Select the “Outbound” tab',
        image: 'https://assets.toplyne.io/setup-guides/snowflake-outbound.png',
      },
      {
        text: 'Click on "Create" icon next to "Outbound" tab button',
        image: 'https://assets.toplyne.io/setup-guides/snowflake-create.png',
      },
      {
        text: 'Fill in the “Secure Share Name” field (this is a text-field, so any name should work) and then select the database you would like to share',
        image: 'https://assets.toplyne.io/setup-guides/snowflake-select-db.png',
      },
      {
        text: 'After this, click on “Select Tables  & Secure Views”',
        image: 'https://assets.toplyne.io/setup-guides/snowflake-select-tables.png',
      },
      {
        text: 'Secure view to the select tables you want to share and hit “Apply”',
        image: 'https://assets.toplyne.io/setup-guides/snowflake-secure-view.png',
      },
      {
        text: 'Click On “Create” on the bottom right',
      },
      {
        text: 'Click On “Next: Add Consumers” on the bottom right to add the account you’d like to share the data with',
        image: 'https://assets.toplyne.io/setup-guides/snowflake-add-consumers.png',
      },
      {
        text: 'Under “Account Type”, select “Full” (don’t worry - this will grant read-only access!) and enter <b>“jfa01628”</b> in the text box',
        image: 'https://assets.toplyne.io/setup-guides/snowflake-share-access.png',
      },
      {
        text: 'Click on “Add” on the bottom right - and we’re good to go 🚀',
        image: 'https://assets.toplyne.io/setup-guides/snowflake-save-consumers.png',
      },
    ],
  },
  rudderstack: {
    'Add a webhook destination': [
      {
        text: 'login to your <a href="https://www.rudderstack.com/" target="_blank">Rudderstack account</a>',
      },
      {
        text: 'On the left hand panel, Click on "Destinations" tab',
        image: 'https://assets.toplyne.io/setup-guides/rudderstack-destinations-tab.png',
      },
      {
        text: 'In the new window that pops up, Click on "New destination" CTA',
        image: 'https://assets.toplyne.io/setup-guides/rudderstack-new-destination.png',
      },
      {
        text: 'On the window that opens, search for "Webhook" destination and click on the result',
        image: 'https://assets.toplyne.io/setup-guides/rudderstack-webhook-destination.png',
      },
      {
        text: 'Name your destination and click on "Continue" button on bottom right',
        image: 'https://assets.toplyne.io/setup-guides/rudderstack-save-destination.png',
      },
      {
        text: 'Select all data sources you want to connect with this destination (for example: Rudderstack HTTP source) and click on "Continue" button on bottom right',
        image: 'https://assets.toplyne.io/setup-guides/rudderstack-select-data-source.png',
      },
    ],
    'Connect to Toplyne': [
      {
        text: 'Name your integration and click on "Complete Integration"',
      },
      {
        text: 'Copy the Webhook URL in your screen(with a copy button next to it)',
      },
      {
        text: 'Go to your created webhook destination in Rudderstack and, paste the webhook URL. In URL Method, select "POST"  and leave Headers empty',
      },
      {
        text: 'On the next window, select "No transformations needed" and click on "Continue"',
        image: 'https://assets.toplyne.io/setup-guides/rudderstack-no-transformations.png',
      },
      {
        text: 'Come back to Toplyne and click on "Complete Integration" to proceed - and we’re good to go 🚀',
      },
    ],
  },
  big_query: {
    '': [
      {
        text: 'login into You GCP account and go to BigQuery and select your dataset',
        image: 'https://assets.toplyne.io/setup-guides/bigquery-select-dataset.png',
      },
      {
        text: 'Click on "Sharing" on the second tab from the right & click on "Permissions"',
        image: 'https://assets.toplyne.io/setup-guides/bigquery-open-permissions.png',
      },
      {
        text: 'Add Principal as "data-sharing@toplyne.io" and grant BigQuery Data Editor, Data Owner and Data Viewer Access',
        image: 'https://assets.toplyne.io/setup-guides/bigquery-grant-access.png',
      },
      {
        text: 'Go to IAM and click on ADD',
        image: 'https://assets.toplyne.io/setup-guides/bigquery-add-iam.png',
      },
      {
        text: 'Grant BigQuery JOB User access to the user <a href="mailto:data-sharing@toplyne.io">"data-sharing@toplyne.io"</a> - and we’re good to go 🚀',
        image: 'https://assets.toplyne.io/setup-guides/biquery-grant-job-user-access.png',
      },
    ],
  },
};

export const prerequisites = {
  amplitude:
    'To connect Amplitude, you need an <a target="_blank" href="https://analytics.amplitude.com/login">Amplitude</a> account' +
    '<br> To get API Key and Secret Key, you need to be on an admin role </br>' +
    '',
  mixpanel:
    'To connect Mixpanel, you must have a billing-enabled Mixpanel account. For more see <a target="_blank" href="https://help.mixpanel.com/hc/en-us/articles/115004491123-Paying-for-Mixpane">Mixpanel\'s payment documentation</a>',
  segment:
    'To connect Segment you need to have a <a target="_blank" href="https://segment.com/docs/connections/sources/">source</a> added with events flowing in.',
  snowflake: null,
  rudderstack: null,
  big_query: null,
};
