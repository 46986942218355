import create, { SetState } from 'zustand';

export interface ApiTokenStoreInterface {
  id: number;
  name: string | null;
  token: string | null;
  expiresOn: string | null;
  setTokenStore: any;
  clearTokenStore: any;
}

const defaultState = {
  id: null,
  name: null,
  token: null,
  expiresOn: null,
};

export const tokenStore = (set: SetState<ApiTokenStoreInterface>): ApiTokenStoreInterface => ({
  ...defaultState,
  setTokenStore: (data) => set((state) => ({ ...state, ...data })),
  clearTokenStore: () => set(() => ({ ...defaultState })),
});
export const useApiTokenStore = create<ApiTokenStoreInterface>(tokenStore);
