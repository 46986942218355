import { IntegrationType } from 'api-client';
import * as Yup from 'yup';
import _ from 'lodash';

export const isCRM = (integration: IntegrationType) => {
  if (
    [
      IntegrationType.SALESFORCE,
      IntegrationType.SALESFORCE_SANDBOX,
      IntegrationType.PIPEDRIVE,
      IntegrationType.HUBSPOT,
    ].includes(integration)
  ) {
    return true;
  }
  return false;
};

// Tags are initially mapped from a dictionary
// to a { key: value } pair.
export function mapToArray(obj: { [k: string]: string }) {
  if (!obj) return [];
  return Object.keys(obj).map((keyName) => ({ key: keyName, value: obj[keyName] }));
}

// Different integrations need different metadata to be
// sent encapsulating the entity.
export const getEntityObject = (
  entityValue: string | string[],
  connectedIntegration: IntegrationType
) => {
  switch (connectedIntegration) {
    case IntegrationType.SALESFORCE:
    case IntegrationType.SALESFORCE_SANDBOX:
      return { [connectedIntegration]: { sObject: entityValue } };
    case IntegrationType.EMAIL:
      return { [connectedIntegration]: entityValue };
    case IntegrationType.TRAY:
      return {
        [connectedIntegration]: { entity: entityValue },
      };
    default:
      return { [connectedIntegration]: { object: entityValue } };
  }
};

// gets default entity object
export const getDefaultEntity = (
  entityValue: Record<string, any>,
  connectedIntegration: IntegrationType
): string | string[] => {
  switch (connectedIntegration) {
    case 'salesforce':
      return entityValue?.salesforce.sObject;
    case 'salesforce_sandbox':
      return entityValue?.salesforceSandbox.sObject;
    case 'email':
      return entityValue?.email;
    case 'tray':
      return entityValue?.[connectedIntegration].entity;
    default:
      return entityValue?.[connectedIntegration].object;
  }
};

export function validateFieldMappingsTypes(
  sourceType: string,
  destinationType: string,
  typeMappings: object
): boolean {
  return (
    sourceType &&
    destinationType &&
    typeMappings[sourceType] &&
    !typeMappings[sourceType]?.includes(destinationType)
  );
}

export function checkTypeMappings(
  sourceTypePath: string,
  destinationTypePath: string,
  message: string,
  allowedTypeMappings: any
) {
  return this.test('checkTypeMappings', '', function (list: any[]) {
    const errors = [];
    allowedTypeMappings = this?.options?.parent?.typeMappings ?? {};
    list.forEach((item, index) => {
      const sourceTypeVal = _.get(item, sourceTypePath);
      const destinationTypeVal = _.get(item, destinationTypePath);
      if (validateFieldMappingsTypes(sourceTypeVal, destinationTypeVal, allowedTypeMappings)) {
        errors.push(
          this.createError({
            path: `${this.path}[${index}].${sourceTypePath}`,
            message,
          })
        );
        errors.push(
          this.createError({
            path: `${this.path}[${index}].${destinationTypePath}`,
            message,
          })
        );
      }
    });

    if (!_.isEmpty(errors)) {
      throw new Yup.ValidationError(errors);
    }

    return true;
  });
}

// dependentArr -> { field: form_field, propertyPath }
// dependentArr -> this field should be unique w.r.t another formik
// field.
export function uniqueProperty(propertyPath: string, message: string, dependentArr?) {
  // list -> current array
  // ctx -> formik context
  return this.test('unique', '', function (list: any[], ctx) {
    const errors = [];
    list.forEach((item, index) => {
      const propertyValue = _.get(item, propertyPath);

      if (propertyValue && _.filter(list, [propertyPath, propertyValue]).length > 1) {
        errors.push(
          this.createError({
            path: `${this.path}[${index}].${propertyPath}`,
            message,
          })
        );
      }
    });

    if (dependentArr) {
      list.forEach((item, index: number) => {
        const currProperty = _.get(item, propertyPath);
        if (
          currProperty &&
          _.filter(ctx.parent[dependentArr?.field], [dependentArr?.propertyPath, currProperty])
            .length >= 1
        ) {
          errors.push(
            this.createError({
              path: `${this.path}[${index}].${propertyPath}`,
              message,
            })
          );
        }
      });
    }

    if (!_.isEmpty(errors)) {
      throw new Yup.ValidationError(errors);
    }

    return true;
  });
}

export const getItemSuffix = (keySpace: string) => {
  if (keySpace.includes('event')) {
    return 'event';
  }
  return 'property';
};

export const getCorrelationPropertyType = (isSystem: boolean, source: string, keySpace: string) => {
  if (source === 'slintel') {
    return `Slintel ${getItemSuffix(keySpace)}`;
  }

  if (isSystem) {
    return `System ${getItemSuffix(keySpace)}`;
  }

  return `Custom ${getItemSuffix(keySpace)}`;
};
