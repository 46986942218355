import * as Tooltip from '@radix-ui/react-tooltip';
import { classNames } from 'utils/common';

type tooltipProps = {
  label?: string;
  placement: 'top' | 'bottom' | 'left' | 'right';
  className: string;
  hasArrow: boolean;
  children: React.ReactNode;
  content?: React.ReactNode;
  open?: boolean;
  tooltipArrowClassName?: string;
};

const ToolTip = (props: tooltipProps) => {
  const { children, label, placement, className, hasArrow, content, open, tooltipArrowClassName } =
    props;
  return (
    <Tooltip.Root open={open} delayDuration={0}>
      <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
      <Tooltip.Content side={placement}>
        {hasArrow && <Tooltip.Arrow className={tooltipArrowClassName} />}
        {content || (
          <p className={classNames('font-medium rounded p-2 max-w-80 z-40', className)}>{label}</p>
        )}
      </Tooltip.Content>
    </Tooltip.Root>
  );
};

ToolTip.defaultProps = {
  hasArrow: false,
  className: 'text-white bg-tw-gray-7',
  placement: 'top',
};

export default ToolTip;
