import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { useVirtual } from 'react-virtual';
import { highlightSearchedString } from '../../../../utils/common';
import CheckBoxInput from '../../../inputs/CheckBoxInput';
import InputWIthAddons from '../../../inputs/InputWIthAddons';
import { FilledInfoIcon } from '../../../data-display/Icons';
import { CustomCheckBox } from '../../../inputs/CustomCheckBox';
import { PopoverContentProps } from './PopoverContent';
import VirtualItemMeasurer from 'ui-components/util/VirtualItemMeasurer';
import VirtualItem from 'ui-components/util/VirtualItem';
export interface StringFilterPopoverProps extends PopoverContentProps {
  handleLocalFilterChange?: (event: React.ChangeEvent) => void;
  filteredResults?: any;
  propertyFilters?: string[];
  selectAllFilterCheckHandling?: () => void;
  filterCheckHandling?: (bin: string) => void;
}

export const DisplayOptionsForEqAndNEFilter: FC<StringFilterPopoverProps> = (props) => {
  const {
    selectAllFilterCheckHandling,
    allSelected,
    propertyFilters,
    filterCheckHandling,
    filtersObj,
  } = props;

  const [sortedPropertyFilters, setSortedPropertyFilters] = useState([...(propertyFilters || [])]);

  useEffect(() => {
    const checkedPropertyFilters = propertyFilters.filter((propertyFilter) => {
      return filtersObj[propertyFilter];
    });
    const uncheckedPropertyFilters = propertyFilters.filter((propertyFilter) => {
      return !filtersObj[propertyFilter];
    });
    setSortedPropertyFilters([...checkedPropertyFilters, ...uncheckedPropertyFilters]);
  }, [propertyFilters]);

  const parentRef = React.useRef();

  const rowVirtualizer = useVirtual({
    size: sortedPropertyFilters.length,
    parentRef,
    estimateSize: React.useCallback(() => 18, []),
  });

  const isChecked = React.useCallback(
    (item) => {
      if (filtersObj[item]) return true;

      return false;
    },
    [filtersObj]
  );

  return (
    <div>
      <CustomCheckBox
        key={`select-all-${allSelected}`}
        clickEvent={() => selectAllFilterCheckHandling()}
        checkedValue={allSelected}
        value="Select All"
        checkboxClassname="my-1"
      />

      <div
        ref={parentRef}
        className="List"
        style={{
          height: `110px`,
          width: `100%`,
          overflow: 'auto',
        }}
      >
        <div
          className="ListInner"
          style={{
            height: `${rowVirtualizer.totalSize}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {rowVirtualizer.virtualItems.map((virtualRow) => (
            <VirtualItemMeasurer
              tagName="div"
              data-key={virtualRow.key}
              key={virtualRow.key}
              measure={virtualRow.measureRef}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              <VirtualItem index={virtualRow.index}>
                <div>
                  <CustomCheckBox
                    clickEvent={() => filterCheckHandling(sortedPropertyFilters[virtualRow.index])}
                    key={`${virtualRow.key}-${filtersObj[sortedPropertyFilters[virtualRow.index]]}`}
                    checkedValue={isChecked(sortedPropertyFilters[virtualRow.index])}
                    value={sortedPropertyFilters[virtualRow.index]}
                    checkboxClassname="my-1"
                  />
                </div>
              </VirtualItem>
            </VirtualItemMeasurer>
          ))}
        </div>
      </div>
    </div>
  );
};

export const EqAndNEFilterPopover: FC<StringFilterPopoverProps> = (props) => {
  const {
    isLoading,
    filtersObj,
    setFiltersObj,
    rowBins,
    allSelected,
    setAllSelected,
    stringComparator,
    isComparatorChanged,
    setButtonDisabled,
  } = props;
  const [propertyFilters, setPropertyFilters] = useState<string[]>([]); //list for array of filters for strings (comparator = eq | ne)
  let filtersObjLength;
  if (filtersObj)
    filtersObjLength = Object.entries(filtersObj).filter(([, value]) => value === true).length;

  useEffect(() => {
    setPropertyFilters(rowBins);
  }, [rowBins]);

  useEffect(() => {
    if (propertyFilters?.length === rowBins?.length) {
      setAllSelected(false);
    }
  }, [rowBins?.length, setAllSelected]);

  useEffect(() => {
    if (Object.entries(filtersObj).filter(([, value]) => value === true).length) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [filtersObj, setButtonDisabled]);

  const handleLocalFilterChangeForMultiInput = (value: string) => {
    let results = [];
    if (!value) {
      results = rowBins;
    } else {
      if (stringComparator === 'eq') {
        results.push(
          ...(rowBins || []).filter((a) => a.toLowerCase().includes(value.toLowerCase()))
        );
      } else if (stringComparator === 'ne') {
        const values = rowBins.slice();
        results.push(...values.filter((a) => a.toLowerCase().includes(value.toLowerCase())));
      }
    }
    setPropertyFilters([...results]);
  };

  const filterCheckHandling = (bin) => {
    setFiltersObj({
      ...filtersObj,
      [bin]: !filtersObj[bin],
    });
  };

  const selectAllFilterCheckHandling = () => {
    let newFilterObj = {};
    propertyFilters?.forEach((r) => {
      newFilterObj[r] = !allSelected;
    });
    setFiltersObj(newFilterObj);
    setAllSelected(!allSelected);
  };

  const changeOptionsOnCompChange = (value: string) => {
    handleLocalFilterChangeForMultiInput(value);
  };

  return (
    <>
      <div className="px-3">
        <CheckBoxInput
          placeholder="Start typing..."
          onChange={(value) => handleLocalFilterChangeForMultiInput(value)}
          autoComplete="off"
          isComparatorChanged={isComparatorChanged}
          onComparatorChanged={changeOptionsOnCompChange}
        />
        {filtersObj && (
          <div className="mt-1 text-xs text-tw-black-7">
            {filtersObjLength > 1 && <>{filtersObjLength} results selected</>}
            {filtersObjLength > 0 && filtersObjLength <= 1 && (
              <>{filtersObjLength} result selected</>
            )}
          </div>
        )}
      </div>
      {isLoading && (
        <div className="px-3 mt-3">
          <>
            <div className="text-tw-black-1 text-xs font-regular mt-4">{'Results'}</div>
            <div className="space-y-5 animate-pulse mt-2">
              <div className="w-60 h-4 bg-gray-100" />
              <div className="w-48 h-4 bg-gray-100" />
            </div>
          </>
        </div>
      )}
      {!isLoading && propertyFilters && (
        <div className="px-3 mt-3">
          <div className="mt-4 mb-1 text-xs text-tw-black-1">
            Results ({propertyFilters.length})
          </div>
          <div className="mt-2 space-y-2 overflow-y-scroll max-h-36">
            <DisplayOptionsForEqAndNEFilter
              selectAllFilterCheckHandling={selectAllFilterCheckHandling}
              allSelected={allSelected}
              propertyFilters={propertyFilters}
              filterCheckHandling={filterCheckHandling}
              filtersObj={filtersObj}
            />
          </div>
        </div>
      )}
    </>
  );
};

export const ContainsAndDoesNotContainFilterPopover: FC<StringFilterPopoverProps> = (props) => {
  const {
    isLoading,
    rowBins,
    localSearchBin,
    setLocalSearchBin,
    stringComparator,
    setButtonDisabled,
  } = props;
  const [results, setResults] = useState<{ count: number; results: string[] }>({
    count: 0,
    results: [],
  });
  const parentRef = React.useRef(null);

  const [searchedString, setSearchedString] = useState<string>('');

  const handleLocalFilterChange = (value: string[]) => {
    setLocalSearchBin([...localSearchBin, ...value]);
  };

  useEffect(() => {
    if (searchedString || localSearchBin.length) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [searchedString, setButtonDisabled, localSearchBin]);

  useEffect(() => {
    let searchResults = [];
    const searchedQuery = searchedString.trim().toLowerCase();
    if (stringComparator === 'doesnotcontain') {
      searchResults = rowBins?.filter(
        (bin: string) => !(searchedQuery ? bin.toLowerCase().includes(searchedQuery) : true)
      );
      setResults({ ...results, count: searchResults.length, results: searchResults });
    } else if (stringComparator === 'contains') {
      searchResults = rowBins?.filter((bin: string) =>
        searchedQuery ? bin.toLowerCase().includes(searchedQuery) : true
      );
      setResults({ ...results, count: searchResults.length, results: searchResults });
    }
  }, [rowBins, searchedString, stringComparator]);

  const removeTag = (tag: string) => {
    let tags = localSearchBin.filter((t) => t !== tag);
    setLocalSearchBin(tags);
  };

  const rowVirtualizer = useVirtual({
    size: results.count,
    parentRef,
    estimateSize: React.useCallback(() => 16, []),
  });

  return (
    <>
      <div className="px-3">
        <InputWIthAddons
          ph="Start typing..."
          value={localSearchBin}
          onChange={(e) => handleLocalFilterChange(e)}
          removeTag={removeTag}
          onInput={(val) => setSearchedString(val)}
        />
      </div>
      {isLoading && localSearchBin ? (
        <div className="px-3 mt-2">
          <>
            <div className="text-tw-black-1 text-xs font-regular mt-4">{'Current Results'}</div>
            <div className="space-y-5 animate-pulse mt-2">
              <div className="w-60 h-4 bg-gray-100" />
              <div className="w-48 h-4 bg-gray-100" />
            </div>
          </>
        </div>
      ) : (
        [
          !isLoading &&
            localSearchBin &&
            (results?.count > 0 ? (
              <div className="px-3 mt-2">
                <div className="mt-4 mb-1 text-xs text-tw-black-1">
                  Current Results ({results?.count})
                </div>
                <div
                  ref={parentRef}
                  className="List"
                  style={{
                    height: `110px`,
                    width: `100%`,
                    overflow: 'auto',
                  }}
                >
                  <div
                    className="ListInner"
                    style={{
                      height: `${rowVirtualizer.totalSize}px`,
                      width: '100%',
                      position: 'relative',
                    }}
                  >
                    {rowVirtualizer.virtualItems.map((virtualRow) => (
                      <VirtualItemMeasurer
                        tagName="div"
                        data-key={virtualRow.key}
                        key={virtualRow.key}
                        measure={virtualRow.measureRef}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          transform: `translateY(${virtualRow.start}px)`,
                        }}
                      >
                        <VirtualItem index={virtualRow.index}>
                          <span
                            key={`${virtualRow.index}`}
                            className="bg-tw-gray-eb rounded-sm text-tw-black-7 text-xs py-0.5 px-3 m-1"
                          >
                            {highlightSearchedString(
                              results.results[virtualRow.index],
                              searchedString,
                              'bg-tw-black-9 text-tw-white-ff'
                            )}
                          </span>
                        </VirtualItem>
                      </VirtualItemMeasurer>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="h-12 px-3 mt-2 text-xs font-semibold text-tw-black-7 max-h-36">
                <p>0 results currently</p>
              </div>
            )),
        ]
      )}
    </>
  );
};

export const EqAndNEFilterPopoverForHighlyCardinalCorrelation: FC<StringFilterPopoverProps> = (
  props
) => {
  const { stringValues, setStringValues, setButtonDisabled } = props;
  const [propertyFilters, setPropertyFilters] = useState<string[]>(
    stringValues ? stringValues : []
  ); //list for array of filters for strings (comparator = eq | ne)

  const onPropertyFiltersChange = (value) => {
    setPropertyFilters((prev) => [...prev, ...value]);
  };

  useEffect(() => {
    setStringValues(propertyFilters);
    if (stringValues.length) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [propertyFilters, setButtonDisabled, setStringValues, stringValues.length]);

  const removeTag = (tag: string) => {
    let tags = propertyFilters.filter((t) => t !== tag);
    setPropertyFilters(tags);
  };

  return (
    <>
      <InputWIthAddons
        ph="Start typing..."
        value={propertyFilters}
        onChange={(e) => onPropertyFiltersChange(e)}
        removeTag={removeTag}
      />
      <div className="px-3 py-10 mt-2">
        <div className="flex space-x-2 text-xs place-items-start text-tw-black-9">
          <FilledInfoIcon className="w-10 h-auto fill-current" />
          <p>
            Too many distinct values to show results. But you can still type a value and apply the
            filter.
          </p>
        </div>
      </div>
    </>
  );
};
