import { SideBorder } from 'ui-components/data-display/Icons';
import { classNames } from 'utils/common';

export default function SideTab({
  onClick,
  isActive,
  label,
  isLastItem,
}: {
  onClick: () => void;
  isActive: boolean;
  label: string;
  isLastItem?: boolean;
}) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'font-medium cursor-pointer flex items-center gap-x-2',
        !isLastItem && 'mb-4',
        isActive ? 'text-tw-blue-0d' : 'text-tw-black-7'
      )}
    >
      <SideBorder
        className={classNames(
          isActive ? 'text-tw-blue-0d' : 'text-transparent',
          'fill-current h-6'
        )}
      />
      {label}
    </div>
  );
}

SideTab.defaultProps = {
  isLastItem: false,
};
