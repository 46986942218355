import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ConnectedIntegration } from 'api-client';
import {
  CheckIcon,
  CrossIcon,
  EmptyCheckbox,
  FilterFunnelIcon,
  SearchIcon,
} from 'ui-components/data-display/Icons';
import Fuse from 'fuse.js';
import { PrimaryButton, SecondaryButton, TertiaryButton } from 'ui-components/inputs/Buttons';
import useConnectedIntegrations from 'hooks/useConnectedIntegrations';
import IntegrationIcon from 'pages/integrations/sub-components/IntegrationIcon';
import { default as React, useCallback, useEffect, useState, useMemo } from 'react';
import { useAnalytics } from 'telemetry';
import { AnalyticsConst, AnalyticsEvents } from 'telemetry/constants';
import { trim } from 'utils/helpers';
import { usePQLFilter } from './hooks/usePQLFilter';
import { useOpportunityListStore } from './store/OpportunityListStore';

type FuseIntegrationSearchResult = {
  item: ConnectedIntegration;
  refIndex?: number;
};

export default function FilterDestinations() {
  const {
    data: destinations,
    isLoading: loadingDestinations,
    isError: errorFetchingDestinations,
  } = useConnectedIntegrations();

  const { track } = useAnalytics();

  // search space represents search results
  const [searchSpace, setSearchSpace] = useState<FuseIntegrationSearchResult[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  // dropdown open
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  // selected, not selected destinations
  const [checkedDestinations, setCheckedDestinations] = useState<FuseIntegrationSearchResult[]>([]);
  const [uncheckedDestinations, setUncheckedDestinations] = useState<FuseIntegrationSearchResult[]>(
    []
  );

  const { queryParams, destinationFieldMapping, updateDestinationFieldMapping } =
    useOpportunityListStore();

  const { updateSearchParams } = usePQLFilter();

  useEffect(() => {
    if (destinations) {
      setSearchSpace(
        destinations
          .filter((destination) => destination.integration.supportsWrite && destination.active)
          .map((d) => ({
            item: d,
          }))
      );
    }
  }, [destinations]);

  // This useEffect is used to filter checked and unchecked destinations
  // from the list of all destinations.
  useEffect(() => {
    let checked = [];
    let unchecked = [];
    searchSpace?.map((searchItem) => {
      if (destinationFieldMapping[searchItem?.item?.id]) {
        checked.push({ item: searchItem?.item });
      } else unchecked.push({ item: searchItem?.item });
      return searchItem?.item;
    });
    setCheckedDestinations(checked);
    setUncheckedDestinations(unchecked);
    // adding field mapping to the deps will make it jump.
  }, [searchSpace, dropdownOpen]);

  // Note: You can append any number of destinations in the URL, doesn't mean
  // we will show every filter applied cause it makes no sense.
  function getActualDestinationFiltersApplied(param: string[]): number {
    // source: compare from destinations API
    return destinations
      ?.map((d) => {
        if (param.includes(d.id.toString())) {
          return 1;
        } else return 0;
      })
      .reduce((a, b) => a + b, 0);
  }

  // new fuse instance.
  // let's memoize the fuse instance for performances
  const fuseInstance = useMemo(
    () =>
      new Fuse(destinations, {
        keys: ['name', 'type'],
        threshold: 0.3,
      }),
    [destinations]
  );

  const handleSearch = useCallback(
    (value: string) => {
      setSearchTerm(value);
      if (value) {
        // update current search space.
        setSearchSpace(fuseInstance.search(value));
      } else {
        // reset search
        setSearchSpace(
          destinations?.map((d) => ({
            item: d,
          }))
        );
      }
    },
    [destinations, fuseInstance]
  );

  return (
    <div className="mt-8">
      <DropdownMenu.Root open={dropdownOpen} onOpenChange={() => setDropdownOpen(!dropdownOpen)}>
        <DropdownMenu.Trigger>
          <div className="flex items-center px-4 py-3 ml-4 rounded-md border-1 grow-1 border-tw-gray-ed whitespace-nowrap">
            <FilterFunnelIcon className="fill-current text-tw-black-5 mt-0.5" />
            <div className="ml-3.5 flex items-center">
              Filter destinations
              {queryParams.destinations?.length > 0 && (
                <div className="flex items-center">
                  <div className="ml-2 text-tw-gray-eb">|</div>
                  <div className="ml-2 font-medium text-tw-blue-0d">
                    {' '}
                    {getActualDestinationFiltersApplied(queryParams.destinations)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          onClick={(e) => {
            e.preventDefault();
          }}
          key="dropdown-menu-content"
          className="w-64 mt-4 ml-4 bg-white rounded-lg shadow-lg"
        >
          <div className="pt-1">
            {/* Search destinations */}
            <div className="px-5">
              <div className="flex items-center justify-between w-full px-3 py-2 mt-4 bg-white rounded-lg gap-x-2 input-group border-1 border-tw-gray-ed">
                <div className="cursor-pointer">
                  <SearchIcon />
                </div>
                <input
                  value={searchTerm}
                  key="search-destinations"
                  type="text"
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                  id="searchInput"
                  placeholder="Search destinations"
                  className="flex-1 w-full my-2 text-sm bg-transparent focus:outline-none text-tw-black-7"
                />
                <span className="flex-none cursor-pointer">
                  {searchTerm ? (
                    <CrossIcon
                      className="w-2 fill-current text-tw-black-3"
                      onClick={() => {
                        setSearchTerm('');
                        // reset search
                        setSearchSpace(
                          destinations?.map((d) => ({
                            item: d,
                          }))
                        );
                      }}
                    />
                  ) : (
                    <div className="w-2"></div>
                  )}
                </span>
              </div>
            </div>
            {errorFetchingDestinations && (
              <div className="mt-4 text-tw-red">Failed to fetch playbooks</div>
            )}
            {(errorFetchingDestinations || loadingDestinations) &&
              Array(5)
                .fill(0)
                .map((val, id) => {
                  return (
                    <div key={id} className="flex w-full px-5 my-2">
                      <div className="w-full h-6 bg-tw-gray-eb animate-pulse"></div>
                    </div>
                  );
                })}
            {/* If no search results */}
            {!errorFetchingDestinations && !loadingDestinations && searchSpace?.length === 0 && (
              <div className="w-full px-2 text-sm text-center my-7">
                <div className="text-tw-black-5">No destinations found</div>
                {searchTerm && (
                  <SecondaryButton
                    type="button"
                    onClick={() => {
                      setSearchTerm('');
                      // reset search
                      setSearchSpace(
                        destinations?.map((d) => ({
                          item: d,
                        }))
                      );
                    }}
                    className="mx-auto mt-2 mb-8 text-sm text-tw-blue-0d"
                  >
                    Clear search query
                  </SecondaryButton>
                )}
              </div>
            )}
            {!errorFetchingDestinations &&
              !loadingDestinations &&
              destinations &&
              searchSpace?.length > 0 && (
                <div className="px-5 mt-1 overflow-scroll sm:max-h-40 2xl:max-h-48">
                  {checkedDestinations?.map((searchItem) => {
                    const { id, name } = searchItem.item;
                    return (
                      <div
                        key={id}
                        className="flex items-center w-full py-3 cursor-pointer"
                        onClick={() => {
                          // remove destination in store if checked,
                          // set destination from store if unchecked.
                          if (destinationFieldMapping[id]) {
                            let obj = destinationFieldMapping;
                            delete obj[id];
                            updateDestinationFieldMapping(obj);
                          } else {
                            updateDestinationFieldMapping({
                              ...destinationFieldMapping,
                              [id]: name,
                            });
                          }
                        }}
                      >
                        <div className="flex items-center grow">
                          <div className="w-5 h-5 mr-4">
                            {destinationFieldMapping[id] ? (
                              <CheckIcon className="w-5 h-5 rounded-md fill-current text-tw-blue-0d" />
                            ) : (
                              <EmptyCheckbox className="w-5 h-5 rounded-md fill-current text-tw-black-9" />
                            )}
                          </div>
                          <div>{trim(searchItem?.item.name, 24)}</div>
                        </div>
                        <div className="w-6 h-6">
                          <IntegrationIcon
                            width={24}
                            height={24}
                            logoUrl={searchItem?.item?.integration?.logoUrl}
                            type={searchItem?.item?.type}
                          />
                        </div>
                      </div>
                    );
                  })}
                  {uncheckedDestinations?.map((searchItem) => {
                    return (
                      <div
                        key={searchItem?.item?.id}
                        className="flex items-center w-full py-3 cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          // update destinationFieldMapping based
                          // on whether the destination is being checked or not.
                          if (destinationFieldMapping[searchItem?.item?.id]) {
                            let obj = destinationFieldMapping;
                            delete obj[searchItem?.item?.id];
                            updateDestinationFieldMapping(obj);
                          } else {
                            updateDestinationFieldMapping({
                              ...destinationFieldMapping,
                              [searchItem?.item?.id]: searchItem?.item?.name,
                            });
                          }
                        }}
                      >
                        <div className="flex items-center grow">
                          <div className="w-5 h-5 mr-3">
                            {destinationFieldMapping[searchItem?.item?.id] ? (
                              <CheckIcon className="w-5 h-5 rounded-md fill-current text-tw-blue-0d" />
                            ) : (
                              <EmptyCheckbox className="w-5 h-5 rounded-md fill-current text-tw-black-9" />
                            )}
                          </div>
                          <div className="text-sm text-tw-black-5">
                            {trim(searchItem?.item?.name, 24)}
                          </div>
                        </div>
                        <div>
                          <IntegrationIcon
                            logoUrl={searchItem?.item?.integration?.logoUrl}
                            width={24}
                            height={24}
                            type={searchItem?.item?.type}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
          </div>
          <hr className="p-0" />
          <div className="flex w-full rounded-lg bg-tw-white-ff min-h-max shadow-b-lg">
            <div className="flex py-2 ml-auto">
              {queryParams.destinations.length === 0 ? (
                <TertiaryButton
                  className="mr-3"
                  onClick={() => {
                    // clear search term, destinationFieldMapping
                    // and close the dialog
                    updateDestinationFieldMapping({});
                    setSearchTerm('');
                    // reset search
                    setSearchSpace(
                      destinations?.map((d) => ({
                        item: d,
                      }))
                    );
                    setDropdownOpen(false);
                  }}
                >
                  Cancel
                </TertiaryButton>
              ) : (
                <TertiaryButton
                  className="mr-3"
                  onClick={() => {
                    // clear search term and close the dialog
                    setSearchTerm('');
                    // reset search
                    setSearchSpace(
                      destinations?.map((d) => ({
                        item: d,
                      }))
                    );
                    // reset commits to destination queryParams
                    updateSearchParams({
                      ...queryParams,
                      destinations: [],
                    });
                    updateDestinationFieldMapping({});
                    setDropdownOpen(false);
                  }}
                >
                  Reset
                </TertiaryButton>
              )}
              <PrimaryButton
                className="ml-auto mr-3"
                onClick={() => {
                  // applies the current field mapping to the selected destinations
                  // and closes the dialog
                  updateSearchParams({
                    ...queryParams,
                    destinations: Object.keys(destinationFieldMapping),
                  });
                  track(AnalyticsEvents.OPPORTUNITY_FILTER, {
                    [AnalyticsConst.TYPE]: 'destinations',
                    [AnalyticsConst.VALUE]: Object.keys(destinationFieldMapping),
                  });
                  setDropdownOpen(false);
                }}
              >
                Apply
              </PrimaryButton>
            </div>
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
}
