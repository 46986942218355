import * as React from 'react';

import { Badge } from '@chakra-ui/react';

interface TableProps {}

interface UserBillingAmountProps {
  row: any;
}

const UserDetailStatus: React.FC<TableProps> = () => {
  return <Badge colorScheme="green">PAID</Badge>;
};

const UserBillingAmount: React.FC<UserBillingAmountProps> = (props) => {
  let { currency, amount } = props.row.original;
  const CURRENCY_SYMBOLS = { usd: '$' };
  return (
    <>
      {CURRENCY_SYMBOLS[currency]} {amount}
    </>
  );
};

export const Columns = [
  {
    Header: 'Amount',
    accessor: '',
    Cell: ({ row }: { row: any; column: any }) => <UserBillingAmount row={row} />,
  },
  {
    Header: 'Date',
    accessor: 'created',
    Cell: ({ row }: { row: any; column: any }) => new Date(row.values.created).toLocaleDateString(),
  },
  {
    Header: 'Transaction Id',
    accessor: 'chargeId',
    Cell: ({ row }: { row: any; column: any }) => row.values.chargeId,
  },
  {
    Header: 'Status',
    accessor: '',
    Cell: () => <UserDetailStatus />,
  },
];
