import { toast as toastify } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, IntegrationType, useClient } from '../../../api-client';
import { addNewIntegrationToCache } from './useAddIntegration';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';

export type AddWebhookRequest = {
  name: string;
  url: string;
  method: string;
  headers: { [h: string]: string | { [key: string]: string } };
};

export default function useAddWebhook(integrationId: string | number) {
  const queryClient = useQueryClient();
  const client = useClient();

  return useMutation(
    (request: AddWebhookRequest) =>
      client.addIntegration({
        type: IntegrationType.WEBHOOK,
        name: request.name,
        integration: integrationId,
        connect: {
          webhook: {
            url: request.url,
            method: request.method,
            headers: request.headers,
          },
        },
      }),
    {
      onSuccess: async (data) => {
        return addNewIntegrationToCache(queryClient, data);
      },
      onError: (error: AxiosError) => {
        const errorMessage = error?.response?.data?.detail ?? '';
        toastify(
          <ErrorToast description={errorMessage || 'Unable to create a webhook integration'} />,
          {
            type: 'error',
          }
        );
      },
      onSettled: () => {
        queryClient.refetchQueries(['integrations', 'connected']);
      },
    }
  );
}
