import { PrimaryDropdown } from 'ui-components/navigation/Dropdown';
import { ErrorBoundary } from 'react-error-boundary';
import { useAuthStore } from 'hooks/useAuthStore';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { routes } from 'routing/clientRoutes';
import { ReactComponent as InfoIcon } from '../../../assets/images/inforound.svg';
import ErrorFromHook from '../../../ui-components/feedback/ErrorFromHook';
import { PrimaryButton, TertiaryButton } from '../../../ui-components/inputs/Buttons';
import { TextInput } from '../../../ui-components/inputs/Inputs';
import { convertDate } from '../../../utils/common';
import useAddAPIToken from '../hooks/useAddAPIToken';
import _debounce from 'lodash/debounce';
import { ErrorAnalyticsEvents } from 'telemetry/constants';
import ErrorBoundaryFallback from 'ui-components/feedback/ErrorBoundaryFallback';
import { useAnalytics } from 'telemetry';

interface CreateTokenProps {}

const expirationOptions = [
  {
    value: '7',
    label: '7 days',
  },
  {
    value: '14',
    label: '14 days',
  },
  {
    value: '30',
    label: '30 days',
  },
  {
    value: '60',
    label: '60 days',
  },
  {
    value: '0',
    label: 'Does not expire',
  },
];

export const AddTokenForm = ({ emitValuesToParent, isError }) => {
  const date = new Date();
  date.setDate(date.getDate() + 7);
  const [tokenExpireOn, setTokenExpireOn] = React.useState<string>(date.toString());

  const onSubmit = (values) => {
    emitValuesToParent(values);
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: { expiresOn: '7', name: null } });
  return (
    <form className="" onSubmit={handleSubmit(onSubmit)} id="create-token-form">
      <div className="pb-10 max-h-70 sm:overflow-y-scroll lg:overflow-y-auto">
        {isError && <ErrorFromHook>Token name or expiration is invalid</ErrorFromHook>}
        <label className="mb-3 text-tw-black-5">Token name</label>
        <TextInput
          className="w-full py-3"
          {...register('name', {
            required: true,
          })}
          isError={errors.name}
        />
        <div className="flex flex-col mt-8 w-46">
          <label className="mb-3 text-tw-black-5">Expiration</label>
          <PrimaryDropdown
            options={expirationOptions}
            onChange={(value) => {
              setValue('expiresOn', value, { shouldValidate: true });
              if (parseInt(value)) {
                let dt = new Date();
                dt.setDate(dt.getDate() + parseInt(value));
                setTokenExpireOn(dt.toString());
              } else {
                setTokenExpireOn('');
              }
            }}
            value={getValues('expiresOn')}
          />
        </div>
        {tokenExpireOn && (
          <div className={'mt-2 flex'}>
            <InfoIcon />
            <p className={'font-normal text-sm text-tw-black-9 leading-4 ml-2'}>
              The token will expire on {convertDate(tokenExpireOn)}
            </p>
          </div>
        )}
      </div>
    </form>
  );
};

export const AddToken: React.FC<CreateTokenProps> = () => {
  const tokenMutation = useAddAPIToken();
  const { getAccountId } = useAuthStore();
  const router = useHistory();

  const { track } = useAnalytics();

  const getExpireOn = (val) => {
    if (parseInt(val)) {
      let dte = new Date();
      dte.setDate(dte.getDate() + parseInt(val));
      return dte.toISOString().split('T')[0];
    }
    return null;
  };
  const onAPITokenSubmit = (values) => {
    tokenMutation.mutate(
      {
        name: values.name,
        expires_on: getExpireOn(values.expiresOn),
      },
      {
        onSuccess: () => {
          const wait = _debounce(() => {
            router.push(`/${getAccountId()}${routes.apiSettings}`);
          }, 750);
          wait();
        },
      }
    );
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={(e) =>
        track(ErrorAnalyticsEvents.ERROR_OCCURED, {
          error: e.message,
        })
      }
    >
      <div className="grid content-start min-h-full py-8">
        <div className="relative w-1/2 py-4 mx-auto bg-white border rounded-lg border-tw-gray-eb h-80vh">
          <div className={'px-8 pb-8'}>
            <div className="flex items-center justify-between">
              <p className="mb-0 text-lg font-normal leading-6">Generating new token</p>
            </div>
            <hr className="my-4 border-b border-tw-gray-eb" />
            <p>Tokens you have generated that can be used to access the Toplyne API.</p>
          </div>
          <div className="px-8">
            <AddTokenForm emitValuesToParent={onAPITokenSubmit} isError={tokenMutation.isError} />
          </div>

          <div className="absolute w-full px-8 bg-white bottom-4">
            <hr className="border-b border-tw-gray-eb" />
            <div className="flex justify-end mt-5">
              <TertiaryButton
                type="button"
                className="mr-4"
                disabled={tokenMutation.isLoading}
                onClick={() => router.push(`/${getAccountId()}${routes.apiSettings}`)}
              >
                Cancel
              </TertiaryButton>
              <PrimaryButton
                type="submit"
                className="py-2"
                isLoading={tokenMutation.isLoading}
                disabled={tokenMutation.isLoading}
                loaderClass={'mx-tw-4.125'}
                form="create-token-form"
              >
                Generate new token
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
