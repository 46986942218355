import { useAuthStore } from 'hooks/useAuthStore';
import { useMutation } from 'react-query';
import { useClient } from '../../../api-client';
import useLoginSuccess from './useLoginSuccess';

type GoogleAuthData = {
  code: string;
  invitationToken?: string;
  hubspotCookie: string;
};

export default function useGoogleAuth() {
  const client = useClient();
  const success = useLoginSuccess();
  const { removeAccountId } = useAuthStore();

  return useMutation(
    (data: GoogleAuthData) =>
      client.googleAuth(data.code, data.hubspotCookie, data.invitationToken),
    {
      onMutate: () => {
        removeAccountId();
      },
      onSuccess: (data) => {
        success(data);
      },
    }
  );
}
