import { OpportunitySchedule } from 'pages/opportunities/types';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

export enum PQLWorkflowSteps {
  DEFINE = 'define',
  FILTERS = 'filters',
  EXPORT = 'export',
}

type PQLWorkflowState = {
  currentStep: PQLWorkflowSteps;
  createPQLModal: {
    open: boolean;
    expanded: boolean;
    payload: OpportunitySchedule | null;
    opportunityId: number;
    nextRun?: string;
  };
  expandedSidebar: boolean;
  completedSteps: { [key in PQLWorkflowSteps]: boolean };
};

type PQLWorkflowStore = {
  setCurrentStep: (step: PQLWorkflowSteps) => void;
  setCompletedSteps: (completedSteps: { [key in PQLWorkflowSteps]: boolean }) => void;
  setCreatePQLModal: ({
    open,
    expanded,
    payload,
    opportunityId,
    nextRun,
  }: {
    open: boolean;
    expanded: boolean;
    payload: OpportunitySchedule | null;
    opportunityId: number;
    nextRun?: string;
  }) => void;
  reset: () => void;
  setExpandedSidebar: (expanded: boolean) => void;
} & PQLWorkflowState;

const initialState: PQLWorkflowState = {
  currentStep: PQLWorkflowSteps.DEFINE,
  createPQLModal: {
    open: false,
    expanded: false,
    payload: null,
    opportunityId: null,
    nextRun: '',
  },
  completedSteps: {
    [PQLWorkflowSteps.DEFINE]: false,
    [PQLWorkflowSteps.FILTERS]: false,
    [PQLWorkflowSteps.EXPORT]: false,
  },
  expandedSidebar: false,
};

export const usePQLWorkflowStore = create<PQLWorkflowStore>(
  devtools((set) => {
    return {
      ...initialState,
      setCurrentStep: (step: PQLWorkflowSteps) => {
        set((state) => ({
          ...state,
          currentStep: step,
        }));
      },
      setCompletedSteps: (completedSteps: { [key in PQLWorkflowSteps]: boolean }) => {
        set((state) => ({
          ...state,
          completedSteps,
        }));
      },
      setCreatePQLModal: ({ open, expanded, payload, opportunityId, nextRun }) => {
        set((state) => ({
          ...state,
          createPQLModal: {
            open,
            expanded,
            payload,
            opportunityId,
            nextRun,
          },
        }));
      },
      setExpandedSidebar: (expandedSidebar: boolean) => {
        set((state) => ({
          ...state,
          expandedSidebar,
        }));
      },
      reset: () => {
        set(() => ({
          ...initialState,
        }));
      },
    };
  })
);
