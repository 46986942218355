import {
  StatsEntity,
  StatsModel,
  StatsPersona,
  Clear,
  TickPlain,
  PerfTrackEvent,
  SyncLimit,
} from '../../ui-components/data-display/Icons';
import { capitalizeFirstLetter, valuePlusCountNotation, getSourceImg } from '../../utils/common';
import { MODELID_LABEL_FOR_ACCOUNTS, MODELID_LABEL_FOR_USERS } from '../../utils/constants';
import { OpportunityResponse } from 'ui-components/business/opportunities/types';
import { ReactNode, useState, FC } from 'react';
import { HStack } from '@chakra-ui/react';
import { toast as toastify } from 'react-toastify';
import * as Popover from '@radix-ui/react-popover';
import MenuOptionsSearch from '../../ui-components/business/opportunities/MenuOptionsSearch';
import useUpdateOpportunity from './hooks/useUpdateOpportunity';
import { trim } from 'utils/helpers';
import { getProfileEvents, MoreItemsPopover } from './common/utils';
import {
  modelToPerformanceVerbMap,
  modelToQuestionMap,
} from '../pql-workflow/components/define-pql/SelectConversionEvent';
import useMeta from './hooks/useMeta';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';

type PQLMetadataProps = {
  opportunity: OpportunityResponse;
};

interface OptionProps {
  value: string;
  itemStyle?: string;
  label: string;
  Icon?: any;
  type?: any;
  disabled?: boolean;
  infoMessage?: string;
}

const Option: FC<OptionProps> = ({ Icon, label }) => {
  return (
    <div style={{ marginLeft: -15 }}>
      <HStack spacing="2">
        {Icon && <Icon />}

        <div>{label}</div>
      </HStack>
    </div>
  );
};

function getCapacityText(filter) {
  if (filter.leadStatus) {
    return filter.leadStatus
      .map((lead) => {
        return `${capitalizeFirstLetter(lead)} Users`;
      })
      .join(', ');
  }
  if (filter.limit) {
    return `${filter.limit.end} ${filter.limit?.by === 'percentage' ? '%' : 'Users'}`;
  }
  return '';
}

const CardContainer = (props) => {
  return (
    <div
      className="py-4 px-4 w-64 my-2 rounded-lg relative h-38 border-1 border-tw-gray-eb shrink-0"
      style={props.style}
    >
      {props.children}
    </div>
  );
};

const MetaText = (props) => {
  return <div className="text-gray-500 mt-4">{props.children}</div>;
};

const MetaValue = (props: { children: ReactNode }) => {
  return <div className="font-medium mt-2 whitespace-nowrap">{props.children}</div>;
};

const PopoverMenu = (props) => {
  const { opportunity } = props;
  const { data } = useMeta(
    opportunity.query?.type,
    opportunity.query?.modelId,
    opportunity.query?.goal
  );
  const [showMenu, setShowMenu] = useState(false);
  const sourceItems = getProfileEvents(data?.sourceMeta?.data);
  return (
    <Popover.Root open={showMenu}>
      <Popover.Anchor />
      <Popover.Trigger>
        <div
          className="text-blue-700 font-medium"
          onClick={() => {
            if (!showMenu) {
              setShowMenu(true);
            }
          }}
        >
          + Add
        </div>
      </Popover.Trigger>
      <Popover.Content
        avoidCollisions
        side="bottom"
        align="start"
        className="w-64 -ml-2"
        onEscapeKeyDown={() => setShowMenu(false)}
        onInteractOutside={() => setShowMenu(false)}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="px-3 py-2 border rounded-md bg-tw-white-ff shadow-3xl drop-shadow-3xl border-tw-gray-eb">
          <div className="py-2 text-tw-black-9">
            {modelToQuestionMap[opportunity?.query?.modelId]}
          </div>
          <MenuOptionsSearch
            value={''}
            parentWidth={'400'}
            onChange={(value: string) => {
              const source = sourceItems.find((sourceItem) => {
                return sourceItem.value === value;
              })?.source;
              props.onChange({ value, source });
            }}
            isMenuOpen={showMenu}
            closeMenu={() => {
              setShowMenu(false);
            }}
            render={(option) => <Option {...option} />}
            optionsArray={sourceItems}
          />
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};

export default function PQLMetadata(props: PQLMetadataProps) {
  const { opportunity } = props;

  const updateOpportunity = useUpdateOpportunity(opportunity.id);
  const [localTrackEvent, setLocalTrackEvent] = useState(null);

  const conversionEventChange = (value) => {
    updateOpportunity.mutate(
      {
        opportunityId: opportunity.id,
        query: { ...opportunity.query, performanceTrackEvent: value },
      },
      {
        onSuccess: () => {
          setLocalTrackEvent(null);
        },
        onError: () => {
          toastify(<ErrorToast />, {
            type: 'error',
          });
        },
      }
    );
  };

  return (
    <div className="flex flex-wrap items-start gap-x-4">
      <CardContainer>
        <StatsEntity className="w-14 h-14" />
        <MetaText>Targeting</MetaText>
        <MetaValue>{capitalizeFirstLetter(opportunity.query?.type)}</MetaValue>
      </CardContainer>
      <CardContainer>
        <StatsModel className="w-14 h-14" />
        <MetaText>Goal</MetaText>
        <MetaValue>
          {MODELID_LABEL_FOR_USERS[opportunity.query?.modelId] ||
            MODELID_LABEL_FOR_ACCOUNTS[opportunity.query?.modelId]}
        </MetaValue>
      </CardContainer>
      {!opportunity.query?.dsEnabled && (
        <CardContainer style={localTrackEvent ? { border: '1px solid #0D6EFD' } : {}}>
          <PerfTrackEvent className="w-14 h-14" />
          <MetaText>{modelToPerformanceVerbMap[opportunity.query?.modelId]}</MetaText>
          {!opportunity.query?.performanceTrackEvent?.value ? (
            !localTrackEvent ? (
              <div className="flex mt-2 justify-between add-track-popover">
                <div className="text-tw-black-9 italic">Not added yet</div>
                <PopoverMenu opportunity={opportunity} onChange={setLocalTrackEvent} />
              </div>
            ) : (
              <>
                <div className="flex gapx-x-2">
                  <img
                    alt={localTrackEvent.source}
                    className="mx-1 mt-3 w-4 h-4"
                    src={getSourceImg(localTrackEvent.source)}
                  />
                  <MetaValue>{trim(localTrackEvent.value, 24)}</MetaValue>
                </div>
                <div className="flex gap-x-2 mt-4">
                  <div
                    className="flex items-center gap-x-1 bg-tw-gray-f5 text-black font-medium px-4 h-8 rounded cursor-pointer"
                    onClick={() => {
                      setLocalTrackEvent(null);
                    }}
                  >
                    <Clear />
                    <div>Undo</div>
                  </div>
                  <div
                    style={{ background: '#F2F7FF' }}
                    className="flex items-center gap-x-1 bg-tw-gray-f5 text-black font-medium px-4 h-8 rounded cursor-pointer"
                    onClick={() => {
                      conversionEventChange(localTrackEvent);
                    }}
                  >
                    <TickPlain />
                    <div>Confirm</div>
                  </div>
                </div>
              </>
            )
          ) : (
            <div className="flex gapx-x-2">
              <img
                alt={opportunity.query?.performanceTrackEvent.source}
                className="mx-1 mt-3 w-4 h-4"
                src={getSourceImg(opportunity.query?.performanceTrackEvent.source)}
              />
              <MetaValue>{trim(opportunity.query?.performanceTrackEvent.value, 24)}</MetaValue>
            </div>
          )}
        </CardContainer>
      )}
      {opportunity?.cohorts?.length > 0 && (
        <CardContainer>
          <StatsPersona className="w-14 h-14" />
          <MetaText>Persona selected</MetaText>
          <MetaValue>
            {opportunity.cohorts?.length > 1 ? (
              <div className="flex items-center">
                <div className="flex items-center">
                  {opportunity?.cohorts
                    ?.map((c) => c.name)
                    .slice(0, 1)
                    .join(', ')}
                </div>

                <MoreItemsPopover items={opportunity.cohorts?.slice(1).map((c) => c.name)} />
              </div>
            ) : (
              <div>
                {valuePlusCountNotation(
                  opportunity.cohorts?.map((c) => c.name),
                  1
                )}
              </div>
            )}
          </MetaValue>
        </CardContainer>
      )}

      <CardContainer>
        <SyncLimit className="w-14 h-14" />
        <MetaText>Sync limit</MetaText>
        <MetaValue>{trim(getCapacityText(opportunity.exports[0].filter), 24)}</MetaValue>
      </CardContainer>
    </div>
  );
}
