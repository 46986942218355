import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

export const useServiceWorker = () => {
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);
  const [showReload, setShowReload] = useState<boolean>(false);
  const [navigate, setNavigate] = useState<boolean>(false);
  const history = useHistory();

  // called when a service worker
  // updates. this function is a callback
  // to the actual service worker
  // registration onUpdate.
  const onSWUpdate = useCallback(
    (registration: ServiceWorkerRegistration) => {
      if (!navigate) {
        setShowReload(true);
      }
      setWaitingWorker(registration.waiting);
    },
    [navigate]
  );

  // simply put, this tells the service
  // worker to skip the waiting phase and then reloads the page
  const reloadPage = useCallback(() => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload();
  }, [waitingWorker]);

  // update sw on navigation
  // from one route to the other
  useEffect(() => {
    const unlisten = history?.listen(async () => {
      setNavigate(true);
      await navigator.serviceWorker
        ?.getRegistrations()
        .then((regs) => regs?.forEach((reg) => reg?.update()));
      setNavigate(false);
    });

    return () => {
      // remove history listener
      // on component unmount
      unlisten();
    };
    // do not create new listeners by adding variables to
    // the dependency array.
  }, []);
  // if user is navigating and there
  // exists a waiting worker immediately
  // reload the page
  useEffect(() => {
    if (navigate && waitingWorker) {
      reloadPage();
    }
  }, [navigate, waitingWorker, reloadPage]);

  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate,
    });
  }, [onSWUpdate]);

  return { showReload, waitingWorker, reloadPage };
};
