import Card from 'ui-components/surfaces/cards/Card';
import { ErrorBoundary } from 'react-error-boundary';
import { useAuthStore } from 'hooks/useAuthStore';
import useEntityDetails from 'pages/users-and-accounts/accounts/hooks/useAccountDetails';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { QueryType } from 'stores/QueryStore';
import { useApiClient } from '../../../../api-client';
import Attribute from '../../../../ui-components/business/attributes/Attribute';
import {
  ATTRIBUTE_CONST,
  checkDsEnabled,
  processAttributeDetails,
  processToplyneStats,
  setDefaultAttributes,
} from '../../../../ui-components/business/attributes/Attributes';
import useAttributePreferences from '../../accounts/hooks/useAttributePreferences';
import useModels from '../../../../hooks/useModels';
import ToplyneStats from '../../accounts/toplyne-shap/ToplyneStats';
import useEntitiesQuery from '../../../../hooks/useEntitiesQuery';
import { Entity } from '../types';
import HeroContent from './HeroContent';
import { ErrorAnalyticsEvents } from 'telemetry/constants';
import ErrorBoundaryFallback from 'ui-components/feedback/ErrorBoundaryFallback';
import { useAnalytics } from 'telemetry';

interface UserDetailsProps {
  match?: any;
}

const UserDetails: React.FC<UserDetailsProps> = () => {
  const { getAccountId } = useAuthStore();
  const location = useLocation();
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const userId = decodeURIComponent(
    location.pathname.replace(`/${getAccountId()}/users/`, '').replaceAll('/', '\\')
  );

  const {
    data: userDetailsData,
    isLoading: isUserDetailsLoading,
    isError: isUserDetailsError,
  } = useEntityDetails(Entity.users, userId);
  const {
    data: userAttributePreferences,
    isLoading: isUserAttributePreferenceLoading,
    isError: isUserAttributePreferenceError,
  } = useAttributePreferences('entity=users');
  const {
    data: toplyneMetaData,
    isLoading: isToplyneMetaDataLoading,
    isError: isToplyneMetaDataError,
  } = useEntitiesQuery(Entity.users);

  const { data: modelData } = useModels();

  const dsEnabled = checkDsEnabled(modelData);

  const { track } = useAnalytics();

  const [userAttributePreferencesBase, setUserAttributePreferencesBase] = React.useState({});
  const [tempUserAttributePreferencesBase, setTempUserAttributePreferencesBase] = React.useState(
    {}
  );
  const [attributeData, setAttributeData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [billingHeroData, setBillingHeroData] = React.useState({
    billing_subscription_total: null,
    billing_next_charge_date: null,
  });
  const [heroContentData, setHeroContentData] = React.useState({
    user_id: '',
    country: '',
    user_creation_time: '',
    USER_PROPERTY_email: '',
    billing_first_charge_date: '',
    sub_date: '',
    USER_PROPERTY_Plan: '',
    USER_PROPERTY_PlanDuration: '',
    is_paid: false,
    USER_PROPERTY_first_name: '',
    USER_PROPERTY_last_name: '',
    billing_amount: 0,
    source: '',
  });
  const [toplyneStatsData, setToplyneStatsData] = React.useState({
    expansion: { name: 'Expansion Score', value: 0, key: 'expansion_score' },
    conversion: { name: 'Conversion Score', value: 0, key: 'conversion_score' },
    engagement: { name: 'Engagement Score', value: 0, key: 'engagement_score' },
  });

  React.useEffect(() => {
    if (
      !isUserDetailsLoading &&
      !isUserAttributePreferenceLoading &&
      !isToplyneMetaDataLoading &&
      !isUserDetailsError &&
      !isUserAttributePreferenceError &&
      !isToplyneMetaDataError
    ) {
      let displayAttribute;
      if (userAttributePreferences.length === 0) {
        displayAttribute = { profileEvents: [], billing: [], profileProperties: [] };
      } else {
        displayAttribute = userAttributePreferences[0]['displayAttribute'];
      }
      setUserAttributePreferencesBase(displayAttribute);

      let {
        _profilePropertiesData,
        _billingData,
        _productEventData,
        profilePropertiesShowCount,
        billingPropertyShowCount,
        productEventsPropertyShowCount,
        _heroContentData,
        _billingHeroData,
      } = processAttributeDetails(
        userDetailsData['userDetails'],
        displayAttribute,
        toplyneMetaData,
        heroContentData,
        billingHeroData
      );

      if (dsEnabled) {
        setToplyneStatsData(processToplyneStats(userDetailsData['userDetails'], toplyneStatsData));
      }

      setAttributeData({
        [ATTRIBUTE_CONST.PRODUCT_EVENTS]: _productEventData,
        [ATTRIBUTE_CONST.BILLING]: _billingData,
        [ATTRIBUTE_CONST.PROFILE_PROPERTIES]: _profilePropertiesData,
      });
      setHeroContentData(_heroContentData);
      setBillingHeroData(_billingHeroData);
      setUserAttributePreferencesBase(displayAttribute);
      let { productEventData, billingData, profilePropertiesData, temporarySelections } =
        setDefaultAttributes(
          _profilePropertiesData,
          _billingData,
          _productEventData,
          profilePropertiesShowCount,
          billingPropertyShowCount,
          productEventsPropertyShowCount,
          displayAttribute
        );
      setTempUserAttributePreferencesBase(temporarySelections);
      setAttributeData({
        [ATTRIBUTE_CONST.PRODUCT_EVENTS]: productEventData,
        [ATTRIBUTE_CONST.BILLING]: billingData,
        [ATTRIBUTE_CONST.PROFILE_PROPERTIES]: profilePropertiesData,
      });
      setIsLoading(false);
    }
  }, [isUserDetailsLoading, isUserAttributePreferenceLoading, isToplyneMetaDataLoading]);

  const updateAttributePreferences = async (preferences, tempPreferences, updateDB) => {
    setTempUserAttributePreferencesBase(tempPreferences);
    if (updateDB) {
      const { data } = await apiClient.post(`data/user-attributes/`, {
        display_attribute: preferences,
        entity: 'users',
      });
      await queryClient.invalidateQueries('displayAttributes');
      let {
        _profilePropertiesData,
        _billingData,
        _productEventData,
        profilePropertiesShowCount,
        billingPropertyShowCount,
        productEventsPropertyShowCount,
        _heroContentData,
        _billingHeroData,
      } = processAttributeDetails(
        userDetailsData['userDetails'],
        data['displayAttribute'],
        toplyneMetaData,
        heroContentData,
        billingHeroData
      );

      if (dsEnabled) {
        setToplyneStatsData(processToplyneStats(userDetailsData['userDetails'], toplyneStatsData));
      }

      setAttributeData({
        [ATTRIBUTE_CONST.PRODUCT_EVENTS]: _productEventData,
        [ATTRIBUTE_CONST.BILLING]: _billingData,
        [ATTRIBUTE_CONST.PROFILE_PROPERTIES]: _profilePropertiesData,
      });
      setHeroContentData(_heroContentData);
      setBillingHeroData(_billingHeroData);
      setUserAttributePreferencesBase(data['displayAttribute']);
      let { productEventData, billingData, profilePropertiesData, temporarySelections } =
        setDefaultAttributes(
          _profilePropertiesData,
          _billingData,
          _productEventData,
          profilePropertiesShowCount,
          billingPropertyShowCount,
          productEventsPropertyShowCount,
          data['displayAttribute']
        );
      setTempUserAttributePreferencesBase(temporarySelections);
      setAttributeData({
        [ATTRIBUTE_CONST.PRODUCT_EVENTS]: productEventData,
        [ATTRIBUTE_CONST.BILLING]: billingData,
        [ATTRIBUTE_CONST.PROFILE_PROPERTIES]: profilePropertiesData,
      });
    } else {
      let {
        _profilePropertiesData,
        _billingData,
        _productEventData,
        _heroContentData,
        _billingHeroData,
      } = processAttributeDetails(
        userDetailsData['userDetails'],
        preferences,
        toplyneMetaData,
        heroContentData,
        billingHeroData
      );

      if (dsEnabled) {
        setToplyneStatsData(processToplyneStats(userDetailsData['userDetails'], toplyneStatsData));
      }

      setAttributeData({
        [ATTRIBUTE_CONST.PRODUCT_EVENTS]: _productEventData,
        [ATTRIBUTE_CONST.BILLING]: _billingData,
        [ATTRIBUTE_CONST.PROFILE_PROPERTIES]: _profilePropertiesData,
      });
      setHeroContentData(_heroContentData);
      setBillingHeroData(_billingHeroData);
      setUserAttributePreferencesBase(preferences);
    }
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={(e) =>
        track(ErrorAnalyticsEvents.ERROR_OCCURED, {
          error: e.message,
        })
      }
    >
      <div className="grid grid-flow-col grid-cols-10 gap-4 px-4 mt-8 h-5/6">
        <div className={dsEnabled ? `col-span-7 ml-4` : `col-start-2 col-span-8`}>
          {isLoading ? (
            <Card
              bodyClass={''}
              className={'mb-4 rounded'}
              hover={false}
              style={{ border: 'none' }}
            >
              <div className="flex space-x-4 animate-pulse">
                <div className="flex-1 py-1 space-y-4">
                  <div className="h-12 bg-gray-100 rounded" />
                  <div className="grid grid-cols-2 gap-4 space-y-2">
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                  </div>
                </div>
              </div>
            </Card>
          ) : (
            <HeroContent
              isPaid={heroContentData.is_paid}
              planInterval={heroContentData.USER_PROPERTY_PlanDuration}
              planName={heroContentData.USER_PROPERTY_Plan}
              email={heroContentData.USER_PROPERTY_email}
              firstPaidPlanPurchasedAt={heroContentData.billing_first_charge_date}
              country={heroContentData.country}
              createdAt={heroContentData.user_creation_time}
              userId={heroContentData.user_id}
              firstName={heroContentData.USER_PROPERTY_first_name}
              lastName={heroContentData.USER_PROPERTY_last_name}
              billingAmount={heroContentData.billing_amount}
              source={heroContentData.source}
              subscriptionDate={heroContentData.sub_date}
            />
          )}
          {isLoading ? (
            <Card
              bodyClass={''}
              className={'mb-4 rounded'}
              hover={false}
              style={{ border: 'none' }}
            >
              <div className="flex space-x-4 animate-pulse">
                <div className="flex-1 py-1 space-y-4">
                  <div className="flex justify-between h-12">
                    <div className="w-1/6 h-4 col-span-1 bg-gray-100 rounded" />
                    <span className={'bg-gray-100 mr-4 px-16 py-2 rounded w-8'} />
                  </div>
                  <div className="h-12 rounded">
                    <span className={'bg-gray-100 h-4 mr-4 px-12 py-2 rounded w-8'} />
                    <span className={'bg-gray-100 h-4 mr-4 px-12 py-2 rounded w-8'} />
                    <span className={'bg-gray-100 h-4 mr-4 px-12 py-2 rounded w-8'} />
                  </div>
                  <div className="grid grid-cols-2 gap-4 space-y-2">
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                    <div className="w-4/5 h-4 col-span-1 bg-gray-100 rounded" />
                  </div>
                </div>
              </div>
            </Card>
          ) : (
            <Attribute
              productEventsData={attributeData[ATTRIBUTE_CONST.PRODUCT_EVENTS]}
              billingData={attributeData[ATTRIBUTE_CONST.BILLING]}
              profilePropertiesData={attributeData[ATTRIBUTE_CONST.PROFILE_PROPERTIES]}
              userAttributePreferences={userAttributePreferencesBase}
              updateParentAttributePreferences={updateAttributePreferences}
              billingHeroData={billingHeroData}
              billingLedger={userDetailsData['billingLedger']}
              ATTRIBUTE_CONST={ATTRIBUTE_CONST}
              temporaryUserAttributePreferences={tempUserAttributePreferencesBase}
            />
          )}
        </div>
        {dsEnabled && (
          <div className="col-span-3 row-span-2">
            <ToplyneStats
              scores={toplyneStatsData}
              queryType={QueryType.users}
              id={userId}
              entity={Entity.users}
            />
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default UserDetails;
