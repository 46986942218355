import { useClient } from 'api-client';
import dayjs from 'dayjs';
import { useAuthStore } from 'hooks/useAuthStore';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { routes } from 'routing/clientRoutes';

export default function useSetupSandbox() {
  const client = useClient();
  const history = useHistory();
  const { getAccountSchemaName } = useAuthStore();
  return useMutation(
    (databaseName: string) =>
      client.setupSandbox(getAccountSchemaName(), databaseName, dayjs.tz.guess()),
    {
      onMutate: () => {
        history.replace(routes.setupOrganization);
      },
    }
  );
}
