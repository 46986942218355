import { useMutation, useQueryClient } from 'react-query';
import { toast as toastify } from 'react-toastify';
import { TeamMemberEntity, useClient } from '../../../api-client';
import { AnalyticsConst, AnalyticsEvents } from '../../../telemetry/constants';
import { useAnalytics } from '../../../telemetry';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';

interface memberDeleteData {
  id: number | string;
  email: string;
}

export default function useDeleteMember() {
  const queryClient = useQueryClient();
  const client = useClient();
  const { track } = useAnalytics();
  return useMutation((data: memberDeleteData) => client.deleteMember(data.id), {
    onSuccess: (resp, data) => {
      toastify(<SuccessToast description="Member deleted" />, {
        type: 'success',
      });
      queryClient.setQueryData('acceptedTeamMembers', (userDetails: TeamMemberEntity[]) => {
        return userDetails.filter((userDetail) => userDetail.id !== data.id);
      });
      track(AnalyticsEvents.TEAM_MEMBER_DELETED, {
        [AnalyticsConst.EMAIL]: data.email,
      });
    },
    onError: (error, data) => {
      const err = error['response']['data']['detail'];
      toastify(<ErrorToast description={err ?? 'There was an error deleting the member'} />, {
        type: 'error',
      });
      track(AnalyticsEvents.TEAM_MEMBER_DELETED_FAILED, {
        [AnalyticsConst.EMAIL]: data.email,
      });
    },
    onSettled: async () => {
      await queryClient.refetchQueries('acceptedTeamMembers');
    },
  });
}
