import React from 'react';
import ToastIcons from './ToastIcons';

export default function SuccessToast({ description }: { description: string }) {
  return (
    <div className="flex items-center">
      <ToastIcons type="success" />
      <div className="ml-1">
        <div className="font-semibold text-md text-tw-black-1">{description}</div>
      </div>
    </div>
  );
}
