import { MetaItem } from 'api-client';
import { DropdownTriangleFilled } from 'ui-components/data-display/Icons';
import PopupFilter from 'ui-components/business/PopupFilter';
import ProgressBar from 'ui-components/feedback/ProgressBar';
import { classNames, getSourceImg, removePredictorText } from 'utils/common';
import { PopupFilterPage } from 'utils/globalTypes';
import { getItemSuffix } from '../../utils';
import { BarChartOp } from 'pages/opportunities/barChartOp';
import useDsEnabled from 'hooks/useDsEnabled';
import { useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { useFiltersPQLStore } from 'pages/pql-workflow/store/PQLFiltersStore';
import { useCallback, useState } from 'react';
import { useAnalytics } from 'telemetry';
import { AnalyticsEvents, AnalyticsConst } from 'telemetry/constants';
import { trim } from 'utils/helpers';

export default function FilterRow({ filter }: { filter: MetaItem }) {
  const { filtersListOpen, setFiltersListOpen, filters, setFilters } = useFiltersPQLStore();
  const { modelId, targetEntity } = useDefinePQLStore();

  const dsEnabled = useDsEnabled(modelId, targetEntity);
  const [hasMouseEntered, setHasMouseEntered] = useState(false);
  const [hasClicked, setHasClicked] = useState(false);
  const { track } = useAnalytics();

  const isApplied = filters.find((f) => f.columnName === filter.COLUMN_NAME);

  const onApplyPopupFilter = useCallback(
    (currentComparator, filterToBeApplied) => {
      const { KEYNAME, KEYSPACE, SOURCE, COLUMN_NAME, DISPLAY_NAME } = filter;
      const newFilters = filters?.filter((prevFilter) => {
        return prevFilter.columnName !== COLUMN_NAME;
      });
      // Set filters in new PQL store
      setFilters([
        ...newFilters,
        {
          keyName: KEYNAME,
          keySpace: KEYSPACE,
          source: SOURCE,
          op: currentComparator,
          value: filterToBeApplied,
          displayName: DISPLAY_NAME,
          columnName: COLUMN_NAME,
        },
      ]);

      track(AnalyticsEvents.OPPORTUNITY_CREATE_FILTER, {
        [AnalyticsConst.ATTRIBUTE_NAME]: KEYNAME,
        [AnalyticsConst.OPERATOR]: currentComparator,
        [AnalyticsConst.VALUE]: filterToBeApplied,
      });
    },
    [filter, filters, setFilters, track]
  );

  return (
    <div>
      <div
        className={classNames(
          'relative border rounded-lg  border-tw-gray-eb',
          dsEnabled && 'cursor-pointer'
        )}
      >
        <div
          className={classNames(
            'relative grid items-center py-4 pl-4 pr-5 group gap-x-4',
            dsEnabled ? 'grid-cols-7' : 'grid-cols-8'
          )}
          onMouseEnter={() => setHasMouseEntered(true)}
          onMouseLeave={() => setHasMouseEntered(false)}
          onClick={() => {
            if (dsEnabled) {
              if (filtersListOpen.includes(filter.COLUMN_NAME)) {
                setFiltersListOpen(filtersListOpen.filter((f) => f !== filter.COLUMN_NAME));
              } else {
                setFiltersListOpen([...filtersListOpen, filter.COLUMN_NAME]);
              }
            }
          }}
        >
          {dsEnabled && (
            <div className="absolute right-4">
              {filtersListOpen.includes(filter.COLUMN_NAME) ? (
                <DropdownTriangleFilled className="transform rotate-180 fill-current text-tw-black-7" />
              ) : (
                <DropdownTriangleFilled className="fill-current text-tw-black-7" />
              )}
            </div>
          )}
          <div
            className={classNames(
              'flex items-center overflow-hidden gap-x-2',
              dsEnabled ? 'col-span-3' : 'col-span-5 py-1'
            )}
          >
            {getSourceImg(filter.SOURCE) ? (
              <img alt={filter.SOURCE} className="mx-1 w-7 h-7" src={getSourceImg(filter.SOURCE)} />
            ) : (
              <div className="grid w-6 h-6 mr-2 text-center rounded-full place-items-center bg-tw-blue-f2">
                {filter.SOURCE[0]}
              </div>
            )}
            <div>
              {dsEnabled ? trim(filter.DISPLAY_NAME, 20) : trim(filter.DISPLAY_NAME, 30)}
              {dsEnabled && (
                <p className="text-xs text-tw-black-7">
                  {getItemSuffix(filter.KEYSPACE) === 'event' ? 'Usage Event' : 'Profile Property'}
                </p>
              )}
            </div>
          </div>
          {dsEnabled && (
            <div className="col-span-2">
              <ProgressBar
                weight={filter.FEATURE_SCORE}
                toolTip={removePredictorText(filter.CORRELATION)}
              />
            </div>
          )}
          <div
            className={classNames(dsEnabled ? 'col-span-2' : 'col-span-3', !dsEnabled && 'ml-8')}
          >
            {(isApplied || hasClicked || hasMouseEntered) && (
              <PopupFilter
                onClick={() => setHasClicked(true)}
                onClose={() => {
                  setHasClicked(false);
                  setHasMouseEntered(false);
                }}
                row={{ original: filter }}
                old={false}
                onApply={(currentComparator, filterToBeApplied) =>
                  onApplyPopupFilter(currentComparator, filterToBeApplied)
                }
                page={PopupFilterPage.PQLWORKFLOW}
              />
            )}
          </div>
        </div>
        {filtersListOpen.includes(filter.COLUMN_NAME) && (
          <div className="px-5">
            <BarChartOp row={{ original: filter }} old={false} />
          </div>
        )}
      </div>
    </div>
  );
}
