import { useAuth } from 'hooks/useAuth';
import { AnimatePing } from 'pages/opportunities/Opportunities';
import qs from 'query-string';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorAnalyticsEvents } from 'telemetry/constants';
import { DuplicateDoubleRound } from 'ui-components/data-display/Icons';
import ErrorBoundaryFallback from 'ui-components/feedback/ErrorBoundaryFallback';
import { classNames } from 'utils/common';
import { Status } from '../../hooks/use-async';
import CreatePQLModal from './components/CreatePQLModal';
import Define from './components/define-pql/Define';
import Export from './components/export/Export';
import Filters from './components/filters';
import SectionSidebar from './components/sidebar';
import useConnectedIntegrationEntities from './hooks/useConnectedIntegrationEntities';
import useConnectedIntegrationEntityDetail from './hooks/useConnectedIntegrationEntityDetail';
import useMeta from './hooks/useMeta';
import useOpportunity, { useCheckOpportunityCanBeDuplicated } from './hooks/useOpportunity';
import usePrefetchQueries from './hooks/usePrefetchQueries';
import { PQLWorkflowSteps, usePQLWorkflowStore } from './store/PQLWorkflowStore';
import { useAnalytics } from 'telemetry';

export function usePQLWorkflowRoute() {
  const router = useHistory();
  const params: { id: string } = useParams();
  return {
    isDuplicate: router.location.pathname.includes('/duplicate'),
    isEditExports: router.location.pathname.includes('/edit-exports'),
    opportunityId: params.id,
  };
}

export default function PQLWorkflow() {
  const { completedSteps } = usePQLWorkflowStore();
  const defineRef = useRef<HTMLDivElement>();
  const filtersRef = useRef<HTMLDivElement>();
  const exportRef = useRef<HTMLDivElement>();

  // prefetch queries
  usePrefetchQueries();
  const { user } = useAuth();
  const router = useHistory();

  const { isDuplicate, opportunityId } = usePQLWorkflowRoute();
  const [pqlRestoreCheckStatus, setPQLRestoreCheckStatus] = useState(
    isDuplicate ? Status.PENDING : Status.SUCCESS
  );
  const { isLoading: opportunityIsLoading, data: opportunity } = useOpportunity(
    opportunityId,
    isDuplicate
  );
  const { data: meta } = useMeta();
  const { check } = useCheckOpportunityCanBeDuplicated(opportunity, meta);

  const { track } = useAnalytics();

  useEffect(() => {
    if (isDuplicate) {
      const canPQlBeCloned = check();
      setPQLRestoreCheckStatus(canPQlBeCloned);
    }
  }, [opportunity, meta, check]);

  // Fetching data
  useConnectedIntegrationEntities();
  useConnectedIntegrationEntityDetail();

  // adds isSandbox parameter to the URL
  useEffect(() => {
    let urlParams = {};
    if (user?.currentOrganization?.isSandbox) {
      urlParams['isSandbox'] = true;
    } else urlParams['isSandbox'] = false;

    router.replace({
      search: qs.stringify(urlParams),
    });
  }, [router, user?.currentOrganization?.isSandbox]);

  // manages scroll
  useEffect(() => {
    if (!isDuplicate) {
      if (!completedSteps[PQLWorkflowSteps.DEFINE]) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      } else {
        if (completedSteps[PQLWorkflowSteps.DEFINE]) {
          filtersRef?.current?.scrollIntoView({
            behavior: 'smooth',
          });
        } else if (completedSteps[PQLWorkflowSteps.FILTERS]) {
          exportRef?.current?.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }
    }
  }, [completedSteps, isDuplicate]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={(e) =>
        track(ErrorAnalyticsEvents.ERROR_OCCURED, {
          error: e.message,
        })
      }
    >
      {isDuplicate && (
        <div
          className={classNames(
            'flex items-center w-full px-32 bg-white shadow gap-x-4 z-5 h-26',
            user?.currentOrganization.isSandbox && '-mt-4'
          )}
        >
          <DuplicateDoubleRound />
          {pqlRestoreCheckStatus === Status.PENDING || opportunityIsLoading ? (
            <div className="animate-pulse">
              <div className="h-5 w-80 bg-tw-gray-eb "></div>
              <div className="h-4 mt-2 w-96 bg-tw-gray-eb "></div>
            </div>
          ) : (
            <div>
              <h3 className="flex items-center text-base font-medium gap-x-2">
                This is a duplicate of the playbook <span>“{opportunity?.name}”</span>
              </h3>
              <span className="mt-2 text-tw-black-7">
                You may make changes to this playbook and save it as a new one
              </span>
            </div>
          )}
        </div>
      )}
      {(pqlRestoreCheckStatus === Status.PENDING || opportunityIsLoading) && (
        <div className="grid grid-cols-10 px-20 mt-8 gap-x-5 auto-rows-min">
          <div className="flex flex-col w-full col-span-7 mx-auto gap-y-6">
            <div className="flex p-6 bg-white border gap-x-5 rounded-xl border-tw-gray-eb animate-pulse">
              <span className="w-6 h-6 rounded-full bg-tw-gray-eb"></span>
              <div className="flex flex-col w-full gap-y-2">
                <div className="w-1/4 h-6 bg-tw-gray-eb"></div>
                <div className="w-2/3 h-4 bg-tw-gray-eb"></div>
              </div>
            </div>
            <div className="flex p-6 bg-white border border-tw-gray-eb gap-x-5 rounded-xl animate-pulse">
              <span className="w-6 h-6 rounded-full bg-tw-gray-eb"></span>
              <div className="flex flex-col w-full gap-y-2">
                <div className="w-1/4 h-6 bg-tw-gray-eb"></div>
                <div className="w-2/3 h-4 bg-tw-gray-eb"></div>
              </div>
            </div>
            <div className="flex p-6 bg-white border gap-x-5 rounded-xl border-tw-gray-eb animate-pulse">
              <span className="w-6 h-6 rounded-full bg-tw-gray-eb"></span>
              <div className="flex flex-col w-full gap-y-2">
                <div className="w-1/4 h-6 bg-tw-gray-eb"></div>
                <div className="w-2/3 h-4 bg-tw-gray-eb"></div>
              </div>
            </div>
          </div>
          <div className="h-32 col-span-3 px-6 pt-8 pb-6 bg-no-repeat bg-cover bg-tw-blue-f2 rounded-xl bg-pql-rhs">
            <h4 className="text-xs text-tw-black-9">YOU HAVE QUALIFIED</h4>
            <div className="flex items-center mt-2 gap-x-4">
              <h3 className="text-2xl font-medium text-tw-black-3">0</h3>
              <AnimatePing className="bg-tw-blue-0d" />
            </div>
          </div>
        </div>
      )}
      <CreatePQLModal />
      {pqlRestoreCheckStatus === Status.SUCCESS && !opportunityIsLoading && (
        <div
          className={classNames(
            'grid w-full grid-cols-10 px-20 gap-x-5',
            isDuplicate ? 'mt-8' : 'mt-4'
          )}
        >
          <div className={classNames('col-span-7')}>
            <Define ref={defineRef} />
            <div className="my-4">
              <Filters ref={filtersRef} />
            </div>
            <div className="my-4">
              <Export ref={exportRef} />
            </div>
          </div>
          <div className="col-span-3">
            <SectionSidebar />
          </div>
        </div>
      )}
    </ErrorBoundary>
  );
}
