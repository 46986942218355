import { AxiosInstance } from 'axios';
import { formatColumnName } from 'utils/common';
import useModels from 'hooks/useModels';
import { useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { useQuery } from 'react-query';
import { useApiClient } from '../../../api-client';

export const goalToVerbMap = {
  conversion: 'converted',
  expansion: 'expanded',
  engagement: 'engaged',
  churn_prevention: 'churned',
};

type HistogramFilterParams = {
  columnName: string;
  type: string;
  shouldFetchHistogram?: boolean | null;
};

async function getHistogramFilter(
  apiClient: AxiosInstance,
  params: HistogramFilterParams,
  goal: string
) {
  const { columnName, type } = params;
  const { data } = await apiClient.get(`data/histogram/filters/${type}/${columnName}/`);

  return {
    filters: data.filters.map((item) => {
      if (params?.columnName?.includes('lead_status') && item === '-1') {
        return goalToVerbMap[goal];
      }
      return item;
    }),
  };
}

export default function useHistogramFilter(params: HistogramFilterParams) {
  const client = useApiClient();
  const { data: models } = useModels();
  const { modelId } = useDefinePQLStore();
  return useQuery(
    ['histogramFilter', formatColumnName(params?.columnName), params?.type],
    () =>
      getHistogramFilter(
        client,
        {
          ...params,
          columnName: formatColumnName(params?.columnName),
        },
        models?.find((m) => m.modelId === modelId)?.goal
      ),
    {
      enabled: params.shouldFetchHistogram ?? false,
    }
  );
}
