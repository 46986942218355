import { useClient } from 'api-client';
import { useQuery } from 'react-query';

export type CohortsResponse = {
  description: string;
  filters: {
    columnName: string;
    keyName: string;
    keySpace: string;
    op: string;
    source: string;
    value: string[];
  }[];
  id: number;
  isSystem: boolean;
  name: string;
};

export default function useCohorts() {
  const client = useClient();
  return useQuery<CohortsResponse[]>(['cohorts'], () => client.getCohorts());
}
