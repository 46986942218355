export const AUTH_TOKEN = 'AUTH_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const ACCOUNT_ID = 'ACCOUNT_ID';
export const ACCOUNT_SCHEMANAME = 'ACCOUNT_SCHEMANAME';
export const EMAIL_ID = 'EMAIL_ID';

export const BACKSPACE = 'Backspace';
export const ENTER = 'Enter';
export const TAB = 'Tab';

export const INPUT_TAGS_MAX_COUNT = 25;

export enum PopupFilterPage {
  USERACCOUNTS = 'useraccounts',
  PQLWORKFLOW = 'pqlworkflow',
}

export const avatars = [
  {
    url: 'https://assets.toplyne.io/avatars/lady_avatar1.png',
    name: 'lady_avatar1',
  },
  {
    url: 'https://assets.toplyne.io/avatars/lady_avatar2.png',
    name: 'lady_avatar2',
  },
  {
    url: 'https://assets.toplyne.io/avatars/lady_avatar3.png',
    name: 'lady_avatar3',
  },
  {
    url: 'https://assets.toplyne.io/avatars/man_avatar1.png',
    name: 'man_avatar1',
  },
  {
    url: 'https://assets.toplyne.io/avatars/man_avatar2.png',
    name: 'man_avatar2',
  },
  {
    url: 'https://assets.toplyne.io/avatars/man_avatar3.png',
    name: 'man_avatar3',
  },
];

export const commonComparatorMap = {
  eq: 'Equals to',
  ne: 'Does not equal',
  notnull: 'Exists',
  null: 'Does not exist',
};

export const stringComparatorMap = {
  contains: 'Contains',
  doesnotcontain: 'Does not contain',
  ...commonComparatorMap,
};

export const dateComparatorMap = {
  inthelast: 'In the last',
  during: 'During',
};

export const boolComparatorMap = {
  ...commonComparatorMap,
};

export const intComparatorMap = {
  gt: 'Greater than',
  lt: 'Less than',
  gte: 'Greater than equals',
  lte: 'Less than equals',
  between: 'Between',
  ...commonComparatorMap,
};

export const comparatorMap = {
  ...commonComparatorMap,
  ...dateComparatorMap,
  ...intComparatorMap,
};

export const allComparatorsMap = {
  ...commonComparatorMap,
  ...stringComparatorMap,
  ...dateComparatorMap,
  ...intComparatorMap,
  ...boolComparatorMap,
};

export const comparatorSymbolMap = {
  eq: '=',
  ne: '!=',
  lt: '<',
  gt: '>',
  lte: '<=',
  gte: '>=',
  notnull: 'Exists',
  null: 'Does not exist',
  between: 'Between',
  contains: 'Contains',
  doesnotcontain: 'Does not contain',
  inthelast: 'In the last',
  during: 'During',
};

export const redirectPath = `/`;
