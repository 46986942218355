import { useClient } from 'api-client';
import { useQuery } from 'react-query';
import { useExportPQLStore } from '../store/PQLExportStore';

export default function useConnectedIntegrationEntityDetail() {
  const client = useClient();
  const { destinationId, object } = useExportPQLStore();
  return useQuery(
    ['connectedintegration', 'pql', object, destinationId],
    () => client.connectedIntegrationDetail(destinationId, object),
    {
      enabled: !!destinationId && !!object,
    }
  );
}
