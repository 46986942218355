import Modal, { ModalWidth } from 'ui-components/util/modal';
import { SecondaryButton, TertiaryButton } from 'ui-components/inputs/Buttons';
import * as React from 'react';
import IntegrationIcon from './IntegrationIcon';
import { IntegrationType } from '../../../api-client';
import { useAuth } from '../../../hooks/useAuth';
import useSaveIntegrationRequest from '../hooks/useSaveIntegrationRequest';
import { RefObject } from 'react';

interface RequestIntegrationModalProps {
  id: number;
  name: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  type?: IntegrationType;
  logoUrl?: string;
  scrollCallback?: any;
  ref?: RefObject<HTMLDivElement>;
  requestedAppRef?: any;
}

const RequestIntegrationModal: React.FC<RequestIntegrationModalProps> = (props) => {
  const { id, name, showModal, setShowModal, type, logoUrl, scrollCallback } = props;
  const { user: userData } = useAuth();
  const requestAnIntegration = useSaveIntegrationRequest();

  const requestIntegration = (data) => {
    requestAnIntegration.mutate(data, {
      onSettled: () => {
        setShowModal(false);
        scrollCallback();
      },
    });
  };

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      width={ModalWidth.md}
      title={'Get more apps'}
    >
      <div className="mt-3 p-4">
        <div className="flex place-items-center p-4 h-18 bg-white rounded-lg border-1 border-tw-gray-eb w-2/5 gap-x-3">
          <IntegrationIcon type={type} logoUrl={logoUrl} height={40} width={40} />
          {name}
        </div>
        <div className="mt-4 text-tw-black-5 font-normal text-sm">
          It typically takes 7-10 days to enable the {name} integration on your account. Someone
          from our team will reach out to you shortly.
        </div>
        <div className="flex justify-end px-2 mt-6 gap-x-2">
          <TertiaryButton
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </TertiaryButton>
          <SecondaryButton
            className="min-w-[200px] text-tw-white-ff"
            bgClassName="bg-tw-blue-0d"
            borderClassName="border-tw-blue-0d"
            isLoading={requestAnIntegration.isLoading}
            onClick={() => {
              let data = {
                id: id,
                name: name,
                data: {
                  email: userData?.email,
                  organization: userData?.currentOrganization?.name,
                },
              };
              requestIntegration(data);
            }}
          >
            Request this app
          </SecondaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default RequestIntegrationModal;
