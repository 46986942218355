import { useCallback, useEffect, useState } from 'react';
import { Optional } from '../utils';

type AsyncFunction<T> = () => Promise<T>;

export enum Status {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

export default function useAsync<T>(asyncFunction: AsyncFunction<T>, immediate = true) {
  const [status, setStatus] = useState<Status>(Status.IDLE);
  const [value, setValue] = useState<Optional<T>>();
  const [error, setError] = useState<Optional<Error>>();

  const execute = useCallback(() => {
    setStatus(Status.PENDING);
    setValue(undefined);
    setError(undefined);

    asyncFunction()
      .then((response) => {
        setValue(response);
        setStatus(Status.SUCCESS);
      })
      .catch((err) => {
        setError(err);
        setStatus(Status.ERROR);
      });
  }, [asyncFunction]);

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return { execute, status, value, error };
}
