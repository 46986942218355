import { EntityResponse, useClient } from 'api-client';
import { useAuth } from 'hooks/useAuth';
import { useQuery } from 'react-query';
import { ColumnType } from '../store/AccountsStore';

type UserPersonaPropertiesList = {
  properties: Array<ColumnType>;
  entity: EntityResponse;
};

export default function useGetUserListPreference() {
  const client = useClient();
  const { user: userData } = useAuth();

  return useQuery<Array<UserPersonaPropertiesList>>(['user-list-preference', userData?.id], () =>
    client.getUserListPreference()
  );
}
