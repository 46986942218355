import { ReactComponent as HiddenOutlined } from 'assets/images/hidden_outlined.svg';
import { ReactComponent as ShowPassword } from 'assets/images/show_password.svg';
import { classNames } from 'utils/common';
import { forwardRef, InputHTMLAttributes, Ref, useState } from 'react';

type InputProps = {
  isError?: boolean;
  error?: {
    isError?: boolean;
    message: string;
  };
};

const inputAutoFill = {
  transition: 'background-color 1000s ease-in-out 0s',
  height: 40,
};
export const TextInput = forwardRef(
  (
    {
      className,
      isError,
      error,
      disabled,
      ...rest
    }: InputHTMLAttributes<HTMLInputElement> & InputProps,
    ref
  ) => {
    TextInput.displayName = 'HeadlessTextInput';

    return (
      <>
        <input
          className={classNames(
            'px-2 py-2 rounded-md  border-1 focus:outline-none placeholder:text-tw-gray-c',
            className,
            isError ? (disabled ? 'border-tw-gray-c' : 'border-tw-red') : 'border-tw-gray-c'
          )}
          style={inputAutoFill}
          ref={ref as Ref<HTMLInputElement>}
          disabled={disabled}
          {...rest}
        />
        <p className="text-xs text-red-500">{disabled ? '' : isError ? error?.message : ''}</p>
      </>
    );
  }
);

export const PasswordInput = forwardRef(
  ({ className, isError, ...rest }: InputHTMLAttributes<HTMLInputElement> & InputProps, ref) => {
    PasswordInput.displayName = 'HeadlessPasswordInput';

    const [hidden, setHidden] = useState(true);
    return (
      <div
        className={classNames(
          'flex items-center px-2 rounded border-1',
          isError ? 'border-tw-red' : 'border-tw-gray-c'
        )}
      >
        <input
          type={hidden ? 'password' : 'text'}
          // className used for asserting the test
          className={classNames(
            'py-3 focus:outline-none',
            className,
            isError ? 'error' : 'no-error'
          )}
          ref={ref as Ref<HTMLInputElement>}
          style={inputAutoFill}
          {...rest}
        />
        <div className="cursor-pointer" onClick={() => setHidden(!hidden)}>
          {hidden ? <HiddenOutlined /> : <ShowPassword />}
        </div>
      </div>
    );
  }
);
