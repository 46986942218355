import { PrimaryButton } from 'ui-components/inputs/Buttons';
import { ACCOUNT_ID } from 'utils/globalTypes';
import { useAuth } from 'hooks/useAuth';
import { noop } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

export default function NotFound() {
  const router = useHistory();
  const { user } = useAuth();
  const accountIdFromLocalStorage =
    localStorage.getItem(ACCOUNT_ID) === 'null' ? null : localStorage.getItem(ACCOUNT_ID);
  const params = useParams<any>();
  const redirectOnce = useRef(false);
  const [renderNotFound, setRenderNotFound] = useState<boolean | null>(null);

  const getHashFromAccountSchemaName = useCallback(
    (accountSchemaName: string) => {
      if (user?.organizations.find((org) => org.orgId === accountSchemaName)) {
        return accountSchemaName;
      }

      return user?.organizations.find((org) => org.schemaName === accountSchemaName)?.orgId;
    },
    [user?.organizations]
  );

  // TODO: REMOVE
  // TEMPORARY_REDIRECT
  useEffect(() => {
    if (user) {
      if (accountIdFromLocalStorage) {
        if (accountIdFromLocalStorage === params?.schemaName) {
          noop();
          setRenderNotFound(true);
        } else {
          if (!redirectOnce.current) {
            const redirectHashUrl = getHashFromAccountSchemaName(accountIdFromLocalStorage);
            // if hash is not undefined.
            if (redirectHashUrl) {
              router.push(`/${redirectHashUrl}${`${router.location.pathname}`}`);
            }
            redirectOnce.current = true;
            setTimeout(() => {
              setRenderNotFound(false);
            }, 0);
          }
        }
      }
    }

    return () => {
      setRenderNotFound(null);
    };
  }, [user, accountIdFromLocalStorage, router, params?.schemaName, getHashFromAccountSchemaName]);

  if (renderNotFound === null) {
    return null;
  }

  return (
    <div className="h-screen grid place-items-center">
      <div className="flex items-center">
        <div>
          <div className="text-lg font-semibold">
            Uh Oh! You have reached an unreachable location
          </div>
          <div className="text-tw-gray-7">
            The URL you are looking for does not exist or has been removed.
          </div>
          <PrimaryButton onClick={() => router.push('/')} className="mx-auto mt-8">
            Go Home
          </PrimaryButton>
          {user?.isStaff && (
            <div
              className="text-center mt-4 text-tw-blue-0d font-medium cursor-pointer"
              onClick={() => router.push('/login')}
            >
              Go to login instead
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
