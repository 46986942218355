import { useMemo } from 'react';
import { Column, useTable } from 'react-table';
import * as React from 'react';
import { TeamMemberEntity } from '../../../../api-client';
import { RolesData } from '../../Roles';

interface TableProps {
  columns: Array<Column>;
  data: TeamMemberEntity[] | RolesData[];
}

export const Table: React.FC<TableProps> = (props) => {
  const { columns, data } = props;
  const tableColumns = useMemo(() => columns, [columns]);
  const tableData = useMemo(() => data || [], [data]);
  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: tableColumns,
    data: tableData,
    initialState: {},
  });
  return (
    <div>
      <table
        {...getTableBodyProps()}
        className={`table table-lg table-alternate table-thead-bordered table-nowrap table-align-middle`}
      >
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  key={column.id}
                  {...column.getHeaderProps()}
                  className="text-sm font-normal uppercase text-tw-gray-7"
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return [
              <tr key={row.id} {...row.getRowProps()} className="h-12">
                {row.cells.map((cell) => {
                  return (
                    <td
                      key={cell.row.id}
                      {...cell.getCellProps()}
                      className="text-sm font-normal text-left text-tw-black-5"
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>,
            ];
          })}
        </tbody>
      </table>
    </div>
  );
};
