import { ReactComponent as AttributesIcon } from 'assets/images/attributes.svg';
import { useAuth } from 'hooks/useAuth';
import useEntitiesQuery from 'hooks/useEntitiesQuery';
import { useSyncScroller } from 'hooks/useSyncScroll';
import { isEmpty } from 'lodash';
import { getCohortIconAndColor } from 'pages/pql-workflow/components/define-pql/Cohort';
import AccountAttributesModal from 'pages/users-and-accounts/accounts/accounts-list/account-list/AccountAttributesModal';
import UserAccountsHeader from 'pages/users-and-accounts/accounts/UsersAccountsHeader';
import { AccountFields } from 'pages/users-and-accounts/fields';
import useGetUserListPreference from 'pages/users-and-accounts/hooks/useGetUserListPreference';
import useUpdateUserListPreference, {
  UpdateUserListPreferenceActions,
} from 'pages/users-and-accounts/hooks/useUpdateUserListPreference';
import { Entity, SortOrder } from 'pages/users-and-accounts/users/types';
import qs from 'query-string';
import { useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import { useColumnOrder, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { toast as toastify } from 'react-toastify';
import { QueryType } from 'stores/QueryStore';
import { useAnalytics } from 'telemetry';
import { AnalyticsConst, AnalyticsEvents } from 'telemetry/constants';
import { EmptyFolder } from 'ui-components/data-display/Icons';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';
import { SecondaryButton } from 'ui-components/inputs/Buttons';
import { Search } from 'ui-components/inputs/Search/Search';
import { capitalizeFirstLetter, classNames, getTableCell, updateCellWidthMap } from 'utils/common';
import { ReactComponent as LeftArrowIcon } from '../../../../assets/images/left-arrow.svg';
import { ReactComponent as RightArrowIcon } from '../../../../assets/images/right-arrow.svg';
import CohortsPanel from '../../components/CohortsPanel';
import ColumnActions, { SortColumnFilters } from '../../components/ColumnActions';
import UserAccountsTable from '../../components/UserAccountsTable';
import useAccounts from '../../hooks/useAccounts';
import { ColumnType, useAccountsStore } from '../../store/AccountsStore';
import { getDefaultAccountTableColumn } from './account-list/AccountListTableColumns';
import useAllCohorts from '../../../../hooks/useAllCohorts';

export default function AccountsList() {
  const {
    searchQuery,
    setSearchQuery,
    field_mapping,
    page,
    setPage,
    filters,
    setFilters,
    setSortBy,
    sortBy,
    addFieldMapping,
    initFieldMappings,
  } = useAccountsStore();

  const { data: accountsData, isLoading, isFetching, isError } = useAccounts();
  const { data: accountEntityFields, isLoading: loadingEntitiesQuery } = useEntitiesQuery(
    Entity.accounts
  );
  const { data: userListPreferences, isLoading: loadingUserListPreference } =
    useGetUserListPreference();
  const { mutate: updateUserListPreference } = useUpdateUserListPreference();
  const { user: userData } = useAuth();
  const router = useHistory();

  const [attributesModelOpen, setAttributesModalOpen] = useState<boolean>(false);
  const [columnHovered, setColumnHovered] = useState<string | null>(null);
  const [showPopupFilter, setShowPopupFilter] = useState<boolean>(false);
  const [cellWidthMap, setCellWidthMap] = useReducer(updateCellWidthMap, {} as any);
  const { cohortItems, allUsersCohortItem } = useAllCohorts();

  const { track } = useAnalytics();
  const columns = useMemo(
    () => [
      /**
       * Additional columns that can be added by the user
       */
      ...Object.keys(field_mapping).map((f) => {
        if (f === 'domain_1') {
          return {
            Header: 'DOMAIN',
            accessor: f,
            Cell: ({ cell }) => getTableCell(cell.value, cell.column.id),
            sticky:
              field_mapping[f]?.keyName === userData?.currentOrganization[AccountFields.ACCOUNT_ID]
                ? 'left'
                : false,
          };
        }

        if (f === 'account_creation_time') {
          return {
            Header: 'Account Creation Time',
            accessor: f,
            Cell: ({ cell }) => getTableCell(cell.value, cell.column.id),
            sticky:
              field_mapping[f]?.keyName === userData?.currentOrganization[AccountFields.ACCOUNT_ID]
                ? 'left'
                : false,
          };
        }

        if (f === 'account_id') {
          return {
            Header: 'Account ID',
            accessor: f,
            Cell: ({ cell }) => getTableCell(cell.value, cell.column.id),
            sticky:
              field_mapping[f].keyName === userData?.currentOrganization[AccountFields.ACCOUNT_ID]
                ? 'left'
                : false,
          };
        }

        if (f === 'macro_persona') {
          return {
            Header: field_mapping[f].displayName,
            accessor: field_mapping[f].keyName,
            Cell: ({ cell }) => {
              return (
                <div className="flex items-center">
                  <div>
                    {
                      getCohortIconAndColor(
                        cohortItems
                          ?.filter((c) => c?.name !== allUsersCohortItem?.name)
                          .find((c) => c.filters?.[0].value.includes(cell.value))?.name
                      ).icon
                    }
                  </div>
                  <div className="ml-1">
                    {cell.value ? cell.value.replace('Users', 'Accounts') : 'N/A'}
                  </div>
                </div>
              );
            },
            sticky:
              field_mapping[f]?.keyName === userData?.currentOrganization[AccountFields.ACCOUNT_ID]
                ? 'left'
                : false,
          };
        }

        return {
          Header: capitalizeFirstLetter(field_mapping[f]?.displayName || '--'),
          accessor: field_mapping[f]?.keyName,
          Cell: ({ cell }) => getTableCell(cell.value, cell.column.id),
          sticky:
            field_mapping[f].keyName === userData?.currentOrganization[AccountFields.ACCOUNT_ID]
              ? 'left'
              : false,
        };
      }),
    ],
    [allUsersCohortItem, cohortItems, field_mapping, userData?.currentOrganization]
  );

  const tableInstance = useTable(
    { columns, data: (accountsData?.data || []) as any },
    useColumnOrder,
    useSticky
  );
  const { setColumnOrder } = tableInstance;

  // get the cell width
  useLayoutEffect(() => {
    if (!isLoading && !isFetching && !loadingUserListPreference) {
      tableInstance?.columns?.map((column) => {
        const el = document.getElementsByClassName(`td-${column.id}`);
        setCellWidthMap({
          type: 'update',
          payload: {
            id: column.id,
            width: el?.[0]?.getClientRects()[0].width,
            height: el?.[0]?.getClientRects()[0].height,
          },
        });
      });
    }
  }, [isFetching, isLoading, tableInstance?.columns, loadingUserListPreference]);

  // sets the column order
  useEffect(() => {
    setColumnOrder([
      userData?.currentOrganization?.[AccountFields.ACCOUNT_ID],
      userData?.currentOrganization?.[AccountFields.ACCOUNT_CREATED_AT],
      userData?.currentOrganization?.[AccountFields.ACCOUNT_NAME],
    ]);
  }, [setColumnOrder, userData]);

  // sync scroll refs
  const headersRef = useSyncScroller('syncScrollAccountDivs');
  const tableRef = useSyncScroller('syncScrollAccountDivs');
  const errorRef = useSyncScroller('syncScrollAccountDivs');

  const hasInitialisedMappingsRef = useRef(false);

  // initialise field_mapping.
  // handles initial field mappings
  useEffect(() => {
    const entitySpace = userListPreferences?.find(
      (pref) => pref.entity.id === QueryType.accounts.toLowerCase()
    );
    // initialise field_mappings only when it is empty.
    if (
      accountEntityFields &&
      entitySpace?.properties?.length > 0 &&
      !hasInitialisedMappingsRef.current
    ) {
      let initialMappings = {};
      accountEntityFields
        ?.filter(
          (accntProp) => !!entitySpace.properties.find((p) => p.columnName === accntProp.columnName)
        )
        .map((mapping) => (initialMappings[mapping.keyName] = mapping));
      hasInitialisedMappingsRef.current = true;
      initFieldMappings(initialMappings);
    }
  }, [accountEntityFields, initFieldMappings, userListPreferences]);

  const applySelectedAttribute = (attr: ColumnType) => {
    const { source, keyName, keySpace, displayName } = attr;

    if (!field_mapping[keyName]) {
      // mutate with adding field_mapping
      updateUserListPreference({
        entityType: QueryType.accounts,
        action: UpdateUserListPreferenceActions.ADD,
        currentFieldMapping: attr,
      });
      track(AnalyticsEvents.COLUMN_ADDED, {
        [AnalyticsConst.ATTRIBUTES]: {
          keyName: {
            keyAlias: keyName,
            keyName: keyName,
            source: source,
            keySpace: keySpace,
            displayName: displayName,
          },
        },
      });
    } else {
      if (getDefaultAccountTableColumn(userData).includes(keyName)) {
        toastify(<ErrorToast description="Cannot remove a default account column" altText="" />, {
          type: 'error',
        });
      } else {
        // mutate with remove field_mapping
        updateUserListPreference({
          entityType: QueryType.accounts,
          action: UpdateUserListPreferenceActions.REMOVE,
          currentFieldMapping: attr,
        });
        track(AnalyticsEvents.COLUMN_DELETED, {
          [AnalyticsConst.ATTRIBUTES]: {
            keyName: {
              keyAlias: keyName,
              keyName: keyName,
              source: source,
              keySpace: keySpace,
              displayName: displayName,
            },
          },
        });
      }
    }

    // reset the scroll positions
    (tableRef?.current as HTMLElement).scrollLeft = 0;
    (headersRef?.current as HTMLElement).scrollLeft = 0;
    (errorRef?.current as HTMLElement).scrollLeft = 0;
  };

  // adds isSandbox parameter to the URL
  useEffect(() => {
    let urlParams = {};
    if (userData?.currentOrganization?.isSandbox) {
      urlParams['isSandbox'] = true;
    } else urlParams['isSandbox'] = false;

    router.replace({
      search: qs.stringify(urlParams),
    });
  }, [router, userData?.currentOrganization?.isSandbox]);

  return (
    <div className="-mt-5">
      <UserAccountsHeader />
      {/* Account Attributes Modal */}
      <AccountAttributesModal
        isOpen={attributesModelOpen}
        setIsOpen={setAttributesModalOpen}
        field_mapping={field_mapping}
        applySelectedAttribute={(attr: ColumnType) => applySelectedAttribute(attr)}
        defaultSelectedColumns={[]}
      />
      <div className="bg-tw-white-ff">
        <div className="w-full px-6 ">
          <div className="sticky top-0 bg-tw-white-ff z-5">
            <div className="py-2 rounded bg-tw-white-ff">
              {userData?.currentOrganization?.isPersonas && (
                <CohortsPanel
                  filters={filters}
                  setFilters={setFilters}
                  entityType={QueryType.accounts}
                  addFieldMapping={addFieldMapping}
                  fieldMappings={field_mapping}
                />
              )}
              <hr className="w-full my-3" />
              <div className="flex items-center justify-between pt-2 pb-1 pr-3">
                <div className="flex items-center">
                  <Search
                    placeholder="Search by account ID..."
                    value={searchQuery}
                    onChangeFunction={(e) => setSearchQuery(e.target.value)}
                    onClearFunction={() => setSearchQuery('')}
                    onClick={() => track(AnalyticsEvents.ACCOUNT_LIST_SEARCH_CLICKED)}
                    hasShadow={false}
                  />
                  {isLoading || isFetching || loadingUserListPreference ? (
                    <div className="h-8 ml-4 bg-tw-gray-eb w-36"></div>
                  ) : (
                    <div className="ml-4 font-medium text-tw-black-7">
                      Showing{' '}
                      {new Intl.NumberFormat('en-us', {
                        compactDisplay: 'short',
                        notation: 'compact',
                      }).format(accountsData?.count)}{' '}
                      accounts
                    </div>
                  )}
                </div>
                <SecondaryButton
                  className="text-tw-blue-0d -mt-0.5 product-tour-add-attributes"
                  onClick={() => setAttributesModalOpen(true)}
                >
                  <AttributesIcon />
                  Add Attributes
                </SecondaryButton>
              </div>
            </div>
            <div className="w-full mt-2 overflow-x-hidden bg-tw-gray-f9" ref={headersRef}>
              {tableInstance?.headerGroups?.map((headerGroup, index) => {
                return (
                  <div
                    key={index}
                    className={classNames(
                      'inline-flex items-center grow-1',
                      accountsData?.count === 0 ? '!flex w-full' : ''
                    )}
                  >
                    {headerGroup.headers.map((column) => {
                      return (
                        <div
                          key={column.id}
                          style={{
                            minWidth: cellWidthMap[column.id]?.width
                              ? cellWidthMap[column.id]?.width
                              : 250,
                            minHeight: cellWidthMap[column.id]?.height
                              ? cellWidthMap[column.id]?.height - 8
                              : 52,
                            maxHeight: cellWidthMap[column.id]?.height
                              ? cellWidthMap[column.id]?.height - 8
                              : 52,
                          }}
                          className={classNames(
                            `border-x-1 border-transparent pl-3 !text-md font-medium flex items-center justify-between pr-2 grow product-tour-${column?.id}`,
                            columnHovered === column.id
                              ? '!bg-tw-gray-eb cursor-pointer border-tw-gray-eb'
                              : '',
                            column.id === userData?.currentOrganization[AccountFields.ACCOUNT_ID]
                              ? 'sticky z-[3] left-0 bg-tw-gray-f7 border-r-1 border-l-0 border-tw-gray-eb'
                              : 'bg-tw-gray-f7'
                          )}
                          onMouseOver={() => {
                            if (!showPopupFilter) {
                              setColumnHovered(column.id);
                            }
                          }}
                          onMouseLeave={() => {
                            if (!showPopupFilter) {
                              setColumnHovered(null);
                            }
                          }}
                          onClick={() => {
                            setColumnHovered(column.id);
                            // also sort the table.
                            let sf: null | {
                              source: string;
                              keyName: string;
                              keySpace: string;
                              order: typeof SortColumnFilters[0];
                            } = null;
                            sortBy.forEach((sort) => {
                              if (sort.keyName === field_mapping[column.id]?.keyName) {
                                const { source, keyName, keySpace } = field_mapping[column.id];
                                sf = {
                                  source,
                                  keyName,
                                  keySpace,
                                  order: SortColumnFilters?.find(
                                    (filter) => filter.shortHand === sort.order
                                  ),
                                };
                              }
                            });

                            if (sf) {
                              if (sf.order?.shortHand === 'ASC') {
                                // sort descending
                                setSortBy([
                                  ...sortBy.filter(
                                    (s) => s.keyName !== field_mapping[column.id]?.keyName
                                  ),
                                  {
                                    source: field_mapping[column.id]?.source,
                                    keySpace: field_mapping[column.id]?.keySpace,
                                    keyName: field_mapping[column.id]?.keyName,
                                    order: SortOrder.descending,
                                  },
                                ]);
                              } else {
                                // sort default.
                                setSortBy([
                                  ...sortBy.filter(
                                    (s) => s.keyName !== field_mapping[column.id]?.keyName
                                  ),
                                ]);
                              }
                            } else {
                              // just sort ascending if there is no sort applied
                              // on the filter.
                              setSortBy([
                                ...sortBy.filter(
                                  (s) => s.keyName !== field_mapping[column.id]?.keyName
                                ),
                                {
                                  source: field_mapping[column.id]?.source,
                                  keySpace: field_mapping[column.id]?.keySpace,
                                  keyName: field_mapping[column.id]?.keyName,
                                  order: SortOrder.ascending,
                                },
                              ]);
                            }
                          }}
                        >
                          {column.Header}
                          {columnHovered === column.id ? (
                            <ColumnActions
                              column={field_mapping[column.id]}
                              showPopupFilter={showPopupFilter}
                              setShowPopupFilter={setShowPopupFilter}
                              entityType={QueryType.accounts}
                              setSortBy={setSortBy}
                              sortBy={sortBy}
                              setFilters={setFilters}
                              filters={filters}
                            />
                          ) : (
                            // Column actions are taking 82px
                            <div className="w-[84px] h-[22px]"></div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          {(isLoading || loadingEntitiesQuery || isFetching || loadingUserListPreference) && (
            <div className="mt-2 mr-4 text-center text-tw-black-7 animate-pulse">
              {Array(15)
                .fill(0)
                .map((v, index) => {
                  return (
                    <div
                      key={index}
                      className={classNames('bg-tw-gray-eb h-6', index === 0 ? '' : 'my-8')}
                    ></div>
                  );
                })}
            </div>
          )}
          {/* field_mapping is empty after all loading states are resolved */}
          {isEmpty(field_mapping) &&
            !(isLoading || loadingEntitiesQuery || isFetching || loadingUserListPreference) && (
              <div className="mt-2 mr-4 text-center py-24 grid place-items-center mb-16">
                <div className="grid place-items-center">
                  <EmptyFolder />
                  <div className="w-48">
                    <p>No attributes selected</p>
                    <p className="text-tw-black-7 text-xs">
                      Please add some attributes to filter accounts
                    </p>
                  </div>
                </div>
              </div>
            )}
          {!isLoading && !loadingEntitiesQuery && !isFetching && (
            <div>
              <div className="w-full overflow-x-scroll border-b-1 border-tw-gray-eb" ref={tableRef}>
                <UserAccountsTable
                  tableInstance={tableInstance}
                  columnHovered={columnHovered}
                  entityType={QueryType.accounts}
                  defaultColumns={[userData?.currentOrganization[AccountFields.ACCOUNT_ID]]}
                />
                <div
                  className={classNames(
                    'max-w-full h-[43vh] relative',
                    accountsData?.data.length === 0 ? '' : 'hidden'
                  )}
                >
                  {accountsData?.data.length === 0 && (
                    <div
                      className="h-full overflow-scroll bg-tw-white-ff"
                      style={{
                        width: 250 * Object.keys(field_mapping).length,
                      }}
                    >
                      <div className="fixed ml-[42%] overflow-y-hidden overflow-x-scroll my-24 text-tw-black-7">
                        <EmptyFolder className="w-20 h-20" />
                        No accounts found
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {isError && (
          <div
            className="relative w-full overflow-x-scroll border-b-1 border-tw-gray-eb"
            ref={errorRef}
          >
            <div
              className={classNames(
                'max-w-full h-[43vh] relative'
                // usersData?.data.length === 0 ? '' : 'hidden'
              )}
            >
              <div
                className="h-full overflow-scroll bg-tw-white-ff"
                style={{
                  width: 250 * Object.keys(field_mapping).length,
                }}
              >
                <div className="fixed translate-x-[35vw] overflow-y-hidden overflow-x-scroll my-24 text-tw-black-7">
                  Something went wrong...
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="py-4">
          {accountsData?.count > 0 && !isFetching && (
            <ReactPaginate
              forcePage={page - 1}
              pageCount={Math.ceil(accountsData?.count / 50)}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              onPageChange={({ selected }) => {
                setPage(selected + 1);
                window.scrollTo({ top: 0, behavior: 'auto' });
                track(AnalyticsEvents.ACCOUNT_LIST_PAGE_CHANGED, {
                  [AnalyticsConst.PAGE]: selected + 1,
                });
              }}
              containerClassName="flex items-center justify-start"
              activeClassName="children:bg-tw-gray-eb children:text-tw-black-5 children:font-medium"
              pageLinkClassName="px-tw-3 py-2 rounded text-tw-black-5 hover:text-tw-black-5 font-medium"
              breakLinkClassName="px-tw-3 py-2"
              previousLabel={
                <div className="flex items-center py-2 gap-x-2 px-tw-3 text-black-3">
                  <LeftArrowIcon className="fill-current" />
                  <span>Prev</span>
                </div>
              }
              nextLabel={
                <div className="flex items-center py-2 gap-x-2 px-tw-3 text-black-3">
                  <span>Next</span>
                  <RightArrowIcon className="fill-current" />
                </div>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
