import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';
import { useMutation, useQueryClient } from 'react-query';
import { toast as toastify } from 'react-toastify';
import { useClient } from '../../../api-client';

export default function useResendInvite() {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation((id: number | string) => client.resendInvite(id), {
    onSuccess: () => {
      toastify(<SuccessToast description="Invite has been resent" />, {
        type: 'success',
      });

      queryClient.refetchQueries(['pendingInvites']);
    },
    onError: (error) => {
      const err = error['response']['data']['detail'];
      toastify(<ErrorToast description={err ?? 'There was an error in resending the invite'} />, {
        type: 'error',
      });
    },
  });
}
