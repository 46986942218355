import { Menu, Transition } from '@headlessui/react';
import * as React from 'react';
import { Fragment } from 'react';
import { AddUserIcon, EmptyFolder, MoreVertical } from '../../../ui-components/data-display/Icons';
import { transformText } from '../../../utils/common';
import useRole from '../../../hooks/useRole';
import { useAnalytics } from '../../../telemetry';
import { AnalyticsConst, AnalyticsEvents } from '../../../telemetry/constants';
import useCancelInvite from '../hooks/useCancelInvite';
import usePendingInvites from '../hooks/usePendingInvites';
import useResendInvite from '../hooks/useResendInvite';

interface PendingInviteProps {}

const PendingInvite: React.FC<PendingInviteProps> = () => {
  const { track } = useAnalytics();
  const { data: pendingInvites, isLoading: isPendingInvitesLoading } = usePendingInvites();
  const cancelInviteHook = useCancelInvite();
  const resendInviteHook = useResendInvite();
  const { isReadOnly } = useRole();

  const resendInvite = (id, email) => {
    resendInviteHook.mutate(id, {
      onError: async () => {
        track(AnalyticsEvents.TEAM_MEMBER_INVITE_RESEND_FAILED, {
          [AnalyticsConst.INVITED_USER]: email,
        });
      },
    });
  };

  const cancelInvite = (id, email) => {
    cancelInviteHook.mutate(id, {
      onError: async () => {
        track(AnalyticsEvents.TEAM_MEMBER_INVITE_CANCELLED_FAILED, {
          [AnalyticsConst.INVITED_USER]: email,
        });
      },
    });
  };

  return (
    <div className="h-[70vh]">
      {isPendingInvitesLoading && (
        <div className="mb-10 space-y-5">
          <div className="w-24 h-2 bg-gray-100" />
          <div className="h-12 bg-gray-100 w-68" />
        </div>
      )}
      {pendingInvites && pendingInvites.length !== 0 && (
        <div className="grid grid-cols-2 gap-x-4 gap-y-4">
          {pendingInvites.map((key) => (
            <div key={key?.invitation.id}>
              <div className="relative px-4 py-5 bg-white rounded-lg border-1 border-tw-gray-eb">
                <div className="flex space-x-4">
                  <div>
                    <AddUserIcon className="inline-block h-full align-middle fill-current text-tw-black-5" />
                  </div>
                  <div className="flex-1">
                    <div className="text-base font-normal text-tw-black-1 overflow-ellipsis">
                      {key.email}
                    </div>
                    {!key?.invitation.expired ? (
                      [
                        <div
                          key={key?.invitation.id}
                          className="mt-1 text-xs font-normal uppercase text-tw-black-7"
                        >
                          {key.role && (
                            <>
                              {transformText(key.role)} {'\u30FB'}{' '}
                            </>
                          )}
                          EXPIRES IN {key?.invitation.expireDaysLeft} DAYS
                        </div>,
                      ]
                    ) : (
                      <div className="mt-1 text-xs font-normal uppercase text-tw-orange-de">
                        INVITE EXPIRED
                      </div>
                    )}
                  </div>
                  {!isReadOnly && (
                    <div>
                      <Menu as="div" className="relative">
                        <div>
                          <Menu.Button className="align-middle">
                            <div className="py-2">
                              <MoreVertical className="block w-6 h-auto fill-current text-tw-black-5" />
                            </div>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 w-40 p-4 mt-2 space-y-3 origin-top-right bg-white rounded-md shadow-lg ring-black focus:outline-none text-tw-black-5">
                            <div className="cursor-pointer">
                              <Menu.Item>
                                <div
                                  onClick={() =>
                                    resendInvite(
                                      key?.invitation.id,
                                      key?.invitation.createdBy.email
                                    )
                                  }
                                >
                                  <p className="text-base font-normal">Resend invite</p>
                                </div>
                              </Menu.Item>
                            </div>
                            <div className="cursor-pointer">
                              <Menu.Item>
                                <div
                                  onClick={() =>
                                    cancelInvite(
                                      key?.invitation.id,
                                      key?.invitation.createdBy.email
                                    )
                                  }
                                >
                                  <p className="text-base font-normal">Cancel invite</p>
                                </div>
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {pendingInvites?.length === 0 && (
        <div className="grid place-items-center h-3/4">
          <div className="flex flex-col items-center mt-8">
            <EmptyFolder />
            <h2 className="mt-5 text-h2">No requested invites yet</h2>
            <p className="mt-2 text-tw-black-7">
              You’ll be able to see all the requested invites here when they come
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PendingInvite;
