import { PrimaryButton, TertiaryButton } from 'ui-components/inputs/Buttons';
import { useAuthStore } from 'hooks/useAuthStore';
import useSendInvite, { EmailInvite } from 'pages/settings/hooks/useSendInvite';
import * as React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ArrowRight, PlusIcon } from '../../../../ui-components/data-display/Icons';
import { PrimaryDropdown } from '../../../../ui-components/navigation/Dropdown';
import { TextInput } from '../../../../ui-components/inputs/Inputs';
import { emailRegExp } from '../../../../utils/common';
import useRole from '../../../../hooks/useRole';
import { roleOptions, Roles } from '../../Roles';
import MemberAccessTableModal from './MemberAccessTableModal';

const AddTeamMember: React.FC<{}> = () => {
  const router = useHistory();
  const { isReadAndWrite } = useRole();
  const { mutate: sendInvite, isLoading } = useSendInvite();
  const [numberOfEmails, setNumberOfEmails] = React.useState<number>(3);
  const [showTeamMembersAccess, setShowTeamMembersAccess] = React.useState<boolean>(false);
  const { getAccountId } = useAuthStore();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm();

  const { fields, append, remove } = useFieldArray({ name: 'emails', control });

  const onCloseTeamMembersAccessModal = () => {
    setShowTeamMembersAccess(false);
  };

  React.useEffect(() => {
    const newVal = numberOfEmails;
    const oldVal = fields.length;
    if (newVal > oldVal) {
      for (let i = oldVal; i < newVal; i++) {
        append({ email: '', role: '' });
      }
    } else {
      for (let i = oldVal; i > newVal; i--) {
        remove(i - 1);
      }
    }
  }, [numberOfEmails]);

  const onSubmit = (data) => {
    let emails: Array<EmailInvite> = [];
    let errs = false;
    data.email.forEach(function (v, i) {
      if (v && !data.role[i]) {
        setError(`role.${i}`, {
          type: 'manual',
          message: 'Please enter the role',
        });
        errs = true;
      } else if (!v && data.role[i]) {
        setError(`email.${i}`, {
          type: 'manual',
          message: 'Please enter the email',
        });
        errs = true;
      } else {
        let obj: EmailInvite;
        obj = {
          email: v,
          role: data.role[i],
        };
        if (obj.email) emails.push(obj);
      }
    });
    if (emails.length && !errs)
      sendInvite(emails, {
        onSuccess: () => {
          router.push(`/${getAccountId()}/settings/team-members`);
        },
      });
  };

  const addEmailField = () => {
    setNumberOfEmails(numberOfEmails + 1);
  };

  return (
    <div className="grid content-start min-h-full py-8 ">
      <div className="relative w-1/2 py-4 mx-auto bg-white border rounded-lg border-tw-gray-eb h-80vh">
        <div className="px-8">
          <h3 className="text-xl">Add team member</h3>
          <hr className="my-4 border-b border-tw-gray-eb" />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-8">
            <div className="flex mb-8 text-sm place-items-center">
              <p className="text-tw-black-3">
                All roles come with their own access levels within the dashboard.
              </p>
              <span
                className="cursor-pointer text-tw-blue-0d"
                onClick={() => setShowTeamMembersAccess(true)}
              >
                &nbsp; Learn more
              </span>
              <ArrowRight className="ml-1 text-base fill-current text-tw-blue-0d" />
            </div>

            <div className="flex w-3/4 space-x-4">
              <div className="flex-1 w-1/2">Enter email</div>
              <div className="flex-none w-2/6">Role</div>
            </div>
            {fields.map((item, i) => (
              <div key={i} className="flex w-3/4 mt-4 space-x-4">
                <div className="flex-1 w-1/2">
                  <TextInput
                    className="w-full py-3 border-tw-gray-c"
                    placeholder="Name@company.com"
                    autoFocus={i === 0}
                    id={`email.${i}`}
                    {...register(`email.${i}`, {
                      required: false,
                      pattern: emailRegExp,
                    })}
                    isError={errors?.email && errors?.email[`${i}`]}
                  />
                  {errors?.email &&
                    errors?.email[`${i}`] &&
                    (errors?.email[`${i}`].type === 'pattern' ? (
                      <span className="mt-2 text-xs text-tw-red-dd">This is not a valid email</span>
                    ) : (
                      <span className="mt-2 text-xs text-tw-red-dd">
                        {errors.email[`${i}`].message}
                      </span>
                    ))}
                </div>
                <div className="flex-none w-2/6">
                  <PrimaryDropdown
                    {...register(`role.${i}`)}
                    options={
                      isReadAndWrite
                        ? [
                            {
                              label: 'Read and Write',
                              value: Roles.READ_AND_WRITE,
                            },
                            { label: 'Read Only', value: Roles.READ_ONLY },
                          ]
                        : roleOptions
                    }
                    selectBtnWidth={'w-full'}
                    placeholder="Select one"
                    className="py-3"
                    id={`role.${i}`}
                    value={getValues(`role.${i}`)}
                    isError={errors?.role && errors?.role[`${i}`]}
                    onChange={(e) => {
                      setValue(`role.${i}`, e, { shouldValidate: true });
                    }}
                  />
                  {errors?.role && errors?.role[`${i}`] && (
                    <span className="mt-2 text-xs text-tw-red-dd">
                      {errors.role[`${i}`].message}
                    </span>
                  )}
                </div>
              </div>
            ))}
            <div className="flex mt-4 cursor-pointer place-items-center">
              <PlusIcon className="text-base fill-current text-tw-blue-0d" />
              <p className="ml-2 text-sm text-tw-blue-0d" onClick={addEmailField}>
                Add email
              </p>
            </div>
          </div>

          <div className="absolute w-full px-8 bg-white bottom-4">
            <hr className="border-b border-tw-gray-eb" />
            <div className="flex justify-end mt-5">
              <TertiaryButton
                type="button"
                onClick={() => router.push(`/${getAccountId()}/settings/team-members`)}
              >
                Cancel
              </TertiaryButton>
              <PrimaryButton
                type="submit"
                className="ml-2 w-44"
                isLoading={isLoading}
                disabled={isLoading}
              >
                Send Invitation
              </PrimaryButton>
            </div>
          </div>
        </form>
      </div>
      {
        <MemberAccessTableModal
          showTeamMembersAccess={showTeamMembersAccess}
          setShowTeamMembersAccess={setShowTeamMembersAccess}
          onCloseTeamMembersAccessModal={onCloseTeamMembersAccessModal}
        />
      }
    </div>
  );
};

export default AddTeamMember;
