import { toast as toastify } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { PrimaryButton, TertiaryButton } from 'ui-components/inputs/Buttons';
import Toggle from 'ui-components/inputs/Switch';
import { ErrorBoundary } from 'react-error-boundary';
import { classNames } from 'utils/common';
import { useAuthStore } from 'hooks/useAuthStore';
import { useEffect } from 'react';
import { Controller, FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';
import { ReactComponent as ErrorIcon } from '../../assets/images/error.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import Select from '../../ui-components/inputs/Select';
import useAddWebhook from './hooks/useAddWebhook';
import useEditWebhook from './hooks/useEditWebhook';
import useGetWebhookById from './hooks/useGetWebhookById';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';
import { ErrorAnalyticsEvents } from 'telemetry/constants';
import ErrorBoundaryFallback from 'ui-components/feedback/ErrorBoundaryFallback';
import { useAnalytics } from 'telemetry';

export type InitialValues = {
  name: string;
  method: {
    value: string;
    text: string;
  };
  url: string;
  auth: {
    username: string;
    password: string;
  };
  headers: { key: string; value: string }[];
  authEnabled: boolean;
  headersEnabled: boolean;
};

// form schema and validation
const schema = Yup.object({
  name: Yup.string().required(),
  method: Yup.object()
    .shape({
      text: Yup.string(),
      value: Yup.string(),
    })
    .required(),
  url: Yup.string()
    .matches(
      // https://gist.github.com/dperini/729294, URL regex
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
      'Enter a valid url!'
    )
    .required(),
  authEnabled: Yup.boolean(),
  headersEnabled: Yup.boolean(),
  auth: Yup.object()
    .when('authEnabled', {
      is: true,
      then: (orgSchema) =>
        orgSchema.shape({
          username: Yup.string().required(),
          password: Yup.string().required(),
        }),
      otherwise: (orgSchema) => orgSchema.shape({}),
    })
    .nullable()
    .notRequired(),
  headers: Yup.array().when('headersEnabled', {
    is: true,
    then: (orgSchema) =>
      orgSchema.of(
        Yup.object().shape({
          key: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
    otherwise: (orgSchema) => orgSchema.notRequired(),
  }),
});

const initialValues: InitialValues = {
  name: '',
  method: {
    value: 'post',
    text: 'POST',
  },
  url: '',
  auth: {
    username: '',
    password: '',
  },
  headers: [{ key: '', value: '' }],
  authEnabled: false,
  headersEnabled: false,
};

const getHeaders = (headers: { [key: string]: string }) => {
  let mappedHeaderstoArr = Object.keys(headers)
    .map((key) => ({
      key,
      value: headers[key],
    }))
    .filter((item) => {
      return !['Authorization'].includes(item.key);
    });
  return {
    enabled: mappedHeaderstoArr.length > 0,
    payload: mappedHeaderstoArr,
  };
};

const getAuth = (headers: { [key: string]: string }) => {
  const basicAuthHeader = headers['Authorization']?.split(' ');
  const decodedBasicAuthHeader =
    basicAuthHeader && basicAuthHeader?.length ? atob(basicAuthHeader[1])?.split(':') : [];
  if (basicAuthHeader && decodedBasicAuthHeader.length === 2) {
    return {
      enabled: true,
      payload: { username: decodedBasicAuthHeader[0], password: decodedBasicAuthHeader[1] },
    };
  }

  return {
    enabled: false,
    payload: null,
  };
};

const options = [
  {
    value: 'post',
    text: 'POST',
  },
  {
    value: 'put',
    text: 'PUT',
  },
];

type WebhookFormProps = {
  integrationId?: string;
  connectedIntegrationId?: string;
  isEdit: boolean;
  showButtons: boolean;
  submitButtonsClassNames?: string;
  onFormSubmit?: (
    name: string,
    url: string,
    method: string,
    headers: Record<string, string>
  ) => void;
  onFormStateChange?: (isDirty: boolean) => void;
};

export function WebhookForm({
  integrationId,
  connectedIntegrationId,
  isEdit,
  showButtons,
  onFormSubmit,
  onFormStateChange,
  submitButtonsClassNames = '',
}: WebhookFormProps) {
  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    control,
    register,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });
  const router = useHistory();
  const { getAccountId } = useAuthStore();
  const { data } = useGetWebhookById(connectedIntegrationId, isEdit);

  useEffect(() => {
    if (isEdit && data) {
      // resets the initial values for Edit cases
      reset({
        name: data.data.name,
        method: options.find((o) => o.value === data.data.metadata?.webhook?.method) || {
          value: 'post',
          text: 'POST',
        },
        url: data.data.metadata?.webhook?.url,
        auth: getAuth(data.data.metadata?.webhook?.headers).payload,
        headers: getHeaders(data.data.metadata?.webhook?.headers).payload,
        headersEnabled: getHeaders(data.data.metadata?.webhook?.headers).enabled ? true : false,
        authEnabled: getAuth(data.data.metadata?.webhook?.headers).enabled ? true : false,
      });
    }
  }, [data, isEdit, reset]);

  useEffect(() => {
    onFormStateChange && onFormStateChange(isDirty);
  }, [isDirty]);

  const { mutateAsync: createNewWebhook, isLoading: creatingNewWebhook } =
    useAddWebhook(integrationId);
  const { mutateAsync: editExistingWebhook, isLoading: editingWebhook } =
    useEditWebhook(connectedIntegrationId);

  const getBasicAuthHeader = (username: string, password: string) => {
    const base64header = btoa(`${username}:${password}`);
    return { Authorization: `Basic ${base64header}` };
  };

  const onSubmit = async (values: FieldValues) => {
    const { name, method, url, headers, headersEnabled, auth } = values;
    let username = auth?.username ?? '';
    let password = auth?.password ?? '';
    let payloadHeaders = {};
    if (headersEnabled) {
      headers.map(
        (header: { key: string; value: string }) => (payloadHeaders[header.key] = header.value)
      );
    }
    if (isEdit) {
      await editExistingWebhook({
        name,
        webhook: {
          url,
          method: method.value,
          headers: {
            ...payloadHeaders,
            ...(username && password ? getBasicAuthHeader(username, password) : {}),
          },
          batchSize: data.data.metadata?.webhook?.batchSize,
        },
      });
    } else if (!showButtons) {
      onFormSubmit(name, url, method.value, payloadHeaders);
    } else {
      await createNewWebhook(
        {
          name,
          url,
          method: method.value,
          headers: {
            ...payloadHeaders,
            ...(username && password ? getBasicAuthHeader(username, password) : {}),
          },
        },
        {
          onSuccess: () => {
            toastify(<SuccessToast description="Sucessfully added the webhook" />, {
              type: 'success',
            });
            if (showButtons) {
              router.push(`/${getAccountId()}/integrations?type=webhooks`);
            }
          },
        }
      );
    }
  };

  // interestingly picks up the selected field (which ever is an array type)
  // from the useForm declaration
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'headers',
    // keyName: "id", default to "id", you can change the key name
  });

  return (
    <form id="webhook" onSubmit={handleSubmit(onSubmit)}>
      {/* webhook name */}
      <div className="mt-3">
        <label className="mb-2 text-tw-black-5">Enter webhook name</label>
        <input
          placeholder="My awesome endpoint"
          className={classNames(
            'w-full px-3 py-2.5 border-1 rounded border-tw-gray-c focus:outline-none',
            errors?.name ? 'border-tw-red-dd' : 'border-tw-gray-c'
          )}
          {...register('name')}
          data-testid="webhook-name"
        />
      </div>
      {}
      {/* HTTP method */}
      <div className="flex mt-4 gap-x-4 text-tw-black-5">
        <div className="flex flex-col">
          <label className="mb-2">HTTP method</label>
          <Controller
            name="method"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className="w-32"
                options={options}
                selected={
                  watch('method').value
                    ? options.find((o) => o.value === watch('method')?.value)
                    : null
                }
                setSelected={(e) => {
                  const option = options.find((o) => o.value === e);
                  setValue('method', option, { shouldDirty: true });
                }}
                {...register('method')}
              />
            )}
          />
          {errors?.method && (
            <p className="flex items-center mt-1 text-xs text-tw-red-dd gap-x-1">
              <ErrorIcon />
              <span>Invalid method</span>
            </p>
          )}
        </div>
        {/* Destination URL */}
        <div className="flex flex-col flex-1">
          <label className="mb-2">Destination URL</label>
          <input
            placeholder="Enter destination URL"
            className={classNames(
              'w-full px-3 py-2.5 border-1 rounded focus:outline-none',
              errors?.url ? 'border-tw-red-dd' : 'border-tw-gray-c'
            )}
            {...register('url')}
            data-testid="webhook-url"
          />
          {errors?.url && (
            <p className="flex items-center mt-1 text-xs text-tw-red-dd gap-x-1">
              <ErrorIcon />
              <span>Invalid URL</span>
            </p>
          )}
        </div>
      </div>
      {/* Authorization Parameters */}
      <div className={classNames('px-3 py-3 mt-4 rounded border-1')}>
        <div className="flex items-center justify-between">
          <div className="text-base cursor-pointer">
            <span>Basic authorization</span>
            <span className="ml-2 text-tw-black-9">(optional)</span>
          </div>
          <Controller
            control={control}
            name="authEnabled"
            render={({ field }) => (
              <Toggle
                {...field}
                enabled={watch('authEnabled')}
                setEnabled={() => {
                  // reset on toggle
                  setValue('auth', { username: '', password: '' }, { shouldDirty: true });
                  setValue('authEnabled', !watch('authEnabled'), { shouldDirty: true });
                }}
                {...register('authEnabled')}
                data-testid="authenabled-switch"
              />
            )}
          />
        </div>
        {watch('authEnabled') && (
          <div className="mt-4">
            <div>
              <label className="mb-2">Username</label>
              <input
                placeholder="Enter username"
                className={classNames(
                  'w-full px-3 py-2.5 border-1 rounded border-tw-gray-c focus:outline-none',
                  errors?.auth?.username ? 'border-tw-red-dd' : 'border-tw-gray-c'
                )}
                defaultValue={watch('auth')?.username}
                {...register('auth.username')}
              />
            </div>
            <div className="mt-4">
              <label className="mb-2">Password</label>
              <input
                placeholder="Enter password"
                type="password"
                className={classNames(
                  'w-full px-3 py-2.5 border-1 rounded border-tw-gray-c focus:outline-none',
                  errors?.auth?.password ? 'border-tw-red-dd' : 'border-tw-gray-c'
                )}
                defaultValue={watch('auth')?.password}
                {...register('auth.password')}
              />
            </div>
          </div>
        )}
      </div>
      {/* Headers */}
      <div className={classNames('px-3 py-3 mt-4 border-1 rounded')}>
        <div className="flex items-center justify-between">
          <div className="text-base cursor-pointer">
            <span>Header parameters </span>
            <span className="ml-2 text-tw-black-9">(optional)</span>
          </div>
          <Controller
            control={control}
            name="headersEnabled"
            render={({ field }) => (
              <Toggle
                {...field}
                enabled={watch('headersEnabled')}
                setEnabled={() => {
                  // reset if there are no headers
                  if (!watch('headersEnabled')) {
                    setValue('headers', [{ key: '', value: '' }], { shouldDirty: true });
                  } else setValue('headers', [], { shouldDirty: true });
                  setValue('headersEnabled', !watch('headersEnabled'), { shouldDirty: true });
                }}
                {...register('headersEnabled')}
              />
            )}
          />
        </div>
        {watch('headersEnabled') && fields.length > 0 && (
          <>
            <div className="flex mt-4 text-sm">
              <div className="w-5/12 mr-4">Key</div>
              <div>Value</div>
            </div>
            {fields?.map((header, index) => {
              return (
                <div key={header.id} className="flex items-center mt-2">
                  <input
                    defaultValue={watch(`headers.${index}.key`)}
                    placeholder="Enter Key"
                    className={classNames(
                      'px-3 w-5/12 py-2.5 border-1 rounded focus:outline-none mr-4',
                      errors?.headers?.[index]?.key ? 'border-tw-red-dd' : 'border-tw-gray-c'
                    )}
                    {...register(`headers.${index}.key`, { required: true })}
                  />
                  <input
                    defaultValue={watch(`headers.${index}.value`)}
                    placeholder="Enter Value"
                    className={classNames(
                      'px-3 w-5/12 py-2.5 border-1 rounded  focus:outline-none mr-4',
                      errors?.headers?.[index]?.value ? 'border-tw-red-dd' : 'border-tw-gray-c'
                    )}
                    {...register(`headers.${index}.value`, { required: true })}
                  />
                  <div>
                    <DeleteIcon
                      onClick={() => {
                        // if there is one element, removing it should
                        // disable headers
                        if (fields.length === 1) {
                          setValue('headersEnabled', false);
                        }
                        remove(index);
                      }}
                      className="w-8 h-8 cursor-pointer fill-current text-tw-black-9 hover:text-tw-red-dd"
                    />
                  </div>
                </div>
              );
            })}
            <div
              className="flex items-center mt-4 text-base cursor-pointer text-tw-blue-primary gap-x-2"
              onClick={() => append({ key: '', value: '' })}
            >
              <PlusIcon className="fill-current" />
              <span>Add pair</span>
            </div>
          </>
        )}
      </div>
      {/* submit */}
      <div className={classNames('absolute right-0 w-full px-8 bottom-6', submitButtonsClassNames)}>
        <hr className="my-4 border-b border-tw-gray-eb" />
        <div className="flex justify-end text-base gap-x-4">
          <TertiaryButton
            onClick={() => {
              return router.goBack();
            }}
            data-testid="cancelButton"
            type="button"
          >
            Cancel
          </TertiaryButton>
          <PrimaryButton
            form="webhook"
            isLoading={creatingNewWebhook || editingWebhook}
            disabled={!isDirty}
            type="submit"
            className="w-46"
            data-testid="submitButton"
          >
            {isSubmitting ? `Creating` : isEdit ? `Save changes` : `Create Webhook`}
          </PrimaryButton>
        </div>
      </div>
    </form>
  );
}

WebhookForm.defaultProps = {
  isEdit: false,
  showButtons: true,
};

export default function WebhookPage() {
  const {
    location: { pathname },
  } = useHistory();
  const isEdit = pathname.indexOf('edit') > -1;
  const {
    connectedIntegrationId,
    integrationId,
  }: { connectedIntegrationId?: string; integrationId?: string } = useParams();
  const { track } = useAnalytics();

  // page query when you're editing a webhook
  const { isLoading } = useGetWebhookById(connectedIntegrationId, isEdit);

  if (isLoading) {
    return (
      <div className="relative w-1/2 px-8 mx-auto mt-8 bg-white rounded shadow-sm text-tw-black-5 h-4/5">
        <div className="p-4 pt-8 h-88">
          <div className="w-2/6 h-10 mt-4 bg-tw-gray-eb animate-pulse"></div>
          <div className="w-full h-10 mt-8 bg-tw-gray-eb animate-pulse"></div>
          <div className="flex mt-8">
            <div className="w-2/6 h-10 bg-tw-gray-eb animate-pulse"></div>
            <div className="w-4/6 h-10 ml-4 bg-tw-gray-eb animate-pulse"></div>
          </div>
          <div className="w-full h-24 mt-8 bg-tw-gray-eb animate-pulse"></div>
          <div className="w-full h-24 mt-8 bg-tw-gray-eb animate-pulse"></div>
        </div>
      </div>
    );
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={(e) =>
        track(ErrorAnalyticsEvents.ERROR_OCCURED, {
          error: e.message,
        })
      }
    >
      <div
        className="relative w-1/2 px-8 py-4 mx-auto mt-8 bg-white rounded shadow-sm text-tw-black-5 h-4/5"
        data-testid="webhook-page"
      >
        <div className="p-4 overflow-y-scroll h-88 mb-36">
          <div>
            <h2 className="text-xl text-black">{isEdit ? 'Edit webhook' : 'Create webhook'}</h2>
            <hr className="my-4 border-b border-tw-gray-eb" />
          </div>
          <WebhookForm
            integrationId={integrationId}
            connectedIntegrationId={connectedIntegrationId}
            isEdit={isEdit}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
}
