import { Fragment, HTMLAttributes } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ReactComponent as DropdownArrowIcon } from '../../assets/images/dropdown-arrow.svg';
import { classNames } from '../../utils/common';

interface Option {
  value: string;
  text: string;
}

interface SelectProps extends HTMLAttributes<HTMLDivElement> {
  options?: Array<Option>;
  selected: Option;
  setSelected: (e: any) => void;
  optionsURL?: string;
}

export const Select = ({ className, options, selected, setSelected }: SelectProps) => {
  return (
    <div className={classNames(className)}>
      <Listbox value={selected?.value} onChange={setSelected}>
        <div className="relative">
          <Listbox.Button className="flex items-center justify-between relative w-full px-2.5 py-2.5 rounded cursor-pointer focus:outline-none border-1 border-tw-gray-c text-tw-black-3">
            <span
              className={classNames(
                'block truncate',
                !selected ? 'text-tw-black-9' : 'text-tw-black-3'
              )}
            >
              {!selected ? 'Select one' : selected.text}
            </span>
            <DropdownArrowIcon className="fill-current text-tw-black-1" />
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 focus:outline-none">
              {options.map((option, optionIdx) => (
                <Listbox.Option
                  key={optionIdx}
                  className={() =>
                    ` text-tw-black-5 cursor-pointer select-none relative py-2.5 px-2.5`
                  }
                  value={option.value}
                >
                  {() => (
                    <>
                      <span className={`font-medium block truncate`}>{option.text}</span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default Select;
