import React, { useState, useEffect } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { TertiaryButton, PrimaryButton } from 'ui-components/inputs/Buttons';
import { SortFilter, sortFilters, useOpportunityListStore } from './store/OpportunityListStore';
import { usePQLFilter } from './hooks/usePQLFilter';
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';
import { SortFilerIcon } from 'ui-components/data-display/Icons';
import { useAnalytics } from 'telemetry';
import { AnalyticsEvents, AnalyticsConst } from 'telemetry/constants';

export default function SortOpportunities() {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const { queryParams } = useOpportunityListStore();

  const { track } = useAnalytics();

  const [currentSortFilter, setCurrentSortFilter] = useState<SortFilter>(sortFilters[0]);
  // custom hook to handle the PQL filter by sort
  const { updateSearchParams } = usePQLFilter();

  // re-validate on dropdown open.
  useEffect(() => {
    if (dropdownOpen) {
      const s = sortFilters.find(
        (sf) =>
          sf.value.toLowerCase() === queryParams.selectedOrderFilter.filter.value.toLowerCase()
      );
      setCurrentSortFilter(s);
    }
  }, [queryParams.selectedOrderFilter.filter.value, dropdownOpen]);

  return (
    <div className="mt-8">
      <DropdownMenu.Root open={dropdownOpen} onOpenChange={() => setDropdownOpen(!dropdownOpen)}>
        <DropdownMenu.Trigger className="ml-2">
          <span className="w-full min-w-full border-1 flex items-center whitespace-nowrap border-tw-gray-ed rounded-md ml-2 py-3 px-4">
            <SortFilerIcon className="fill-current text-tw-black-5" />
            <div className="ml-3.5">
              Sort{' '}
              {queryParams.selectedOrderFilter.filter.label &&
                `: ${queryParams.selectedOrderFilter.filter.label}`}
            </div>
          </span>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          key="dropdown-menu-content"
          className="ml-4 mt-4 bg-white shadow-lg rounded-lg w-64"
        >
          <div className="px-5 pt-4 font-medium text-tw-black-3">Sort by</div>
          <div className="pl-5 pr-20 pb-8 text-tw-black-5">
            <DropdownPrimitive.RadioGroup className="mt-4">
              {sortFilters.map((f: SortFilter) => (
                <div
                  key={`${f.value}${f.label}`}
                  className="flex items-center gap-x-3 my-4"
                  onClick={() => setCurrentSortFilter(f)}
                >
                  <input
                    className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 cursor-pointer"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    onChange={() => setCurrentSortFilter(f)}
                    checked={currentSortFilter?.label.toLowerCase() === f.label.toLowerCase()}
                  />
                  <div className="cursor-pointer">{f.label}</div>
                </div>
              ))}
            </DropdownPrimitive.RadioGroup>
          </div>
          <hr className="p-0" />
          <div className="bg-tw-white-ff flex w-full min-h-max shadow-b-lg rounded-lg">
            <div className="py-2 flex items-center ml-auto">
              <TertiaryButton
                className="ml-auto mr-3"
                onClick={() => {
                  // reset the order selection
                  // close the dialog
                  updateSearchParams({
                    ...queryParams,
                    selectedOrderFilter: {
                      ...queryParams.selectedOrderFilter,
                      filter: sortFilters[0],
                    },
                  });
                  setDropdownOpen(false);
                }}
              >
                Reset
              </TertiaryButton>
              <PrimaryButton
                className="ml-auto mr-3"
                onClick={() => {
                  // applies the current sort filter
                  // and closes the dialog
                  updateSearchParams({
                    ...queryParams,
                    selectedOrderFilter: {
                      ...queryParams.selectedOrderFilter,
                      filter: currentSortFilter,
                    },
                  });
                  track(AnalyticsEvents.OPPORTUNITY_FILTER, {
                    [AnalyticsConst.TYPE]: 'sort',
                    [AnalyticsConst.VALUE]: currentSortFilter,
                  });
                  setDropdownOpen(false);
                }}
              >
                Apply
              </PrimaryButton>
            </div>
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
}
