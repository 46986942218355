import { Organization, Role, SimilarOrganization } from 'api-client';
import { ACCOUNT_ID, ACCOUNT_SCHEMANAME, AUTH_TOKEN, REFRESH_TOKEN } from 'utils/globalTypes';
import create from 'zustand';

type LoggedInUser = {
  id: string | number;
  firstName: string;
  lastName: string;
  email: string;
  isStaff: boolean;
  avatarName: string;
  organizations: Array<Organization>;
  currentOrganization: Organization & Record<string, any>;
  currentRole?: Role;
  defaultOrganization: Organization;
  isTelemetryEnabled?: boolean;
  hasSimilarOrganization: boolean;
  similarOrganizations: SimilarOrganization[];
};

type AuthState = {
  user: LoggedInUser;
  isLoggedIn: boolean;
};

type AuthStore = {
  setAuthState: (data: Partial<AuthState>) => void;
  setAuthToken: (accessToken: string) => void;
  setRefreshToken: (refreshToken: string) => void;
  removeRefreshToken: () => void;
  removeAuthToken: () => void;
  getRefreshToken: () => string | null;
  getAccountId: () => string | null;
  getAccountSchemaName: () => string | null;
  setAccountId: (accountId: string, accountSchemaName: string) => void;
  removeAccountId: () => void;
  getAuthToken: () => string | null;
} & AuthState;

export const useAuthStore = create<AuthStore>((set) => ({
  user: null,
  isLoggedIn: false,
  setAuthState: (data) => {
    set((state) => ({ ...state, ...data }));
  },
  setAuthToken: (accessToken: string) => {
    localStorage.setItem(AUTH_TOKEN, accessToken);
    set((state) => ({ ...state, isLoggedIn: true }));
  },
  setRefreshToken: (refreshToken: string) => {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  },
  removeRefreshToken: () => {
    localStorage.removeItem(REFRESH_TOKEN);
    set((state) => ({ ...state, isLoggedIn: false }));
  },
  removeAuthToken: () => {
    localStorage.removeItem(AUTH_TOKEN);
  },
  getRefreshToken: () => localStorage.getItem(REFRESH_TOKEN),
  getAuthToken: () => localStorage.getItem(AUTH_TOKEN),
  // we are storing accountId in sessionStorage
  getAccountId: () =>
    sessionStorage.getItem(ACCOUNT_ID) === 'null' ? null : sessionStorage.getItem(ACCOUNT_ID),
  getAccountSchemaName: () =>
    sessionStorage.getItem(ACCOUNT_SCHEMANAME) === 'null'
      ? null
      : sessionStorage.getItem(ACCOUNT_SCHEMANAME),
  // removing from sessionStorage
  removeAccountId: () => {
    sessionStorage.removeItem(ACCOUNT_ID);
    localStorage.removeItem(ACCOUNT_ID);

    sessionStorage.removeItem(ACCOUNT_SCHEMANAME);
    localStorage.removeItem(ACCOUNT_SCHEMANAME);
  },
  // we are storing accountId in sessionStorage and localStorage
  setAccountId: (accountId: string, accountSchemaName: string) => {
    sessionStorage.setItem(ACCOUNT_ID, accountId);
    localStorage.setItem(ACCOUNT_ID, accountId);

    sessionStorage.setItem(ACCOUNT_SCHEMANAME, accountSchemaName);
    localStorage.setItem(ACCOUNT_SCHEMANAME, accountSchemaName);
  },
}));
