import { FC, useCallback, useEffect, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import { useAuthStore } from 'hooks/useAuthStore';
import { Redirect, Route, useParams } from 'react-router-dom';
import { useApiClient } from 'api-client';
import { routes } from './clientRoutes';
import useGetAllOrganizations from 'pages/login/hooks/useGetAllOrganizations';
import Loader from 'ui-components/feedback/Loader';
import NotFound from './NotFound';
import { AxiosInstance } from 'axios';

interface Props {
  render: any;
  path: string;
}

const TenantRoute: FC<Props> = (props) => {
  const { data: allOrganizations, isLoading: loadingOrganizations } = useGetAllOrganizations();

  const { user, isLoading, isError } = useAuth();
  const { setAccountId } = useAuthStore();
  // TODO: Cleanup this schemaName -> orgHash
  const { schemaName: schemaNameFromUrlParams } = useParams<{ schemaName: string }>();
  const client = useApiClient();

  // Strategy: hasAccess is initially null and gets true/false after getting populated.
  // 1. schemaName from url params && user.organizations contains schemaName: hasAccess = true
  // 2. schemaName from url params && user.isStaff && allOrgs contains schemaName: hasAccess = true
  // 3. user.isStaff is false and user.organizations does not contain schemaName: hasAccess = false
  const [hasAccess, setHasAccess] = useState<boolean | null>(null);

  const updateAccountIdToStorageAndHeaders = useCallback(
    (accountId: string, apiClient: AxiosInstance, schemaName: string) => {
      setAccountId(accountId, schemaName);
      apiClient.defaults.headers.common['TL-ORG'] = schemaName;
    },
    [setAccountId]
  );

  useEffect(() => {
    let accountIdToPersist: string | null = null,
      schemaName: string | null = null;

    const shouldUpdateAccountId = user?.isStaff ? !!user && !!allOrganizations : !!user;

    if (shouldUpdateAccountId) {
      // always true
      if (schemaNameFromUrlParams) {
        const orgInUserProfile = user?.organizations.find(
          (org) => org.orgId === schemaNameFromUrlParams
        );
        const orgPresentInGlobalList = allOrganizations?.find(
          (org) => org.orgId === schemaNameFromUrlParams
        );

        // Case 1: When there is accountId in URL params and the same org
        // exists in list of orgs, take the user to the dashboard.
        if (orgInUserProfile) {
          accountIdToPersist = schemaNameFromUrlParams;
          schemaName = orgInUserProfile.schemaName;

          setHasAccess(true);
          updateAccountIdToStorageAndHeaders(accountIdToPersist, client, schemaName);
        }

        // Bypass all orgs for isStaff users.
        else if (user?.isStaff && orgPresentInGlobalList) {
          accountIdToPersist = schemaNameFromUrlParams;
          schemaName = orgPresentInGlobalList.schemaName;

          setHasAccess(true);
          updateAccountIdToStorageAndHeaders(accountIdToPersist, client, schemaName);
        }

        // Resolve hasAccess to hide the loader if nothing matches.
        else {
          setHasAccess(false);
        }
      }
    }
  }, [allOrganizations, client, schemaNameFromUrlParams, updateAccountIdToStorageAndHeaders, user]);

  if (isError) {
    return <Redirect to={routes.login} />;
  }

  if (isLoading || !user || loadingOrganizations || hasAccess === null) {
    return (
      <div className="grid h-screen place-items-center">
        <Loader height={100} width={100} />
      </div>
    );
  }

  if (hasAccess === true) {
    return <Route {...props} />;
  }

  return <NotFound />;
};

export default TenantRoute;
