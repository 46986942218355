import { toast as toastify } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { useClient } from '../../../api-client';
import { useAnalytics } from '../../../telemetry';
import { AnalyticsEvents } from '../../../telemetry/constants';
import { Roles } from '../Roles';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';

export type EmailInvite = {
  email: string;
  role: Roles;
};

export default function useSendInvite() {
  const queryClient = useQueryClient();
  const { track } = useAnalytics();
  const client = useClient();
  return useMutation((emails: Array<EmailInvite>) => client.sendInvite(emails), {
    onSuccess: () => {
      toastify(<SuccessToast description="Invite has been sent" />, {
        type: 'success',
      });
      queryClient.refetchQueries(['pendingInvites']);
    },
    onError: (error) => {
      const err = error['response']['data']['detail'];
      toastify(<ErrorToast description={err ?? 'There was an error in sending the invite'} />, {
        type: 'error',
      });
      track(AnalyticsEvents.TEAM_MEMBER_INVITE_SEND_FAILED);
    },
  });
}
