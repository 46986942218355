import { Organization, OrganizationDetails, useClient } from 'api-client';
import { useAuthStore } from 'hooks/useAuthStore';
import { useMutation, useQueryClient } from 'react-query';

type UpdateOrganizationData = {
  schemaName: string;
} & Partial<Organization>;

export default function useUpdateOrganization() {
  const { getAccountId, getAccountSchemaName } = useAuthStore();
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdateOrganizationData) => client.updateOrganization(data.schemaName, data),
    {
      onSuccess: async (data, variables) => {
        await queryClient.cancelQueries('organization');
        const previousOrgData = queryClient.getQueryData(['organization', getAccountSchemaName()]);
        if (previousOrgData) {
          queryClient.setQueryData(
            ['organization', variables.schemaName],
            (old: OrganizationDetails) => ({
              ...old,
              ...data,
            })
          );
        }
      },
      onSettled: (variables) => {
        queryClient.refetchQueries(['organization', variables.schemaName]);
        queryClient.refetchQueries(['user', getAccountId()]);
      },
    }
  );
}
