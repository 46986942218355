import { useAuth } from 'hooks/useAuth';
import useOpportunityQuery from 'pages/pql-workflow/hooks/useOpportunityQuery';
import { FiltersPQLSections, useFiltersPQLStore } from 'pages/pql-workflow/store/PQLFiltersStore';
import { useAnalytics } from 'telemetry';
import { AnalyticsConst, AnalyticsEvents } from 'telemetry/constants';
import { FiltersRoundIcon } from 'ui-components/data-display/Icons';
import { PrimaryButton, SecondaryButton } from 'ui-components/inputs/Buttons';
import Capacity from './Capacity';
import Filters from './Filters';
import QualifyingTextAnimated from './QualifyingTextAnimated';

// next "step" of the workflow.
export default function FilterSubSections() {
  const { overviewOpen, setOverviewOpen, setCurrentSection, setSectionsMap, sectionsMap } =
    useFiltersPQLStore();

  const { data, isLoading, isError } = useOpportunityQuery();

  const { track } = useAnalytics();
  const { user } = useAuth();

  return (
    <div className="bg-white rounded-b-xl">
      {overviewOpen && (
        <div className="flex flex-col items-center px-6">
          <FiltersRoundIcon className="mt-4" />
          <div className="flex flex-col w-full mt-6 text-center">
            {isLoading ? (
              <div className="h-6 mx-auto w-96 animate-pulse bg-tw-gray-eb"></div>
            ) : (
              <p className="text-base text-tw-black-5">
                We are down to {data && data?.count.toLocaleString('en')} leads. Add further nuance
                - segment with product usage and demographic filters.
              </p>
            )}
            <QualifyingTextAnimated />
          </div>
          <div className="flex w-full pb-6 mt-24 gap-x-4">
            <SecondaryButton
              className="w-1/2 py-2 text-tw-blue-0d"
              onClick={() => {
                setOverviewOpen(false);
                setCurrentSection(FiltersPQLSections.CAPACITY);
                setSectionsMap({
                  ...sectionsMap,
                  [FiltersPQLSections.FILTERS]: true,
                });
                track(AnalyticsEvents.OPPORTUNITY_FILTER_SKIPPED, {
                  [AnalyticsConst.USER_EMAIL]: user.email,
                  [AnalyticsConst.USER_NAME]: `${user.firstName} ${user.lastName}`,
                  [AnalyticsConst.ACCOUNT_NAME]: user.currentOrganization.name,
                });
              }}
            >
              Skip
            </SecondaryButton>
            <PrimaryButton
              disabled={isLoading || isError}
              onClick={() => {
                setOverviewOpen(!overviewOpen);
              }}
              className="w-1/2 py-2 product-tour-7"
            >
              Segment further
            </PrimaryButton>
          </div>
        </div>
      )}
      {!overviewOpen && (
        <>
          <Filters />
          <Capacity />
        </>
      )}
    </div>
  );
}
