import * as Dialog from '@radix-ui/react-dialog';
import { keyframes, styled } from '@stitches/react';
import { motion } from 'framer-motion';
import { classNames } from 'utils/common';
import { useEffect } from 'react';
import { CrossIconWithoutBg } from '../data-display/Icons';

type TransitionModalProps = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  widthClassName?: string;
  primaryButton?: React.ReactNode;
  secondaryButton?: React.ReactNode;
  hideButtons?: boolean;
  paddingX: string;
};

export enum ModalWidth {
  lg = 'w-1/2',
  md = 'w-1/3',
  sm = 'w-1/4',
}

const fadeIn = keyframes({
  from: { opacity: '0' },
  to: { opacity: '1' },
});

const fadeOut = keyframes({
  from: { opacity: '1' },
  to: { opacity: '0' },
});

const StyledOverlay = styled(Dialog.Overlay, {
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,

  '&[data-state="open"]': {
    animation: `${fadeIn} 150ms cubic-bezier(0.22, 1, 0.36, 1)`,
  },

  '&[data-state="closed"]': {
    animation: `${fadeOut} 150ms cubic-bezier(0.22, 1, 0.36, 1)`,
  },
  length: 0,
});

const slideIn = keyframes({
  from: { transform: 'translate3d(100%,0,0)' },
  to: { transform: 'translate3d(0,0,0)' },
});

const slideOut = keyframes({
  from: { transform: 'translate3d(0,0,0)' },
  to: { transform: 'translate3d(100%,0,0)' },
});

const StyledContent = styled(Dialog.Content, {
  '&[data-state="open"]': {
    animation: `${slideIn} 750ms cubic-bezier(0.22, 1, 0.36, 1)`,
  },

  '&[data-state="closed"]': {
    animation: `${slideOut} 750ms cubic-bezier(0.22, 1, 0.36, 1)`,
  },
  length: 0,
});

const Drawer = ({
  open,
  onClose,
  children,
  widthClassName,
  primaryButton,
  secondaryButton,
  hideButtons,
  paddingX,
}: TransitionModalProps) => {
  /**
   * Hide the intercom widget when the drawer is open
   */
  useEffect(() => {
    if (open) {
      window.Intercom('update', {
        hide_default_launcher: true,
      });
    } else {
      window.Intercom('update', {
        hide_default_launcher: false,
      });
    }
  }, [open]);

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal forceMount>
        <>
          <StyledOverlay className="fixed inset-0 z-30 flex flex-col items-center justify-center bg-tw-black-50"></StyledOverlay>
          <StyledContent
            className={classNames('bg-white', 'fixed z-40 right-0 top-0 h-screen', widthClassName)}
          >
            <div className="relative flex flex-col w-full h-full">
              <div
                className={classNames(
                  'overflow-y-auto',
                  paddingX,
                  hideButtons ? 'h-full' : 'h-[90vh]'
                )}
              >
                {children}
              </div>
              {open && (
                <motion.div
                  onClick={onClose}
                  className="absolute p-3 bg-white rounded-full cursor-pointer -left-12 top-4"
                  whileHover={{
                    rotate: 90,
                  }}
                >
                  <CrossIconWithoutBg className="w-3 fill-current text-tw-gray-6a" />
                </motion.div>
              )}
              {!hideButtons && (
                <div className="w-full bg-white grow ">
                  <hr className="w-full" />
                  <div className="flex items-center justify-end h-full px-8 gap-x-4">
                    {secondaryButton}
                    {primaryButton}
                  </div>
                </div>
              )}
            </div>
          </StyledContent>
        </>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

Drawer.defaultProps = {
  onClose: () => {},
  open: false,
  widthClassName: 'w-1/2',
  paddingX: 'px-8',
};

export default Drawer;
