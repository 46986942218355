import { toast as toastify } from 'react-toastify';
import { Menu, Transition } from '@headlessui/react';
import { ReactComponent as SettingsIcon } from 'assets/images/settingsIcon.svg';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';
import Toggle from 'ui-components/inputs/Switch';
import dayjs from 'dayjs';
import { useAuthStore } from 'hooks/useAuthStore';
import useBulkStopPlaybooks from 'pages/integrations/hooks/useBulkStopPlaybooks';
import usePlaybooksByDestination from 'pages/integrations/hooks/usePlaybooksByDestination';
import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { trim } from 'utils/helpers';
import { ConnectedIntegrationStatus, IntegrationType, useClient } from '../../../api-client';
import {
  ArrowRight,
  FilledArrowUp,
  LockIcon,
  SpinnerIcon,
} from '../../../ui-components/data-display/Icons';
import Modal from '../../../ui-components/util/modal';
import { classNames, getIntegrationConnectorType } from '../../../utils/common';
import useRole from '../../../hooks/useRole';
import { useAnalytics } from '../../../telemetry';
import { AnalyticsConst, AnalyticsEvents } from '../../../telemetry/constants';
import useDeleteIntegration from '../hooks/useDeleteIntegration';
import useIntegrations from '../hooks/useIntegrations';
import DeleteIntegrationModal from './DeleteIntegrationModal';
import IntegrationIcon from './IntegrationIcon';
import RequestedAppModal from './RequestedAppModal';
import RequestIntegrationModal from './RequestIntegrationModal';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';

type IntegrationItemProps = {
  type?: IntegrationType;
  name: string;
  isConnected?: boolean;
  integrationId?: number;
  connectedIntegrationId?: number;
  active?: boolean;
  isSource?: boolean;
  createdAt?: string;
  inactive?: boolean;
  locked?: boolean;
  logoUrl?: string;
  status?: string;
  requestedApp?: boolean;
  scrollCallback?: any;
  requestedAppRef?: any;
  integrationName?: string;
};

export default function IntegrationItem({
  type,
  name,
  isConnected,
  integrationId,
  connectedIntegrationId,
  active,
  isSource,
  createdAt,
  inactive,
  logoUrl,
  status,
  requestedApp,
  scrollCallback,
  requestedAppRef,
  locked,
  integrationName,
}: IntegrationItemProps) {
  const { getAccountId } = useAuthStore();

  const [showAccessModal, setShowAccessModal] = useState<boolean>(false);
  const { track } = useAnalytics();
  const client = useClient();
  const router = useHistory();
  const { isReadOnly } = useRole();
  const deleteApplication = useDeleteIntegration();
  const [isIntActive, setIsIntActive] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteOpenDialog, setIsDeleteOpenDialog] = React.useState(false);
  const integrations = useIntegrations();
  const [isRequestAppModalOpen, setIsRequestAppModalOpen] = React.useState(false);
  const [isRequestedAppModalOpen, setIsRequestedAppModalOpen] = React.useState(false);
  const [showRequestBtn, setShowRequestBtn] = React.useState(false); // for inactive apps

  useEffect(() => {
    setIsIntActive(active);
  }, [active]);

  const switchAction = async () => {
    if (isIntActive) {
      await deactivateIntegration();
      setIsIntActive(false);
      toastify(<SuccessToast description="App has been disabled" />, {
        type: 'success',
      });
      return;
    }
    try {
      await client.toggleIntegration(connectedIntegrationId, true);
      setIsIntActive(true);
      toastify(<SuccessToast description="App has been enabled" />, {
        type: 'success',
      });
      track(AnalyticsEvents.CONNECTED_INTEGRATION_ACTIVE, {
        [AnalyticsConst.INTEGRATION_NAME]: name,
        [AnalyticsConst.INTEGRATION_TYPE]: type,
        [AnalyticsConst.CONNECTOR_TYPE]: getIntegrationConnectorType(integrations.data, type),
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsIntActive(false);
    } finally {
      setIsLoading(false);
    }
  };

  const deactivateIntegration = async () => {
    try {
      setIsLoading(true);
      await client.toggleIntegration(connectedIntegrationId, false);
      setIsIntActive(false);
      track(AnalyticsEvents.CONNECTED_INTEGRATION_INACTIVE, {
        [AnalyticsConst.INTEGRATION_NAME]: name,
        [AnalyticsConst.INTEGRATION_TYPE]: type,
        [AnalyticsConst.CONNECTOR_TYPE]: getIntegrationConnectorType(integrations.data, type),
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsIntActive(true);
    } finally {
      setIsLoading(false);
    }
  };

  const editApp = () => {
    router.push({
      pathname: !isSource
        ? `/${getAccountId()}/integrations/${connectedIntegrationId}/edit`
        : `/${getAccountId()}/source/${type}/edit/${connectedIntegrationId}`,
      state: {
        name: name,
        connectedIntegrationId: connectedIntegrationId,
        type: type,
        isIntActive: isIntActive,
      },
    });
  };

  const affectedPlaybooksMutation = usePlaybooksByDestination(integrationId);
  const stopAffectedPlaybooks = useBulkStopPlaybooks();

  useEffect(() => {
    if (isDeleteOpenDialog) affectedPlaybooksMutation.mutate();
  }, [isDeleteOpenDialog]);

  const handleShowError = () => {
    toastify(<ErrorToast description="Unable to delete app" />, {
      type: 'error',
    });
  };
  // TODO: combine deleteApp handler for this component and addIntegrationPage component
  const deleteApp = () => {
    try {
      if (affectedPlaybooksMutation?.data?.data?.length)
        stopAffectedPlaybooks.mutate(
          affectedPlaybooksMutation?.data?.data?.map((pb) => pb.id),
          {
            onError: handleShowError,
            onSuccess: () => {
              deleteApplication.mutate(connectedIntegrationId, {
                onError: () => {
                  handleShowError();
                  track(AnalyticsEvents.CONNECTED_INTEGRATION_DELETED_FAILED, {
                    [AnalyticsConst.INTEGRATION_NAME]: name,
                    [AnalyticsConst.INTEGRATION_TYPE]: type,
                    [AnalyticsConst.CONNECTOR_TYPE]: getIntegrationConnectorType(
                      integrations.data,
                      type
                    ),
                  });
                },
                onSuccess: () => {
                  setIsDeleteOpenDialog(false);
                },
              });
            },
          }
        );
      else {
        deleteApplication.mutate(connectedIntegrationId, {
          onError: () => {
            handleShowError();
            track(AnalyticsEvents.CONNECTED_INTEGRATION_DELETED_FAILED, {
              [AnalyticsConst.INTEGRATION_NAME]: name,
              [AnalyticsConst.INTEGRATION_TYPE]: type,
              [AnalyticsConst.CONNECTOR_TYPE]: getIntegrationConnectorType(integrations.data, type),
            });
          },
          onSuccess: () => {
            setIsDeleteOpenDialog(false);
          },
        });
      }
    } catch (e) {
      handleShowError();
    }
  };
  return (
    <div className="group">
      <div
        className={classNames(
          'relative flex items-center p-4 rounded-lg border-1 border-tw-gray-eb',
          isConnected ? 'max-w-lg 2xl:max-w-full' : 'max-w-xs 2xl:max-w-full',
          locked ? 'cursor-not-allowed' : 'cursor-pointer'
        )}
        onMouseOver={() => {
          if (inactive) setShowRequestBtn(true);
        }}
        onMouseLeave={() => {
          if (inactive) setShowRequestBtn(false);
        }}
        onClick={() => {
          if (inactive) {
            setIsRequestAppModalOpen(true);
          }
          if (requestedApp) {
            setIsRequestedAppModalOpen(true);
          }
          // If an integration is
          // connected, we open the edit-integration page,
          // else we open the add-integration page
          if (!inactive && !requestedApp) {
            if (!locked) {
              if (isConnected) {
                editApp();
              } else {
                if (isReadOnly) {
                  setShowAccessModal(true);
                } else {
                  track(AnalyticsEvents.ADD_INTEGRATION_CLICKED, {
                    [AnalyticsConst.TYPE]: type,
                  });
                  router.push(
                    isSource
                      ? `/${getAccountId()}/source/${type}/add`
                      : `/${getAccountId()}/integrations/${integrationId}/add`
                  );
                }
              }
            }
          }
        }}
      >
        <div
          className={classNames(
            'flex items-center space-x-2 bg-white h-18',
            locked && 'opacity-50'
          )}
        >
          <div className="flex flex-col justify-center w-12 h-12">
            <IntegrationIcon
              type={type}
              logoUrl={logoUrl}
              height={integrationName === 'Customer.io' || integrationName === 'Zoho' ? 'auto' : 40}
              width={40}
            />
          </div>
          <div className="pr-4">
            <div className="font-medium text-tw-black-1">{trim(name, 16)}</div>
            {isConnected && (
              <div className="flex flex-wrap-reverse pr-12 mr-1 text-xs not-italic leading-4 text-tw-black-7">
                {`Connected on ${dayjs(createdAt).tz().date()} ${dayjs(createdAt)
                  .tz()
                  .format('MMM')}, ${dayjs(createdAt).tz().year()}`}
              </div>
            )}
          </div>
        </div>
        {locked && (
          <div>
            <LockIcon className="absolute w-4 h-4 fill-current text-tw-gray-c top-2 right-2" />
          </div>
        )}
        {showRequestBtn && inactive && (
          <div
            className="absolute px-2 py-1 rounded top-2 right-2 bg-tw-gray-f5"
            onClick={() => setIsRequestAppModalOpen(true)}
          >
            <div className="flex space-x-1 place-items-center">
              <FilledArrowUp className="fill-current" />
              <div className="text-xs font-medium uppercase text-tw-black-5">request</div>
            </div>
          </div>
        )}
        {requestedApp && (
          <div
            className="absolute px-2 py-1 rounded top-2 right-2 bg-tw-gray-f5"
            onClick={() => setIsRequestedAppModalOpen(true)}
          >
            <div className="flex space-x-1 place-items-center text-tw-blue-0d">
              <FilledArrowUp className="fill-current text-tw-blue-0d" />
              <div className="text-xs font-medium uppercase">requested</div>
            </div>
          </div>
        )}
        <div className="absolute right-0">
          <div className="flex px-2 space-x-2 place-items-center">
            {!isReadOnly && isConnected && (
              <>
                {/*Show settings menu for connected integration*/}
                <div>
                  <Menu as="div" className="relative">
                    <div>
                      <Menu.Button className="align-middle">
                        <div className="py-2 mr-2 opacity-0 group-hover:opacity-100">
                          <SettingsIcon />
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 w-40 p-4 mt-2 space-y-3 origin-top-right bg-white rounded-md shadow-lg ring-black focus:outline-none text-tw-black-5">
                        <div className="cursor-pointer">
                          <Menu.Item>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                editApp();
                              }}
                            >
                              <p className="text-base font-normal">Edit</p>
                            </div>
                          </Menu.Item>
                        </div>
                        <div className="cursor-pointer">
                          <Menu.Item>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsDeleteOpenDialog(true);
                              }}
                            >
                              <p className="text-base font-normal">Delete</p>
                            </div>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>

                {/*Show toggle*/}
                <div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {isLoading ? (
                      <SpinnerIcon className="w-8 h-8 fill-current animate-spin text-tw-blue-0d" />
                    ) : (
                      <div onClick={switchAction}>
                        {/* <Switch isChecked={isIntActive} id="email-alerts" /> */}
                        {status === ConnectedIntegrationStatus.READY && (
                          <Toggle enabled={isIntActive} />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        open={showAccessModal}
        onClose={() => {
          setShowAccessModal(false);
        }}
        hrRequired={false}
      >
        <div className="p-4">
          <p className="text-base font-medium text-tw-black-1">Want to integrate an app?</p>
          <p className="mt-1 text-sm text-tw-gray-7">
            Only team members with {`"Admin" or "Read & Write"`} role can add an integration. Please
            get in touch with your admin.
          </p>
          <div
            className="flex mt-3 mb-2 cursor-pointer place-items-center"
            onClick={() => router.push(`/${getAccountId()}/settings/team-members`)}
          >
            <p className="text-sm font-medium text-tw-blue-0d">Who is my admin?</p>
            <ArrowRight className="ml-2 fill-current text-tw-blue-0d" />
          </div>
        </div>
      </Modal>

      <DeleteIntegrationModal
        isDeleteOpenDialog={isDeleteOpenDialog}
        setIsDeleteOpenDialog={setIsDeleteOpenDialog}
        deleteApp={deleteApp}
        integrationName={name}
        isLoading={deleteApplication.isLoading || stopAffectedPlaybooks.isLoading}
        affectedPlaybooks={affectedPlaybooksMutation?.data?.data ?? null}
        affectedPlaybooksLoading={affectedPlaybooksMutation?.isLoading}
      />

      <RequestedAppModal
        name={name}
        showModal={isRequestedAppModalOpen}
        setShowModal={setIsRequestedAppModalOpen}
        type={type}
        logoUrl={logoUrl}
      />

      <RequestIntegrationModal
        id={integrationId}
        name={name}
        showModal={isRequestAppModalOpen}
        setShowModal={setIsRequestAppModalOpen}
        type={type}
        logoUrl={logoUrl}
        scrollCallback={scrollCallback}
        requestedAppRef={requestedAppRef}
      />
    </div>
  );
}

IntegrationItem.defaultProps = {
  inactive: false,
  requestedApp: false,
};
