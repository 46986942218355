import { CheckIconBasic, EditIcon } from 'ui-components/data-display/Icons';
import { ReactNode } from 'react';

type AccordionHeaderProps = {
  onClick: () => void;
  children: ReactNode;
};

export default function AccordionHeader({ onClick, children }: AccordionHeaderProps) {
  return (
    <div className="flex items-center justify-between py-4 group" onClick={onClick}>
      <div className="flex items-center">
        <CheckIconBasic className="w-4 fill-current text-tw-green-39" />
        <div className="ml-2.5 flex items-center text-md text-tw-black-5 mt-0.5">{children}</div>
      </div>
      <div className="items-center hidden group-hover:flex gap-x-2 text-tw-black-5">
        <EditIcon />
        <span>Edit</span>
      </div>
    </div>
  );
}
