import openInNewTab from 'assets/svg/components/open-in-new-tab.svg';
import { AccountFields, UserFields } from 'pages/users-and-accounts/fields';
import { useAuth } from 'hooks/useAuth';
import { useAuthStore } from 'hooks/useAuthStore';
import useModels from 'hooks/useModels';
import useEntityDetails from 'pages/users-and-accounts/accounts/hooks/useAccountDetails';
import * as React from 'react';
import Card from '../../../../ui-components/surfaces/cards/Card';
import {
  convertAmount,
  formatForCellDisplay,
  getPlanIntervalShort,
  getSourceImg,
  toSentence,
} from '../../../../utils/common';
import useEntitiesQuery from '../../../../hooks/useEntitiesQuery';
import { Entity } from '../types';

interface HeroContentProps {
  isPaid: boolean;
  planName: string;
  planInterval: string;
  email: string;
  firstPaidPlanPurchasedAt: string;
  country: string;
  createdAt: string;
  userId: number | string;
  firstName: string;
  lastName: string;
  billingAmount: number;
  source: string;
  subscriptionDate: string;
}

function extractObjectFromUserDetails(data, currentOrganization) {
  let ret = {};
  const fields = Object.values(UserFields)
    .filter((f) => currentOrganization[f])
    .map((f) => currentOrganization[f]);
  data?.userDetails.forEach((d) => {
    if (fields.includes(d.key)) {
      ret[d.key] = d.value;
    }
  });
  return ret;
}

const HeroContent: React.FC<HeroContentProps> = (props) => {
  const { getAccountId } = useAuthStore();
  const {
    user: { currentOrganization },
  } = useAuth();
  const { data } = useEntityDetails(Entity.users, props.userId);
  const { data: usersData } = useEntitiesQuery(Entity.users);

  const { data: modelData } = useModels();

  const userFieldValue = (field: UserFields) => {
    return userObject[currentOrganization[field]];
  };
  const accountIdField = data?.userDetails.find(
    (d) => d.key === currentOrganization[AccountFields.ACCOUNT_ID]
  );
  const userObject = extractObjectFromUserDetails(data, currentOrganization);

  const getPropertyDisplayNameFromItem = React.useCallback(
    (item: string) => {
      if (item === 'domain_1') {
        return 'Domain';
      }
      if (item === 'user_creation_time') {
        return 'Created At';
      }

      if (item === 'user_id') {
        return 'User ID';
      }

      if (usersData?.find((e) => e.keyName === item)?.displayName) {
        return usersData?.find((e) => e.keyName === item)?.displayName;
      }

      return toSentence(item);
    },
    [usersData]
  );

  return (
    <Card bodyClass={'p-0'} className={'mb-4 rounded-lg'} hover={false} style={{ border: 'none' }}>
      {props.isPaid && (
        <div
          className={
            'justify-between items-center h-24 p-4 bg-tw-blue-ec flex bg-sl-line rounded-top'
          }
        >
          <div className={'flex items-center'}>
            <div className={''}>
              <p className={'font-medium text-base'}>
                {userFieldValue(UserFields.USER_EMAIL)
                  ? userFieldValue(UserFields.USER_EMAIL)
                  : userFieldValue(UserFields.USER_ID)}
              </p>
              {props.planName && props.planInterval ? (
                <small className={'text-sm font-normal text-tw-gray-7'}>
                  Subscribed to the {props.planName} plan (Billed {props.planInterval})
                </small>
              ) : (
                <small className={'text-sm font-normal text-tw-gray-7'}>Subscribed to a plan</small>
              )}
            </div>
          </div>
          <div className={'flex items-center'}>
            <p className={'text-3xl font-semibold'}>${convertAmount(props.billingAmount)}</p>
            <p className={'text-2xl text-tw-gray-7'}>{getPlanIntervalShort(props.planInterval)}</p>
          </div>
        </div>
      )}
      <div className={'p-4 grid grid-cols-2 gap-x-1 space-y-4'}>
        {currentOrganization.userPersonaProperties.map((item: string) => {
          const value = data?.userDetails.find((d) => d.key === item)?.value;
          return (
            <li key={item} className={'grid grid-cols-2 mb-4'}>
              <div className={'flex space-x-1 flex-wrap'}>
                <img
                  alt={''}
                  className={'w-6 h-6 filter grayscale opacity-60'}
                  src={getSourceImg(props.source)}
                />
                <p className={'font-medium'}>{getPropertyDisplayNameFromItem(item)}</p>
              </div>
              <div>
                <p className={'text-tw-black-5 font-medium truncate'}>
                  {value
                    ? formatForCellDisplay(value)
                    : formatForCellDisplay(
                        data?.userDetails.find((d) => d.key === `USER_PROPERTY_${item}`)?.value
                      )}
                </p>
              </div>
            </li>
          );
        })}
        {modelData?.some((d) => d.entity.toLowerCase() === Entity.accounts && d.active) &&
          currentOrganization[AccountFields.ACCOUNT_ID] &&
          accountIdField && (
            <li className={'flex mb-4'}>
              <div className={'flex w-68'}>
                <img
                  alt={''}
                  className={'w-6 h-6 mr-3 filter grayscale opacity-60'}
                  src={getSourceImg(props.source)}
                />
                <p className={'font-medium'}>Account</p>
              </div>
              <a
                href={`/${getAccountId()}/accounts/${accountIdField.value}`}
                target={'_blank'}
                rel="noreferrer"
                className="flex items-center space-x-1 cursor-pointer"
              >
                <img alt="new-tab" src={openInNewTab} />
                <p className={'text-tw-black-5 font-medium truncate w-68'}>
                  {accountIdField.value}
                </p>
              </a>
            </li>
          )}
      </div>
    </Card>
  );
};

export default HeroContent;
