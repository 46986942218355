import { ArrowRight, CalendarDotsIcon, WarningIconRed } from 'ui-components/data-display/Icons';
import NavDropdown from 'ui-components/business/navbars/NavDropdown';
import dayjs from 'dayjs';
import { classNames } from 'utils/common';
import { useAuth } from 'hooks/useAuth';
import AvatarSelect from 'pages/activate/AvatarSelect';
import OrganizationName from 'pages/activate/OrganizationName';
import TimezoneSelect from 'pages/activate/TimezoneSelect';
import Banner from 'ui-components/feedback/Banner';
import { getIllustration } from 'utils/helpers';
import { useHistory } from 'react-router-dom';
import { useAnalytics } from 'telemetry';
import { AnalyticsEvents } from 'telemetry/constants';
import useActivateOrganization from '../../hooks/useActivateOrganization';
import Sources from './Sources';
import { ActivateSteps, useActivateStore } from './store/ActivateStore';

const activateSteps = {
  organization: 1,
  avatar: 2,
  timezone: 3,
  sources: 4,
};

export default function Activate() {
  const { logout } = useAuth();
  const router = useHistory();
  const { step } = useActivateStore();
  const { errorSource, connectedSource, inProgressSource } = useActivateOrganization();
  const { track } = useAnalytics();

  const showErrorBanner = !connectedSource && !inProgressSource && errorSource;

  return (
    <div className="flex flex-col">
      <div
        style={{
          backgroundImage: `${getIllustration(dayjs().toDate())}`,
        }}
        className="pb-8 bg-no-repeat bg-cover backdrop-opacity-50"
      >
        <div className="flex items-center justify-between px-6 pt-4">
          <button
            onClick={() => router.push('/')}
            className="flex items-center p-2 text-white border border-white rounded gap-x-2"
          >
            <ArrowRight className="rotate-180 fill-current" />
            <span>Test dashboard</span>
          </button>
          <NavDropdown handleSignOut={logout} />
        </div>
        <div className="mt-10 text-center">
          <div className="text-[2rem] font-medium text-white">Activating your account</div>
          <div className="text-base text-tw-gray-eb">
            Fill in your details and connect data source to get things rolling
          </div>
        </div>
      </div>
      {showErrorBanner && (
        <div className="w-1/2 mx-auto mt-6">
          <Banner
            color="#DD3C3C"
            borderColor="#FFDEDE"
            icon={<WarningIconRed />}
            textColor="#DD3C3C"
          >
            {`Error: ${
              errorSource?.metadata?.errorMsg ||
              'Something went wrong. Try connecting again maybe? 👀'
            }`}
          </Banner>
        </div>
      )}
      <div className={classNames('grid place-items-center', showErrorBanner ? 'mt-6' : 'mt-12')}>
        <div className="flex flex-col items-center w-1/2 p-10 border rounded-xl border-tw-gray-eb">
          <h6 className="text-tw-black-9">
            Step {activateSteps[step]} of {Object.keys(activateSteps).length}
          </h6>
          {step === ActivateSteps.organization && <OrganizationName />}
          {step === ActivateSteps.avatar && <AvatarSelect />}
          {step === ActivateSteps.timezone && <TimezoneSelect />}
          {step === ActivateSteps.sources && <Sources />}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center my-12">
        <CalendarDotsIcon />
        <p className="mt-3 text-tw-black-3">Want a product demo?</p>
        <button
          onClick={() => track(AnalyticsEvents.CHAT_WITH_US)}
          className="mt-2 font-medium text-tw-blue-0d"
        >
          Book a time slot
        </button>
      </div>
    </div>
  );
}
