import { EditDoubleRound } from 'ui-components/data-display/Icons';
import { Destination } from 'ui-components/business/opportunities/types';
import dayjs from 'dayjs';
import { ErrorBoundary } from 'react-error-boundary';
import { classNames } from 'utils/common';
import { Status } from 'hooks/use-async';
import { useAuth } from 'hooks/useAuth';
import useConnectedIntegrations from 'hooks/useConnectedIntegrations';
import { DestinationTag } from 'pages/opportunities/DestinationTag';
import useEntitiesQuery from 'hooks/useEntitiesQuery';
import { Entity } from 'pages/users-and-accounts/users/types';
import { useEffect, useState } from 'react';
import { usePQLWorkflowRoute } from '..';
import MappingsForm from '../components/export/mappings/MappingsForm';
import SectionHeader from '../components/SectionHeader';
import useConnectedIntegrationEntities from '../hooks/useConnectedIntegrationEntities';
import useConnectedIntegrationEntityDetail from '../hooks/useConnectedIntegrationEntityDetail';
import useMeta from '../hooks/useMeta';
import useOpportunity, { useCheckOpportunityCanBeDuplicated } from '../hooks/useOpportunity';
import { useExportPQLStore } from '../store/PQLExportStore';
import { ErrorAnalyticsEvents } from 'telemetry/constants';
import ErrorBoundaryFallback from 'ui-components/feedback/ErrorBoundaryFallback';
import { useAnalytics } from 'telemetry';

export default function EditExports() {
  const { opportunityId } = usePQLWorkflowRoute();
  const { isLoading: opportunityIsLoading, data: opportunity } = useOpportunity(opportunityId);
  const { data: meta } = useMeta();
  const { check } = useCheckOpportunityCanBeDuplicated(opportunity, meta);
  const [pqlRestoreCheckStatus, setPQLRestoreCheckStatus] = useState(Status.PENDING);
  const { user } = useAuth();

  const { track } = useAnalytics();

  useEffect(() => {
    const canPQlBeCloned = check();
    setPQLRestoreCheckStatus(canPQlBeCloned);
  }, [opportunity, meta, check]);

  // fetch the required data for the page
  useConnectedIntegrationEntities();
  const { isLoading: entitiesQueryIsLoading, isSuccess: entitiesQueryIsSuccess } = useEntitiesQuery(
    opportunity?.query.type.toLowerCase() as Entity,
    !!opportunity?.query.type
  );
  useConnectedIntegrationEntityDetail();

  const { destinationId } = useExportPQLStore();
  const { data: connectedIntegrations, isFetching: connectedIntegrationsIsLoading } =
    useConnectedIntegrations(false);

  const selectedDestination = connectedIntegrations?.find((i) => i.id === destinationId);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={(e) =>
        track(ErrorAnalyticsEvents.ERROR_OCCURED, {
          error: e.message,
        })
      }
    >
      <div className="pb-6">
        <div
          className={classNames(
            'flex items-center w-full px-32 bg-white shadow gap-x-4 z-5 h-26',
            user?.currentOrganization.isSandbox && '-mt-4'
          )}
        >
          <EditDoubleRound />
          {opportunityIsLoading || pqlRestoreCheckStatus === Status.PENDING ? (
            <div className="animate-pulse">
              <div className="h-5 w-80 bg-tw-gray-eb"></div>
              <div className="h-4 mt-2 w-96 bg-tw-gray-eb"></div>
            </div>
          ) : (
            <div>
              <h3 className="flex items-center text-base font-medium gap-x-2">
                Editing sync fields
              </h3>
              <span className="mt-2 text-tw-black-7">
                {opportunity?.name} | Created by: {opportunity?.createdBy.email} on{' '}
                {dayjs(opportunity?.createdAt).tz().format('DD MMM, YYYY')}
              </span>
            </div>
          )}
        </div>
        <div className="w-3/5 pb-8 mx-auto mt-6 bg-white shadow-md rounded-xl">
          <SectionHeader number={null} title="Sync" />
          <div className="p-6">
            <div className="flex items-center gap-x-2">
              <span className="text-tw-black-5">Syncing to: </span>
              {opportunityIsLoading ||
              connectedIntegrationsIsLoading ||
              pqlRestoreCheckStatus === Status.PENDING ? (
                <div className="w-40 rounded-full h-7 animate-pulse bg-tw-gray-eb"></div>
              ) : (
                <DestinationTag destination={selectedDestination as Destination} />
              )}
            </div>
            <hr className="my-6 bg-tw-gray-eb" />
            {(entitiesQueryIsLoading ||
              connectedIntegrationsIsLoading ||
              !entitiesQueryIsSuccess ||
              pqlRestoreCheckStatus === Status.PENDING) && (
              <div className="grid grid-cols-2 gap-4">
                <div className="w-2/3 h-8 rounded animate-pulse bg-tw-gray-eb"></div>
                <div className="w-2/3 h-8 rounded animate-pulse bg-tw-gray-eb"></div>
                <div className="w-2/3 h-8 rounded animate-pulse bg-tw-gray-eb"></div>
                <div className="w-2/3 h-8 rounded animate-pulse bg-tw-gray-eb"></div>
              </div>
            )}
            {entitiesQueryIsSuccess && pqlRestoreCheckStatus === Status.SUCCESS && <MappingsForm />}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}
