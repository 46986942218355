import { toast as toastify } from 'react-toastify';
import {
  ConnectedIntegration,
  Integration,
  IntegrationCategory,
  IntegrationType,
} from 'api-client';
import { Search } from 'ui-components/inputs/Search/Search';
import Drawer from 'ui-components/navigation/Drawer';
import { PrimaryButton, SecondaryButton } from 'ui-components/inputs/Buttons';
import { TextInput } from 'ui-components/inputs/Inputs';
import { capitalizeFirstLetter, classNames } from 'utils/common';
import { redirectUri } from 'hooks/use-oauth';
import { createTrayUpdatePayload } from 'pages/integrations/AddIntegrationPage';
import useAddIntegration from 'pages/integrations/hooks/useAddIntegration';
import useAddWebhook, { AddWebhookRequest } from 'pages/integrations/hooks/useAddWebhook';
import useIntegrations from 'pages/integrations/hooks/useIntegrations';
import useUpdateIntegration from 'pages/integrations/hooks/useUpdateIntegration';
import OAuthConnectField from 'pages/integrations/OAuthConnectField';
import IntegrationIcon from 'pages/integrations/sub-components/IntegrationIcon';
import TrayConfigField from 'pages/integrations/TrayConfigField';
import { WebhookForm } from 'pages/integrations/WebhookPage';
import {
  ConnectDestinationUIState,
  ConnectDestinationUITabs,
  useConnectDestinationStore,
} from 'pages/pql-workflow/store/ConnectDestinationStore';
import { useExportPQLStore } from 'pages/pql-workflow/store/PQLExportStore';
import { useCallback, useEffect, useState } from 'react';
import { UseMutationResult } from 'react-query';
import Card from '../../../../ui-components/business/Card';
import SideTab from '../SideTab';
import Tab from '../Tab';
import { TrayConfigStatus } from 'pages/integrations/TrayConfigField';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';
import { useAuth } from 'hooks/useAuth';

type ConnectDestinationProps = {
  showDrawer: boolean;
  setShowDrawer: (showDrawer: boolean) => void;
};

function AddIntegration() {
  const {
    destinationName,
    setDestinationName,
    setTrayConfigStatus,
    setTrayAuthId,
    apiDomain,
    apiKey,
    setCode,
    setApiDomain,
    setApiKey,
    setHideButtons,
    setTrayConnectedIntegration,
    selectedDestination,
  } = useConnectDestinationStore();

  return (
    <div className="mt-8">
      <IntegrationIcon
        className="w-18"
        type={selectedDestination.type}
        logoUrl={selectedDestination.logoUrl}
      />
      <h2 className="mt-6 text-h2">Setting up {selectedDestination.name} as a destination</h2>
      <div className="relative mt-8 custom-int-section">
        <p className="text-tw-black-5">Name your integration</p>
        <TextInput
          onChange={(e) => setDestinationName(e.target.value)}
          value={destinationName}
          placeholder={selectedDestination.name}
          className="w-full px-4 mt-3"
        />
        {selectedDestination.type === IntegrationType.TRAY && selectedDestination?.metadata ? (
          <>
            <div className="mt-8 mb-4 border-dashed border-b-1" />
            <div className="flex items-center justify-between">
              <div className="text-base font-medium text-tw-black-3">
                Connect to {selectedDestination?.name}
              </div>
              <TrayConfigField
                integration={selectedDestination}
                connectedIntegrationName={destinationName}
                updateTrayConnectedIntegration={setTrayConnectedIntegration}
                updateTrayConfigStatus={setTrayConfigStatus}
                heightTillEndOfPage={true}
                onClick={(b) => setHideButtons(b)}
                updateTrayAuthId={setTrayAuthId}
              />
            </div>
          </>
        ) : selectedDestination.oauth && selectedDestination.type !== IntegrationType.PIPEDRIVE ? (
          <>
            <div className="mt-8 mb-4 border-dashed border-b-1" />
            <div className="flex items-center justify-between">
              <div className="text-base font-medium text-tw-black-3">
                Connect to {selectedDestination?.name}
              </div>
              <OAuthConnectField
                name="code"
                type={selectedDestination?.type}
                {...selectedDestination?.oauth}
                showReconnect={false}
                onSuccess={(c) => setCode(c)}
              />
            </div>
          </>
        ) : (
          <>
            {selectedDestination?.type === 'pipedrive' && (
              <>
                <div className={'mt-6'}>
                  <div className="my-2 text-tw-black-5">Pipedrive Company Domain</div>
                  <TextInput
                    className="w-full px-4 py-3"
                    value={apiDomain}
                    onChange={(e) => setApiDomain(e.target.value)}
                    placeholder={`Pipedrive company name from https://companyname.pipedrive.com`}
                  />
                </div>
                <div className={'mt-6'}>
                  <div className="my-2 text-tw-black-5">API Key</div>
                  <TextInput
                    className="w-full px-4 py-3"
                    type="password"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    placeholder={`Your App's API key (or API token)`}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function IntegrationList() {
  const { user } = useAuth();
  const { selectedDestination, setSelectedDestination, setDestinationName } =
    useConnectDestinationStore();
  const [query, setQuery] = useState('');
  const [activeCategory, setActiveCategory] = useState('all_apps');
  const { data: integrations } = useIntegrations();
  const integrationsCanBeConnected = integrations?.filter(
    (d) =>
      d.supportsWrite &&
      d.available &&
      ![IntegrationType.EMAIL, IntegrationType.WEBHOOK].includes(d.type)
  );
  const integrationCategories = [
    ...new Set(integrationsCanBeConnected?.map((i) => i.categories).flat()),
  ];
  const integrationsToShow = integrationsCanBeConnected
    ?.filter((i) => {
      if (activeCategory === 'all_apps') {
        return true;
      }
      return i.categories?.includes(activeCategory as IntegrationCategory);
    })
    .filter((i) => {
      if (query === '') {
        return true;
      }
      return i.name?.toLowerCase()?.includes(query.toLowerCase());
    })
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    })
    .sort((a, b) => {
      if (a.type === IntegrationType.TRAY) {
        return 1;
      }
      if (b.type === IntegrationType.TRAY) {
        return -1;
      }
      return 0;
    });

  const getIntegrationCount = useCallback(
    (category: IntegrationCategory) => {
      let integrationsToReturn: Integration[];
      if (category.toString() === 'all_apps') {
        integrationsToReturn = integrationsCanBeConnected;
      } else {
        integrationsToReturn = integrationsCanBeConnected?.filter((i) =>
          i.categories?.includes(category)
        );
      }
      return (
        integrationsToReturn?.filter((i) => {
          if (!query) {
            return true;
          }
          return i.name?.toLowerCase()?.includes(query.toLowerCase());
        }).length || 0
      );
    },
    [query]
  );

  return (
    <div className="pb-8 mt-4">
      <h2 className="text-2xl font-medium text-tw-black-3">Connect your applications</h2>
      <p className="mt-3 text-xs text-tw-black-9">
        You can sync your qualified users to the app of your choice
      </p>
      <Search
        placeholder="Search for an app"
        onChangeFunction={(e) => {
          setActiveCategory('all_apps');
          setQuery(e.target.value);
        }}
        onClearFunction={() => setQuery('')}
        value={query}
        className="w-full mt-6"
        hasShadow={false}
        fullWidth={true}
      />
      <div className="grid grid-cols-4 mt-11">
        <div className="flex flex-col">
          <div className="border-l border-tw-gray-eb">
            <SideTab
              label={`All Apps (${getIntegrationCount('all_apps' as IntegrationCategory)})`}
              onClick={() => setActiveCategory('all_apps')}
              isActive={activeCategory === 'all_apps'}
            />
          </div>
          {integrationCategories
            .sort()
            .filter((c) => c !== 'api')
            .map((category, index) => (
              <div key={category} className="border-l border-tw-gray-eb">
                <SideTab
                  isLastItem={index === integrationCategories?.length - 1}
                  isActive={activeCategory === category}
                  onClick={() => setActiveCategory(category)}
                  label={`${category
                    .split('_')
                    .map((c) => {
                      return c.toLowerCase() === 'crm' ? 'CRM' : capitalizeFirstLetter(c);
                    })
                    .join(' ')} (${getIntegrationCount(category)})`}
                />
              </div>
            ))}
        </div>
        <div
          className={classNames(
            'grid col-span-3',
            integrationsToShow?.length === 0
              ? 'place-items-center'
              : 'grid-cols-2 gap-4 auto-rows-min'
          )}
        >
          {integrationsToShow?.map((i) => {
            return (
              <Card
                key={i.id}
                onClick={() => {
                  setSelectedDestination(i);
                  if (user?.currentOrganization?.name && i?.name)
                    setDestinationName(
                      `${user.currentOrganization.name.toLowerCase()}-${i.name.toLowerCase()}`
                    );
                }}
                isActive={selectedDestination?.id === i.id}
                cardClassname="mr-0"
              >
                <div className="flex items-center gap-x-2">
                  <IntegrationIcon
                    className="w-8"
                    type={i.type}
                    logoUrl={i.logoUrl}
                    height="auto"
                  />
                  <p>{i.name}</p>
                </div>
              </Card>
            );
          })}
          {integrationsToShow?.length === 0 && (
            <span className="text-tw-black-7">No results found</span>
          )}
        </div>
      </div>
    </div>
  );
}

function AddWebhook({
  createWebhook,
  handleSuccess,
}: {
  handleSuccess: (id: number, name: string) => void;
  createWebhook: UseMutationResult<ConnectedIntegration, unknown, AddWebhookRequest, unknown>;
}) {
  const { setWebhookButtonDisabled } = useConnectDestinationStore();
  const { data: integrations } = useIntegrations();
  const webhookDestination = integrations?.find((i) => i.type === IntegrationType.WEBHOOK);
  return (
    <div>
      <h2 className="mt-4 mb-8 text-2xl font-medium text-tw-black-3">Create a webhook</h2>
      <WebhookForm
        connectedIntegrationId={null}
        isEdit={false}
        showButtons={false}
        submitButtonsClassNames={'bottom-30'}
        onFormStateChange={(isDirty) => {
          setWebhookButtonDisabled(!isDirty);
        }}
        onFormSubmit={(name, url, method, headers) => {
          createWebhook.mutate(
            {
              name: name,
              url: url,
              method: method,
              headers,
              // auth: authEnabled && username && password ? { username, password } : {},
            },
            {
              onSuccess: (resp) => {
                toastify(<SuccessToast description="Webhook has been added" />, {
                  type: 'success',
                });
                handleSuccess(resp.id, webhookDestination?.name);
              },
            }
          );
        }}
        integrationId={integrations.find((i) => i.type === IntegrationType.WEBHOOK)?.id?.toString()}
      />
    </div>
  );
}

export default function ConnectDestination({ showDrawer, setShowDrawer }: ConnectDestinationProps) {
  const { data: integrations } = useIntegrations();

  const {
    selectedDestination,
    setSelectedDestination,
    code,
    apiKey,
    trayConfigStatus,
    apiDomain,
    activeTab,
    setActiveTab,
    setActiveUIState,
    activeUIState,
    destinationName,
    setDestinationName,
    trayConnectedIntegration,
    hideButtons,
    setHideButtons,
    reset,
    setTrayConnectedIntegration,
    setTrayConfigStatus,
    webhookButtonDisabled,
  } = useConnectDestinationStore();

  const addIntegration = useAddIntegration();
  const updateTrayIntegration = useUpdateIntegration(trayConnectedIntegration?.id);
  const { setActiveIntegrationTab, setDestinationId, setShowConnectSection } = useExportPQLStore();
  const webhookDestination = integrations?.find((i) => i.type === IntegrationType.WEBHOOK);
  const createWebhook = useAddWebhook(webhookDestination?.id);

  const handleSuccess = (id: number, name: string) => {
    setActiveIntegrationTab(name);
    setDestinationId(id);
    setShowConnectSection(false);
    setActiveUIState(ConnectDestinationUIState.integrationList);
    setActiveTab(ConnectDestinationUITabs.apps);
    setSelectedDestination(null);
    setDestinationName('');
    setShowDrawer(false);
    setTrayConnectedIntegration(null);
    setTrayConfigStatus(null);
  };

  useEffect(() => {
    setActiveUIState(ConnectDestinationUIState.integrationList);
    setHideButtons(false);
  }, []);

  useEffect(() => {
    if (trayConfigStatus === TrayConfigStatus.FINISH) {
      handleConnectIntegration();
    }
  }, [trayConfigStatus]);

  const handleConnectIntegration = async () => {
    if (selectedDestination.type === IntegrationType.TRAY) {
      const payload = createTrayUpdatePayload(trayConfigStatus);
      updateTrayIntegration.mutate(
        {
          name: destinationName,
          active: payload.active,
          status: payload.status,
        },
        {
          onSuccess: () => {
            toastify(<SuccessToast description="App has been integrated" />, {
              type: 'success',
            });
            handleSuccess(trayConnectedIntegration?.id, selectedDestination?.name);
          },
        }
      );
    } else {
      addIntegration.mutate(
        {
          name: destinationName,
          type: selectedDestination?.type,
          integration: selectedDestination?.id,
          connect:
            selectedDestination?.oauth && selectedDestination?.type !== IntegrationType.PIPEDRIVE
              ? {
                  [selectedDestination?.type]: {
                    code,
                    redirectUri: redirectUri(selectedDestination?.type, 'integrations'),
                  },
                }
              : {
                  [selectedDestination?.type]: {
                    apiKey,
                    apiDomain: `https://${apiDomain}.pipedrive.com`,
                  },
                },
        },
        {
          onSuccess: (resp) => {
            toastify(<SuccessToast description={`App integrated ${selectedDestination?.name}`} />, {
              type: 'success',
            });
            handleSuccess(resp.id, selectedDestination?.name);
          },
        }
      );
    }
  };

  const buttonDisabled = useCallback(() => {
    if (activeUIState === ConnectDestinationUIState.integrationList) {
      if (!selectedDestination) {
        return true;
      }
    } else if (activeUIState === ConnectDestinationUIState.addIntegration) {
      if (!destinationName) {
        return true;
      }
      if (selectedDestination?.type === IntegrationType.TRAY) {
        return !trayConnectedIntegration;
      }

      if (
        [
          IntegrationType.HUBSPOT,
          IntegrationType.SALESFORCE,
          IntegrationType.SALESFORCE_SANDBOX,
        ].includes(selectedDestination?.type)
      ) {
        return !code;
      }
      if (selectedDestination?.type === IntegrationType.PIPEDRIVE) {
        return !apiKey || !apiDomain;
      }
    } else if (activeUIState === ConnectDestinationUIState.addWebhook) {
      return webhookButtonDisabled;
    }
  }, [
    selectedDestination,
    trayConnectedIntegration,
    activeUIState,
    destinationName,
    code,
    apiKey,
    apiDomain,
    webhookButtonDisabled,
  ]);

  return (
    <Drawer
      open={showDrawer}
      hideButtons={hideButtons}
      onClose={() => {
        reset();
        setShowDrawer(false);
      }}
      widthClassName="w-3/5"
      primaryButton={
        <PrimaryButton
          form={activeUIState === ConnectDestinationUIState.addWebhook ? 'webhook' : null}
          className="w-60"
          isLoading={
            updateTrayIntegration?.isLoading ||
            addIntegration?.isLoading ||
            createWebhook?.isLoading
          }
          onClick={() => {
            if (activeUIState === ConnectDestinationUIState.integrationList) {
              setActiveUIState(ConnectDestinationUIState.addIntegration);
            } else if (activeUIState === ConnectDestinationUIState.addIntegration) {
              handleConnectIntegration();
            }
          }}
          disabled={buttonDisabled()}
        >
          Proceed
        </PrimaryButton>
      }
      secondaryButton={
        activeUIState === ConnectDestinationUIState.addIntegration ? (
          <SecondaryButton
            className="w-60 text-tw-blue-0d"
            onClick={() => {
              setActiveUIState(ConnectDestinationUIState.integrationList);
              setDestinationName('');
            }}
          >
            Go back
          </SecondaryButton>
        ) : null
      }
    >
      <div className="px-6 mt-11">
        {activeUIState !== ConnectDestinationUIState.addIntegration && (
          <div className="flex items-center gap-x-4">
            <Tab
              label={'Apps'}
              isActive={activeTab === ConnectDestinationUITabs.apps}
              onClick={() => {
                setActiveTab(ConnectDestinationUITabs.apps);
                setActiveUIState(ConnectDestinationUIState.integrationList);
              }}
            />
            <Tab
              label={'Webhooks'}
              isActive={activeTab === ConnectDestinationUITabs.webhooks}
              onClick={() => {
                setActiveTab(ConnectDestinationUITabs.webhooks);
                setActiveUIState(ConnectDestinationUIState.addWebhook);
              }}
            />
          </div>
        )}
        {activeTab === ConnectDestinationUITabs.apps &&
          activeUIState === ConnectDestinationUIState.integrationList && <IntegrationList />}
        {activeTab === ConnectDestinationUITabs.apps &&
          activeUIState === ConnectDestinationUIState.addIntegration &&
          selectedDestination && <AddIntegration />}
        {activeTab === ConnectDestinationUITabs.webhooks &&
          activeUIState === ConnectDestinationUIState.addWebhook && (
            <AddWebhook createWebhook={createWebhook} handleSuccess={handleSuccess} />
          )}
      </div>
    </Drawer>
  );
}
