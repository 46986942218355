import { classNames } from 'utils/common';

export default function Tab({ onClick, isActive, label }) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'text-base cursor-pointer border-b-2 pb-1 mb-4',
        isActive
          ? 'border-tw-blue-0d text-tw-blue-0d font-semibold'
          : 'border-transparent text-tw-black-9 font-medium'
      )}
    >
      {label}
    </div>
  );
}
