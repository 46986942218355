import { useApiClient } from 'api-client';
import { AxiosInstance } from 'axios';
import { OpportunityResponse } from 'ui-components/business/opportunities/types';
import { useQuery } from 'react-query';

async function getOpportunityById(client: AxiosInstance, oppId: string) {
  const { data } = await client.get<OpportunityResponse>(`/data/opportunities/${oppId}/`);

  return data;
}

export default function useOpportunity(oppId: string, enabled?: boolean) {
  const client = useApiClient();
  const isOppIdNumeric = Number.isInteger(Number(oppId));

  return useQuery(['opportunity', oppId], () => getOpportunityById(client, oppId), {
    enabled: !!oppId && isOppIdNumeric && (enabled ?? true),
  });
}
