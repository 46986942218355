import { CompleteIcon, ToplyneLogo } from 'ui-components/data-display/Icons';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../ui-components/inputs/Buttons';

interface PasswordChangedProps {}

const PasswordChanged: FC<PasswordChangedProps> = () => {
  const router = useHistory();
  return (
    <div className="h-screen py-4 pl-4">
      <ToplyneLogo className="h-auto w-36" />
      <div className="my-4 h-88">
        <div className="w-36% mx-auto rounded-lg border-1 border-tw-gray-eb px-12 pt-12 h-full">
          <a className="d-flex justify-content-center">
            <CompleteIcon className="mx-auto" />
          </a>
          <div className="w-3/4 mx-auto mt-6">
            <div className="text-center">
              <h3 className="mt-6 text-2xl font-medium text-tw-black-3">Password changed</h3>
              <p className="mt-2 text-sm text-tw-black-5">
                You can now login using your new password
              </p>
            </div>
            <div className="px-8 mt-10">
              <PrimaryButton
                type="submit"
                className="w-full uppercase"
                onClick={() => {
                  router.push('/login');
                }}
              >
                Go to Login
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordChanged;
