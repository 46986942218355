import { ReactNode } from 'react';

export default function Banner(props: {
  children: ReactNode;
  icon: ReactNode;
  color: string;
  textColor?: string;
  borderColor: string;
}) {
  const { icon, color, borderColor } = props;
  return (
    <div className="flex w-full mt-2 rounded-lg text-tw-black-5">
      <div className="w-3 rounded-tl-lg rounded-bl-lg" style={{ backgroundColor: color }}>
        {' '}
      </div>
      <div
        className="flex flex-1 p-3 space-x-3 text-sm rounded-tr-lg rounded-br-lg bg-tw-white-ff place-items-center border-1 border-r-tw-blue-b6"
        style={{ borderColor }}
      >
        {icon}
        <span className="my-3 text-tw-black-5" style={{ color: props.textColor }}>
          {props.children}
        </span>
      </div>
    </div>
  );
}
