import {
  ConnectedIntegrationStatus,
  IntegrationType,
  Organization,
  OrganizationStatus,
} from 'api-client';
import { useAuth } from 'hooks/useAuth';
import useConnectedIntegrations from 'hooks/useConnectedIntegrations';
import useOrganization from 'pages/opportunities/hooks/useOrganization';
import { useEffect, useState } from 'react';
import { ActivateSteps, useActivateStore } from '../pages/activate/store/ActivateStore';

export default function useActivateOrganization() {
  const { user } = useAuth();
  let liveOrg: Organization & Record<string, any>;
  if (user?.currentOrganization?.isSandbox) {
    liveOrg = user?.organizations?.find(
      (org) => !org.isSandbox && org.sandboxId === user?.currentOrganization?.schemaName
    );
  } else {
    liveOrg = user?.currentOrganization;
  }

  const { data: organization } = useOrganization(liveOrg?.schemaName);
  const { setStep, setOrganizationSchemaName } = useActivateStore();
  const [activateButtonCTA, setActivateButtonCTA] = useState('Setup account');
  const [activateBannerHeader, setActivateBannerHeader] = useState(
    'Unlock the true power of your product led growth'
  );
  const [activateBannerText, setActivateBannerText] = useState(
    'Fill out your account profile and connect product data source to start targeting your customers'
  );
  const [activateButtonLoading, setActivateButtonLoading] = useState(false);
  const [isActivateBannerTextError, setIsActivateBannerTextError] = useState(false);

  const { data: connectedIntegrations } = useConnectedIntegrations(
    false,
    organization?.schemaName,
    [OrganizationStatus.IN_PROGRESS, OrganizationStatus.ERROR].includes(organization?.status)
  );
  const connectedSource = connectedIntegrations
    ?.filter(
      (d) =>
        ((d.integration.supportsRead && !d.integration.supportsWrite) ||
          d.integration.type === IntegrationType.API) &&
        d.status === ConnectedIntegrationStatus.READY
    )
    ?.sort((a, b) => b.id - a.id)?.[0];

  const lastSource = connectedIntegrations
    ?.filter(
      (d) =>
        (d.integration.supportsRead && !d.integration.supportsWrite) ||
        d.integration.type === IntegrationType.API
    )
    ?.sort((a, b) => b.id - a.id)?.[0];
  const errorSource =
    lastSource?.status === ConnectedIntegrationStatus.ERROR ? lastSource : undefined;

  const inProgressSource =
    lastSource?.status === ConnectedIntegrationStatus.IN_PROGRESS ? lastSource : undefined;

  useEffect(() => {
    if (organization && organization.status === OrganizationStatus.READY) {
      setStep(ActivateSteps.avatar);
      setActivateButtonCTA('Continue account set-up');

      setOrganizationSchemaName(organization.schemaName);
      if (user.avatarName) {
        setStep(ActivateSteps.timezone);
      }
      if (organization?.timezone) {
        setStep(ActivateSteps.sources);
        setActivateButtonCTA('Finish account setup');
        setActivateBannerHeader('Current objective: Complete your account set-up');
        setActivateBannerText(
          'You’re just one step away. Complete product data source integration to start targeting your customers'
        );
      }
      if (connectedSource) {
        if (
          [
            IntegrationType.SNOWFLAKE,
            IntegrationType.RUDDERSTACK,
            IntegrationType.API,
            IntegrationType.BIGQUERY,
          ].includes(connectedSource.type)
        ) {
          if (!organization.callScheduled) {
            setActivateBannerHeader('Book a 20 min slot with us');
            setActivateBannerText(
              `We’d like to help you set up the final details of your ${connectedSource?.integration.name} integration. Schedule a session with our product expert now!`
            );
          } else {
            setActivateBannerHeader(
              `Meeting booked to complete ${connectedSource?.integration.name} integration`
            );
            setActivateBannerText(
              `We’ll see you soon! In the mean time, why don’t you play around with your Toplyne test dashboard?`
            );
            setActivateButtonCTA('Finishing up account setup');
            setActivateButtonLoading(true);
          }
        } else {
          setActivateBannerHeader(
            `Importing data from ${connectedSource?.integration.name || ''}...`
          );
          setActivateBannerText(
            `Keep an eye on your inbox! We will send you an email once the import is complete. Depending on the volume of your data, this might might take upto 48 hours`
          );
          setActivateButtonCTA('Finishing up account setup');
          setActivateButtonLoading(true);
        }
      }

      if (errorSource) {
        setActivateBannerHeader(
          `Your ${errorSource?.integration.name} connection was unsuccessful`
        );
        setActivateBannerText(
          errorSource?.metadata?.errorMsg || `Something went wrong. Try connecting again maybe? 👀`
        );
        setIsActivateBannerTextError(true);
        setActivateButtonCTA('Retry source connection');
      }
    }
  }, [user, organization, connectedIntegrations]);

  return {
    organization,
    activateButtonCTA,
    activateBannerHeader,
    activateBannerText,
    connectedSource,
    errorSource,
    inProgressSource,
    activateButtonLoading,
    isActivateBannerTextError,
  };
}
