import { useQuery } from 'react-query';
import { useClient } from '../../../api-client';
import { getDaysAndHoursOnly } from '../../../utils/common';

export default function usePerformance(opId: number, payload: object, enabled?: boolean) {
  const client = useClient();
  return useQuery(
    ['performanceDetails', opId.toString(), JSON.stringify(getDaysAndHoursOnly(payload))],
    () => client.getPerformanceDetails(opId, payload),
    {
      enabled,
    }
  );
}
