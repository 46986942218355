import { TickIconFilled } from 'ui-components/data-display/Icons';
import { PrimaryButton } from 'ui-components/inputs/Buttons';
import InitialsAvatar from 'ui-components/data-display/InitialsAvatar';
import { classNames } from 'utils/common';
import { avatars } from 'utils/globalTypes';
import { useAuth } from 'hooks/useAuth';
import useEditUserDetails from 'hooks/useEditUserDetails';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAnalytics } from '../../telemetry';
import { AnalyticsEvents } from '../../telemetry/constants';
import { ActivateSteps, useActivateStore } from './store/ActivateStore';

export default function AvatarSelect() {
  const { user } = useAuth();
  const [selectedAvatar, setSelectedAvatar] = useState(user?.avatarName);
  const editUserMutation = useEditUserDetails();
  const { handleSubmit } = useForm();
  const { track } = useAnalytics();
  const { setStep: changeStep } = useActivateStore();

  useEffect(() => {
    setSelectedAvatar(user?.avatarName);
  }, [user?.avatarName]);

  const onSubmit = () => {
    const updateData = {
      id: user.id,
      userDetails: {
        avatarName: selectedAvatar,
        email: user.email,
      },
    };

    editUserMutation.mutate(updateData, {
      onSuccess: () => {
        track(AnalyticsEvents.AVATAR_SELECTED, {});
        changeStep(ActivateSteps.timezone);
      },
    });
  };

  return (
    <div className="flex flex-col items-center w-2/3 mx-auto mt-4">
      <form className="grid w-full place-items-center" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-2xl font-medium text-center">Select an avatar</h2>
        <div className="grid w-4/5 grid-cols-4 gap-2 mt-8">
          <div
            className={classNames(
              'border-3 w-18 h-18 rounded-full relative cursor-pointer',
              selectedAvatar === 'initials' ? 'border-tw-blue-0d' : 'border-transparent'
            )}
            onClick={() => setSelectedAvatar('initials')}
          >
            {selectedAvatar === 'initials' && (
              <TickIconFilled className="absolute right-0 fill-current text-tw-blue-0d" />
            )}
            <InitialsAvatar user={user} />
          </div>
          {avatars.map((avatar) => (
            <div
              key={avatar.name}
              className={classNames(
                'border-3 rounded-full relative w-18 h-18',
                selectedAvatar === avatar.name ? 'border-tw-blue-0d' : 'border-transparent'
              )}
            >
              {selectedAvatar === avatar.name && (
                <TickIconFilled className="absolute right-0 fill-current text-tw-blue-0d" />
              )}
              <img
                className={classNames('w-full h-full rounded-full cursor-pointer')}
                src={avatar.url}
                alt={avatar.name}
                onClick={() => {
                  setSelectedAvatar(avatar.name);
                }}
              />
            </div>
          ))}
        </div>
        <PrimaryButton
          type="submit"
          className="w-4/5 py-2 mt-8"
          disabled={!selectedAvatar || editUserMutation.isLoading}
          isLoading={editUserMutation.isLoading}
        >
          Proceed
        </PrimaryButton>
      </form>
    </div>
  );
}
