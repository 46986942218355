import * as React from 'react';
import { classNames } from '../../../utils/common';

interface AttributeBtnProps {
  isActive: boolean;
  title: string;
  showLength?: boolean;
  length?: number;
  clicked: any;
}

const activeAttributeClass = 'bg-tw-black-1 text-tw-white-ff font-bold';
const inActiveAttributeClass = 'bg-tw-gray-f5 text-tw-black-5 font-normal';
const AttributeBtn: React.FC<AttributeBtnProps> = (props) => {
  return (
    <button
      type={'button'}
      className={classNames(
        'mr-2 py-2.5 px-4 rounded text-sm',
        props.isActive ? activeAttributeClass : inActiveAttributeClass
      )}
      onClick={() => props.clicked()}
    >
      {props.title} {props.showLength ? `(${props.length})` : ''}
    </button>
  );
};
export default AttributeBtn;
