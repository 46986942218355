import useDsEnabled from 'hooks/useDsEnabled';
import useMeta from 'pages/pql-workflow/hooks/useMeta';
import { useFiltersPQLStore } from 'pages/pql-workflow/store/PQLFiltersStore';
import { useEffect } from 'react';
import { useDefinePQLStore } from '../store/PQLDefineStore';

export enum FiltersTabs {
  VERY_STRONG = 'Very strong',
  STRONG = 'Strong',
  ALL = 'All',
  APPLIED_FILTERS = 'Applied Filters',
  USER_EVENTS = 'Usage behaviour',
  PROFILE_PROPERTIES = 'Profile properties',
}

const filtersNonDSKeys = {
  [FiltersTabs.USER_EVENTS]: 'profile_events',
  [FiltersTabs.PROFILE_PROPERTIES]: 'profile_properties',
};

const filtersTabRange = {
  [FiltersTabs.VERY_STRONG]: {
    min: 81,
    max: 100,
  },
  [FiltersTabs.STRONG]: {
    min: 61,
    max: 80,
  },
  [FiltersTabs.ALL]: {
    min: 0,
    max: 100,
  },
};

export function useFilterTabs() {
  const { filtersActiveTab, setFiltersActiveTab } = useFiltersPQLStore();
  const { searchQuery, filters } = useFiltersPQLStore();
  const { modelId, targetEntity } = useDefinePQLStore();
  // const { data: recommendedFilters } = useRecommendedFilters();

  const { data, isLoading: loadingMeta } = useMeta();

  const dsEnabled = useDsEnabled(modelId, targetEntity);

  useEffect(() => {
    setFiltersActiveTab(dsEnabled ? FiltersTabs.VERY_STRONG : FiltersTabs.USER_EVENTS);
  }, [dsEnabled, setFiltersActiveTab]);

  const fallbackActiveTab = dsEnabled ? FiltersTabs.VERY_STRONG : FiltersTabs.USER_EVENTS;

  const filterTabToUse = filtersActiveTab || fallbackActiveTab;

  const availableFilters = data?.sourceMeta.data?.filter((d) => d.COLUMN_NAME !== 'MACRO_PERSONA');

  const filtersToShowNonDS =
    !dsEnabled && filterTabToUse !== FiltersTabs.APPLIED_FILTERS
      ? availableFilters
          ?.filter(
            (d) =>
              filterTabToUse === FiltersTabs.ALL || d.KEYSPACE === filtersNonDSKeys[filterTabToUse]
          )
          ?.filter((d) => {
            if (searchQuery) {
              return d.DISPLAY_NAME.toLowerCase().includes(searchQuery.toLowerCase());
            }
            return true;
          })
      : filters?.map((f) => {
          return availableFilters?.find((d) => d.COLUMN_NAME === f.columnName);
        });

  const filtersToShow =
    dsEnabled && filterTabToUse !== FiltersTabs.APPLIED_FILTERS
      ? availableFilters
          ?.filter((d) => {
            return (
              d.FEATURE_SCORE >= filtersTabRange[filterTabToUse]?.min &&
              d.FEATURE_SCORE <= filtersTabRange[filterTabToUse]?.max
            );
          })
          ?.filter((d) => {
            if (searchQuery) {
              return d.DISPLAY_NAME.toLowerCase().includes(searchQuery.toLowerCase());
            }
            return true;
          })
      : // .sort((a) => (recommendedFilters?.find((f) => f.columnName === a.COLUMN_NAME) ? -1 : 1))
        filters.map((f) => {
          return availableFilters?.find((d) => d.COLUMN_NAME === f.columnName);
        });

  const filterCount = {
    [FiltersTabs.ALL]: availableFilters?.filter((d) => {
      if (searchQuery) {
        return d.DISPLAY_NAME.toLowerCase().includes(searchQuery.toLowerCase());
      }
      return true;
    }).length,
    [FiltersTabs.VERY_STRONG]: availableFilters
      ?.filter((d) => d.FEATURE_SCORE > filtersTabRange[FiltersTabs.VERY_STRONG].min)
      ?.filter((d) => {
        if (searchQuery) {
          return d.DISPLAY_NAME.toLowerCase().includes(searchQuery.toLowerCase());
        }
        return true;
      }).length,
    [FiltersTabs.STRONG]: availableFilters
      ?.filter(
        (d) =>
          d.FEATURE_SCORE > filtersTabRange[FiltersTabs.STRONG].min &&
          d.FEATURE_SCORE <= filtersTabRange[FiltersTabs.STRONG].max
      )
      ?.filter((d) => {
        if (searchQuery) {
          return d.DISPLAY_NAME.toLowerCase().includes(searchQuery.toLowerCase());
        }
        return true;
      }).length,
    [FiltersTabs.APPLIED_FILTERS]: filters.length,
    [FiltersTabs.USER_EVENTS]: availableFilters?.filter(
      (d) => d.KEYSPACE === filtersNonDSKeys[FiltersTabs.USER_EVENTS]
    ).length,
    [FiltersTabs.PROFILE_PROPERTIES]: availableFilters?.filter(
      (d) => d.KEYSPACE === filtersNonDSKeys[FiltersTabs.PROFILE_PROPERTIES]
    ).length,
  };

  return {
    activeTab: filterTabToUse,
    setActiveTab: setFiltersActiveTab,
    filtersToShow: dsEnabled ? filtersToShow : filtersToShowNonDS,
    filterCount,
    loadingMeta,
    filtersToShowNonDS,
    filterTabs: dsEnabled
      ? [FiltersTabs.VERY_STRONG, FiltersTabs.STRONG, FiltersTabs.ALL]
      : [FiltersTabs.USER_EVENTS, FiltersTabs.PROFILE_PROPERTIES, FiltersTabs.ALL],
  };
}
