import * as Sentry from '@sentry/react';
import { LoginResponse } from 'api-client';
import { EMAIL_ID, redirectPath } from 'utils/globalTypes';
import { useAuthStore } from 'hooks/useAuthStore';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAnalytics } from 'telemetry';

export default function useLoginSuccess() {
  const { setAuthToken, setRefreshToken, setAccountId, setAuthState } = useAuthStore();
  const { identify, track } = useAnalytics();
  const router = useHistory();
  return useCallback(
    (data: LoginResponse) => {
      const {
        accessToken,
        refreshToken,
        user: { email, firstName, lastName, id, organizations, isStaff, defaultOrganization },
      } = data;
      setAuthToken(accessToken);
      setRefreshToken(refreshToken);
      identify(email, {
        name: firstName + ' ' + lastName,
        email,
        'First Name': firstName,
        'Last Name': lastName,
        id,
      });
      track('Logged In', {
        Email: email,
      });
      localStorage.setItem(EMAIL_ID, email.split('@')[0]);
      Sentry.setUser({
        email: email,
      });
      setAuthState({
        user: data.user,
      });
      const prodOrganizations = organizations.filter((org) => !org.isSandbox && org.isActive);
      const sandboxOrganizations = organizations.filter((org) => org.isSandbox);
      if (defaultOrganization) {
        setAccountId(defaultOrganization.orgId, defaultOrganization.schemaName);
        identify(email, {
          Account: defaultOrganization.schemaName,
        });
        window.location.href = redirectPath;
      } else if (prodOrganizations.length >= 1) {
        setAccountId(prodOrganizations[0].orgId, prodOrganizations[0].schemaName);
        identify(email, {
          Account: prodOrganizations[0].schemaName,
        });
        window.location.href = redirectPath;
      } else if (sandboxOrganizations.length >= 1) {
        setAccountId(sandboxOrganizations[0].orgId, sandboxOrganizations[0].schemaName);
        identify(email, {
          Account: sandboxOrganizations[0].schemaName,
        });
        window.location.href = redirectPath;
      } else if (isStaff) {
        router.push('/organizations/choose');
      } else {
        router.push('/');
      }
    },
    [setAuthToken, setRefreshToken, identify, track, setAccountId, setAuthState, router]
  );
}
