import { toast as toastify } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { LoggedInUser, useClient } from '../api-client';
import { useAnalytics } from '../telemetry';
import { AnalyticsEvents } from '../telemetry/constants';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';

type EditUserDetailsData = {
  id: string | number;
  userDetails: {
    email: string;
    firstName?: string;
    lastName?: string;
    avatarName?: string;
    hasSimilarOrganization?: boolean;
  };
};

export default function useEditUserDetails() {
  const queryClient = useQueryClient();
  const { track } = useAnalytics();
  const client = useClient();
  return useMutation(
    (data: EditUserDetailsData) => client.editUserDetails(data?.id, data?.userDetails),
    {
      onSuccess: async (response, data) => {
        await queryClient.cancelQueries(['user']);

        queryClient.setQueryData('user', (user: LoggedInUser) => {
          return {
            ...user,
            ...data?.userDetails,
          };
        });
      },
      onError: () => {
        toastify(<ErrorToast description="There was an error while updating the details" />, {
          type: 'error',
        });
        track(AnalyticsEvents.USER_PROFILE_EDITED_FAILED);
      },
      onSettled: () => {
        queryClient.refetchQueries(['user']);
      },
    }
  );
}
