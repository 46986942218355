import { useUsersStore } from 'pages/users-and-accounts/store/UsersStore';
import { useQuery } from 'react-query';
import { useClient } from '../../../../api-client';

export default function useUsersList(page: number) {
  const client = useClient();
  const { field_mapping, sortBy, filters } = useUsersStore();

  return useQuery(
    [
      'account-details-users',
      page,
      JSON.stringify(Object.keys(field_mapping)),
      [sortBy.map((s) => `${s.source}_${s.keyName}_${s.keySpace}_${s.order}`).join(', ')],
      [
        filters
          .map((f) => `${f.source}_${f.keyName}_${f.keySpace}_${f.op}_${JSON.stringify(f.value)}`)
          .join(', '),
      ],
    ],
    () => client.getUsers(page, field_mapping, sortBy, '', filters ?? [], 10),
    {
      keepPreviousData: true,
      onError: (error) => {
        throw error;
      },
    }
  );
}
