import Modal, { ModalWidth } from 'ui-components/util/modal';
import * as React from 'react';
import IntegrationIcon from './IntegrationIcon';
import { IntegrationType } from '../../../api-client';

interface RequestAppModalProps {
  name: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  type?: IntegrationType;
  logoUrl?: string;
}

const RequestedAppModal: React.FC<RequestAppModalProps> = (props) => {
  const { name, showModal, setShowModal, type, logoUrl } = props;

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      width={ModalWidth.md}
      title={'Requested app'}
    >
      <div className="mt-3 p-6">
        <div className="flex place-items-center p-4 h-20 bg-white rounded-lg border-1 border-tw-gray-eb w-2/5 gap-x-3">
          <IntegrationIcon type={type} logoUrl={logoUrl} />
          {name}
        </div>
        <div className="mt-6 text-tw-black-5 font-normal text-sm">
          We are working on making this app live for you. It normally takes upto 7-10 business days
          to make the app live after you have requested it.
        </div>
      </div>
    </Modal>
  );
};

export default RequestedAppModal;
