import { SearchIcon } from '@chakra-ui/icons';
import { CircularProgress, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import Modal, { ModalWidth } from 'ui-components/util/modal';
import { SecondaryButton } from 'ui-components/inputs/Buttons';
import _cloneDeep from 'lodash/cloneDeep';
import _debounce from 'lodash/debounce';
import * as React from 'react';
import { ReactComponent as MagnifyingGlass } from '../../../assets/svg/components/magnifying-glass.svg';
import Card from '../../surfaces/cards/Card';
import AttributeBtn from './AttributeBtn';
import AttributeToggleRow from './AttributeToggleRow';
import Billing from './Billing';
import ProductEvents from './ProductEvents';
import ProfileProperties from './ProfileProperties';
import useModels from '../../../hooks/useModels';
import { checkDsEnabled } from './Attributes';

interface AttributeProps {
  productEventsData: {
    [key: string]: any;
  };
  billingData: {
    [key: string]: any;
  };
  profilePropertiesData: {
    [key: string]: any;
  };
  userAttributePreferences: {
    [key: string]: any;
  };
  temporaryUserAttributePreferences: {
    [key: string]: any;
  };
  updateParentAttributePreferences: any;
  billingLedger?: any;
  billingHeroData: {
    [key: string]: any;
  };
  ATTRIBUTE_CONST: {
    PRODUCT_EVENTS: string;
    BILLING: string;
    PROFILE_PROPERTIES: string;
  };
}

const Attribute: React.FC<AttributeProps> = (props) => {
  const [currentAttribute, setCurrentAttribute] = React.useState<string>(
    props.ATTRIBUTE_CONST.PRODUCT_EVENTS
  );
  const [searchAttributeInput, setSearchAttributeInput] = React.useState<string>('');
  const [searchAttributeData, setSearchAttributeData] = React.useState([]);
  const [isSearchAttributeDataLoading, setIsSearchAttributeDataLoading] =
    React.useState<boolean>(false);
  let localUserAttributePreferences = _cloneDeep(props.userAttributePreferences);
  let temporaryLocalUserAttributePreferences = _cloneDeep(props.temporaryUserAttributePreferences);

  const orderShow = (attributes) => {
    return [
      ...attributes.filter((item) => item.show === true),
      ...attributes.filter((item) => item.show === false),
    ];
  };

  const setAttributesWithSearch = (searchInput: React.ChangeEvent<HTMLInputElement>) => {
    let baseAttribute = _cloneDeep(props.productEventsData);
    if (currentAttribute === props.ATTRIBUTE_CONST.PROFILE_PROPERTIES) {
      baseAttribute = _cloneDeep(props.profilePropertiesData);
    } else if (currentAttribute === props.ATTRIBUTE_CONST.BILLING) {
      baseAttribute = _cloneDeep(props.billingData);
    }
    baseAttribute = baseAttribute.filter((value) => {
      return value['displayName'].toLowerCase().includes(searchInput.target.value);
    });
    setSearchAttributeInput(searchInput.target.value);
    setSearchAttributeData(orderShow(baseAttribute));
  };

  const updateAttributePreference = (isChecked: boolean, keyName: string) => {
    let index = localUserAttributePreferences[currentAttribute].indexOf(keyName);
    let tempIndex = temporaryLocalUserAttributePreferences[currentAttribute].indexOf(keyName);
    if (isChecked && index === -1) {
      localUserAttributePreferences[currentAttribute].push(keyName);
    } else if (index > -1 && !isChecked) {
      localUserAttributePreferences[currentAttribute].splice(index, 1);
    } else if (tempIndex > -1 && !isChecked) {
      temporaryLocalUserAttributePreferences[currentAttribute].splice(tempIndex, 1);
    }
    // if any changes is made to the current list then push all the temporary ones to the local
    // one's and post to database when the modal closes and clear the temporary one
    localUserAttributePreferences[currentAttribute] = [
      ...localUserAttributePreferences[currentAttribute],
      ...temporaryLocalUserAttributePreferences[currentAttribute],
    ];
    temporaryLocalUserAttributePreferences[currentAttribute] = [];
    props.updateParentAttributePreferences(
      localUserAttributePreferences,
      temporaryLocalUserAttributePreferences,
      false
    );
  };
  const changeSearchTab = (currSearchAttr, currSearchAttrData) => {
    setSearchAttributeInput('');
    setIsSearchAttributeDataLoading(true);
    const wait = _debounce(() => {
      setCurrentAttribute(currSearchAttr);
      setSearchAttributeData(orderShow(currSearchAttrData));
      setIsSearchAttributeDataLoading(false);
    }, 100);
    wait();
  };
  const [showSearchAttributeModal, setshowSearchAttributeModal] = React.useState(false);

  const { data: modelData } = useModels();

  const dsEnabled = checkDsEnabled(modelData);

  return (
    <>
      <Card hover={false} className="rounded" style={{ border: 'none' }}>
        <div className={'flex justify-between items-center pb-4'}>
          <p className={'text-base font-medium text-tw-black-7'}>ATTRIBUTES</p>
          <Modal
            width={ModalWidth.lg}
            open={showSearchAttributeModal}
            title="Attributes"
            onClose={() => {
              props.updateParentAttributePreferences(
                localUserAttributePreferences,
                temporaryLocalUserAttributePreferences,
                true
              );

              setCurrentAttribute(currentAttribute);
              setSearchAttributeInput('');
              setshowSearchAttributeModal(false);
            }}
            trigger={
              <SecondaryButton
                className="text-tw-blue-0d"
                onClick={() => {
                  setshowSearchAttributeModal(true);
                  changeSearchTab(
                    currentAttribute,
                    currentAttribute === props.ATTRIBUTE_CONST.PROFILE_PROPERTIES
                      ? props.profilePropertiesData
                      : currentAttribute === props.ATTRIBUTE_CONST.BILLING
                      ? props.billingData
                      : props.productEventsData
                  );
                }}
              >
                <MagnifyingGlass />
                <span>Search attributes</span>
              </SecondaryButton>
            }
          >
            <div className={'mt-3 p-6'}>
              <InputGroup mb={5}>
                <InputLeftElement>
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  fontSize="14px"
                  onChange={setAttributesWithSearch}
                  value={searchAttributeInput}
                  placeholder="Search attributes"
                />
              </InputGroup>
              <div className={'mb-5'}>
                <AttributeBtn
                  isActive={currentAttribute === props.ATTRIBUTE_CONST.PRODUCT_EVENTS}
                  showLength={true}
                  length={
                    currentAttribute === props.ATTRIBUTE_CONST.PRODUCT_EVENTS
                      ? searchAttributeData.length
                      : Object.keys(props.productEventsData).length
                  }
                  title={'Product Events'}
                  clicked={() => {
                    setIsSearchAttributeDataLoading(true);
                    changeSearchTab(props.ATTRIBUTE_CONST.PRODUCT_EVENTS, props.productEventsData);
                  }}
                />
                {dsEnabled && (
                  <AttributeBtn
                    isActive={currentAttribute === props.ATTRIBUTE_CONST.BILLING}
                    showLength={true}
                    length={
                      currentAttribute === props.ATTRIBUTE_CONST.BILLING
                        ? searchAttributeData.length
                        : Object.keys(props.billingData).length
                    }
                    title={'Billing'}
                    clicked={() => {
                      setIsSearchAttributeDataLoading(true);
                      changeSearchTab(props.ATTRIBUTE_CONST.BILLING, props.billingData);
                    }}
                  />
                )}
                <AttributeBtn
                  isActive={currentAttribute === props.ATTRIBUTE_CONST.PROFILE_PROPERTIES}
                  showLength={true}
                  length={
                    currentAttribute === props.ATTRIBUTE_CONST.PROFILE_PROPERTIES
                      ? searchAttributeData.length
                      : Object.keys(props.profilePropertiesData).length
                  }
                  title={'profile Properties'}
                  clicked={() => {
                    setIsSearchAttributeDataLoading(true);
                    changeSearchTab(
                      props.ATTRIBUTE_CONST.PROFILE_PROPERTIES,
                      props.profilePropertiesData
                    );
                  }}
                />
              </div>
              <div className={'overflow-y-scroll h-72'}>
                {!isSearchAttributeDataLoading ? (
                  searchAttributeData.length ? (
                    searchAttributeData.map((item, index) => (
                      <AttributeToggleRow
                        key={`${item.keyName}-${index}`}
                        displayName={item.displayName}
                        value={item.value}
                        show={
                          localUserAttributePreferences[currentAttribute].indexOf(item.keyName) >
                            -1 ||
                          temporaryLocalUserAttributePreferences[currentAttribute].indexOf(
                            item.keyName
                          ) > -1
                        }
                        source={item?.source}
                        keyName={item.keyName}
                        onEnabled={updateAttributePreference}
                      />
                    ))
                  ) : (
                    <div className={'flex justify-center'}>
                      <p className={'text-2xl'}>No Data Available</p>
                    </div>
                  )
                ) : (
                  <div className={'justify-center flex'}>
                    <CircularProgress isIndeterminate size="8" />
                  </div>
                )}
              </div>
            </div>
          </Modal>
        </div>
        <hr />
        <div className={'felx justify-between my-4 mx-0'}>
          <div>
            <AttributeBtn
              isActive={currentAttribute === props.ATTRIBUTE_CONST.PRODUCT_EVENTS}
              showLength={false}
              title={'Product Events'}
              clicked={() => {
                setCurrentAttribute(props.ATTRIBUTE_CONST.PRODUCT_EVENTS);
              }}
            />
            {dsEnabled && (
              <AttributeBtn
                isActive={currentAttribute === props.ATTRIBUTE_CONST.BILLING}
                showLength={false}
                title={'Billing'}
                clicked={() => {
                  setCurrentAttribute(props.ATTRIBUTE_CONST.BILLING);
                }}
              />
            )}
            <AttributeBtn
              isActive={currentAttribute === props.ATTRIBUTE_CONST.PROFILE_PROPERTIES}
              showLength={false}
              title={'profile Properties'}
              clicked={() => {
                setCurrentAttribute(props.ATTRIBUTE_CONST.PROFILE_PROPERTIES);
              }}
            />
          </div>
          <div />
        </div>
        {currentAttribute === props.ATTRIBUTE_CONST.PRODUCT_EVENTS ? (
          <ProductEvents
            data={props.productEventsData}
            showMore={() => {
              setshowSearchAttributeModal(true);
              changeSearchTab(props.ATTRIBUTE_CONST.PRODUCT_EVENTS, props.productEventsData);
            }}
          />
        ) : currentAttribute === props.ATTRIBUTE_CONST.BILLING ? (
          <Billing
            data={props.billingData}
            billingLedger={props.billingLedger}
            billingHeroData={props.billingHeroData}
          />
        ) : (
          <ProfileProperties
            data={props.profilePropertiesData}
            showMore={() => {
              setshowSearchAttributeModal(true);
              changeSearchTab(
                props.ATTRIBUTE_CONST.PROFILE_PROPERTIES,
                props.profilePropertiesData
              );
            }}
          />
        )}
      </Card>
    </>
  );
};
export default Attribute;
