import { Model } from 'api-client';
import useModels from 'hooks/useModels';
import { DefinePQLSections, useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { useFiltersPQLStore } from 'pages/pql-workflow/store/PQLFiltersStore';
import { useEffect } from 'react';
import { QueryType } from 'stores/QueryStore';
import { PrimaryButton } from 'ui-components/inputs/Buttons';
import { MODELID_LABEL_FOR_ACCOUNTS, MODELID_LABEL_FOR_USERS } from 'utils/constants';
import useDsEnabled from '../../../../hooks/useDsEnabled';
import AccordionHeader from '../AccordionHeader';
import Card from '../../../../ui-components/business/Card';
import SectionAccordion from '../SectionAccordion';
import LockedModelId from './LockedModelId';

export const getModelName = (model: Model, type: QueryType) => {
  if (type === QueryType.accounts) {
    if (MODELID_LABEL_FOR_ACCOUNTS[model.modelId]) {
      return MODELID_LABEL_FOR_ACCOUNTS[model.modelId];
    }
  } else {
    if (MODELID_LABEL_FOR_USERS[model.modelId]) {
      return MODELID_LABEL_FOR_USERS[model.modelId];
    }
  }
  return model.name;
};

export default function SelectGoal() {
  const {
    currentSection,
    setSectionsMap,
    setCurrentSection,
    setCohorts,
    sectionsMap,
    modelId,
    setModelId,
    targetEntity,
  } = useDefinePQLStore();
  const { setFilters } = useFiltersPQLStore();

  const { data, isLoading, isError } = useModels();
  const dsEnabled = useDsEnabled(modelId, targetEntity);

  // pre-select modelId for the active entity only.
  // Also, When targetEntity changes and there is no modelId
  // a default modelId will be chosen from the list of available
  // models for the selected entity.
  useEffect(() => {
    const enabledModels = data?.filter((e) => e.active && e.entity === targetEntity?.toLowerCase());
    if (enabledModels?.length >= 1 && !modelId) {
      setModelId(enabledModels[0].modelId);
    }
  }, [data, setModelId, targetEntity]);

  // doesn't show the section if the previous section is not finished
  if (!sectionsMap[DefinePQLSections.ENTITIES] || currentSection === DefinePQLSections.ENTITIES) {
    return null;
  }
  const selectedModel = data?.find((m) => m.modelId === modelId);
  return (
    <div>
      <hr className="w-full" />
      <SectionAccordion
        id={DefinePQLSections.GOALS}
        hasPadding={true}
        accordionHeader={
          <AccordionHeader
            onClick={() => {
              // if not on current section, mark it as current,
              // then mark all other next steps to not finished
              let newSectionsMap = {};
              let refIndex = Object.keys(sectionsMap).indexOf(DefinePQLSections.GOALS);
              (Object.keys(DefinePQLSections) as Array<keyof typeof DefinePQLSections>).forEach(
                (key, index) => {
                  if (index >= refIndex) {
                    newSectionsMap[key.toLowerCase()] = false;
                  }
                }
              );
              setSectionsMap({
                ...sectionsMap,
                ...newSectionsMap,
              });
            }}
          >
            {modelId
              ? targetEntity === QueryType.users
                ? MODELID_LABEL_FOR_USERS[selectedModel?.modelId]
                : MODELID_LABEL_FOR_ACCOUNTS[selectedModel?.modelId]
              : 'Goal for this Playbook'}
          </AccordionHeader>
        }
        expanded={currentSection === DefinePQLSections.GOALS}
      >
        <div className="font-medium rounded-b-xl text-tw-black-1">Pick your business goal</div>
        <div className="grid grid-cols-2 col-span-3 mt-4 lg:grid-cols-3 auto-rows-min gap-y-4">
          {isLoading || isError ? (
            <div className="flex animate-pulse">
              <div className="h-20 rounded-lg bg-tw-gray-eb w-72"></div>
              <div className="h-20 ml-4 rounded-lg bg-tw-gray-eb w-72"></div>
            </div>
          ) : (
            data
              .filter((d: Model) => targetEntity.toLowerCase() === d.entity)
              .sort((a, b) => {
                if (a.active && b.active) {
                  return 0;
                }
                return a.active ? -1 : 1;
              })
              .map((model: Model) => {
                if (!model.active) {
                  return <LockedModelId key={model.id} model={model} />;
                }
                return (
                  <Card
                    isActive={model.modelId === modelId}
                    onClick={() => {
                      setModelId(model.modelId);
                      if (modelId !== model.modelId) {
                        // changing modelId, so reset filters
                        // and cohort
                        setFilters([]);
                        setCohorts([]);
                      }
                    }}
                    cardClassname={model.modelId === 'conversion' ? 'product-tour-model' : ''}
                    key={model.id}
                  >
                    <div className="flex items-center gap-x-2">
                      {getModelName(model, targetEntity as QueryType)}
                    </div>
                  </Card>
                );
              })
          )}
        </div>
        <div className="mt-6">
          <PrimaryButton
            className="w-full product-tour-3"
            onClick={() => {
              const newSectionsMap = {
                ...sectionsMap,
                // mark entities as finished
                [DefinePQLSections.GOALS]: true,
              };
              setSectionsMap(newSectionsMap);
              if (dsEnabled) {
                setCurrentSection(DefinePQLSections.HWC);
              } else {
                setCurrentSection(DefinePQLSections.CONVERSION_EVENT);
              }
            }}
            disabled={!modelId}
          >
            Proceed
          </PrimaryButton>
        </div>
      </SectionAccordion>
    </div>
  );
}
