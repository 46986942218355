import { ErrorBoundary } from 'react-error-boundary';
import { capitalizeFirstLetter, getCategorizedIntegrations } from 'utils/common';
import { useAuthStore } from 'hooks/useAuthStore';
import Tab from 'pages/pql-workflow/components/Tab';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  ConnectedIntegration,
  InactiveIntegrations,
  Integration,
  IntegrationType,
} from '../../api-client';
import useConnectedIntegrations from '../../hooks/useConnectedIntegrations';
import useInactiveIntegrations from './hooks/useInactiveIntegrations';
import useIntegrations from './hooks/useIntegrations';
import { useIntegrationListStore } from './stores/IntegrationListStore';
import PanelForDestination from './sub-components/PanelForDestination';
import Webhooks from './Webhooks';
import ErrorBoundaryFallback from 'ui-components/feedback/ErrorBoundaryFallback';
import { useAnalytics } from 'telemetry';
import { ErrorAnalyticsEvents } from 'telemetry/constants';

const tabs: Record<number, string> = {
  0: 'apps',
  1: 'webhooks',
};

export interface IntegrationsState {
  connected: Record<string, ConnectedIntegration[]>;
  available: Record<string, Integration[]>;
  inactive: Record<string, InactiveIntegrations[]>;
}

const IntegrationListPage: React.FC<{}> = () => {
  const { getAccountId } = useAuthStore();
  const { getAccountSchemaName } = useAuthStore();
  const { track } = useAnalytics();

  useInactiveIntegrations();
  const { setConnectedIntegrationsBasedOnCategory } = useIntegrationListStore();
  const { data: connectedIntegrations, isSuccess } = useConnectedIntegrations(
    true,
    getAccountSchemaName()
  );

  const { data: integrations, isLoading: isIntegrationsLoading } = useIntegrations();

  const router = useHistory();
  const [defaultIndex, setDefaultIndex] = useState(0);

  useEffect(() => {
    if (router.location.search.includes('webhooks')) {
      setDefaultIndex(1);
    } else {
      setDefaultIndex(0);
    }
  }, [router.location.search]);

  useEffect(() => {
    if (connectedIntegrations && isSuccess) {
      setConnectedIntegrationsBasedOnCategory(getCategorizedIntegrations(connectedIntegrations));
    }
  }, [connectedIntegrations, isSuccess]);

  useEffect(() => {
    if (!router.location.search) {
      router.push(`/${getAccountId()}/integrations?type=apps`);
    }
  }, [getAccountId, router]);

  const findWebhookId = useCallback((integrationsList: Integration[]): number => {
    return integrationsList?.filter((i) => i.type == IntegrationType.WEBHOOK)[0]?.['id'];
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={(e) =>
        track(ErrorAnalyticsEvents.ERROR_OCCURED, {
          error: e.message,
        })
      }
    >
      {/* comment the below line when enabling integration header */}
      {/* <div className="px-16 pb-8 bg-white shadow-sm 2xl:px-20"></div> */}
      {/* <IntegrationHeader btnIndex=  {btnIndex} setBtnIndex={setBtnIndex} /> */}
      <div className="flex flex-col px-6 mt-6 min-h-88vh">
        <div className="flex items-center gap-x-4">
          {Object.keys(tabs).map((key) => (
            <Tab
              key={key}
              label={capitalizeFirstLetter(tabs[key])}
              onClick={() => {
                setDefaultIndex(Number(key));
              }}
              isActive={defaultIndex === Number(key)}
            />
          ))}
        </div>
        <div>
          {/* Debug: 1 */}
          {defaultIndex === 0 && (
            <PanelForDestination isIntegrationsLoading={isIntegrationsLoading} />
          )}
          {defaultIndex === 1 && <Webhooks webhookIntegrationId={findWebhookId(integrations)} />}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default IntegrationListPage;
