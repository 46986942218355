import { ConnectedIntegration, ConnectedIntegrationStatus, IntegrationType } from 'api-client';
import dayjs from 'dayjs';
import useConnectedIntegrations from 'hooks/useConnectedIntegrations';
import IntegrationIcon from 'pages/integrations/sub-components/IntegrationIcon';
import { ExportPQLSections, useExportPQLStore } from 'pages/pql-workflow/store/PQLExportStore';
import { useEffect } from 'react';
import { InfoRound } from 'ui-components/data-display/Icons';
import { PrimaryButton } from 'ui-components/inputs/Buttons';
import MultiInput from 'ui-components/inputs/MultiInput';
import { capitalizeFirstLetter, emailRegExp } from 'utils/common';

import { trim } from 'utils/helpers';
import AccordionHeader from '../AccordionHeader';
import Card from '../../../../ui-components/business/Card';
import SectionAccordion from '../SectionAccordion';
import SideTab from '../SideTab';
import ConnectDestination from './ConnectDestination';
import { IntegrationIcons } from './ExportSubSections';

function getDestinationForTabs(connectedIntegrations, tabName) {
  return connectedIntegrations
    ?.filter((d) => d.integration?.name === tabName)
    .filter((d) => {
      return (
        ![ConnectedIntegrationStatus.IN_PROGRESS, ConnectedIntegrationStatus.ERROR].includes(
          d.status
        ) && d.active
      );
    });
}

function useDestinationTabs() {
  const { destinationId, activeIntegrationTab, setActiveIntegrationTab, setDestinationId } =
    useExportPQLStore();
  const { data, isLoading: loadingIntegrations } = useConnectedIntegrations(false);

  const tabsToShow = [
    ...new Set(
      data
        ?.filter(
          (d) =>
            d.integration.type !== IntegrationType.API &&
            ![ConnectedIntegrationStatus.IN_PROGRESS, ConnectedIntegrationStatus.ERROR].includes(
              d.status
            ) &&
            d.integration.supportsWrite &&
            d.active
        )
        .sort((a, b) => (a.id > b.id ? 1 : -1))
        .map((item) => item.integration.name)
    ),
  ];

  useEffect(() => {
    if (!destinationId && tabsToShow?.length > 1) {
      setActiveIntegrationTab(tabsToShow[1]);
      setDestinationId(getDestinationForTabs(data, tabsToShow[1])?.[0]?.id);
    }
    if (destinationId) {
      setActiveIntegrationTab(data.find((i) => i.id === destinationId)?.integration.name);
    }
  }, [destinationId, data, setActiveIntegrationTab]);

  return {
    activeIntegrationTab,
    setActiveIntegrationTab,
    tabsToShow,
    loadingIntegrations,
  };
}

export default function Destination() {
  const {
    currentSection,
    destinationId,
    setDestinationId,
    setExtraFormData,
    // destinationSearchQuery,
    // setDestinationSearchQuery,
    setCurrentSection,
    emails,
    setEmails,
    sectionsMap,
    setSectionsMap,
    setObject,
    setUniqueKeys,
    setMappings,
    showConnectSection,
    setShowConnectSection,
    showDrawer,
    setShowDrawer,
  } = useExportPQLStore();
  const { tabsToShow, activeIntegrationTab, setActiveIntegrationTab, loadingIntegrations } =
    useDestinationTabs();
  const { data: connectedIntegrations } = useConnectedIntegrations(false);
  const destinationsToShow = getDestinationForTabs(connectedIntegrations, activeIntegrationTab);

  const emailDestination = connectedIntegrations?.find((d) => d.type === IntegrationType.EMAIL);

  const onDestinationChange = (destination: ConnectedIntegration) => {
    setDestinationId(destination.id);
    setExtraFormData(null);
    setUniqueKeys([{ source: '', destination: '' }]);
    setObject('');
    // TODO new mappings can be based on model selection
    const newMappings = [];
    if (destination.type === IntegrationType.PIPEDRIVE) {
      setMappings([{ source: '', destination: 'name' }, ...newMappings]);
    } else if (destination.integration.supportsEntities) {
      setMappings(newMappings);
    }
  };

  const selectedDestination = connectedIntegrations?.find((i) => i.id === destinationId);

  return (
    <SectionAccordion
      id={ExportPQLSections.DESTINATION}
      hasPadding={true}
      accordionHeader={
        <AccordionHeader
          onClick={() => {
            // if not on current section, mark it as current,
            // then mark all other next steps to not finished
            let newSectionsMap = {};
            let refIndex = Object.keys(sectionsMap).indexOf(ExportPQLSections.DESTINATION);
            (Object.keys(ExportPQLSections) as Array<keyof typeof ExportPQLSections>).forEach(
              (key, index) => {
                if (index >= refIndex) {
                  newSectionsMap[key.toLowerCase()] = false;
                }
              }
            );
            setSectionsMap({
              ...sectionsMap,
              ...newSectionsMap,
            });
          }}
        >
          <span>Sync to: </span>
          <IntegrationIcon
            height={24}
            width={24}
            type={selectedDestination?.type}
            className="flex-none w-4 h-4 mx-1"
            logoUrl={selectedDestination?.integration.logoUrl}
          />
          <span>{selectedDestination?.name}</span>
        </AccordionHeader>
      }
      expanded={currentSection === ExportPQLSections.DESTINATION}
    >
      <h4 className="font-medium text-tw-black-1">Pick a destination</h4>
      <div className="py-4 mt-6 border rounded-lg border-tw-gray-eb">
        {/*
          TODO: Have commented out search as per discussion with @rkhanna@toplyne.io
        */}
        {/* <div className="ml-4">
          <Search
            placeholder="Search"
            onChangeFunction={(event) => setDestinationSearchQuery(event.target.value)}
            onClearFunction={() => setDestinationSearchQuery('')}
            value={destinationSearchQuery}
            hasShadow={false}
          />
        </div> */}
        <div className="grid grid-cols-4 mt-6 h-[300px]">
          <div>
            <div className="flex flex-col max-h-[250px] overflow-auto">
              {loadingIntegrations ? (
                <div className="animate-pulse">
                  {Array(2)
                    .fill(0)
                    .map((_, id) => (
                      <div key={id} className="h-8 mx-2 mb-4 bg-tw-gray-eb"></div>
                    ))}
                </div>
              ) : (
                tabsToShow.map((tab) => (
                  <SideTab
                    key={tab}
                    label={tab === 'Email' ? 'CSV export' : capitalizeFirstLetter(tab)}
                    isActive={tab === activeIntegrationTab && !showConnectSection}
                    onClick={() => {
                      if (tab === 'Email') {
                        setDestinationId(emailDestination?.id);
                      } else {
                        const localDestinationsToShow = getDestinationForTabs(
                          connectedIntegrations,
                          tab
                        );
                        setDestinationId(localDestinationsToShow?.[0]?.id);
                      }
                      setActiveIntegrationTab(tab);
                      setShowConnectSection(false);
                    }}
                  />
                ))
              )}
            </div>
            {!loadingIntegrations && (
              <>
                <hr className="ml-3 mr-6" />
                <div className="mt-4">
                  <SideTab
                    label="More apps"
                    isActive={showConnectSection}
                    onClick={() => {
                      setShowConnectSection(true);
                      setDestinationId(null);
                    }}
                  />
                </div>
              </>
            )}
          </div>
          {!showConnectSection && (
            <div className="grid grid-cols-2 col-span-3 overflow-y-auto auto-rows-min gap-y-4">
              {loadingIntegrations && (
                <>
                  {Array(2)
                    .fill(0)
                    .map((_, id) => (
                      <div
                        key={id}
                        className="w-48 h-20 mx-2 mb-4 mr-8 rounded-lg bg-tw-gray-eb"
                      ></div>
                    ))}
                </>
              )}
              {activeIntegrationTab !== 'Email' &&
                destinationsToShow?.map((d: ConnectedIntegration) => {
                  return (
                    <Card
                      isActive={d.id === destinationId}
                      onClick={() => onDestinationChange(d)}
                      key={d.id}
                    >
                      <div className="flex items-center gap-x-3">
                        <IntegrationIcon
                          className="flex-none w-8"
                          type={d.type}
                          logoUrl={d.integration.logoUrl}
                        />
                        <div>
                          <h4 className="font-medium break-all text-tw-black-1">
                            {trim(d.name, 42)}
                          </h4>
                          <p className="mt-1 text-xs font-normal text-tw-black-7">
                            Created on {dayjs(d.createdAt).tz().format('DD MMM, YYYY')}
                          </p>
                        </div>
                      </div>
                    </Card>
                  );
                })}

              {!loadingIntegrations && activeIntegrationTab === 'Email' && (
                <div className="col-span-2">
                  <div className="mb-2 text-tw-black-5">We will email you the CSV file</div>
                  <MultiInput
                    value={emails}
                    validation={emailRegExp}
                    onChange={(selectedItems) => {
                      setEmails(selectedItems);
                      if (selectedItems.length) {
                        onDestinationChange(emailDestination);
                      } else {
                        setDestinationId(null);
                      }
                    }}
                  />
                  <div className="mt-1.5 flex items-center gap-2">
                    <InfoRound />
                    <div className="flex text-xs text-tw-black-9 ">
                      Enter multiple emails, separated by comma (,)
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {showConnectSection && !loadingIntegrations && (
            <div className="flex flex-col items-center justify-center col-span-3 mx-8 mb-8 border rounded-lg border-tw-gray-eb bg-tw-gray-f5">
              <IntegrationIcons />
              <p className="mt-4 text-base font-semibold text-tw-black-3">
                Connect your applications
              </p>
              <PrimaryButton className="mt-6">
                <span className="font-medium" onClick={() => setShowDrawer(true)}>
                  See available apps
                </span>
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
      <PrimaryButton
        onClick={() => {
          setCurrentSection(ExportPQLSections.MAPPINGS);
        }}
        className="w-full py-2 mt-6 product-tour-12"
        disabled={destinationId === emailDestination?.id ? emails.length === 0 : !destinationId}
      >
        Proceed
      </PrimaryButton>

      <ConnectDestination showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
    </SectionAccordion>
  );
}
