import { useHistory } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import useDeleteOpportunity from '../opportunities/hooks/useDeleteOpportunity';
import useOpportunityAction from '../opportunities/hooks/useOpportunityAction';
import { useDefinePQLStore } from '../pql-workflow/store/PQLDefineStore';
import { useFiltersPQLStore } from '../pql-workflow/store/PQLFiltersStore';
import { useExportPQLStore } from '../pql-workflow/store/PQLExportStore';
import { usePQLWorkflowStore } from '../pql-workflow/store/PQLWorkflowStore';
import { toast as toastify } from 'react-toastify';
import { useAuthStore } from '../../hooks/useAuthStore';
import { useQueryClient } from 'react-query';
import { CopyIcon, CrossIcon, EditIcon, CircleStop } from '../../ui-components/data-display/Icons';
import IntegrationIcon from '../integrations/sub-components/IntegrationIcon';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { classNames } from '../../utils/common';
import { trim } from 'utils/helpers';
import useUpdateOpportunity from './hooks/useUpdateOpportunity';
import { OpportunityResponse } from 'ui-components/business/opportunities/types';
import { PQL_STATS_PAGE_PADDING_CLASSNAME } from './NewOpDetails';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';
import { SecondaryButton } from 'ui-components/inputs/Buttons';

type StatusItem = {
  label: string;
  icon: React.ReactElement;
  onClick: () => void;
  color: string;
};

const statusToActionMap = {
  active: ['edit', 'archive'],
  stopped: ['edit', 'archive'],
  failed: ['edit', 'archive'],
  running: ['edit', 'archive'],
  completed: ['archive'],
  scheduled: ['edit', 'archive'],
};

const allowedStatusToStopPQL = ['active', 'running', 'scheduled'];

type PQLDescriptionProps = {
  opportunity: OpportunityResponse;
};

export default function PQlDescription(props: PQLDescriptionProps) {
  const { reset: resetDefineStore } = useDefinePQLStore();
  const { reset: resetFiltersStore } = useFiltersPQLStore();
  const { reset: resetExportStore } = useExportPQLStore();
  const { reset: resetPQLWorkflowStore } = usePQLWorkflowStore();

  const { opportunity } = props;
  const router = useHistory();
  const [isEditing, setIsEditing] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [pqlName, setPqlName] = useState(opportunity.name);
  const deleteAnOpportunity = useDeleteOpportunity();
  const actionOnOpportunity = useOpportunityAction();
  const updateOpportunity = useUpdateOpportunity(opportunity.id);
  const { getAccountId } = useAuthStore();
  const queryClient = useQueryClient();

  const actionOpportunity = useCallback(
    (oppId: number, action: string) => {
      const param = { id: oppId, action: action };
      actionOnOpportunity.mutate(param, {
        onSuccess: () => {
          if (action === 'start') {
            toastify(<SuccessToast description="Successfully started the Playbook" />, {
              type: 'success',
            });
          } else if (action === 'stop') {
            toastify(<SuccessToast description="Successfully stopped the Playbook" />, {
              type: 'success',
            });
          } else {
            toastify(<SuccessToast description="Successfully executed the operation" />, {
              type: 'success',
            });
          }
        },
        onError: () => {
          toastify(<ErrorToast />, {
            type: 'error',
          });
        },
      });
    },
    [actionOnOpportunity]
  );

  const stopOpportunity = useCallback(
    (oppId: number) => {
      actionOpportunity(oppId, 'stop');
    },
    [actionOpportunity]
  );

  const deleteOpportunity = useCallback(
    (oppId: number) => {
      deleteAnOpportunity.mutate(oppId, {
        onSuccess: () => {
          router.push(`/${getAccountId()}/opportunities/list`);
        },
        onSettled: () => {},
      });
    },
    [deleteAnOpportunity]
  );

  const updateOpportunityName = () => {
    setIsEditing(false);
    updateOpportunity.mutate(
      {
        opportunityId: opportunity.id,
        name: pqlName,
      },
      {
        onError: () => {
          toastify(<ErrorToast />, {
            type: 'error',
          });
        },
      }
    );
  };

  const actionMap = useMemo<Record<string, StatusItem>>(() => {
    return {
      edit: {
        label: 'Edit sync fields',
        color: 'text-tw-black-3',
        icon: <EditIcon />,
        onClick: () => {
          queryClient.removeQueries(['opportunity']);
          resetDefineStore();
          resetFiltersStore();
          resetExportStore();
          resetPQLWorkflowStore();
          router.push(`/${getAccountId()}/opportunities/edit-exports/${opportunity.id}`);
        },
      },
      archive: {
        label: 'Archive',
        color: 'text-tw-red-dd',
        icon: <CrossIcon className="fill-current " />,
        onClick: () => {
          deleteOpportunity(opportunity.id);
        },
      },
    };
  }, [
    deleteOpportunity,
    getAccountId,
    opportunity.id,
    queryClient,
    resetDefineStore,
    resetExportStore,
    resetFiltersStore,
    resetPQLWorkflowStore,
    router,
  ]);
  return (
    <div
      onMouseOver={() => {
        setShowEditIcon(true);
      }}
      onMouseLeave={() => {
        setShowEditIcon(false);
      }}
      className={classNames(
        'flex items-center justify-between w-full h-24 mt-6 gap-x-4 z-5',
        PQL_STATS_PAGE_PADDING_CLASSNAME
      )}
    >
      <div className="flex items-start mt-4 gap-x-4">
        {opportunity.destination && (
          <div className="w-14 h-14 rounded-md border-1 border-tw-gray-eb py-3 px-2.5">
            <IntegrationIcon
              type={opportunity.destination?.type}
              logoUrl={opportunity.exports[0].destination?.integration?.logoUrl}
              width={32}
              height={32}
            />
          </div>
        )}
        {isEditing && (
          <input
            onBlur={() => {
              updateOpportunityName();
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.key === 'Escape') {
                updateOpportunityName();
              }
            }}
            autoFocus
            value={pqlName}
            onChange={(e) => {
              setPqlName(e.target.value);
            }}
            className="px-2 py-2 mt-2 font-medium bg-transparent rounded-lg w-96 h-11 text-tw-black-5 border-1 border-tw-gray-eb "
          />
        )}
        {!isEditing && (
          <div className="flex py-2.5 gap-4">
            <div className="text-2xl font-weight-bold text-tw-black-1 max-w-md">
              {trim(pqlName, 40)}
            </div>
            {showEditIcon && (
              <EditIcon
                className="w-4 h-4 my-2 cursor-pointer text-tw-black-2"
                onClick={() => {
                  setIsEditing(true);
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className="flex mt-4 gap-x-3">
        {allowedStatusToStopPQL.includes(opportunity.status) && (
          <SecondaryButton
            className="flex items-center !font-medium text-sm h-8 px-4 text-black rounded cursor-pointer gap-x-2 !bg-tw-gray-f5"
            onClick={() => {
              stopOpportunity(opportunity.id);
            }}
            isLoading={actionOnOpportunity.isLoading}
          >
            <CircleStop className="w-3 h-4" />
            <span>Stop</span>
          </SecondaryButton>
        )}
        {!opportunity.isSample && (
          <div
            className="flex items-center h-8 px-4 font-medium text-black rounded cursor-pointer gap-x-2 bg-tw-gray-f5"
            onClick={() => {
              queryClient.removeQueries(['opportunity']);
              resetDefineStore();
              resetFiltersStore();
              resetExportStore();
              resetPQLWorkflowStore();
              router.push(`/${getAccountId()}/opportunities/duplicate/${opportunity.id}`);
            }}
          >
            <CopyIcon className="w-3 h-3" />
            <span>Duplicate</span>
          </div>
        )}
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <div className="flex items-center h-8 px-4 font-medium text-black rounded gap-x-2 bg-tw-gray-f5">
              <span style={{ marginTop: -7 }}>...</span>
              <span>More</span>
            </div>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content className="py-2 mb-8 bg-white rounded-lg shadow-lg" align={'start'}>
            {opportunity.status &&
              statusToActionMap[opportunity.status]?.map((s) => (
                <DropdownMenu.Item
                  key={s}
                  className="px-4 py-2 cursor-pointer hover:bg-tw-gray-f9"
                  onClick={actionMap[s].onClick}
                >
                  <div className={classNames('flex items-center h-6 gap-x-2', actionMap[s].color)}>
                    <div className="flex-none w-2">{actionMap[s].icon}</div>
                    <p>{actionMap[s].label}</p>
                  </div>
                </DropdownMenu.Item>
              ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
    </div>
  );
}
