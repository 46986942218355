import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { ConnectedIntegration, InactiveIntegrations, Integration } from '../../../api-client';

type IntegrationListStore = {
  setSearchQuery: (searchQuery: string) => void;
  setAvailableIntegrationsBasedOnCategory: (availableIntegrationsBasedOnCategory) => void;
  setInactiveIntegrationsBasedOnCategory: (inactiveIntegrationsBasedOnCategory) => void;
  setConnectedIntegrationsBasedOnCategory: (connectedIntegrationsBasedOnCategory) => void;
} & IntegrationListState;

type IntegrationListState = {
  searchQuery: string;
  availableIntegrationsBasedOnCategory: Record<string, Array<Integration>>;
  inactiveIntegrationsBasedOnCategory: Record<string, Array<InactiveIntegrations>>;
  connectedIntegrationsBasedOnCategory: Record<string, Array<ConnectedIntegration>>;
};

const initialState: IntegrationListState = {
  searchQuery: '',
  availableIntegrationsBasedOnCategory: null,
  inactiveIntegrationsBasedOnCategory: null,
  connectedIntegrationsBasedOnCategory: null,
};

export const useIntegrationListStore = create<IntegrationListStore>(
  devtools((set) => {
    return {
      ...initialState,
      setSearchQuery: (searchQuery: string) => {
        set((state) => ({
          ...state,
          searchQuery,
        }));
      },
      setAvailableIntegrationsBasedOnCategory: (availableIntegrationsBasedOnCategory) => {
        set((state) => ({
          ...state,
          availableIntegrationsBasedOnCategory,
        }));
      },
      setInactiveIntegrationsBasedOnCategory: (inactiveIntegrationsBasedOnCategory) => {
        set((state) => ({
          ...state,
          inactiveIntegrationsBasedOnCategory,
        }));
      },
      setConnectedIntegrationsBasedOnCategory: (connectedIntegrationsBasedOnCategory) => {
        set((state) => ({
          ...state,
          connectedIntegrationsBasedOnCategory,
        }));
      },
    };
  })
);
