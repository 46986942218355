import axios from 'axios';
import { BASE_URL } from 'utils/environment';
import { v4 as uuidv4 } from 'uuid';

type RefreshTokenResponse = {
  access: string;
  refresh: string;
};

const whitelistedErrorMessages = ['The url does not exist. Please enter a valid one'];

export function createClient(
  accountId: string,
  refreshAuthToken: () => Promise<RefreshTokenResponse>,
  onError: () => void
) {
  const client = axios.create({
    baseURL: BASE_URL + '/',
  });
  client.defaults.headers.common['TL-ORG'] = accountId || '';

  client.interceptors.request.use(
    function (config) {
      config.headers.common['X-Request-ID'] = uuidv4();
      return config;
    },
    function (error) {
      // eslint-disable-next-line no-console
      console.error(`Error making request`, error);
      return Promise.reject(error);
    }
  );
  client.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      // storing orginal request
      const originalRequest = error.config;
      const status = error?.response?.status || 0;
      const errorMessage = error?.response?.data?.detail ?? '';
      if (status === 401 && !originalRequest._retry) {
        // avoiding infinite loop of 401
        originalRequest._retry = true;
        return refreshAuthToken().then((data) => {
          originalRequest.headers.Authorization = `Bearer ${data.access}`;
          originalRequest.__isRetryRequest = true;
          return client(originalRequest);
        });
      } else if (!status || status >= 500) {
        if (
          !whitelistedErrorMessages.includes(errorMessage) &&
          !originalRequest?.url?.includes('multiple-pql-performance') &&
          !originalRequest.headers?.['is-prefetching']
        ) {
          onError();
        }
      }
      return Promise.reject(error);
    }
  );
  return client;
}
