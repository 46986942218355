import { useMutation } from 'react-query';
import { toast as toastify } from 'react-toastify';
import { useClient } from '../../../api-client';
import { useHistory } from 'react-router-dom';
import { ResetPasswordState } from '../ResetPassword';
import { AxiosError } from 'api-client';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';

export default function useSendPasswordResetEmail() {
  const client = useClient();
  const router = useHistory();
  return useMutation((data: ResetPasswordState) => client.sendPasswordResetEmail(data.email), {
    onSuccess: (resp, data) => {
      router.push({ pathname: '/password/reset/email/confirm', state: { email: data.email } });
    },
    onError: (error: AxiosError) => {
      const errorObj = error?.response?.data?.detail ?? {};
      const errorDetailKey = Object.keys(errorObj)?.[0] ?? '';
      toastify(
        <ErrorToast description={errorObj?.[errorDetailKey] ?? 'Uh oh! Something went wrong'} />,
        {
          type: 'error',
        }
      );
    },
  });
}
