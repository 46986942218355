import { ReactComponent as EditIcon } from 'assets/images/editIcon.svg';
import { ReactComponent as EmptyFolder } from 'assets/images/empty-folder.svg';
import dayjs from 'dayjs';
import { useAuthStore } from 'hooks/useAuthStore';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useTable } from 'react-table';
import { IntegrationType } from '../../../api-client';
import Copy from '../../../ui-components/util/Copy';
import useRole from '../../../hooks/useRole';
import useConnectedIntegrations from '../../../hooks/useConnectedIntegrations';
import { SecondaryButton } from '../../../ui-components/inputs/Buttons';

interface TableColumn {
  [key: string]: number | string | boolean;
}

export default function WebhookTable() {
  const { getAccountId } = useAuthStore();
  const { data: connectedIntegrations, isLoading } = useConnectedIntegrations();
  const router = useHistory();
  const { isReadOnly } = useRole();

  const data = useMemo<TableColumn[]>(
    () =>
      connectedIntegrations
        ?.filter((i) => i.type === IntegrationType.WEBHOOK)
        .map((i) => {
          return {
            active: i.active,
            itemId: i.id,
            name: i.name,
            url: i.metadata?.webhook.url,
            createdBy: `${i.createdBy.firstName ?? ''} ${i.createdBy.lastName ?? ''}`,
            createdAt: dayjs.tz(i.createdAt).format('DD MMM, YYYY hh:mm A'),
            type: i.type,
            metadata: i.metadata,
          };
        })
        .sort((a, b) => {
          return dayjs.tz(a.createdAt).diff(dayjs(b.createdAt)) < 0 ? 1 : -1;
        }) || [],
    [connectedIntegrations]
  );
  const [columns, setColumns] = useState<
    {
      Header: any;
      accessor: string;
      Cell?: ({ row, column, cell }: { row: any; column: any; cell: any }) => any;
    }[]
  >([]);

  const editWebHook = useCallback(
    (rowData) => {
      router.push({
        pathname: `/${getAccountId()}/integrations/webhook/${rowData.row.original.itemId}/edit/`,
      });
    },
    [getAccountId, router]
  );

  useEffect(() => {
    setColumns([
      {
        Header: 'NAME',
        accessor: 'name',
      },
      {
        Header: 'URL',
        accessor: 'url',
        Cell: ({ cell }) => {
          return <Copy value={cell.value} trackTitle={'Webhook URL copied from Webhook Table'} />;
        },
      },
      {
        Header: 'CREATED BY',
        accessor: 'createdBy',
        Cell: ({ cell }) => {
          return <div>{cell.value?.toString().trim() || '--'}</div>;
        },
      },
      {
        Header: 'CREATED ON',
        accessor: 'createdAt',
      },
      {
        Header: '',
        accessor: 'edit',
        Cell: (row) => {
          if (isLoading) {
            return <div className="flex items-center w-full h-6 bg-tw-gray-eb animate-pulse" />;
          }

          return (
            <SecondaryButton onClick={() => editWebHook(row)}>
              <div className="flex items-center">
                <div>
                  <div className="mr-1">
                    <EditIcon />
                  </div>
                  <div className="font-medium text-tw-black-3">Edit</div>
                </div>
              </div>
            </SecondaryButton>
          );
        },
      },
    ]);

    if (isReadOnly) {
      let items = columns.filter((item) => {
        return item.accessor !== 'edit';
      });
      if (items.length) setColumns([...items]);
    }
  }, [isReadOnly, editWebHook, isLoading]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    initialState: {},
  });

  return (
    <div className="overflow-x-auto">
      <table {...getTableProps()} className="w-full">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  key={column.id}
                  {...column.getHeaderProps()}
                  className="w-64 py-3 pl-4 pr-2 text-tw-black-7 bg-tw-gray-f9"
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr key={row.id} {...row.getRowProps()} className="even:bg-tw-gray-f9">
                {row.cells.map((cell) => (
                  <td
                    key={cell.row.id}
                    {...cell.getCellProps()}
                    className="w-64 py-3 pl-4 pr-2 max-w-3xl"
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {isLoading && (
        <div className="animate-pulse">
          {Array(10)
            .fill(0)
            .map((v, idx) => (
              <div key={idx} className="w-full h-12 even:bg-tw-gray-eb"></div>
            ))}
        </div>
      )}
      {!isLoading &&
        !connectedIntegrations?.filter((i) => i.type === IntegrationType.WEBHOOK).length && (
          <div>
            <div className="grid pt-20 place-items-center text-tw-black-5">
              <EmptyFolder />
              <p className="mt-4">There are no Webhooks added yet.</p>
              <p> Add one to sync a playbook.</p>
            </div>
          </div>
        )}
    </div>
  );
}
