import verifyEmailSrc from 'assets/images/verify-email.png';
import { ArrowBack, SpinnerIcon } from 'ui-components/data-display/Icons';
import AlternateTopNav from 'ui-components/business/navbars/AlternateTopNav';
import { useHistory } from 'react-router-dom';
import useResendVerificationEmail from './hooks/useResendEmailVerification';

export default function EmailVerificationSent() {
  const router = useHistory();
  const params = new URLSearchParams(router.location.search.replace('+', '%2b'));
  const resentVerificationEmail = useResendVerificationEmail();

  const handleResendVerificationEmail = () => {
    resentVerificationEmail.mutate(params.get('email'));
  };

  return (
    <div className="flex flex-col h-screen">
      <AlternateTopNav className={'mt-6 ml-8'} />
      <div className="flex flex-col items-center w-1/3 pb-8 mx-auto mt-12 border rounded-xl border-tw-gray-eb">
        <img src={verifyEmailSrc} alt="Verify Email" className="w-2/3" />
        <h2 className="text-h2">Verify your email</h2>
        <p className="mt-3 text-tw-black-7">
          We have sent a verification link to {params.get('email')}
        </p>
        <button
          onClick={handleResendVerificationEmail}
          className="mt-16 font-medium text-tw-blue-0d"
        >
          {resentVerificationEmail.isLoading ? (
            <SpinnerIcon className="w-5 h-5 fill-current text-tw-blue-0d animate-spin" />
          ) : (
            'Didn’t get the email? Send it again'
          )}
        </button>
        <button onClick={() => router.goBack()} className="flex gap-x-1.5 text-tw-black-5 mt-6">
          <ArrowBack className="w-2.5 pt-1.5" />
          <span>Back</span>
        </button>
      </div>
    </div>
  );
}
