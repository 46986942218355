import create from 'zustand';
import { ColumnType } from './AccountsStore';
import { devtools } from 'zustand/middleware';
import { FilterExpression, SortBy } from 'pages/users-and-accounts/users/types';

type UsersStore = {
  page: number;
  setPage: (page: number) => void;
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  field_mapping: Record<string, ColumnType>;
  initFieldMappings: (initialMappings) => void;
  addFieldMapping: (obj: ColumnType) => void;
  removeFieldMapping: (keyName: string) => void;
  sortBy: Array<SortBy>;
  setSortBy: (obj: Array<SortBy>) => void;
  filters: Array<FilterExpression>;
  setFilters: (obj: Array<FilterExpression>) => void;
};

const initialState = {
  page: 1,
  searchQuery: '',
  field_mapping: {},
  sortBy: [],
  filters: [],
};

export const useUsersStore = create<UsersStore>(
  devtools((set, get) => {
    return {
      ...initialState,
      setPage: (page: number) => set(() => ({ page })),
      setSearchQuery: (searchQuery: string) => set(() => ({ searchQuery })),
      initFieldMappings: (initialMappings) => set(() => ({ field_mapping: initialMappings })),
      // adds a new field mapping to the store
      addFieldMapping: (obj: ColumnType) =>
        set((state) => ({
          field_mapping: {
            ...state.field_mapping,
            [obj.keyName]: obj,
          },
        })),
      removeFieldMapping: (keyName: string) => {
        let newState = get().field_mapping;
        delete newState[keyName];
        set(() => ({
          field_mapping: { ...newState },
          filters:
            // if personas column was removed,
            // clear out filter_expression as well.
            keyName === 'macro_persona'
              ? get().filters?.filter((item) => item.keyName !== keyName)
              : get().filters,
        }));
      },
      setSortBy: (sortBy) => set(() => ({ sortBy: sortBy ?? [] })),
      setFilters: (filters) => set(() => ({ filters: filters ?? [] })),
    };
  })
);
