import { useMutation, useQueryClient } from 'react-query';
import { useClient } from 'api-client';
import { QueryType } from 'stores/QueryStore';
import { ColumnType, useAccountsStore } from '../store/AccountsStore';
import { useUsersStore } from '../store/UsersStore';
import { useAuth } from 'hooks/useAuth';

export enum UpdateUserListPreferenceActions {
  ADD = 'add',
  REMOVE = 'remove',
}

export default function useUpdateUserListPreference() {
  const {
    addFieldMapping: addFieldMappingsForAccounts,
    removeFieldMapping: removeFieldMappingForAccounts,
    field_mapping: accountFieldMappings,
  } = useAccountsStore();
  const {
    addFieldMapping: addFieldMappingsForUsers,
    removeFieldMapping: removeFieldMappingForUsers,
    field_mapping: userFieldMappings,
  } = useUsersStore();
  const client = useClient();
  const queryClient = useQueryClient();
  const { user: userData } = useAuth();

  return useMutation(
    (data: {
      entityType: QueryType;
      action: UpdateUserListPreferenceActions;
      currentFieldMapping: ColumnType;
    }) => {
      const { action, currentFieldMapping, entityType } = data;
      const preferencesToUpdate =
        action === UpdateUserListPreferenceActions.ADD
          ? entityType === QueryType.accounts
            ? [...Object.values(accountFieldMappings), currentFieldMapping]
            : [...Object.values(userFieldMappings), currentFieldMapping]
          : // else remove the field_mapping from the store.
          entityType === QueryType.accounts
          ? Object.values(accountFieldMappings)?.filter(
              (item) => item?.columnName !== currentFieldMapping?.columnName
            )
          : Object.values(userFieldMappings)?.filter(
              (item) => item?.columnName !== currentFieldMapping?.columnName
            );
      return client.updateUserListPreference(data.entityType, preferencesToUpdate);
    },
    {
      onMutate: (variables) => {
        const { action, entityType, currentFieldMapping } = variables;
        const { keyName, keySpace, displayName, columnName, source, dataType, isSystem } =
          currentFieldMapping;
        if (action === UpdateUserListPreferenceActions.ADD) {
          if (entityType === QueryType.users) {
            addFieldMappingsForUsers({
              keyAlias: keyName,
              keyName,
              source,
              keySpace,
              displayName,
              columnName,
              dataType,
              isSystem,
            });
          } else {
            addFieldMappingsForAccounts({
              keyAlias: keyName,
              keyName,
              source,
              keySpace,
              displayName,
              columnName,
              dataType,
              isSystem,
            });
          }
        } else {
          if (entityType === QueryType.users) {
            removeFieldMappingForUsers(keyName);
          } else {
            removeFieldMappingForAccounts(keyName);
          }
        }
      },
      onError: (error, variables) => {
        const { entityType, currentFieldMapping } = variables;
        const { keyName } = currentFieldMapping;

        if (entityType === QueryType.users) {
          removeFieldMappingForUsers(keyName);
        } else {
          removeFieldMappingForAccounts(keyName);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(['user-list-preference', userData?.id]);
      },
    }
  );
}
