import emailVerifiedSrc from 'assets/images/email-verified.png';
import Loader from 'ui-components/feedback/Loader';
import AlternateTopNav from 'ui-components/business/navbars/AlternateTopNav';
import { PrimaryButton } from 'ui-components/inputs/Buttons';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useVerifyEmail from './hooks/useVerifyEmail';

export default function VerifyEmail() {
  const router = useHistory();
  const params = new URLSearchParams(router.location.search.replace('+', '%2b'));
  const verifyEmail = useVerifyEmail();

  useEffect(() => {
    verifyEmail.mutate();
  }, []);

  return (
    <div className="flex flex-col h-screen">
      <AlternateTopNav className={'mt-6 ml-8'} />
      {verifyEmail.isLoading && verifyEmail.isSuccess && (
        <div className="grid place-items-center grow">
          <Loader height={100} width={100} />
        </div>
      )}
      {!verifyEmail.isLoading && (
        <div className="flex flex-col items-center w-1/3 pb-8 mx-auto mt-12 border rounded-xl border-tw-gray-eb">
          <img src={emailVerifiedSrc} alt="Verify Email" className="w-2/3" />
          <h2 className="text-h2">Email verified</h2>
          <p className="mt-3 text-tw-black-7">{params.get('email')}</p>
          <PrimaryButton
            onClick={() => router.push(`/login?email=${params.get('email')}`)}
            className="mt-16 w-80"
          >
            PROCEED TO LOGIN
          </PrimaryButton>
        </div>
      )}
    </div>
  );
}
