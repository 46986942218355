import Modal from 'ui-components/util/modal';
import * as React from 'react';
import { IntegrationType } from '../../../../api-client';
import useAddIntegrationField from './hooks/useAddIntegrationField';
import { HubspotFieldForm } from './HubspotFieldForm';
import { PipedriveFieldForm } from './PipedriveFieldForm';

interface NewDestinationFieldProps {
  integration: any;
  show: boolean;
  title: string;
  onclose: any;
  availableFields: string[];
  object: string;
  isUniqueProperty: boolean;
}

export const NewDestinationField: React.FC<NewDestinationFieldProps> = ({
  integration,
  show,
  title,
  onclose,
  availableFields,
  object,
  isUniqueProperty,
}) => {
  const addNewField = useAddIntegrationField();

  const postNewField = (values) => {
    addNewField.mutate(values, {
      onSuccess: (data) => {
        let propertyName = integration.type === IntegrationType.HUBSPOT ? data.name : data.data.key;
        onclose(true, propertyName);
      },
    });
  };

  return (
    <>
      {integration && (
        <Modal open={show} title={title} onClose={onclose}>
          {(integration.type === IntegrationType.PIPEDRIVE ||
            integration.type === IntegrationType.HUBSPOT) && (
            <div>
              {integration.type === IntegrationType.PIPEDRIVE && (
                <PipedriveFieldForm
                  integrationId={integration.id}
                  integrationType={integration.type}
                  submitCallback={postNewField}
                  onclose={onclose}
                  availableFieldNames={availableFields}
                />
              )}
              {integration.type === IntegrationType.HUBSPOT && (
                <HubspotFieldForm
                  integrationId={integration.id}
                  integrationType={integration.type}
                  submitCallback={postNewField}
                  onclose={onclose}
                  availableFieldNames={availableFields}
                  objectType={object}
                  hasUniqueValue={isUniqueProperty}
                />
              )}
            </div>
          )}
        </Modal>
      )}
    </>
  );
};
