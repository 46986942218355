import { PrimaryButton } from 'ui-components/inputs/Buttons';
import { Link } from 'react-router-dom';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import WebhookTable from './sub-components/WebhookTable';
import { AnalyticsConst, AnalyticsEvents } from '../../telemetry/constants';
import { useAnalytics } from '../../telemetry';
import useRole from '../../hooks/useRole';
import { useAuthStore } from 'hooks/useAuthStore';

type WebhookProps = {
  webhookIntegrationId: number;
};

export default function Webhooks({ webhookIntegrationId }: WebhookProps) {
  const { track } = useAnalytics();
  const { isReadOnly } = useRole();
  const { getAccountId } = useAuthStore();

  return (
    <div>
      {!isReadOnly && (
        <div className="flex justify-end px-4 py-4">
          <Link
            to={`/${getAccountId()}/integrations/webhook/${webhookIntegrationId}/new`}
            onClick={() =>
              track(AnalyticsEvents.ADD_INTEGRATION_CLICKED, {
                [AnalyticsConst.TYPE]: 'webhook',
              })
            }
          >
            <PrimaryButton>
              <PlusIcon className="fill-current" />
              <span>Add webhook</span>
            </PrimaryButton>
          </Link>
        </div>
      )}
      <hr className="mx-4" />
      <WebhookTable />
    </div>
  );
}
