import { CrossIcon } from 'ui-components/data-display/Icons';

type ChipProps = {
  label: string;
  onDelete: () => void;
};

export default function Chip({ label, onDelete }: ChipProps) {
  return (
    <div className="flex items-center px-2 py-1 text-xs rounded cursor-default gap-x-1 bg-tw-blue-f2 text-tw-black-3">
      <div>{label}</div>
      <CrossIcon onClick={onDelete} className="w-2 cursor-pointer fill-current text-tw-blue-0d" />
    </div>
  );
}
