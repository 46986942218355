import { toast as toastify } from 'react-toastify';
import { ConnectedIntegrationStatus, Integration, IntegrationType, useClient } from 'api-client';
import Toggle from 'ui-components/inputs/Switch';
import { Formik } from 'formik';
import { ErrorBoundary } from 'react-error-boundary';
import { useAuthStore } from 'hooks/useAuthStore';
import useBulkStopPlaybooks from 'pages/integrations/hooks/useBulkStopPlaybooks';
import usePlaybooksByDestination from 'pages/integrations/hooks/usePlaybooksByDestination';
import IntegrationIcon from 'pages/integrations/sub-components/IntegrationIcon';
import { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { PrimaryButton, SecondaryButton } from '../../ui-components/inputs/Buttons';
import Skeleton from '../../ui-components/util/Skeleton';
import { classNames } from '../../utils/common';
import { Status } from '../../hooks/use-async';
import { redirectUri } from '../../hooks/use-oauth';
import useRole from '../../hooks/useRole';
import { routes } from '../../routing/clientRoutes';
import useAddIntegration from './hooks/useAddIntegration';
import useDeleteIntegration from './hooks/useDeleteIntegration';
import useGetConnectedIntegration from './hooks/useGetConnectedIntegration';
import useIntegrationDetails from './hooks/useIntegrationDetails';
import useUpdateIntegration from './hooks/useUpdateIntegration';
import OAuthConnectField from './OAuthConnectField';
import DeleteIntegrationModal from './sub-components/DeleteIntegrationModal';
import TrayConfigField, { TrayConfigStatus } from './TrayConfigField';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';
import { useAuth } from 'hooks/useAuth';
import ErrorBoundaryFallback from 'ui-components/feedback/ErrorBoundaryFallback';
import { ErrorAnalyticsEvents } from 'telemetry/constants';
import { useAnalytics } from 'telemetry';
/** This page is currently hardcoded to Salesforce **/

type RouteParams = { integrationId?: string; connectedIntegrationId?: string };

type LocationState = {
  isIntActive: boolean | (() => boolean);
  type: IntegrationType;
  name: string;
};

type FormState = {
  name: string;
  code: string;
  apiKey: string;
  apiDomain: string;
};

const oAuthAddSchema = Yup.object().shape({
  name: Yup.string().min(1).max(255).required(),
  code: Yup.string().required(),
});

const oAuthEditSchema = Yup.object().shape({
  name: Yup.string().min(1).max(255).required(),
  code: Yup.string().notRequired(),
});

const apiSchema = Yup.object().shape({
  name: Yup.string().min(1).max(255).required(),
  apiDomain: Yup.string().required(),
  apiKey: Yup.string().min(1).required(),
});

function createConnectPayload(
  { oauth, type }: Integration,
  code: string,
  apiDomain: string,
  apiKey: string
) {
  if (type === IntegrationType.PIPEDRIVE) {
    return { pipedrive: { apiKey, apiDomain: `https://${apiDomain}.pipedrive.com` } };
  }

  if (oauth && code) {
    return {
      [type]: {
        code,
        redirectUri: redirectUri(type, 'integrations'),
      },
    };
  }

  return undefined;
}

export function createTrayUpdatePayload(trayConfigStatus: TrayConfigStatus) {
  if (trayConfigStatus === TrayConfigStatus.FINISH) {
    return {
      active: true,
      status: ConnectedIntegrationStatus.READY,
    };
  }
  return {
    active: false,
    status: ConnectedIntegrationStatus.IN_PROGRESS,
  };
}

export default function AddIntegrationPage() {
  const { getAccountId } = useAuthStore();
  const params = useLocation<LocationState>();
  const [isIntActive, setIsIntActive] = useState<boolean>(
    params?.state ? params?.state?.isIntActive : true
  );
  const [isDeleteOpenDialog, setIsDeleteOpenDialog] = useState(false);
  const { integrationId, connectedIntegrationId } = useParams<RouteParams>();
  const client = useClient();
  const formikFormRef = useRef();
  const history = useHistory();
  const { track } = useAnalytics();
  const { isReadOnly } = useRole();
  const updateIntegration = useUpdateIntegration(connectedIntegrationId);
  const addIntegration = useAddIntegration();
  const { data: connectedIntegration } = useGetConnectedIntegration(connectedIntegrationId);
  const [integrationID, setIntegrationID] = useState<string | number>(null);
  const { user } = useAuth();

  //State to store the newly created tray connected integration in the configure level
  const [trayConnectedIntegration, setTrayConnectedIntegration] = useState(null);
  const [trayConfigStatus, setTrayConfigStatus] = useState(null);
  const [trayAuthId, setTrayAuthId] = useState(null);
  const updateTrayIntegration = useUpdateIntegration(trayConnectedIntegration?.id);

  useEffect(() => {
    integrationId
      ? setIntegrationID(integrationId)
      : setIntegrationID(connectedIntegration?.data?.integration.id);
  }, [integrationId, connectedIntegration]);

  const deactivateIntegration = async () => {
    try {
      await client.toggleIntegration(connectedIntegrationId, false);
      setIsIntActive(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsIntActive(true);
    }
  };

  const switchAction = async () => {
    if (isIntActive) {
      await deactivateIntegration();
      setIsIntActive(false);
      toastify(<SuccessToast description="This app has been disabled" />, {
        type: 'success',
      });
      return;
    }
    try {
      await client.toggleIntegration(connectedIntegrationId, true);
      setIsIntActive(true);
      toastify(<SuccessToast description="This app has been enabled" />, {
        type: 'success',
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsIntActive(false);
    }
  };

  const { data: integration, status }: { data: Integration; status: string } =
    useIntegrationDetails(integrationID);

  const getDomain = () => {
    const data: string = connectedIntegration?.data?.metadata[
      connectedIntegration?.data?.type
    ]?.apiDomain
      ?.replace('.pipedrive.com', '')
      .replace('https://', '');
    return data;
  };

  const isEditing = history.location.pathname.includes('edit');

  const initialValues = {
    name: params?.state
      ? params.state?.name
      : user?.currentOrganization?.name && integration?.name
      ? `${user.currentOrganization.name.toLowerCase()}-${integration.name.toLowerCase()}`
      : '',
    apiKey: '',
    apiDomain: getDomain(),
  };

  const handleAdd = useCallback(
    async ({ name, code, apiKey, apiDomain }: FormState, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      try {
        addIntegration.mutate({
          name,
          type: integration?.type,
          integration: integration?.id,
          connect:
            integration?.oauth && integration?.type !== IntegrationType.PIPEDRIVE
              ? {
                  [integration?.type]: {
                    code,
                    redirectUri: redirectUri(integration?.type, 'integrations'),
                  },
                }
              : {
                  [integration?.type]: { apiKey, apiDomain: `https://${apiDomain}.pipedrive.com` },
                },
        });
        toastify(<SuccessToast description={`Integration ${name} is now available for use`} />, {
          type: 'success',
        });
        history.push(`/${getAccountId()}${routes.integrations}`);
      } catch (e) {
        setStatus('Error while saving');
      } finally {
        setSubmitting(false);
      }
    },
    [addIntegration, integration?.type, integration?.id, integration?.oauth, history, getAccountId]
  );

  const handleTrayCompleteIntegration = useCallback(
    async (name, setSubmitting) => {
      try {
        const payload = createTrayUpdatePayload(trayConfigStatus);
        setSubmitting(true);
        const trayArgs = {
          name,
          active: payload.active,
          status: payload.status,
        };
        if (trayAuthId) {
          trayArgs['connect'] = {
            tray: {
              authId: trayAuthId,
            },
          };
        }
        updateTrayIntegration.mutate(trayArgs, {
          onSuccess: () => {
            setSubmitting(false);
            toastify(<SuccessToast description="Integration has been updated" />, {
              type: 'success',
            });
            history.push(`/${getAccountId()}${routes.integrations}`);
          },
          onError: () => {
            setSubmitting(false);
            toastify(<ErrorToast />, {
              type: 'error',
            });
            history.push(`/${getAccountId()}${routes.integrations}`);
          },
        });
      } catch (e) {
        setSubmitting(false);
        toastify(<ErrorToast />, {
          type: 'error',
        });
      }
    },
    [trayConfigStatus, updateTrayIntegration, history, getAccountId]
  );

  const handleEdit = useCallback(
    async ({ name, code, apiDomain, apiKey }: FormState, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      try {
        const connect = createConnectPayload(integration, code, apiDomain, apiKey);
        updateIntegration.mutate({ name, connect });
        toastify(<SuccessToast description="Integration has been updated" />, {
          type: 'success',
        });
        history.push(`/${getAccountId()}${routes.integrations}`);
      } catch (e) {
        setStatus('Error while saving');
      } finally {
        setSubmitting(false);
      }
    },
    [getAccountId, history, integration, updateIntegration]
  );

  const affectedPlaybooksMutation = usePlaybooksByDestination(connectedIntegrationId);
  const deleteApplication = useDeleteIntegration();
  const stopAffectedPlaybooks = useBulkStopPlaybooks();

  useEffect(() => {
    if (isDeleteOpenDialog) affectedPlaybooksMutation.mutate();
  }, [isDeleteOpenDialog]);

  useEffect(() => {
    if (trayConfigStatus === TrayConfigStatus.FINISH) {
      /* eslint-disable */
      // @ts-ignore
        handleTrayCompleteIntegration(formikFormRef.current?.values.name, formikFormRef.current?.setSubmitting)
      /* eslint-disable */
    }
  }, [trayConfigStatus]);

  const handleShowError = () => {
    toastify(<ErrorToast />, {
      type: 'error',
    });
  };

  const deleteApp = () => {
    try {
      if (affectedPlaybooksMutation?.data?.data?.length)
        stopAffectedPlaybooks.mutate(
          affectedPlaybooksMutation?.data?.data?.map((pb) => pb.id),
          {
            onError: handleShowError,
            onSuccess: () => {
              deleteApplication.mutate(connectedIntegrationId, {
                onError: () => {
                  handleShowError();
                },
                onSuccess: () => {
                  history.push(`/${getAccountId()}${routes.integrations}`);
                  setIsDeleteOpenDialog(false);
                },
              });
            },
          }
        );
      else
        deleteApplication.mutate(connectedIntegrationId, {
          onError: () => {
            handleShowError();
          },
          onSuccess: () => {
            setIsDeleteOpenDialog(false);
            history.push(`/${getAccountId()}${routes.integrations}`);
          },
        });
    } catch (e) {
      handleShowError();
    }
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={(e) =>
        track(ErrorAnalyticsEvents.ERROR_OCCURED, {
          error: e.message,
        })
      }
    >
      <div className={classNames('grid content-start pb-10 h-full')}>
        {isEditing && (
          <div className="relative w-1/2 px-8 pt-8 pb-5 mx-auto mt-8 bg-white border rounded-lg border-tw-gray-eb">
            {status === Status.SUCCESS && integration ? (
              <div>
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-row items-center">
                    <div className="w-20">
                      <IntegrationIcon
                        type={integration?.type}
                        logoUrl={integration?.logoUrl}
                        height={48}
                        width={48}
                      />
                    </div>
                    <div className="flex pl-3 flex-column">
                      <div className="text-base font-medium text">{`${integration?.name} Integration`}</div>
                      <div className="px-2 py-1 mt-2 text-xs rounded max-w-max bg-tw-gray-eb tw-gray-eb">
                        DESTINATION
                      </div>
                    </div>
                  </div>
                  {!isReadOnly && (
                    <div className="flex items-center flex-column">
                      <div onClick={switchAction}>
                        <Toggle enabled={isIntActive} />
                      </div>
                      <div className="my-1 text-tw-gray-7">
                        {isIntActive ? `Enabled` : `Disabled`}
                      </div>
                    </div>
                  )}
                </div>

                <div className="my-4 border-dashed border-b-1" />
                {connectedIntegration && (
                  <div className="p-2 text-tw-gray-7">
                    Connected on {new Date(connectedIntegration.data?.createdAt).toUTCString()}
                  </div>
                )}
              </div>
            ) : (
              <Skeleton />
            )}
          </div>
        )}
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={
            integration?.oauth ? (isEditing ? oAuthEditSchema : oAuthAddSchema) : apiSchema
          }
          onSubmit={isEditing ? handleEdit : handleAdd}
          innerRef={formikFormRef}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            setSubmitting,
            setValues,
          }) => (
            <div
              className={classNames(
                'px-6 pb-6 mt-4 bg-white rounded-lg border border-tw-gray-eb',
                !isEditing ? `w-4/5 h-75vh  ml-auto mr-28` : 'w-1/2 mx-auto'
              )}
            >
              <div className="grid h-full grid-cols-4">
                {status === Status.SUCCESS && integration ? (
                  <>
                    {!isEditing && (
                      <div className="col-span-1 mt-tw-8">
                        <div className="w-28">
                          <IntegrationIcon
                            height={40}
                            width={40}
                            type={integration?.type}
                            logoUrl={integration?.logoUrl}
                          />
                        </div>
                        <h2 className="text-2xl font-semibold mt-tw-4">{integration?.name}</h2>
                        <p className="px-2 py-1 mt-2 text-xs rounded max-w-max bg-tw-gray-eb tw-gray-eb">
                          DESTINATION
                        </p>
                        {integration?.categories?.map((category) => (
                          <p key={category} className="mt-3 text-tw-black-7">
                            {category?.toUpperCase()}
                          </p>
                        ))}
                        {/* <p>Supports read: {integration.supportsRead ? 'Yes' : 'No'}</p>
                        <p>Supports write: {integration.supportsWrite ? 'Yes' : 'No'}</p> */}
                      </div>
                    )}
                    <div
                      className={classNames(
                        'relative flex flex-col mt-8 custom-int-section',
                        isEditing ? 'col-span-4' : 'col-span-3'
                      )}
                    >
                      <div className="flex justify-between">
                        <div>
                          {isEditing ? (
                            <div className="text-tw-gray-7">DETAILS</div>
                          ) : (
                            <div className="text-xl">{`${integration?.name} Integration`}</div>
                          )}

                          {/* <div onClick={switchAction}>
                            <Toggle enabled={isIntActive} />
                          </div> */}
                        </div>
                      </div>
                      <hr className="my-3" />
                      <form className="flex flex-col flex-auto" onSubmit={handleSubmit}>
                        <div className="mb-7">
                          <div className="my-2 text-sm text-tw-black-5">Name your integration</div>
                          <input
                            className="w-full px-4 py-3 rounded-sm border-1 border-tw-gray-c focus:outline-none"
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            disabled={isReadOnly}
                            placeholder={`New ${integration?.name} integration`}
                          />
                          {integration?.type === IntegrationType.TRAY &&
                          !isEditing &&
                          integration?.metadata ? (
                            <>
                              <div className="mt-8 mb-4 border-dashed border-b-1" />
                              <div className="flex items-center justify-between">
                                <div className="text-base font-medium text-tw-black-3">
                                  Connect to {integration?.name}
                                </div>
                                <TrayConfigField
                                  integration={integration}
                                  connectedIntegrationName={values.name}
                                  updateTrayConnectedIntegration={setTrayConnectedIntegration}
                                  updateTrayConfigStatus={setTrayConfigStatus}
                                  updateTrayAuthId={setTrayAuthId}
                                />
                              </div>
                            </>
                          ) : integration.oauth &&
                            !isEditing &&
                            integration?.type !== IntegrationType.PIPEDRIVE ? (
                            <>
                              <div className="mt-8 mb-4 border-dashed border-b-1" />
                              <div className="flex items-center justify-between">
                                <div className="text-base font-medium text-tw-black-3">
                                  Connect to {integration?.name}
                                </div>
                                <OAuthConnectField
                                  name="code"
                                  integrationName={values.name}
                                  type={integration?.type}
                                  {...integration?.oauth}
                                  showReconnect={isEditing}
                                  onSuccess={(code, name) => {
                                    setValues({ ...values, name, code } as any);
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              {integration?.type === 'pipedrive' && (
                                <>
                                  <div className={'mt-6'}>
                                    <div className="my-2 text-tw-black-5">
                                      Pipedrive Company Domain
                                    </div>
                                    <input
                                      className="w-full px-4 py-3 rounded-sm border-1 border-tw-gray-c focus:outline-none"
                                      type="text"
                                      name="apiDomain"
                                      disabled={isReadOnly}
                                      value={values.apiDomain}
                                      autoComplete="off"
                                      onChange={handleChange}
                                      placeholder={`Pipedrive company name from https://companyname.pipedrive.com`}
                                    />
                                  </div>
                                  <div className={'mt-6'}>
                                    <div className="my-2 text-tw-black-5">API Key</div>
                                    <input
                                      className="w-full px-4 py-3 rounded-sm border-1 border-tw-gray-c focus:outline-none"
                                      type="password"
                                      name="apiKey"
                                      value={values.apiKey}
                                      onChange={handleChange}
                                      disabled={isReadOnly}
                                      placeholder={`Your App's API key (or API token)`}
                                      autoComplete={'off'}
                                    />
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>

                        {!isReadOnly && (
                          <div className="w-full mt-auto">
                            <hr className="my-4" />
                            <div className="flex justify-end gap-x-2">
                              {integration?.type === IntegrationType.TRAY ? (
                                <PrimaryButton
                                  isLoading={isSubmitting}
                                  type={'button'}
                                  disabled={
                                    isEditing
                                      ? isValid
                                      : trayConfigStatus !== TrayConfigStatus.FINISH
                                  }
                                  onClick={() => {
                                    if (isEditing) {
                                      handleEdit(values as any, { setSubmitting });
                                    } else {
                                      handleTrayCompleteIntegration(values.name, setSubmitting);
                                    }
                                  }}
                                  className="w-60"
                                >
                                  {' '}
                                  {isEditing ? 'Rename Integration' : 'Complete integration'}
                                </PrimaryButton>
                              ) : (
                                <PrimaryButton
                                  type="submit"
                                  isLoading={isSubmitting}
                                  disabled={!isValid || !dirty}
                                  className="w-60"
                                >
                                  {params?.state || isEditing
                                    ? 'Save changes'
                                    : 'Complete integration'}
                                </PrimaryButton>
                              )}
                            </div>
                          </div>
                        )}
                      </form>
                    </div>
                  </>
                ) : (
                  <div className="grid h-full col-span-4 place-items-center">
                    <Skeleton count={20} />
                  </div>
                )}
              </div>
            </div>
          )}
        </Formik>
        {isEditing && !isReadOnly && (
          <div className="relative w-1/2 px-6 py-4 mx-auto mt-4 bg-white border rounded-lg border-tw-gray-eb">
            {status === Status.SUCCESS && integration && (
              <div className="flex flex-row items-center justify-between">
                <div className="text tw-black-3">Want to delete this integration?</div>
                <SecondaryButton
                  className="text-tw-red-dd"
                  borderClassName="border-tw-red-dd"
                  bgClassName="bg-tw-red-ff"
                  onClick={() => {
                    setIsDeleteOpenDialog(true);
                  }}
                >
                  Delete Integration
                </SecondaryButton>
              </div>
            )}
          </div>
        )}
        <DeleteIntegrationModal
          isDeleteOpenDialog={isDeleteOpenDialog}
          setIsDeleteOpenDialog={setIsDeleteOpenDialog}
          integrationName={integration?.name}
          deleteApp={deleteApp}
          isLoading={deleteApplication.isLoading || stopAffectedPlaybooks.isLoading}
          affectedPlaybooks={affectedPlaybooksMutation?.data?.data ?? null}
          affectedPlaybooksLoading={affectedPlaybooksMutation?.isLoading}
        />
      </div>
    </ErrorBoundary>
  );
}
