import { PrimaryButton } from 'ui-components/inputs/Buttons';
import React from 'react';
import { useHistory } from 'react-router-dom';

export default function StaticNotFound() {
  const router = useHistory();

  return (
    <div className="h-screen grid place-items-center">
      <div className="flex items-center">
        <div>
          <div className="text-lg font-semibold">
            Uh Oh! You have reached an unreachable location
          </div>
          <div className="text-tw-gray-7">
            The URL you are looking for does not exist or has been removed.
          </div>
          <PrimaryButton onClick={() => router.push('/')} className="mx-auto mt-8">
            Go Home
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
