import { DropdownTriangleFilled } from 'ui-components/data-display/Icons';
import PopupFilter from 'ui-components/business/PopupFilter';
import { AnimatePresence, motion } from 'framer-motion';
import { classNames } from 'utils/common';
import { comparatorMap, PopupFilterPage } from 'utils/globalTypes';
import { BarChartOp } from 'pages/opportunities/barChartOp';
import useMeta from 'pages/pql-workflow/hooks/useMeta';
import { useFiltersPQLStore } from 'pages/pql-workflow/store/PQLFiltersStore';
import { useCallback, useMemo, useState } from 'react';
import { useAnalytics } from 'telemetry';
import { AnalyticsEvents, AnalyticsConst } from 'telemetry/constants';

type InitialValue = {
  timestamp?: {
    start: number;
    end: number;
    timeType: string;
  };
  numberValue?: string;
  stringValues?: string[];
};

type RecommendedFilterProps = {
  title: string;
  value: string;
  conversionRate?: number;
  operator: string;
  columnName: string;
  initialValue?: InitialValue;
};

export function RecommendedTag({ text, className }: { text: string; className?: string }) {
  return (
    <div
      className={classNames(
        'px-1.5 text-xs max-w-40 text-white rounded-t-[4px] bg-tw-black-1 overflow-clip relative',
        className
      )}
    >
      <motion.div
        className="absolute flex items-center h-full -top-4"
        initial={{ x: -10 }}
        animate={{ x: 160 }}
        transition={{ repeat: 1, duration: 1.3 }}
      >
        <div className="h-28 w-0.5 bg-tw-white-ff/[0.3] -rotate-30"></div>
        <div className="h-28 w-2 ml-1 bg-tw-white-ff/[0.3] -rotate-30"></div>
      </motion.div>
      <div className="py-1 font-medium uppercase">{text}</div>
    </div>
  );
}

export default function RecommendedFilterCard({
  title,
  value,
  conversionRate,
  operator,
  columnName,
  initialValue,
}: RecommendedFilterProps) {
  const { filters, setFilters } = useFiltersPQLStore();
  const { data: meta } = useMeta();

  const metaItem = useMemo(
    () => meta?.sourceMeta.data.find((m) => m.COLUMN_NAME === columnName),
    [columnName, meta?.sourceMeta.data]
  );
  const [open, setOpen] = useState(false);
  const isApplied = filters.some((f) => f.columnName === columnName);
  const { track } = useAnalytics();

  const onApplyPopupFilter = useCallback(
    (currentComparator, filterToBeApplied) => {
      const { KEYNAME, KEYSPACE, SOURCE, COLUMN_NAME, DISPLAY_NAME } = metaItem;
      const newFilters = filters.filter((prevFilter) => {
        return prevFilter.columnName !== COLUMN_NAME;
      });
      // Set filters in new PQL store
      setFilters([
        ...newFilters,
        {
          keyName: KEYNAME,
          keySpace: KEYSPACE,
          source: SOURCE,
          op: currentComparator,
          value: filterToBeApplied,
          displayName: DISPLAY_NAME,
          columnName: COLUMN_NAME,
        },
      ]);

      track(AnalyticsEvents.OPPORTUNITY_CREATE_FILTER, {
        [AnalyticsConst.ATTRIBUTE_NAME]: KEYNAME,
        [AnalyticsConst.OPERATOR]: currentComparator,
        [AnalyticsConst.VALUE]: filterToBeApplied,
      });
    },
    [filters, metaItem, setFilters, track]
  );

  return (
    <div>
      <RecommendedTag text={'recommended'} className="w-28" />
      <div className="relative overflow-hidden bg-white rounded-b-lg rounded-tr-lg z-5">
        <div
          className="px-4 py-4 shadow cursor-pointer product-tour-8"
          onClick={() => setOpen(!open)}
        >
          {!title || !operator || value === 'NaN' ? (
            <div className="flex justify-between w-full">
              <div className="flex w-3/6">
                <div className="w-full rounded-sm h-[38px] bg-tw-gray-eb"></div>
              </div>
              <div className="flex-nowrap w-1/6 h-[38px] bg-tw-gray-eb rounded-sm"></div>
            </div>
          ) : (
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-start flex-1 gap-x-2">
                <span>{title}</span>
                <span className="flex-shrink w-12 border-b-1 border-tw-blue-b6"></span>
                <span className="px-2 py-1 text-xs rounded-full text-tw-black-7 bg-tw-blue-f2">
                  {comparatorMap[operator]}
                </span>
                <span className="flex-shrink w-12 border-b-1 border-tw-blue-b6"></span>
                <span className="flex-nowrap">{value}</span>
              </div>
              <div className="mr-12">
                <PopupFilter
                  buttonClassname="text-tw-blue-0d bg-tw-blue-f2"
                  old={false}
                  row={{ original: metaItem }}
                  initialValue={initialValue}
                  isApplied={isApplied}
                  onApply={(currentComparator, filterToBeApplied) =>
                    onApplyPopupFilter(currentComparator, filterToBeApplied)
                  }
                  page={PopupFilterPage.PQLWORKFLOW}
                />
              </div>
              <motion.div
                className={'absolute right-4'}
                animate={{
                  rotate: open ? 180 : 0,
                }}
              >
                <DropdownTriangleFilled className="fill-current text-tw-black-5" />
              </motion.div>
            </div>
          )}
          {!isNaN(conversionRate) && (
            <div className="text-xs font-medium text-tw-black-7">
              <hr className="my-4 border-dashed"></hr>
              <p>⚡️ {(conversionRate * 100).toFixed(2)}% of all your conversions have done this</p>
            </div>
          )}
        </div>
        <AnimatePresence initial={false}>
          {open && (
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
              className="px-2 mt-4"
            >
              <BarChartOp row={{ original: metaItem }} old={false} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
