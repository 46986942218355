import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Spacer, Stack, Text as ChakraText } from '@chakra-ui/react';
import { PrimaryDropdown } from 'ui-components/navigation/Dropdown';
import { dataObj, getBarChartData } from 'ui-components/business/Barchart';
import { formatToUserReadableFormat } from 'hooks/UserReadableNumbers';
import useModels from 'hooks/useModels';
import { useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from 'recharts';
import { FilledInfoIcon, InfoIcon, SpinnerIcon } from '../../ui-components/data-display/Icons';
import { QueryGoal, QueryType, useQueryStore } from '../../stores/QueryStore';
import useHistogram from './hooks/useHistogram';
import { CORRELATION } from './types';
import { useAuth } from 'hooks/useAuth';

export enum dataTypes {
  recommended = 'recommended',
  conversionRates = 'conversionRates',
  convertedCounts = 'convertedCounts',
  userCounts = 'userCounts',
}

const CustomYLabel = (props: any) => {
  const { x, y, payload, type } = props;
  return (
    <Text
      x={x - 10}
      y={y + 3}
      width={75}
      fontSize={10}
      textAnchor="middle"
      fill="#ABABAB"
    >{`${formatToUserReadableFormat(payload?.value)}${type}`}</Text>
  );
};

const CustomXLabel = (props: any) => {
  const { x, y, payload } = props;
  return (
    <Text x={x} y={y} fontSize="0.6rem" textAnchor="middle" verticalAnchor="middle" fill="#ABABAB">
      {payload?.value.length > 12 ? `${payload?.value.slice(0, 9)}...` : payload?.value}
    </Text>
  );
};

const CustomizedTooltip = (props: any) => {
  const { label, data, dataType, keyName, type, goal, displayName } = props;
  const [tooltipData, setTooltipData] = useState<any>({});

  const extractTooltipData = () => {
    const matchedArray = data ? Object.keys(data)?.filter((key) => data[key]?.bin === label) : [];
    setTooltipData(matchedArray?.map((item) => data[item])[0]);
  };
  useEffect(() => {
    extractTooltipData();
  });

  return (
    <div
      style={{
        width: 'auto',
        height: 'auto',
        backgroundColor: '#F2F2F2',
        color: '#383838',
        padding: '10px',
        borderRadius: '10px',
      }}
    >
      <div style={{ marginBottom: '10px', fontSize: '14px', fontWeight: 'bold' }}>
        {dataType === 'str' ? label : displayName || keyName}
      </div>
      {tooltipData &&
        Object.keys(tooltipData)?.map((item) => {
          if (item !== 'bin') {
            return (
              <p style={{ fontSize: '12px' }} key={item}>
                {item === dataTypes?.conversionRates && (
                  <>
                    {getYLabel(item, type, goal)} : {tooltipData[item]}%
                  </>
                )}
              </p>
            );
          } else return null;
        })}
    </div>
  );
};

const Rectangle = (props: { color: string }) => {
  return <div style={{ height: 10, width: 10, background: props.color || 'green' }}></div>;
};

const CustomLegend = () => {
  return (
    <>
      <ul style={{ textAlign: 'center', color: '#999999', fontSize: 12 }}>
        <li style={{ display: 'inline-block', marginLeft: 5 }}>
          <Rectangle color={'#377DFF'} />
        </li>
        <li style={{ display: 'inline-block', marginLeft: 5 }}>Values</li>
      </ul>
    </>
  );
};
export const getGoalText = (goal: QueryGoal, past?: boolean) => {
  switch (goal) {
    case QueryGoal.conversion:
      return 'Conversion';
    case QueryGoal.engagement:
      return 'Engagement';
    case QueryGoal.expansion:
      if (past) {
        return 'Expanded';
      } else {
        return 'Expansion';
      }
    case QueryGoal.churn:
      return 'Churn';
    default:
      return '';
  }
};

const getTypeText = (type: QueryType) => {
  switch (type) {
    case QueryType.users:
      return 'User';
    case QueryType.accounts:
      return 'Account';
  }
};

const getYLabel = (dataType: string, type: QueryType, goal: QueryGoal) => {
  switch (dataType) {
    case dataTypes.conversionRates:
      return `Likelihood of ${getGoalText(goal)}`;
    case dataTypes.convertedCounts:
      return `${getGoalText(goal, true)} Count`;
    case dataTypes.userCounts:
      return `${getTypeText(type)} Count`;
    case dataTypes.recommended:
      return `Likelihood of ${getGoalText(goal)}`;
  }
};

interface BarChartOpProps {
  row: any;
  old?: boolean;
}

export const BarChartOp: FC<BarChartOpProps> = (props) => {
  const { SOURCE, KEYNAME, KEYSPACE, DATA_TYPE, COLUMN_NAME, DISPLAY_NAME } = props.row.original;
  const NO_OF_BARS = 7;
  const [dataType, setDataType] = useState<string>(dataTypes.recommended);
  const [average, setAverage] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalBins, setTotalBins] = useState<number>(0);
  const { goal, type, modelId } = useQueryStore();
  const { targetEntity, modelId: newModelId } = useDefinePQLStore();
  const { data: modelData } = useModels();
  const [timeline, setTimeline] = useState<string>(
    props.old
      ? modelData
          ?.find((m) => m.modelId === modelId && m.entity.toUpperCase() === type)
          ?.defaultBin.toString()
      : modelData
          ?.find((m) => m.modelId === newModelId && m.entity.toUpperCase() === targetEntity)
          ?.defaultBin.toString()
  );

  const {
    data: histogramData,
    refetch,
    isLoading,
    isSuccess,
  } = useHistogram({
    source: SOURCE,
    keyName: KEYNAME,
    keySpace: KEYSPACE,
    columnName: COLUMN_NAME,
    goal: props.old ? goal : modelData?.find((m) => m.modelId === newModelId).goal.toUpperCase(),
    type: props.old ? type : targetEntity,
    modelId: props.old ? modelId : newModelId,
  });

  const [data, setData] = useState<dataObj[] | undefined>(
    getBarChartData(histogramData, timeline, dataType)
  );

  useEffect(() => {
    if (!isSuccess && props.row.original.CORRELATION !== CORRELATION.HIGHLYCARDINAL) {
      refetch();
    }
  }, [props?.row?.id]);

  useEffect(() => {
    const timeLineOptions = [];
    if (histogramData) {
      Object.keys(histogramData).map((key) => {
        if (!isNaN(Number(key))) {
          timeLineOptions.push(key.toString());
        }
      });
      if (!timeLineOptions.includes(timeline)) {
        setTimeline(timeLineOptions[0]);
      }
    }
    setData(getBarChartData(histogramData, timeline, dataType)?.slice(0, NO_OF_BARS));
    setPageNumber(1);
    setTotalBins(getBarChartData(histogramData, timeline, dataType)?.length || 0);
    histogramData && setAverage(histogramData[timeline]?.avgConversionRate * 100);
  }, [timeline, histogramData, dataType]);

  useEffect(() => {
    setData(
      getBarChartData(histogramData, timeline, dataType)?.slice(
        NO_OF_BARS * (pageNumber - 1),
        NO_OF_BARS * pageNumber
      )
    );
  }, [pageNumber]);

  const onBarClick = () => {
    // alert(e.bin);
  };
  const { user } = useAuth();

  const barDataType = [
    { label: 'Recommended', value: dataTypes.recommended },
    { label: 'Conversion Rate', value: dataTypes.conversionRates },
    { label: 'Converted Count', value: dataTypes.convertedCounts },
    { label: 'User Count', value: dataTypes.userCounts },
  ];

  const showPaginationButtons = totalBins > NO_OF_BARS;

  const renderChartOrText = useCallback(() => {
    if (props.row.original.CORRELATION == CORRELATION.HIGHLYCARDINAL) {
      return (
        <Box alignItems="center">
          <ChakraText
            m={5}
            style={{
              padding: 5,
              borderRadius: 5,
              width: 400,
            }}
          >
            <div className="flex space-x-2 place-items-center">
              <FilledInfoIcon className="fill-current" />
              <p>Too many distinct values to show attribute analytics</p>
            </div>
          </ChakraText>
        </Box>
      );
    } else {
      if (isLoading) {
        return (
          <div className="grid h-40 place-items-center">
            <SpinnerIcon className="w-8 h-8 fill-current animate-spin text-tw-blue-0d" />
          </div>
        );
      } else {
        if (isSuccess && data?.length) {
          return (
            <>
              {DATA_TYPE === 'str' ? (
                <Stack direction="row" align={'right'} alignItems="center" spacing={2}>
                  <Spacer />
                  <ChakraText>Sort by</ChakraText>
                  <PrimaryDropdown
                    options={barDataType}
                    onChange={(e: any) => setDataType(e)}
                    value={dataType}
                  />
                </Stack>
              ) : (
                // Info: removes dropdown from profile events
                KEYSPACE === 'profile_events' && <></>
              )}
              <Flex alignItems="center" direction="row" align="center">
                {showPaginationButtons && (
                  <Button
                    size="xs"
                    onClick={() => {
                      setPageNumber(pageNumber - 1);
                    }}
                    disabled={pageNumber === 1}
                  >
                    <ChevronLeftIcon />
                  </Button>
                )}
                <div style={{ width: '100%', overflowX: 'auto' }}>
                  <div
                    style={{
                      width: `${(data?.length || 0) * 70}px`,
                      minWidth: '99%',
                      height: '200px',
                    }}
                  >
                    <ResponsiveContainer width={'99%'} height={200}>
                      <BarChart
                        data={data}
                        margin={{
                          top: 30,
                          right: 20,
                          left: 20,
                          bottom: 15,
                        }}
                      >
                        <XAxis
                          dataKey="bin"
                          domain={['auto', 'auto']}
                          tickMargin={15}
                          tickLine={false}
                          tick={<CustomXLabel />}
                          interval={0}
                        />
                        <YAxis
                          dataKey={
                            dataType === dataTypes.recommended
                              ? dataTypes.conversionRates
                              : dataType
                          }
                          tick={
                            <CustomYLabel
                              type={
                                dataType === dataTypes.conversionRates ||
                                dataType === dataTypes.recommended
                                  ? '%'
                                  : ''
                              }
                            />
                          }
                        >
                          <Label
                            angle={270}
                            position="left"
                            style={{ textAnchor: 'middle', fontSize: 10 }}
                          >
                            {getYLabel(
                              dataType,
                              props.old ? type : QueryType[targetEntity.toLowerCase()],
                              props.old
                                ? goal
                                : QueryGoal[modelData?.find((m) => m.modelId === newModelId).goal]
                            )}
                          </Label>
                        </YAxis>
                        <ReferenceLine y={average} strokeDasharray="5 5" />
                        {/* <Brush dataKey='bin' height={18} stroke="#8884d8"/> */}
                        {/* <Tooltip /> */}
                        <Tooltip
                          cursor={false}
                          content={
                            <CustomizedTooltip
                              type={props.old ? type : targetEntity}
                              goal={
                                props.old
                                  ? goal
                                  : modelData
                                      ?.find((m) => m.modelId === newModelId)
                                      .goal.toUpperCase()
                              }
                              chartType={0}
                              data={data}
                              dataType={DATA_TYPE}
                              keyName={KEYNAME}
                              displayName={DISPLAY_NAME}
                              keySpace={KEYSPACE}
                            />
                          }
                        />
                        <Bar
                          dataKey={
                            dataType === dataTypes.recommended
                              ? dataTypes.conversionRates
                              : dataType
                          }
                          barSize={20}
                          onClick={onBarClick}
                        >
                          {data?.map((item, index) => {
                            return <Cell key={`cell-${index}`} fill="#377dff" />;
                          })}
                        </Bar>
                        <Legend content={<CustomLegend />} />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                {showPaginationButtons && (
                  <Button
                    size="xs"
                    onClick={() => {
                      setPageNumber(pageNumber + 1);
                    }}
                    disabled={totalBins / (NO_OF_BARS * pageNumber) <= 1}
                  >
                    <ChevronRightIcon />
                  </Button>
                )}
              </Flex>
              <div className="flex items-center">
                {DATA_TYPE === 'str' ? (
                  <span className="mb-4 py-1 px-2 inline-flex items-center mx-auto bg-tw-blue-f2 text-tw-black-5">
                    <InfoIcon className="w-4 h-4 fill-current mr-1" />
                    The likelihood of conversion is based on all historical data
                  </span>
                ) : (
                  KEYSPACE === 'profile_events' && (
                    <span className="mb-4 py-1 px-2 inline-flex items-center mx-auto bg-tw-blue-f2 text-tw-black-5">
                      <InfoIcon className="w-4 h-4 fill-current mr-1" />
                      The likelihood of conversion is based on the last{' '}
                      {user?.currentOrganization?.trainingWindow} days of data
                    </span>
                  )
                )}
              </div>
            </>
          );
        } else {
          return (
            <div className="flex justify-center p-5 pb-10">
              <div className="text-sm text-tw-gray-7">Graph unavailable</div>
            </div>
          );
        }
      }
    }
  }, [props.row.original, isLoading, isSuccess, data]);

  return <>{renderChartOrText()}</>;
};

BarChartOp.defaultProps = {
  old: true,
};
