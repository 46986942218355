import { ErrorBoundary } from 'react-error-boundary';
import { OpportunityStatus, OrganizationStatus, PAGINATION_LIMIT } from 'api-client';
import { ReactComponent as ActivateBannerIllustration } from 'assets/svg/components/activate-banner-illustration.svg';
import { ReactComponent as EmptyStateSvg } from 'assets/svg/components/empty-state-illustration.svg';
import { Search } from 'ui-components/inputs/Search/Search';
import {
  AlertIcon,
  ArrowRight,
  CrossIconWithoutBg,
  SideBorder,
} from 'ui-components/data-display/Icons';
import { PrimaryButton, SecondaryButton } from 'ui-components/inputs/Buttons';
import { classNames } from 'utils/common';
import { useAuth } from 'hooks/useAuth';
import { useAuthStore } from 'hooks/useAuthStore';
import useActivateOrganization from 'hooks/useActivateOrganization';
import usePrefetchQueries from 'pages/pql-workflow/hooks/usePrefetchQueries';
import { useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { useExportPQLStore } from 'pages/pql-workflow/store/PQLExportStore';
import { useFiltersPQLStore } from 'pages/pql-workflow/store/PQLFiltersStore';
import { usePQLWorkflowStore } from 'pages/pql-workflow/store/PQLWorkflowStore';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import { routes } from 'routing/clientRoutes';
import { useAnalytics } from 'telemetry';
import { AnalyticsConst, AnalyticsEvents, ErrorAnalyticsEvents } from 'telemetry/constants';
import { useQueryStore } from 'stores/QueryStore';
import Modal from '../../ui-components/util/modal';
import FilterDestinations from './FilterDestinations';
import useOpportunities from './hooks/useOpportunities';
import { useOpportunityStatusCount } from './hooks/useOpportunityStatusCount';
import { usePQLFilter } from './hooks/usePQLFilter';
import OpportunityItem from './OpportunityItem';
import Salutation from './Salutation';
import SortOpportunities from './SortOpportunities';
import { useOpportunityListStore } from './store/OpportunityListStore';
import { ActivateSteps } from '../activate/store/ActivateStore';
import ErrorBoundaryFallback from 'ui-components/feedback/ErrorBoundaryFallback';
export function SampleDataBanner() {
  return (
    <div className="grid w-full border-t-4 place-items-center border-tw-orange-dd">
      <span className="px-2 pb-1 pt-0.5 mx-auto text-xs font-semibold text-white rounded-b-lg bg-tw-orange-dd">
        SAMPLE DATA
      </span>
    </div>
  );
}

export function AnimatePing({ className }: { className: string }) {
  return (
    <span className="relative grid w-3 h-3 place-items-center">
      <span
        className={classNames(
          'absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping',
          className
        )}
      ></span>
      <span className={classNames('relative inline-flex w-2 h-2 rounded-full', className)}></span>
    </span>
  );
}

const OpportunityStatuses: string[] = ['All', 'Active', 'Pending', 'Finished', 'Failed'];

export default function OpportunitiesList() {
  // local component state which manages currently selected status
  const router = useHistory();

  // zustand store
  const { queryParams } = useOpportunityListStore();

  // analytics
  const { track } = useAnalytics();

  // page queries
  const {
    data: opportunities,
    isLoading: loadingOpportunities,
    isError: errorFetchingOpportunities,
  } = useOpportunities();
  // opp status counts
  const { data: opportunityStatusCounts, isLoading: loadingCount } = useOpportunityStatusCount();
  const { user, shouldFireQueries } = useAuth();
  const { getAccountId } = useAuthStore();

  const { updateSearchParams, hydrateQueryParamsFromUrl } = usePQLFilter();
  const [pqlDuplicateErrorModalShow, setPQLDuplicateErrorModalShow] = useState(false);

  const { reset: resetDefineStore } = useDefinePQLStore();
  const { reset: resetExportStore } = useExportPQLStore();
  const { reset: resetFiltersStore } = useFiltersPQLStore();
  const { reset: resetPQLWorkflowStore } = usePQLWorkflowStore();

  const { resetQueryData } = useQueryStore();

  useEffect(() => {
    const queryParameters = new URLSearchParams(router.location.search);
    if (queryParameters.has('duplicateError')) {
      queryParameters.delete('duplicateError');
      setPQLDuplicateErrorModalShow(true);
      router.replace({
        search: queryParameters.toString(),
      });
    }
  }, [router]);
  const isSandbox = user?.currentOrganization.isSandbox;

  // prefetch queries
  shouldFireQueries && usePrefetchQueries();

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (pqlDuplicateErrorModalShow) {
        setPQLDuplicateErrorModalShow(false);
      }
    }, 4000);
    return () => {
      clearTimeout(timeOutId);
    };
  }, [pqlDuplicateErrorModalShow]);

  // initially hydrate the queryParams as per URL
  useEffect(() => {
    hydrateQueryParamsFromUrl(router.location.search, queryParams);
  }, [router.location.search, hydrateQueryParamsFromUrl, queryParams]);

  const opportunityStatusMap = new Map(
    opportunityStatusCounts?.map((opportunityStatusCount) => [
      opportunityStatusCount.status,
      opportunityStatusCount.count,
    ])
  );

  function getPQLStatusCount(status: string) {
    if (status === OpportunityStatus.FINISHED) {
      return (
        (opportunityStatusMap.get(OpportunityStatus.COMPLETED) ?? 0) +
        (opportunityStatusMap.get(OpportunityStatus.STOPPED) ?? 0)
      );
    }
    // TODO: update this when we rename scheduled to pending
    // on the backend.
    else if (status === OpportunityStatus.PENDING) {
      return opportunityStatusMap.get(OpportunityStatus.SCHEDULED) ?? 0;
    } else if (status === OpportunityStatus.ACTIVE) {
      return (
        (opportunityStatusMap.get(OpportunityStatus.ACTIVE) ?? 0) +
        (opportunityStatusMap.get(OpportunityStatus.RUNNING) ?? 0)
      );
    }

    return opportunityStatusMap.get(status) ?? 0;
  }

  const {
    activateButtonCTA,
    activateBannerHeader,
    activateBannerText,
    activateButtonLoading,
    organization,
    isActivateBannerTextError,
  } = useActivateOrganization();

  const trackActivationStatus = () => {
    if (!organization) {
      track(AnalyticsEvents.ACTIVATION_INITIATED, {});
    } else if (organization && organization.status === OrganizationStatus.READY) {
      if (organization?.timezone) {
        track(AnalyticsEvents.ACTIVATION_RESUMED, {
          [AnalyticsConst.STEP_NAME]: ActivateSteps.sources,
        });
        return;
      }
      if (user.avatarName) {
        track(AnalyticsEvents.ACTIVATION_RESUMED, {
          [AnalyticsConst.STEP_NAME]: ActivateSteps.timezone,
        });
        return;
      }
      track(AnalyticsEvents.ACTIVATION_RESUMED, {
        [AnalyticsConst.STEP_NAME]: ActivateSteps.avatar,
      });
    }
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={(e) =>
        track(ErrorAnalyticsEvents.ERROR_OCCURED, {
          error: e.message,
        })
      }
    >
      <div className="mx-auto 2xl:w-11/12 ">
        <div
          className={classNames(isSandbox && !organization?.isActive ? 'pt-8' : 'pt-4', 'relative')}
        >
          {(!isSandbox || organization?.isActive) && <Salutation />}
          {isSandbox && !organization?.isActive && (
            <div>
              <div className="px-8 mb-8">
                <h2 className="text-2xl font-semibold text-tw-black-1">{activateBannerHeader}</h2>
                <p
                  className={classNames(
                    'text-base max-w-[70%] mt-2.5',
                    isActivateBannerTextError ? 'text-tw-red-dd' : 'text-tw-black-5'
                  )}
                >
                  {activateBannerText}
                </p>
                {!activateButtonLoading && (
                  <PrimaryButton
                    onClick={() => {
                      trackActivationStatus();
                      router.replace(`/${getAccountId()}/activate`);
                    }}
                    className="flex items-center mt-8 text-sm text-white"
                  >
                    <span>{activateButtonCTA}</span>
                    <ArrowRight className="fill-current" />
                  </PrimaryButton>
                )}
                <ActivateBannerIllustration className="absolute top-0 right-0 h-full" />
              </div>
              <SampleDataBanner />
            </div>
          )}
        </div>
        <div className="pb-8 sm:px-8 2xl:px-0">
          <div
            className="relative grid grid-cols-5 pb-24 bg-tw-white-ff rounded-xl"
            style={{ minHeight: '70vh' }}
          >
            <div className="col-span-1 bg-tw-white-ff rounded-l-xl">
              {/* makes the top controls such as sort, filter and search stick on scroll */}
              <div className="sticky flex flex-col mt-12 border-l top-8 gap-y-4 border-tw-gray-eb">
                {OpportunityStatuses.map((status: string) => {
                  const isActive = queryParams.status.toLowerCase() === status.toLowerCase();
                  return (
                    <div key={status}>
                      <div
                        className={classNames(
                          isActive ? 'text-tw-blue-0d font-medium' : 'text-tw-black-5',
                          'flex items-center cursor-pointer gap-x-3'
                        )}
                        onClick={() => {
                          track(AnalyticsEvents.OPPORTUNITY_FILTER, {
                            [AnalyticsConst.TYPE]: 'status',
                            [AnalyticsConst.VALUE]: status.toLowerCase(),
                          });
                          // reset page number on status change.
                          updateSearchParams({
                            ...queryParams,
                            status: OpportunityStatuses.find((s) => s === status).toLowerCase(),
                            page: 1,
                            offset: 0,
                          });
                        }}
                      >
                        <SideBorder
                          className={classNames(
                            'h-6 fill-current',
                            !isActive && 'text-transparent'
                          )}
                        />
                        <div className="flex items-center gap-x-0.5">
                          <span>{status}</span>
                          {loadingCount || !opportunityStatusCounts ? (
                            <div></div>
                          ) : (
                            <div className="ml-1">({getPQLStatusCount(status.toLowerCase())})</div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-span-4">
              <div className="sticky top-0 z-5 bg-tw-white-ff rounded-r-xl">
                <div className="flex items-center justify-between pl-3 -ml-3 bg-tw-white-ff">
                  <div className="flex items-center filters">
                    {/* Search */}
                    <div className="mt-8">
                      <Search
                        placeholder="Search by Playbook name"
                        value={queryParams.query}
                        onChangeFunction={(e) => {
                          updateSearchParams({ ...queryParams, query: e.target.value });
                          track(AnalyticsEvents.OPPORTUNITY_SEARCH_QUERY, {
                            [AnalyticsConst.SEARCH_QUERY]: e.target.value,
                          });
                        }}
                        onClearFunction={() => {
                          updateSearchParams({
                            ...queryParams,
                            query: '',
                          });
                        }}
                        hasShadow={false}
                      />
                    </div>
                    <div>
                      <FilterDestinations />
                    </div>
                    <SortOpportunities />
                  </div>
                  <div className="mt-8 mr-4">
                    <PrimaryButton
                      className="product-tour-1"
                      onClick={() => {
                        track(AnalyticsEvents.OPPORTUNITY_INITIATED);

                        // reset query store for the old PQL workflow
                        resetQueryData();

                        // reset new pql workflow
                        resetPQLWorkflowStore();
                        resetDefineStore();
                        resetExportStore();
                        resetFiltersStore();
                        router.push(`/${getAccountId()}${routes.createOpportunity}`);
                      }}
                    >
                      + New Playbook
                    </PrimaryButton>
                  </div>
                </div>
                {!errorFetchingOpportunities && !loadingOpportunities && opportunities && (
                  <div>
                    <div className="flex items-center pt-4 pb-2 pl-3 -ml-3 bg-tw-white-ff">
                      <div>{opportunities.count} playbooks</div>
                      {queryParams.page !== 1 &&
                        queryParams.page <= Math.ceil(opportunities.count / PAGINATION_LIMIT) && (
                          <div className="ml-2 text-tw-gray-7">
                            (Page {queryParams.page} of{' '}
                            {Math.ceil(opportunities.count / PAGINATION_LIMIT)})
                          </div>
                        )}
                    </div>
                    <hr className="pt-2 mr-6 backdrop-blur-lg" />
                  </div>
                )}
              </div>
              {errorFetchingOpportunities && (
                <div className="flex justify-center w-full -ml-20">
                  <div className="my-28">
                    <AlertIcon className="w-24 h-24 ml-8" />
                    <div className="text-center">
                      <div className="mt-3 font-medium">Something went wrong</div>
                      <div className="mt-2 text-xs text-tw-black-5">
                        Please try reloading the page
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {loadingOpportunities ? (
                // makes the top controls such as sort, filter and search stick on scroll
                <div className="sticky w-full mt-4" style={{ top: '200px' }}>
                  <div>
                    <div className="w-32 h-5 mt-4 animate-pulse bg-tw-gray-eb"></div>
                    <hr className="pb-2 mt-2 mr-6 backdrop-blur-lg" />
                  </div>
                  <div className="mt-4 mb-16 mr-6">
                    {Array(3)
                      .fill(0)
                      .map((val, id) => {
                        return (
                          <div key={id} className="mb-4 rounded-xl border-1">
                            <div className="shadow-3xl">
                              <div className="grid grid-cols-4 px-4 pt-6 pb-4">
                                <div className="w-4/5">
                                  <div className="h-6 animate-pulse bg-tw-gray-eb"></div>
                                  <div className="w-2/3 h-6 mt-2 animate-pulse bg-tw-gray-eb"></div>
                                </div>
                                <div className="w-4/5 -ml-2">
                                  <div className="h-8 animate-pulse bg-tw-gray-eb"></div>
                                </div>
                                <div className="-ml-4">
                                  <div className="h-6 animate-pulse bg-tw-gray-eb"></div>
                                  <div className="w-2/3 h-6 mt-2 animate-pulse bg-tw-gray-eb"></div>
                                </div>
                                <div className="ml-4">
                                  <div className="h-8 animate-pulse bg-tw-gray-eb"></div>
                                </div>
                              </div>
                            </div>
                            <div className="w-full p-1 text-xs text-center cursor-pointer h-7 bg-tw-gray-f5 text-tw-black-5"></div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              ) : (
                <>
                  {!errorFetchingOpportunities && opportunities?.results.length === 0 && (
                    <div className="w-full -ml-20 text-center 2xl:mb-96">
                      {queryParams.query ? (
                        <div className="my-28">
                          <div className="text-tw-black-5">No results found</div>
                          <SecondaryButton
                            type="button"
                            onClick={() => {
                              updateSearchParams({
                                ...queryParams,
                                query: '',
                              });
                            }}
                            className="mx-auto mt-4 text-tw-blue-0d"
                          >
                            Clear search query
                          </SecondaryButton>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-center mt-12 mb-8">
                            <EmptyStateSvg />
                          </div>
                          <div className="text-tw-black-5">
                            Psst…you have no{' '}
                            {queryParams.status.toLowerCase() !==
                            OpportunityStatuses[0].toLowerCase()
                              ? queryParams.status.toLowerCase()
                              : ''}{' '}
                            Playbooks{' '}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {!errorFetchingOpportunities && opportunities && (
                    <div className="mt-4 mr-6">
                      <div>
                        {opportunities.results.map((opportunity) => {
                          return (
                            <div key={opportunity.id}>
                              <OpportunityItem key={opportunity.id} opportunity={opportunity} />
                            </div>
                          );
                        })}
                      </div>
                      {opportunities.results.length > 0 && (
                        <ReactPaginate
                          forcePage={queryParams.page - 1}
                          pageCount={Math.ceil(opportunities.count / PAGINATION_LIMIT)}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={1}
                          onPageChange={({ selected }) => {
                            updateSearchParams({
                              ...queryParams,
                              offset: selected * PAGINATION_LIMIT,
                              page: selected + 1,
                            });
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          containerClassName="flex items-center justify-end mt-8 mb-16 absolute right-4 -bottom-8"
                          activeClassName="children:bg-tw-gray-eb children:text-tw-black-5 children:font-medium"
                          pageLinkClassName="px-tw-3 py-2 rounded text-tw-black-5 hover:text-tw-black-5 font-medium"
                          breakLinkClassName="px-tw-3 py-2"
                          previousLabel={
                            <div className="flex items-center py-2 gap-x-2 px-tw-3 text-black-3">
                              <ArrowRight
                                className="fill-current"
                                style={{
                                  transform: 'rotate(-180deg)',
                                }}
                              />
                              <span>Prev</span>
                            </div>
                          }
                          nextLabel={
                            <div className="flex items-center py-2 gap-x-2 px-tw-3 text-black-3">
                              <span>Next</span>
                              <ArrowRight className="fill-current" />
                            </div>
                          }
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {pqlDuplicateErrorModalShow && (
        <Modal
          open={true}
          trigger={null}
          onClose={() => setPQLDuplicateErrorModalShow(false)}
          hrRequired={false}
          width="w-6/12"
          closeIcon={null}
        >
          <div className="w-full">
            <div className="flex justify-end mt-4 mr-4">
              <div
                className="flex justify-center w-6 h-6 p-2 rounded-full cursor-pointer bg-tw-gray-eb"
                onClick={() => setPQLDuplicateErrorModalShow(false)}
              >
                <CrossIconWithoutBg className="-mt-0.5 fill-current border-0 w-3 h-3 text-tw-black-7 mx-auto" />
              </div>
            </div>
            <div className="px-6 bg-tw-white-ff">
              <div className="mb-6 font-normal text-tw-black-5">
                Something unexpected happened and we are unable to duplicate this playbook. Please
                consider creating a new playbook.
              </div>
            </div>
          </div>
        </Modal>
      )}
    </ErrorBoundary>
  );
}
