import React from 'react';
import { Link } from 'react-router-dom';
import { TertiaryButton } from 'ui-components/inputs/Buttons';

export default function ErrorBoundaryFallback() {
  return (
    <div className="grid h-screen place-items-center">
      <div className="flex flex-col items-center gap-y-4">
        <p className="text-base font-medium text-tw-black-7">Something went wrong</p>
        <Link to={'/'}>
          <TertiaryButton>Go to home</TertiaryButton>
        </Link>
      </div>
    </div>
  );
}
