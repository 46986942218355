import { useHistory } from 'react-router-dom';
import { QueryParams, sortFilters, useOpportunityListStore } from '../store/OpportunityListStore';
import qs from 'query-string';
import { useCallback } from 'react';
import { PAGINATION_LIMIT } from 'api-client';
import { useAuth } from '../../../hooks/useAuth';

// wrappers around the redux store.
// 1. updateSearchParams updates the store's queryParams, which refetches opportunities.
// 2. hydrateSearchParams initially updates the store
export function usePQLFilter() {
  const router = useHistory();
  const { updateQueryParams, updateDestinationFieldMapping } = useOpportunityListStore();
  const { user } = useAuth();

  // updates the store and pushes to the next page state.
  const updateSearchParams = useCallback(
    (queryParams: QueryParams) => {
      updateQueryParams(queryParams);

      // form the URL object to push the user into.
      let search = {};

      if (queryParams.query) {
        search['query'] = queryParams.query;
      }

      if (queryParams.status) {
        search['status'] = queryParams.status;
      }

      if (queryParams.destinations) {
        search['destination'] = queryParams.destinations;
      }

      if (queryParams.page) {
        search['page'] = queryParams.page;
      }

      if (queryParams.selectedOrderFilter.filter.value) {
        search['order'] = queryParams.selectedOrderFilter.filter.value;
      } else {
        search['order'] = sortFilters[0].value;
      }
      if (queryParams.duplicateError) {
        search['duplicateError'] = true;
      }

      if (user?.currentOrganization?.isSandbox) {
        search['isSandbox'] = true;
      } else search['isSandbox'] = false;

      router.replace({
        search: qs.stringify(search),
      });
    },
    [router, updateQueryParams, user?.currentOrganization?.isSandbox]
  );

  // builds an object from URL params and updates the store.
  const hydrateQueryParamsFromUrl = useCallback(
    (searchUrl: string, payload: QueryParams) => {
      let queryParamsFromUrl = payload;

      // parse the URL
      const searchParamsFromUrl = qs.parse(searchUrl);

      if (searchParamsFromUrl.duplicateError) {
        queryParamsFromUrl['duplicateError'] = true;
      }

      // builds the new URL
      if (searchParamsFromUrl.query) {
        queryParamsFromUrl['query'] = searchParamsFromUrl.query as string;
      } else queryParamsFromUrl['query'] = '';

      if (searchParamsFromUrl.destination) {
        const { destination } = searchParamsFromUrl;
        let destinationArr = Array.isArray(destination) ? destination : [destination];
        let initialDestinationsFieldMapping = {};
        destinationArr.forEach((destinationId) => {
          initialDestinationsFieldMapping[destinationId as string] = destinationId;
        });
        updateDestinationFieldMapping(initialDestinationsFieldMapping);
        queryParamsFromUrl['destinations'] = Object.keys(initialDestinationsFieldMapping);
      } else queryParamsFromUrl['destinations'] = [];

      if (searchParamsFromUrl.status) {
        queryParamsFromUrl['status'] = searchParamsFromUrl.status as string;
      } else queryParamsFromUrl['status'] = 'all';

      if (searchParamsFromUrl.order) {
        const filterApplied = sortFilters.find(
          (sf) => sf.value.toLowerCase() === (searchParamsFromUrl.order as string).toLowerCase()
        );
        queryParamsFromUrl.selectedOrderFilter.filter = filterApplied || sortFilters[0];
      } else {
        // reset the sort order.
        queryParamsFromUrl.selectedOrderFilter.filter = sortFilters[0];
      }

      if (searchParamsFromUrl.page) {
        queryParamsFromUrl['page'] = parseInt(searchParamsFromUrl.page as string);
        queryParamsFromUrl['offset'] = (queryParamsFromUrl['page'] - 1) * PAGINATION_LIMIT;
      } else {
        queryParamsFromUrl['page'] = 1;
        queryParamsFromUrl['offset'] = 0;
      }
      updateSearchParams(queryParamsFromUrl);
    },
    [updateDestinationFieldMapping, updateSearchParams]
  );

  return {
    updateSearchParams,
    hydrateQueryParamsFromUrl,
  };
}
