import { ChakraProvider, useToast } from '@chakra-ui/react';
import { css, Global } from '@emotion/react';
import * as Sentry from '@sentry/react';
import { ApiContextProvider } from 'api-client';
import './css/App.css';
import { InfoAlertIcon } from 'ui-components/data-display/Icons';
import { useServiceWorker } from 'hooks/useServiceWorker';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, Switch, useLocation } from 'react-router-dom';
import AuthRoute from 'routing/AuthRoute';
import { ClientRouteInterface, ClientRoutes } from 'routing/clientRoutes';
import { useGlobalStore } from 'stores/GlobalStore';
import { SegmentAnalyticsApiProvider } from './telemetry';
import { useStore } from './stores';
import theme from './theme';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */

  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
    mutations: {
      onError: (error) => {
        Sentry.captureException(error);
      },
    },
  },
});

// eslint-disable-next-line react/display-name
const _routeMap = (url: string) => (route: ClientRouteInterface) => {
  const { isSecure, children, component: Component, path, props: componentProps, ...props } = route;
  const RouteComponent = isSecure ? AuthRoute : Route;

  return (
    <RouteComponent
      key={`${url}${path}`}
      render={(routeProps: any) => {
        const {
          match: { url: urlParams },
        } = routeProps;

        if (Component && children) {
          return (
            <Component {...componentProps} {...routeProps}>
              <Switch>{children.map(_routeMap(urlParams))}</Switch>
            </Component>
          );
        } else if (children) {
          return <Switch>{children.map(_routeMap(urlParams))}</Switch>;
        }

        return Component && <Component {...componentProps} {...routeProps} />;
      }}
      path={`${url}${path}`}
      {...props}
    />
  );
};

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function App() {
  // init global store.
  useGlobalStore();
  useStore();
  // ignore this toast, this is used for service worker notification.
  const toast = useToast();
  const { showReload, waitingWorker, reloadPage } = useServiceWorker();

  // decides when to show the toast
  useEffect(() => {
    if (showReload && waitingWorker) {
      if (!toast.isActive('update-toast')) {
        toast({
          id: 'update-toast',
          position: 'bottom-left',
          duration: null,
          isClosable: false,
          render: ({ onClose }) => (
            <div className="flex mb-10 ml-24 rounded-lg bg-tw-white-ff shadow-3xl drop-shadow-3xl">
              <div className="min-h-full p-6 rounded-l-lg bg-tw-gray-f5">
                <InfoAlertIcon className="w-6 h-6 fill-current text-tw-black-9" />
              </div>
              <div className="flex items-center p-6">
                <div>A new version of this page is available</div>
                <div
                  className="cursor-pointer text-blue-600 font-bold ml-4 mt-0.5"
                  onClick={() => {
                    reloadPage();
                    // closes the toast
                    onClose();
                  }}
                >
                  REFRESH
                </div>
              </div>
            </div>
          ),
        });
      }
    } else toast.close('update-toast');
  }, [waitingWorker, showReload, toast, reloadPage]);
  return (
    <>
      <ScrollToTop />
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <Global styles={GlobalStyles} />
          <ApiContextProvider>
            <SegmentAnalyticsApiProvider>
              <ToastContainer
                position="top-right"
                autoClose={2300}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={1}
                icon={false}
              />
              <Switch>{ClientRoutes.map(_routeMap(''))}</Switch>
            </SegmentAnalyticsApiProvider>
          </ApiContextProvider>
        </ChakraProvider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </>
  );
}
