import * as React from 'react';
import { getSourceImg, getTableCell } from '../../../utils/common';
import Tooltip from '../../data-display/Tooltip';

interface HighlightsData {
  items: {
    [key: string]: any;
  };
}

const Highlights: React.FC<HighlightsData> = (props) => {
  let keys = Object.keys(props.items);
  if (keys.length > 0) {
    return (
      <div className={'grid grid-cols-2 gap-x-2'}>
        {keys.map((key, index) => {
          if (props.items[key].show) {
            return (
              <div className={'grid grid-cols-3 mb-4 col-span-1'} key={index}>
                <div className={'flex col-span-2'}>
                  <img
                    className={'mr-3 w-6 filter grayscale opacity-60'}
                    alt={props.items[key].source}
                    src={getSourceImg(props.items[key].source)}
                  />
                  {props.items[key].displayName.length > 25 ? (
                    <Tooltip label={`${props.items[key].displayName}`} placement={'left'}>
                      <p className={'font-medium  text-tw-black-1  mr-4'}>
                        {props.items[key].displayName.slice(0, 25)}...
                      </p>
                    </Tooltip>
                  ) : (
                    <p className={'font-medium  text-tw-black-1 w-48 mr-4'}>
                      {props.items[key].displayName}
                    </p>
                  )}
                </div>
                <div className={'flex items-center '}>
                  {props.items[key].value && String(props.items[key].value).length > 14 ? (
                    <Tooltip label={props.items[key].value} placement={'left'}>
                      <p className={'font-normal  text-tw-black-5 truncate'}>
                        {getTableCell(props.items[key].value, props.items[key].keyName)}
                      </p>
                    </Tooltip>
                  ) : (
                    <p className={'font-normal  text-tw-black-5 w-44'}>
                      {getTableCell(props.items[key].value, props.items[key].keyName)}
                    </p>
                  )}
                </div>
              </div>
            );
          } else {
            return '';
          }
        })}
      </div>
    );
  } else {
    return (
      <div className={'flex justify-center'}>
        <p className={'text-xl'}>No Data Available</p>
      </div>
    );
  }
};
export default Highlights;
