import { classNames } from 'utils/common';
import { useState, useRef, useEffect, useCallback } from 'react';
import { CaretLeft, CaretRight } from 'ui-components/data-display/Icons';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { noop } from 'lodash';
import { motion } from 'framer-motion';

const TwoOrgSlider = ({ orgs, activeSlideIndex, setActiveSlideIndex }) => {
  const handleSelectOrg = useCallback(
    (index) => {
      setActiveSlideIndex(index);
    },
    [setActiveSlideIndex]
  );

  return (
    <div className="flex items-start h-full ml-5">
      {orgs.map((org, index) => {
        return (
          <div
            className="flex items-center justify-center w-full mr-8"
            onClick={() => {
              handleSelectOrg(index);
            }}
            key={org.schemaName}
          >
            <div>
              <div>
                <motion.div
                  className="flex justify-center"
                  animate={{
                    scale: activeSlideIndex === index ? 1.1 : 1,
                    x:
                      activeSlideIndex === index
                        ? index === 0
                          ? 60
                          : -40
                        : index === 0
                        ? -40
                        : 60,
                  }}
                  transition={{
                    duration: 0.3,
                    ease: 'easeOut',
                  }}
                  initial={{ x: 0 }}
                >
                  <div
                    className={classNames(
                      activeSlideIndex === index
                        ? 'w-28 h-28 text-3.5xl'
                        : 'w-20 h-20 opacity-40 mt-5 cursor-pointer text-2xl',
                      'flex items-center justify-center  rounded-full  bg-tw-blue-f2 text-tw-blue-3c font-bold uppercase'
                    )}
                  >
                    {org.name[0]}
                  </div>
                </motion.div>
                {activeSlideIndex === index && (
                  <div
                    className={classNames(
                      'flex flex-col items-center',
                      index === 0 ? 'translate-x-14' : '-translate-x-12'
                    )}
                  >
                    <p className="mt-4 text-base font-medium text-center text-tw-black-1">
                      {org.name}
                    </p>
                    <p className="mt-2 font-medium text-tw-black-9">
                      {org.userCount} member
                      {org.userCount > 1 && 's'}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const PrevArrowComponent = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{ height: '8rem', position: 'absolute', top: '-3%', left: '-5%' }}
    >
      <CaretLeft />
    </button>
  );
};

const NextArrowComponent = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{ height: '8rem', position: 'absolute', top: '-3%', right: '-5%' }}
    >
      <CaretRight />
    </button>
  );
};

export default function OrgSlider({ orgs, handleSetActiveOrg }) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  useEffect(() => {
    // set active org in useEffect and not in afterChange callback for the slider so it runs on mount too.
    handleSetActiveOrg(orgs[activeSlideIndex]);
  }, [activeSlideIndex]);

  useEffect(() => {
    // set center slide as active slide in case of 3 orgs
    if (orgs.length === 3) setActiveSlideIndex(1);
  }, []);

  const sliderRef = useRef() as any;

  const settings = {
    centerMode: true,
    slidesToShow: orgs.length > 3 ? 3 : orgs.length,
    initialSlide: orgs.length === 3 ? 1 : 0,
    speed: 300,
    arrows: orgs.length > 3,
    centerPadding: '0px',
    focusOnSelect: true,
    prevArrow: <PrevArrowComponent onClick={undefined} />,
    nextArrow: <NextArrowComponent onClick={undefined} />,
    afterChange: (index: number) => {
      setActiveSlideIndex(index);
    },
  };
  return (
    <div className="flex justify-center w-full">
      <div>
        {orgs.length === 1 && (
          <div className="flex flex-col items-center justify-center w-full">
            <div className="flex justify-center">
              <div
                className={classNames(
                  'flex items-center justify-center  rounded-full  bg-tw-blue-f2 text-tw-blue-3c  font-bold w-30 h-30 text-3.5xl'
                )}
              >
                {orgs[0].name[0]}
              </div>
            </div>
            <div className="flex flex-col items-center">
              <p className="mt-4 text-base font-medium text-tw-black-1">{orgs[0].name}</p>
              <p className="mt-2 font-medium text-tw-black-9">
                {orgs[0].userCount} member
                {orgs[0].userCount > 1 && 's'}
              </p>
            </div>
          </div>
        )}
        {orgs.length == 2 && (
          <TwoOrgSlider
            orgs={orgs}
            activeSlideIndex={activeSlideIndex}
            setActiveSlideIndex={setActiveSlideIndex}
          />
        )}
        {
          // Temporarily add explicit next and prev buttons for case where # of orgs < 3, we'll later drive this with our form of react-slick library
        }
        {orgs.length > 2 && (
          <div className="flex justify-center w-full">
            {orgs.length <= 3 && (
              <button
                style={{ height: '8rem' }}
                onClick={() => {
                  if (sliderRef && sliderRef.current && sliderRef.current.slickPrev)
                    sliderRef?.current?.slickPrev();
                  else noop();
                }}
              >
                <CaretLeft />
              </button>
            )}
            <div style={{ width: orgs.length >= 3 ? '400px' : '300px', height: '210px' }}>
              <Slider {...settings} ref={sliderRef}>
                {orgs.map((org, index) => {
                  return (
                    <div key={org.schemaName} className="flex items-center justify-center w-full">
                      <div className="flex justify-center">
                        <div
                          className={classNames(
                            activeSlideIndex === index
                              ? 'w-30 h-30 text-3.5xl'
                              : 'w-20 h-20 opacity-40 mt-5 cursor-pointer text-2xl',
                            'flex items-center justify-center  rounded-full  bg-tw-blue-f2 text-tw-blue-3c font-bold uppercase'
                          )}
                        >
                          {org.name[0]}
                        </div>
                      </div>
                      {activeSlideIndex === index && (
                        <div className="flex flex-col items-center">
                          <p className="mt-4 text-base font-medium text-center text-tw-black-1">
                            {org.name}
                          </p>
                          <p className="mt-2 font-medium text-tw-black-9">
                            {org.userCount} member
                            {org.userCount > 1 && 's'}
                          </p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </Slider>
            </div>
            {orgs.length <= 3 && (
              <button
                style={{ height: '8rem' }}
                onClick={() => {
                  if (sliderRef && sliderRef.current && sliderRef.current.slickNext)
                    sliderRef?.current?.slickNext();
                  else noop();
                }}
              >
                <CaretRight />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
