import { GoogleLogo, InfoIcon, ToplyneLogo } from 'ui-components/data-display/Icons';
import { PrimaryButton } from 'ui-components/inputs/Buttons';
import { PasswordInput, TextInput } from 'ui-components/inputs/Inputs';
import { classNames, passwordRegExp, passwordValidationString } from 'utils/common';
import useOAuth from 'hooks/use-oauth';
import { useAuth } from 'hooks/useAuth';
import LoginRightBanner from 'ui-components/business/LoginRightBanner';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { ReactComponent as SendIcon } from '../../assets/images/send.svg';
import useInviteDetails from '../../hooks/useInviteDetails';

interface SignUpProps {
  match: any;
}

type SignUpFormState = {
  password: string;
};

const SignUpByInvite: React.FC<SignUpProps> = (props) => {
  const token = props.match.params.invitation_token;
  const { data: inviteDetails } = useInviteDetails(token);
  const { signUpByEmailInvite, googleAuth } = useAuth(false);

  const renderUserDetails = () => {
    if (
      inviteDetails &&
      inviteDetails.invitation.createdBy.firstName &&
      inviteDetails.invitation.createdBy.lastName
    ) {
      return (
        inviteDetails.invitation.createdBy.firstName +
        ' ' +
        inviteDetails.invitation.createdBy.lastName
      );
    } else if (inviteDetails && inviteDetails.invitation.createdBy.email) {
      return inviteDetails.invitation.createdBy.email;
    }
  };

  const onSubmit = (data: SignUpFormState) =>
    signUpByEmailInvite({ password: data.password, token });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpFormState>();

  const oauthCallback = React.useCallback(
    (err, code) => {
      googleAuth.login(code, token);
    },
    [googleAuth, token]
  );

  const authorize = useOAuth(
    'google',
    process.env.REACT_APP_GOOGLE_CLIENT_ID,
    'https://accounts.google.com/o/oauth2/v2/auth',
    'email',
    'login',
    oauthCallback
  );

  return (
    <div className="h-screen">
      <div className="flex">
        <div className="w-3/5 h-screen p-4">
          <div className="pl-4 text-center">
            <ToplyneLogo />
          </div>
          <div className="flex items-center justify-center px-5 py-5 mt-4">
            <div className="w-3/5 mx-auto">
              <p className={'font-medium text-3.5xl text-center'}>Create your Account</p>
              {inviteDetails && (
                <div className={'flex justify-center mt-4 mb-6'}>
                  <span className="flex flex-row px-2 py-1 border rounded border-tw-gray-c w-fc">
                    <div className="mr-2">
                      <SendIcon className="inline-block h-full align-middle" />
                    </div>
                    <p className={'text-tw-gray-7 font-base font-normal'}>
                      {renderUserDetails()} has invited you to join{' '}
                      {inviteDetails.organization.name}
                    </p>
                  </span>
                </div>
              )}
              <div className="px-4">
                <button
                  data-testid="google-login-button"
                  className="flex items-center justify-center w-full p-4 mt-12 rounded gap-x-2 border-tw-blue-0d border-1"
                  onClick={() => authorize()}
                >
                  <span>
                    <GoogleLogo />
                  </span>
                  <span className="font-bold uppercase text-tw-blue-0d">
                    {googleAuth.isLoading ? `Logging in` : `Continue with Google`}
                  </span>
                </button>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="px-4 mt-5">
                  <div className="mt-4 form-group">
                    <label htmlFor="email" className={'font-normal text-sm text-tw-black-5 mb-2'}>
                      Work email
                    </label>
                    <TextInput className="w-full py-3" disabled value={inviteDetails?.email} />
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="password"
                      className={'font-normal text-sm text-tw-black-5 mb-2'}
                    >
                      Password
                    </label>
                    <PasswordInput
                      className={classNames('w-full py-3', errors.password ? 'border-tw-red' : '')}
                      {...register('password', {
                        required: true,
                        pattern: passwordRegExp,
                      })}
                    />
                    <div className="flex flex-row mt-2">
                      <div>
                        <InfoIcon
                          className={`mr-2 w-4 ${
                            errors.password ? `fill-current text-tw-red` : `fill-current`
                          }`}
                        />
                      </div>
                      <div>
                        <p
                          className={` text-xs ${
                            errors.password ? `text-tw-red` : `text-tw-black-9`
                          }`}
                        >
                          {passwordValidationString}
                        </p>
                      </div>
                    </div>
                  </div>
                  <PrimaryButton type="submit" className="w-full py-3">
                    Get Started
                  </PrimaryButton>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="w-2/5">
          <LoginRightBanner />
        </div>
      </div>
    </div>
  );
};

export default SignUpByInvite;
