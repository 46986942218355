import { IntegrationType, useClient } from 'api-client';
import dayjs from 'dayjs';
import { useAuth } from 'hooks/useAuth';
import useConnectedIntegrations from 'hooks/useConnectedIntegrations';
import useDsEnabled from 'hooks/useDsEnabled';
import useEntitiesQuery from 'hooks/useEntitiesQuery';
import useModels from 'hooks/useModels';
import {
  initialQueryParamsState,
  useOpportunityListStore,
} from 'pages/opportunities/store/OpportunityListStore';
import { OpportunitySchedule } from 'pages/opportunities/types';
import { Entity, FieldMapping, SortBy } from 'pages/users-and-accounts/users/types';
import { useMutation, useQueryClient } from 'react-query';
import { toast as toastify } from 'react-toastify';
import { Filter, QueryGoal, QueryType } from 'stores/QueryStore';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';
import { GtmStrategy, useDefinePQLStore } from '../store/PQLDefineStore';
import {
  Cadence,
  CadenceFrequency,
  ExtraFormData,
  OneTimeType,
  Schedule,
  ScheduleType,
  useExportPQLStore,
} from '../store/PQLExportStore';
import { ExportCategory, LeadStatus, useFiltersPQLStore } from '../store/PQLFiltersStore';
import { usePQLWorkflowStore } from '../store/PQLWorkflowStore';
import { replaceValueForLeadStatus } from './useOpportunityQuery';

type RelativeTime = {
  timeUnit: string;
  start: number;
  end: number;
};

type FixedTime = {
  start: string;
  end: string;
};

type Time = {
  relative?: RelativeTime;
  fixed?: FixedTime;
};

type Query = {
  type: QueryType | '';
  goal: QueryGoal;
  modelId: string;
  gtmStrategy: GtmStrategy;
  fieldMapping: FieldMapping[];
  filter: Filter[];
  sortBy: Array<SortBy>;
  time: Time;
  sampleProfiles: boolean;
  cohort: number | null;
  performanceTrackEvent?: object;
};

enum LimitType {
  PERCENTAGE = 'percentage',
  COUNT = 'count',
}
type Limit = {
  by: LimitType;
  start: number;
  end: number;
};

type FieldMappingEntity = {
  source: string;
  keyName: string;
  keySpace: string;
  keyAlias: string;
  isUniqueKey?: boolean;
};

type ControlGroupPayload = {
  metadata: {
    percentage: number;
  };
};

type ExportPayload = {
  destination: number;
  filter: {
    lead_status?: string[];
    limit?: Limit;
  };
  metadata: Record<string, string | Array<String> | null | Record<string, string>>;
  field_mapping: FieldMappingEntity[];
  tags: Record<string, string>;
  schedule: OpportunitySchedule;
  control_group?: ControlGroupPayload;
};

const getFilterPayload = (
  exportCategory: ExportCategory,
  salesCapacity: number,
  percentage: number,
  leadStatus: string[],
  dsEnabled: boolean
) => {
  let ret = {};
  if (exportCategory === ExportCategory.SALES && salesCapacity)
    ret = {
      limit: {
        by: LimitType.COUNT,
        start: 0,
        end: salesCapacity,
      },
    };
  else if (exportCategory === ExportCategory.PERCENTAGE && percentage)
    ret = {
      limit: {
        by: LimitType.PERCENTAGE,
        start: 0,
        end: percentage,
      },
    };
  if (dsEnabled) {
    ret['lead_status'] =
      leadStatus[0] === LeadStatus.ALL
        ? [LeadStatus.HOT, LeadStatus.WARM, LeadStatus.COLD]
        : leadStatus;
  } else {
    ret['lead_status'] = null;
  }

  return ret;
};

export function getMetadata(
  destinationType: IntegrationType,
  object: string,
  subObject: string | undefined,
  emails: string[],
  extraFormData: ExtraFormData
) {
  switch (destinationType) {
    case IntegrationType.SALESFORCE:
      return { salesforce: { sObject: object } };
    case IntegrationType.SALESFORCE_SANDBOX:
      return { salesforceSandbox: { sObject: object } };
    case IntegrationType.HUBSPOT:
      return { hubspot: { object: object } };
    case IntegrationType.PIPEDRIVE:
      return { pipedrive: { object: object } };
    case IntegrationType.WEBHOOK:
      return { webhook: {} };
    case IntegrationType.EMAIL:
      return { email: emails };
    default:
      return {
        [destinationType]: { ...extraFormData, entity: object, subEntity: subObject },
      };
  }
}

function getDays(cadence: Cadence) {
  switch (cadence.type) {
    case CadenceFrequency.WEEKLY:
      return cadence.weeklyValues;
    case CadenceFrequency.MONTHLY:
      return cadence.monthlyValues;
    case CadenceFrequency.HOURLY:
      return cadence.hourlyValue;
  }
}

function getSchedulePayload(schedule: Schedule, timezone: string) {
  const timeInHMS = dayjs.tz(schedule.time, timezone).format('HH:mm:00');
  switch (schedule.type) {
    case ScheduleType.RECURRING:
      return {
        recurring: {
          start:
            schedule.cadence.type === CadenceFrequency.HOURLY
              ? `${dayjs.tz(schedule.startDate, 'UTC').format('YYYY-MM-DD')}${dayjs //for hourly merge the start time and date
                  .tz(schedule.time, 'UTC')
                  .format('THH:mm:00.000[Z]')}`
              : dayjs.tz(schedule.startDate, 'UTC').format('YYYY-MM-DDT00:00:00.000[Z]'),
          // end: endDate,
          frequency: {
            [schedule.cadence.type]:
              schedule.cadence.type === CadenceFrequency.DAILY
                ? {
                    time: timeInHMS,
                  }
                : {
                    time: timeInHMS,
                    days: getDays(schedule.cadence),
                  },
          },
        },
      };
    case ScheduleType.ONETIME:
      return {
        time:
          schedule.oneTimeType === OneTimeType.NOW
            ? dayjs.tz(dayjs().add(2, 'm'), timezone).format()
            : dayjs.tz(schedule.time, timezone).format(),
      };
  }
}

export default function useCreatePQL() {
  const client = useClient();
  const {
    targetEntity,
    modelId,
    gtmStrategy,
    leadStatus,
    showSampleProfiles,
    cohorts,
    performanceTrackEvent,
  } = useDefinePQLStore();
  const { filters, exportCategory, percentage, salesCapacity, controlGroup } = useFiltersPQLStore();
  const {
    destinationId,
    name,
    object,
    emails,
    uniqueKeys,
    mappings,
    tags,
    schedule,
    extraFormData,
    subObject,
  } = useExportPQLStore();
  const { data: models } = useModels();
  const { data: connectedIntegrations } = useConnectedIntegrations(false);
  const { data: entities } = useEntitiesQuery(targetEntity.toLowerCase() as Entity);
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { queryParams, updateQueryParams } = useOpportunityListStore();
  const opportunitiesQueryKey = [
    'opportunities',
    queryParams.offset,
    queryParams.query,
    queryParams.status,
    queryParams.selectedOrderFilter,
    queryParams.destinations,
  ];
  const dsEnabled = useDsEnabled(modelId, targetEntity);

  const { setCreatePQLModal } = usePQLWorkflowStore();

  const integration = connectedIntegrations?.find((i) => i.id === destinationId);
  let query: Partial<Query> = {
    type: targetEntity,
    goal: models.find((model) => model.modelId === modelId)?.goal.toUpperCase() as QueryGoal,
    modelId: modelId,
    fieldMapping: [],
    filter: replaceValueForLeadStatus(filters),
    sortBy: [],
    sampleProfiles: showSampleProfiles,
    performanceTrackEvent: performanceTrackEvent,
  };

  let fieldMapping = [];

  if (integration?.integration.supportsEntities) {
    uniqueKeys.forEach((uniqueKey) => {
      const entity = entities?.find((e) => e.columnName === uniqueKey?.source.split(',')[0]);
      entity &&
        fieldMapping.push({
          source: entity?.source,
          keyName: entity?.keyName,
          columnName: entity?.columnName,
          keySpace: entity?.keySpace,
          keyAlias: uniqueKey.destination,
          isUniqueKey: true,
        });
    });
  }

  mappings.forEach((m) => {
    const columnName = m.source.split(',')[0];
    const entity = entities?.find((e) => e.columnName === columnName);
    entity &&
      fieldMapping.push({
        source: entity?.source,
        keyName: entity?.keyName,
        columnName: entity?.columnName,
        keySpace: entity?.keySpace,
        keyAlias: m.destination,
        isUniqueKey: false,
      });
  });

  let exportPayload: Partial<ExportPayload> = {
    destination: destinationId,
    filter: getFilterPayload(exportCategory, salesCapacity, percentage, leadStatus, dsEnabled),
    metadata: getMetadata(integration?.type, object, subObject, emails, extraFormData),
    field_mapping: fieldMapping,
    tags: tags.reduce((obj, item) => Object.assign(obj, { [item.name]: item.value }), {}),
    schedule: getSchedulePayload(schedule, user?.currentOrganization.timezone),
  };

  if (controlGroup.enabled) {
    exportPayload['control_group'] = {
      metadata: {
        percentage: controlGroup.value,
      },
    };
  }

  return useMutation(
    () =>
      client.createPQL(name, name, query, gtmStrategy, cohorts, performanceTrackEvent, [
        exportPayload,
      ]),
    {
      onSuccess: (data) => {
        setCreatePQLModal({
          open: true,
          expanded: false,
          payload: data?.schedule,
          opportunityId: data?.id,
          nextRun: data?.nextRun,
        });
        // refetch the opportunitie &
        // reinitialise the opp list state
        updateQueryParams(initialQueryParamsState);
        queryClient.refetchQueries([
          'opportunities',
          'count',
          {
            from: dayjs().startOf('week').format('YYYY-MM-DD'),
            to: dayjs().endOf('week').format('YYYY-MM-DD'),
          },
        ]);
        queryClient.refetchQueries([
          'opportunities',
          'count',
          queryParams.query,
          queryParams.destinations,
        ]);
        queryClient.refetchQueries(opportunitiesQueryKey);
      },
      onError: () => {
        toastify(<ErrorToast description="Failed to create the Playbook" />, {
          type: 'error',
        });
        setTimeout(() => {
          setCreatePQLModal({
            open: false,
            expanded: false,
            payload: null,
            opportunityId: null,
            nextRun: '',
          });
        }, 2000);
      },
    }
  );
}
