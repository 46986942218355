import { getPropertyDisplayName, getTableCell } from 'utils/common';
import { AccountFields } from 'pages/users-and-accounts/fields';
import { LoggedInUser } from 'api-client';

export function getDefaultAccountTableColumn(userData: LoggedInUser) {
  if (!userData) {
    return [];
  }
  const { currentOrganization } = userData;

  const defaultFields: string[] = [];
  if (currentOrganization[AccountFields.ACCOUNT_ID]) {
    defaultFields.push(currentOrganization[AccountFields.ACCOUNT_ID]);
  }

  if (currentOrganization[AccountFields.ACCOUNT_CREATED_AT]) {
    defaultFields.push(currentOrganization[AccountFields.ACCOUNT_CREATED_AT]);
  }

  return defaultFields;
}

export function getAccountTableColumns(userData: any) {
  if (!userData) {
    return [];
  }

  const { currentOrganization } = userData;

  const defaultFields = [];
  if (currentOrganization[AccountFields.ACCOUNT_ID]) {
    defaultFields.push(currentOrganization[AccountFields.ACCOUNT_ID]);
  }

  if (currentOrganization[AccountFields.ACCOUNT_CREATED_AT]) {
    defaultFields.push(currentOrganization[AccountFields.ACCOUNT_CREATED_AT]);
  }

  return [
    ...defaultFields,
    ...currentOrganization.accountPersonaProperties.filter((item) => !defaultFields.includes(item)),
  ].map((item) => {
    return {
      Header: getPropertyDisplayName(item),
      accessor: item,
      Cell: ({ cell }) => getTableCell(cell.value, cell.column.id),
    };
  });
}
