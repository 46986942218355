import * as React from 'react';
import Highlights from './Highlights';
import arrowRight from '../../../assets/svg/components/arrow-right.svg';

interface ProfilePropertiesData {
  data: {
    [key: string]: any;
  };
  showMore: any;
}

const ProfileProperties: React.FC<ProfilePropertiesData> = (props) => {
  return (
    <>
      <div className={'py-4 px-0'}>
        <Highlights items={props.data} />
      </div>
      <div className={'mb-4 border-b-1 border-dashed'} />
      <span className={'inline-flex cursor-pointer items-center'} onClick={() => props.showMore()}>
        <span className={'text-tw-blue-0d font-semibold text-base mr-1.5'}>More</span>
        <img alt={'arrow-right'} className="fill-current text-tw-blue-0d" src={arrowRight} />
      </span>
    </>
  );
};

export default ProfileProperties;
