import { classNames } from 'utils/common';

type BlockProps = {
  onClick: () => void;
  children: React.ReactNode;
  isActive: boolean;
};

export default function Block({ onClick, children, isActive }: BlockProps) {
  return (
    <span
      onClick={onClick}
      className={classNames(
        'px-4 py-2 rounded cursor-pointer',
        isActive ? 'bg-tw-blue-f2 border-tw-blue-0d text-tw-blue-0d' : 'bg-tw-gray-f5'
      )}
    >
      {children}
    </span>
  );
}
