import { motion } from 'framer-motion';
import useAuthUser from 'hooks/useAuthUser';
import useDsEnabled from 'hooks/useDsEnabled';
import { useSyncScroller } from 'hooks/useSyncScroll';
import useCohortTabs from 'pages/pql-workflow/hooks/useCohortTabs';
import useOpportunityQuery from 'pages/pql-workflow/hooks/useOpportunityQuery';
import { useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { useFiltersPQLStore } from 'pages/pql-workflow/store/PQLFiltersStore';
import UserAccountsTable from 'pages/users-and-accounts/components/UserAccountsTable';
import { AccountFields, UserFields } from 'pages/users-and-accounts/fields';
import { useCallback, useEffect, useLayoutEffect, useMemo, useReducer } from 'react';
import { Column, useColumnOrder, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { QueryType } from 'stores/QueryStore';
import { EmptyFolder } from 'ui-components/data-display/Icons';
import {
  classNames,
  formatForCellDisplay,
  formattedColumnNames,
  updateCellWidthMap,
} from 'utils/common';
import { trim } from 'utils/helpers';
import { getCohortIconAndColor } from '../define-pql/Cohort';

type SampleEntitiesProp = {
  qualifiedEntitiesCount: number;
};

function getHeaderAndCellComponent(personaProperty: string, primaryField: string) {
  return {
    Header: (
      <div className="pl-1 font-regular text-tw-black-1">
        {formattedColumnNames(personaProperty)}
      </div>
    ),
    accessor: personaProperty,
    // set fixed column width
    Cell: (row) => {
      return (
        <div className="flex items-center pl-1">{trim(row?.value?.toString() || '--', 20)}</div>
      );
    },
    sticky: personaProperty === primaryField ? 'left' : false,
  };
}

export default function SampleEntities(props: SampleEntitiesProp) {
  const { qualifiedEntitiesCount } = props;
  const { modelId, targetEntity } = useDefinePQLStore();
  const { filters } = useFiltersPQLStore();

  const { data, isLoading, isError, isFetching } = useOpportunityQuery();
  const { data: userData } = useAuthUser(true);
  const dsEnabled = useDsEnabled(modelId, targetEntity);
  const { cohortItems, allUsersCohortItem } = useCohortTabs();
  // table helpers
  const [cellWidthMap, setCellWidthMap] = useReducer(updateCellWidthMap, {} as any);
  // sync scroll refs
  const headersRef = useSyncScroller('syncScrollSampleEntitiesDivs');
  const tableRef = useSyncScroller('syncScrollSampleEntitiesDivs');

  // gets the columns from the filters applied
  const getRestColumns = useCallback(() => {
    let restColumns: Column<any>[] = [];

    filters.map((filter) => {
      restColumns.push({
        Header: (
          <div className="font-regular text-tw-black-1">
            {filter.displayName || filter.columnName}
          </div>
        ),
        accessor: filter.columnName,
        Cell: (row) => {
          return <div className="py-4">{formatForCellDisplay(row?.value)}</div>;
        },
      });

      return filter;
    });

    return restColumns;
  }, [filters]);

  // gets columns from the user/account persona properties
  const getColumnsFromPersonaProps = useCallback(() => {
    let columns: Column<any>[] = [];

    if (targetEntity === QueryType.users) {
      userData?.currentOrganization?.userPersonaProperties.map((p: string) => {
        columns.push(
          getHeaderAndCellComponent(p, userData?.currentOrganization[UserFields.USER_ID])
        );
        return p;
      });
    } else {
      userData?.currentOrganization?.accountPersonaProperties.map((p: string) => {
        columns.push(
          getHeaderAndCellComponent(p, userData?.currentOrganization[AccountFields.ACCOUNT_ID])
        );
        return p;
      });
    }

    return columns.map((col) => {
      if (col.accessor === 'macro_persona') {
        return {
          Header: <div className="pl-1 font-regular text-tw-black-1">Toplyne Persona</div>,
          accessor: 'macro_persona',
          Cell: ({ cell }) => {
            return (
              <div className="flex items-center">
                <div>
                  {
                    getCohortIconAndColor(
                      cohortItems
                        ?.filter((c) => c.name !== allUsersCohortItem.name)
                        .find((c) => c.filters?.[0].value.includes(cell.value))?.name
                    ).icon
                  }
                </div>
                <div className="ml-1">{cell.value ?? 'N/A'}</div>
              </div>
            );
          },
        };
      }
      return col;
    });
  }, [targetEntity, userData?.currentOrganization]);

  // columns object
  const columns = useMemo<Column<any>[]>(() => {
    if (targetEntity === QueryType.accounts) {
      if (!dsEnabled) {
        return [...getColumnsFromPersonaProps(), ...getRestColumns()];
      }

      return [
        ...getColumnsFromPersonaProps(),
        {
          Header: <div className="font-regular text-tw-black-7">SCORE</div>,
          accessor: 'score',
          Cell: (row) => {
            return (
              <div className="py-4 text-tw-black-5">
                {row?.value?.toString().substr(0, 4) || '--'}
              </div>
            );
          },
        },
        ...getRestColumns(),
      ];
    }

    if (!dsEnabled) {
      return [...getColumnsFromPersonaProps(), ...getRestColumns()];
    }

    return [
      ...getColumnsFromPersonaProps(),
      {
        Header: <div className="font-regular text-tw-black-7">SCORE</div>,
        accessor: 'score',
        Cell: (row) => {
          return (
            <div className="text-tw-black-5">{row?.value?.toString().substr(0, 4) || '--'}</div>
          );
        },
      },
      ...getRestColumns(),
    ];
  }, [targetEntity, dsEnabled, getColumnsFromPersonaProps, getRestColumns]);
  const tableInstance = useTable(
    {
      columns,
      data: data?.leads?.slice(0, Math.min(qualifiedEntitiesCount, 10)) || [],
    },
    useColumnOrder,
    useSticky
  );
  const { setColumnOrder } = tableInstance;

  useEffect(() => {
    if (targetEntity === QueryType.accounts) {
      setColumnOrder([
        userData?.currentOrganization[AccountFields.ACCOUNT_ID],
        userData?.currentOrganization[AccountFields.ACCOUNT_CREATED_AT],
        userData?.currentOrganization[AccountFields.ACCOUNT_NAME],
      ]);
    } else {
      setColumnOrder([
        userData?.currentOrganization[UserFields.USER_ID],
        userData?.currentOrganization[UserFields.USER_CREATED_AT],
      ]);
    }
  }, [setColumnOrder, targetEntity, userData]);

  // get the cell width
  // and apply it to the headers
  useLayoutEffect(() => {
    if (!isLoading && !isFetching) {
      tableInstance?.columns?.map((column) => {
        const el = document.getElementsByClassName(`td-${column.id}`);
        setCellWidthMap({
          type: 'update',
          payload: {
            id: column.id,
            width: el?.[0]?.getClientRects()[0].width,
            height: el?.[0]?.getClientRects()[0].height,
          },
        });
      });
    }
  }, [isFetching, isLoading, tableInstance?.columns]);

  if (isLoading || isError || isFetching) {
    return (
      <div className="mt-6 animate-pulse">
        {Array(15)
          .fill(0)
          .map((_, i) => (
            <div key={i} className="h-8 my-4 bg-tw-gray-eb"></div>
          ))}
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center mt-6 mb-6 font-medium bg-tw-white-ff text-tw-black-5">
        <div>Sample {targetEntity.toLowerCase()}</div>
      </div>
      <div className="sticky top-0 z-5 bg-tw-white-ff">
        <div
          className={classNames('bg-tw-gray-f7 w-full mt-2 userlist-header overflow-hidden')}
          ref={headersRef}
        >
          {tableInstance?.headerGroups?.map((headerGroup, index) => {
            return (
              // use inline-flex to fix sticky scroll.
              <div key={index} className={classNames('inline-flex items-center grow-1')}>
                {headerGroup.headers.map((column) => {
                  return (
                    <div
                      key={column.id}
                      style={{
                        minWidth: cellWidthMap[column.id]?.width
                          ? cellWidthMap[column.id]?.width
                          : 250,
                        minHeight: cellWidthMap[column.id]?.height
                          ? cellWidthMap[column.id]?.height - 12
                          : 52,
                        maxHeight: cellWidthMap[column.id]?.height
                          ? cellWidthMap[column.id]?.height - 12
                          : 52,
                      }}
                      className={classNames(
                        'border-x-1 border-transparent pl-3 !text-md font-medium flex items-center justify-between pr-2 grow',
                        column.id === userData?.currentOrganization[UserFields.USER_ID] ||
                          column.id === userData?.currentOrganization[AccountFields.ACCOUNT_ID]
                          ? 'sticky z-[5] left-0 bg-tw-gray-f7 border-r-1 border-l-0 border-tw-gray-eb'
                          : 'z-0'
                      )}
                    >
                      {column.Header}
                      {/* we do not need columnActions because Sample Entities Table is readOnly for now */}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div>
        {/* container for overflow-scroll */}
        <div
          className="relative w-full overflow-x-scroll border-b-1 border-tw-gray-eb"
          ref={tableRef}
        >
          <UserAccountsTable
            tableInstance={tableInstance}
            columnHovered={''}
            entityType={targetEntity as QueryType}
            // pass keyNames as default columns
            defaultColumns={
              targetEntity === QueryType.users
                ? [userData?.currentOrganization[UserFields.USER_ID]]
                : [userData?.currentOrganization[AccountFields.ACCOUNT_ID]]
            }
          />
          <motion.div
            className={classNames(
              'max-w-full h-[43vh] relative',
              data?.count === 0 ? '' : 'hidden'
            )}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 1.2 } }}
          >
            {data?.count === 0 && (
              <div
                className="h-full overflow-scroll bg-tw-white-ff"
                style={{
                  width: 250 * tableInstance?.allColumns?.length,
                }}
              >
                <div className="fixed ml-[30%] overflow-y-hidden overflow-x-scroll my-24 text-tw-black-7">
                  <EmptyFolder className="w-20 h-20" />
                  <div>0 {targetEntity?.toLowerCase()} qualified</div>
                </div>
              </div>
            )}
          </motion.div>
        </div>
      </div>
    </div>
  );
}
