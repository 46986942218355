import { getSourceImg, getTableCell } from 'utils/common';
import * as React from 'react';
import Toggle from '../../inputs/Switch';

interface AttributeToggleRowProps {
  source?: string;
  displayName: string;
  keyName: string;
  value: string | number;
  show: boolean;
  onEnabled: (isChecked: boolean, keyName: string) => void;
}

const AttributeToggleRow: React.FC<AttributeToggleRowProps> = (props) => {
  const [isChecked, setIsChecked] = React.useState<boolean>(props.show);
  return (
    <>
      <div className={'flex flex-row items-center justify-between mb-4'}>
        <div className={'flex flex-row items-center'}>
          <img src={getSourceImg(props.source as any)} alt={props.source} className={'mr-2 w-6'} />
          <p className={'w-48 truncate max-w-3xl'}>{props.displayName}</p>
        </div>
        <p className={'w-48 truncate max-w-3xl mx-2'}>
          {getTableCell(props.value?.toString(), props.keyName)}
        </p>
        <div>
          <Toggle
            enabled={isChecked}
            setEnabled={() => {
              let currCheck = isChecked;
              setIsChecked(!isChecked);
              props.onEnabled(!currCheck, props.keyName);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AttributeToggleRow;
