import { toast as toastify } from 'react-toastify';
import { useClient, UserData } from 'api-client';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';
import { useAuth } from 'hooks/useAuth';
import { useMutation, useQueryClient } from 'react-query';

type SetDefaultAccountData = {
  organizationSchemaName: string;
  toastEnabled?: boolean;
};

export default function useSetDefaultAccount() {
  const client = useClient();
  const { user } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(
    (data: SetDefaultAccountData) =>
      client.editUserDetails(user?.id, {
        defaultOrganization: data.organizationSchemaName,
        email: user?.email,
      }),
    {
      onSuccess: async (resp, variables) => {
        await queryClient.cancelQueries('user');

        queryClient.setQueryData('user', (userData: UserData) => {
          return {
            ...userData,
            ...resp,
          };
        });

        if (variables.toastEnabled) {
          toastify(<SuccessToast description="Default account changed" />, {
            type: 'success',
          });
        }
      },
    }
  );
}
