import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import useDsEnabled from 'hooks/useDsEnabled';
import { CohortCountResponse } from 'pages/pql-workflow/hooks/useCohortCount';
import { CohortsResponse } from 'pages/pql-workflow/hooks/useCohorts';
import useCohortTabs from 'pages/pql-workflow/hooks/useCohortTabs';
import { useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { memo } from 'react';
import {
  AboutToSleepPersona,
  AllUsersPersona,
  CasualUsers,
  ChampionsPersona,
  HibernatingPersona,
  NeedsAttentionPersona,
  NewUsersPersona,
  PotentialPowerUsers,
  PowerUsers,
  PromisingUsersPersona,
} from 'ui-components/data-display/Icons';
import { CheckBoxCircle } from 'ui-components/inputs/CheckBoxCircle';
import { capitalizeFirstLetter, classNames } from 'utils/common';
import { RecommendedTag } from '../filters/RecommendedFilterCard';

type CohortPropsType = {
  cohortToDisplay: CohortsResponse;
  index: number;
  isRecommended: boolean;
  showCheckbox: boolean;
  setShowCheckbox: (showCheckbox: boolean) => void;
  counts: CohortCountResponse;
  loadingCounts: boolean;
};

export const getCohortIconAndColor = (
  cohortName: string,
  width: number = 32,
  height: number = 32
): { icon: ReactJSXElement; color: string } => {
  switch (cohortName) {
    case 'All Users':
      return { icon: <AllUsersPersona width={width} height={height} />, color: null };
    case 'Promising Users':
      return {
        icon: <PromisingUsersPersona width={width} height={height} />,
        color: 'text-tw-green-39',
      };
    case 'Needs attention':
      return {
        icon: <NeedsAttentionPersona width={width} height={height} />,
        color: 'text-tw-orange-ffa',
      };
    case 'New Users':
      return { icon: <NewUsersPersona width={width} height={height} />, color: 'text-tw-blue-40' };
    case 'Champion Users':
      return { icon: <ChampionsPersona width={width} height={height} />, color: 'text-tw-red-fa' };
    case 'About to sleep':
      return {
        icon: <AboutToSleepPersona width={width} height={height} />,
        color: 'text-tw-red-dd',
      };
    case 'Hibernating Users':
    case 'Dormant Users':
      return {
        icon: <HibernatingPersona width={width} height={height} />,
        color: 'text-tw-red-dd',
      };
    case 'Power Users':
      return { icon: <PowerUsers width={width} height={height} />, color: 'text-tw-green-39' };
    case 'Casual Users':
      return { icon: <CasualUsers width={width} height={height} />, color: 'text-tw-orange-ffa' };
    case 'Potential Power Users':
      return {
        icon: <PotentialPowerUsers width={width} height={height} />,
        color: 'text-tw-red-fa',
      };

    default:
      return { icon: null, color: null };
  }
};

const Cohort = (props: CohortPropsType) => {
  const { setCohorts, modelId, targetEntity, cohorts } = useDefinePQLStore();
  const { cohortItems, allUsersCohortItem } = useCohortTabs();

  const { cohortToDisplay, counts, loadingCounts, index: cohortIndex } = props;
  const dsEnabled = useDsEnabled(modelId, targetEntity);

  return (
    <div
      className={classNames(!props.isRecommended && 'mt-6', cohortIndex === 1 && 'product-tour-4')}
    >
      {props.isRecommended && <RecommendedTag text={'recommended'} className="w-28" />}
      <div
        onMouseEnter={() => {
          if (cohortToDisplay.id !== allUsersCohortItem?.id && !props.showCheckbox) {
            props.setShowCheckbox(true);
          }
        }}
        onMouseLeave={() => {
          if (cohortToDisplay.id !== allUsersCohortItem?.id && props.showCheckbox) {
            props.setShowCheckbox(false);
          }
        }}
        key={cohortToDisplay?.id}
        className={classNames(
          'py-4 px-4 rounded-lg cursor-pointer group relative h-44',
          cohorts?.includes(cohortToDisplay.id)
            ? 'bg-tw-blue-f2 border-1 border-tw-blue-0d'
            : 'border-1 border-tw-gray-eb',
          props.isRecommended && 'rounded-tl-none'
        )}
        onClick={() => {
          if (cohortToDisplay.id === allUsersCohortItem?.id) {
            setCohorts([allUsersCohortItem.id]);
          } else {
            if (
              [...cohorts, cohortToDisplay.id].length === cohortItems?.length - 1 &&
              !cohorts?.includes(cohortToDisplay?.id)
            ) {
              // This means all cohorts apart from all users have been selected
              setCohorts([allUsersCohortItem.id]);
            } else if (cohorts?.includes(cohortToDisplay.id)) {
              // eslint-disable-next-line no-unsafe-optional-chaining
              setCohorts([...cohorts?.filter((c) => c !== cohortToDisplay.id)]);
            } else {
              setCohorts(
                [...cohorts, cohortToDisplay.id].filter((c) => c !== allUsersCohortItem.id)
              );
            }
          }
        }}
      >
        {cohortToDisplay.id !== allUsersCohortItem?.id && (
          <CheckBoxCircle
            className={classNames(
              'absolute right-4',
              cohorts?.includes(allUsersCohortItem?.id) && !props.showCheckbox
                ? 'hidden group-hover:block'
                : ''
            )}
            checkboxClassname="w-5 h-5"
            checked={cohorts?.includes(cohortToDisplay.id)}
          />
        )}
        {getCohortIconAndColor(cohortToDisplay.name).icon}
        <div className="mt-2 font-medium">
          {cohortToDisplay?.name.replace('Users', capitalizeFirstLetter(targetEntity))}
        </div>
        <div className="mt-2 mb-10 text-xs text-tw-black-9">
          {cohortToDisplay?.description
            .split('.')
            .map((item) => item.trim())
            .map((item, index) => {
              return (
                <p
                  className={classNames(index > 0 && 'mt-0.5')}
                  key={`${cohortToDisplay?.id}_${index}`}
                >
                  {item}
                </p>
              );
            })}
        </div>
        {(!counts || loadingCounts) && (
          <div className="absolute flex items-center w-full text-xs animated-pulse bottom-4">
            <div className="w-5/6 h-6 rounded-sm bg-tw-gray-eb"></div>
          </div>
        )}
        {!!counts && (
          <div className="absolute flex items-center text-xs bottom-4">
            <div>🌎 {counts?.count?.toLocaleString('en')}</div>
            {dsEnabled && (
              <div className="mx-4">🔥 {counts?.hotLeadCount?.toLocaleString('en')}</div>
            )}
            {/* {dsEnabled && (
              <div>
                🎯{' '}
                {((counts?.convertedCount / counts?.count) * 100).toLocaleString('en').slice(0, 4)}%
              </div>
            )} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Cohort);
