import { classNames } from 'utils/common';
import { useAuthStore } from 'hooks/useAuthStore';
import useEntities from 'hooks/useEntities';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

const UserAccountsHeader: React.FC = () => {
  const { getAccountId } = useAuthStore();
  const router = useHistory();
  const { data: entities, isLoading } = useEntities();

  const tabs = React.useMemo(
    () =>
      entities
        ?.filter((e) => e.enabled)
        .map((e) => {
          return {
            name: e.name,
            path: `/${e.id}`,
          };
        }),
    [entities]
  );

  return (
    <div className="px-6 py-3 pt-8 bg-white shadow-sm ">
      {isLoading && (
        <div className="flex items-center justify-start mr-4 gap-x-2 animate-pulse">
          <div className="w-24 h-10 rounded bg-tw-gray-eb" />
          <div className="w-24 h-10 rounded bg-tw-gray-eb" />
        </div>
      )}
      {!isLoading && tabs && (
        <div className="flex items-center">
          {tabs?.map((t, index) => {
            return (
              <div
                onClick={() => {
                  router.push(`/${getAccountId()}${tabs[index].path}`);
                }}
                key={t.path}
                className={classNames(
                  'mr-4 py-2 cursor-pointer',
                  `product-tour-${t.name}`,
                  router.location.pathname.includes(t.path)
                    ? 'text-tw-blue-0d border-b-2 border-tw-blue-0d font-semibold text-base'
                    : 'text-tw-black-9 text-base'
                )}
              >
                {t.name}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default UserAccountsHeader;
