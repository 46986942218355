import { toast as toastify } from 'react-toastify';
import { Menu, Transition } from '@headlessui/react';
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';
import InitialsAvatar from 'ui-components/data-display/InitialsAvatar';
import { useAuth } from 'hooks/useAuth';
import { useAuthStore } from 'hooks/useAuthStore';
import useSetDefaultAccount from 'pages/login/hooks/useSetDefaultAccount';
import * as React from 'react';
import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { classNames } from '../../../utils/common';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';

interface NavDropdownProps {
  handleSignOut: () => void;
  alternate?: boolean;
}

export const DropdownMenuCheckboxItem = React.forwardRef(({ children, ...props }, forwardedRef) => {
  DropdownMenuCheckboxItem.displayName = 'DropdownMenuCheckboxItem';

  return (
    <DropdownPrimitive.CheckboxItem {...props} ref={forwardedRef as any}>
      {children}
      <DropdownPrimitive.ItemIndicator></DropdownPrimitive.ItemIndicator>
    </DropdownPrimitive.CheckboxItem>
  );
});

const DefaultAccountDropdown = ({ children }) => {
  const { user } = useAuth();
  const setDefaultAccountMutation = useSetDefaultAccount();

  const setDefaultAccount = (schemaName: string) => {
    setDefaultAccountMutation.mutate(
      { organizationSchemaName: schemaName, toastEnabled: true },
      {
        onSuccess: () => {
          toastify(<SuccessToast description="Default account has been changed" />, {
            type: 'success',
          });
        },
      }
    );
  };

  return (
    <DropdownPrimitive.Root>
      <DropdownPrimitive.Trigger className="w-full">{children}</DropdownPrimitive.Trigger>
      <DropdownPrimitive.Content
        className="w-48 px-3 py-5 my-3 bg-white rounded drop-shadow-lg"
        side="left"
        sideOffset={8}
        align={'start'}
      >
        <p className="font-medium text-tw-black-1">Default account</p>
        <DropdownPrimitive.RadioGroup className="mt-4">
          {user?.organizations
            .filter((o) => o.isActive && !o.isSandbox)
            .map((org) => (
              <div
                key={org.schemaName}
                className="flex items-center gap-x-1.5 mt-3"
                onClick={() => setDefaultAccount(org.schemaName)}
              >
                <input
                  className="w-4 h-4 bg-white rounded-full appearance-none cursor-pointer border-1 border-tw-black-9 ring-white ring-2 ring-inset checked:bg-tw-blue-0d checked:border-tw-blue-0d"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  checked={org.schemaName === user.defaultOrganization?.schemaName}
                />
                <label className="cursor-pointer">
                  {org?.isSandbox ? 'Test account' : org?.name}
                </label>
              </div>
            ))}
        </DropdownPrimitive.RadioGroup>
      </DropdownPrimitive.Content>
    </DropdownPrimitive.Root>
  );
};

const NavDropdown: React.FC<NavDropdownProps> = ({ handleSignOut }) => {
  const { user: data, isLoading, isError, changeOrg } = useAuth();
  const { getAccountId } = useAuthStore();

  const router = useHistory();

  const activeOrganizations = data?.organizations?.filter((org) => org.isActive && !org.isSandbox);

  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button key={data?.currentOrganization?.name} className="flex items-center gap-x-1">
          <div className="flex items-center justify-between px-2 bg-white rounded-lg min-w-27 max-w-56 gap-x-4 h-15 border-1 border-tw-gray-eb">
            {!isLoading ? (
              <div className="flex flex-col items-start gap-y-1">
                <div className="text-xs text-left truncate w-28 text-tw-black-7">{data?.email}</div>
                <div className="font-medium text-tw-black-1">
                  {data?.currentOrganization?.isSandbox
                    ? 'Test account'
                    : data?.currentOrganization?.name}
                </div>
              </div>
            ) : (
              <span className="w-20 h-6 bg-tw-gray-eb animate-pulse"></span>
            )}
            {!isLoading && !isError ? (
              <>
                {data?.avatarName && data?.avatarName !== 'initials' && (
                  <img
                    key="avatar"
                    src={`https://assets.toplyne.io/avatars/${data?.avatarName}.png`}
                    alt={data?.avatarName}
                    className="border-solid rounded-full h-11 w-11 border-1 border-tw-gray-e7"
                  />
                )}
                {(!data?.avatarName || data?.avatarName === 'initials') && (
                  <div className="h-11 w-11">
                    <InitialsAvatar fontClass="text-lg" user={data} />
                  </div>
                )}
              </>
            ) : (
              <span className="rounded-full w-11 h-11 bg-tw-gray-eb animate-pulse"></span>
            )}
          </div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-30 w-56 mt-4 origin-top-right bg-white divide-y divide-gray-100 rounded-md drop-shadow-lg ring-black focus:outline-none text-tw-black-3">
            <div className="py-3">
              <Menu.Item>
                <div
                  className="w-full px-3 py-2 text-sm rounded-md cursor-pointer group"
                  onClick={() => {
                    router.push(`/${getAccountId()}/settings/member-profile`);
                  }}
                >
                  {!isLoading && !isError && data.firstName && data.lastName ? (
                    <p className="font-medium text-tw-black-1">
                      {`${data.firstName} ${data.lastName}`}
                    </p>
                  ) : (
                    ''
                  )}
                  <p className="mt-1 text-xs text-tw-black-7">
                    {!isLoading && !isError && data.email}
                  </p>
                </div>
              </Menu.Item>
            </div>

            <div className="py-3">
              <Menu.Item>
                <div className="space-y-3 text-tw-black-1">
                  <div className="px-3 font-medium">
                    {data?.organizations.length > 1 ? 'Accounts' : 'Account'}
                  </div>
                  <div
                    className={classNames(
                      'cursor-pointer text-tw-black-3',
                      'border-l-4 border-tw-blue-0d text-tw-blue-0d px-2.5 font-medium'
                    )}
                    onClick={() =>
                      changeOrg(
                        data?.currentOrganization?.orgId,
                        data?.currentOrganization?.schemaName
                      )
                    }
                  >
                    <div className="flex items-center justify-between">
                      <span className="text-tw-blue-primary">
                        {data?.currentOrganization?.isSandbox
                          ? 'Test account'
                          : data?.currentOrganization?.name}
                      </span>
                      {data?.defaultOrganization?.schemaName ===
                        data?.currentOrganization?.schemaName && (
                        <span className="text-xs text-tw-black-9">Default</span>
                      )}
                    </div>
                  </div>
                  {activeOrganizations
                    ?.filter((o) => data?.currentOrganization?.schemaName !== o.schemaName)
                    .map((o, index) => (
                      <div
                        key={index}
                        className={classNames(
                          'cursor-pointer text-tw-black-3',
                          o.schemaName === data?.currentOrganization?.schemaName
                            ? 'border-l-4 border-tw-blue-0d text-tw-blue-0d px-2.5 font-medium'
                            : 'px-3'
                        )}
                        onClick={() => changeOrg(o.orgId, o.schemaName)}
                      >
                        <div className="flex items-center justify-between">
                          <span>{o.isSandbox ? 'Test account' : o.name}</span>
                          {data?.defaultOrganization?.schemaName === o.schemaName && (
                            <span className="text-xs text-tw-black-9">Default</span>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </Menu.Item>
            </div>

            <div className="py-3 text-tw-black-3">
              <Menu.Item>
                <>
                  {activeOrganizations?.length > 1 && (
                    <DefaultAccountDropdown>
                      <button
                        className={classNames(
                          'group flex rounded-md items-center w-full px-3 py-2 hover:bg-tw-gray-f9'
                        )}
                      >
                        Change default account
                      </button>
                    </DefaultAccountDropdown>
                  )}
                  <button
                    onClick={handleSignOut}
                    className={classNames(
                      'group flex rounded-md items-center w-full px-3 py-2 text-tw-red-dd hover:bg-tw-gray-f9'
                    )}
                  >
                    Sign out
                  </button>
                </>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default NavDropdown;
