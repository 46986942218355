import { classNames } from 'utils/common';
import { ToplyneLogoBlue } from '../../data-display/Icons';

export default function AlternateTopNav({ className }) {
  return (
    <div id="alternate-top-nav" className={classNames('flex items-start gap-x-1', className)}>
      <ToplyneLogoBlue className="w-8" />
      <h1 className="text-2xl font-medium select-none">Toplyne</h1>
    </div>
  );
}
