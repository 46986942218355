import { Filter } from 'stores/QueryStore';
import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { FiltersTabs } from '../hooks/useFilterTabs';

export enum FiltersPQLSections {
  FILTERS = 'filters',
  CAPACITY = 'capacity',
}

export enum LeadStatus {
  ALL = 'all',
  HOT = 'hot',
  WARM = 'warm',
  COLD = 'cold',
}

export const FilterSectionsMap: {
  [key in FiltersPQLSections]: boolean;
} = {
  [FiltersPQLSections.FILTERS]: false,
  [FiltersPQLSections.CAPACITY]: false,
  // cohort: false,
};

export enum ExportCategory {
  SALES = 'SALES',
  PERCENTAGE = 'PERCENTAGE',
}

export type ControlGroupState = {
  enabled: boolean;
  value: number;
  hasChanged: boolean;
};

type FiltersPQLState = {
  overviewOpen: boolean;
  filtersOpen: boolean;
  filtersActiveTab: FiltersTabs;
  searchQuery: string;
  filters: Array<Filter>;
  sectionsMap: { [key in FiltersPQLSections]: boolean };
  currentSection: FiltersPQLSections;
  exportCategory: ExportCategory;
  salesCapacity: number;
  salesCustomValue: number;
  percentage: number;
  filtersListOpen: Array<string>;
  controlGroup: ControlGroupState;
};

type FiltersPQLStore = {
  // which filters are open in the accordion
  setOverviewOpen: (open: boolean) => void;
  setFiltersOpen: (open: boolean) => void;
  setFiltersActiveTab: (tab: FiltersTabs) => void;
  setSearchQuery: (query: string) => void;
  setFilters: (filters: Array<Filter>) => void;
  setCurrentSection: (section: FiltersPQLSections) => void;
  setSectionsMap: (sectionsMap: { [key: string]: boolean }) => void;
  setExportCategory: (category: ExportCategory) => void;
  setSalesCapacity: (salesCapacity: number) => void;
  setSalesCustomValue: (salesCustomValue: number) => void;
  setPercentage: (percentage: number) => void;
  setFiltersListOpen: (filtersListOpen: Array<string>) => void;
  setControlGroup: (controlGroup: ControlGroupState) => void;
  reset: () => void;
  hydrate: (state: FiltersPQLState) => void;
} & FiltersPQLState;

const initialState: FiltersPQLState = {
  overviewOpen: true,
  filtersOpen: true,
  filtersActiveTab: null,
  searchQuery: '',
  filters: [],
  currentSection: FiltersPQLSections.FILTERS,
  sectionsMap: FilterSectionsMap,
  exportCategory: null,
  salesCapacity: null,
  salesCustomValue: null,
  percentage: null,
  filtersListOpen: [],
  controlGroup: {
    enabled: false,
    value: 0,
    hasChanged: false,
  },
};

export const useFiltersPQLStore = create<FiltersPQLStore>(
  devtools((set) => {
    return {
      ...initialState,
      setOverviewOpen: (open: boolean) => {
        set((state) => ({
          ...state,
          overviewOpen: open,
        }));
      },
      setFiltersOpen: (open: boolean) => {
        set((state) => ({
          ...state,
          filtersOpen: open,
        }));
      },
      setFiltersActiveTab: (tab: FiltersTabs) => {
        set((state) => ({
          ...state,
          filtersActiveTab: tab,
        }));
      },
      setSearchQuery: (query: string) => {
        set((state) => ({
          ...state,
          searchQuery: query,
        }));
      },
      setFilters: (filters: Array<Filter>) => {
        set((state) => ({
          ...state,
          filters,
        }));
      },
      setCurrentSection: (section: FiltersPQLSections) => {
        set((state) => ({
          ...state,
          currentSection: section,
        }));
      },
      setSectionsMap: (sectionsMap: { [key in FiltersPQLSections]: boolean }) => {
        set((state) => ({
          ...state,
          sectionsMap,
        }));
      },
      setExportCategory: (category: ExportCategory) => {
        set((state) => ({
          ...state,
          exportCategory: category,
        }));
      },
      setSalesCapacity: (salesCapacity: number) => {
        set((state) => ({
          ...state,
          salesCapacity,
        }));
      },
      setSalesCustomValue: (salesCustomValue: number) => {
        set((state) => ({
          ...state,
          salesCustomValue,
        }));
      },
      setPercentage: (percentage: number) => {
        set((state) => ({
          ...state,
          percentage,
        }));
      },
      setFiltersListOpen: (filtersListOpen: Array<string>) => {
        set((state) => ({
          ...state,
          filtersListOpen,
        }));
      },
      setControlGroup: (controlGroup: ControlGroupState) => {
        set((state) => ({
          ...state,
          controlGroup,
        }));
      },
      reset: () => {
        set(() => ({
          ...initialState,
        }));
      },
      hydrate: (state: FiltersPQLState) => {
        set(() => ({
          ...state,
        }));
      },
    };
  })
);
