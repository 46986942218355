import { ConnectedIntegration, Integration } from 'api-client';
import { TrayConfigStatus } from 'pages/integrations/TrayConfigField';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

export enum ConnectDestinationUITabs {
  apps = 'apps',
  webhooks = 'webhooks',
}

export enum ConnectDestinationUIState {
  integrationList = 'integration_list',
  addIntegration = 'add_integration',
  addWebhook = 'add_webhook',
}

type ConnectDestinationState = {
  destinationName: string;
  trayConfigStatus: TrayConfigStatus;
  trayAuthId: string;
  apiKey: string;
  apiDomain: string;
  code: string;
  selectedDestination: Integration;
  activeTab: ConnectDestinationUITabs;
  activeUIState: ConnectDestinationUIState;
  trayConnectedIntegration: ConnectedIntegration;
  hideButtons: boolean;
  webhookButtonDisabled: boolean;
};

type ConnectDestinationStore = {
  setDestinationName: (destinationName: string) => void;
  setTrayConfigStatus: (trayConfigStatus: TrayConfigStatus) => void;
  setTrayAuthId: (trayAuthId: string) => void;
  setApiKey: (apiKey: string) => void;
  setApiDomain: (apiDomain: string) => void;
  setCode: (code: string) => void;
  setSelectedDestination: (selectedDestination: Integration) => void;
  setActiveTab: (activeTab: ConnectDestinationUITabs) => void;
  setActiveUIState: (activeUIState: ConnectDestinationUIState) => void;
  setTrayConnectedIntegration: (trayConnectedIntegration: ConnectedIntegration) => void;
  setHideButtons: (hideButtons: boolean) => void;
  setWebhookButtonDisabled: (webhookButtonDisabled: boolean) => void;
  reset: () => void;
} & ConnectDestinationState;

const initialState: ConnectDestinationState = {
  destinationName: '',
  trayConfigStatus: null,
  trayAuthId: null,
  apiKey: '',
  apiDomain: '',
  code: '',
  selectedDestination: null,
  activeTab: ConnectDestinationUITabs.apps,
  activeUIState: ConnectDestinationUIState.integrationList,
  trayConnectedIntegration: null,
  hideButtons: false,
  webhookButtonDisabled: true,
};

export const useConnectDestinationStore = create<ConnectDestinationStore>(
  devtools((set) => {
    return {
      ...initialState,
      setDestinationName: (destinationName: string) => {
        set((state) => ({
          ...state,
          destinationName,
        }));
      },
      setTrayConfigStatus: (trayConfigStatus: TrayConfigStatus) => {
        set((state) => ({
          ...state,
          trayConfigStatus,
        }));
      },
      setTrayAuthId: (trayAuthId: string) => {
        set((state) => ({
          ...state,
          trayAuthId,
        }));
      },
      setApiKey: (apiKey: string) => {
        set((state) => ({
          ...state,
          apiKey,
        }));
      },
      setApiDomain: (apiDomain: string) => {
        set((state) => ({
          ...state,
          apiDomain,
        }));
      },
      setCode: (code: string) => {
        set((state) => ({
          ...state,
          code,
        }));
      },
      setSelectedDestination: (selectedDestination: Integration) => {
        set((state) => ({
          ...state,
          selectedDestination,
        }));
      },
      setActiveTab: (activeTab: ConnectDestinationUITabs) => {
        set((state) => ({
          ...state,
          activeTab,
        }));
      },
      setActiveUIState: (activeUIState: ConnectDestinationUIState) => {
        set((state) => ({
          ...state,
          activeUIState,
        }));
      },
      setTrayConnectedIntegration: (trayConnectedIntegration: ConnectedIntegration) => {
        set((state) => ({
          ...state,
          trayConnectedIntegration,
        }));
      },
      setHideButtons: (hideButtons: boolean) => {
        set((state) => ({
          ...state,
          hideButtons,
        }));
      },
      setWebhookButtonDisabled: (webhookButtonDisabled: boolean) => {
        set((state) => ({
          ...state,
          webhookButtonDisabled,
        }));
      },
      reset: () => {
        set(() => ({
          ...initialState,
        }));
      },
    };
  })
);
