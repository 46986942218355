import { Spinner } from '@chakra-ui/spinner';
import { ArrowBack } from 'ui-components/data-display/Icons';
import { ButtonHTMLAttributes, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { classNames } from '../../utils/common';

interface TertiaryButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
}

export function TertiaryButton({
  children,
  className,
  isLoading,
  disabled,
  ...rest
}: TertiaryButtonProps) {
  return (
    <button
      disabled={disabled}
      {...rest}
      className={classNames(
        'flex items-center justify-center px-4 py-2 text-base font-semibold rounded border-1 border-tw-black-9 text-tw-black-7',
        className,
        disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
      )}
    >
      {isLoading ? <Spinner size={'md'} /> : children}
    </button>
  );
}

interface RedirectBtnProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  seconds: number;
  redirectPath: string;
}

export const RedirectAfterNSeconds = (props: RedirectBtnProps) => {
  const { seconds, redirectPath } = props;
  const router = useHistory();
  const [count, setCount] = useState(seconds);

  useEffect(() => {
    let timerId = null;
    timerId = setTimeout(() => setCount(count - 1), 1000);

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [count]);

  useEffect(() => {
    if (count === 0) {
      router.push(redirectPath);
    }
  }, [count, redirectPath, router]);

  return (
    <PrimaryButton onClick={() => router.push('/')} className="mx-auto mt-8">
      Redirecting to Home ({count})
    </PrimaryButton>
  );
};

interface SecondaryButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  bgClassName?: string;
  borderClassName?: string;
  spinnerSize?: string;
  fontClassName?: string;
}

export function SecondaryButton({
  children,
  className,
  disabled,
  isLoading,
  bgClassName,
  borderClassName,
  fontClassName = 'font-semibold text-base',
  spinnerSize = 'md',
  ...rest
}: SecondaryButtonProps) {
  return (
    <button
      {...rest}
      className={classNames(
        'flex items-center justify-center py-2 font-semibold rounded border-1 px-tw-4 gap-x-2',
        className,
        bgClassName || `bg-tw-blue-f2`,
        borderClassName || `border-transparent`,
        disabled && 'cursor-not-allowed opacity-75',
        fontClassName
      )}
      disabled={disabled}
    >
      {isLoading ? <Spinner size={spinnerSize} /> : children}
    </button>
  );
}

SecondaryButton.defaultProps = {
  className: 'text-tw-blue-0d',
};

interface PrimaryButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  loaderClass?: string;
  spinnerSize?: string;
  fontClassName: string;
}

export function PrimaryButton({
  disabled,
  isLoading,
  children,
  className,
  loaderClass,
  spinnerSize,
  fontClassName,
  ...rest
}: PrimaryButtonProps) {
  return (
    <button
      {...rest}
      disabled={disabled || isLoading}
      className={classNames(
        'px-4 py-2 gap-x-2 border-1 border-transparent flex items-center justify-center text-white rounded bg-tw-blue-0d',
        disabled || isLoading ? 'opacity-50 cursor-not-allowed' : '',
        className,
        fontClassName
      )}
    >
      {isLoading ? <Spinner className={loaderClass} size={spinnerSize || 'md'} /> : children}
    </button>
  );
}

PrimaryButton.defaultProps = {
  fontClassName: 'text-base font-semibold',
};

export function BackButton() {
  const router = useHistory();
  return (
    <div
      className={'flex items-center text-base font-normal text-tw-black-5 gap-x-2 cursor-pointer'}
      onClick={() => {
        router.goBack();
      }}
    >
      <ArrowBack className="w-3" />
      <span>Back</span>
    </div>
  );
}
