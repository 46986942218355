import * as Dialog from '@radix-ui/react-dialog';
import { CrossIcon, RocketIcon } from 'ui-components/data-display/Icons';
import { PrimaryButton } from 'ui-components/inputs/Buttons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { AnimatePresence, motion } from 'framer-motion';
import { classNames } from 'utils/common';
import { useAuthStore } from 'hooks/useAuthStore';
import useAuthUser from 'hooks/useAuthUser';
import Lottie from 'lottie-react';
import { CadenceType, getCadenceType } from 'pages/opportunities/DisplayPQLCadence';
import useUpdatePQL from 'pages/opportunities/hooks/useUpdatePQL';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAnalytics } from 'telemetry';
import { AnalyticsConst, AnalyticsEvents } from 'telemetry/constants';
import animationData from '../../../assets/lotties/rocket.json';
import useGtmStrategies from '../hooks/useGtmStrategies';
import { GtmStrategy, useDefinePQLStore } from '../store/PQLDefineStore';
import { useExportPQLStore } from '../store/PQLExportStore';
import { usePQLWorkflowStore } from '../store/PQLWorkflowStore';
import { getCadenceString } from '../../../utils/helpers';

dayjs.extend(customParseFormat);

const getRecurringScheduleString = (recurringSchedule, nextRun, userTimezone) => {
  const startDateString = `${dayjs(nextRun).tz(userTimezone).format('DD MMM YYYY')}`;
  const startTimeString = `${dayjs(nextRun).tz(userTimezone).format('HH:mm A')}`;

  return getCadenceString(
    Object.keys(recurringSchedule?.frequency)[0],
    startTimeString,
    startDateString,
    recurringSchedule?.frequency?.weekly?.days ?? [],
    recurringSchedule?.frequency?.monthly?.days ?? []
  );
};

export default function CreatePQLModal() {
  const { track } = useAnalytics();
  const { getAccountId } = useAuthStore();
  const { createPQLModal, setCreatePQLModal } = usePQLWorkflowStore();
  const { schedule } = useExportPQLStore();
  const { targetEntity } = useDefinePQLStore();
  const router = useHistory();
  const { data: gtmStrategies } = useGtmStrategies();
  const [selectedGtmStrategy, setSelectedGtmStrategy] = useState('');
  const gtmRef = useRef(null);
  const updatePQLMutation = useUpdatePQL();

  const { data: userData } = useAuthUser(false);

  // if open and expanded false,
  // then set expanded to true
  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (createPQLModal.open && !createPQLModal.expanded) {
      timerId = setTimeout(() => {
        setCreatePQLModal({
          ...createPQLModal,
          expanded: true,
        });
      }, 500);
    }

    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [setCreatePQLModal, createPQLModal]);

  const handleGtmStrategyChange = (strategy: GtmStrategy) => {
    setSelectedGtmStrategy(strategy.value);
    if (gtmRef?.current !== undefined) {
      clearTimeout(gtmRef?.current);
    }
    gtmRef.current = setTimeout(() => {
      updatePQLMutation.mutate(
        {
          opportunityId: createPQLModal.opportunityId,
          gtmStrategy: {
            [strategy.value]: {
              label: strategy.label,
              value: strategy.value,
            },
          },
        },
        {
          onSuccess: () => {
            track(AnalyticsEvents.GTMSTRATEGY_SELECTED, {
              [AnalyticsConst.GTM_STRATEGY]: strategy.value,
            });
          },
        }
      );
    }, 200);
  };

  if (!createPQLModal.payload) return null;

  return (
    <Dialog.Dialog open={createPQLModal.open}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-40 justify-center bg-tw-black-50">
          <Dialog.DialogContent
            className={classNames(
              'px-4 py-4 rounded bg-transparent',
              `w-3/6`,
              'max-h-2/3 overflow-y-hidden',
              'mx-auto mt-32'
            )}
          >
            <Dialog.DialogTitle className="flex text-base font-medium">
              <Dialog.DialogClose asChild>
                <div className="w-full">
                  <div className="flex justify-end">
                    <div
                      className="flex justify-end w-10 h-10 p-2 rounded-full text-tw-black-5 bg-tw-white-ff"
                      onClick={() => {
                        setCreatePQLModal({
                          open: false,
                          expanded: false,
                          payload: null,
                          opportunityId: null,
                          nextRun: '',
                        });
                        router.push(`/${getAccountId()}/opportunities/list`);
                      }}
                    >
                      <CrossIcon className="w-4 h-6 mx-auto fill-current" />
                    </div>
                  </div>
                </div>
              </Dialog.DialogClose>
            </Dialog.DialogTitle>
            <div>
              <motion.div
                key="div-animation"
                className="mt-4 rounded-lg bg-tw-white-ff"
                initial={false}
                animate={
                  !createPQLModal.expanded
                    ? {
                        height: '350px',
                      }
                    : {
                        height: '75px',
                      }
                }
                transition={{ duration: 1.2, ease: 'easeInOut', delay: 2 }}
              >
                <AnimatePresence exitBeforeEnter>
                  {!createPQLModal.expanded ? (
                    <motion.div
                      key="expanded"
                      className="grid p-4 rounded-xl place-items-center"
                      initial={{ opacity: 1 }}
                      exit={{ opacity: 0, transition: { delay: 2, ease: 'easeOut' } }}
                      transition={{ ease: 'easeIn' }}
                    >
                      <Lottie
                        style={{
                          height: 200,
                          width: 200,
                        }}
                        animationData={animationData}
                        loop={true}
                        autoplay={true}
                      />
                      <div className="flex justify-center mt-8 text-xl font-medium">
                        3,2,1...Liftoff!
                      </div>
                      <div className="flex justify-center mt-3">
                        This playbook will run{' '}
                        {schedule?.type === 'onetime' && schedule?.oneTimeType === 'now' ? (
                          'within 2 mins'
                        ) : (
                          <>
                            {getCadenceType(createPQLModal.payload) === CadenceType.ONE_TIME
                              ? `on ${dayjs(createPQLModal?.payload?.time)
                                  .tz(userData?.currentOrganization?.timezone)
                                  .format('DD MMM YYYY, hh:mm A')}`
                              : getRecurringScheduleString(
                                  createPQLModal?.payload?.recurring,
                                  createPQLModal?.nextRun,
                                  userData?.currentOrganization?.timezone
                                )}
                          </>
                        )}
                      </div>
                    </motion.div>
                  ) : (
                    <motion.div
                      key="collapsed"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.2, ease: 'easeIn' }}
                      className="flex items-center px-8 py-4 rounded-xl"
                    >
                      <RocketIcon />
                      <div className="flex justify-center pr-12 ml-6 text-base font-thin">
                        This playbook will run{' '}
                        {schedule?.type === 'onetime' && schedule?.oneTimeType === 'now' ? (
                          'within 2 mins'
                        ) : (
                          <>
                            {getCadenceType(createPQLModal.payload) === CadenceType.ONE_TIME
                              ? `on ${dayjs(createPQLModal?.payload?.time)
                                  .tz(userData?.currentOrganization?.timezone)
                                  .format('DD MMM YYYY, hh:mm A')}`
                              : getRecurringScheduleString(
                                  createPQLModal?.payload?.recurring,
                                  createPQLModal?.nextRun,
                                  userData?.currentOrganization?.timezone
                                )}
                          </>
                        )}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
              {createPQLModal.expanded && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 3, ease: 'easeIn' }}
                  className="relative mt-2 rounded-lg bg-tw-white-ff"
                >
                  <div className="p-6">
                    <h3 className="text-base text-tw-black-1">
                      How would you reach out to these qualified {targetEntity.toLowerCase()}?
                    </h3>
                    <div className="flex items-center mt-4 gap-x-2">
                      {gtmStrategies?.strategies?.map((strategy) => {
                        return (
                          <div
                            key={strategy.value}
                            onClick={() =>
                              handleGtmStrategyChange({
                                label: strategy.label,
                                value: strategy.value,
                              })
                            }
                            className={classNames(
                              'text-xs border-tw-gray-c p-2 border-1 cursor-pointer rounded',
                              selectedGtmStrategy === strategy.value
                                ? 'bg-tw-blue-f2 border-tw-blue-0d'
                                : 'border-tw-gray-c '
                            )}
                          >
                            {strategy.label}
                          </div>
                        );
                      })}
                    </div>
                    <div className="px-2 py-4 mt-4 text-xs font-medium rounded-lg bg-tw-gray-f5 text-tw-black-7">
                      💡 This selection won’t impact your Playbook in any way
                    </div>
                  </div>
                  <div className="flex justify-center w-full px-6">
                    <PrimaryButton
                      className="flex items-center justify-center w-full mb-6 font-normal border-0 text-smbg-tw-gray-f5 text-tw-black-1"
                      onClick={() => router.push(`/${getAccountId()}/opportunities/list`)}
                    >
                      Go to Playbooks list
                    </PrimaryButton>
                  </div>
                </motion.div>
              )}
            </div>
            {/* Animation end */}
          </Dialog.DialogContent>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Dialog>
  );
}
