import { ReactComponent as IntegrationIconActive } from 'assets/svg/components/navbar/apps-active.svg';
import { ReactComponent as IntegrationsIconFilled } from 'assets/svg/components/navbar/apps-filled.svg';
import { ReactComponent as IntegrationsIconOutline } from 'assets/svg/components/navbar/apps-outline.svg';
import { ReactComponent as SwitchOrganizationFilled } from 'assets/svg/components/navbar/buildings-filled.svg';
import { ReactComponent as SwitchOrganizationOutline } from 'assets/svg/components/navbar/buildings-outline.svg';
import { ReactComponent as DocsFilled } from 'assets/svg/components/navbar/docs-filled.svg';
import { ReactComponent as DocsOutline } from 'assets/svg/components/navbar/docs-outline.svg';
import { ReactComponent as SettingsIconActive } from 'assets/svg/components/navbar/gears-active.svg';
import { ReactComponent as SettingsIconFilled } from 'assets/svg/components/navbar/gears-filled.svg';
import { ReactComponent as SettingsIconOutline } from 'assets/svg/components/navbar/gears-outline.svg';
import { ReactComponent as UsersIconActive } from 'assets/svg/components/navbar/users-active.svg';
import { ReactComponent as UsersIconFilled } from 'assets/svg/components/navbar/users-filled.svg';
import { ReactComponent as UsersIconOutline } from 'assets/svg/components/navbar/users-outline.svg';
import { ReactComponent as ZapIconActive } from 'assets/svg/components/navbar/zap-active.svg';
import { ReactComponent as ZapIconFilled } from 'assets/svg/components/navbar/zap-filled.svg';
import { ReactComponent as ZapIconOutline } from 'assets/svg/components/navbar/zap-outline.svg';
import { ReactComponent as SidebarIllustration } from 'assets/svg/components/sidebar-illustration.svg';
import {
  SideBorder,
  ToplyneBlueRound,
  ToplyneBlueRoundSmall,
} from 'ui-components/data-display/Icons';
import ToolTip from 'ui-components/data-display/Tooltip';
import { motion } from 'framer-motion';
import { classNames } from 'utils/common';
import { useAuth } from 'hooks/useAuth';
import { useAuthStore } from 'hooks/useAuthStore';
import useEntities from 'hooks/useEntities';
import useModels from 'hooks/useModels';
import * as React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { routes } from 'routing/clientRoutes';

interface SideNavBarProps {}

type SideNavItemProps = {
  to: string;
  label: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  HoverIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  ActiveIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  collapsed: boolean;
  isTenantRoute: boolean;
  className?: string;
};

function SideNavItem({
  to,
  label,
  Icon,
  HoverIcon,
  ActiveIcon,
  collapsed,
  isTenantRoute,
  className,
}: SideNavItemProps) {
  const {
    location: { pathname },
  } = useHistory();
  const { getAccountId } = useAuthStore();

  let isActive = pathname.includes(to);
  const [hover, setHover] = React.useState(false);

  // hack for the accounts page
  if (
    (pathname.includes(routes.accounts) && to.includes(routes.users)) ||
    (pathname.includes(routes.users) && to.includes(routes.accounts))
  ) {
    isActive = true;
  }

  if (pathname.includes(routes.opportunities) && to === routes.opportunitiesList) {
    isActive = true;
  }

  if (pathname.includes(routes.createOpportunity) && to.includes(routes.createOpportunity)) {
    isActive = true;
  }

  // hack for the edit profile page
  if (pathname.includes(routes.settings) && to.includes(routes.settings)) {
    isActive = true;
  }

  const IconToShow = isActive ? ActiveIcon : hover ? HoverIcon : Icon;

  return (
    <Link
      to={to.startsWith('/') ? (isTenantRoute ? `/${getAccountId()}${to}` : to) : { pathname: to }}
      target={!to.startsWith('/') && '_blank'}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={classNames(
          'flex items-center',
          isActive ? 'text-tw-blue-0d' : 'text-tw-black-7',
          collapsed ? 'w-full justify-between' : 'gap-x-3',
          className
        )}
      >
        <SideBorder
          className={classNames(
            isActive ? 'text-tw-blue-0d' : 'text-transparent',
            'fill-current h-10'
          )}
        />
        <div className="flex items-center gap-x-2">
          {collapsed && (
            <ToolTip placement="right" label={label}>
              <IconToShow className="w-8 h-8" />
            </ToolTip>
          )}
          {!collapsed && <IconToShow className="w-6 h-6" />}
          {!collapsed && <span className="font-medium">{label}</span>}
        </div>
        {collapsed && <SideBorder className="h-10 text-transparent fill-current" />}
      </div>
    </Link>
  );
}

SideNavItem.defaultProps = {
  isTenantRoute: true,
};

const SideNavBar: React.FC<SideNavBarProps> = () => {
  const { data: entities } = useEntities();
  const { pathname } = useLocation();
  const { user } = useAuth();
  // //fetch models
  useModels();

  const collapsed =
    pathname.includes(routes.opportunities) && !pathname.includes(routes.opportunitiesList);

  return (
    <motion.aside
      initial={{ width: '15%' }}
      animate={{ width: collapsed ? '5%' : '15%' }}
      className={classNames(
        'relative z-20 border-r border-tw-gray-eb h-screen bg-white flex flex-col items-center justify-between',
        collapsed ? 'w-[5%]' : 'w-[15%]'
      )}
    >
      <div className="w-full">
        <Link to="/">
          {collapsed ? (
            <ToplyneBlueRoundSmall className="mx-auto mt-6" />
          ) : (
            <ToplyneBlueRound className="mx-auto" />
          )}
        </Link>
        <div className={classNames('flex flex-col', collapsed ? 'gap-y-8 mt-28' : 'gap-y-4 mt-16')}>
          <SideNavItem
            to={routes.opportunitiesList}
            label={'Playbooks'}
            Icon={ZapIconOutline}
            HoverIcon={ZapIconFilled}
            ActiveIcon={ZapIconActive}
            collapsed={collapsed}
            className={'playbook-nav'}
          />
          <SideNavItem
            to={`/${entities?.filter((e) => e.enabled)[0]?.id}`}
            label={'Users & Accounts'}
            Icon={UsersIconOutline}
            HoverIcon={UsersIconFilled}
            ActiveIcon={UsersIconActive}
            collapsed={collapsed}
            className={'user-accounts-nav'}
          />
          <SideNavItem
            to={'/integrations'}
            label={'Integrations'}
            Icon={IntegrationsIconOutline}
            HoverIcon={IntegrationsIconFilled}
            ActiveIcon={IntegrationIconActive}
            collapsed={collapsed}
            className={'integrations-nav'}
          />
          <SideNavItem
            to={'/settings/member-profile'}
            label="Settings"
            Icon={SettingsIconOutline}
            HoverIcon={SettingsIconFilled}
            ActiveIcon={SettingsIconActive}
            collapsed={collapsed}
            className={'settings-nav'}
          />
        </div>
      </div>
      <div className={classNames('w-full flex flex-col pb-8', collapsed ? 'gap-y-4' : 'gap-y-2')}>
        {user?.isStaff && (
          <SideNavItem
            to={'/organizations/choose'}
            label="Change Org"
            Icon={SwitchOrganizationOutline}
            HoverIcon={SwitchOrganizationFilled}
            ActiveIcon={SwitchOrganizationFilled}
            collapsed={collapsed}
            isTenantRoute={false}
          />
        )}
        <SideNavItem
          to={'https://docs.toplyne.io'}
          label="Documentation"
          Icon={DocsOutline}
          HoverIcon={DocsFilled}
          collapsed={collapsed}
        />
      </div>
      {!collapsed && (
        <SidebarIllustration className="absolute w-full pointer-events-none bottom-20" />
      )}
    </motion.aside>
  );
};

export default SideNavBar;
