import { IntegrationType } from 'api-client';
import { ReactComponent as WarningIcon } from 'assets/images/warning-icon-grey.svg';
import Tooltip from 'ui-components/data-display/Tooltip';
import { classNames } from 'utils/common';
import IntegrationIcon from 'pages/integrations/sub-components/IntegrationIcon';
import { trim } from 'utils/helpers';
import { destinationColour } from './OpportunityItem';
import { Destination } from './types';
type DestinationTagProps = {
  destination: Destination | null;
};

export function DestinationTag(props: DestinationTagProps) {
  const { destination } = props;

  if (!destination) {
    return (
      <div className="flex items-center ml-2">
        <WarningIcon />
        <span className="ml-1 italic text-tw-black-5">Destination deleted </span>
      </div>
    );
  }

  const { name, type } = destination;

  return (
    <Tooltip
      label={name}
      className={name.length <= 24 ? 'hidden' : '-mt-2 text-white bg-tw-gray-7'}
    >
      <span
        className={classNames(
          destinationColour(type),
          'px-4 py-2 rounded-3xl inline-flex flex-wrap items-center'
        )}
      >
        <IntegrationIcon width={16} height={16} type={type as IntegrationType} />
        <div className="ml-1.5 text-xs">
          <div>{trim(name, 24)}</div>
        </div>
      </span>
    </Tooltip>
  );
}
