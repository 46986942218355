import { Integration, IntegrationType } from 'api-client';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

export enum ActivateSteps {
  avatar = 'avatar',
  organization = 'organization',
  timezone = 'timezone',
  sources = 'sources',
}

type AdditionalStepRequired = {
  value: boolean;
  type: IntegrationType;
};

export enum SourceConnectionSteps {
  select = 'select',
  connect = 'connect',
}

export type SegmentPlan = { label: string; value: string };

export const segmentPlans: SegmentPlan[] = [
  {
    label: 'Free plan',
    value: 'free',
  },
  {
    label: 'Team/Business plan',
    value: 'paid',
  },
];

type ActivateState = {
  organizationSchemaName: string;
  step: ActivateSteps;
  additionalStepRequired: AdditionalStepRequired;
  webhookUrl: string;
  segmentWorkspaceSources: any[];
  webhookUrlCopied: boolean;
  showCopyWarningModal: boolean;
  showSnowflakeSetupCompleteModal: boolean;
  buttonLoading: boolean;
  showDrawer: boolean;
  source: Integration;
  formValues: Record<string, string>;
  sourceConnectionStep: SourceConnectionSteps;
  showCalendly: boolean;
  currentSegmentPlan: string | undefined;
};

type ActivateStore = {
  setOrganizationSchemaName: (organizationSchemaName: string) => void;
  setStep: (step: ActivateSteps) => void;
  setAdditionalStepRequired: (additionalStepRequired: AdditionalStepRequired) => void;
  setWebhookUrl: (webhookUrl: string) => void;
  setSegmentWorkspaceSources: (segmentWorkspaceSources: any[]) => void;
  setWebhookUrlCopied: (webhookUrlCopied: boolean) => void;
  setShowCopyWarningModal: (showCopyWarningModal: boolean) => void;
  setShowSnowflakeSetupCompleteModal: (showSnowflakeSetupCompleteModal: boolean) => void;
  setButtonLoading: (buttonLoading: boolean) => void;
  setShowDrawer: (showDrawer: boolean) => void;
  setFormValues: (formValues: Record<string, string>) => void;
  setSource: (source: Integration) => void;
  reset: () => void;
  setSourceConnectionStep: (step: SourceConnectionSteps) => void;
  setShowCalendly: (showCalendly: boolean) => void;
  setCurrentSegmentPlan: (currentSegmentPlan: string) => void;
} & ActivateState;

const initialState: ActivateState = {
  organizationSchemaName: null,
  step: ActivateSteps.organization,
  additionalStepRequired: {
    value: false,
    type: null,
  },
  webhookUrl: '',
  segmentWorkspaceSources: [],
  webhookUrlCopied: false,
  showCopyWarningModal: false,
  showSnowflakeSetupCompleteModal: false,
  buttonLoading: false,
  showDrawer: false,
  source: null,
  formValues: {},
  sourceConnectionStep: SourceConnectionSteps.select,
  showCalendly: false,
  currentSegmentPlan: null,
};

export const useActivateStore = create<ActivateStore>(
  devtools((set) => {
    return {
      ...initialState,
      setOrganizationSchemaName: (organizationSchemaName: string) => {
        set((state) => ({
          ...state,
          organizationSchemaName,
        }));
      },
      setStep: (step: ActivateSteps) => {
        set((state) => ({
          ...state,
          step,
        }));
      },
      setAdditionalStepRequired: (additionalStepRequired: AdditionalStepRequired) => {
        set((state) => ({
          ...state,
          additionalStepRequired,
        }));
      },
      setWebhookUrl: (webhookUrl: string) => {
        set((state) => ({
          ...state,
          webhookUrl,
        }));
      },
      setSegmentWorkspaceSources: (segmentWorkspaceSources: string[]) => {
        set((state) => ({
          ...state,
          segmentWorkspaceSources,
        }));
      },
      setWebhookUrlCopied: (webhookUrlCopied: boolean) => {
        set((state) => ({
          ...state,
          webhookUrlCopied,
        }));
      },
      setShowCopyWarningModal: (showCopyWarningModal: boolean) => {
        set((state) => ({
          ...state,
          showCopyWarningModal,
        }));
      },
      setShowSnowflakeSetupCompleteModal: (showSnowflakeSetupCompleteModal: boolean) => {
        set((state) => ({
          ...state,
          showSnowflakeSetupCompleteModal,
        }));
      },
      setButtonLoading: (buttonLoading: boolean) => {
        set((state) => ({
          ...state,
          buttonLoading,
        }));
      },
      setShowDrawer: (showDrawer: boolean) => {
        set((state) => ({
          ...state,
          showDrawer,
        }));
      },
      setFormValues: (formValues: Record<string, string>) => {
        set((state) => ({
          ...state,
          formValues,
        }));
      },
      setSource: (source: Integration) => {
        set((state) => ({
          ...state,
          source,
        }));
      },
      reset: () => {
        set((state) => ({
          ...initialState,
          organizationSchemaName: state.organizationSchemaName,
          step: state.step,
          source: state.source,
        }));
      },
      setSourceConnectionStep: (step: SourceConnectionSteps) => {
        set((state) => ({
          ...state,
          sourceConnectionStep: step,
        }));
      },
      setShowCalendly: (showCalendly: boolean) => {
        set((state) => ({
          ...state,
          showCalendly,
        }));
      },
      setCurrentSegmentPlan: (plan: string) => {
        set((state) => ({
          ...state,
          currentSegmentPlan: plan,
        }));
      },
    };
  })
);
