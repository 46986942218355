import { PrimaryButton } from 'ui-components/inputs/Buttons';
import HoverPopover from 'ui-components/business/HoverPopover';
import Tooltip from 'ui-components/data-display/Tooltip';
import dayjs from 'dayjs';
import { ErrorBoundary } from 'react-error-boundary';
import { useAuth } from 'hooks/useAuth';
import { useAuthStore } from 'hooks/useAuthStore';
import useActivateOrganization from 'hooks/useActivateOrganization';
import Tab from 'pages/pql-workflow/components/Tab';
import PendingInvite from 'pages/settings/team-members/PendingInvite';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  AddUserIcon,
  DeleteBinIcon,
  FilledInfoIcon,
} from '../../../ui-components/data-display/Icons';
import { transformText } from '../../../utils/common';
import useRole from '../../../hooks/useRole';
import useJoinRequests from '../hooks/useJoinRequests';
import usePendingInvites from '../hooks/usePendingInvites';
import useAcceptedTeamMembersList from '../hooks/useTeamMembers';
import { Roles } from '../Roles';
import SettingsSideNav from '../SettingsSideNav';
import JoinRequests from './JoinRequests';
import { DeleteTeamMemberModal } from './sub-components/DeleteTeamMemberModal';
import { RoleComponent } from './sub-components/EditRoleComponent';
import MemberAccessTableModal from './sub-components/MemberAccessTableModal';
import { Table as MembersTable } from './sub-components/Table';
import { ErrorAnalyticsEvents } from 'telemetry/constants';
import ErrorBoundaryFallback from 'ui-components/feedback/ErrorBoundaryFallback';
import { useAnalytics } from 'telemetry';

interface TeamMembersProps {}

export enum TeamMembersTabs {
  members = 'members',
  pendingInvites = 'pendingInvites',
  requests = 'requests',
}

const TeamMembers: React.FC<TeamMembersProps> = () => {
  const { getAccountId } = useAuthStore();
  const [activeTab, setActiveTab] = React.useState<TeamMembersTabs>(TeamMembersTabs.members);

  const router = useHistory();
  const { data: acceptedTeamMembers, isLoading: isAcceptedTeamMembersLoading } =
    useAcceptedTeamMembersList();
  const { isReadOnly, isReadAndWrite, isAdmin } = useRole();
  const { user } = useAuth();
  const { organization } = useActivateOrganization();
  const { data: pendingInvites } = usePendingInvites();
  const { data: joinRequests } = useJoinRequests();

  const { track } = useAnalytics();

  const [showTeamMembersAccess, setShowTeamMembersAccess] = React.useState<boolean>(false);
  const [showDelTeamMember, setShowDelTeamMember] = React.useState<{
    id: string;
    email: string;
    show: boolean;
  }>({
    id: '',
    email: '',
    show: false,
  });

  const onCloseTeamMembersAccessModal = () => {
    setShowTeamMembersAccess(false);
  };

  const handleCloseDelModal = (showDelTeamMemberValue) => {
    setShowDelTeamMember({
      ...showDelTeamMember,
      show: showDelTeamMemberValue,
    });
  };

  const Columns = [
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ row }: { row: any }) => `${row.values['email']}`,
    },
    {
      Header: 'Member Since',
      accessor: (d) => `${d.createdAt}`,
      Cell: ({ row }: { row: any }) => {
        return dayjs(row.values['Member Since']).tz().format('DD MMM, YYYY');
      },
    },
    {
      Header: (
        <div className="flex place-items-center">
          Role
          <FilledInfoIcon
            className="ml-1 cursor-pointer fill-current text-tw-black-7"
            onClick={() => setShowTeamMembersAccess(true)}
          />
        </div>
      ),
      accessor: 'current_role',
      Cell: ({ row }: { row: any }) => allowRoleEditAccess(row),
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ row }: { row: any }) => {
        const { user: data } = useAuth();
        return row.original.email !== data?.email ? (
          <div className="pl-2">
            <DeleteBinIcon
              onClick={() => {
                setShowDelTeamMember({
                  id: row.original.id,
                  email: row.original.email,
                  show: true,
                });
              }}
              className="align-middle cursor-pointer fill-current text-tw-black-9"
            />
          </div>
        ) : (
          <>
            <Tooltip label="You cannot delete yourself" placement="left">
              <div className="pl-2">
                <DeleteBinIcon
                  className="inline-block h-full align-middle cursor-pointer fill-current text-tw-black-9"
                  onClick={() => {
                    setShowDelTeamMember({
                      id: row.original.id,
                      email: row.original.email,
                      show: false,
                    });
                  }}
                />
              </div>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const allowRoleEditAccess = (row) => {
    if (user?.email !== row.original['email']) {
      if (
        isAdmin ||
        (isReadAndWrite &&
          (row.original['currentRole']['role'] === Roles.READ_AND_WRITE ||
            row.original['currentRole']['role'] === Roles.READ_ONLY))
      ) {
        return (
          <RoleComponent
            email={row.original['email']}
            currentRole={row.original['currentRole']['role']}
            id={row.original['id']}
          />
        );
      }
    }
    return transformText(`${row.original['currentRole']['role']}`);
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={(e) =>
        track(ErrorAnalyticsEvents.ERROR_OCCURED, {
          error: e.message,
        })
      }
    >
      <div className="flex w-full mt-4">
        <SettingsSideNav />
        <div className="flex-grow px-4 py-4 mr-3 bg-white rounded min-h-86vh">
          <div className="flex items-center mb-6 gap-x-4">
            <Tab
              label="Team Members"
              isActive={activeTab === TeamMembersTabs.members}
              onClick={() => {
                setActiveTab(TeamMembersTabs.members);
              }}
            />
            <Tab
              label={`Pending invites (${pendingInvites?.length || 0})`}
              isActive={activeTab === TeamMembersTabs.pendingInvites}
              onClick={() => {
                setActiveTab(TeamMembersTabs.pendingInvites);
              }}
            />
            <Tab
              label={`Requested invites (${joinRequests?.length || 0})`}
              isActive={activeTab === TeamMembersTabs.requests}
              onClick={() => {
                setActiveTab(TeamMembersTabs.requests);
              }}
            />
          </div>
          {activeTab === TeamMembersTabs.pendingInvites && <PendingInvite />}
          {activeTab === TeamMembersTabs.requests && <JoinRequests setActiveTab={setActiveTab} />}
          {activeTab === TeamMembersTabs.members &&
            (isAcceptedTeamMembersLoading ? (
              <div className="mt-5 space-y-5 animate-pulse">
                <div className="w-24 h-2 bg-gray-100" />
                <div>
                  <div className="h-12 bg-gray-100" />
                  <div className="h-12 bg-white-100" />
                  <div className="h-12 bg-gray-100" />
                </div>
              </div>
            ) : (
              <>
                <div className={`flex items-center justify-between mb-4`}>
                  <h3 className="text-base font-medium text-tw-black-3">Members</h3>
                  <div className="my-3">
                    {!isReadOnly &&
                    user?.currentOrganization.isSandbox &&
                    !organization?.isActive ? (
                      <HoverPopover
                        side="bottom"
                        align="end"
                        popOverContent={
                          <div>
                            Please{' '}
                            <Link
                              className="font-medium text-tw-blue-0d hover:text-tw-blue-0d"
                              to={'/activate'}
                            >
                              Activate your account
                            </Link>{' '}
                            to invite team members
                          </div>
                        }
                      >
                        <PrimaryButton className="opacity-50">
                          <span className="mr-2">
                            <AddUserIcon className="w-4 h-auto fill-current text-tw-white-ff" />
                          </span>
                          Add team member
                        </PrimaryButton>
                      </HoverPopover>
                    ) : (
                      <PrimaryButton
                        onClick={() => router.push(`/${getAccountId()}/settings/member/add`)}
                      >
                        <span className="mr-2">
                          <AddUserIcon className="w-4 h-auto fill-current text-tw-white-ff" />
                        </span>
                        Add team member
                      </PrimaryButton>
                    )}
                  </div>
                </div>
                <MembersTable
                  data={acceptedTeamMembers}
                  columns={Columns.filter((item) => {
                    if (isReadOnly) {
                      return item.accessor !== 'action';
                    }
                    return true;
                  })}
                />
              </>
            ))}
          <MemberAccessTableModal
            showTeamMembersAccess={showTeamMembersAccess}
            setShowTeamMembersAccess={setShowTeamMembersAccess}
            onCloseTeamMembersAccessModal={onCloseTeamMembersAccessModal}
          />
          <DeleteTeamMemberModal showValue={showDelTeamMember} onCloseModal={handleCloseDelModal} />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default TeamMembers;
