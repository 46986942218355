import useModels from 'hooks/useModels';
import { goalsWordDictionary } from 'pages/opp-details/constants';
import useOpportunityQuery from 'pages/pql-workflow/hooks/useOpportunityQuery';
import { DefinePQLSections, useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { LeadStatus } from 'pages/pql-workflow/store/PQLFiltersStore';
import { PQLWorkflowSteps, usePQLWorkflowStore } from 'pages/pql-workflow/store/PQLWorkflowStore';
import { useState } from 'react';
import { PrimaryButton } from 'ui-components/inputs/Buttons';
import { CheckBoxCircle } from 'ui-components/inputs/CheckBoxCircle';
import { classNames } from 'utils/common';
import AccordionHeader from '../AccordionHeader';
import LeadStatusEmoji from '../filters/LeadStatusEmoji';
import SectionAccordion from '../SectionAccordion';

const leadOptions: Array<{ label: string; value: LeadStatus }> = [
  {
    label: 'All',
    value: LeadStatus.ALL,
  },
  {
    label: 'Hot',
    value: LeadStatus.HOT,
  },
  {
    label: 'Warm',
    value: LeadStatus.WARM,
  },
  {
    label: 'Cold',
    value: LeadStatus.COLD,
  },
];

function LeadsCard({ option }) {
  const { targetEntity, modelId, leadStatus, setLeadStatus } = useDefinePQLStore();
  const isActive = leadStatus.includes(option.value);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const { data: opportunityQuery, isLoading: loadingOpportunityQuery } = useOpportunityQuery();
  const { data: models } = useModels();
  const goal = models?.find((m) => m.modelId === modelId)?.goal;

  return (
    <div
      className={classNames(
        'p-4 border rounded-lg cursor-pointer relative',
        isActive ? 'border-tw-blue-0d bg-tw-blue-f2' : 'border-tw-gray-eb',
        option.value === LeadStatus.HOT ? 'product-tour-hwc' : ''
      )}
      onMouseOver={() => {
        if (option.value !== LeadStatus.ALL) setShowCheckbox(true);
      }}
      onMouseLeave={() => {
        if (option.value !== LeadStatus.ALL) setShowCheckbox(false);
      }}
      onClick={() => {
        if (option.value === LeadStatus.ALL) {
          setLeadStatus([LeadStatus.ALL]);
        } else {
          if (
            [LeadStatus.HOT, LeadStatus.WARM, LeadStatus.COLD].every((status) =>
              [...leadStatus, option.value].includes(status)
            )
          ) {
            setLeadStatus([LeadStatus.ALL]);
          } else {
            if (!leadStatus.includes(option.value)) {
              setLeadStatus([...leadStatus, option.value].filter((l) => l !== LeadStatus.ALL));
            } else if (leadStatus.length > 1) {
              setLeadStatus(leadStatus.filter((l) => l !== option.value));
            }
          }
        }
      }}
    >
      {option.value !== LeadStatus.ALL && (
        <CheckBoxCircle
          className={classNames(
            'absolute right-4',
            leadStatus?.includes(LeadStatus.ALL) && !showCheckbox ? 'hidden group-hover:block' : ''
          )}
          checkboxClassname="w-5 h-5"
          checked={leadStatus?.includes(option.value)}
        />
      )}
      <div className="flex flex-col">
        <LeadStatusEmoji value={option.value} />
        <h3 className="mt-4 font-medium text-tw-black-1">
          {option.label} {targetEntity.toLowerCase()}
        </h3>
        {opportunityQuery?.goalMetRate && (
          <p className="mt-2 text-xs text-tw-black-7">
            Expected {(opportunityQuery?.goalMetRate?.[option.value] * 100).toFixed(2)}%{' '}
            {goalsWordDictionary[goal.toUpperCase()]['noun']} rate
          </p>
        )}
        {loadingOpportunityQuery ? (
          <div className="bg-tw-gray-eb animate-pulse w-36 h-4 mt-9"></div>
        ) : (
          <p className="text-xs text-tw-black-7 mt-9">
            🌍{' '}
            {option.value === LeadStatus.ALL
              ? opportunityQuery?.count.toLocaleString('en-US')
              : opportunityQuery?.leadDistribution[option.value].toLocaleString('en-US')}{' '}
            {targetEntity.toLowerCase()}
          </p>
        )}
      </div>
    </div>
  );
}

export default function SelectHWC() {
  const { sectionsMap, setSectionsMap, currentSection, leadStatus } = useDefinePQLStore();
  const { setCurrentStep } = usePQLWorkflowStore();

  // doesn't show the section if the previous section is not finished
  if (!sectionsMap[DefinePQLSections.GOALS] || currentSection === DefinePQLSections.GOALS) {
    return null;
  }

  return (
    <div className="product-tour-leads">
      <hr className="w-full" />
      <SectionAccordion
        id={DefinePQLSections.HWC}
        hasPadding={true}
        accordionHeader={
          <AccordionHeader
            onClick={() => {
              // if not on current section, mark it as current,
              // then mark all other next steps to not finished
              let newSectionsMap = {};
              let refIndex = Object.keys(sectionsMap).indexOf(DefinePQLSections.HWC);
              (Object.keys(DefinePQLSections) as Array<keyof typeof DefinePQLSections>).forEach(
                (key, index) => {
                  if (index >= refIndex) {
                    newSectionsMap[key.toLowerCase()] = false;
                  }
                }
              );
              setSectionsMap({
                ...sectionsMap,
                ...newSectionsMap,
              });
            }}
          >
            Goal for this Playbook
          </AccordionHeader>
        }
        expanded={currentSection === DefinePQLSections.HWC}
      >
        <div className="font-medium rounded-b-xl text-tw-black-1">
          Select the leads you want to target
        </div>
        <div className="grid grid-cols-3 gap-4 mt-6">
          {leadOptions.map((option) => (
            <LeadsCard key={option.value} option={option} />
          ))}
        </div>
        <div className="mt-6">
          <PrimaryButton
            className="w-full product-tour-hwc-proceed"
            onClick={() => {
              const newSectionsMap = {
                ...sectionsMap,
                // mark entities as finished
                [DefinePQLSections.HWC]: true,
              };
              setSectionsMap(newSectionsMap);
              setCurrentStep(PQLWorkflowSteps.FILTERS);
            }}
            disabled={!leadStatus.length}
          >
            Proceed
          </PrimaryButton>
        </div>
      </SectionAccordion>
    </div>
  );
}
