import { QueryGoal } from 'stores/QueryStore';

export type WordDictionary = {
  noun: string;
  verb: string;
  verbPast: string;
};

type Query = `${QueryGoal}`;

export type GoalsWordDictionary = {
  [index in Query]: WordDictionary;
};

export const goalsWordDictionary: GoalsWordDictionary = {
  EXPANSION: { noun: 'expansion', verb: 'expand', verbPast: 'expanded' },
  CONVERSION: { noun: 'conversion', verb: 'convert', verbPast: 'converted' },
  ENGAGEMENT: { noun: 'engagement', verb: 'engage', verbPast: 'engaged' },
  CHURN: { noun: 'churn prevention', verb: 'churn', verbPast: 'did not churn' },
  CHURN_PREVENTION: { noun: 'churn prevention', verb: 'churn', verbPast: 'did not churn' },
};
