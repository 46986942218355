import { toast as toastify } from 'react-toastify';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { keyframes, styled } from '@stitches/react';
import DateJSUtils from '@date-io/dayjs';
import { CheckIconBasic, DropdownArrowIcon, InfoAlertIcon } from 'ui-components/data-display/Icons';
import Modal from 'ui-components/util/modal';
import { PrimaryButton } from 'ui-components/inputs/Buttons';
import { PrimaryDropdown } from 'ui-components/navigation/Dropdown';
import { TextInput } from 'ui-components/inputs/Inputs';
import dayjs from 'dayjs';
import { capitalizeFirstLetter, classNames } from 'utils/common';
import useConnectedIntegrations from 'hooks/useConnectedIntegrations';
import { CadenceType, getCadenceType } from 'pages/opportunities/DisplayPQLCadence';
import { usePQLWorkflowRoute } from 'pages/pql-workflow';
import useCreatePQL from 'pages/pql-workflow/hooks/useCreatePQL';
import useOpportunity from 'pages/pql-workflow/hooks/useOpportunity';
import {
  Cadence,
  CadenceFrequency,
  ExportPQLSections,
  OneTimeType,
  Schedule,
  ScheduleType,
  useExportPQLStore,
} from 'pages/pql-workflow/store/PQLExportStore';
import { PQLWorkflowSteps, usePQLWorkflowStore } from 'pages/pql-workflow/store/PQLWorkflowStore';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Block from '../Block';
import Card from '../../../../ui-components/business/Card';
import SectionAccordion from '../SectionAccordion';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';
import { getCadenceString } from '../../../../utils/helpers';
import { useAuth } from '../../../../hooks/useAuth';

const daysInWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const daysInMonth = Array(31)
  .fill(0)
  .map((_, i) => i + 1)
  .map((num) => num.toString());

const getNumberWithOrdinal = (number) => {
  const suffix = ['th', 'st', 'nd', 'rd'];
  if (number === -1) {
    return 'Last day';
  }
  if (!isNaN(number)) {
    const suffixMod = number % 100;
    return number + (suffix[(suffixMod - 20) % 10] || suffix[suffixMod] || suffix[0]);
  }
};

const getSelectedValues = (cadence: Cadence) => {
  if (cadence?.type === CadenceFrequency.MONTHLY) {
    if (cadence.monthlyValues.length < 1) {
      return null;
    }
    const ordinalValues = cadence?.monthlyValues
      .sort()
      .map((num) => getNumberWithOrdinal(parseInt(num)));
    return `Monthly (${ordinalValues})`;
  } else if (cadence?.type === CadenceFrequency.DAILY) {
    return 'Daily';
  } else if (cadence?.type === CadenceFrequency.WEEKLY) {
    if (cadence.weeklyValues.length < 1) {
      return null;
    }
    return `Weekly (${cadence?.weeklyValues})`;
  } else {
    return 'Hourly';
  }
};

const options = [
  {
    label: 'Hourly',
    value: CadenceFrequency.HOURLY,
  },
  {
    label: 'Daily',
    value: CadenceFrequency.DAILY,
  },
  {
    label: 'Weekly',
    value: CadenceFrequency.WEEKLY,
  },
  {
    label: 'Monthly',
    value: CadenceFrequency.MONTHLY,
  },
];

// animation for the accordion
const open = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});
const close = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});
const AccordionContent = styled(AccordionPrimitive.Content, {
  overflow: 'hidden',
  '&[data-state="open"]': { animation: `${open} 300ms ease-out forwards` },
  '&[data-state="closed"]': { animation: `${close} 300ms ease-in forwards` },
  length: 0,
});

const getRecurringScheduleString = (schedule) => {
  const startDateString = `${dayjs.tz(schedule.startDate).format('DD MMM YYYY')}`;
  const startTimeString = `${dayjs.tz(schedule.time).format('HH:mm A')}`;
  const { cadence } = schedule;

  return getCadenceString(
    cadence.type,
    startTimeString,
    startDateString,
    cadence?.weeklyValues ?? [],
    cadence?.monthlyValues ?? []
  );
};

function getScheduleText(schedule: Schedule, timezone) {
  if (schedule.type === ScheduleType.RECURRING) {
    if (schedule.time && schedule.startDate) {
      return `This playbook will run ${getRecurringScheduleString(schedule)}`;
    }
  } else if (schedule.type === ScheduleType.ONETIME) {
    if (schedule.oneTimeType === OneTimeType.TIME) {
      if (schedule.time && schedule.startDate) {
        return `This playbook will run on ${dayjs(schedule.time)
          .tz(timezone)
          .format('hh:mm')}, ${dayjs(schedule.startDate).tz(timezone).format('DD/MM/YY')}`;
      } else {
        return '';
      }
    } else {
      return `This playbook will run on ${dayjs()
        .tz(timezone)
        .add(2, 'minute')
        .format('hh:mm')}, ${dayjs().tz(timezone).format('DD/MM/YY')}`;
    }
  }
}

function PreviousPQLSchedule() {
  const { opportunityId, isDuplicate } = usePQLWorkflowRoute();
  const { data: opportunity } = useOpportunity(opportunityId, isDuplicate);
  const scheduleDuplicate = opportunity?.exports[0].schedule;

  const cadenceType =
    getCadenceType(scheduleDuplicate) === CadenceType.RECURRING
      ? (Object.keys(scheduleDuplicate[ScheduleType.RECURRING].frequency)[0] as CadenceFrequency)
      : scheduleDuplicate.time;

  return (
    <AccordionPrimitive.Root type="single" collapsible={true}>
      <AccordionPrimitive.Item
        value="schedule-past"
        className="p-6 mt-4 rounded-xl bg-tw-gray-f5 border-tw-gray-eb border-1"
      >
        <AccordionPrimitive.Header>
          <AccordionPrimitive.Trigger className="flex items-center justify-between w-full">
            <div className="flex items-center font-medium text-tw-black-5 gap-x-2">
              <InfoAlertIcon className="w-4 fill-current" />
              <span>See original PQL schedule details</span>
            </div>
            <DropdownArrowIcon className="fill-current text-tw-black-7" />
          </AccordionPrimitive.Trigger>
        </AccordionPrimitive.Header>
        <AccordionContent className="rounded-lg text-tw-black-7">
          <div className="px-4 py-4 mt-6 bg-white rounded-lg">
            <ul className="grid grid-cols-2 p-4 list-disc">
              <li>
                <span className="font-medium text-tw-black-3">Type: </span>
                {scheduleDuplicate[ScheduleType.RECURRING] ? 'Recurring Sync' : 'One time sync'}
              </li>
              {scheduleDuplicate[ScheduleType.RECURRING] && (
                <li>
                  <span className="font-medium text-tw-black-3">Cadence: </span>
                  {getSelectedValues({
                    type: cadenceType as CadenceFrequency,
                    weeklyValues:
                      cadenceType === CadenceFrequency.WEEKLY
                        ? scheduleDuplicate[ScheduleType.RECURRING].frequency[cadenceType].days.map(
                            (d) => capitalizeFirstLetter(d)
                          )
                        : [],
                    monthlyValues:
                      cadenceType === CadenceFrequency.MONTHLY
                        ? scheduleDuplicate[ScheduleType.RECURRING].frequency[cadenceType].days
                        : [],
                    hourlyValue:
                      cadenceType === CadenceFrequency.HOURLY
                        ? scheduleDuplicate[ScheduleType.RECURRING].frequency[cadenceType].days
                        : [],
                  })}
                </li>
              )}
            </ul>
            <hr className="border-t border-dashed border-tw-gray-c" />
            <ul className="grid grid-cols-2 p-4 list-disc">
              <li>
                <span className="font-medium text-tw-black-3">
                  {scheduleDuplicate[ScheduleType.RECURRING] ? 'Start date: ' : 'Date: '}
                </span>
                {scheduleDuplicate[ScheduleType.RECURRING]
                  ? dayjs(scheduleDuplicate[ScheduleType.RECURRING].start).format('DD/MM/YYYY')
                  : dayjs(scheduleDuplicate.time).format('DD/MM/YYYY')}
              </li>
              <li>
                <span className="font-medium text-tw-black-3">
                  {scheduleDuplicate[ScheduleType.RECURRING] ? 'Start time: ' : 'Time: '}
                </span>
                {scheduleDuplicate[ScheduleType.RECURRING]
                  ? scheduleDuplicate[ScheduleType.RECURRING].frequency[
                      Object.keys(scheduleDuplicate[ScheduleType.RECURRING].frequency)[0]
                    ].time
                      .split(':')
                      .slice(0, 2)
                      .join(':')
                  : dayjs(scheduleDuplicate.time).format('HH:mm')}
              </li>
            </ul>
          </div>
        </AccordionContent>
      </AccordionPrimitive.Item>
    </AccordionPrimitive.Root>
  );
}

function AccordionHeader() {
  const { sectionsMap, setSectionsMap } = useExportPQLStore();
  return (
    <div
      className="flex items-center"
      onClick={() => {
        // if not on current section, mark it as current,
        // then mark all other next steps to not finished
        let newSectionsMap = {};
        let refIndex = Object.keys(sectionsMap).indexOf(ExportPQLSections.SCHEDULE);
        (Object.keys(ExportPQLSections) as Array<keyof typeof ExportPQLSections>).forEach(
          (key, index) => {
            if (index >= refIndex) {
              newSectionsMap[key.toLowerCase()] = false;
            }
          }
        );
        setSectionsMap({
          ...sectionsMap,
          ...newSectionsMap,
        });
      }}
    >
      <CheckIconBasic className="w-4 fill-current text-tw-green-39" />
      <div className="ml-2.5 text-md flex items-center text-tw-black-5 mt-0.5"></div>
    </div>
  );
}

export default function ScheduleSection() {
  const { currentSection, schedule, setSchedule, name, setName } = useExportPQLStore();
  const [scheduleSectionOpen, setScheduleSectionOpen] = useState(false);
  const createPQLMutation = useCreatePQL();
  useConnectedIntegrations(false);
  const { isDuplicate } = usePQLWorkflowRoute();
  const { user } = useAuth();

  const { completedSteps, setCompletedSteps } = usePQLWorkflowStore();
  const {
    register,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  if (
    currentSection === ExportPQLSections.DESTINATION ||
    currentSection === ExportPQLSections.MAPPINGS
  ) {
    return null;
  }

  const onOptionChange = (selected) => {
    if (selected === 'monthly' || selected === 'weekly') setScheduleSectionOpen(true);
  };

  const handleCloseModal = () => {
    setScheduleSectionOpen(false);
  };

  const handleSubmit = () => {
    if (schedule.oneTimeType !== OneTimeType.NOW && schedule.time < dayjs(new Date()).tz()) {
      toastify(<ErrorToast description="This seems to be a time in the past 🤔" />, {
        type: 'error',
      });
    } else {
      // set completed to true, open the
      // create PQL modal.
      setCompletedSteps({
        ...completedSteps,
        [PQLWorkflowSteps.EXPORT]: true,
      });
      createPQLMutation.mutate();
    }
  };

  const areTimeFieldsPresent =
    schedule.type === ScheduleType.RECURRING ||
    (schedule.type === ScheduleType.ONETIME && schedule.oneTimeType === OneTimeType.TIME);

  const disablePlaybookCreationButton = !(
    name &&
    !errors.playbookName &&
    schedule.type &&
    (areTimeFieldsPresent ? schedule.startDate && schedule.time : true)
  );

  return (
    <MuiPickersUtilsProvider utils={DateJSUtils}>
      <div>
        <hr />
        <SectionAccordion
          id={ExportPQLSections.SCHEDULE}
          hasPadding={true}
          accordionHeader={<AccordionHeader />}
          expanded={currentSection === ExportPQLSections.SCHEDULE}
        >
          {isDuplicate && <PreviousPQLSchedule />}
          <h5>Name this Playbook</h5>

          <TextInput
            {...register('playbookName', {
              maxLength: {
                value: 128,
                message: 'Playbook name cannot be greater than 128 characters.',
              },
              onChange: (e) => setName(e.target.value),
            })}
            value={name}
            className="py-3 mt-3 w-80 product-tour-22"
            isError={!!errors.playbookName}
          />
          <div>
            <p className={`text-xs ${errors.playbookName?.message ? `text-tw-red-dd` : ``}`}>
              {errors.playbookName?.message ? errors.playbookName?.message : ''}
            </p>
          </div>
          <h5 className="mt-6">Schedule your sync</h5>
          <div className="flex mt-3 gap-x-4">
            <Card
              cardClassname="mr-0"
              isActive={schedule.type === ScheduleType.RECURRING}
              onClick={() => setSchedule({ type: ScheduleType.RECURRING })}
            >
              Recurring sync
            </Card>

            <div className="product-tour-23">
              <Card
                isActive={schedule.type === ScheduleType.ONETIME}
                onClick={() =>
                  setSchedule({
                    type: ScheduleType.ONETIME,
                  })
                }
              >
                One-time sync
              </Card>
            </div>
          </div>
          {schedule.type === ScheduleType.RECURRING && (
            <>
              <h5 className="mt-6">Start date</h5>
              <DatePicker
                disablePast={true}
                initialFocusedDate={dayjs().tz()}
                value={schedule.startDate}
                onChange={(value) => {
                  setSchedule({ startDate: value, time: value });
                }}
                placeholder="DD/MM/YYYY"
                format="DD/MM/YYYY"
                inputVariant="outlined"
                TextFieldComponent={({ onChange, value, onClick }) => (
                  <TextInput
                    placeholder="DD/MM/YYYY"
                    className="py-3 mt-3"
                    value={value as any}
                    onChange={onChange}
                    onClick={onClick}
                  />
                )}
              />
              <h5 className="mt-6">Cadence</h5>
              <div className="flex items-center mt-3 gap-x-4">
                <PrimaryDropdown
                  className="py-3"
                  align="start"
                  value={schedule.cadence.type}
                  onChange={(selected: CadenceFrequency) => {
                    setSchedule({ cadence: { ...schedule.cadence, type: selected } });
                    onOptionChange(selected);
                  }}
                  options={options}
                  displayValue={
                    <span className={classNames('w-full truncate text-left')}>
                      {getSelectedValues(schedule.cadence)}
                    </span>
                  }
                />
                {schedule.cadence.type === CadenceFrequency.HOURLY && (
                  <div className="flex items-center gap-x-4">
                    <span>Once every</span>
                    <PrimaryDropdown
                      className="py-3"
                      value={schedule.cadence.hourlyValue[0]}
                      onChange={(selected: string) =>
                        setSchedule({ cadence: { ...schedule.cadence, hourlyValue: [selected] } })
                      }
                      options={[1, 2, 3, 4, 6, 8, 12]
                        .map((x) => x.toString())
                        .map((num) => ({ label: num, value: num }))}
                    />
                    <span>hours</span>
                  </div>
                )}
              </div>
              <h5 className="mt-6">Start time</h5>
              <TimePicker
                initialFocusedDate={dayjs().tz()}
                value={schedule.time}
                onChange={(value) => {
                  setSchedule({ time: value });
                }}
                inputVariant="outlined"
                TextFieldComponent={({ onChange, value, onClick }) => (
                  <TextInput
                    placeholder="hh:mm"
                    className="py-3 mt-3"
                    value={value as any}
                    onChange={onChange}
                    onClick={onClick}
                  />
                )}
              />
            </>
          )}
          {schedule.type === ScheduleType.ONETIME && (
            <>
              <h5 className="mt-6">When to sync?</h5>
              <div className="flex items-center mt-3">
                <Card
                  isActive={schedule.oneTimeType === OneTimeType.NOW}
                  onClick={() => setSchedule({ oneTimeType: OneTimeType.NOW })}
                >
                  Sync right now
                </Card>
                <Card
                  isActive={schedule.oneTimeType === OneTimeType.TIME}
                  onClick={() => setSchedule({ oneTimeType: OneTimeType.TIME })}
                >
                  Setup time
                </Card>
              </div>
              {schedule.oneTimeType === OneTimeType.TIME && (
                <>
                  <h5 className="mt-6">Date</h5>
                  <DatePicker
                    disablePast={true}
                    initialFocusedDate={dayjs().tz()}
                    value={schedule.startDate}
                    onChange={(value) => {
                      setSchedule({ startDate: value, time: value });
                    }}
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    inputVariant="outlined"
                    TextFieldComponent={({ onChange, value, onClick }) => (
                      <TextInput
                        placeholder="DD/MM/YYYY"
                        className="py-3 mt-3"
                        value={value as any}
                        onChange={onChange}
                        onClick={onClick}
                      />
                    )}
                  />
                  <h5 className="mt-6">Time</h5>
                  <TimePicker
                    initialFocusedDate={dayjs().tz()}
                    value={schedule.time}
                    onChange={(value) => {
                      setSchedule({ time: value });
                    }}
                    inputVariant="outlined"
                    TextFieldComponent={({ onChange, value, onClick }) => (
                      <TextInput
                        placeholder="hh:mm"
                        className="py-3 mt-3"
                        value={value as any}
                        onChange={onChange}
                        onClick={onClick}
                      />
                    )}
                  />
                </>
              )}
            </>
          )}
          <div className="mt-2 text-xs text-tw-black-9">
            {getScheduleText(schedule, user?.currentOrganization?.timezone)}
          </div>
          <PrimaryButton
            isLoading={createPQLMutation.isLoading}
            className="w-full py-3 mt-6"
            onClick={handleSubmit}
            disabled={disablePlaybookCreationButton}
          >
            Proceed
          </PrimaryButton>
        </SectionAccordion>
        {schedule.cadence && (
          <Modal
            open={scheduleSectionOpen}
            onClose={() => {
              handleCloseModal();
            }}
            closeIcon={true}
            title={`${capitalizeFirstLetter(schedule.cadence.type)} cadence`}
          >
            <div className="flex flex-wrap gap-2 p-6">
              {schedule.cadence.type === CadenceFrequency.MONTHLY &&
                daysInMonth.map((day) => (
                  <Block
                    key={day}
                    onClick={() => {
                      if (schedule.cadence.monthlyValues.includes(day)) {
                        setSchedule({
                          cadence: {
                            ...schedule.cadence,
                            monthlyValues: schedule.cadence.monthlyValues.filter((x) => x !== day),
                          },
                        });
                      } else {
                        setSchedule({
                          cadence: {
                            ...schedule.cadence,
                            monthlyValues: [...schedule.cadence.monthlyValues, day],
                          },
                        });
                      }
                    }}
                    isActive={schedule.cadence.monthlyValues.includes(day)}
                  >
                    {day}
                  </Block>
                ))}
              {schedule.cadence.type === CadenceFrequency.WEEKLY &&
                daysInWeek.map((day) => (
                  <Block
                    key={day}
                    onClick={() => {
                      if (schedule.cadence.weeklyValues.includes(day)) {
                        setSchedule({
                          cadence: {
                            ...schedule.cadence,
                            weeklyValues: schedule.cadence.weeklyValues.filter((x) => x !== day),
                          },
                        });
                      } else {
                        setSchedule({
                          cadence: {
                            ...schedule.cadence,
                            weeklyValues: [...new Set([...schedule.cadence.weeklyValues, day])],
                          },
                        });
                      }
                    }}
                    isActive={schedule.cadence.weeklyValues.includes(day)}
                  >
                    {day}
                  </Block>
                ))}
            </div>
          </Modal>
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
}
