import * as Popover from '@radix-ui/react-popover';
import { motion } from 'framer-motion';
import { classNames } from 'utils/common';
import { useCallback, useEffect, useRef, useState } from 'react';

type HoverPopoverProps = {
  children: React.ReactNode;
  popOverContent: React.ReactNode;
  side: 'top' | 'bottom' | 'left' | 'right';
  align: 'start' | 'center' | 'end';
  marginClassName?: string;
  usePortal?: boolean;
};

export default function HoverPopover({
  children,
  popOverContent,
  side,
  align,
  marginClassName,
  usePortal,
}: HoverPopoverProps) {
  const [showDialogOnHover, setShowDialogOnHover] = useState(false);
  const popoverContentRef = useRef(null);
  const componentRef = useRef(null);
  const mouseOverTimerId = useRef(null);

  const mouseMoveCallback = useCallback(
    (e) => {
      if (
        !(
          popoverContentRef?.current?.contains(e.target) ||
          componentRef?.current?.contains(e.target)
        )
      ) {
        if (mouseOverTimerId?.current !== undefined) {
          clearTimeout(mouseOverTimerId?.current);
        }
        mouseOverTimerId.current = setTimeout(() => {
          if (showDialogOnHover) {
            setShowDialogOnHover(false);
          }
        }, 200);
      }
    },
    [showDialogOnHover]
  );

  useEffect(() => {
    document.addEventListener('mousemove', mouseMoveCallback);
    return () => {
      clearTimeout(mouseOverTimerId?.current);
      document.removeEventListener('mousemove', mouseMoveCallback);
    };
  }, [mouseMoveCallback]);

  const popoverContent = (
    <Popover.Content
      ref={popoverContentRef}
      avoidCollisions
      side={side}
      align={align}
      className={classNames('z-30 w-64 -ml-2 focus:outline-none', marginClassName)}
      onEscapeKeyDown={() => setShowDialogOnHover(false)}
      onInteractOutside={() => setShowDialogOnHover(false)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onMouseOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="px-4 py-2 border rounded-md bg-tw-white-ff shadow-3xl drop-shadow-3xl border-tw-gray-eb"
      >
        {popOverContent}
      </motion.div>
    </Popover.Content>
  );

  return (
    <div className="relative">
      <div
        ref={componentRef}
        onMouseOver={() => {
          if (mouseOverTimerId?.current !== undefined) {
            clearTimeout(mouseOverTimerId?.current);
          }
          mouseOverTimerId.current = setTimeout(() => {
            setShowDialogOnHover(true);
          }, 200);
        }}
      >
        {children}
      </div>
      <Popover.Root open={showDialogOnHover}>
        <Popover.Anchor />
        {usePortal ? <Popover.Portal>{popoverContent}</Popover.Portal> : popoverContent}
      </Popover.Root>
    </div>
  );
}

HoverPopover.defaultProps = {
  usePortal: true,
};
