import { useAuthStore } from 'hooks/useAuthStore';
import { useQuery } from 'react-query';
import { useClient } from '../api-client';
import { useAnalytics } from '../telemetry';

export default function useAuthUser(requireUserDetails: boolean) {
  const client = useClient();
  const { identify } = useAnalytics();
  const { setAuthState } = useAuthStore();
  const { getAccountId } = useAuthStore();

  // depends on accountId from session-storage which
  // will decide the current-organisation
  return useQuery(['user', getAccountId()], () => client.getUser(), {
    enabled: requireUserDetails,
    onSuccess: (data) => {
      setAuthState({
        user: data,
        isLoggedIn: true,
      });

      const props = {
        firstname: data.firstName,
        lastname: data.lastName,
        isStaff: data.isStaff,
      };
      if (data?.currentOrganization?.isSandbox) {
        props['sandboxAccountName'] = data.currentOrganization?.schemaName;
        props['accountName'] = data.organizations?.find(
          (org) => !org.isSandbox && org.sandboxId === data.currentOrganization?.schemaName
        )?.schemaName;
      } else {
        props['accountName'] = data.currentOrganization?.schemaName;
        props['sandboxAccountName'] = data.currentOrganization?.sandboxId;
      }
      identify(data.email, props);
    },
  });
}
