import { QueryType } from 'stores/QueryStore';
import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { LeadStatus } from './PQLFiltersStore';

export enum DefinePQLSections {
  ENTITIES = 'entities',
  GOALS = 'goals',
  CONVERSION_EVENT = 'conversionEvent',
  COHORT = 'cohort',
  HWC = 'hwc',
}

// <name_of_the_section>: <finished>
export const DefineSectionsMap: {
  [key in DefinePQLSections]: boolean;
} = {
  [DefinePQLSections.ENTITIES]: false,
  [DefinePQLSections.GOALS]: false,
  [DefinePQLSections.CONVERSION_EVENT]: false,
  [DefinePQLSections.COHORT]: false,
  [DefinePQLSections.HWC]: false,
};

export type GtmStrategy = {
  label?: string;
  value?: string;
};

export enum ModelGoals {
  CONVERSION = 'conversion',
  EXPANSION = 'expansion',
  UPSELL = 'upsell',
  ENGAGEMENT = 'engagement',
}

type DefinePQLState = {
  currentSection: DefinePQLSections;
  sectionsMap: { [key in DefinePQLSections]: boolean };
  modelId: string;
  targetEntity: QueryType | '';
  performanceTrackEvent: { [key: string]: string };
  // cohort can be nullable
  cohorts: Array<number>;
  // Gtm strategy can be an empty object
  gtmStrategy: GtmStrategy;
  showSampleProfiles: boolean;
  leadStatus: Array<LeadStatus>;
};

type DefinePQLStore = {
  setTargetEntity: (entity: QueryType) => void;
  setModelId: (modelId: string) => void;
  setPerformanceTrackEvent: (conversionEvent: object) => void;
  setCohorts: (cohorts: Array<number>) => void;
  setGtmStrategy: (gtmStrategy: GtmStrategy) => void;
  setSectionsMap: (sectionsMap: { [key: string]: boolean }) => void;
  setCurrentSection: (section: DefinePQLSections) => void;
  setShowSampleProfiles: (showSampleProfiles: boolean) => void;
  setLeadStatus: (leadStatus: Array<LeadStatus>) => void;
  reset: () => void;
  hydrate: (state: DefinePQLState) => void;
} & DefinePQLState;

const initialState: DefinePQLState = {
  currentSection: DefinePQLSections.ENTITIES,
  sectionsMap: DefineSectionsMap,
  modelId: '',
  targetEntity: '',
  performanceTrackEvent: null,
  gtmStrategy: {},
  showSampleProfiles: false,
  cohorts: [],
  leadStatus: [LeadStatus.ALL],
};

export const useDefinePQLStore = create<DefinePQLStore>(
  devtools((set) => {
    return {
      ...initialState,
      setTargetEntity: (targetEntity: QueryType) => {
        set((state) => ({
          ...state,
          targetEntity,
        }));
      },
      setModelId: (modelId: string) => {
        set((state) => ({
          ...state,
          modelId,
        }));
      },
      setPerformanceTrackEvent: (performanceTrackEvent: { [key: string]: string }) => {
        set((state) => ({
          ...state,
          performanceTrackEvent,
        }));
      },
      setCohorts: (cohorts: Array<number>) => {
        set((state) => ({
          ...state,
          cohorts,
        }));
      },
      setGtmStrategy: (gtmStrategy: GtmStrategy) => {
        set((state) => ({
          ...state,
          gtmStrategy,
        }));
      },
      setSectionsMap: (sectionsMap: { [key in DefinePQLSections]: boolean }) => {
        set((state) => ({
          ...state,
          sectionsMap,
        }));
      },
      setCurrentSection: (section: DefinePQLSections) => {
        set((state) => ({
          ...state,
          currentSection: section,
        }));
      },
      setShowSampleProfiles: (showSampleProfiles: boolean) => {
        set((state) => ({
          ...state,
          showSampleProfiles,
        }));
      },
      setLeadStatus: (leadStatus: Array<LeadStatus>) => {
        set((state) => ({
          ...state,
          leadStatus,
        }));
      },
      reset: () => {
        set(() => ({
          ...initialState,
        }));
      },
      hydrate: (state: DefinePQLState) => {
        set(() => ({
          ...state,
        }));
      },
    };
  })
);
