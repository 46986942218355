import { GrantAccessData, useClient } from 'api-client';
import { useMutation, useQueryClient } from 'react-query';

export default function useGrantAccess() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation((data: GrantAccessData) => client.approveJoinRequest(data), {
    onSettled: () => {
      queryClient.refetchQueries('joinRequests');
      queryClient.refetchQueries('acceptedTeamMembers');
    },
  });
}
