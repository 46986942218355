import OpportunityActionDropdown from 'ui-components/business/opportunities/OpportunityActionDropdown';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import { capitalizeFirstLetter, classNames } from 'utils/common';
import { useAuthStore } from 'hooks/useAuthStore';
import { useHistory } from 'react-router-dom';
import useAuthUser from 'hooks/useAuthUser';
import useRole from 'hooks/useRole';
import useOpportunity from '../../hooks/useOpportunity';
import { useCallback, useEffect, useState } from 'react';
import { useAnalytics } from 'telemetry';
import { AnalyticsConst, AnalyticsEvents } from 'telemetry/constants';
import { useGlobalStore } from 'stores/GlobalStore';
import { trim } from 'utils/helpers';
import { Link } from 'react-router-dom';
import { routes } from '../../routing/clientRoutes';
import { DestinationTag } from './DestinationTag';
import DisplayPQLCadence from './DisplayPQLCadence';
import { Opportunity, OpportunitySchedule } from './types';

// extend dayjs
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mon',
    MM: '%dmon',
    y: '1y',
    yy: '%dy',
  },
});

type OpportunityItemProps = {
  opportunity: Opportunity;
};

export const destinationColour: (destinationType: string) => string = (destinationType: string) => {
  switch (destinationType) {
    case 'salesforce':
      return 'bg-tw-mild-blue/[0.1] text-tw-mild-blue';
    case 'salesforce_sandbox':
      return 'bg-tw-mild-blue/[0.1] text-tw-mild-blue';
    case 'email':
      return 'bg-tw-blue-31/[0.1] text-tw-blue-31';
    case 'webhook':
      return 'bg-tw-purple-78/[0.1] text-tw-purple-78';
    case 'pipedrive':
      return 'bg-tw-gray-23/[0.1] text-tw-gray-23';
    case 'hubspot':
      return 'bg-tw-orange-hubspot/[0.1] text-tw-orange-hubspot';
    default:
      return 'bg-tw-mild-orange/[0.1] text-tw-mild-orange';
  }
};

function getCadenceType(schedule: OpportunitySchedule) {
  if (!schedule) {
    return <div className="flex items-center justify-center h-5 py-2"></div>;
  }

  if (schedule.time) {
    return 'One time';
  }

  if (schedule.recurring) {
    return `${capitalizeFirstLetter(Object.keys(schedule.recurring.frequency)[0])} recurring`;
  }
}

export default function OpportunityItem(props: OpportunityItemProps) {
  const { setActiveStatsTab } = useGlobalStore();

  // used to show/hide the quick view button
  const [showDetailsBtn, setShowDetailsBtn] = useState<boolean>(false);
  const [fetchScheduleFromExport, setFetchScheduleFromExport] = useState<boolean>(false);

  // get account timezone
  const { data: userData } = useAuthUser(false);
  const { getAccountId } = useAuthStore();

  const { isReadAndWrite, isAdmin } = useRole();
  const router = useHistory();

  const { track } = useAnalytics();

  const {
    opportunity: {
      id,
      name,
      status,
      destination,
      createdBy,
      query,
      schedule,
      createdAt,
      lastRun,
      nextRun,
      isSample,
    },
  } = props;

  // fetch schedule data from export table if
  // schedule is not present in the opportunity
  const { data: opportunityDetail } = useOpportunity(id.toString(), fetchScheduleFromExport);

  useEffect(() => {
    if (!schedule) {
      setFetchScheduleFromExport(true);
    }
  }, [schedule]);

  const getOpportunityCreatedBy = useCallback(() => {
    if (destination?.name === 'Sandbox Salesforce' && isSample) {
      return 'janedoe@toplyne.io';
    } else if (destination?.name === 'Sandbox CustomerIO' && isSample) {
      return 'johndoe@toplyne.io';
    }

    return createdBy?.email || '--';
  }, [createdBy?.email, destination?.name]);

  return (
    <div
      className={classNames('mb-6 cursor-pointer group playbook-item')}
      onMouseOver={() => {
        setShowDetailsBtn(true);
      }}
      onMouseLeave={() => {
        setShowDetailsBtn(false);
      }}
      onClick={() => {
        router.push(`/${getAccountId()}${routes.opportunities}/details/${id}`);
      }}
    >
      <div className="rounded-t-xl border-l-1 border-r-1 border-t-1 border-tw-gray-eb">
        <div className="grid grid-cols-4 px-4 pt-6 pb-8">
          <div className="w-10/12">
            <Link
              to={`/${getAccountId()}${routes.opportunities}/details/${id}`}
              onClick={(e) => e.preventDefault()}
            >
              <div
                className="group-hover:text-tw-blue-0d hover:cursor-pointer"
                onClick={() => {
                  track(AnalyticsEvents.OPPORTUNITY_DETAIL_CLICKED, {
                    [AnalyticsConst.OPPORTUNITY_ID]: id.toString(),
                    [AnalyticsConst.OPPORTUNITY_NAME]: name,
                    [AnalyticsConst.GOAL]: query?.goal,
                  });
                }}
              >
                {trim(name, 64)}
              </div>
            </Link>
            <div className="mt-1 text-xs text-tw-gray-7">{getCadenceType(schedule)}</div>
          </div>
          <div className="-ml-2">
            <DestinationTag destination={destination} />
          </div>
          <div className="-ml-4">
            <div>{trim(`Created by: ${getOpportunityCreatedBy()}`, 32)}</div>
            <div className="mt-1 text-xs text-tw-gray-7">
              {capitalizeFirstLetter(dayjs(createdAt).tz().fromNow())}
            </div>
          </div>
          <div className="flex items-center justify-end">
            <div className="no-wrap">
              <div className="flex items-center">
                {showDetailsBtn && (
                  <Link
                    to={`/${getAccountId()}${routes.opportunities}/details/${id}`}
                    onClick={(e) => e.preventDefault()}
                  >
                    <button
                      className={classNames(
                        'ml-4 flex items-start py-3 px-4 -mt-1 rounded font-semibold border-0 bg-tw-blue-a text-tw-blue-0d'
                      )}
                      onClick={() => {
                        track(AnalyticsEvents.OPPORTUNITY_DETAIL_CLICKED, {
                          [AnalyticsConst.OPPORTUNITY_ID]: id.toString(),
                          [AnalyticsConst.OPPORTUNITY_NAME]: name,
                          [AnalyticsConst.GOAL]: query?.goal,
                        });
                        // reset active stats tab.
                        setActiveStatsTab(0);
                      }}
                    >
                      <div className="flex items-center">See details</div>
                    </button>
                  </Link>
                )}
              </div>
            </div>
            <div className="w-6 mt-1 ml-8">
              {status && destination?.type !== 'api' && (isAdmin || isReadAndWrite) && (
                // does not change quick view toggle
                // on interacting with this section.
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onMouseEnter={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onMouseLeave={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <OpportunityActionDropdown
                    id={id}
                    isSample={isSample}
                    name={name}
                    status={status}
                    sizeClassName="w-4 h-auto fill-current text-tw-black-5"
                    align="end"
                    onChangeOpen={(dropdownOpen) => {
                      if (!dropdownOpen) {
                        setShowDetailsBtn(false);
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="w-full text-xs text-center cursor-pointer border-b-1 border-l-1 border-r-1 border-tw-gray-eb bg-tw-gray-f5 rounded-b-xl text-tw-black-5">
          <DisplayPQLCadence
            schedule={schedule || opportunityDetail?.exports[0]?.schedule}
            status={status}
            timezone={userData?.currentOrganization?.timezone}
            lastRun={lastRun}
            nextRun={nextRun}
          />
        </div>
      </div>
    </div>
  );
}
