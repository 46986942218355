import * as React from 'react';

interface CardProps {
  title?: string;
  subTitle?: string;
  style?: React.CSSProperties;
  className?: string;
  bodyClass?: string;
  hover?: boolean;
}

const Card: React.FC<CardProps> = (props) => {
  return (
    <div className={`${props.className ? props.className : ''}`} style={props.style}>
      <div className={`${props.bodyClass} border border-tw-gray-eb rounded-lg p-5`}>
        {props.title && <h3 className="card-title h2">{props.title}</h3>}
        {props.subTitle && <h6 className="card-subtitle">{props.subTitle}</h6>}
        {/* <p className='card-text'></p> */}
        {props.children}
      </div>
    </div>
  );
};

export default Card;
