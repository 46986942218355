import * as React from 'react';
import styled from '@emotion/styled';

const CircleContainer = styled.div`
  display: inline-block;
  border-radius: 100%;
  position: relative;
`;

const PercentageContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const StyledCircle = styled.circle`
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;

interface GradientCircleProgressbarProps {
  percentage?: number;
  width?: number;
  strokeWidth?: number;
  strokeLinecap?: 'round' | 'square' | 'butt' | 'inherit';
  fontSize?: string;
  fontWeight?: number;
  fontColor?: string;
  fontFamily?: string;
  primaryColor?: any;
  secondaryColor?: string;
  fill?: string;
  hidePercentageText?: boolean;
  title?: string;
}

const GradientCircleProgressbar: React.FC<GradientCircleProgressbarProps> = ({
  percentage,
  width,
  strokeWidth,
  fontSize,
  fontWeight,
  fontColor,
  fontFamily,
  primaryColor,
  secondaryColor,
  fill,
  hidePercentageText,
  strokeLinecap,
  title,
}) => {
  const PI = Math.PI;
  const R = width / 2 - strokeWidth * 2;

  let circumference = 2 * PI * R;
  let offset = circumference - (percentage / 100) * circumference;
  let gradientId = `${primaryColor[0]}${primaryColor[1]}`.replace(/#/g, '');

  return (
    <CircleContainer
      className="react-super-progressbar__base"
      style={{
        height: `${width}px`,
        width: `${width}px`,
      }}
    >
      {!hidePercentageText ? (
        <PercentageContainer className="react-super-progressbar__percentage-container">
          <span
            className="react-super-progressbars__percentage"
            style={{
              fontSize,
              fontFamily,
              fontWeight,
              color: fontColor,
            }}
          >
            {title}
          </span>
        </PercentageContainer>
      ) : null}

      <svg width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor={primaryColor[0]} />
          <stop offset={`100%`} stopColor={primaryColor[1]} />
        </linearGradient>
        <circle
          strokeWidth={strokeWidth}
          fill="transparent"
          r={R}
          cx={width / 2}
          cy={width / 2}
          stroke={secondaryColor}
          strokeDasharray={`${circumference} ${circumference}`}
        />
        <StyledCircle
          strokeWidth={strokeWidth}
          fill={fill}
          r={R}
          cx={width / 2}
          cy={width / 2}
          stroke={`url(#${gradientId})`}
          strokeLinecap={strokeLinecap}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
        />
      </svg>
    </CircleContainer>
  );
};

GradientCircleProgressbar.defaultProps = {
  width: 200,
  strokeWidth: 5,
  strokeLinecap: 'round',
  fontSize: 'inherit',
  fontWeight: 500,
  fontColor: 'inherit',
  fontFamily: 'inherit',
  primaryColor: ['#00BBFF', '#92d7f1'],
  secondaryColor: 'transparent',
  fill: 'transparent',
  title: '',
};

export default GradientCircleProgressbar;
