import React, { useEffect } from 'react';
import { useAnalytics } from 'telemetry';
import { ErrorAnalyticsEvents } from 'telemetry/constants';
import ToastIcons from './ToastIcons';

export default function ErrorToast({
  description = 'Uh oh! Something went wrong',
  altText = 'Our team is looking into it',
}: {
  description?: string;
  altText?: string;
}) {
  const { track } = useAnalytics();

  useEffect(() => {
    track(ErrorAnalyticsEvents.ERROR_OCCURED);
  }, [track]);

  return (
    <div className="flex items-center">
      <ToastIcons type="error" />
      <div className="ml-1">
        <div className="font-semibold text-sm text-tw-black-1">{description}</div>
        <div className="text-tw-black-7 text-sm mt-1">{altText}</div>
      </div>
    </div>
  );
}
