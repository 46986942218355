import { TokenResponse, useApiClient } from '../../../api-client';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosInstance } from 'axios';
import { useApiTokenStore } from '../../../stores/ApiTokenStore';
import { toast as toastify } from 'react-toastify';
import { useAnalytics } from '../../../telemetry';
import { AnalyticsEvents } from '../../../telemetry/constants';
import { useAuthStore } from 'hooks/useAuthStore';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';

type TokenData = {
  name: string;
  expires_on: string;
};

async function addToken(
  client: AxiosInstance,
  values: TokenData & { schemaName?: string },
  getAccountSchemaName
) {
  const { schemaName, ...rest } = values;
  const accountSchemaName = schemaName || getAccountSchemaName();
  const headers = accountSchemaName ? { 'TL-ORG': accountSchemaName } : {};
  const { data } = await client.post<TokenResponse>('/api-token/', rest, { headers });
  return data;
}

export default function useAddAPIToken() {
  const client = useApiClient();
  const { track } = useAnalytics();
  const { setTokenStore } = useApiTokenStore();
  const queryClient = useQueryClient();
  const { getAccountSchemaName } = useAuthStore();

  return useMutation(
    (data: TokenData & { schemaName?: string }) => addToken(client, data, getAccountSchemaName),
    {
      onSuccess: async (response) => {
        setTokenStore(response);
        toastify(<SuccessToast description="Token has been created" />, {
          type: 'success',
        });

        queryClient.setQueryData('useMemberApiDetails', (apiDetails: TokenResponse[]) => {
          return [response, ...(apiDetails || [])];
        });
      },
      onError: async (error) => {
        track(AnalyticsEvents.TOKEN_FAILED);

        throw error;
      },
      onSettled: async () => {
        await queryClient.refetchQueries('useMemberApiDetails');
      },
    }
  );
}
