import { AxiosInstance } from 'axios';
import useRefresh from 'hooks/auth/useRefresh';
import { useAuthStore } from 'hooks/useAuthStore';
import isEmpty from 'lodash/isEmpty';
import { SortSyncedUsersBy } from 'pages/opp-details/hooks/useGetSyncedUsers';
import { QueryParams } from 'pages/opportunities/store/OpportunityListStore';
import { OpportunityQueryPayload } from 'pages/pql-workflow/hooks/useOpportunityQuery';
import { GtmStrategy } from 'pages/pql-workflow/store/PQLDefineStore';
import { Roles } from 'pages/settings/Roles';
import { ColumnType } from 'pages/users-and-accounts/store/AccountsStore';
import { FilterExpression, SortBy } from 'pages/users-and-accounts/users/types';
import qs from 'query-string';
import { createContext, useContext, useMemo, useRef } from 'react';
import { toast as toastify } from 'react-toastify';
import { Filter, QueryType } from 'stores/QueryStore';
import {
  FieldMappingEntity,
  OpportunityResponse,
} from 'ui-components/business/opportunities/types';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';
import { getStatusRequestPayload } from 'utils/helpers';
import {
  Accounts,
  Leads,
  ListAllOpportunities,
  OpportunityStatusCount,
  UsersTableDataQuery,
} from '../pages/opportunities/types';
import { createClient } from './client';

export const PAGINATION_LIMIT = 50;
export const NETWORK_ERR_TOAST = 'network_err_toast';

export enum IntegrationType {
  EMAIL = 'email',
  SLACK = 'slack',
  SALESFORCE = 'salesforce',
  SALESFORCE_SANDBOX = 'salesforce_sandbox',
  PIPEDRIVE = 'pipedrive',
  HUBSPOT = 'hubspot',
  MARKETO = 'marketo',
  GAINSIGHT = 'gainsight',
  WEBHOOK = 'webhook',
  API = 'api',
  MIXPANEL = 'mixpanel',
  AMPLITUDE = 'amplitude',
  SEGMENT = 'segment',
  TRAY = 'tray',
  OTHERS = 'others',
  SNOWFLAKE = 'snowflake',
  RUDDERSTACK = 'rudderstack',
  BIGQUERY = 'big_query',
}

export enum ConnectedIntegrationStatus {
  READY = 'ready',
  IN_PROGRESS = 'in_progress',
  ERROR = 'error',
}

export enum OrganizationStatus {
  READY = 'ready',
  IN_PROGRESS = 'in_progress',
  ERROR = 'error',
}

type CustomHeader = {
  'is-prefetching'?: boolean;
};

export type OpportunityStatusCountParams = {
  queryParams?: QueryParams;
  dateRange?: { from: string; to: string };
} & (
  | {
      queryParams: QueryParams;
    }
  | {
      dateRange: { from: string; to: string };
    }
);

export enum OpportunityStatus {
  ALL = 'all',
  SCHEDULED = 'scheduled',
  RUNNING = 'running',
  ACTIVE = 'active',
  FAILED = 'failed',
  COMPLETED = 'completed',
  STOPPED = 'stopped',
  FINISHED = 'finished',
  // TODO: update this when we rename scheduled to pending
  // on the backend.
  PENDING = 'pending',
}

export enum IntegrationCategory {
  MESSAGING = 'messaging',
  CRM = 'crm',
  MARKETING = 'marketing',
  CUSTOMER_SUCCESS = 'customer_success',
  ANALYTICS = 'analytics',
  BILLING = 'billing',
  SALES = 'sales',
  SALES_ENGAGEMENT = 'sales_and_engagement',
  LIVECHAT = 'livechat',
  MARKETING_AUTOMATION = 'marketing_automation',
  ADVERTISING = 'advertising',
  EMAIL_MARKETING = 'email_marketing',
  API = 'api',
}

export enum IntegrationReleaseType {
  AVAILABLE = 'available',
  BETA = 'beta',
  PRIVATE = 'private',
}

export type Error = {
  response: {
    data: any;
  };
};

type SchemaValidation = {
  pattern: string;
  errorMessage: string;
};

type IntegrationSchema = {
  label: string;
  name: string;
  dType: string;
  options: Array<any>;
  validation?: SchemaValidation;
};

type TrayIntegrationMetadata = {
  solutionId: string;
  solutionName: string;
  batchType: string;
  batchSize: string;
  externalAuthId?: string;
};

type TyepMappings = {
  str?: string[];
  int?: string[];
  bool?: string[];
  float?: string[];
  timestamp?: string[];
};

type IntegrationMetadata = {
  tray: TrayIntegrationMetadata;
  typeMappings?: TyepMappings;
  entityLabel?: string;
  subEntityLabel?: string;
  uniqueKeysDestinationLabel?: string;
  mappingsDestinationLabel?: string;
};

type IntegrationFormMetadataEntity = {
  name: string;
  label: string;
  defaultMappings?: Record<string, { name: string; required: boolean }>;
  formElements?: Record<
    string,
    {
      name: string;
      label: string;
      type: 'TEXTBOX' | 'STATIC' | 'DROPDOWN';
      required: boolean;
      defaultValue: string;
    }
  >;
  children?: Array<string>;
};

type IntegrationFormMetadata = Record<
  string,
  IntegrationFormMetadataEntity & Partial<Record<string, IntegrationFormMetadataEntity>>
>;

export type Integration = {
  type: IntegrationType;
  name: string;
  description: string;
  categories: IntegrationCategory[];
  supportsRead: boolean;
  supportsWrite: boolean;
  supportsEntities: boolean;
  supportsFields: boolean;
  default: boolean;
  available: boolean;
  oauth?: {
    clientId: string;
    authorizationUrl: string;
    scope: string;
  } | null;
  requiresUniqueId: boolean;
  icon?: any;
  isConnected?: boolean;
  id?: number;
  active?: boolean;
  editIntegration?: void;
  isSource?: boolean;
  createdAt?: string;
  schema?: Array<IntegrationSchema>;
  metadata?: IntegrationMetadata;
  formMetadata?: IntegrationFormMetadata;
  logoUrl?: string;
  popular?: boolean;
  releaseType?: IntegrationReleaseType;
};

export type ConnectedIntegration = {
  id: number;
  type: IntegrationType;
  name: string;
  integration: Integration;
  createdAt: string;
  modifiedAt: string;
  createdBy?: any;
  metadata?: any;
  active?: boolean;
  available?: boolean;
  status?: ConnectedIntegrationStatus;
};

type OAuthConnect = { code: string; redirectUri: string };
type SalesforceConnect = OAuthConnect & { domain: string };
type PipedriveConnect = { apiDomain: string; apiKey: string };
type TrayConnect = { authId: string };
export type WebhookConnect = {
  url: string;
  method: string;
  headers: { [key: string]: string | { [key: string]: string } };
  batchSize?: number;
};

type SegmentConnect = {
  selectedSourceName: string;
};

export type Connect = {
  salesforce?: SalesforceConnect;
  webhook?: WebhookConnect;
  hubspot?: OAuthConnect;
  pipedrive?: PipedriveConnect;
  tray?: TrayConnect;
  segment?: SegmentConnect;
};

export type Entity = {
  label: string;
  name: string;
  custom: boolean;
};

type Field = {
  source: string;
  keySpace: string;
  keyName: string;
  displayName: string;
  dataType: string;
  isSystem: boolean;
  columnName: string;
};

export type EntityResponse = {
  id: string;
  name: string;
  defaultModel: Model;
  defaultFields: string[];
  enabled: boolean;
  fields: Field[];
  activationStatus: ActivationStatus;
};

export type EntityField = {
  label: string;
  name: string;
  type: string;
  nullable: boolean;
  required: boolean;
  unique: boolean;
};

export type EntityDetail = Entity & {
  fields: EntityField[];
};

type ScoreThresholds = {
  hot: number;
  warm: number;
  cold: number;
};

export enum ActivationStatus {
  AVAILABLE = 'available',
  REQUESTED = 'requested',
  NOT_AVAILABLE = 'not_available',
}

export type Model = {
  id: number;
  name: string;
  modelId: string;
  description: string;
  active: boolean;
  available: boolean;
  entity: string;
  goal: string;
  scoreThresholds: ScoreThresholds;
  createdAt: Date;
  modifiedAt: Date;
  createdBy: any;
  scoreColumnName: string;
  queryStartDay: number;
  queryEndDay: number;
  bins: Array<number>;
  defaultBin: number;
  dsEnabled?: boolean;
  activationStatus: ActivationStatus;
};

export type Organization = {
  callScheduled: boolean;
  domain: string;
  name: string;
  orgId: string;
  schemaName: string;
  status: OrganizationStatus;
  timezone: string;
  isSandbox: boolean;
  isActive: boolean;
  sandboxId: string;
  trainingWindow: string;
};

export type SimilarOrganization = {
  adminEmails: string[];
  userCount: number;
} & Organization;

export type UserData = {
  email: string;
  id: number;
  firstName: string;
  lastName: string;
  isStaff: boolean;
  organizations: Array<Organization>;
  currentOrganization: Organization & Record<string, any>;
  avatarName: string;
  currentRole?: Role;
  defaultOrganization: Organization;
  hasSimilarOrganization: boolean;
  similarOrganizations: SimilarOrganization[];
};

export type ResetPassword = {
  uid: string;
  token: string;
  password: string;
};

export type TeamMemberEntity = {
  id: string;
  email: string;
  createdAt: string;
  currentRole: Role;
};

export type Role = {
  role: string;
};

export type InvitedMember = {
  id: string;
  createdBy: UserEntity;
  email: string;
  organization: Organization;
};

export type InvitationsEntity = {
  id: string;
  createdAt: string;
  expiresOn: string;
  expired: boolean;
  createdBy: UserEntity;
  expireDaysLeft: string;
};

export type PendingInviteEntity = {
  id: string;
  email: string;
  invitation: InvitationsEntity;
  organization: Organization;
};

export type UserEntity = {
  email: string;
  firstName: string;
  lastName: string;
  id: number;
  avatarName: string;
  isStaff: boolean;
  defaultOrganization: Organization;
};

export type OrganizationDetails = Organization & Record<string, any>;

export type LoginResponse = {
  accessToken: string;
  refreshToken: string;
  user: UserEntity & {
    currentOrganization: Organization;
    organizations: Organization[];
    hasSimilarOrganization: boolean;
    similarOrganizations: SimilarOrganization[];
  };
};
export type TokenResponse = {
  id: number;
  name: string;
  token?: string;
  expire_on: string;
  metadata?: string;
  createdBy: UserEntity;
};

export type AddIntegrationRequest = {
  type: IntegrationType;
  integration?: number | string;
  name: string;
  active?: boolean;
  connect: Connect;
};

export type EditIntegrationRequest = {
  name?: string;
  active?: boolean;
  status?: string;
  connect?: Connect;
};

export type AddIntegrationFieldRequest = {
  integrationId: number;
  integrationType: IntegrationType.HUBSPOT | IntegrationType.PIPEDRIVE;
  name: string;
  fieldType: string;
  type?: string;
  groupName?: string;
  objectType?: string;
  label?: string;
};

type PerformanceCardResponse = {
  toConvert: number;
  leads: number;
};

type PerformanceQualificationResponse = {
  summary: Record<string, any>;
  aggregate: Record<string, any>;
};

type PerformanceConversionResponse = {
  monetaryValue: Record<string, any>;
  medianTimeToConversion: Record<string, any>;
  timeSeries: Record<string, any>;
} & PerformanceQualificationResponse;

export type PerformanceDetailsResponse = {
  card: {
    nonControlGroup: PerformanceCardResponse;
    controlGroup: PerformanceCardResponse;
  };
  // nested object
  conversions: {
    nonControlGroup: PerformanceConversionResponse;
    controlGroup: PerformanceConversionResponse;
  };
  qualifications: {
    nonControlGroup: PerformanceQualificationResponse;
    controlGroup: PerformanceQualificationResponse;
  };
  liftSummary: {
    lift: string;
    change: number;
    impact: boolean;
  };
};

export type LoggedInUser = {
  id: string | number;
  firstName: string;
  lastName: string;
  email: string;
  isStaff: boolean;
  avatarName: string;
  organizations: Array<Organization>;
  currentOrganization: Organization & Record<string, any>;
};

type LogoutData = {
  refresh: string;
};

type LogoutResponse = {
  detail: string;
};

type RecommendedFilter = {
  keyname: string;
  keyspace: string;
  keyvalue: string;
  userCount: number;
  convertedCount: number;
  columnName: string;
  operator: string;
};

type RecommendedFiltersResponse = {
  results: RecommendedFilter[];
};

type RecommendedFilterArgs = {
  modelId: string;
  goal: string;
  type: string;
};

type HistogramParams = {
  goal?: string;
  type: string;
  modelId: string;
};

export type MetaItem = {
  SOURCE: string;
  KEYSPACE: string;
  KEYNAME: string;
  FEATURE_SCORE: number;
  DISPLAY_NAME: string;
  WEIGHT: number;
  DATA_TYPE: string;
  CORRELATION: string;
  IS_SYSTEM: boolean;
  COLUMN_NAME: string;
};

export type MetaResponse = {
  sourceMeta: {
    success: boolean;
    data: Array<MetaItem>;
  };
};

export type AxiosError = {
  response: {
    data: any;
    status: number;
  };
};

export type TrayConfigUrl = {
  url: string;
};

export type InactiveIntegrations = {
  id?: number;
  name: string;
  image: string;
  popular: boolean;
  categories: IntegrationCategory[];
  status: string;
  enabledAt: Date;
  requestedBy: any;
};

export type IntegrationRequest = {
  email: string;
  organization: string;
};

export type EditExportPayload = {
  metadata: Record<string, string | Array<String> | null | Record<string, string>>;
  fieldMapping: FieldMappingEntity[];
  tags: Record<string, string>;
};

type JoinRequest = {
  organization_schema_name: string;
  is_resend: boolean;
};

type ExistingOrgResponse = {
  schemaName: string;
  adminEmails: string[];
  name: string;
};

export type GrantAccessData = {
  email: string;
  role: Roles;
};

export type RejectJoinRequestData = {
  email: string;
};

export type ResendVerificationEmailData = {
  email: string;
};

export type BulkStopPlaybooksData = {
  opportunityIds: number[];
};

type SandboxDatabaseResponse = {
  databaseName: string;
  description: string;
  displayName: string;
  logoUrl: string;
};

export type CohortCountRequest = {
  type: QueryType;
  goal: string;
  modelId: string;
  filter: Filter[];
};

class ApiClient {
  static axiosInstance: AxiosInstance;

  constructor(private axiosInstance: AxiosInstance) {}

  async getInactiveIntegrations() {
    return (await this.axiosInstance.get<InactiveIntegrations[]>('inactive-integrations/')).data;
  }

  async saveRequestForInactiveIntegration(id, data: IntegrationRequest) {
    return (await this.axiosInstance.put(`inactive-integrations/${id}/`, { data: data })).data;
  }

  async integrationDetails(id: number | string) {
    return (await this.axiosInstance.get<Integration>(`/data/integrations/${id}/`)).data;
  }

  async integrations(schemaName?: string) {
    const headers = schemaName ? { 'TL-ORG': schemaName } : {};
    return (await this.axiosInstance.get<Integration[]>('/data/integrations/', { headers })).data;
  }

  async connectedIntegrations(schemaName?: string) {
    const headers = schemaName ? { 'TL-ORG': schemaName } : {};
    return (
      await this.axiosInstance.get<ConnectedIntegration[]>('/data/connected-integrations/', {
        headers,
      })
    ).data;
  }

  async getConnectedIntegration(id: number | string) {
    return await this.axiosInstance.get<ConnectedIntegration>(
      `/data/connected-integrations/${id}/`
    );
  }

  async getSegmentSources(data: AddIntegrationRequest) {
    return (
      await this.axiosInstance.post(`/data/connected-integrations/get-segment-sources/`, data)
    ).data;
  }

  async addIntegration(request: AddIntegrationRequest, schemaName?: string) {
    const headers = schemaName ? { 'TL-ORG': schemaName } : {};
    return (
      await this.axiosInstance.post<ConnectedIntegration>(
        '/data/connected-integrations/',
        request,
        { headers }
      )
    ).data;
  }

  async deleteIntegration(id: string | number) {
    return await this.axiosInstance.delete(`/data/connected-integrations/${id}/`);
  }

  async updateIntegration(id: string | number, data: EditIntegrationRequest) {
    return await this.axiosInstance.patch(`/data/connected-integrations/${id}/`, {
      ...data,
    });
  }

  async getTrayConfigUrl(id: number | string) {
    return await this.axiosInstance.get<TrayConfigUrl>(
      `/data/connected-integrations/${id}/config-url/`
    );
  }

  async deleteOpportunity(id: number | string) {
    return await this.axiosInstance.delete(`/data/opportunities/${id}/`);
  }

  async actionOpportunity(id: number, action: string) {
    return await this.axiosInstance.post(`/data/opportunities/${id}/${action}/`);
  }

  async getOpportunity(id: number | string) {
    return (await this.axiosInstance.get<OpportunityResponse>(`/data/opportunities/${id}/`)).data;
  }

  async connectedIntegrationEntities(id: number | string) {
    return (await this.axiosInstance.get<Entity[]>(`/data/connected-integrations/${id}/entities/`))
      .data;
  }

  async connectedIntegrationDetail(id: number | string | undefined, entity: string) {
    return (
      await this.axiosInstance.get<EntityDetail>(
        `/data/connected-integrations/${id}/entities/${entity}/`
      )
    ).data;
  }

  async toggleIntegration(id: number | string | undefined, active: boolean) {
    return (
      await this.axiosInstance.patch<any>(`/data/connected-integrations/${id}/`, {
        active,
      })
    ).data;
  }

  async getUser() {
    return (await this.axiosInstance.get<UserData>(`/auth/user/`)).data;
  }

  async opportunities(queryParams: QueryParams) {
    const { status, query, selectedOrderFilter, destinations, offset } = queryParams;
    const baseUrl = `/data/opportunities/?`;
    let searchParams = {
      limit: PAGINATION_LIMIT,
      offset,
      status: getStatusRequestPayload(status),
      order_by:
        selectedOrderFilter.filter.value === 'upcoming_runs'
          ? selectedOrderFilter.filter.value
          : `-${selectedOrderFilter.filter.value}`,
      search: query ? query : null,
      destination: destinations.map((destination) => destination),
    };
    return (
      await this.axiosInstance.get<ListAllOpportunities>(
        baseUrl + `${qs.stringify(searchParams, { skipNull: true })}`,
        {
          headers: {
            'TL-Feature-Flags': 'paginated-response',
          },
        }
      )
    ).data;
  }

  async getOpportunityQuery(payload: OpportunityQueryPayload, isPrefetching = false) {
    const customHeaders: CustomHeader = isPrefetching ? { 'is-prefetching': isPrefetching } : {};
    return (
      await this.axiosInstance.post<Leads>(`/data/opportunities/query/`, payload, {
        headers: customHeaders,
      })
    ).data;
  }

  async opportunitiesStatusCount(params: OpportunityStatusCountParams) {
    let baseUrl = `data/opportunities/_count/?`;
    const { queryParams, dateRange } = params;
    let searchParams = {
      search: queryParams?.query ? queryParams.query : null,
      destination: queryParams?.destinations
        ? queryParams.destinations.map((destination) => destination)
        : null,
      next_run_after: dateRange ? dateRange.from : null,
      next_run_before: dateRange ? dateRange.to : null,
    };
    return (
      await this.axiosInstance.get<OpportunityStatusCount[]>(
        baseUrl + `${qs.stringify(searchParams, { skipNull: true })}`,
        {
          headers: {
            'TL-Feature-Flags': 'paginated-response',
          },
        }
      )
    ).data;
  }

  async getTeamMembersList() {
    return (await this.axiosInstance.get<TeamMemberEntity[]>('members/')).data;
  }

  async deleteMember(id) {
    return (await this.axiosInstance.delete<TeamMemberEntity>(`members/${id}/`)).data;
  }

  async updateMemberRole(id, role) {
    return (await this.axiosInstance.put<TeamMemberEntity>(`members/${id}/`, { role: role })).data;
  }

  async getPendingInvites() {
    return (await this.axiosInstance.get<any>('invitations/')).data;
  }

  async getInviteDetails(token) {
    return (await this.axiosInstance.get<PendingInviteEntity>(`signup/${token}/`)).data;
  }

  async getPerformanceDetails(opId: number, payload: object) {
    return (
      await this.axiosInstance.get<PerformanceDetailsResponse>(
        `data/opportunities/${opId}/performance/`,
        { params: payload }
      )
    ).data;
  }

  async validateOrg(schemaName: string) {
    return (await this.axiosInstance.get<OrganizationDetails>(`organizations/${schemaName}/`)).data;
  }

  async signUpUser(token, password) {
    return (
      await this.axiosInstance.post<PendingInviteEntity>(`signup/`, {
        token,
        password,
      })
    ).data;
  }

  async sendPasswordResetEmail(email: string) {
    return (await this.axiosInstance.post<UserData>(`/dj-rest-auth/password/reset/`, { email }))
      .data;
  }

  async sendNewPassword(uid, token, password) {
    return (
      await this.axiosInstance.post<ResetPassword>(`/dj-rest-auth/password/reset/confirm/`, {
        uid,
        token,
        password,
      })
    ).data;
  }

  async login(username, password, account, headers) {
    return (
      await this.axiosInstance.post<LoginResponse>(
        'auth/login/',
        {
          username,
          password,
          account,
        },
        {
          headers: headers,
        }
      )
    ).data;
  }

  async sendInvite(emails) {
    return (await this.axiosInstance.post<InvitedMember>('invitations/send/', { emails })).data;
  }

  async resendInvite(id) {
    return (await this.axiosInstance.post<InvitedMember>(`invitations/${id}/resend/`, {})).data;
  }

  async cancelInvite(id) {
    return (await this.axiosInstance.post<InvitedMember>(`invitations/${id}/cancel/`, {})).data;
  }

  async userAttributePreferences(queryParam) {
    return (await this.axiosInstance.get<any>(`data/user-attributes/?${queryParam}`)).data;
  }

  async userDetails(userId) {
    return (await this.axiosInstance.get<any>(`data/users/${encodeURIComponent(userId)}/`)).data;
  }

  async getModels() {
    return (await this.axiosInstance.get<Model[]>('data/models/', {})).data;
  }

  async editExports(opportunityId: number, exportId: number, data: EditExportPayload) {
    return await this.axiosInstance.patch(
      `/data/opportunities/${opportunityId}/exports/${exportId}/`,
      data
    );
  }

  async createPQL(
    name: string,
    description: string,
    query: any,
    gtmStrategy: GtmStrategy,
    cohorts: number[] | null,
    performanceTrackEvent: object,
    exports: any
  ) {
    return (
      await this.axiosInstance.post<OpportunityResponse>('data/opportunities/', {
        name,
        description,
        gtmStrategy: !isEmpty(gtmStrategy)
          ? {
              [gtmStrategy.value]: gtmStrategy,
            }
          : {},
        cohorts,
        query,
        exports,
        performanceTrackEvent,
      })
    ).data;
  }

  async updateOpportunity(
    opportunityId: number,
    data: Partial<{
      gtmStrategy?: GtmStrategy;
      name?: string;
      performanceTrackEvent?: string;
    }>
  ) {
    return (await this.axiosInstance.patch(`data/opportunities/${opportunityId}/`, data)).data;
  }

  async accountDetails(accountId) {
    return (await this.axiosInstance.get<any>(`data/accounts/${encodeURIComponent(accountId)}/`))
      .data;
  }

  async accountMeta() {
    return (await this.axiosInstance.get<any>(`data/accounts/meta/`)).data;
  }

  async editUserDetails(id, userDetails) {
    return (await this.axiosInstance.put<UserEntity>(`users/${id}/`, userDetails)).data;
  }

  async changePassword(currentPassword: string, newPassword: string) {
    return this.axiosInstance.post(`auth/password/change/`, { currentPassword, newPassword });
  }

  async getHubspotContactGroupProperty(objectType: string, integrationId: number) {
    return (
      await this.axiosInstance.get(
        `/data/connected-integrations/hubspot-group/${objectType}/${integrationId}/`
      )
    ).data;
  }

  async addIntegrationField(data: AddIntegrationFieldRequest) {
    return (
      await this.axiosInstance.post<any>(`data/connected-integrations/create-property/`, data)
    ).data;
  }

  async logout(data: LogoutData) {
    return await this.axiosInstance.post<LogoutResponse>(`auth/logout/`, data);
  }

  async getMemberAPIDetails() {
    return (await this.axiosInstance.get<TokenResponse[]>('/api-token/')).data;
  }

  async googleAuth(code: string, hubspotCookie, invitationToken?: string) {
    return (
      await this.axiosInstance.post<LoginResponse>(
        `auth/login/google`,
        {
          code,
          invitationToken,
          hubspotCookie,
        },
        {
          headers: {
            Authorization: null,
          },
        }
      )
    ).data;
  }

  async updateOrganization(schemaName: string, data: Partial<Organization>) {
    return (
      await this.axiosInstance.patch<Organization>(`organizations/${schemaName}/`, data, {
        headers: {
          'TL-ORG': schemaName,
        },
      })
    ).data;
  }

  async getOrganizationDetails(schemaName: string) {
    return (
      await this.axiosInstance.get<Organization & Record<string, any>>(
        `organizations/${schemaName}/`
      )
    ).data;
  }

  async getCohorts() {
    return (await this.axiosInstance.get(`data/cohort/`)).data;
  }

  async getCohortCounts(data: CohortCountRequest, isPrefetching = false) {
    const customHeaders: CustomHeader = isPrefetching ? { 'is-prefetching': isPrefetching } : {};
    return (
      await this.axiosInstance.post(`data/cohort/count/`, data, {
        headers: customHeaders,
      })
    ).data;
  }

  // modify activation_status of entity and models
  async modifyModelActivationStatus(modelId: string, activationStatus: ActivationStatus) {
    return (
      await this.axiosInstance.patch(`data/models/${modelId}/`, {
        activation_status: activationStatus,
      })
    ).data;
  }

  async modifyEntityActivationStatus(entityId: string, activationStatus: ActivationStatus) {
    return (
      await this.axiosInstance.patch(`data/entities/${entityId}/`, {
        activation_status: activationStatus,
      })
    ).data;
  }

  async getGtmStrategies() {
    return (await this.axiosInstance.get(`data/gtm-strategy/`)).data;
  }

  async getHistogram(params: HistogramParams, isPrefetching = false) {
    const { goal, type, modelId } = params;
    const customHeaders: CustomHeader = isPrefetching ? { 'is-prefetching': isPrefetching } : {};
    const { data } = await this.axiosInstance.post<MetaResponse>(
      'data/meta/',
      {
        goal,
        type,
        modelId,
      },
      { headers: customHeaders }
    );

    return data;
  }

  async getAllOrganizations(active: boolean) {
    let searchParams = new URLSearchParams();
    if (active) {
      searchParams.append('is_active', 'true');
    }
    return (await this.axiosInstance.get(`organizations/?${searchParams.toString()}`)).data;
  }

  async getEntities() {
    return (await this.axiosInstance.get<EntityResponse[]>('data/entities/')).data;
  }

  async getEntitiesQuery(entity: string, isPrefetching = false) {
    const customHeaders: CustomHeader = isPrefetching ? { 'is-prefetching': isPrefetching } : {};
    return (
      await this.axiosInstance.get<EntityResponse>(`data/entities/${entity}/`, {
        headers: customHeaders,
      })
    ).data.fields;
  }

  async getRecommendedFilters(args: RecommendedFilterArgs) {
    return (
      await this.axiosInstance.post<RecommendedFiltersResponse>(
        'data/opportunities/recommended-filters/',
        {
          ...args,
        }
      )
    ).data.results;
  }

  async getFormDropDown(sourceUrl: string, options: Array<string>) {
    if (!sourceUrl) {
      return options.map((option) => {
        return { label: option, name: option };
      });
    }
    return (await this.axiosInstance.get<EntityResponse>(sourceUrl)).data;
  }

  async getAccounts(
    page: number,
    field_mapping: Record<string, ColumnType>,
    sortBy: SortBy[],
    query: string,
    filterExpression?: FilterExpression[]
  ) {
    const payload = {
      offset: (page - 1) * 50,
      limit: 50,
      fieldMapping: Object.values(field_mapping),
      filterExpression: filterExpression || [],
      sortBy: sortBy,
      searchQuery: query ?? undefined,
    };

    const { data } = await this.axiosInstance.post<Accounts>(
      'data/entities/accounts/query/',
      payload
    );

    return data;
  }

  async getUsers(
    page: number,
    field_mapping: Record<string, ColumnType>,
    sortBy: SortBy[],
    query: string,
    filterExpression?: FilterExpression[],
    limit = 50
  ) {
    const payload = {
      offset: (page - 1) * limit,
      limit,
      fieldMapping: Object.values(field_mapping),
      filterExpression: filterExpression,
      sortBy: sortBy,
      searchQuery: query ?? undefined,
    };

    const { data } = await this.axiosInstance.post<UsersTableDataQuery>(
      'data/entities/users/query/',
      payload
    );

    return data;
  }

  async getSyncedUsers(
    query: string,
    limit = 50,
    sortBy: Array<SortSyncedUsersBy>,
    oppId: string,
    offset: number,
    exportId: string,
    fieldMappings: Record<string, ColumnType>
  ) {
    const baseUrl = `/data/opportunities/${oppId}/synced_users/`;
    const payload = {
      limit,
      offset,
      sortBy,
      search: query ? query : '',
      export_id: exportId,
      field_mapping: Object.values(fieldMappings),
    };

    return (await this.axiosInstance.post(baseUrl, payload)).data;
  }

  async getErrorStats(oppId: string) {
    const baseUrl = `/data/opportunities/${oppId}/errors/`;

    return (await this.axiosInstance.get(baseUrl)).data;
  }

  async modifyPersonaProps(
    /* eslint-disable-next-line   */
    personaProperties: { [key: string]: Array<string> },
    /* eslint-disable-next-line   */
    orgSchemaName: string
  ) {
    // return (await this.axiosInstance.patch(`/organizations/${orgSchemaName}/`, personaProperties))
    //   .data;
    return {};
  }

  async updateUserListPreference(entityType: QueryType, userListPreferences: Array<ColumnType>) {
    return (
      await this.axiosInstance.patch(`/data/user-list-preference/${entityType.toLowerCase()}/`, {
        properties: userListPreferences,
      })
    ).data;
  }

  async getUserListPreference() {
    return (await this.axiosInstance.get(`/data/user-list-preference/`)).data;
  }

  async sendJoinRequest(data: JoinRequest) {
    return (await this.axiosInstance.post(`join-requests/send/`, data)).data;
  }

  async getExistingJoinRequest() {
    return (await this.axiosInstance.get<ExistingOrgResponse[]>(`join-requests/existing/`)).data;
  }

  async approveJoinRequest(data: GrantAccessData) {
    return (await this.axiosInstance.post(`join-requests/approve/`, data)).data;
  }

  async getJoinRequests() {
    return (await this.axiosInstance.get<{ email: string }[]>(`join-requests/`)).data;
  }

  async rejectJoinRequest(data: RejectJoinRequestData) {
    return (await this.axiosInstance.post(`join-requests/reject/`, data)).data;
  }

  async resendVerificationEmail(email: string) {
    return (await this.axiosInstance.post(`auth/registration/resend-email/`, { email })).data;
  }

  async verifyEmail(token: string) {
    return (await this.axiosInstance.post(`auth/registration/verify-email/`, { key: token })).data;
  }

  async getPlaybooksByDestination(destinationId: string | number) {
    return (
      await this.axiosInstance.get(
        `/data/connected-integrations/get-integration-opportunities/${destinationId}/`
      )
    ).data;
  }

  async bulkStopPlaybooks(data: BulkStopPlaybooksData) {
    return (await this.axiosInstance.post(`/data/opportunities/stop-opportunities/`, data)).data;
  }

  async getSandboxDatasets() {
    return (await this.axiosInstance.get<SandboxDatabaseResponse[]>(`/sandbox/`)).data;
  }

  async setupSandbox(schemaName: string, databaseName: string, timezone: string) {
    return (
      await this.axiosInstance.post<OrganizationDetails>(`/sandbox/setup/`, {
        databaseName,
        schemaName,
        timezone,
      })
    ).data;
  }

  async createSandbox(data) {
    return (
      await this.axiosInstance.post('/sandbox/', data, {
        headers: {
          'TL-ORG': undefined,
        },
      })
    ).data;
  }

  async getOpportunitiesStatus(noOfDays: number, performanceStatsPayload: object, status: string) {
    return (
      await this.axiosInstance.post(`/data/opportunities/multiple-pql-performances/`, {
        days: noOfDays,
        status,
        performanceQuery: performanceStatsPayload,
      })
    ).data;
  }
}

const ApiClientContext = createContext<AxiosInstance | null>(null);

export const ApiContextProvider = ({ children }) => {
  const { getAccountSchemaName } = useAuthStore();

  const refreshMutation = useRefresh();

  let refreshTokenRequest = useRef(null);

  function refreshAuthToken() {
    if (!refreshTokenRequest.current) {
      refreshTokenRequest.current = refreshMutation.mutateAsync();
      /**
       * clear the promise incase of success or failure
       */
      refreshTokenRequest.current.finally(resetRefreshTokenRequest);
    }

    return refreshTokenRequest.current;
  }

  function resetRefreshTokenRequest() {
    refreshTokenRequest.current = null;
  }

  function onError() {
    if (!toastify.isActive(NETWORK_ERR_TOAST)) {
      toastify(<ErrorToast />, {
        type: 'error',
        toastId: NETWORK_ERR_TOAST,
      });
    }
  }

  const client = createClient(getAccountSchemaName(), refreshAuthToken, onError);

  return <ApiClientContext.Provider value={client}>{children}</ApiClientContext.Provider>;
};

export const useApiClient = (publicApi?: boolean) => {
  const client = useContext(ApiClientContext);
  const { getAuthToken } = useAuthStore();
  if (publicApi) {
    client.defaults.headers.common.Authorization = undefined;
  } else {
    const authToken = getAuthToken();
    client.defaults.headers.common.Authorization = authToken ? `Bearer ${authToken}` : undefined;
  }

  return client;
};

export function useClient(publicApi?: boolean) {
  const client = useApiClient(publicApi);

  return useMemo(() => new ApiClient(client), [client]);
}
