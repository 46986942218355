// TODO: Revisit. Something went wrong should pop up on error
import { AxiosInstance } from 'axios';
import { useQuery } from 'react-query';
import { useApiClient } from '../../../api-client';

type HistogramParams = {
  source: string;
  keySpace: string;
  keyName: string;
  goal: string;
  type: string;
  modelId: string;
  columnName?: string;
};

async function getHistogram(apiClient: AxiosInstance, params: HistogramParams) {
  const { source, keyName, keySpace, goal, type, modelId, columnName } = params;
  const { data } = await apiClient.post('data/histogram/', {
    query: {
      source,
      keySpace,
      keyName,
      goal,
      type,
      modelId,
      columnName,
    },
  });

  return data;
}

export default function useHistogram(params: HistogramParams, enabled?: boolean) {
  const client = useApiClient();
  return useQuery(
    ['histogram', params.keyName, params.type, params.modelId, params.goal],
    () => getHistogram(client, params),
    {
      enabled: enabled ?? false,
    }
  );
}
