import useSendJoinRequest from '../../hooks/useSendJoinRequest';
import useExistingJoinRequest from '../../hooks/useExistingJoinRequest';
import { PrimaryButton } from '../inputs/Buttons';
import ToolTip from '../data-display/Tooltip';
import { CrossIcon } from '../data-display/Icons';
import { SimilarOrganization } from 'api-client';
import { useAuth } from 'hooks/useAuth';
import { useState } from 'react';
import OrgSlider from './OrgSlider';

export default function RequestToJoinOrg({ CTA, handleClickCTA }) {
  const { user } = useAuth();
  const sendJoinRequest = useSendJoinRequest();
  const { data: existingRequests } = useExistingJoinRequest();
  const [seeMoreOpen, setSeeMoreOpen] = useState(false);

  const [activeOrg, setActiveOrg] = useState({} as SimilarOrganization);

  const handleResendJoinRequest = () => {
    sendJoinRequest.mutate({
      organizationSchemaName: existingRequests[0]?.schemaName,
      isResend: true,
    });
  };

  const handleSendJoinRequest = () => {
    sendJoinRequest.mutate({
      organizationSchemaName: activeOrg.schemaName,
      isResend: false,
    });
  };

  const handleSetActiveOrg = (org: SimilarOrganization) => {
    setActiveOrg({ ...org });
  };
  return (
    <>
      {!existingRequests?.length && (
        <>
          <div className="flex flex-col items-center justify-center h-full">
            <h2 className="mb-6 text-h2">
              We found {user?.similarOrganizations?.length} workspace
              {user?.similarOrganizations?.length > 1 && 's'} from your organisation
            </h2>
            <OrgSlider orgs={user?.similarOrganizations} handleSetActiveOrg={handleSetActiveOrg} />
            <PrimaryButton
              className="mt-6 w-80"
              isLoading={sendJoinRequest.isLoading}
              onClick={handleSendJoinRequest}
            >
              Request to join this team
            </PrimaryButton>
            <p className="mt-4 text-tw-black-9">OR</p>
            <button
              onClick={handleClickCTA}
              className="mt-4 text-base font-semibold text-tw-blue-0d"
            >
              {CTA}
            </button>
          </div>
        </>
      )}
      {existingRequests?.[0]?.schemaName && (
        <div className="flex flex-col items-center justify-center h-full">
          <h2 className="text-h2">We have sent your request to the admin 🚀</h2>
          <p className="mt-3 text-tw-black-9">
            We will email you when it’s approved. You can also contact the mentioned
          </p>
          <p className="text-tw-black-9">people to get it approved faster</p>
          <div className="flex items-center mt-9 gap-x-0.5">
            <p>Request sent to: {existingRequests[0].adminEmails.slice(0, 2).join(', ')}</p>
            {existingRequests[0].adminEmails.length > 2 && (
              <div>
                <span>, </span>
                <ToolTip
                  open={seeMoreOpen}
                  content={
                    <div className="p-4 bg-white border rounded-lg shadow-lg w-96 border-tw-gray-eb">
                      <div className="flex items-center justify-between">
                        <h3 className="font-medium text-tw-black-1">Request sent list</h3>
                        <CrossIcon
                          onClick={() => setSeeMoreOpen(false)}
                          className="w-3 cursor-pointer fill-current text-tw-black-9"
                        />
                      </div>
                      <div className="mt-3 text-xs">
                        {existingRequests[0].adminEmails.join(', ')}
                      </div>
                      <div></div>
                    </div>
                  }
                >
                  <span
                    onClick={() => setSeeMoreOpen(!seeMoreOpen)}
                    className="cursor-pointer text-tw-blue-0d"
                  >
                    See more
                  </span>
                </ToolTip>
              </div>
            )}
          </div>

          <PrimaryButton
            className="mt-6 w-80"
            onClick={handleResendJoinRequest}
            isLoading={sendJoinRequest.isLoading}
          >
            Resend request
          </PrimaryButton>
          <p className="mt-4 text-tw-black-9">OR</p>
          <button onClick={handleClickCTA} className="mt-4 text-base font-semibold text-tw-blue-0d">
            Create a new account
          </button>
        </div>
      )}
    </>
  );
}
