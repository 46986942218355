import { FC } from 'react';
import { CORRELATION } from '../../../../pages/opportunities/types';
import { BooleanFilterPopover } from './BooleanFilterPopover';
import CategoryFilterDropdown from './CategoryFilterDropdown';
import { DuringFilterPopover, InTheLastFilterPopover } from './DateFilterPopover';
import { BetweenFilterPopover, OtherThanBetweenFilterPopover } from './IntegerFilterPopover';
import {
  ContainsAndDoesNotContainFilterPopover,
  EqAndNEFilterPopover,
  EqAndNEFilterPopoverForHighlyCardinalCorrelation,
} from './StringFilterPopover';

export interface PopoverContentProps {
  comparatorMap?: { [key: string]: string };
  setComparator?: (event) => void;
  comparatorType?: string;
  isLoading?: boolean;
  dataType?: string;
  filtersObj?: Record<string, boolean>;
  setFiltersObj?: any;
  rowBins?: string[];
  setValue?: (value: string) => void;
  comparatorValue?: string;
  setComparatorValue?: (comparatorValue: string) => void;
  startComparatorValue?: string;
  setStartComparatorValue?: (startComparatorValue: string) => void;
  endComparatorValue?: string;
  setEndComparatorValue?: (endComparatorValue: string) => void;
  setLocalSearchBin?: (localSearchBin: string[]) => void;
  allSelected?: boolean;
  setAllSelected?: any;
  localSearchBin?: string[];
  selectedBoolValue?: string;
  setSelectedBoolValue?: (selectedBoolValue: string) => void;
  stringComparator?: string;
  integerComparator?: string;
  isComparatorChanged?: boolean;
  dateComparator?: string;
  startDateValue?: number | Date;
  setStartDateValue?: (startDateValue: number | Date) => void;
  endDateValue?: number | Date;
  setEndDateValue?: (endDateValue: number | Date) => void;
  relativeTimeOption?: string;
  setRelativeTimeOption?: (relativeTimeOption: string) => void;
  duringSelectionRange?: {
    startDate: Date;
    endDate: Date;
    key: string;
  };
  handleInputDate?: any;
  className?: string;
  stringValues?: any[];
  setStringValues?: any;
  correlation?: string;
  setButtonDisabled?: (value: boolean) => void;
}

export const PopoverContent: FC<PopoverContentProps> = (props) => {
  const { comparatorMap, setComparator, comparatorType } = props;

  return (
    <>
      <div className="px-3 my-2 cursor-pointer">
        <CategoryFilterDropdown
          options={Object.keys(comparatorMap).map((o) => {
            return {
              label: comparatorMap[o],
              value: o,
            };
          })}
          onChange={(e: any) => setComparator(e)}
          value={comparatorType}
        />
      </div>
      {!['null', 'notnull'].includes(comparatorType) && <PopoverOptions {...props} />}
    </>
  );
};

export const PopoverOptions: FC<PopoverContentProps> = (props) => {
  const { dataType, integerComparator, stringComparator, dateComparator, correlation } = props;

  return (
    <>
      {correlation === CORRELATION.HIGHLYCARDINAL ? (
        <div className="px-3">
          <EqAndNEFilterPopoverForHighlyCardinalCorrelation {...props} />
        </div>
      ) : (
        <>
          {(dataType.includes('int') || dataType.includes('float')) && (
            <div className="px-3">
              {integerComparator === 'between' ? (
                <BetweenFilterPopover {...props} />
              ) : (
                <OtherThanBetweenFilterPopover {...props} />
              )}
            </div>
          )}
          {dataType.includes('str') &&
            (['contains', 'doesnotcontain'].includes(stringComparator) ? (
              <ContainsAndDoesNotContainFilterPopover {...props} />
            ) : (
              <EqAndNEFilterPopover {...props} />
            ))}
          {dataType.includes('bool') && <BooleanFilterPopover {...props} />}
          {dataType.includes('timestamp') && (
            <div className="px-3">
              {dateComparator === 'inthelast' ? (
                <InTheLastFilterPopover {...props} />
              ) : (
                <DuringFilterPopover {...props} className="p-3 border-tw-gray-ed" />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};
