import { useAuth } from 'hooks/useAuth';
import ChooseSandboxDataModal from 'pages/sandbox-setup/ChooseSandboxDataModal';
import { useEffect, useState } from 'react';

export default function ChooseSandboxDataset() {
  const [sandboxModalOpen, setSandboxModalOpen] = useState(false);
  const { shouldFireQueries } = useAuth();

  useEffect(() => {
    if (!shouldFireQueries) {
      setSandboxModalOpen(true);
    }
  }, [shouldFireQueries]);

  return (
    <ChooseSandboxDataModal open={sandboxModalOpen} onClose={() => setSandboxModalOpen(false)} />
  );
}
