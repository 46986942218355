import { noop } from 'lodash';
import * as React from 'react';
import { classNames } from '../../utils/common';

type CustomCheckBoxProps = {
  checkedValue: boolean;
  value: string;
  clickEvent: (value: string) => void;
  labelClassName?: string;
  disabled?: boolean;
  checkboxClassname?: string;
};

export const CustomCheckBox: React.FC<CustomCheckBoxProps> = ({
  checkedValue,
  value,
  clickEvent,
  labelClassName,
  disabled,
  checkboxClassname,
}) => {
  return (
    <div
      className={classNames(
        'flex items-center',
        disabled ? 'disabled cursor-not-allowed' : 'cursor-pointer',
        checkboxClassname
      )}
      onClick={() => {
        clickEvent(value);
      }}
    >
      <input
        type="checkbox"
        name={value}
        value={value}
        className={classNames(
          'w-4 h-4 flex-none bg-checkbox-unchecked checked:bg-checkbox-checked bg-center bg-no-repeat',
          disabled ? 'disabled cursor-not-allowed' : 'cursor-pointer'
        )}
        disabled={disabled}
        checked={checkedValue === true}
        onChange={() => noop()}
      />
      <label
        className={classNames(
          'ml-2.5 mt-0.5 mb-0 text-xs text-tw-black-5 truncate',
          disabled ? 'disabled cursor-not-allowed' : 'cursor-pointer',
          value === 'Select All' ? `` : `${labelClassName || `capitalize`}`
        )}
      >
        {value}
      </label>
    </div>
  );
};

CustomCheckBox.defaultProps = {
  disabled: false,
  checkboxClassname: '',
};
