import { yupResolver } from '@hookform/resolvers/yup';
import { InfoIcon } from 'ui-components/data-display/Icons';
import { PrimaryButton, TertiaryButton } from 'ui-components/inputs/Buttons';
import { ErrorBoundary } from 'react-error-boundary';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import ErrorFromHook from '../../../ui-components/feedback/ErrorFromHook';
import { PasswordInput } from '../../../ui-components/inputs/Inputs';
import { passwordRegExp, passwordValidationString } from '../../../utils/common';
import useChangePassword from '../hooks/useChangePassword';
import { ErrorAnalyticsEvents } from 'telemetry/constants';
import ErrorBoundaryFallback from 'ui-components/feedback/ErrorBoundaryFallback';
import { useAnalytics } from 'telemetry';

type ChangePasswordFormState = {
  currentPassword: string;
  newPassword: string;
  confirmedPassword: string;
};

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().min(8).required('Current password is required'),
  newPassword: Yup.string()
    .required('New password is required')
    .matches(passwordRegExp, passwordValidationString),
  confirmedPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords do not match')
    .required('Password is required'),
});

const ChangePassword = () => {
  const router = useHistory();
  const changePasswordHook = useChangePassword();
  const { track } = useAnalytics();

  const onSubmit = (data: ChangePasswordFormState) => {
    var resetData = {
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
    };
    changePasswordHook.mutate(resetData);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={(e) =>
        track(ErrorAnalyticsEvents.ERROR_OCCURED, {
          error: e.message,
        })
      }
    >
      <div className="grid content-start h-full py-8" data-testId="changepassword-component">
        <div className="relative w-1/2 py-4 mx-auto bg-white border rounded-lg border-tw-gray-eb h-80vh">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-8">
              <h3 className="text-xl">Changing password</h3>
              <hr className="my-4 border-b border-tw-gray-eb" />
              {changePasswordHook.isError &&
                changePasswordHook['error']['response']['data']['newPassword'] && (
                  <ErrorFromHook>
                    {changePasswordHook['error']['response']['data']['newPassword']}
                  </ErrorFromHook>
                )}
              <div className="mt-11">
                <label className={'font-normal text-sm text-tw-black-5 mt-8 mb-2'}>
                  Current Password
                </label>
                <PasswordInput
                  className="w-full py-3"
                  autoFocus
                  id="currentPassword"
                  {...register('currentPassword')}
                  isError={errors.currentPassword}
                  data-testid="currentPassword"
                />
                {errors.currentPassword?.message && (
                  <div className="flex flex-row mt-2">
                    <InfoIcon className="w-4 mr-2 fill-current text-tw-red-dd" />
                    <p className="text-xs text-tw-red-dd">{errors.currentPassword?.message}</p>
                  </div>
                )}

                <label className={'font-normal text-sm text-tw-black-5 mt-8 mb-2'}>
                  New Password
                </label>
                <PasswordInput
                  className="w-full py-3"
                  id="newPassword"
                  {...register('newPassword')}
                  isError={errors.newPassword}
                  data-testid="newPassword"
                />
                <div className="flex flex-row mt-2">
                  <div>
                    <InfoIcon
                      className={`mr-2 w-4 ${
                        errors.newPassword?.message
                          ? `fill-current text-tw-red-dd`
                          : `fill-current text-tw-black-9`
                      }`}
                    />
                  </div>
                  <div>
                    <p
                      className={`text-tw-black-9 text-xs ${
                        errors.newPassword?.message ? `text-tw-red-dd` : ``
                      }`}
                    >
                      {errors.newPassword?.message
                        ? errors.newPassword?.message
                        : passwordValidationString}
                    </p>
                  </div>
                </div>

                <label className={'font-normal text-sm text-tw-black-5 mt-8 mb-2'}>
                  Confirm new Password
                </label>
                <PasswordInput
                  className="w-full py-3"
                  id="confirmedPassword"
                  {...register('confirmedPassword')}
                  isError={errors.confirmedPassword}
                  data-testid="confirmedPassword"
                />
                {errors.confirmedPassword?.message && (
                  <div className="flex flex-row mt-2">
                    <InfoIcon className="w-4 mr-2 fill-current text-tw-red-dd" />
                    <p className="text-xs text-tw-red-dd">{errors.confirmedPassword?.message}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="absolute w-full px-8 bg-white bottom-4">
              <hr className="border-b border-tw-gray-eb" />
              <div className="flex justify-end mt-5">
                <TertiaryButton type="button" onClick={() => router.goBack()}>
                  Cancel
                </TertiaryButton>
                <PrimaryButton
                  type="submit"
                  disabled={changePasswordHook.isLoading}
                  className="ml-2"
                  data-testid="submitButton"
                >
                  Save changes
                </PrimaryButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ChangePassword;
