import { toast as toastify } from 'react-toastify';
import { TickIconFilled } from 'ui-components/data-display/Icons';
import { PrimaryButton, TertiaryButton } from 'ui-components/inputs/Buttons';
import InitialsAvatar from 'ui-components/data-display/InitialsAvatar';
import { Field, Form, Formik } from 'formik';
import { ErrorBoundary } from 'react-error-boundary';
import { avatars } from 'utils/globalTypes';
import { useAuth } from 'hooks/useAuth';
import { useAuthStore } from 'hooks/useAuthStore';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import useEditUserDetails from '../../../hooks/useEditUserDetails';
import SuccessToast from 'ui-components/feedback/Toasts/SuccessToast';
import { ErrorAnalyticsEvents } from 'telemetry/constants';
import ErrorBoundaryFallback from 'ui-components/feedback/ErrorBoundaryFallback';
import { useAnalytics } from 'telemetry';

interface EditProfileProps {}

type FormData = {
  email: string;
  firstName?: string;
  lastName?: string;
  avatarName: string;
};

const EditProfile: React.FC<EditProfileProps> = () => {
  const router = useHistory();
  const { user: data, isLoading, isError } = useAuth();
  const { getAccountId } = useAuthStore();

  const { track } = useAnalytics();

  const validationSchema = Yup.object().shape({
    avatarName: Yup.string().nullable(),
    firstName: Yup.string().nullable(),
    lastName: Yup.string().nullable(),
    email: Yup.string().email().nullable(),
  });

  const editUserDetails = useEditUserDetails();

  const handleSubmit = (formData: FormData) => {
    const updatedData = {
      id: data.id,
      userDetails: formData,
    };
    editUserDetails.mutate(updatedData, {
      onSuccess: () => {
        toastify(<SuccessToast description="Updated Successfully" />, {
          type: 'success',
        });
        router.push(`/${getAccountId()}/settings/member-profile`);
      },
    });
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={(e) =>
        track(ErrorAnalyticsEvents.ERROR_OCCURED, {
          error: e.message,
        })
      }
    >
      <div className="grid content-start min-h-full py-8">
        <div className="relative w-1/2 py-4 mx-auto bg-white border rounded-lg border-tw-gray-eb h-80vh">
          {!isLoading && !isError && data && (
            <Formik
              initialValues={{
                avatarName: data.avatarName,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
              }}
              validationSchema={validationSchema}
              onSubmit={(formData) => {
                handleSubmit(formData);
              }}
            >
              {({ errors, values, handleChange, setFieldValue, dirty, touched }) => {
                return (
                  <Form>
                    <div className="px-8 pb-10 max-h-70 sm:overflow-y-scroll lg:overflow-y-auto">
                      <h3 className="text-xl">Editing profile</h3>
                      <hr className="my-4 border-b border-tw-gray-eb" />
                      <div className="mt-5 form-group">
                        <div id="checkbox-group" className={'font-normal text-sm text-tw-black-5'}>
                          Avatar
                        </div>
                        <div
                          role="group"
                          aria-labelledby="checkbox-group"
                          className="flex mt-4 mb-8 space-x-3"
                        >
                          <label className="relative">
                            <Field
                              type="checkbox"
                              name="avatarName"
                              as="div"
                              value={'initials'}
                              onClick={() => {
                                setFieldValue('avatarName', 'initials');
                              }}
                              className={'h-16 w-16'}
                            >
                              <InitialsAvatar user={data} />
                            </Field>
                            {values.avatarName === 'initials' && (
                              <TickIconFilled className="absolute top-0 right-0 fill-current text-tw-blue-0d" />
                            )}
                          </label>
                          {avatars.map((avatar) => (
                            <label key={avatar.name} className="relative">
                              <Field
                                type="checkbox"
                                name="avatarName"
                                value={avatar.name}
                                as="img"
                                src={avatar.url}
                                alt={avatar.name}
                                onClick={() => {
                                  setFieldValue('avatarName', avatar.name);
                                }}
                                className={`h-16 w-16 border-1 border-solid cursor-pointer ${
                                  values.avatarName === avatar.name
                                    ? `border-tw-blue-0d`
                                    : `border-tw-gray-e7`
                                } rounded-full`}
                              />
                              {values.avatarName === avatar.name && (
                                <TickIconFilled className="absolute top-0 right-0 fill-current text-tw-blue-0d" />
                              )}
                            </label>
                          ))}
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="firstName"
                          className={'font-normal text-sm text-tw-black-5 mb-2'}
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          className={`block w-full py-3.5 px-4 rounded border-1 border-solid border-tw-gray-c text-tw-black-3 ${
                            touched.firstName && errors.firstName ? `border-tw-red-dd` : ``
                          }`}
                          name="firstName"
                          id="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          aria-describedby="firstNameHelp"
                        />
                        {touched.firstName && errors.firstName && (
                          <p className="mt-1 text-xs text-tw-red-dd">{errors.firstName}</p>
                        )}
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="lastName"
                          className={'font-normal text-sm text-tw-black-5 mb-2'}
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          className={`block w-full py-3.5 px-4 rounded border-1 border-solid border-tw-gray-c text-tw-black-3 ${
                            touched.lastName && errors.lastName ? `border-tw-red-dd` : ``
                          }`}
                          name="lastName"
                          id="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          aria-describedby="lastNameHelp"
                        />
                        {touched.lastName && errors.lastName && (
                          <p className="mt-1 text-xs text-tw-red-dd">{errors.lastName}</p>
                        )}
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="email"
                          className={'font-normal text-sm text-tw-black-5 mb-2'}
                        >
                          Work email
                        </label>
                        <input
                          type="email"
                          disabled
                          className={`block w-full py-3.5 px-4 rounded border-1 border-solid border-tw-gray-c bg-tw-gray-eb text-tw-black-3 ${
                            touched.email && errors.email ? `border-tw-red-dd` : ``
                          }`}
                          name="email"
                          id="email"
                          value={values.email}
                          onChange={handleChange}
                          aria-describedby="emailHelp"
                        />
                        {touched.email && errors.email && (
                          <p className="mt-1 text-xs text-tw-red-dd">{errors.email}</p>
                        )}
                      </div>
                    </div>
                    <div className="absolute w-full px-8 bg-white bottom-4">
                      <hr className="border-b border-tw-gray-eb" />
                      <div className="flex justify-end mt-5">
                        <TertiaryButton type="button" onClick={() => router.goBack()}>
                          Cancel
                        </TertiaryButton>
                        <PrimaryButton
                          isLoading={editUserDetails.isLoading}
                          type="submit"
                          disabled={!dirty}
                          className="ml-2 w-36"
                        >
                          Save changes
                        </PrimaryButton>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default EditProfile;
