import Modal from 'ui-components/util/modal';
import { useAuth } from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { filterAsync } from 'utils/helpers';
import AttributesList from '../../../../ui-components/business/attributes-modal/AttributesList';
import useEntitiesQuery from '../../../../hooks/useEntitiesQuery';
import { Entity } from '../types';
import { QueryType } from 'stores/QueryStore';
import { ColumnType } from 'pages/users-and-accounts/store/AccountsStore';

type Section = {
  name: string;
  value: string;
};

const sections: Section[] = [
  { name: 'Profile Properties', value: 'profile_properties' },
  { name: 'Profile Events', value: 'profile_events' },
];

// For User attributes modal, you need to pass the store because there are
// two components users-list and users/users-list/User and Accounts/user-details/users-list
// which reuse this component but have two different data stores.
export default function UserAttributesModal({
  isOpen,
  setIsOpen,
  field_mapping,
  applySelectedAttribute,
  defaultSelectedColumns = [],
}) {
  // page query
  const { data, isLoading } = useEntitiesQuery(Entity.users);
  const { isLoading: userDataLoading } = useAuth();

  // toggles current section
  const [currentSection, setCurrentSection] = useState(sections[0]);
  // handles loading while computation
  const [loadingModal, setLoadingModal] = useState(false);

  // represents the current space (events/properties)
  const [datasets, setDatasets] = useState([]);
  const [selectedDatasets, setSelectedDatasets] = useState([]);
  useEffect(() => {
    let didCancel = false;

    async function filterProperties(givenDatasets: any[]) {
      return Promise.resolve(
        filterAsync(givenDatasets, currentSection.value, field_mapping, defaultSelectedColumns)
      ).then((d: any) => {
        setSelectedDatasets(d.selected);
        setDatasets(d.notSelected);
      });
    }

    // filter as per current section value.
    if (!didCancel && isOpen) {
      setLoadingModal(true);
      filterProperties(data).then(() =>
        setTimeout(() => {
          setLoadingModal(false);
        }, 200)
      );
    }

    return () => {
      didCancel = true;
    };
    // do not add field_mapping to the dep list else the
    // mapping will jump once clicked
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection.value, data, isOpen]);

  const changeSection = (value: string) => {
    setCurrentSection(sections.find((sec) => sec.value === value));
  };

  // do not render anything
  // if modal is not open
  if (!isOpen) return null;

  if (currentSection.value === sections[0]?.value) {
    // return profile properties
    return (
      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        title={`${datasets?.length + selectedDatasets.length} + attributes`}
      >
        <AttributesList
          selectedProperties={selectedDatasets}
          notSelectedProperties={datasets}
          field_mapping={field_mapping}
          sections={sections}
          currentSection={currentSection.value}
          changeSection={(section) => changeSection(section)}
          isLoadingModal={loadingModal || isLoading || userDataLoading}
          entityType={QueryType.users}
          applySelectedAttribute={(attr: ColumnType) => applySelectedAttribute(attr)}
          defaultSelectedColumns={defaultSelectedColumns}
        />
      </Modal>
    );
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      title={`${datasets?.length + selectedDatasets.length} attributes`}
    >
      <AttributesList
        selectedProperties={selectedDatasets}
        notSelectedProperties={datasets}
        field_mapping={field_mapping}
        sections={sections}
        currentSection={currentSection.value}
        changeSection={(section) => changeSection(section)}
        isLoadingModal={loadingModal || isLoading || userDataLoading}
        entityType={QueryType.users}
        applySelectedAttribute={(attr: ColumnType) => applySelectedAttribute(attr)}
        defaultSelectedColumns={defaultSelectedColumns}
      />
    </Modal>
  );
}
