import { ArrowRightLong, ToplyneLogo } from 'ui-components/data-display/Icons';
import { PrimaryButton } from 'ui-components/inputs/Buttons';
import ComboBox from 'ui-components/inputs/Combobox';
import { CustomCheckBox } from 'ui-components/inputs/CustomCheckBox';
import { ErrorBoundary } from 'react-error-boundary';
import { useAuth } from 'hooks/useAuth';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import LoginRightBanner from '../../ui-components/business/LoginRightBanner';
import useGetAllOrganizations from './hooks/useGetAllOrganizations';
import useSetDefaultAccount from './hooks/useSetDefaultAccount';
import useValidOrg from './hooks/useValidateOrg';
import { useAnalytics } from 'telemetry';
import { ErrorAnalyticsEvents } from 'telemetry/constants';
import ErrorBoundaryFallback from 'ui-components/feedback/ErrorBoundaryFallback';

type ChooseOrgFormState = {
  accountName: {
    id: any;
    name: string;
  };
};

export default function OrgChooser() {
  const { user } = useAuth();
  const { data: allOrganizations, isLoading } = useGetAllOrganizations();
  const setDefaultAccountMutation = useSetDefaultAccount();
  const { mutateAsync: validateOrg, isLoading: validatingOrg } = useValidOrg();
  const { track } = useAnalytics();

  const [isChecked, setIsChecked] = useState(false);

  const { handleSubmit, control } = useForm({
    defaultValues: {
      accountName: { id: -1, name: '' },
    },
  });

  const onSubmit: SubmitHandler<ChooseOrgFormState> = async (data) => {
    validateOrg(data.accountName.id);
  };

  if (!user) {
    return (
      <div className="grid grid-cols-5">
        <div className="flex flex-col items-center col-span-3 py-8">
          <ToplyneLogo className="h-auto ml-8 w-36 place-self-start" />
          <div className="w-3/6 mt-24">
            <div className="w-full h-10 mb-4 bg-tw-gray-eb animate-pulse"></div>
          </div>
          <div className="w-3/6 mt-2">
            <div className="w-full h-10 mb-4 bg-tw-gray-eb animate-pulse"></div>
          </div>
        </div>
        <div className="col-span-2">
          <LoginRightBanner />
        </div>
      </div>
    );
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={(e) =>
        track(ErrorAnalyticsEvents.ERROR_OCCURED, {
          error: e.message,
        })
      }
    >
      <div className="grid grid-cols-5">
        <div className="flex flex-col items-center col-span-3 py-8">
          <ToplyneLogo className="h-auto ml-8 w-36 place-self-start" />
          <h5 className="mt-24 text-3.5xl">
            {user.isStaff ? 'Enter account name to proceed 🙈' : 'Choose an account to proceed 🙈'}
          </h5>
          <div className="w-1/2">
            {user.isStaff && !isLoading && (
              <>
                <form className="flex flex-col mt-9" onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    name={'accountName'}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <ComboBox
                        field={field}
                        placeholder='Account name, eg. "canva"'
                        data={allOrganizations}
                      />
                    )}
                  />
                  <PrimaryButton className="py-3 mt-6" isLoading={validatingOrg}>
                    Proceed
                  </PrimaryButton>
                </form>
              </>
            )}
            {!user.isStaff && (
              <>
                <ul className="space-y-4 mt-9">
                  {user?.organizations?.map((org, index) => (
                    <li
                      key={index}
                      className="flex items-center justify-between w-full p-4 rounded cursor-pointer text-tw-black-3 border-tw-gray-eb border-1"
                      onClick={async () => {
                        if (isChecked) {
                          await setDefaultAccountMutation.mutateAsync({
                            organizationSchemaName: org.schemaName,
                          });
                        }
                        validateOrg(org.schemaName);
                      }}
                    >
                      <span className="text-base font-medium">{org.name}</span>
                      <ArrowRightLong className="w-4 fill-current" />
                    </li>
                  ))}
                </ul>
                <div className="mt-4">
                  <CustomCheckBox
                    labelClassName="normal-case"
                    checkedValue={isChecked}
                    clickEvent={() => setIsChecked(!isChecked)}
                    value={`Set this account as my default account`}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col-span-2">
          <LoginRightBanner />
        </div>
      </div>
    </ErrorBoundary>
  );
}
