import { useEffect, useState } from 'react';
import * as React from 'react';
import { CustomOptions, Options } from '../DropDownMenu';
import { Listbox } from '@headlessui/react';
import { ReactComponent as DropdownArrowIcon } from '../../../../assets/images/dropdown-arrow.svg';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { classNames } from '../../../../utils/common';

interface SelectDropdownProps {
  onChange: any;
  value: string | number | Date;
  customOptions?: CustomOptions;
  isLoading?: boolean;
  options?: Options;
  size?: string;
  placeholder?: string;
  selectBtnWidth?: string;
  id?: string;
  isError?: boolean;
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  value,
  onChange,
  options: optionsArray,
  customOptions,
  isLoading,
  placeholder,
  selectBtnWidth,
  isError,
  id,
  ...rest
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null | number | Date>(
    optionsArray[0].label
  );

  useEffect(() => {
    if (value && selectedOption !== value && Array.isArray(optionsArray)) {
      const label = optionsArray?.find((option) => option.value === value)?.label || '';
      setSelectedOption(label);
    }
    if (value && selectedOption !== value && customOptions) {
      setSelectedOption(value);
    }
  }, [value, optionsArray, selectedOption, customOptions]);

  return (
    <Listbox value={selectedOption} onChange={onChange}>
      <DropdownMenuPrimitive.Root>
        <DropdownMenuPrimitive.Trigger
          id={id}
          {...rest}
          className={classNames(
            'flex items-center justify-between px-3 py-3 rounded-md cursor-pointer focus:outline-none border-1 border-tw-gray-eb text-xs',
            selectBtnWidth ? `${selectBtnWidth}` : 'w-full',
            isError ? 'border-tw-red' : 'border-tw-gray-eb',
            !value ? 'text-tw-black-9' : 'text-tw-black-3'
          )}
        >
          <div>{!value ? placeholder : selectedOption}</div>
          <DropdownArrowIcon className="fill-current text-tw-black-7 inline-block ml-2" />
        </DropdownMenuPrimitive.Trigger>
        <DropdownMenuPrimitive.Content className="p-4 mt-1 overflow-auto bg-white rounded shadow-lg focus:outline-none max-h-72">
          <DropdownMenuPrimitive.Item className="list-none space-y-3">
            {!isLoading &&
              optionsArray &&
              optionsArray.map((option) => (
                <Listbox.Option
                  className="text-xs text-tw-black-5 cursor-pointer"
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </Listbox.Option>
              ))}
          </DropdownMenuPrimitive.Item>
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Root>
    </Listbox>
  );
};

export default SelectDropdown;
