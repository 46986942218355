import { QueryGoal } from 'stores/QueryStore';
import { ACCOUNT_ID } from '../../utils/globalTypes';

export interface GetAccordinsResponse {
  sourceMeta: SourceMeta;
}

export interface GetExportFileds {
  exportFieldList: ExportFieldList;
}

export interface ExportFieldList {
  success: boolean;
  data: ExportFieldEntity[];
}

export interface ExportFieldEntity {
  SOURCE: string;
  KEYSPACE: string;
  KEYNAME: string;
  DISPLAY_NAME: string;
  IS_SYSTEM: boolean;
}

export interface SourceMeta {
  success: boolean;
  data?: AccordionInfo[];
}

export interface AccordionInfo {
  DISPLAY_NAME: string;
  SOURCE: string;
  KEYSPACE: string;
  KEYNAME: string;
  WEIGHT: number;
  DATA_TYPE: string;
  CORRELATION: string;
  filter?: number;
  COLUMN_NAME?: string;
}

export enum CORRELATION {
  VERYSTRONG = 'Very Strong Predictor',
  STRONG = 'Strong Predictor',
  MODERATE = 'Moderate Predictor',
  WEAK = 'Weak Predictor',
  VERYWEAK = 'Very Weak Predictor',
  SPARSE = 'Sparse',
  MODELDROPPED = 'Model Dropped',
  NOISY = 'Noisy',
  HIGHLYCARDINAL = 'Highly Cardinal',
}

export enum TimeType {
  RELATIVE = 'relative',
  FIXED = 'fixed',
}

export const typeOptions = [
  {
    label: 'Users',
    value: 'USERS',
  },
  {
    label: 'Accounts',
    value: 'ACCOUNTS',
    type: () => (['letsdive'].includes(localStorage.getItem(ACCOUNT_ID)) ? '' : 'soon'),
  },
];

export const dateBetweenOptions = [
  { label: 'In the last', value: TimeType.RELATIVE },
  { label: 'During', value: TimeType.FIXED },
];

export const goalOptions = [
  {
    label: 'Conversion opportunities',
    value: 'CONVERSION',
    type: () => (localStorage.getItem(ACCOUNT_ID) === 'letsdive' ? 'soon' : ''),
  },
  {
    label: 'Engagement opportunities',
    value: 'ENGAGEMENT',
    type: () => (localStorage.getItem(ACCOUNT_ID) === 'letsdive' ? '' : 'soon'),
  },
  {
    label: 'Expansion opportunities',
    value: 'EXPANSION',
    type: () => (['invideo', 'demo'].includes(localStorage.getItem(ACCOUNT_ID)) ? '' : 'soon'),
  },
  {
    label: 'Churn opportunities',
    value: 'CHURN',
    type: () => 'soon',
  },
];

export function isComingSoon(type: string | (() => string) | undefined) {
  if (!type) {
    return false;
  }

  if (typeof type === 'string') {
    return type === 'soon';
  }

  return type() === 'soon';
}

export const days = [0, 1, 2, 3, 4, 5, 6, 7];

export const filterDummyOptions = [
  { label: 'US', value: 'usa' },
  { label: 'INDIA', value: 'india' },
];

type GoalMetRate = {
  all: number;
  hot: number;
  warm: number;
  cold: number;
};

export interface Leads {
  count: number;
  leadDistribution: LeadDistribution;
  leads?: LeadsEntity[] | null;
  goalMetRate?: GoalMetRate;
  success: boolean;
}

export interface LeadDistribution {
  warm?: number;
  hot?: number;
  cold?: number;
}

export interface LeadsEntity {
  country: string;
  user_id: string;
  USER_PROPERTY_first_name: string;
  user_creation_time: string;
  USER_PROPERTY_last_name?: string | null;
  USER_PROPERTY_email: string;
  score?: null;
}

export type OpportunitySchedule = {
  time?: string;
  recurring?: {
    start: string;
    frequency: { [key: string]: { time: string; days?: string[] } };
  };
} & (
  | {
      time: string;
    }
  | {
      recurring: {
        start: string;
        frequency: { [key: string]: { time: string; days?: string[] } };
      };
    }
);

export type Opportunity = {
  id: number;
  name: string;
  description: string;
  query: Query;
  exports?: (ExportsEntity | null)[] | null;
  createdAt: string;
  modifiedAt: string;
  length: number;
  createdBy: any;
  lastRun: string;
  nextRun: string;
  status: string;
  destination?: Destination;
  schedule: OpportunitySchedule;
  controlGroup: ControlGroupResponse;
  isSample: boolean;
};

export interface ListAllOpportunities {
  results: Array<Opportunity>;
  count: number;
  previous?: string;
  next?: string;
}

export interface OpportunityStatusCount {
  status: string;
  count: number;
}

export interface Query {
  type: string;
  goal: QueryGoal;
  time: Time;
  sampleProfiles: boolean;
  filter?: FilterEntity[] | null;
  dsEnabled?: boolean;
  performanceTrackEvent: { [key: string]: string };
}

export interface Time {
  fixed: Fixed;
}

export interface Fixed {
  start: string;
  end: string;
}

export interface FilterEntity {
  source: string;
  keySpace: string;
  keyName: string;
  op: string;
  value: string;
}

export type ControlGroupResponse = {
  id: number;
  name: string;
  type: string;
  metadata: {
    percentage: number;
  };
  active: boolean;
  description: string;
};

export interface ExportsEntity {
  id: number;
  active: boolean;
  filter: MetadataOrFilter;
  schedule: Schedule;
  metadata: Metadata;
  opportunity: string;
  destination: Destination;
  fieldMapping?: null[] | null;
  created_at: string;
  modified_at: string;
  controlGroup: ControlGroupResponse;
}

export interface MetadataOrFilter {}

export interface Schedule {
  recurring: string;
}

export interface Metadata {
  email?: string[] | null;
}

export interface Destination {
  id: number;
  name: string;
  type: string;
  metadata: MetadataOrFilter;
  createdAt: string;
  modifiedAt: string;
}

export interface Destinations {
  id: number;
  name: string;
  type: string;
  metadata: Metadata;
  createdAt: string;
  modifiedAt: string;
}

export enum LeadType {
  ALL = 'all',
  HOT = 'hot',
  WARM = 'warm',
  COLD = 'cold',
}

export const allLeads = [LeadType.HOT, LeadType.WARM, LeadType.COLD];

export const LeadEmojis = {
  [LeadType.HOT]: '🔥 Hot Leads',
  [LeadType.COLD]: '❄️ Cold Leads',
  [LeadType.WARM]: '☕ Warm Leads',
};

export interface OpPerformance {
  qualInfo: QualInfo;
  convInfo: ConvInfo;
}

export interface QualInfo {
  summary: QualInfoSummary;
  // aggInfo: QualInfoAggInfo;
}

export interface QualInfoSummary {
  uniqueQualifiedUsers: number;
  changePer: number;
}

export interface ConvInfo {
  summary: ConvInfoSummary;
  // aggInfo: ConvInfoAggInfo;
}

export interface ConvInfoSummary {
  users: Users;
  dollars: Dollars;
}

export interface Users {
  convertedUsers: number;
  changePer: number;
}

export interface Dollars {
  totalValue: number;
  changePer: number;
}

export interface Accounts {
  count: number;
  data?: DataEntity[] | null;
}

export interface UsersTableDataQuery {
  count: number;
  data?: Record<string, string>[] | null;
}

export interface DataEntity {
  domain_1: string;
  user_creation_time: string;
  country?: string | null;
  industry?: string | null;
  sector?: string | null;
  champion_user_name: string;
  champion_user_email: string;
}
