import { useClient } from 'api-client';
import dayjs from 'dayjs';
import { useAuth } from 'hooks/useAuth';
import useEntities from 'hooks/useEntities';
import useModels from 'hooks/useModels';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { QueryType } from 'stores/QueryStore';
import useCohorts from './useCohorts';
import useGtmStrategies from './useGtmStrategies';
import { getDaysAndHoursOnly } from '../../../utils/common';

const STATS_FOR_NO_OF_DAYS = 4;

function getPerformanceRequestPayload(dateRange) {
  return {
    start: `${dayjs(dateRange.startDate).format('YYYY-MM-DD')}${dayjs(new Date())
      .tz('UTC')
      .format('THH:mm:00.000[Z]')}`,
    end: `${dayjs(dateRange.endDate).format('YYYY-MM-DD')}${dayjs(new Date())
      .tz('UTC')
      .format('THH:mm:00.000[Z]')}`,
  };
}
const PERFORMANCE_STATS_PAYLOAD = {
  startDate: dayjs().subtract(30, 'days').format('MM/DD/YYYY'),
  endDate: dayjs().format('MM/DD/YYYY'),
};

function setOppDataInCache(oppData, queryClient) {
  for (let oppId in oppData) {
    queryClient.setQueryData(['opportunity', oppId], oppData[oppId]?.details);
    queryClient.setQueryData(
      [
        'performanceDetails',
        oppId,
        JSON.stringify(
          getDaysAndHoursOnly(getPerformanceRequestPayload(PERFORMANCE_STATS_PAYLOAD))
        ),
      ],
      oppData[oppId]?.performance
    );
  }
}

export default async function usePrefetchQueries() {
  const { data: entities } = useEntities();
  const { data: models } = useModels();

  // const { data: userListPreferences } = useGetUserListPreference();

  const { user } = useAuth();
  const queryClient = useQueryClient();
  const client = useClient();

  // prefetch cohort and gtm-strategies
  const { data: cohorts } = useCohorts();
  useGtmStrategies();

  useEffect(() => {
    if (!user?.currentOrganization?.isSandbox) {
      client
        .getOpportunitiesStatus(
          STATS_FOR_NO_OF_DAYS,
          getPerformanceRequestPayload(PERFORMANCE_STATS_PAYLOAD),
          'ACTIVE'
        )
        .then((data) => {
          setOppDataInCache(data, queryClient);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
      client
        .getOpportunitiesStatus(
          STATS_FOR_NO_OF_DAYS,
          getPerformanceRequestPayload(PERFORMANCE_STATS_PAYLOAD),
          'COMPLETED'
        )
        .then((data) => {
          setOppDataInCache(data, queryClient);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
  }, [user]);

  useEffect(() => {
    if (entities && models) {
      entities
        .filter((e) => e.enabled)
        .forEach((e) => {
          models
            .filter((m) => m.entity === e.id && m.active)
            .forEach((m) => {
              queryClient.prefetchQuery(
                // prefetch toplyne meta
                ['meta', QueryType[e.id], m.modelId, m.goal.toUpperCase()],
                () =>
                  client.getHistogram(
                    {
                      type: QueryType[e.id],
                      modelId: m.modelId,
                      goal: m.goal.toUpperCase(),
                    },
                    true
                  )
              );

              // prefetch entity fields
              queryClient.prefetchQuery(['entities', e.id, 'query'], () =>
                client.getEntitiesQuery(e.id, true)
              );

              // prefetch opportunity query
              queryClient.prefetchQuery(
                [
                  'oppquery',
                  'newpqlworkflow',
                  QueryType[e.id],
                  m.modelId,
                  m.goal.toUpperCase(),
                  false,
                  [],
                  [cohorts?.find((c) => c.name === 'All Users')?.id],
                ],
                () =>
                  client.getOpportunityQuery(
                    {
                      query: {
                        type: QueryType[e.id],
                        modelId: m.modelId,
                        sampleProfiles: false,
                        filter: [],
                        field_mapping: [],
                        goal: m.goal.toUpperCase(),
                      },
                    },
                    true
                  )
              );

              // prefetch cohort counts
              if (user?.currentOrganization?.isPersonas) {
                // prefetch cohort counts
                queryClient.prefetchQuery(['cohort', 'count', m.modelId, QueryType[e.id]], () =>
                  client.getCohortCounts(
                    {
                      type: QueryType[e.id],
                      goal: models.find((ms) => ms.modelId === m.modelId)?.goal.toUpperCase(),
                      modelId: m.modelId,
                      filter: [],
                    },
                    true
                  )
                );
              }
            });
        });
    }
  }, [entities, models, queryClient, client, cohorts, user]);
}
