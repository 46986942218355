import {
  AllUsersPersona,
  CoffeeIcon,
  FireIcon,
  FrozenIcon,
} from 'ui-components/data-display/Icons';

export default function LeadStatusEmoji({
  value,
  className,
}: {
  value: string;
  className: string;
}) {
  if (value === 'all') {
    return <AllUsersPersona className={className} />;
  } else if (value === 'hot') {
    return <FireIcon className={className} />;
  } else if (value === 'warm') {
    return <CoffeeIcon className={className} />;
  } else if (value === 'cold') {
    return <FrozenIcon className={className} />;
  }

  return null;
}

LeadStatusEmoji.defaultProps = {
  className: 'w-6 h-6',
};
