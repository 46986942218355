import { ReactComponent as InfoToolTipIcon } from 'assets/images/info-filled.svg';
import Modal from 'ui-components/util/modal';
import { ErrorBoundary } from 'react-error-boundary';
import { useAuthStore } from 'hooks/useAuthStore';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'routing/clientRoutes';
import { ReactComponent as DeleteBinIcon } from '../../../assets/images/delete.svg';
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from '../../../ui-components/inputs/Buttons';
import Copy from '../../../ui-components/util/Copy';
import { convertDate } from '../../../utils/common';
import useRole from '../../../hooks/useRole';
import { useAnalytics } from '../../../telemetry';
import {
  AnalyticsConst,
  AnalyticsEvents,
  ErrorAnalyticsEvents,
} from '../../../telemetry/constants';
import { useApiTokenStore } from '../../../stores/ApiTokenStore';
import useDeleteAPIToken from '../hooks/useDeleteAPIToken';
import useMemberApiDetails from '../hooks/useMemberApiDetails';
import SettingsSideNav from '../SettingsSideNav';
import { APITokensTable } from './APITokensTable';
import ErrorBoundaryFallback from 'ui-components/feedback/ErrorBoundaryFallback';

interface APISettingsProps {}

export const TokenDetails = ({
  tokenName,
  token,
  expiresOn,
}: {
  tokenName: string;
  token: string;
  expiresOn: string;
}) => {
  return (
    <>
      {tokenName && token && (
        <div>
          <div className={'mt-6 grid grid-cols-6 gap-4'}>
            <div className={'col-span-1 flex items-center'}>
              <p className={'text-sm leading-4 text-tw-gray-7 font-normal'}>Access token</p>
            </div>
            <div className={'col-span-3'}>
              <Copy
                value={token}
                className={'w-fc'}
                toastTitle={'Token copied to clipboard'}
                trackTitle={'Token copied from Token list'}
              />
            </div>
          </div>
          <div className={'mt-8 grid grid-cols-6 gap-4'}>
            <div className={'col-span-1 flex'}>
              <p className={'text-sm leading-4 text-tw-gray-7 font-normal'}>Name</p>
            </div>
            <div className={'col-span-3'}>
              <p className={'text-sm leading-4 text-tw-black-5 font-normal pb-1.5'}>{tokenName}</p>
              <p className={'text-xs leading-3 text-tw-gray-7 font-normal'}>
                {expiresOn === '9999-12-31'
                  ? 'Expires never'
                  : `Expires on ${convertDate(expiresOn)}`}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const APISettings: React.FC<APISettingsProps> = () => {
  const { getAccountId } = useAuthStore();

  const router = useHistory();
  const { isReadOnly } = useRole();
  const { track } = useAnalytics();
  const deleteMutation = useDeleteAPIToken();
  const { data: memberAPIDetails, isLoading: memberAPIDetailsLoading } = useMemberApiDetails();
  const { id: tokenId, name: tokenName, token, expiresOn, clearTokenStore } = useApiTokenStore();
  const [showDeleteModal, setshowDeleteModal] = React.useState(false);

  const [deleteTokenId, setDeleteTokenId] = React.useState<number>(null);
  const [deleteTokenName, setDeleteTokenName] = React.useState<string>('');
  const [clearStore, setClearStore] = React.useState<boolean>(false);
  React.useEffect(() => {
    return () => {
      clearTokenStore();
    };
  }, [clearTokenStore]);
  const Columns = [
    {
      Header: 'NAME',
      accessor: 'name',
      Cell: ({ row }: { row: any }) => row.original.name,
    },
    {
      Header: 'EXPIRY DATE',
      accessor: 'expiresOn',
      Cell: ({ row }: { row: any }) => {
        if (row.values['expiresOn']) {
          return (
            <p className={'text-sm font-normal leading-4 text-tw-black-5'}>
              {row.values['expiresOn'] === '9999-12-31'
                ? 'Never'
                : convertDate(row.values['expiresOn'])}
            </p>
          );
        }
        return <p className={'text-sm font-normal leading-4 text-tw-black-5'}>Never</p>;
      },
    },
    {
      Header: 'Created By',
      accessor: 'createdBy.email',
      Cell: ({ row }: { row: any }) => row.original.createdBy.email,
    },
    {
      Header: 'ACTION',
      accessor: 'action',
      Cell: ({ row }: { row: any }) => {
        return (
          <div className={'pl-3'}>
            <DeleteBinIcon
              className="inline-block h-full align-middle cursor-pointer fill-current text-tw-black-9"
              onClick={() => {
                if (row.original.id === tokenId) {
                  setClearStore(true);
                } else {
                  setClearStore(false);
                }
                setDeleteTokenId(row.original.id);
                setDeleteTokenName(row.original.name);
                setshowDeleteModal(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  const deleteToken = (id, name) => {
    deleteMutation.mutate(id, {
      onSuccess: async () => {
        setshowDeleteModal(false);
        if (clearStore) {
          clearTokenStore();
        }
      },
      onError: async () => {
        track(AnalyticsEvents.TOKEN_DELETED_FAILED, {
          [AnalyticsConst.NAME]: name,
        });
      },
    });
    setClearStore(false);
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={(e) =>
        track(ErrorAnalyticsEvents.ERROR_OCCURED, {
          error: e.message,
        })
      }
    >
      <div className="flex w-full mt-4">
        <SettingsSideNav />
        <div className="w-full px-6 bg-white rounded min-h-86vh">
          <div className={'px-4 py-4 '}>
            <div className="flex items-center justify-between">
              <p className="mb-0 text-base font-normal uppercase text-tw-gray-7">
                API ACCESS TOKENS
              </p>
            </div>
            <hr className="my-4 border-b border-tw-gray-eb" />
            {tokenName && token && (
              <div className={'my-8'}>
                <p className={'leading-5 font-medium text-base text-tw-black-3 mb-4'}>
                  Recently created token
                </p>
                <div className={'border-1 border-tw-gray-eb rounded-lg'}>
                  <div className={'flex py-6 bg-tw-blue-ec px-4 rounded-t-lg'}>
                    <InfoToolTipIcon className={'h-5 w-5 text-tw-teal-1 fill-current'} />
                    <p className={'leading-4 font-medium text-sm ml-4 items-center flex'}>
                      Make sure to copy your personal access token now. You won’t be able to see it
                      again!
                    </p>
                  </div>
                  <div className="px-4">
                    <TokenDetails token={token} tokenName={tokenName} expiresOn={expiresOn} />
                  </div>
                  <div className={'my-8 px-4 grid grid-cols-6 gap-4'}>
                    <div className={'col-span-1 flex items-center'}>
                      <p className={'text-sm leading-4 text-tw-gray-7 font-normal'}>Action</p>
                    </div>
                    <div className={'col-span-3'}>
                      <DeleteBinIcon
                        className="inline-block h-full align-middle cursor-pointer fill-current text-tw-black-9"
                        onClick={() => {
                          setClearStore(true);
                          setDeleteTokenId(tokenId);
                          setDeleteTokenName(tokenName);
                          setshowDeleteModal(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <p className={'text-tw-black-3 font-normal text-sm leading-5'}>
              Use tokens to access the Toplyne API which provides you the capability to upload your
              event data and user profile data to Toplyne. We analyse this data to come up with
              meaningful segments to power your GTM campaigns
            </p>
            <div className={'mt-8 justify-between flex'}>
              <p className={'text-tw-black-3 text-base font-medium leading-5 items-center flex'}>
                Tokens
              </p>
              {!isReadOnly && (
                <PrimaryButton
                  onClick={() => router.push(`/${getAccountId()}${routes.createToken}`)}
                >
                  + Generate new token
                </PrimaryButton>
              )}
            </div>
          </div>
          <div>
            <APITokensTable
              columns={Columns.filter((item) => {
                if (isReadOnly) {
                  return item.accessor !== 'action';
                }
                return true;
              })}
              data={memberAPIDetails}
              isLoading={memberAPIDetailsLoading}
            />
          </div>
        </div>
      </div>
      <Modal
        onClose={() => {
          setshowDeleteModal(false);
        }}
        open={showDeleteModal}
        title={'Delete token'}
      >
        <div className={'px-2'}>
          <p className={'text-tw-black-5 font-normal leading-8 text-base mb-16'}>
            Are you sure you want to delete {deleteTokenName}?
          </p>
        </div>
        <hr />
        <div className={'flex justify-end px-2 gap-x-2 mt-4'}>
          <TertiaryButton type="button" disabled={false} onClick={() => setshowDeleteModal(false)}>
            Cancel
          </TertiaryButton>
          <SecondaryButton
            className="w-36 text-tw-red-dd"
            bgClassName="bg-tw-red-ff"
            borderClassName="border-tw-red-dd"
            isLoading={deleteMutation.isLoading}
            disabled={deleteMutation.isLoading}
            onClick={() => deleteToken(deleteTokenId, deleteTokenName)}
          >
            Delete token
          </SecondaryButton>
        </div>
      </Modal>
    </ErrorBoundary>
  );
};

export default APISettings;
