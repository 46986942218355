// This component would manage when the entire subsection is finished then call the

import { IntegrationType } from 'api-client';
import { PrimaryButton, SecondaryButton } from 'ui-components/inputs/Buttons';
import { classNames } from 'utils/common';
import { useAuth } from 'hooks/useAuth';
import useConnectedIntegrations from 'hooks/useConnectedIntegrations';
import useIntegrations from 'pages/integrations/hooks/useIntegrations';
import { useDefinePQLStore } from 'pages/pql-workflow/store/PQLDefineStore';
import { useExportPQLStore } from 'pages/pql-workflow/store/PQLExportStore';
import Destination from './Destination';
import Mappings from './mappings';
import Schedule from './Schedule';

export function IntegrationIcons() {
  const { data, isLoading } = useIntegrations();
  const destinations = data?.filter(
    (d) =>
      d.supportsWrite &&
      d.available &&
      ![IntegrationType.EMAIL, IntegrationType.WEBHOOK].includes(d.type)
  );
  const logosToShow = [
    ...new Set(destinations?.filter((i) => i.logoUrl).map((i) => i.logoUrl)),
  ].slice(0, 4);

  const classNameFromIndex = (index: number) => {
    if (index === 0) {
      return 'ml-0';
    } else if (index === 1) {
      return '-ml-3 z-5';
    } else if (index === 2) {
      return '-ml-3 z-10';
    } else if (index === 3) {
      return '-ml-3 z-20';
    }
  };
  if (isLoading) {
    return <div className="bg-tw-gray-eb w-40 h-8 animate-pulse"></div>;
  }
  return (
    <div className="flex items-center">
      {logosToShow?.map((logoUrl, index) => (
        <div
          key={index}
          className={classNames(
            'p-3 border bg-white rounded-full border-tw-gray-eb',
            classNameFromIndex(index)
          )}
        >
          <img src={logoUrl} className="w-5 h-5" />
        </div>
      ))}
      <div className="z-30 grid -ml-3 bg-white border rounded-full w-[46px] h-[46px] place-items-center border-tw-gray-eb">
        <p className="text-xs text-tw-black-7">+ {destinations?.length - 4}</p>
      </div>
    </div>
  );
}

// next "step" of the workflow.
export default function ExportSubSections() {
  const {
    syncOverviewOpen,
    setSyncOverviewOpen,
    setEmails,
    destinationId,
    setDestinationId,
    setShowDrawer,
  } = useExportPQLStore();
  const { isLoading: connectedIntegrationsIsLoading } = useConnectedIntegrations();
  const { isLoading: integrationsIsLoading } = useIntegrations();
  const { user } = useAuth();
  const { targetEntity } = useDefinePQLStore();
  const { data: connectedIntegrations } = useConnectedIntegrations(false);

  return (
    <div className="rounded-lg bg-tw-white-ff">
      {syncOverviewOpen && (
        <div className="flex flex-col items-center px-6">
          <div className="flex flex-col items-center pt-16 pb-11">
            <IntegrationIcons />
            <p className="mt-4 text-base font-medium text-tw-black-3">
              You haven’t connected any destination
            </p>
            <p className="mt-2 text-tw-black-7">
              Setup a destination to sync these qualified {targetEntity.toLowerCase()} to the app of
              your choice
            </p>
          </div>
          <div className="flex w-full pb-6 gap-x-4">
            <SecondaryButton
              className="w-1/2 py-2 text-tw-blue-0d product-tour-csv"
              disabled={connectedIntegrationsIsLoading || integrationsIsLoading}
              onClick={() => {
                setSyncOverviewOpen(false);
                setEmails([user.email]);
                if (!destinationId) {
                  setDestinationId(
                    connectedIntegrations?.find((i) => i.type === IntegrationType.EMAIL)?.id
                  );
                }
              }}
            >
              Export as CSV
            </SecondaryButton>
            <PrimaryButton
              disabled={connectedIntegrationsIsLoading || integrationsIsLoading}
              className="w-1/2 py-2"
              onClick={() => {
                setSyncOverviewOpen(false);
                setEmails([user.email]);
                setShowDrawer(true);
                if (!destinationId) {
                  setDestinationId(
                    connectedIntegrations?.find((i) => i.type === IntegrationType.EMAIL)?.id
                  );
                }
              }}
            >
              See available apps
            </PrimaryButton>
          </div>
        </div>
      )}
      {!syncOverviewOpen && (
        <>
          <Destination />
          <Mappings />
          <Schedule />
        </>
      )}
    </div>
  );
}
