import { useClient } from 'api-client';
import useDebounce from 'hooks/useDebounce';
import { useQuery, useQueryClient } from 'react-query';
import { useUsersStore } from '../store/UsersStore';

export default function useUsers(enabled = true) {
  const { page, field_mapping, sortBy, searchQuery, filters } = useUsersStore();

  const client = useClient();
  const queryClient = useQueryClient();
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  return useQuery(
    [
      'users',
      page,
      JSON.stringify(Object.keys(field_mapping)),
      [sortBy.map((s) => `${s.source}_${s.keyName}_${s.keySpace}_${s.order}`).join(', ')],
      [
        filters
          .map((f) => `${f.source}_${f.keyName}_${f.keySpace}_${f.op}_${JSON.stringify(f.value)}`)
          .join(', '),
      ],
      debouncedSearchQuery,
    ],
    () => client.getUsers(page, field_mapping, sortBy, searchQuery, filters ?? []),
    {
      keepPreviousData: true,
      onSuccess: () => {
        // prefetch the next page automatically.
        queryClient.prefetchQuery([
          'users',
          page + 1,
          JSON.stringify(Object.keys(field_mapping)),
          [sortBy.map((s) => `${s.source}_${s.keyName}_${s.keySpace}_${s.order}`).join(', ')],
          [
            filters
              .map(
                (f) => `${f.source}_${f.keyName}_${f.keySpace}_${f.op}_${JSON.stringify(f.value)}`
              )
              .join(', '),
          ],
          debouncedSearchQuery,
          () => client.getUsers(page + 1, field_mapping, sortBy, debouncedSearchQuery, filters),
        ]);
      },
      enabled: !!enabled,
    }
  );
}
