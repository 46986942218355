import { useQueryStore } from './QueryStore';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

export const useStore = () => {
  const queryStore = useQueryStore();

  const store = () => ({
    queryStore,
  });

  create(devtools(store as any));
};
