import { TickPlain, WarningIcon } from 'ui-components/data-display/Icons';
import Modal from 'ui-components/util/modal';
import { TertiaryButton } from 'ui-components/inputs/Buttons';
import { useCallback } from 'react';

export default function WarningModal({
  flagToShowModal,
  setFlagToShowModal,
  handleSubmit,
  heading,
  subHeading,
  buttonCopy,
  backButtonCopy = 'Go back',
  title = 'One last check',
}) {
  const closeModal = useCallback(() => {
    setFlagToShowModal(false);
  }, []);

  return (
    <Modal title={title} open={flagToShowModal} onClose={closeModal} closeIcon={true}>
      <div className="p-4">
        <div className="flex items-center p-4 rounded-lg bg-tw-papaya gap-x-2 text-tw-black-3">
          <WarningIcon />
          <span className="font-medium">{heading}</span>
        </div>
        <div className="mt-6">{subHeading}</div>
        <div className="flex justify-end px-2 pt-4 mt-8 border-t-1 gap-x-4">
          <TertiaryButton onClick={closeModal}>{backButtonCopy}</TertiaryButton>
          <TertiaryButton className="flex items-center gap-x-2" onClick={handleSubmit}>
            <TickPlain />
            <span>{buttonCopy}</span>
          </TertiaryButton>
        </div>
      </div>
    </Modal>
  );
}
