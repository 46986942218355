import { RejectJoinRequestData, useClient } from 'api-client';
import { useMutation, useQueryClient } from 'react-query';

export default function useRejectJoinRequest() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation((data: RejectJoinRequestData) => client.rejectJoinRequest(data), {
    onSuccess: async (data: { email: string }) => {
      await queryClient.cancelQueries('joinRequests');
      queryClient.setQueryData('joinRequests', (prev: { email: string }[]) => [
        ...prev.filter((item) => item.email !== data.email),
      ]);
    },
    onSettled: () => {
      queryClient.refetchQueries('joinRequests');
    },
  });
}
