import { useClient } from 'api-client';
import { useAuthStore } from 'hooks/useAuthStore';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useAnalytics } from '../../telemetry';

export default function useLogout() {
  const client = useClient();
  const router = useHistory();
  const queryClient = useQueryClient();
  const { getRefreshToken } = useAuthStore();
  const refresh = getRefreshToken();
  const { reset } = useAnalytics();
  return useMutation(
    () =>
      client.logout({
        refresh,
      }),
    {
      onMutate: () => {
        queryClient.clear();
        window.localStorage.clear();
        window.sessionStorage.clear();
        reset();
        router.push('/login');
      },
    }
  );
}
