import {
  CaretDown,
  DeleteTableResults,
  FiltersFunnelCenter,
} from 'ui-components/data-display/Icons';
import PopupFilter from 'ui-components/business/PopupFilter';
import { classNames } from 'utils/common';
import { PopupFilterPage } from 'utils/globalTypes';
import { useAuth } from 'hooks/useAuth';
import { getDefaultAccountTableColumn } from 'pages/users-and-accounts/accounts/accounts-list/account-list/AccountListTableColumns';
import { FilterExpression, SortBy, SortOrder } from 'pages/users-and-accounts/users/types';
import { getDefaultUserTableColumn } from 'pages/users-and-accounts/users/users-list/Columns';
import { useEffect, useMemo, useState } from 'react';
import { QueryType } from 'stores/QueryStore';
import useModifyPersonaProperties, {
  ModifyPersonaPropActions,
} from '../hooks/useModifyPersonaProperties';
import { ColumnType, useAccountsStore } from '../store/AccountsStore';
import { useUsersStore } from '../store/UsersStore';

export const SortColumnFilters = [
  {
    label: 'Ascending',
    value: 'ascending',
    shortHand: 'ASC',
  },
  {
    label: 'Descending',
    value: 'descending',
    shortHand: 'DESC',
  },
];

type ColumnActionsProps = {
  column: ColumnType;
  showPopupFilter: boolean;
  setShowPopupFilter: (value: boolean) => void;
  setSortBy: (obj: SortBy[]) => void;
  sortBy: SortBy[];
  setFilters: (obj: FilterExpression[]) => void;
  filters: FilterExpression[];
  entityType: QueryType;
};

export default function ColumnActions({
  column,
  showPopupFilter,
  setShowPopupFilter,
  entityType,
  setSortBy,
  sortBy,
  setFilters,
  filters,
}: ColumnActionsProps) {
  // field mappings from users and accounts store.
  const { field_mapping: usersStoreFieldMappings } = useUsersStore();
  const { field_mapping: accountsStoreFieldMappings } = useAccountsStore();

  const { user: userData } = useAuth();
  const { mutateAsync: modifyPersonaProperties } = useModifyPersonaProperties();
  // sort filters state before commiting
  const [sortFilterApplied, setSortFilterApplied] = useState<typeof SortColumnFilters[0] | null>(
    null
  );

  useEffect(() => {
    if (sortBy?.length && column) {
      sortBy.forEach((sort) => {
        if (sort.keyName === column.keyName) {
          setSortFilterApplied(
            SortColumnFilters?.find((filter) => filter.shortHand === sort.order)
          );
        }
      });
    }
  }, [column, sortBy]);

  if (!column) {
    return <div className="w-[72px]"></div>;
  }

  const nonDeletableColumn = useMemo(() => {
    if (entityType === QueryType.users) {
      if (getDefaultUserTableColumn(userData).includes(column.keyName)) {
        return true;
      }
    } else {
      if (getDefaultAccountTableColumn(userData).includes(column.keyName)) {
        return true;
      }
    }

    return false;
  }, [column.keyName, entityType, userData]);

  return (
    <div className="flex items-center ml-4" key={column.keyName}>
      {sortBy && (
        <div className="bg-tw-white-ff rounded mr-1">
          {sortFilterApplied ? (
            <div>
              {sortFilterApplied.shortHand === 'ASC' ? (
                <div
                  className="py-1 px-1.5 border-1 border-tw-gray-c rounded"
                  onClick={() => {
                    const { source, keyName, keySpace } = column;
                    // ascending -> descending
                    setSortBy([
                      ...sortBy.filter((s) => s.keyName !== keyName),
                      { source, keyName, keySpace, order: SortOrder['descending'] },
                    ]);
                  }}
                >
                  <CaretDown className="h-1.5 w-1.5 rotate-180 text-tw-black-9 fill-current" />
                  <CaretDown className="h-1.5 w-1.5 text-tw-gray-eb fill-current" />
                </div>
              ) : (
                <div
                  className="py-1 px-1.5 border-1 border-tw-gray-c rounded"
                  onClick={() => {
                    const { keyName } = column;
                    // descending -> no sort
                    setSortBy([...sortBy.filter((s) => s.keyName !== keyName)]);
                  }}
                >
                  <CaretDown className="h-1.5 w-1.5 rotate-180 text-tw-gray-eb fill-current" />
                  <CaretDown className="h-1.5 w-1.5 text-tw-black-9 fill-current" />
                </div>
              )}
            </div>
          ) : (
            <div
              className="py-1 px-1.5 border-1 border-tw-gray-c rounded"
              onClick={() => {
                const { source, keyName, keySpace } = column;
                // normal -> ascending
                setSortBy([
                  ...sortBy.filter((s) => s.keyName !== keyName),
                  { source, keyName, keySpace, order: SortOrder['ascending'] },
                ]);
              }}
            >
              <CaretDown className="h-1.5 w-1.5 rotate-180 text-tw-black-9 fill-current" />
              <CaretDown className="h-1.5 w-1.5 text-tw-black-9 fill-current" />
            </div>
          )}
        </div>
      )}
      <PopupFilter
        shouldFetchHistogram={showPopupFilter}
        page={PopupFilterPage.USERACCOUNTS}
        trigger={
          <div
            className={classNames(
              'p-1 rounded',
              filters?.find((filter) => filter.columnName === column.columnName)
                ? 'bg-tw-blue-f2 border-1 border-tw-blue-0d'
                : 'bg-tw-white-ff border-1 border-tw-gray-c'
            )}
            onClick={() => setShowPopupFilter(true)}
          >
            <FiltersFunnelCenter
              className={classNames(
                'w-3 h-3 fill-current',
                filters?.find((filter) => filter.columnName === column.columnName)
                  ? 'text-tw-blue-0d'
                  : 'text-tw-black-9'
              )}
            />
          </div>
        }
        initialValue={
          filters?.find((filter) => filter.columnName === column.columnName)
            ? {
                [column.dataType]: filters.find((filter) => filter.columnName === column.columnName)
                  ?.value,
              }
            : null
        }
        row={{
          original: {
            COLUMN_NAME: column.columnName,
            KEYNAME: column.keyName,
            KEYSPACE: column.keySpace,
            SOURCE: column.source,
            DATA_TYPE: column.dataType,
            DISPLAY_NAME: column.displayName,
          },
        }}
        onClose={() => setShowPopupFilter(false)}
        onApply={(currentComparator, filterToBeApplied) => {
          const { keyName, keySpace, source, columnName, displayName } = column;
          const newFilters = filters.filter((prevFilter) => {
            return prevFilter.columnName !== columnName;
          });

          // Set filters in accounts store
          setFilters([
            ...newFilters,
            {
              keyName,
              keySpace,
              source,
              op: currentComparator,
              value: filterToBeApplied,
              displayName,
              columnName,
            },
          ]);

          setShowPopupFilter(false);
          // TODO: Telemetry
        }}
        onReset={(columnName: string) => {
          // Set filters in accounts store
          setFilters(filters.filter((f) => f.columnName !== columnName));

          setShowPopupFilter(false);
        }}
        popoverSide="bottom"
        old={false}
        entityType={entityType}
      />
      {!nonDeletableColumn && (
        <div
          className="bg-tw-white-ff p-1 border-1 border-tw-gray-c rounded ml-1"
          onClick={() => {
            if (entityType === QueryType.accounts) {
              modifyPersonaProperties({
                personaProps: [
                  ...Object.keys(accountsStoreFieldMappings).filter(
                    (mapping) => mapping !== column.keyName
                  ),
                ],
                orgSchemaName: userData?.currentOrganization?.schemaName,
                action: ModifyPersonaPropActions.REMOVE,
                entityType,
                currentFieldMapping: {
                  displayName: column?.displayName,
                  source: column?.source,
                  keyName: column?.keyName,
                  keySpace: column?.keySpace,
                  columnName: column?.columnName,
                  isSystem: column?.isSystem,
                  dataType: column?.dataType,
                  keyAlias: column?.keyAlias,
                },
              });
            } else {
              modifyPersonaProperties({
                personaProps: [
                  ...Object.keys(usersStoreFieldMappings).filter(
                    (mapping) => mapping !== column.keyName
                  ),
                ],
                orgSchemaName: userData?.currentOrganization?.schemaName,
                action: ModifyPersonaPropActions.REMOVE,
                entityType,
                currentFieldMapping: {
                  displayName: column?.displayName,
                  source: column?.source,
                  keyName: column?.keyName,
                  keySpace: column?.keySpace,
                  columnName: column?.columnName,
                  isSystem: column?.isSystem,
                  dataType: column?.dataType,
                  keyAlias: column?.keyAlias,
                },
              });
            }
          }}
        >
          <DeleteTableResults className="w-3 h-3 fill-current text-tw-black-9" />
        </div>
      )}
    </div>
  );
}
