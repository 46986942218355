import animationData from 'assets/lotties/paperplane.json';
import { InfoIcon } from 'ui-components/data-display/Icons';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';
import { PrimaryButton } from 'ui-components/inputs/Buttons';
import { TextInput } from 'ui-components/inputs/Inputs';
import { useAuth } from 'hooks/useAuth';
import Lottie from 'lottie-react';
import RequestToJoinOrg from '../../ui-components/business/RequestToJoinOrg';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { toast as toastify } from 'react-toastify';
import { OrganizationStatus } from '../../api-client';
import useActivateOrganization from '../../hooks/useActivateOrganization';
import useCreateOrganization from '../../hooks/useCreateOrganization';
import useOrganizationDetails from '../../hooks/useOrganizationDetails';
import { ActivateSteps, useActivateStore } from './store/ActivateStore';

type FormData = {
  organizationName: string;
};

const loadingTexts = [
  'We’re preparing your account for take off...',
  'Loading...but it won’t take forever',
  'Please don’t refresh your screen...just a few more seconds',
];

function OrganizationNameLoader() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((ct) => ct + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-h2">That’s a great name! 👌</h2>
      <Lottie
        animationData={animationData}
        loop={true}
        autoplay={true}
        style={{ height: 200, width: 200 }}
      />
      {count < 10 && <p className="text-xs text-tw-black-9">{loadingTexts[0]}</p>}
      {count >= 10 && count < 20 && <p className="text-xs text-tw-black-9">{loadingTexts[1]}</p>}
      {count >= 20 && <p className="text-xs text-tw-black-9">{loadingTexts[2]}</p>}
    </div>
  );
}

export default function OrganizationName() {
  const { user } = useAuth();
  const { handleSubmit, formState, setError, control } = useForm<FormData>({
    defaultValues: {
      organizationName: '',
    },
  });
  const [forceLoading, setForceLoading] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { organization } = useActivateOrganization();
  // const { setAccountId } = useAuthStore();
  const {
    setOrganizationSchemaName,
    organizationSchemaName,
    setStep: changeStep,
  } = useActivateStore();
  const [showRequestToJoin, setShowRequestToJoin] = useState(user?.hasSimilarOrganization);

  const createOrganizationMutation = useCreateOrganization();

  const { data: organizationData, isError: organizationDataError } = useOrganizationDetails(
    organizationSchemaName,
    enabled,
    5000
  );

  const handleChangeStep = () => {
    if (user?.avatarName) {
      changeStep(ActivateSteps.timezone);
    } else {
      changeStep(ActivateSteps.avatar);
    }
  };

  const onSubmit = async (data: FormData) => {
    setForceLoading(true);
    createOrganizationMutation.mutate(
      {
        name: data.organizationName.trim(),
        sandboxId: user?.currentOrganization.schemaName,
      },
      {
        onSuccess: (response) => {
          setOrganizationSchemaName(response.schemaName);
          if (response.status === OrganizationStatus.READY) {
            setForceLoading(false);
            handleChangeStep();
          } else if (response.status === OrganizationStatus.IN_PROGRESS) {
            setEnabled(true);
          }

          // TENANT_ROUTES: Setting accountId for this session
          // of onboarding.
          // setAccountId(response['orgId'], response['schemaName']);
        },
        onError: async () => {
          setForceLoading(false);
          setEnabled(false);
        },
      }
    );
  };

  useEffect(() => {
    if (organizationData) {
      processOrganizationConnection(organizationData);
    }
  }, [organizationData]);

  useEffect(() => {
    const processOrgError = async () => {
      if (organizationDataError) {
        setEnabled(false);
        queryClient.removeQueries('user');
        setError('organizationName', { type: 'custom', message: 'Try again' });
        toastify(
          <ErrorToast description="We encountered an error while creating your organization" />,
          {
            type: 'error',
          }
        );
        setForceLoading(false);
      }
    };
    processOrgError()
      .then(() => queryClient.refetchQueries('user'))
      .catch();
  }, [organizationDataError]);

  const processOrganizationConnection = async (orgData) => {
    if (orgData.status === OrganizationStatus.READY) {
      setEnabled(false);
      setForceLoading(false);
      handleChangeStep();
    }
  };

  return (
    <div className="flex flex-col items-center w-full mx-auto mt-4">
      {!createOrganizationMutation.isLoading &&
        organization?.status !== OrganizationStatus.IN_PROGRESS &&
        !forceLoading &&
        showRequestToJoin && (
          <RequestToJoinOrg
            CTA={'Setup new account'}
            handleClickCTA={() => setShowRequestToJoin(false)}
          />
        )}
      {!createOrganizationMutation.isLoading &&
        organization?.status !== OrganizationStatus.IN_PROGRESS &&
        !showRequestToJoin &&
        !forceLoading && (
          <>
            <h2 className="text-2xl font-medium text-center">
              What do you want to call your workspace?
            </h2>
            <form className="w-3/5" onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name={'organizationName'}
                control={control}
                rules={{ required: true, pattern: /^[a-zA-Z0-9_ ]*$/g }}
                defaultValue={''}
                render={({ field }) => (
                  <div>
                    <TextInput
                      className="w-full px-4 py-3 mt-8"
                      placeholder="Your company name maybe?"
                      autoComplete={'off'}
                      onChange={field.onChange}
                      isError={!!formState.errors['organizationName']}
                    />
                    {!!formState.errors.organizationName && (
                      <div className="flex items-center mt-2 gap-x-1 text-tw-red">
                        <InfoIcon className="w-4 fill-current" />
                        <p className="text-xs">
                          {formState.errors.organizationName.message
                            ? formState.errors.organizationName.message
                            : 'Your organization name cannot have special characters'}
                        </p>
                      </div>
                    )}

                    <PrimaryButton
                      disabled={!field.value}
                      isLoading={createOrganizationMutation.isLoading}
                      className="w-full py-2 mt-11"
                    >
                      Proceed
                    </PrimaryButton>
                  </div>
                )}
              />
            </form>
          </>
        )}
      {(createOrganizationMutation.isLoading ||
        forceLoading ||
        organization?.status === OrganizationStatus.IN_PROGRESS) && <OrganizationNameLoader />}
    </div>
  );
}
