import { toast as toastify } from 'react-toastify';
import { AxiosError, useApiClient } from 'api-client';
import { AxiosInstance } from 'axios';
import { useAuthStore } from 'hooks/useAuthStore';
import useLogin from 'pages/login/hooks/useLogin';
import { useMutation } from 'react-query';
import ErrorToast from 'ui-components/feedback/Toasts/ErrorToast';

type SignupData = {
  email: string;
  password: string;
  recaptcha: string;
};

async function signup(client: AxiosInstance, values: SignupData) {
  await client.post<{ detail: string }>('auth/registration/', values, {
    headers: {
      Authorization: null,
    },
  });

  return values;
}

export default function useSignup() {
  const client = useApiClient(true);
  const login = useLogin();
  const { removeAccountId } = useAuthStore();

  return useMutation((data: SignupData) => signup(client, data), {
    onMutate: () => {
      removeAccountId();
    },
    onSuccess: (data: SignupData) => {
      login.mutate(data);
    },
    onError: (error: AxiosError) => {
      if (error.response?.data.detail) {
        throw error.response?.data.detail;
      } else {
        toastify(<ErrorToast description="Uh oh! Something went wrong" />, {
          type: 'error',
        });
      }
    },
  });
}
