import GrantAccessImage from 'assets/images/grant-access.svg';
import AlternateTopNav from 'ui-components/business/navbars/AlternateTopNav';
import { PrimaryButton } from 'ui-components/inputs/Buttons';
import { PrimaryDropdown } from 'ui-components/navigation/Dropdown';
import { TextInput } from 'ui-components/inputs/Inputs';
import { roleOptions } from 'pages/settings/Roles';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import useGrantAccess from './hooks/useGrantAccess';

export default function GrantAccess() {
  const { register, handleSubmit, control, watch } = useForm();
  const { email }: { email: string } = useParams();
  const router = useHistory();
  const grantAccess = useGrantAccess();

  const onSubmit = (data) => {
    grantAccess.mutate(data, {
      onSuccess: () => {
        router.push('/settings/team-members');
      },
    });
  };

  return (
    <div className="flex flex-col h-screen">
      <AlternateTopNav className={'mt-6 ml-8'} />
      <div className="grid h-full grow place-items-center">
        <div className="flex flex-col items-center">
          <img src={GrantAccessImage} alt="Grant Access" className="w-28" />
          <h2 className="mt-6 text-base font-medium text-tw-black-1">
            Your teammate is requesting access to your
          </h2>
          <h2 className="text-base font-medium text-tw-black-1">workspace, orgname on Toplyne</h2>
          <form
            className="flex flex-col items-center w-full mt-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col">
              <label className="text-tw-black-7">Email</label>
              <TextInput
                readOnly
                value={email}
                className="py-4 mt-3 bg-tw-gray-f5 w-80"
                {...register('email')}
              />
            </div>
            <div className="flex flex-col">
              <label className="mt-4 text-tw-black-7">Role</label>
              <Controller
                name={'role'}
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <PrimaryDropdown
                      options={roleOptions}
                      selectBtnWidth={'w-80'}
                      placeholder="VirtualizedSelect one"
                      className="py-2 mt-3"
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  );
                }}
              />
            </div>
            <PrimaryButton
              isLoading={grantAccess.isLoading}
              disabled={!watch('email') && !watch('role')}
              className="py-3 mt-6 w-80"
            >
              Grant Access
            </PrimaryButton>
          </form>
        </div>
      </div>
    </div>
  );
}
