import { IntegrationType } from 'api-client';
import { ReactComponent as ApiIcon } from 'assets/images/api_logo.svg';
import { ReactComponent as EmailIcon } from 'assets/images/email.svg';
import { ReactComponent as GainsightIcon } from 'assets/images/gainsight.svg';
import { ReactComponent as HubspotIcon } from 'assets/images/hubspot.svg';
import { ReactComponent as MarketoIcon } from 'assets/images/marketo.svg';
import { ReactComponent as PipedriveIcon } from 'assets/images/pipedrive.svg';
import { ReactComponent as SalesforceIcon } from 'assets/images/salesforce.svg';
import { ReactComponent as SlackIcon } from 'assets/images/slack.svg';
import { ReactComponent as WebhookIcon } from 'assets/images/webhook.svg';
import { AmplitudeIcon, MixpanelIcon, SegmentIcon } from 'ui-components/data-display/Icons';
import { classNames } from 'utils/common';

type IntegrationIconProps = {
  type: IntegrationType;
  width?: number;
  height?: number | string;
  className?: string;
  logoUrl?: string;
  style?: object;
};

export default function IntegrationIcon({
  type,
  className,
  logoUrl,
  ...props
}: IntegrationIconProps) {
  if (logoUrl) {
    return (
      <img
        src={logoUrl}
        alt=""
        className={className}
        style={{ height: props.height, width: props.width, ...props.style }}
      ></img>
    );
  }

  switch (type) {
    case IntegrationType.EMAIL:
      return <EmailIcon className={className} {...props} />;
    case IntegrationType.SLACK:
      return <SlackIcon className={className} {...props} />;
    case IntegrationType.SALESFORCE:
      return <SalesforceIcon className={className} {...props} />;
    case IntegrationType.SALESFORCE_SANDBOX:
      return <SalesforceIcon className={className} {...props} />;
    case IntegrationType.PIPEDRIVE:
      return <PipedriveIcon className={className} {...props} />;
    case IntegrationType.HUBSPOT:
      return <HubspotIcon className={className} {...props} />;
    case IntegrationType.MARKETO:
      return <MarketoIcon className={className} {...props} />;
    case IntegrationType.GAINSIGHT:
      return <GainsightIcon {...props} />;
    case IntegrationType.WEBHOOK:
      return (
        <WebhookIcon
          className={classNames('fill-current text-tw-purple-78', className)}
          {...props}
        />
      );
    case IntegrationType.API:
      return <ApiIcon className={className} {...props} />;
    case IntegrationType.AMPLITUDE:
      return <AmplitudeIcon className={className} />;
    case IntegrationType.SEGMENT:
      return <SegmentIcon className={className} />;
    case IntegrationType.MIXPANEL:
      return <MixpanelIcon className={className} />;
    default:
      return <></>;
  }
}

IntegrationIcon.defaultProps = {
  height: 48,
  width: 48,
};
