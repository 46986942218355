import axios from 'axios';
import { BASE_URL } from 'utils/environment';
import { useAuthStore } from 'hooks/useAuthStore';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

async function refresh(refreshToken: string) {
  const resp = await axios.post(BASE_URL + '/auth/token/refresh/', {
    refresh: refreshToken,
  });
  return resp.data;
}

export default function useRefresh() {
  const { getRefreshToken, setAuthToken, removeRefreshToken, setRefreshToken, removeAuthToken } =
    useAuthStore();
  const router = useHistory();
  return useMutation(
    () => {
      return refresh(getRefreshToken());
    },
    {
      onMutate: () => {
        removeAuthToken();
      },
      onSuccess: (data) => {
        setAuthToken(data.access);
        setRefreshToken(data.refresh);
      },
      onError: () => {
        removeRefreshToken();
        router.push('/login');
      },
    }
  );
}
