import { useClient } from 'api-client';
import useDebounce from 'hooks/useDebounce';
import { useQuery, useQueryClient } from 'react-query';
import { useAccountsStore } from '../store/AccountsStore';

export default function useAccounts(enabled = true) {
  const { page, sortBy, field_mapping, searchQuery, filters } = useAccountsStore();
  const debouncedSearchQuery = useDebounce(searchQuery, 300);
  const [sort] = sortBy;
  const client = useClient();
  const queryClient = useQueryClient();

  return useQuery(
    [
      'accounts',
      page,
      JSON.stringify(Object.keys(field_mapping)),
      [sortBy.map((s) => `${s.source}_${s.keyName}_${s.keySpace}_${s.order}`).join(', ')],
      [
        filters
          .map((f) => `${f.source}_${f.keyName}_${f.keySpace}_${f.op}_${JSON.stringify(f.value)}`)
          .join(', '),
      ],
      debouncedSearchQuery,
    ],
    () => client.getAccounts(page, field_mapping, sortBy, debouncedSearchQuery, filters),
    {
      keepPreviousData: true,
      onSuccess: () => {
        // prefetch the next page automatically.
        queryClient.prefetchQuery(
          [
            'accounts',
            page + 1,
            JSON.stringify(Object.keys(field_mapping)),
            JSON.stringify([sort?.keyName, sort?.order]),
            debouncedSearchQuery,
          ],
          () => client.getAccounts(page + 1, field_mapping, sortBy, debouncedSearchQuery, filters)
        );
      },
      enabled: !!enabled,
    }
  );
}
