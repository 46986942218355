import { ReactComponent as Quotes } from 'assets/images/quotes.svg';
import CanvaImage from 'assets/images/canva.png';

export default function LoginRightBanner() {
  return (
    <div
      className="fixed flex items-center justify-center h-screen px-5 py-5 bg-right-top bg-no-repeat bg-auto bg-waves"
      style={{ backgroundColor: '#2D2F51' }}
    >
      <div className="pl-2">
        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-opacity-30 bg-tw-white-ff">
          <Quotes className="w-8" />
        </div>
        <p className="mt-4 text-2xl text-white">
          It’s important that we’re able to identify users where our premium subscriptions are the
          right fit. Toplyne enables us to effectively target and scale these efforts at our speed
          of growth.
        </p>
        <div className="flex-row mt-8 d-flex">
          <img
            src={CanvaImage}
            alt="founder"
            className="border-2 border-solid rounded-full h-14 w-14 border-tw-white-ff"
          />
          <div className="ml-3">
            <h4 className="mb-0 text-xl text-white">David Burson</h4>
            <p className="mb-0 text-base text-tw-gray-c">Head of Revenue, Canva</p>
          </div>
        </div>
      </div>
    </div>
  );
}
