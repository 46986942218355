import { Combobox, Transition } from '@headlessui/react';
import { Search } from 'ui-components/inputs/Search/Search';
import { DropdownArrowIcon } from 'ui-components/data-display/Icons';
import { classNames } from 'utils/common';
import { useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

type ComboBoxProps = {
  field: ControllerRenderProps<any, any>;
  data: { id: string; name: string }[];
  placeholder: string;
};

export default function ComboBox({ field, data, placeholder }: ComboBoxProps) {
  const [query, setQuery] = useState('');
  const filteredData =
    query === ''
      ? data
      : data.filter((item) =>
          item.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  return (
    <Combobox
      value={field.value}
      onChange={(value) => {
        field.onChange(value);
      }}
    >
      {({ open }) => (
        <div className="relative mt-1">
          <div
            className={classNames(
              'relative flex items-center w-full p-4 overflow-hidden text-left bg-white border rounded-lg cursor-default border-tw-gray-eb focus:outline-none focus-visible:ring-2 focus-visible:ring-white'
            )}
          >
            <span className={classNames(!field.value.name && 'text-tw-black-9')}>
              {field.value.name || placeholder}
            </span>
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center justify-end w-full pr-2">
              <DropdownArrowIcon className="fill-current text-tw-black-7" />
            </Combobox.Button>
          </div>
          {open && (
            <Transition
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery('')}
            >
              <div className="absolute w-full py-1 bg-white rounded-md shadow-lg z-5">
                <Combobox.Options
                  static
                  className="w-full mt-1 text-base bg-white max-h-80 focus:outline-none sm:text-sm"
                >
                  <div className="w-full px-4 py-4">
                    <Search
                      autoFocus={true}
                      fullWidth={true}
                      hasShadow={false}
                      placeholder={''}
                      value={query}
                      onChangeFunction={(event) => setQuery(event.target.value)}
                      onClearFunction={() => setQuery('')}
                    />
                  </div>
                  {filteredData?.length === 0 && query !== '' ? (
                    <div className="relative px-4 py-3 text-gray-700 cursor-default select-none">
                      Nothing found.
                    </div>
                  ) : (
                    <div className="overflow-y-scroll max-h-60">
                      {filteredData?.map((item) => (
                        <Combobox.Option
                          key={item.id}
                          className={({ active }) =>
                            classNames(
                              'relative cursor-default select-none py-3 pl-10 pr-4 text-tw-black-3',
                              active && 'bg-tw-blue-f2'
                            )
                          }
                          value={item}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? 'font-medium' : 'font-normal'
                                }`}
                              >
                                {item.name}
                              </span>
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                    </div>
                  )}
                </Combobox.Options>
              </div>
            </Transition>
          )}
        </div>
      )}
    </Combobox>
  );
}
