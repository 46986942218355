import { FC } from 'react';
import { PrimaryDropdown } from '../../../../ui-components/navigation/Dropdown';
import useRole from '../../../../hooks/useRole';
import useUpdateRole from '../../hooks/useUpdateRole';
import { roleOptions, Roles } from '../../Roles';

interface RoleProps {
  currentRole: string;
  id: string;
  email: string;
}

export const RoleComponent: FC<RoleProps> = ({ currentRole, id, email }) => {
  const { mutate: updateRole } = useUpdateRole();
  const { isReadAndWrite } = useRole();

  return (
    <PrimaryDropdown
      selectBtnWidth={'w-40 2xl:w-60'}
      options={
        isReadAndWrite
          ? [
              {
                label: 'Read and Write',
                value: Roles.READ_AND_WRITE,
              },
              { label: 'Read Only', value: Roles.READ_ONLY },
            ]
          : roleOptions
      }
      value={currentRole}
      onChange={(e) => {
        let roleData = {
          id: id,
          new_role: e,
          email: email,
          old_role: currentRole,
        };
        updateRole(roleData);
      }}
    />
  );
};
