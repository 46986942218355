import { SearchIcon } from '@chakra-ui/icons';
import { Listbox } from '@headlessui/react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { DropdownArrowIcon } from 'ui-components/data-display/Icons';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { CustomOptions, Options } from '../DropDownMenu';

interface SelectDropdownWithSearchProps {
  onChange: any;
  value: string | number | Date;
  customOptions?: CustomOptions;
  isLoading?: boolean;
  options?: Options;
  size?: string;
}

const SelectDropdownWithSearch: React.FC<SelectDropdownWithSearchProps> = ({
  value,
  onChange,
  options: optionsArray,
  customOptions,
  isLoading,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null | number | Date>(
    optionsArray[0]?.label
  );
  const [localSearchBin, setLocalSearchBin] = useState<string>('');
  const [results, setResults] = useState<Options>(optionsArray);
  const inputRef = React.useRef(null);

  useEffect(() => {
    let searchResults = [];
    searchResults = optionsArray.filter((x) => x.label.includes(localSearchBin));
    setResults(searchResults);
  }, [localSearchBin]);

  useEffect(() => {
    if (value && selectedOption !== value && Array.isArray(optionsArray)) {
      const label = optionsArray?.find((option) => option.value == value)?.label;
      setSelectedOption(label);
    }
    if (value && selectedOption !== value && customOptions) {
      setSelectedOption(value);
    }
  }, [value, optionsArray]);

  const handleLocalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearchBin(e.target.value);
  };

  return (
    <div
      onFocus={() => {
        inputRef?.current?.focus({ preventScroll: true });
      }}
    >
      <Listbox value={selectedOption} onChange={onChange}>
        <DropdownMenuPrimitive.Root>
          <DropdownMenuPrimitive.Trigger className="flex items-center justify-between w-full p-3 rounded-lg cursor-pointer focus:outline-none border-1 border-tw-gray-ed text-xs text-tw-black-3">
            <div>{selectedOption}</div>
            <DropdownArrowIcon className="fill-current ml-1 text-tw-black-7 inline-block" />
          </DropdownMenuPrimitive.Trigger>
          <DropdownMenuPrimitive.Content
            className="mt-1 overflow-auto bg-white rounded shadow-lg focus:outline-none max-h-72"
            side="top"
          >
            <DropdownMenuPrimitive.Item className="p-4">
              <div
                className="flex items-center w-full pl-2 pr-1 mt-2 mb-4 rounded-sm border-1 border-tw-gray-c"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <input
                  className="w-14 py-1 focus:outline-none"
                  value={localSearchBin}
                  autoComplete="off"
                  autoFocus
                  onChange={handleLocalFilterChange}
                  ref={inputRef}
                />
                <SearchIcon />
              </div>
              <div className="list-none space-y-4">
                {!isLoading &&
                  localSearchBin &&
                  results.map((option) => (
                    <Listbox.Option
                      className="text-xs text-tw-black-5 cursor-pointer ml-4"
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </Listbox.Option>
                  ))}
                {!isLoading &&
                  !localSearchBin &&
                  optionsArray &&
                  optionsArray.map((option) => (
                    <Listbox.Option
                      className="text- text-tw-black-5 cursor-pointer ml-4"
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </Listbox.Option>
                  ))}
              </div>
            </DropdownMenuPrimitive.Item>
          </DropdownMenuPrimitive.Content>
        </DropdownMenuPrimitive.Root>
      </Listbox>
    </div>
  );
};

export default SelectDropdownWithSearch;
