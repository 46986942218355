import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DEV, REACT_APP_SEGMENT_WRITE_KEY } from '../utils/environment';
import { trimLChar } from '../utils/common';
import { useAuthStore } from '../hooks/useAuthStore';
import { AnalyticsConst } from './constants';

const queryString = require('query-string');

function usePageViews(analytics) {
  const location = useLocation();
  const { user } = useAuthStore();

  useEffect(() => {
    if (!DEV && user?.isTelemetryEnabled) {
      analytics
        ?.page('', {
          [AnalyticsConst.PAGE_NAME]: trimLChar(location.pathname.replaceAll('/', '-'), '-'),
          [AnalyticsConst.USER_NAME]: user?.firstName + ' ' + user?.lastName,
          [AnalyticsConst.USER_EMAIL]: user?.email.toLowerCase(),
          [AnalyticsConst.ACCOUNT_NAME]: user?.currentOrganization?.name.toLowerCase(),
          [AnalyticsConst.ACCOUNT_TYPE]: user?.currentOrganization?.isSandbox ? 'Test' : 'Live',
          ...queryString.parse(location.search),
        })
        .then(() => {});
    }
  }, [analytics, location, user]);
}

const SegmentAnalyticsContext = React.createContext<Analytics | null>(null);
export const SegmentAnalyticsApiProvider = ({ children }) => {
  const [analytics, setAnalytics] = React.useState<Analytics | undefined>(undefined);
  const writeKey = REACT_APP_SEGMENT_WRITE_KEY;
  React.useEffect(() => {
    const loadAnalytics = async () => {
      let [response] = await AnalyticsBrowser.load({ writeKey }, { initialPageview: true });
      setAnalytics(response);
    };
    loadAnalytics().then(() => {});
  }, [writeKey]);

  usePageViews(analytics);

  return (
    <SegmentAnalyticsContext.Provider value={analytics}>
      {children}
    </SegmentAnalyticsContext.Provider>
  );
};

export const useAnalyticsContext = () => {
  return React.useContext(SegmentAnalyticsContext);
};

export const useAnalytics = () => {
  const analytics = useAnalyticsContext();
  const { user } = useAuthStore();

  const userData = React.useMemo(
    () => ({
      [AnalyticsConst.USER_NAME]: user?.firstName + ' ' + user?.lastName,
      [AnalyticsConst.USER_EMAIL]: user?.email.toLowerCase(),
      [AnalyticsConst.ACCOUNT_NAME]: user?.currentOrganization?.name.toLowerCase(),
      [AnalyticsConst.ACCOUNT_TYPE]: user?.currentOrganization?.isSandbox ? 'Test' : 'Live',
      [AnalyticsConst.ORG_ID]: user?.currentOrganization?.orgId,
      [AnalyticsConst.USER_ID]: user?.email.toLowerCase(),
    }),
    [user]
  );

  const track = useCallback(
    (event: string, props?: any) => {
      if (!DEV && user?.isTelemetryEnabled) {
        analytics?.track(event, { ...userData, ...props }).then(() => {});
      }
    },
    [analytics, userData, user?.currentOrganization?.schemaName, user?.email]
  );

  const identify = useCallback(
    (key: string, props: any) => {
      if (!DEV && user?.isTelemetryEnabled) {
        analytics?.identify(key, props).then(() => {});
      }
    },
    [analytics, user?.currentOrganization?.schemaName, user?.email]
  );

  const reset = useCallback(() => {
    if (!DEV && user?.isTelemetryEnabled) {
      analytics?.reset();
    }
  }, [analytics, user?.currentOrganization?.schemaName, user?.email]);

  return React.useMemo(
    () => ({
      track,
      identify,
      reset,
    }),
    [track, identify, reset]
  );
};
