import { FC, useEffect, useState } from 'react';
import { PopoverContentProps } from './PopoverContent';
import SelectDropdownWithSearch from './SelectDropDownWithSearch';
import SelectDropdown from './SelectDropDown';
import * as Popover from '@radix-ui/react-popover';
import dayjs from 'dayjs';
import { classNames } from '../../../../utils/common';
import RangedDatePicker from 'ui-components/inputs/RangedDatePicker/RangedDatePicker';

export interface InTheLastFilterPopoverProps extends PopoverContentProps {}

export const InTheLastFilterPopover: FC<InTheLastFilterPopoverProps> = (props) => {
  const {
    startDateValue,
    setStartDateValue,
    endDateValue,
    setEndDateValue,
    relativeTimeOption,
    setRelativeTimeOption,
    setButtonDisabled,
  } = props;
  let startDaysIn3MonthsArray = Array(181)
    .fill(0)
    .map((i, index) => ({ label: `${index}`, value: `${index}` }));
  let endDaysIn3MonthsArray = startDaysIn3MonthsArray.filter(
    ({ value }) => Number(value) > startDateValue
  );

  // set button disabled when both
  // start and end values are empty
  useEffect(() => {
    if (!!startDateValue.toString() && !!endDateValue.toString()) {
      setButtonDisabled(false);
    } else setButtonDisabled(true);
  }, [startDateValue, endDateValue]);

  return (
    <div className="flex mt-2 justify-between space-x-2 place-items-center">
      <SelectDropdownWithSearch
        size="md"
        options={startDaysIn3MonthsArray || []}
        onChange={(e: any) => {
          setStartDateValue(e);
        }}
        value={startDateValue}
      />
      <p className="text-xs">to</p>
      <SelectDropdownWithSearch
        size="md"
        options={endDaysIn3MonthsArray || []}
        onChange={(e: any) => {
          setEndDateValue(e);
        }}
        value={endDateValue}
      />
      <SelectDropdown
        size="md"
        options={[
          { label: 'days', value: 'days' },
          { label: 'hours', value: 'hours' },
        ]}
        value={relativeTimeOption}
        onChange={(e) => {
          setRelativeTimeOption(e);
        }}
      />
    </div>
  );
};

export interface DuringFilterPopoverProps extends PopoverContentProps {}

export const DuringFilterPopover: FC<DuringFilterPopoverProps> = (props) => {
  const { duringSelectionRange, handleInputDate, className } = props;
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);

  const handleDatePicker = () => {
    setDatePickerOpen(!isDatePickerOpen);
  };

  return (
    <Popover.Root>
      <Popover.Trigger className="w-full">
        <div
          className={classNames(
            'flex items-center justify-between rounded-lg border-1 text-xs',
            className
          )}
          onClick={handleDatePicker}
        >
          {dayjs(duringSelectionRange['startDate']).format('YYYY/MM/DD')} to{' '}
          {dayjs(duringSelectionRange['endDate']).format('YYYY/MM/DD')}
        </div>
      </Popover.Trigger>
      <Popover.Content side="top">
        {isDatePickerOpen && (
          <RangedDatePicker
            handleInputDate={handleInputDate}
            initialSelectionRange={duringSelectionRange}
          />
        )}
      </Popover.Content>
    </Popover.Root>
  );
};
