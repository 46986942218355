export enum AccountFields {
  ACCOUNT_NAME = 'accountNameField',
  ACCOUNT_WEBSITE = 'accountWebsiteField',
  ACCOUNT_CREATED_AT = 'accountCreatedAtField',
  ACCOUNT_ID = 'accountIdField',
  ACCOUNT_JOIN_FIELD = 'accountJoinField',
}

export enum UserFields {
  USER_NAME = 'userNameField',
  USER_EMAIL = 'userEmailField',
  USER_CREATED_AT = 'userCreatedAtField',
  USER_ID = 'userIdField',
}
