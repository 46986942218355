import { SideBorder } from 'ui-components/data-display/Icons';
import { classNames } from 'utils/common';
import { useAuthStore } from 'hooks/useAuthStore';
import { Link, useHistory } from 'react-router-dom';

function SettingsSideNavItem({ to, label, activeMatch }) {
  const router = useHistory();
  const isActive = router.location.pathname.includes(activeMatch);
  return (
    <Link
      to={to}
      className={classNames(
        'cursor-pointer font-medium flex items-center gap-x-3',
        isActive ? 'text-tw-blue-0d' : 'text-tw-black-5'
      )}
    >
      <SideBorder className={classNames('h-6 fill-current', !isActive && 'text-transparent')} />
      <span>{label}</span>
    </Link>
  );
}

export default function SettingsSideNav() {
  const { getAccountId } = useAuthStore();

  return (
    <nav
      className="flex flex-col items-start justify-between p-2 bg-white w-60 text-dark"
      style={{ zIndex: 19, top: '77px' }}
    >
      <div className="flex flex-col w-full">
        <div className="flex flex-col mt-4 ml-6 border-l gap-y-4 border-tw-gray-eb">
          <SettingsSideNavItem
            to={`/${getAccountId()}/settings/member-profile`}
            label={'Profile'}
            activeMatch={'/member-profile'}
          />
          <SettingsSideNavItem
            to={`/${getAccountId()}/settings/team-members`}
            label={'Team members'}
            activeMatch={'/team-members'}
          />
          <SettingsSideNavItem
            to={`/${getAccountId()}/settings/api`}
            label={'API settings'}
            activeMatch={'/api'}
          />
        </div>
      </div>
    </nav>
  );
}
