import { useAuthStore } from 'hooks/useAuthStore';
import { useQuery } from 'react-query';
import { useClient } from '../../../api-client';
import { getCategorizedIntegrations } from '../../../utils/common';
import { useIntegrationListStore } from '../stores/IntegrationListStore';

export default function useIntegrations(schemaName?: string) {
  const client = useClient();
  const { getAccountId, getAccountSchemaName } = useAuthStore();
  const { setAvailableIntegrationsBasedOnCategory } = useIntegrationListStore();
  const organizationSchemaName = schemaName || getAccountSchemaName();

  return useQuery(
    ['integrations', organizationSchemaName],
    () => client.integrations(organizationSchemaName),
    {
      enabled: !!getAccountId(),
      onSuccess: (data) => {
        setAvailableIntegrationsBasedOnCategory(getCategorizedIntegrations(data));
      },
    }
  );
}
